<script>
	import { createEventDispatcher } from 'svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputTextAreaBare from '@/component/atom/InputTextAreaBare.svelte'
	import { generateOpportunityEmailParameters } from '@/shared/util/generate-opportunity-email-parameters.js'
	import { renderLiquidTemplate } from '@/lib/render-liquid-template.js'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import { showSignableAdmin } from '@/service/store/current-user.js'
	import { markdownToHtml } from '@/shared/util/markdown-to-html.js'

	export let asr
	export let opportunity
	export let companies
	export let usersById
	export let currentUser
	export let emailTemplate
	export let signableTemplate
	export let sending

	const dispatch = createEventDispatcher()

	let ccRecipients = ''
	let additionalSignatories = []
	let markdownNote = ''
	$: htmlNote = markdownToHtml(markdownNote)

	$: assigned = usersById[opportunity?.data?.relationships?.user?.data?.id]
	$: generatedEmailParams = generateOpportunityEmailParameters({
		note: htmlNote,
		assigned,
		opportunity,
		companies,
	})

	const senderToLabel = {
		assigned: 'Assigned',
		user: 'Current User',
	}
	let fromLabel
	let fromName
	let fromEmail
	$: {
		const sender = emailTemplate?.attributes?.sender
		fromLabel = senderToLabel[sender] || 'Do-Not-Reply'
		if (sender === 'assigned') {
			fromName = generatedEmailParams?.assigned?.name
			fromEmail = generatedEmailParams?.assigned?.email
		} else if (sender === 'user') {
			fromName = currentUser?.attributes?.name
			fromEmail = currentUser?.attributes?.email
		} else {
			fromName = 'Mr. Car Shipper'
			fromEmail = 'do-not-reply@mcsontrack.com'
		}
	}

	$: toName = generatedEmailParams?.customer?.fullName
	$: toEmail = generatedEmailParams?.customer?.email

	$: subjectPromise = generatedEmailParams && renderLiquidTemplate({
		view: emailTemplate.attributes?.subject || '',
		model: generatedEmailParams,
	})
	$: bodyPromise = generatedEmailParams && renderLiquidTemplate({
		view: emailTemplate.attributes?.view || '',
		model: generatedEmailParams,
	})

	$: signableTemplateId = emailTemplate?.relationships?.signableTemplate?.data?.id
	$: assignedIsInactive = emailTemplate?.attributes?.sender === 'assigned' && !assigned?.attributes?.active
	$: badFrom = !fromName || !fromEmail || assignedIsInactive
	$: badTo = !toName || !toEmail
	$: badQuote = !generatedEmailParams?.totalQuote
	$: badSignatories = additionalSignatories.find(sig => !sig.name.trim() || !sig.email.trim())
	$: readyToSend = !badFrom && !badTo && !badQuote

	$: developEnvironment = [ 'localhost', 'develop.mcsontrack.com' ].includes(window.location.hostname)
</script>

<style>
	th {
		width: 15em;
	}
	code {
		font-size: 100%;
	}
</style>

{#if emailTemplate?.attributes?.model !== 'email-template-opportunity'}
	<div class="alert alert-error">
		This email template is not configured to be used for Opportunities!
	</div>
{:else}
	<table class="table table-sm">
		<tbody>
		<tr class:table-danger={badFrom}>
			<th>
				From
				<span class="text-muted" style="font-weight: lighter;">({fromLabel})</span>
			</th>
			<td>
				"{fromName}" &lt;{fromEmail}&gt;
				{#if assignedIsInactive}
					<br>
					<span class="text-danger">The assigned user is <strong>inactive</strong>.</span>
				{/if}
			</td>
		</tr>
		<tr class:table-danger={badTo}>
			<th>To</th>
			<td>
				<span style="{developEnvironment && 'text-decoration: line-through;'}">
					"{toName}" &lt;{toEmail}&gt;
				</span>
				{#if developEnvironment}
					<br>
					<span class="text-info">
						QA will send to: {currentUser.attributes.email}
					</span>
				{/if}
			</td>
		</tr>
		{#if signableTemplateId}
			<tr>
				<th>Signable Document</th>
				<td>
					<NamedIcon name="signableDocument" />
					<MaybeLink href={$showSignableAdmin && asr.makePath('app.systemSettings.signableTemplates.edit.signableTemplateId', { signableTemplateId }, { inherit: null })}>
						{signableTemplate?.attributes?.name || signableTemplateId}
					</MaybeLink>
				</td>
			</tr>
			<tr>
				<th>Additional Signatories</th>
				<td>
					{#if additionalSignatories.length}
						<table class="table table-sm mb-1">
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th><!-- remove --></th>
								</tr>
							</thead>
							<tbody>
								{#each additionalSignatories as _, index}
									<tr>
										<td>
											<input
												type="text"
												value={additionalSignatories[index].name}
												on:input={({ target: { value } }) => { additionalSignatories[index].name = value }}
												class="form-control form-control-sm"
											>
										</td>
										<td>
											<input
												type="text"
												value={additionalSignatories[index].email}
												on:input={({ target: { value } }) => { additionalSignatories[index].email = value }}
												class="form-control form-control-sm"
											>
										</td>
										<td>
											<button class="btn btn-sm btn-default" on:click={() => { additionalSignatories = [ ...additionalSignatories.filter((_, i) => i !== index) ] }}>
												Remove
											</button>
										</td>
									</tr>
								{/each}
							</tbody>
						</table>
						{#if badSignatories}
							<p class="form-text mb-1">All signatories must have a legally identifiable name and email address.</p>
						{/if}
					{/if}
					<p class="mb-1">
						<button class="btn btn-sm btn-primary" on:click={() => { additionalSignatories = [ ...additionalSignatories, { name:'', email:'' } ] }}>Add Signatory</button>
					</p>
					<p class="form-text mb-1">Each name and address here will receive <em>its own</em> uniquely trackable signable URL.</p>
				</td>
			</tr>
		{/if}
		<tr>
			<th class:text-muted={signableTemplateId}>
				CC
			</th>
			<td>
				{#if signableTemplateId}
					<p class="mb-1 text-muted">
						Emails with Signable Documents cannot send CC emails.
					</p>
				{:else}
					<input
						type="text"
						value={ccRecipients}
						on:input={({ target: { value } }) => { ccRecipients = value }}
						class="form-control form-control-sm"
					>
					<p class="form-text mb-1">
						Separate emails with a comma.
						Additional emails must be in plain email format like <code>person@site.com</code> or long format <code>"First Last" &lt;person@site.com&gt;</code>
					</p>
				{/if}
			</td>
		</tr>
		{#await subjectPromise}
			<tr>
				<th>Subject</th>
				<td></td>
			</tr>
		{:then subject}
			<tr>
				<th>Subject</th>
				<td>
					{subject}
				</td>
			</tr>
		{:catch error}
			<tr class="table-danger">
				<th>Subject</th>
				<td>
					<pre>{error}</pre>
				</td>
			</tr>
		{/await}
		{#if badQuote}
			<tr class="table-danger">
				<th>Total Quote</th>
				<td>Invalid Quote</td>
			</tr>
		{/if}
		{#if emailTemplate?.attributes?.bccs?.length}
			<tr>
				<th>BCC</th>
				<td>
					{#each emailTemplate.attributes.bccs as bcc, index}
						{bcc}
						{#if index < (emailTemplate.attributes.bccs.length - 1)}
							<br>
						{/if}
					{/each}
				</td>
			</tr>
		{/if}
		{#if emailTemplate?.attributes?.customNote}
			<tr>
				<th>
					Custom Note
				</th>
				<td>
					<InputTextAreaBare
						value={markdownNote}
						on:input={({ target: { value } }) => { markdownNote = value }}
					/>
					<p class="form-text">
						You can use <code>*Markdown*</code> syntax.
						<ExternalLink url="https://www.markdown-cheatsheet.com/">Learn more.</ExternalLink>
					</p>
				</td>
			</tr>
		{/if}
		</tbody>
	</table>

	<p>Preview</p>
	<div style="background-color: #fff; border: 1px solid #ddd; border-radius: 5px;" class="mb-3 p-3 pb-0">
		{#await bodyPromise}
			<!-- loading... -->
		{:then body}
			{@html body}
		{:catch error}
			<pre>{JSON.stringify(error, undefined, 4)}</pre>
		{/await}
	</div>

	<p class="mb-0 nowrap">
		<button
			disabled={badFrom || badTo || badQuote || badSignatories || sending}
			class="btn btn-sm btn-primary"
			on:click={() => dispatch('send', { note: markdownNote, ccRecipients, additionalSignatories })}
		>
			<span class="fa fa-paper-plane"></span>
			Send the Email!
		</button>
		{#if sending}
			<NamedIcon name="loading" />
		{/if}
	</p>

{/if}
