<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let row
	$: atts = row?.attributes || {}
</script>

<style>
	span {
		color: #A5ACB2;
	}
	span.show {
		color: #70a637;
	}
</style>

<span class:show={atts.showForOpportunityStatus?.lead}>
	<NamedIcon name="lead" />
</span>

<span class:show={atts.showForOpportunityStatus?.quote}>
	<NamedIcon name="quote" />
</span>

<span class:show={atts.showForOpportunityStatus?.order}>
	<NamedIcon name="order" />
</span>

<span class:show={atts.useForAutoQuote}>
	<NamedIcon name="autoQuote" />
</span>
