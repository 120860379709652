import { commitForm } from '@/lib/form-committer.js'
import { minimumDelay } from '@/lib/delay.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const createOpportunity = async ({ post }, form, callback) => minimumDelay(700, commitForm({ form, request: post, url: '/api/v1/opportunities', callback }))
export const updateOpportunity = async ({ put }, { form, changeNote, onlyOntrackSync, delay = 700 }) => minimumDelay(delay, commitForm({
	form,
	changeNote,
	onlyOntrackSync,
	request: put,
	url: `/api/v1/opportunities/${form.primaryId}`,
}))
export const patchOpportunity = async ({ patch }, form, sparseUpdate, delay = 700) => minimumDelay(delay, commitForm({ form, patch: sparseUpdate, request: patch, url: `/api/v1/opportunities/${form.primaryId}` }))

export const listOpportunities = async ({ get }, params) => get('/api/v1/opportunities' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
export const getOpportunityById = async ({ get }, id, params = {}) => get(`/api/v1/opportunities/${id}${objectToJsonApiQuery(params)}`).then(response => response.body)

export const listOpportunityEmails = async ({ get }, id, params) => get(`/api/v1/opportunities/${id}/emails${objectToJsonApiQuery(params)}`).then(response => response.body)
export const listOpportunityHistories = async ({ get }, id, params) => get(`/api/v1/opportunities/${id}/histories${objectToJsonApiQuery(params)}`).then(response => response.body)
export const listOpportunityNotes = async ({ get }, id, params) => get(`/api/v1/opportunities/${id}/notes${objectToJsonApiQuery(params)}`).then(response => response.body)
export const listOpportunityCcTransactions = async ({ get }, id, params) => get(`/api/v1/opportunities/${id}/ccTransactions${objectToJsonApiQuery(params)}`).then(response => response.body)
export const listOpportunitySignableDocuments = async ({ get }, id, params) => get(`/api/v1/opportunities/${id}/signableDocuments${objectToJsonApiQuery(params)}`).then(response => response.body)

export const postOpportunityCcTransaction = async ({ post }, { opportunityId, ccTransaction }) => minimumDelay(
	300,
	post(`/api/v1/opportunities/${opportunityId}/ccTransactions`, {
		body: { data: ccTransaction },
	}),
)

export const postOpportunityAction = async ({ post }, { action, opportunityId, legId, updated, onlyOntrackSync, data }) => minimumDelay(
	700,
	post(`/api/v1/opportunities/${opportunityId}/legs/${legId}/actions/${action}`, {
		body: { meta: { updated, onlyOntrackSync, data } },
	}),
)

export const sendTemplateEmail = async ({ post }, { opportunityId, emailTemplateId, note, ccRecipients, additionalSignatories }) => minimumDelay(
	700,
	post(`/api/v1/opportunities/${opportunityId}/emails`, {
		body: {
			data: {
				type: 'sent-email',
				attributes: { note, ccRecipients, additionalSignatories },
				relationships: {
					template: {
						data: {
							type: 'email-template',
							id: emailTemplateId,
						},
					},
				},
			},
		},
	}),
)
