const getPreviousLegId = (currentLegId, opportunityId, resourcesById) => {
	const index = resourcesById[opportunityId]?.relationships?.legs?.data?.findIndex(leg => leg.id === currentLegId)
	if (!index || index <= 0) return false
	return resourcesById[opportunityId].relationships.legs.data[index - 1]?.id
}

const getAddress = (which, currentLegId, opportunityId, resourcesById) => {
	const locationType = resourcesById[currentLegId]?.attributes?.[`${which}Type`]
	if (locationType === 'terminal') {
		const companyId = resourcesById[currentLegId].relationships?.[`${which}Terminal`]?.data?.id
		const addressId = resourcesById[companyId]?.relationships?.address?.data?.id
		return {
			address: resourcesById[addressId]?.attributes,
			addressRel: { id: companyId, type: 'company' },
		}
	}
	if (locationType === 'previousLeg') {
		return getAddress(which === 'pickup' ? 'dropoff' : 'pickup', getPreviousLegId(currentLegId, opportunityId, resourcesById), opportunityId, resourcesById)
	}
	// else it's a custom address
	const customAddressId = resourcesById[currentLegId]?.relationships?.[`${which}Address`]?.data?.id
	return { address: resourcesById[customAddressId]?.attributes }
}

const getContact = (which, currentLegId, opportunityId, resourcesById) => {
	const contactType = resourcesById[currentLegId]?.attributes?.[`${which}Type`]
	if (contactType === 'terminal') {
		const companyId = resourcesById[currentLegId].relationships?.[`${which}Terminal`]?.data?.id
		const contactId = resourcesById[companyId]?.relationships?.contact?.data?.id
		return {
			contact: {
				companyName: resourcesById[companyId]?.attributes?.name,
				// ^ will be overwritten if set here
				...resourcesById[contactId]?.attributes,
			},
			contactRel: { id: companyId, type: 'company' },
		}
	}
	if (contactType === 'previousLeg') {
		return getContact(which === 'pickup' ? 'dropoff' : 'pickup', getPreviousLegId(currentLegId, opportunityId, resourcesById), opportunityId, resourcesById)
	}
	const customContactId = resourcesById[currentLegId]?.relationships?.[`${which}Contact`]?.data?.id
	if (!customContactId && resourcesById[currentLegId]?.attributes?.[`${which}Type`] === 'terminal') {
		const companyId = resourcesById[currentLegId].relationships?.[`${which}Terminal`]?.data?.id
		const contactId = resourcesById[companyId]?.relationships?.contact?.data?.id
		return {
			contact: {
				companyName: resourcesById[companyId]?.attributes?.name,
				// ^ will be overwritten if set here
				...resourcesById[contactId]?.attributes,
			},
			contactRel: { id: companyId, type: 'company' },
		}
	}
	return { contact: resourcesById[customContactId]?.attributes }
}

export const getPickupDropoffForLeg = ({ opportunityId, legId, resourcesById }) => {
	return {
		pickup: { ...getAddress('pickup', legId, opportunityId, resourcesById), ...getContact('pickup', legId, opportunityId, resourcesById) },
		dropoff: { ...getAddress('dropoff', legId, opportunityId, resourcesById), ...getContact('dropoff', legId, opportunityId, resourcesById) },
	}
}
