import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	user: false,
}

export const type = 'user-email'

export default {
	description: `
		An email is one that is owned by a single user. The identifier of this resource
		is the actual email address, and it can only have one user associated with it.
	`,
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'The normalized email address, e.g. `me@site.com`.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				password: {
					description: 'The hashed password, stored both on the `user` and `email` object for faster authentication.',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'The user who owns this email address.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
