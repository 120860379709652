import { jsonApiRelationshipList, jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'
import { cdRequestPurpose } from '../value/cd-request-purpose.js'

export const $selfFragment = {
	user: false,
	logins: false,
	opportunity: false,
}

export const type = 'cd-request'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the Central Dispatch request record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-immutable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				purpose: {
					description: 'What was the purpose of the request.',
					type: 'string',
					enum: Object.keys(cdRequestPurpose),
				},
				action: {
					description: 'Set for all post-form requests.',
					type: 'string',
				},
				status: {
					description: 'The *highest* status code returned on any request, set here for filtering.',
					type: 'number',
				},
				hasErrors: {
					description: 'Filterable property, set to true if there were any errors encountered for any request.',
					type: 'boolean',
				},
				startUrl: {
					description: 'The "url" of the very first request, set here for easier filtering.',
					type: 'string',
				},
				endUrl: {
					description: 'The "url" of the very last request, set here for easier filtering.',
					type: 'string',
				},
				requestCount: {
					description: 'The count of the requests, set here for easier filtering.',
					type: 'number',
				},
				requests: {
					description: `
						Making a single request may end up with a long set of redirects. We keep
						track of *all* of them for auditing purposes.
					`,
					type: 'array',
					minItems: 1,
					items: {
						type: 'object',
						additionalProperties: false,
						properties: {
							status: {
								description: 'The response status code, e.g. 302.',
								type: 'number',
							},
							url: {
								description: 'The URL attempting to be accessed.',
								type: 'string',
							},
							form: {
								description: `
									If there was a form submitted, this is a key-value map of all
									the form values, right before they are stringified, except that
									the username/password, CSRF token, and "encryptedListing" are set
									to "[REDACTED]" for security purposes.
								`,
								type: 'object',
							},
							location: {
								description: 'The "Location" header string, if included on the response.',
								type: 'string',
							},
							// TODO: could maybe write this all to S3 instead?
							html: {
								description: 'The HTML of the response, if included.',
								type: 'string',
							},
							errors: {
								description: 'Set if there were any errors encountered during this request.',
								type: 'array',
								items: {
									$ref: '#/components/schemas/error',
								},
							},
						},
					},
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'OnTrack user who initiated this request.',
					...jsonApiRelationshipSingular('user'),
				},
				logins: {
					description: 'If there was a login required during a form post, this is how to tell.',
					...jsonApiRelationshipList('cd-request'),
				},
				opportunity: {
					description: 'The opportunity associated with these requests.',
					...jsonApiRelationshipSingular('opportunity'),
				},
			},
		},
	},
}
