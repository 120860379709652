<script>
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	export let items
	export let asr
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col-auto">
				<h4 class="p-0 m-0">
					{#each items as { path, params, label }, index}
						{#if path}
							<a href={asr.makePath(path, params || null, { inherit: false })}>
								{label}
							</a>
						{:else}
							{label}
						{/if}
						{#if index < (items.length - 1)}
							<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
						{/if}
					{/each}
				</h4>
			</div>
			<slot />
		</div>
	</div>
</div>
