import template from './Create.svelte'
import { type } from '@/shared/model/email-template.model.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Create Email Template',
	},
	template,
	resolve: async () => ({ form: load({
		data: {
			id: 'GID0',
			type,
			attributes: {
				name: '',
				model: '',
			},
		} }, 0) }),
}
