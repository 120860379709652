<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import ActiveColumn from './users/Active.svelte'
	import NameColumn from './users/NameColumn.svelte'
	import UserPermissions from './users/UserPermissions.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'name',
		'email',
		'permissions',
		'lastLogin',
	]

	const nameToColumn = {
		name: {
			title: 'Name',
			component: NameColumn,
			keypath: 'attributes.name',
			link: row => asr.makePath('app.users.edit.userId', { userId: row.id }),
		},
		email: {
			title: 'Email',
			component: PlainText,
			keypath: 'attributes.email',
		},
		permissions: {
			title: 'Permissions',
			component: UserPermissions,
			keypath: 'attributes.permissions',
		},
		lastLogin: {
			title: 'Last Login',
			component: DateTime,
			keypath: 'attributes.lastLogin',
			time: true,
		},
		active: {
			title: 'Active',
			component: ActiveColumn,
			keypath: 'attributes.active',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
