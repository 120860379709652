<script>
	import PrintPage from '@/component/PrintPage.svelte'

	import { description as postDescription, responses as postResponses } from '../../../../cloudflare-api/route/api/v1/opportunity/post.route.js'
	import { description as getDescription, responses as getResponses } from '../../../../cloudflare-api/route/api/v1/opportunity/get.route.js'
	import incomingLead from '../../../../../_shared/model/incoming-lead.model.js'
	import errorModel from '../../../../../_shared/model/error.model.js'
	import OpenApiSchema from '@/component/OpenApiSchema.svelte'

	const exampleRequestBody = {
		autoQuoteCampaignId: 'JOE123',
		shipDate: '2024-07-04',
		email: 'tobias@davistobias.com',
		originZip: '68123',
		destinationZip: 69151,
		vehicles: [
			{
				year: 1989,
				make: 'Chevy',
				model: 'Sprint',
			},
		],
	}
	const exampleErrorResponseBody = {
		errors: [ {
			status: '400',
			code: 'InvalidProperty',
			title: 'Shipping date must be in the format YYYY-MM-DD, e.g. "2024-07-04".',
			source: {
				pointer: '/shipDate',
			},
		} ],
	}
	const errorResponseSchema = {
		type: 'object',
		properties: {
			errors: {
				description: 'Errors about the invalid opportunity properties.',
				type: 'array',
				items: errorModel,
			},
		},
	}
	const exampleSuccessResponseBody = {
		data: {
			opportunityId: '123456P',
			accessToken: 'TODO',
		},
		links: {
			opportunity: 'https://mcsontrack.com/api/v1/opportunity?opportunityId=123456P&accessToken=01HP7J3P3JQEXAMPLE9GNNVPPJ',
		},
	}
</script>

<style>
	dt strong {
		font-weight: lighter;
	}
</style>

<PrintPage>
	Print Page
</PrintPage>

<h1>
	<small style="font-weight: lighter;">
		Mr. Car Shipper: OnTrack
	</small>
	<br>
	Auto-Quote API
</h1>

<p>These are notes intended for a developer to integrate with the OnTrack Auto-Quote functionality.</p>

<!-- ====================================================================== -->
<h2><span>1.</span> Overview</h2>

<p>
	First, <code>POST</code> a JSON body containing lead information (pickup/dropoff
	location, vehicle and contact details). Authentication is with a simple static API key.
</p>

<p>
	Then, make a <code>GET</code> request containing the returned lead identifier and
	provided single-purpose token to check the status of the lead. Retries may be needed
	if processing has not completed.
</p>

<!-- ====================================================================== -->
<h2><span>2.</span> Create a Quote</h2>

{#each postDescription.split('\n\n') as text}
	<p>{text}</p>
{/each}

<h3><span>2.1.</span> Request</h3>

<h4><span>2.1.1.</span> Headers</h4>

<dl>
	<dt>
		<code>X-Api-Key</code>
		<strong>Required</strong>
	</dt>
	<dd>
		This is a static key, provided by Mr. Car Shipper, that looks like <code>b1d5a72b4cefexampleafc69cf02b6ba</code>.
	</dd>
	<dt>
		<code>Content-Type</code>
	</dt>
	<dd>
		Content must be JSON formatted, form-data encoding is not supported. You may optionally set this header to
		<br>
		<code>application/json; charset=utf-8</code>
	</dd>
</dl>

<h4><span>2.1.2.</span> Body</h4>

<p>The body is a JSON object with the following properties:</p>

<OpenApiSchema schema={incomingLead} />

<p>For example:</p>

<div class="card mb-3">
	<div class="card-body mb-0">
		<pre><code>{JSON.stringify(exampleRequestBody, undefined, 3)}</code></pre>
	</div>
</div>

<h3>2.2. Response</h3>

<p>
	If the request was successful, the response status code will be <code>202</code>, meaning the
	request was accepted but processing is incomplete. All other responses will be errors.
</p>

<h4><span>2.2.1</span> Error Responses</h4>

<p>Error responses are JSON objects with the following structure:</p>

<OpenApiSchema schema={errorResponseSchema} />

<p>For example:</p>

<div class="card mb-3">
	<div class="card-body mb-0">
		<pre><code>{JSON.stringify(exampleErrorResponseBody, undefined, 3)}</code></pre>
	</div>
</div>

<h4><span>2.2.2</span> Success Response</h4>

<p>If the <code>errors</code> object is not present at the response root, the request was successful.</p>

<p>The successful response has the following structure:</p>

<OpenApiSchema schema={postResponses[202].content['application/json'].schema} />

<p>For example:</p>

<div class="card mb-3">
	<div class="card-body mb-0">
		<pre><code>{JSON.stringify(exampleSuccessResponseBody, undefined, 3)}</code></pre>
	</div>
</div>

<!-- ====================================================================== -->
<h2><span>3.</span> Check Job Status</h2>

{#each getDescription.split('\n\n') as text}
	<p>{text}</p>
{/each}

<h3><span>3.1.</span> Request</h3>

<p>
	If the response from the <code>POST</code> request contained a URL on the <code>links.opportunity</code>
	property, you may make a <code>GET</code> request to that URL to check the processing status and the
	automatically-calculated tariff.
</p>

<h4><span>3.1.1.</span> Headers</h4>

<p>No special headers are required.</p>

<h4><span>3.1.2</span> Query Parameters</h4>

<p>The URL on the <code>links.opportunities</code> property will contain the following properties:</p>

<dl>
	<dt>
		<code>opportunityId</code>
		<strong>Required</strong>
	</dt>
	<dd>
		The identifier of the created lead opportunity.
	</dd>
	<dt>
		<code>accessToken</code>
	</dt>
	<dd>
		Grants access to a limited set of data of this single opportunity.
	</dd>
</dl>

<h3><span>3.2.</span> Response</h3>

<p>
	If the access token is missing or the opportunity identifier is invalid you will receive an error
	response, but in all other cases you will receive a successful body with the following structure:
</p>

<OpenApiSchema schema={getResponses[200].content['application/json'].schema} />

<h3>3.3. Retries</h3>

<p>
	If the <code>data.attributes.status</code> property of the response is still <code>incoming</code>,
	the lead has not been fully processed and you should make the <code>GET</code> request again.
</p>

<p>Typical processing time is anywhere between a few seconds and 2 minutes, with a p99 of about 5 minutes.</p>

<p>
	Please wait at least 5 seconds between requests, and do not continue making requests after 10 minutes.
	Ideally, you should also implement an <a href="https://en.wikipedia.org/wiki/Exponential_backoff">exponential backoff</a>.
</p>

<p>
	If you receive an HTTP status code of <code>429</code> this means you are making too many requests
	to the API and are being rate-limited. Please be kind.
</p>
