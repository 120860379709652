<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { createUser } from '@/lib/controller/user.js'
	import { router } from '@/service/router.js'

	export let asr
	export let api

	let name = ''
	let email = ''
	let saving
	let errors

	const create = () => {
		saving = true
		errors = undefined
		createUser(api, { name, email })
			.then(({ body: { data: { id: userId } } }) => {
				router.go('app.users.edit.userId', { userId })
			})
			.catch(error => {
				errors = [ error ]
				saving = false
			})
	}

	$: hasEmail = /.+@.+\..+/.test(email)
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.users', null, { inherit: false })}>
		Users
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Create User
	</span>
</FormHeaderMain>

<div class="card mb-3">
	<div class="card-body">
		<div class="input-group input-group-sm mb-2">
			<span class="input-group-text">
				Name
			</span>
			<input
				disabled={saving}
				type="text"
				class="form-control"
				value={name}
				on:input={event => name = event.target.value}
			>
		</div>
		<div class="input-group input-group-sm mb-2">
			<span class="input-group-text">
				Email
			</span>
			<input
				disabled={saving}
				type="text"
				class="form-control"
				value={email}
				on:input={event => email = event.target.value}
			>
		</div>
	</div>
</div>

<button class="btn btn-sm btn-primary" on:click={create} disabled={!hasEmail || !name}>
	{#if saving}
		<span><span class="fas fa-spinner fa-pulse"></span></span>
	{:else}
		<span><span class="fa fa-save"></span></span>
	{/if}
	Create User
</button>

{#if errors?.length}
	{#each errors as error}
		<ErrorAlert {error} />
	{/each}
{/if}
