<script>
	import MapOriginDestination from '@/component/MapOriginDestination.svelte'
	import PhysicalAddress from '@/component/molecule/PhysicalAddress.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { legPickupDropoffToGoogleMapsLink } from '@/lib/leg-pickup-dropoff-to-google-maps-link.js'
	import { legPickupDropoffToCentralDispatchSearch } from '@/lib/leg-pickup-dropoff-to-central-dispatch-search.js'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'

	export let form

	$: legId = form.data[form.primaryId]?.relationships?.legs?.data?.[0]?.id
	$: legLocations = getPickupDropoffForLeg({ opportunityId: form.primaryId, legId, resourcesById: form.data })
	$: mapsLink = legPickupDropoffToGoogleMapsLink(legLocations)
	$: cdLink = legPickupDropoffToCentralDispatchSearch(legLocations)
	$: zips = legLocations && {
		pickup: legLocations.pickup?.address?.zip,
		dropoff: legLocations.dropoff?.address?.zip,
	}

	const getAddressString = ({ city, state, zip }) => `${city}, ${state} ${zip}`

	let pickupAddressString = ''
	$: {
		if (legLocations?.pickup?.address) pickupAddressString = getAddressString(legLocations.pickup.address)
	}

	let dropoffAddressString = ''
	$: {
		if (legLocations?.dropoff?.address) dropoffAddressString = getAddressString(legLocations.dropoff.address)
	}
</script>

<div class="card mb-3">
	<div class="card-header">
		<span class="pe-3">Shipping</span>
		{#if mapsLink}
			<span class="pe-3">
				<ExternalLink url={mapsLink}>
					Google Maps
				</ExternalLink>
			</span>
		{/if}
		{#if cdLink}
			<ExternalLink url={cdLink}>
				Search on Central Dispatch
			</ExternalLink>
		{/if}
	</div>
	<div class="card-body bg-light pt-3">
		<div class="row">
			<div class="col">
				<p class="pt-1 mb-1">
					Pickup
					{#if pickupAddressString}
						<CopyToClipboard string={pickupAddressString} />
					{/if}
				</p>
				<PhysicalAddress
					hideLines
					bind:form
					relId={legId}
					relName="pickupAddress"
				/>
			</div>
			<div class="col">
				<p class="pt-1 mb-1">
					Deliver
					{#if dropoffAddressString}
						<CopyToClipboard string={dropoffAddressString} />
					{/if}
				</p>
				<PhysicalAddress
					hideLines
					bind:form
					relId={legId}
					relName="dropoffAddress"
				/>
			</div>
		</div>
		{#if zips}
			<MapOriginDestination origin={zips.pickup} destination={zips.dropoff} class="mb-3" height="240px" />
		{/if}
	</div>
</div>
