<script>
	import History from '@/component/table/History.svelte'
	import NavHeader from './NavHeader.svelte'
	import TimelineItem from './overview/TimelineItem.svelte'
	import { keyBy } from '@/shared/util/key-by.js'

	export let asr
	export let api
	export let form
	export let histories
	export let historiesIncluded
	export let archivedNotes
	export let users
	export let usersById

	let showArchivedNotes

	$: notesIncludedById = keyBy(archivedNotes?.included || [], 'id')
</script>

<style>
	:global(.opportunity-history .history-table th.change-row-name) { width: 14em; }
</style>

<NavHeader {asr} {form} {users} {usersById} activeTab="history" />

<div class="row">
	{#if form.data[form.primaryId].attributes.duplicateIds?.length}
		<div class="col-auto">
			<div class="alert alert-warning py-2">
				Initially marked duplicate of:
				{#each form.data[form.primaryId].attributes.duplicateIds as opportunityId, index}
					<a
						href={asr.makePath(null, { opportunityId }, { inherit: false })}
					>{opportunityId.replace(/P$/, '')}</a>{#if (index + 1) < form.data[form.primaryId].attributes.duplicateIds.length},&nbsp;{/if}
				{/each}
			</div>
		</div>
	{/if}
</div>

<div class="card mb-3">
	<div class="card-header d-flex">
		<h5>
			Archived Notes
			<span style="color: var(--falcon-500);">{archivedNotes?.data?.length || 0}</span>
		</h5>
		{#if archivedNotes?.included?.length}
			<button class="btn btn-secondary btn-sm ms-5" on:click={() => { showArchivedNotes = !showArchivedNotes }}>
				{#if showArchivedNotes}
					Hide
				{:else}
					Show
				{/if}
			</button>
		{/if}
	</div>
	{#if showArchivedNotes}
		<div class="card-body bg-light">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="col-date" scope="col">Date</th>
						<th class="col-user" scope="col">User</th>
						<th class="col-action" scope="col">Action</th>
						<th scope="col">Notes</th>
					</tr>
				</thead>
				<tbody>
					{#each archivedNotes?.data as item}
						<TimelineItem opportunityId={form.primaryId} {item} includedById={notesIncludedById} {api} {asr} {usersById} />
					{/each}
				</tbody>
			</table>
		</div>
	{/if}
</div>

<div class="card">
	<div class="card-header">
		<h5>Edits</h5>
	</div>
	<div class="card-body bg-light opportunity-history">
		<History
			{asr}
			data={histories}
			included={historiesIncluded}
			parentType="opportunity"
			sort="-meta.created"
		/>
	</div>
</div>
