export const nameToFontAwesomeIcon = {
	add: 'fa-plus',
	ascending: 'fa-caret-up',
	autoQuote: 'fa-comment-dollar',
	archive: 'fa-archive',
	breadcrumb: 'fa-angle-right',
	cancel: 'fa-book-dead',
	carrier: 'fa-truck',
	check: 'fa-money-check',
	cc: 'fa-credit-card',
	clock: 'fa-clock',
	comment: 'fa-comment',
	company: 'fa-building',
	complete: 'fa-check-circle',
	copy: 'fa-clipboard',
	date: 'fa-calendar-alt',
	delivered: 'fa-check-square',
	descending: 'fa-caret-down',
	dispatched: 'fa-file-signature',
	email: 'fa-at',
	fax: 'fa-fax',
	history: 'fa-tools',
	hold: 'fa-hand-paper',
	download: 'fa-download',
	job: 'fa-server',
	lead: 'fa-file',
	link: 'fa-external-link-alt',
	loading: 'fa-spinner fa-pulse',
	map: 'fa-map-pin',
	minus: 'fa-minus-circle',
	money: 'fa-dollar-sign',
	note: 'fa-sticky-note',
	opportunity: 'fa-file-invoice',
	open: 'fa-box-open',
	order: 'fa-file-contract',
	phone: 'fa-phone',
	received: 'fa-shipping-fast',
	quote: 'fa-file-invoice-dollar',
	referrer: 'fa-hands-helping',
	report: 'fa-chart-line',
	save: 'fa-save',
	search: 'fa-search',
	session: 'fa-user-clock',
	shipper: 'fa-truck-pickup',
	sign: 'fa-file-signature',
	staff: 'fa-user-shield',
	sync: 'fa-exchange-alt',
	system: 'fa-cogs',
	terminal: 'fa-parking',
	trash: 'fa-trash-alt',
	unpost: 'fa-ban',
	undeliver: 'fa-stop-circle',
	unsigned: [ 'fa-file-signature', 'fa-ban' ],
	upload: 'fa-file-upload',
	user: 'fa-user-circle',
	users: 'fa-users',
	vehicle: 'fa-car',
	warning: 'fa-exclamation-circle',
}
