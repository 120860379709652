<script>
	import { writable } from 'svelte/store'
	import Chart from './_Chart.svelte'
	export let requests

	$: purposeToRequests = requests.reduce((map, req) => {
		map[req.attributes.purpose] = map[req.attributes.purpose] || {}
		map[req.attributes.purpose][req.attributes.status] = map[req.attributes.purpose][req.attributes.status] || {}
		map[req.attributes.purpose][req.attributes.status][req.meta.created] = (map[req.attributes.purpose][req.attributes.status][req.meta.created] || 0) + req.attributes.requestCount
		return map
	}, {})

	$: buckets = requests.reduce((map, req) => {
		const bucket = req.meta.created.split(':')[0] // "2022-08-19T21:38:44.493Z" => "2022-08-19T21"
		map[bucket] = true
		return map
	}, {})

	$: multipleSeries = Object.keys(purposeToRequests || {}).map(purpose => {
		return Object.keys(purposeToRequests[purpose]).map(status => {
			const dateToCount = purposeToRequests[purpose][status]
			// return {
			// 	name: `${purpose}/${status}`,
			// 	data: Object.keys(dateToCount).map(date => ({
			// 		x: new Date(date),
			// 		y: dateToCount[date],
			// 	})),
			// }
			const seriesBuckets = Object.keys(dateToCount).reduce((map, date) => {
				const bucket = date.split(':')[0]
				map[bucket] = (map[bucket] || 0) + dateToCount[date] // the count
				return map
			}, {})
			return {
				name: `${purpose}/${status}`,
				data: Object.keys(buckets).map(bucket => seriesBuckets[bucket] || null),
			}
		})
	}).flat()

	$: labels = Object.keys(buckets).map(b => {
		const [ date, hour ] = b.split('T')
		return `${date} ${hour}:00`
	})

	let options = writable()
	$: {
		options.set({
			series: multipleSeries,
			chart: {
				height: 250,
				type: 'line',
				zoom: {
					enabled: false,
				},
				animations: {
					enabled: false,
				},
			},
			stroke: {
				width: multipleSeries.map(() => 4),
				curve: 'straight',
			},
			labels,
			colors: [
				({ seriesIndex }) => {
					let [ purpose, status ] = multipleSeries[seriesIndex].name.split('/')
					if (parseInt(status, 10)) status = parseInt(status, 10)
					let color = '#2E93fA'
					if (purpose === 'login') color = '#7E36AF'
					if (status >= 400) color = '#FF9800'
					if (status >= 500) color = '#D9534F'
					return color
				},
			],
			markers: {
				size: 5,
			},
			title: {
				text: 'Central Dispatch Request Logs',
			},
			xaxis: {
				// type: 'datetime',
			},
		})
	}
</script>

<Chart {options} />
