export const notFoundRedirect = (body, type, id) => {
	if (body.error?.status === 404) {
		throw {
			redirectTo: {
				name: 'app.notFound',
				params: { type, id },
			},
		}
	}
}
