import { router } from '@/service/router.js'
import { createUser } from '@/service/api/user.js'
import { AuthSimpleRegister } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'
import { minimumDelay } from '@/lib/delay.js'

export default {
	template: AuthSimpleRegister,
	resolve: (data, { email }) => Promise.resolve({
		config,
		form: {
			register: { email },
		},
	}),
	activate: ({ domApi }) => {
		domApi.$on('submit', ({ detail: { name, form } }) => {
			form._state = {
				submitting: true,
			}
			domApi.$set({ form })
			minimumDelay(1000, createUser({ name: form[name].name, email: form[name].email, password: form[name].password }))
				.then(() => {
					router.go('app.dashboard')
				})
				.catch(error => {
					console.log(JSON.stringify(error.body.errors, undefined, 4))
					form._state = {
						errors: error.body.errors,
					}
					domApi.$set({ form })
				})
		})
	},
}
