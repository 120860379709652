import { opportunityColumns } from '@/shared/value/report-columns.js'
// Note: this doesn't need to be here, I just didn't get around to remapping the existing exports
export { opportunityColumnOrderDefault as defaultOrderedColumns } from '@/shared/value/report-columns.js'

import VehicleTableCell from './VehicleTableCell.svelte'
import LegNumberedNewline from './LegNumberedNewline.svelte'
import LegStatuses from './LegStatuses.svelte'

const components = {
	LegNumberedNewline,
	LegStatuses,
	VehicleTableCell,
}

let columns
export const everyOpportunityColumn = ({ asr }) => {
	if (!columns) {
		columns = opportunityColumns({ asr })
		for (const key in columns) {
			if (columns[key].component && !components[columns[key].component]) console.error('COLUMN COMPONENT MISSING FROM OPPORTUNITIES UI!')
			else columns[key].component = components[columns[key].component]
		}
	}
	return columns
}
