import { get } from '@/lib/json-fetch.js'

export const listViewLoadNext = ({ domApi }) => ({ detail: { links: { next }, data, included } }) => {
	get(next)
		.then(response => {
			domApi.$set({
				loading: false,
				errors: false,
				links: response.body.links,
				data: [ ...data, ...(response.body.data || []) ],
				included: [ ...included, ...(response.body.included || []) ],
			})
		})
		.catch(error => {
			console.error('Error while loading!', error)
			domApi.$set({
				loading: false,
				request: { method: 'GET', url: next },
				errors: error.body && error.body.errors || [ error ],
			})
		})
}

export const activate = ({ domApi }) => {
	domApi.$on('loadNext', listViewLoadNext({ domApi }))
}
