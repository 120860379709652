import { userRoles } from '../value/user-roles.js'

export default {
	description: 'Data available for emails sent from a user, like new user or password reset request emails.',
	type: 'object',
	properties: {
		note: {
			description: 'Custom Markdown-formatted note when sending email. Only available if set in template.',
			type: 'string',
		},
		id: {
			description: 'The OnTrack user identifier.',
			type: 'string',
		},
		name: {
			type: 'string',
			description: 'The name displayed in OnTrack and on sent emails.',
		},
		phone: {
			description: 'Phone number used in sent emails.',
			type: 'string',
		},
		email: {
			description: 'Address used to log in and in the Reply-To for sent emails.',
			type: 'string',
		},
		role: {
			description: 'The role of the user.',
			type: 'string',
			enum: Object.keys(userRoles),
		},
		lastLogin: {
			description: 'The date the user last logged in successfully.',
			type: 'string',
			format: 'date-time',
		},
		token: {
			description: 'Expiring token used in password reset and user creation emails.',
			type: 'string',
		},
		permissions: {
			$ref: '#/components/schemas/user-permissions',
		},
	},
}
