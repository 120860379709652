export const getSchemaKeypathDefinition = (schema, keypath) => {
	let definition = schema.properties
	let key = keypath.shift()
	while (key) {
		const temp = definition[key]
		if (keypath.length) {
			if (temp.type === 'object') definition = temp.properties
			else if (temp.type === 'array') {
				definition = temp.items
				if (definition.type === 'object' && keypath.length > 1) {
					definition = definition.properties
					keypath.shift()
				}
			} else return definition
		} else if (definition[key]) definition = definition[key]
		key = keypath.shift()
	}
	return definition
}
