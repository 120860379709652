/*

%27 = '

https://app.centraldispatch.com/search?search=
	~(
		vC
		~(mn~1)
		~opt~'all
		~rTSW~'d60
		~lmt~50
		~sF~(
			~(
				n~'ppm
				~d~'DESC
			)
			~(
				n~'ppm
				~d~'DESC
			)
		)
		~rT~'Open
		~l~(
			~(
				s~'Pickup
				~r~50
				~c~'Omaha
				~st~'NE
				~zC~'33134
			)
			~(
				s~'Dropoff
				~r~50
				~c~'San*20Diego
				~st~'CA
				~zC~'97223
			)
		)
	)
*/

const base = 'https://app.centraldispatch.com/search?search='

const PREFIX = 'vC'
const ASSORTED = [
	'~(mn~1)',
	'~opt~%27all',
	'~rTSW~%27d60',
].join('')
const LIMIT = '~lmt~50'
const SORT_PRICE_PER_MILE = '~(n~%27ppm~d~%27DESC)'
const SORTS = `~sF~(${SORT_PRICE_PER_MILE}${SORT_PRICE_PER_MILE})`
const MIDDLE_BIT = '~rT~%27Open'

const sanitizeString = string => encodeURIComponent(string).replaceAll('%', '*')

const makeSingleLocation = (which, { city, state, zip }) => ([
	`s~%27${which}`,
	'r~50', // radius in miles to look
	`c~%27${sanitizeString(city)}`,
	`st~%27${state}`,
	`zC~%27${zip}`,
]).join('~')

const makeUrl = (from, to) => `${base}~(${PREFIX}${ASSORTED}${LIMIT}${SORTS}${MIDDLE_BIT}~l~(~(${from})~(${to})))`

const requiredParams = [ 'city', 'state', 'zip' ]

export const legPickupDropoffToCentralDispatchSearch = ({ pickup: { address: pickup }, dropoff: { address: dropoff } }) => {
	if (!pickup || !dropoff) return ''
	if (!requiredParams.every(key => pickup[key]) || !requiredParams.every(key => dropoff[key])) return ''
	const from = makeSingleLocation('Pickup', pickup)
	const to = makeSingleLocation('Dropoff', dropoff)
	return makeUrl(from, to)
}
