<script>
	import { vehicleType } from '@/shared/value/vehicle-type.js'
	export let row
	export let includedById

	const shippingMethodToLabel = {
		topLoad: 'TL',
		enclosed: 'ENC',
	}

	$: vehicles = row?.relationships?.vehicles?.data?.map(v => includedById[v.id]) || []
</script>

{#each vehicles as vehicle}
	{@const v = vehicle?.attributes || {}}
	{#if v.doesNotRun}
		<span class="text-warning">
			<span class="fa fa-car-crash"></span>
		</span>
		&nbsp;
	{/if}
	{#if v.shippingMethod && v.shippingMethod !== 'open'}
		<strong class="text-info">{shippingMethodToLabel[v.shippingMethod] || v.shippingMethod}</strong>
	{/if}
	{#if v.year}{v.year}{/if}
	{#if v.make}{v.make}{/if}
	{#if v.model}{v.model}{/if}
	{#if vehicleType[v.type] || v.typeOther}
		<small><em>{vehicleType[v.type] || v.typeOther}</em></small>
	{/if}
	<br>
{/each}
