import { vehicleSize, vehicleType } from '../value/vehicle-type.js'
import { shippingMethod } from '../value/shipping-method.js'

export const type = 'vehicle'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier of the vehicle.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				note: {
					description: 'Optional arbitrary notes about the vehicle.',
					type: 'string',
				},
				autoQuotedCarrierPay: {
					description: 'The carrier pay to ship this vehicle as set by the Auto Quote calculator.',
					type: 'number',
					format: 'double',
				},
				autoQuotedBrokerFee: {
					description: 'The broker fee set by the Auto Quote calculator.',
					type: 'number',
					format: 'double',
				},
				originalCarrierPay: {
					description: 'The original carrier pay to ship this vehicle as set by the sales person.',
					type: 'number',
					format: 'double',
				},
				originalBrokerFee: {
					description: 'The original broker fee set by the sales person.',
					type: 'number',
					format: 'double',
				},
				tariffAdjustment: {
					description: 'Adjustment made to tariff *after* the opportunity becomes an order, e.g. customer interaction based.',
					type: 'number',
					format: 'double',
				},
				year: {
					description: 'The year of the vehicle, e.g. "2017". Min/Max values taken from Central Dispatch form.',
					type: 'integer',
					minimum: 1900,
					maximum: 2032,
				},
				make: {
					description: 'The make of the vehicle, e.g. "Honda".',
					type: 'string',
				},
				model: {
					description: 'The model of the vehicle, e.g. "Accord LX".',
					type: 'string',
				},
				type: {
					description: 'The type id of the vehicle, e.g. "Car". This value matches the available types on Central Dispatch.',
					type: 'string',
					enum: Object.keys(vehicleType),
				},
				sizeCorrect: {
					description: 'True if the size has been set by our internal `vehicles` database or by Central Dispatch.',
					type: 'boolean',
				},
				size: {
					description: 'The vehicle size, which correlates slightly to the size on Central Dispatch.',
					type: 'string',
					enum: Object.keys(vehicleSize),
				},
				typeOther: {
					description: 'If the type is something else, describe it here manually.',
					type: 'string',
				},
				color: {
					description: 'The color of the vehicle, e.g. "tan".',
					type: 'string',
				},
				plateNumber: {
					description: 'The license plate number of the vehicle.',
					type: 'string',
				},
				plateState: {
					description: 'The issuing state of the license plate.',
					type: 'string',
				},
				vin: {
					description: 'The vehicle identification number.',
					type: 'string',
				},
				lot: {
					description: 'If stored in a terminal, the identifier of the lot the vehicle is kept in.',
					type: 'string',
				},
				doesNotRun: {
					description: 'Set to true if the vehicle is not able to be driven on to the truck.',
					type: 'boolean',
				},
				shippingMethod: {
					description: 'The method of shipment for the vehicle.',
					type: 'string',
					enum: Object.keys(shippingMethod),
				},
			},
		},
	},
}
