<script>
	import { opportunityAddress } from '@/lib/opportunity-address.js'
	import LegLocation from '@/component/LegLocation.svelte'
	import Card from '@/component/atom/Card.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import OverallDates from './OverallDates.svelte'
	import { LEAD_TO_QUOTE, QUOTE_TO_ORDER } from '@/shared/value/system-email-templates.js'
	import EmailsWithNotes from './EmailsWithNotes.svelte'
	import AutoQuoteDetails from '@/component/form/opportunity/AutoQuoteDetails.svelte'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import { showAutoQuote } from '@/service/store/current-user.js'

	export let referrers
	export let asr
	export let form
	export let usersById
	export let leadAndQuoteEmails

	const filterEmails = templateId => email => email.relationships?.template?.data?.id === templateId
		&& email.attributes?.parameters?.note
	$: quoteEmailsWithNotes = (leadAndQuoteEmails || []).filter(filterEmails(LEAD_TO_QUOTE))
	$: orderEmailsWithNotes = (leadAndQuoteEmails || []).filter(filterEmails(QUOTE_TO_ORDER))

	$: customerContactId = form.data[form.primaryId]?.relationships?.customerContact?.data?.id
	$: customer = form.data[customerContactId]?.attributes || {}
	$: customerName = customer.name || `${customer.firstName || ''} ${customer.lastName || ''}`.trim()
	$: referrerId = form.data[form.primaryId]?.relationships?.referrer?.data?.id
	$: assignedId = form.data[form.primaryId]?.relationships?.user?.data?.id
	$: opportunityReferrer = referrerId && referrers && referrers.find(r => r.id === referrerId)
	$: legs = form.data[form.primaryId]?.relationships?.legs?.data || []
	$: legsWithAttributes = legs.filter(leg => leg.attributes)

	$: autoQuoteCampaign = form.data[form.data[form.primaryId].relationships?.autoQuoteCampaign?.data?.id]

	const getLocation = legs => {
		const [ firstLeg, ...remainingLegs ] = legs
		const lastLeg = remainingLegs.length ? remainingLegs.pop() : firstLeg
		if (!firstLeg || !lastLeg) return {}
		return {
			start: opportunityAddress(firstLeg.id, form.data, true),
			end: opportunityAddress(lastLeg.id, form.data, false),
		}
	}
	$: location = getLocation(legs)
</script>

<style>
	span.icon { display: inline-block; width: 25px; text-align: center; }
</style>

<Card title="Overall">
	<table class="table table-sm">
		<OverallDates opportunity={form.data[form.primaryId]} />
		{#if customer.companyName}
			<tr>
				<td>
					<NamedIcon name="company" />
				</td>
				<th>Company</th>
				<td>
					{customer.companyName}
				</td>
			</tr>
		{/if}
		{#if customerName}
			<tr>
				<td></td>
				<th>Customer</th>
				<td>{customerName}</td>
			</tr>
		{/if}
		{#if customer.phonePrimary}
			<tr>
				<td></td>
				<th>Phone</th>
				<td>{customer.phonePrimary}</td>
			</tr>
		{/if}
		{#if customer.emailPrimary}
			<tr>
				<td></td>
				<th>Email</th>
				<td>{customer.emailPrimary}</td>
			</tr>
		{/if}
		{#if location.start || location.end}
			<tr>
				<td>
					<span class="icon">
						<span class="fa fa-map-pin"></span>
					</span>
				</td>
				<td colspan="2">
					<LegLocation location={location.start} />→<LegLocation location={location.end} />
				</td>
			</tr>
		{/if}
		{#if opportunityReferrer}
			<tr>
				<td>
					<NamedIcon name="referrer" />
				</td>
				<th>Referrer</th>
				<td>
					<a href={asr.makePath('app.referrers.edit.referrerId', { referrerId: opportunityReferrer.id }, { inherit: false })}>
						{opportunityReferrer.attributes.name}
					</a>
				</td>
			</tr>
		{/if}
		{#if autoQuoteCampaign}
			<tr>
				<td>
					<NamedIcon name="autoQuote" />
				</td>
				<th>
					<div>
						Auto Quote
					</div>
				</th>
				<td>
					<div class="dropdown">
						<MaybeLink href={showAutoQuote && asr.makePath('app.systemSettings.autoQuoteCampaigns.edit.autoQuoteCampaignId', { autoQuoteCampaignId: autoQuoteCampaign.id })}>
							{autoQuoteCampaign.attributes.name}
						</MaybeLink>
						<button
							class="btn btn-sm btn-primary py-0 px-2 ms-2 dropdown-toggle"
							type="button"
							id="show-auto-quote-decisions"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<span class="fa fa-chevron-down"></span>
						</button>
						<div class="dropdown-menu p-2 text-dark text-nowrap">
							<AutoQuoteDetails {form} {autoQuoteCampaign} />
						</div>
					</div>
				</td>
			</tr>
		{/if}
	</table>
	{#if form.data[form.primaryId]?.attributes.customerNotes}
		<p class="mb-0 mt-3">📝 <strong>Customer Notes</strong></p>
		<div class="ms-3">
			{form.data[form.primaryId].attributes.customerNotes}
		</div>
	{/if}
	<EmailsWithNotes label="Quote Email Notes" emails={quoteEmailsWithNotes} {usersById} />
	<EmailsWithNotes label="Order Email Notes" emails={orderEmailsWithNotes} {usersById} />
</Card>
