<script>
	import dlv from 'dlv'

	export let row
	export let column

	$: id = dlv(row, column.keypath)
	$: text = id && id.replace(/P$/, '')
	$: link = id && column.link(id)

	$: probablyBats = text && (parseInt(text, 10) > 999999)
</script>

{#if probablyBats}
	<span class="text-muted">
		<small>BATS</small>
		{text}
	</span>
{:else if link}
	<a href={link}>
		{text}
	</a>
{/if}
