<script>
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onSelect

	export let label
	export let emptySelectLabel = 'Select...'
	export let options = {}

	$: elementId = [ id, ...keypath ].join('.')

	$: optionsList = Object.keys(options || {}).map(value => ({ value, title: options[value] }))
</script>

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	{#if readonly || disabled}
		<input
			readonly
			type="text"
			value={options?.[value] || ''}
			class="form-control"
			id={elementId}
		>
	{:else}
		<select
			class="form-select form-select-sm pe-5 ps-2 {errors.length ? 'is-invalid' : ''}"
			aria-label="Select a {label}"
			on:input={event => { onSelect(event.target.value, { set, create }) } }
			on:*
			{value}
			id={elementId}
		>
			<option value="" selected="">{emptySelectLabel}</option>
			{#each optionsList as { value, title }}
				<option {value}>
					{title}
				</option>
			{/each}
		</select>
	{/if}
</Field>
