<script>
	import { Field } from 'jsonapi-svelte-form'
	import InputGroup from '@/component/atom/InputGroup.svelte'
	import InputSelectBare from './InputSelectBare.svelte'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onSelect

	export let label
	export let emptySelectLabel
	export let options
	export let labelWidth = '85px'
	export let icon = ''
	export let short

	$: elementId = [ id, ...keypath ].join('.')
	$: hasChanges = !!form?.changes[id]?.find(c => c.path?.every((p, index) => keypath?.[index] === p))
</script>

<Field bind:form {id} {keypath} let:errors>
	<InputGroup {form} {id} {keypath} {elementId} {errors} {icon} {label} {labelWidth} {short}>
		<InputSelectBare {id} bind:form {keypath} {readonly} {onSelect} {label} {emptySelectLabel} {options} />
	</InputGroup>
</Field>
