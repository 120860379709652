import { writable } from 'svelte/store'
import { router } from '@/service/router.js'
import { get } from '@/lib/json-fetch.js'

const currentState = {
	incoming: {
		active: '',
		overall: '',
	},
	lead: {
		active: '',
		cancelled: '',
		overall: '',
	},
	quote: {
		active: '',
		held: '',
		cancelled: '',
		overall: '',
	},
	order: {
		unpost: '',
		post: '',
		assign: '',
		dispatch: '',
		receive: '',
		deliver: '',
		held: '',
		cancelled: '',
		overall: '',
	},
	delivered: {
		delivered: '',
		overall: '',
	},
}

export const opportunityCounts = writable(currentState)

export const reloadCounts = async () => get('/api/v1/opportunityCounts')
	.then(response => { opportunityCounts.set(response.body.data) })
	.catch(error => {
		console.error('Error fetching opportunity counts:', error)
	})

router.on('stateChangeStart', () => { reloadCounts() })
