import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	user: false,
	parentResource: false,
	childResource: false,
}

export const type = 'file'

export default {
	description: 'Reference to a file stored in S3.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the file.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'folder',
				'filename',
				'mimeType',
			],
			properties: {
				folder: {
					type: 'string',
					description: 'The sub-folder portion of the S3 key, e.g. "my/reports", with NO pre or trailing slash.',
				},
				filename: {
					type: 'string',
					description: 'The file name portion of the S3 key, e.g. "202206021533506-margins.csv", with NO pre slash.',
				},
				originalFilename: {
					type: 'string',
					description: 'For reference, the original filename as uploaded.',
				},
				expiration: {
					type: 'string',
					description: `
						Things like reports may only be accessible for an amount of time, and then need to be re-generated. This expiration
						value is used to auto-delete S3 files using S3 mechanisms, which are not exact, so the file may be available some
						time after this date. Always check first when allowing a user access to the file.
					`,
					format: 'date-time',
				},
				mimeType: {
					type: 'string',
					description: 'The mime-type of the file, e.g. "application/vnd.ms-excel" for Excel spreadsheets.',
				},
				bytes: {
					type: 'number',
					description: 'The byte size of the file, if known.',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				childResource: {
					description: 'The parent resource that this file is tied to, if any.',
					...jsonApiRelationshipSingular('cc-transaction', 'note'),
				},
				parentResource: {
					description: 'The parent resource that this file is tied to, if any.',
					...jsonApiRelationshipSingular('opportunity', 'company'), // report
				},
				user: {
					description: 'User who uploaded this file.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
