import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	user: false,
}

export const type = 'session'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-expirable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'status',
			],
			properties: {
				password: {
					description: 'The hashed password for this session.',
					type: 'string',
				},
				status: {
					enum: [
						'a', // active
						'n', // not yet active
						'e',  // expired, aka was previously active but is no longer
					],
				},
				ipAddress: {
					description: 'The logged IP address of the device creating the session, if available.',
					type: 'string',
				},
				userAgent: {
					description: 'The user-agent information of the device creating the session, if available.',
					type: 'string',
				},
				cleanUserAgent: {
					$ref: '#/components/schemas/user-agent',
				},
				cfCountry: {
					description: 'The Cloudflare country-code of the device creating the session, if available.',
					type: 'string',
				},
				cfRay: {
					description: 'The Cloudflare log trace code of the device creating the session, if available. See: https://developers.cloudflare.com/fundamentals/get-started/http-request-headers',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'User to which this session belongs.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
