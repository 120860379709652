import { addClass } from './utils.js'

/* -------------------------------------------------------------------------- */
/*                                  Detector                                  */
/* -------------------------------------------------------------------------- */

export const detector = () => {
	if (!window.is) {
		return setTimeout(() => {
			detector()
		})
	}

	const { is } = window
	const html = document.querySelector('html')

	is.opera() && addClass(html, 'opera')
	is.mobile() && addClass(html, 'mobile')
	is.firefox() && addClass(html, 'firefox')
	is.safari() && addClass(html, 'safari')
	is.ios() && addClass(html, 'ios')
	is.iphone() && addClass(html, 'iphone')
	is.ipad() && addClass(html, 'ipad')
	is.ie() && addClass(html, 'ie')
	is.edge() && addClass(html, 'edge')
	is.chrome() && addClass(html, 'chrome')
	is.mac() && addClass(html, 'osx')
	is.windows() && addClass(html, 'windows')
	navigator.userAgent.match('CriOS') && addClass(html, 'chrome')
}
