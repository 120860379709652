import template from './SignableDocument.svelte'
import { getSignableDocumentById, getSignableDocumentVisits } from '@/lib/controller/signable-document.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { signableDocumentId }) => {
		const [ { data: signableDocument, included }, { data: signableDocumentVisits } ] = await Promise.all([
			getSignableDocumentById({ get }, signableDocumentId),
			getSignableDocumentVisits({ get }, signableDocumentId),
		])
		return { signableDocument, signableDocumentVisits, agreementEmail: included?.find(inc => inc.type === 'sent-email') }
	},
}
