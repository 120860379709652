<script>
	import { Form } from 'jsonapi-svelte-form'
	import EditOrder from './opportunity/EditOrder.svelte'
	import EditCustomerInfo from './opportunity/EditCustomerInfo.svelte'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form
	export let companyData
	export let referrers = []
	export let api
	export let readonly
	export let cloning
</script>

<style>
	:global(.opportunity-form .highlighted-area) {
		padding-top: 8px;
		border: 1px solid var(--falcon-info);
		border-radius: 3px;
	}
</style>

<fieldset disabled={readonly} class="opportunity-form">
	<Form bind:form let:remove let:create>
		<EditCustomerInfo
			bind:form
			{referrers}
			{cloning}
		/>
		<EditOrder
			bind:form
			{create}
			{remove}
			{api}
			{companyData}
			{cloning}
		/>
	</Form>
</fieldset>
