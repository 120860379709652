import template from '@/component/BlankUiView.svelte'
import { redirect } from '@/lib/redirect-if-missing-params.js'

export default {
	data: {
		title: 'View',
	},
	template,
	resolve: redirect([ 'customReportId' ], 'app.reports'),
}
