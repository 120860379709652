<script>
	import JobStatusIcon from '@/component/JobStatusIcon.svelte'
	import JobStatusChecker from '@/component/JobStatusChecker.svelte'
	import IdString from '@/component/atom/IdString.svelte'
	import ConfirmActionSimple from '@/component/ConfirmActionSimple.svelte'
	import ErrorList from '@/component/ErrorList.svelte'
	import ExecutionRow from './ExecutionRow.svelte'
	import { jobStatuses } from '@/shared/value/job-statuses.js'
	import { jobPaths } from '@/shared/value/job-paths.js'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { currentUser } from '@/service/store/current-user.js'
	import { router } from '@/service/router.js'
	import { load } from 'jsonapi-svelte-form/mapper'
	import { cancelJob } from '@/lib/controller/job.js'

	export let asr
	export let form
	export let users
	export let api

	$: atts = form.data[form.primaryId]?.attributes || {}

	$: cdRequestIds = form.data[form.primaryId]?.relationships?.cdRequests?.data?.map(rel => rel.id)
	$: receivedEmailId = form.data[form.primaryId]?.relationships?.receivedEmail?.data?.id
	$: opportunityId = form.data[form.primaryId]?.relationships?.opportunity?.data?.id
	$: owner = users.find(user => user.id === form.data[form.primaryId]?.relationships?.user?.data?.id)
	$: waiting = [ 'REQUESTED', 'IN_PROGRESS' ].includes(form.data[form.primaryId]?.attributes?.status)
	$: hasErrors = atts.executions?.[atts.executions?.length - 1]?.errors?.length > 0
	let jobPath
	let otherParams
	$: {
		const { jobPath: path, ...remaining } = atts.parameters || {}
		jobPath = path
		otherParams = remaining
	}

	let errors
	let showRaw

	const updateJob = ({ detail }) => {
		form = load({ data: detail }, 0)
	}

	let canceling
	const cancel = () => {
		errors = false
		canceling = true
		cancelJob(api, { jobId: form.primaryId, updated: form.data[form.primaryId].meta.updated })
			.then(response => {
				form = load(response.body, 0)
				canceling = false
			})
			.catch(error => {
				errors = error.body?.errors || [ error ]
				canceling = false
			})
	}
</script>

<div class="card mb-3">
	<h4 class="card-header">
		<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
		<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
		<a href={asr.makePath('app.systemSettings.jobs', null, { inherit: false })}>Job Inspector</a>
		<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
		<IdString resource={form.data[form.primaryId]} />
	</h4>
	<div class="card-body">
		<dl>
			<dt>Type</dt>
			<dd>
				{jobPath}
				<br>
				<small>{jobPaths[jobPath]}</small>
			</dd>
			{#if Object.keys(otherParams).length}
				<dt>Other Parameters</dt>
				<dd>
					{#each Object.keys(otherParams) as param}
						{param}:
						<code><pre class="ps-3">{JSON.stringify(otherParams[param], undefined, 2)}</pre></code>
					{/each}
				</dd>
			{/if}
			<dt>Status</dt>
			<dd>
				{atts.status}
				{#if waiting}
					<JobStatusChecker {api} asr={router} jobId={form.primaryId} on:stopped={updateJob} />
				{:else}
					<JobStatusIcon status={atts.status} {hasErrors} />
					{#if hasErrors && atts.status !== 'FAILED'}
						<em>with errors</em>
					{/if}
				{/if}
				<br>
				<small>{jobStatuses[atts.status]}</small>
			</dd>
			{#if owner}
				<dt>
					Owner
					<small style="font-weight: normal;">The person who started the job.</small>
				</dt>
				<dd>{owner.attributes.name}</dd>
			{/if}
			<dt>Total Execution Time</dt>
			<dd>{atts.executionTimeMs || 0}ms</dd>
			{#if opportunityId}
				<dt>Opportunity</dt>
				<dd>
					<a href={router.makePath('app.opportunities.edit.opportunityId', { opportunityId }, { inherit: false })}>
						{opportunityId.replace(/P$/, '')}
					</a>
				</dd>
			{/if}
			{#if receivedEmailId}
				<dt>Received Email</dt>
				<dd>
					<a href={router.makePath('app.systemSettings.receivedEmails.byId.receivedEmailId', { receivedEmailId }, { inherit: false })}>
						{receivedEmailId}
					</a>
				</dd>
			{/if}
			{#if cdRequestIds?.length}
				<dt>Central Request IDs</dt>
				<dd>
					{#each cdRequestIds as id}
						{id}
						<br>
					{/each}
				</dd>
			{/if}
		</dl>
		{#if atts.executions && atts.executions.length}
			<hr>
			<h4>Executions</h4>
			<table class="table">
				<thead>
				<tr>
					<th scope="col">SQS Message ID</th>
					<th scope="col">Start</th>
					<th scope="col">End</th>
					<th scope="col">Millis</th>
					<th scope="col">Errors</th>
				</tr>
				</thead>
				<tbody>
				{#each atts.executions as exe}
					<ExecutionRow {exe} />
 				{/each}
				</tbody>
			</table>
		{/if}
		{#if errors}
			<ErrorList {errors} />
		{/if}
		{#if form.data[form.primaryId]?.attributes?.status === 'REQUESTED'}
			<ConfirmActionSimple
				action="Try to Cancel Requested Job"
				confirm="Trying to Cancel Requested Job"
				iconName="cancel"
				light
				danger
				on:click={cancel}
				disabled={canceling}
			>
				<p class="mb-1" style="max-width: 30em;">
					If the job is in the middle of processing it might not be able to be
					cancelled, and things could end up in a weird or broken state. Only
					cancel the job if it has been running for a long time.
				</p>
			</ConfirmActionSimple>
		{/if}
	</div>
</div>

{#if $currentUser?.attributes?.permissions?.manageApp}
	<p class="text-end">
		<button class="btn btn-secondary" on:click={() => showRaw = !showRaw}>
			{showRaw ? 'Hide' : 'Show'}
			Raw Data
		</button>
	</p>
{/if}

{#if showRaw}
	<pre style="border: 1px solid #ddd; padding: 1em; background-color: #fff; margin: 1em 0;">{JSON.stringify(form.data[form.primaryId], undefined, 4)}</pre>
{/if}
