<script>
	import { Field } from 'jsonapi-svelte-form'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputTextAreaBare from '@/component/atom/InputTextAreaBare.svelte'
	import LiquidTemplateModel from '@/component/molecule/LiquidTemplateModel.svelte'
	import { renderLiquidTemplate } from '@/lib/render-liquid-template.js'

	export let form
	export let id
	export let label
	export let keypath
	export let rows
	export let model
	export let exampleModelData

	let showPreview
	let showData

	let previousTemplate
	let previewHtmlPromise
	const buildPreview = template => {
		previousTemplate = template
		previewHtmlPromise = renderLiquidTemplate({
			view: template,
			model: exampleModelData || {},
		})
	}

	let exampleModelDataError
	const updateModelData = ({ target: { value } }) => {
		try {
			exampleModelData = JSON.parse(value)
			if (previousTemplate) buildPreview(previousTemplate)
		} catch (error) {
			exampleModelDataError = error.message
		}
	}
</script>

<div class="row">
	<div class="col-xs-12 col-lg-6">
	</div>
</div>

<div class="row">
	<div class="col-xs-12 col-lg-6">
		<ul class="nav nav-tabs">
			<li class="nav-item">
				<button on:click={() => showPreview = false} class="nav-link" class:active={!showPreview}>
					{label || 'Editor'}
				</button>
			</li>
			<li class="nav-item">
				<Field bind:form {id} {keypath} let:value>
					<button on:click={() => { showPreview = true; buildPreview(value) }} class="nav-link" class:active={showPreview}>
						Preview
					</button>
				</Field>
			</li>
		</ul>
		{#if showPreview}
			<!-- the 2px margin is to make this visually align with the editor, which has borders etc -->
			<div class="border rounded px-3 py-1" style="margin: 2px;">
				{#await previewHtmlPromise}
					Rendering...
				{:then html}
					{@html html}
				{:catch error}
					<pre>{error}</pre>
				{/await}
			</div>
		{:else}
			<InputTextArea
				bind:form
				{id}
				{keypath}
				{rows}
			/>
			<p class="mt-2 ps-1">For help with the curly syntax, look at the <a href="https://liquidjs.com/tags/overview.html">LiquidJS documentation</a>.</p>
		{/if}
	</div>
	<div class="col-xs-12 col-lg-6">
		<ul class="nav nav-tabs">
			<li class="nav-item">
				<button on:click={() => showData = !showData} class="nav-link" class:active={!showData}>
					Model
				</button>
			</li>
			<li class="nav-item">
				<button on:click={() => showData = !showData} class="nav-link" class:active={showData}>
					Example Data
				</button>
			</li>
		</ul>
		{#if showData}
			{#if exampleModelDataError}
				<div class="alert alert-danger p-2 my-2">{exampleModelDataError}</div>
			{/if}
			<InputTextAreaBare
				value={JSON.stringify(exampleModelData, undefined, 4)}
				on:input={updateModelData}
			/>
		{:else}
			<div style="margin: 2px;">
				<LiquidTemplateModel bind:data={exampleModelData} {model} keypath={[]} visibleKeypath={[]} />
			</div>
		{/if}
	</div>
</div>
