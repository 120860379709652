<script>
	import DateString from '@/component/atom/DateString.svelte'
	import dlv from 'dlv'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { router } from '@/service/router.js'
	import { showAutoQuote } from '@/service/store/current-user.js'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	export let row
	export let column
	$: value = dlv(row, column.keypath)
	$: autoQuoteCampaignId = row?.relationships?.autoQuoteCampaign?.data?.id
	$: href = autoQuoteCampaignId
		&& router.makePath('app.systemSettings.autoQuoteCampaigns.edit.autoQuoteCampaignId', { autoQuoteCampaignId }, { inherit: false })
</script>

<DateString
	date={value}
	format={column.format}
	time={column.time}
	multiline={column.multiline}
	dateFormat={column.dateFormat}
	timeFormat={column.timeFormat}
/>

{#if autoQuoteCampaignId}
	<MaybeLink href={showAutoQuote && href}>
		<NamedIcon name="autoQuote" class={showAutoQuote ? '' : 'text-muted'} />
	</MaybeLink>
{/if}
