export const assignmentStatus = {
	any: 'Any Status',
	set: 'Is Assigned',
	unset: 'Is Not Assigned',
	id: 'Specific User(s)',
}

export const cancellationFilter = {
	any: 'Any Status',
	exclude: 'Exclude Cancelled',
	include: 'Include Cancelled',
	only: 'Only Cancelled',
}

export const carrierStatus = {
	any: 'Any Status',
	set: 'Has a Carrier (any leg)',
	unset: 'No Carrier (all legs)',
	id: 'Specific Carrier(s)',
}

export const dateRangeSelector = {
	lastmonth: 'Last Month',
	back5biz: 'Back 5 Business Days',
	back1biz: 'Back 1 Business Day',
	yesterday: 'Yesterday',
	today: 'Today',
	tomorrow: 'Tomorrow',
	up1biz: 'Forward 1 Business Day',
	up5biz: 'Forward 5 Business Days',
	thismonth: 'This Month',
	custom: 'Custom Date Range',
}

export const dateType = {
	created: 'Created',
	quoted: 'Quoted',
	ordered: 'Ordered',
	firstAvailablePickup: 'First Available Pickup',
	anyDispatched: 'Dispatched (Any Leg)',
	firstDispatched: 'Dispatched (First Leg)',
	anyReceivedActual: 'Pick-Up Date, Actual (Any Leg)',
	firstReceivedActual: 'Pick-Up Date, Actual (First Leg)',
	anyReceivedContract: 'Pick-Up Date, Contractual (Any Leg)',
	firstReceivedContract: 'Pick-Up Date, Contractual (First Leg)',
	anyDeliveredActual: 'Delivery Date, Actual (Any Leg)',
	lastDeliveredActual: 'Delivery Date, Actual (Last Leg)',
	anyDeliveredContract: 'Delivery Date, Contractual (Any Leg)',
	lastDeliveredContract: 'Delivery Date, Contractual (Last Leg)',
}

export const heldFilter = {
	any: 'Any Status',
	exclude: 'Exclude Held',
	include: 'Include Held',
	only: 'Only Held',
}

export const referrerRelationship = {
	any: 'Any',
	set: 'Has a Referrer',
	unset: 'No Referrer Set',
	id: 'Specific Referrer(s)',
	tag: 'Specific Referrer Tag(s)',
}

export const autoQuoteRelationship = {
	any: 'Any',
	set: 'Was Auto-Quoted',
	unset: 'Was Not Auto-Quoted',
	id: 'Specific Auto-Quote Campaign(s)',
}
