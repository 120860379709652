import { appName } from '@/lib/layout-values.js'
import { writable } from 'svelte/store'

const paths = {
	'default-dashboard': '#/',
	// 'authentication/simple/confirm-mail': '#/',
	'authentication/simple/forgot-password': '#/forgotPassword',
	'authentication/simple/login': '#/login',
	'authentication/simple/logout': '#/logout',
	// uncomment to re-enable the "create new account" link
	// 'authentication/simple/register': '#/register',
	'authentication/simple/reset-password': '#/resetPassword',
	// 'misc/privacy-policy': 'app',
	// 'misc/terms': 'app',
	// 'misc/help': 'app',
}

export const system = writable({
	CWD: '',
	name: appName,
	stage: window.location.host === 'mcsontrack.com'
		? 'production'
		: (
			window.location.host === 'develop.mcsontrack.com'
				? 'develop'
				: 'local'
		),
	paths,
})
