<script>
	import Table from '@/component/Table.svelte'
	import { archiveNote, reopenNote, patchNote, completeNote } from '@/lib/controller/note.js'

	import NotesLinkableDate from './notes/LinkableDate.svelte'
	import NotesResource from './notes/Resource.svelte'
	import NotesAttachments from './notes/Attachments.svelte'
	import NotesReminderEditor from './notes/Reminder.svelte'
	import NotesTextEditor from './notes/TextEditor.svelte'
	import NotesAction from './notes/Action.svelte'
	import { closeAll, savingForm } from './notes/open-form-store.js'

	export let api
	export let asr
	export let loading
	export let currentTab = 'active'
	export let notesResponse = {}
	export let sort
	export let sortable
	export let noDataFoundText
	export let assignableUsers

	const filterFromColumnAction = row => () => {
		notesResponse.data = [ ...notesResponse.data.filter(r => r.id !== row.id) ]
		closeAll()
	}

	$: notesColumns = [
		{
			title: 'Updated',
			component: NotesLinkableDate,
			keypath: 'meta.updated',
			sortable: !!sortable,
			time: true,
			columnClass: 'note-updated',
			link: row => asr.makePath('app.notes.edit.noteId', { noteId: row.id }, { inherit: false }),
		},
		{
			title: 'Reminder',
			component: NotesReminderEditor,
			keypath: 'attributes.reminder',
			sortable: !!sortable,
			columnClass: 'note-reminder',
		},
		{
			title: 'Resource',
			component: NotesResource,
			keypath: 'relationships.resource.data.id',
			sortable: !!sortable,
			columnClass: 'note-resource',
		},
		{
			title: 'Note',
			component: NotesTextEditor,
			keypath: 'attributes.note',
			assignableUsers,
			save: async form => {
				const { id, meta } = form.data[form.primaryId]
				const attributes = {}
				;[ 'reminder', 'note' ].forEach(key => {
					const changed = form.data[form.primaryId].attributes[key]
					if (changed !== form.original[form.primaryId].attributes[key]) attributes[key] = changed
				})
				const originalassignedId = form.original[form.primaryId].relationships?.assigned?.data?.id
				const updatedassignedId = form.data[form.primaryId].relationships?.assigned?.data?.id
				const body = { id, meta, attributes }
				if (originalassignedId !== updatedassignedId) {
					body.relationships = {
						assigned: updatedassignedId
							? { data: { type: 'user', id: updatedassignedId } }
							: null,
					}
				}
				patchNote(api, body)
					.then(response => {
						if (originalassignedId === updatedassignedId) {
							notesResponse.data = [ ...notesResponse.data.map(r => r.id === response.data.id ? response.data : r) ]
						} else {
							notesResponse.data = [ ...notesResponse.data.filter(r => r.id !== response.data.id) ]
						}
						closeAll()
					})
			},
		},
		{
			title: 'Attachments',
			component: NotesAttachments,
			keypath: 'attributes.files',
		},
		{
			component: NotesAction,
			columnClass: 'note-action pe-0 text-end',
			currentTab,
			archive: row => {
				savingForm(row)
				archiveNote(api, row).then(filterFromColumnAction(row))
			},
			open: row => {
				savingForm(row)
				reopenNote(api, row).then(filterFromColumnAction(row))
			},
			complete: row => {
				savingForm(row)
				completeNote(api, row).then(filterFromColumnAction(row))
			},
		},
	].filter(Boolean)

	const makeLink = ({ keypath, ascending }) => asr.makePath('app.dashboard', { 'note[sort]': `${ascending ? '-' : ''}${keypath}` }, { inherit: false })
</script>

<style>
	:global(.notes-table .note-updated) { width: 14.5em; }
	:global(.notes-table .note-reminder) { width: 8.5em; }
	:global(.notes-table .note-resource) { width: 6em; }
	:global(.notes-table td.note-action) { width: 23em; }
</style>

<div class="notes-table">
	<Table
		small
		blockClick
		{loading}
		{noDataFoundText}
		{makeLink}
		{sort}
		columns={notesColumns}
		data={notesResponse.data}
		included={notesResponse.included}
		links={notesResponse.links}
		errors={notesResponse.errors}
		firstColumnScopeRow={false}
		on:clicksort
	/>
</div>
