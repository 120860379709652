import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	contact: true,
	address: true,
	insurance: true,
}

export const type = 'company'

export default {
	description: 'Any company that has ability to ship, or act as a carrier or terminal, or any combination of the three.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the company record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-imported',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [ 'name' ],
			properties: {
				centralDispatchId: {
					description: 'This is the identifier used in Central Dispatch.',
					type: 'string',
				},
				name: {
					type: 'string',
					description: 'Name of the company. (From CD)',
				},
				ownerManagerName: {
					type: 'string',
					description: 'Name of person acting as owner/manager of company. (From CD)',
				},
				hoursOpen: {
					type: 'string',
					description: 'Days and hours the location is open, if known. E.g. "M-F 8am-9pm". (From CD)',
				},
				iccmcNumber: {
					type: 'string',
					description: 'Interstate Commerce Commission of Motor Carrier, if available. (From CD)',
				},
				yearEstablished: {
					type: 'string',
					description: 'The year the company claims to have been established.',
				},
				memberSince: {
					type: 'string',
					description: 'How long the carrier has been a Central Dispatch user. (From CD)',
				},
				truckCount: {
					type: 'string',
					description: 'The fleet of trucks this company claims to have. (From CD)',
				},
				equipmentDescription: {
					type: 'string',
					description: 'The companies description of available trucks and equipment. (From CD)',
				},
				isCarrier: {
					type: 'boolean',
					description: 'Set to true if this company has carrier services. (From CD)',
				},
				isShipper: {
					type: 'boolean',
					description: 'Set to true if this company has shipping services.', // TODO from CD???
				},
				isTerminal: {
					type: 'boolean',
					description: 'Set to true if this company has terminal services.', // TODO from CD???
				},
				// The rest of these are managed internally.
				inactive: {
					type: 'boolean',
					description: 'Set to true if the company is known to no longer be active.',
				},
				blocked: {
					type: 'boolean',
					description: 'Set to true if we should be blocked from doing business with this company.',
				},
				taxId: {
					type: 'string',
					description: 'Tax ID',
				},
				notes: {
					type: 'string',
					description: 'Additional internal notes about this company.',
				},
			},
		},
		// All these properties come from CD.
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				contact: {
					description: 'Contact information for the company.',
					...jsonApiRelationshipSingular('contact'),
				},
				address: {
					description: 'Address for this company.',
					...jsonApiRelationshipSingular('address'),
				},
				insurance: {
					description: 'Insurance information for the company.',
					...jsonApiRelationshipSingular('insurance'),
				},
			},
		},
	},
}
