import template from './OpportunityStatus.svelte'
import { listOpportunities } from '@/service/api/opportunity.js'
import { activate } from '@/lib/list-view-load-next.js'
import { getCurrentUser, listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'

const activeOpportunities = { held: 'false', archived: 'false', cancelled: 'false' }
const cancelledOpportunities = { cancelled: 'true' }
const heldOpportunities = { held: 'true' }
const opportunityStatusToLegStatusToFilters = {
	lead: {
		active: activeOpportunities,
		cancelled: cancelledOpportunities,
	},
	quote: {
		active: activeOpportunities,
		held: heldOpportunities,
		cancelled: cancelledOpportunities,
	},
	order: {
		unposted: { ...activeOpportunities, anyLegsUnposted: 'true' },
		posted: { ...activeOpportunities, anyLegsPosted: 'true' },
		unsigned: { ...activeOpportunities, anyLegsAssigned: 'true' },
		dispatched: { ...activeOpportunities, anyLegsDispatched: 'true' },
		received: { ...activeOpportunities, anyLegsReceived: 'true' },
		delivered: { ...activeOpportunities, anyLegsDelivered: 'true' },
		held: heldOpportunities,
		cancelled: cancelledOpportunities,
	},
	delivered: {
		delivered: activeOpportunities,
	},
}

const opportunityStatusToDefaultTab = {
	lead: 'active',
	quote: 'active',
	order: 'unposted',
	delivered: 'delivered',
}
const statusToDefaultSort = {
	order: 'attributes.firstAvailablePickup',
}

export default {
	data: {
		title: 'Opportunity List by Status',
	},
	template,
	querystringParameters: [
		'sort',
		'tab',
		'filter[userId]',
	],
	resolve: async (data, params) => {
		if (!params.tab) return Promise.reject({
			redirectTo: {
				name: 'app.opportunities.list.opportunityStatus',
				params: {
					tab: opportunityStatusToDefaultTab[params.opportunityStatus],
					...params,
				},
			},
		})
		const filter = {
			status: params.opportunityStatus,
			...(opportunityStatusToLegStatusToFilters[params.opportunityStatus]?.[params.tab] || {}),
		}
		if (params['filter[userId]']) {
			filter.userId = params['filter[userId]']
		} else if ([ 'lead', 'quote' ].includes(params.opportunityStatus)) {
			const currentUser = await getCurrentUser({ get })
			if (currentUser.attributes.role === 'sales') filter.userId = currentUser.id
		}
		if (!params.sort) params.sort = statusToDefaultSort[params.opportunityStatus] || 'meta.created'
		return Promise.all([
			listOpportunities({
				filter,
				sort: params.sort || 'meta.created',
				include: [ 'assigned', 'terminals' ],
				page: {
					limit: 50,
				},
			}),
			listUsers({ get }, { filter: { 'attributes.active': true } }),
		]).then(([ opportunitiesResponse, usersResponse ]) => {
			opportunitiesResponse.params = params
			opportunitiesResponse.users = usersResponse.data
			opportunitiesResponse.allUsersSelected = !filter.userId
			return opportunitiesResponse
		})
	},
	activate,
}
