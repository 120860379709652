<script>
	export let visit
	export let signedVisitId
	export let signedDate

	$: created = new Date(visit.meta.created)
	$: atts = visit.attributes
	$: signed = signedVisitId
		&& signedVisitId === visit.id
		&& signedDate
		&& new Date(signedDate)
</script>

<tr class:table-success={signed}>
	<td>{created.toLocaleDateString('default')}</td>
	<td>{created.toLocaleTimeString('default')}</td>
	<td>
		{atts.ipAddress || ''}
		{#if atts.cfCountry}
			({atts.cfCountry})
		{/if}
	</td>
	<td>
		{atts.cleanUserAgent?.browser?.name || ''}
		{#if atts.cleanUserAgent?.platform?.type}
			({atts.cleanUserAgent.platform.type})
		{/if}
	</td>
	<td>
		{atts.cleanUserAgent?.os?.name || ''}
		{atts.cleanUserAgent?.os?.version || ''}
		{#if atts.cleanUserAgent?.os?.versionName}
			({atts.cleanUserAgent.os.versionName})
		{/if}
	</td>
</tr>
{#if signed}
	<tr class="table-success">
		<td colspan="5" class="ps-5">
			Agreement signed and submitted on
			<strong>{signed.toLocaleDateString('default')}</strong>
			at
			<strong>{signed.toLocaleTimeString('default')}</strong>.
		</td>
	</tr>
{/if}
