import { get as getStoreValue } from 'svelte/store'
import ProgressBar from 'svelte-progress-bar'
import { detector } from '@/lib/falcon/detector.js'

import { router } from '@/service/router.js'
import { formHasChanges } from '@/service/store/form-changes.js'
import routes from './globbed-routes.js'
import StateChangeError from './StateChangeError.svelte'
import FormHasChanges from './FormHasChanges.svelte'

const progress = new ProgressBar({
	target: document.querySelector('body'),
	props: { color: '#0366d6' },
})

detector()

const shouldScrollToTop = (currentState, previousState) => {
	// you can add more logic here as needed
	return currentState !== previousState
}

const stateChangeHistory = []
let errorWidget

routes.forEach(route => {
	route.export.name = route.path
		.replace(/^route\//, '')
		.replace(/\.route\.js$/, '')
		.replace(/\//g, '.')
		.replace(/[[\]]/g, '')
	route.export.route = '/' + route.path
		.split('/')
		.pop()
		.replace(/\.route\.js$/, '')
		.replace(/\[([^\]]+)]/g, ':$1')
	router.addState(route.export)
})

router.on('routeNotFound', (...args) => {
	console.error('routeNotFound', ...args)
	// if the route is not found, there is not much to be done, so we
	// could forward them to an error page, or we could send them back
	// to the start (which is what we do here)
	window.location = '/'
})

router.on('stateError', error => {
	console.error('stateError', error)
})

router.on('stateChangeEnd', state => {
	progress.complete()
	if (state.data && state.data.title) {
		window.document.title = `MCS OnTrack | ${state.data.title}`
	}
})

function interceptClickEvent(e) {
	if (getStoreValue(formHasChanges)) {
		let target = e.target || e.srcElement
		if (target.tagName !== 'A') target = target.closest('a')
		if (target?.tagName === 'A' && !target.getAttribute('target')?.includes('_blank') && target.getAttribute('href')?.includes('#/app')) {
			e.preventDefault()
			e.stopPropagation()
			const changesWidget = new FormHasChanges({ target: document.querySelector('body') })
			changesWidget.$on('stay', () => {
				changesWidget?.$destroy()
			})
			changesWidget.$on('navigate', () => {
				formHasChanges.set(false)
				changesWidget.$destroy()
				window.location = target.getAttribute('href')
			})
		}
	}
}
if (document.addEventListener) {
	document.addEventListener('click', interceptClickEvent)
} else if (document.attachEvent) {
	document.attachEvent('onclick', interceptClickEvent)
}

router.on('stateChangeStart', (state, parameters) => {
	if (progress) progress.start()
	if (errorWidget) errorWidget.$destroy()
	const previousState = stateChangeHistory[stateChangeHistory.length - 1]
	const previousStateName = previousState && previousState.state.name && previousState.state.name
	if (shouldScrollToTop(state.name, previousStateName)) {
		window.scrollTo(0, 0)
	}
	stateChangeHistory.push({ state, parameters })
})

router.on('stateChangeError', error => {
	console.debug('stateChangeError', error)
	if (error.status === 403 || error.status === 401) {
		const previousState = stateChangeHistory[stateChangeHistory.length - 1]
		if (previousState) {
			router.go('login', {
				original: JSON.stringify({
					name: previousState.state.name,
					params: previousState.parameters,
				}),
			})
		} else {
			router.go('login')
		}
	} else {
		errorWidget = new StateChangeError({
			target: document.querySelector('body'),
			props: {
				errors: error.body && error.body.errors || [ error ],
				request: error.request,
			},
		})
		console.error('stateChangeError', error)
	}
})

router.evaluateCurrentRoute('login')
