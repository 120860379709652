import template from './Overview.svelte'
import { getCompanyById } from '@/lib/controller/company.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { companyId }) => ({
		companyId,
		form: load(await getCompanyById({ get }, companyId), 0),
	}),
}
