<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import IdString from '@/component/atom/IdString.svelte'
	import NavHeaderEditStatus from './NavHeaderEditStatus.svelte'
	import { opportunityToStateAndIcon } from '@/lib/opportunity-to-state-and-icon.js'
	import { opportunityStatusToLink } from '@/lib/high-level-links.js'
	import { statusToTitle } from '../../status-to-title.js'
	import { soonestExpirationState } from '@/lib/central-dispatch-leg-order-expiration.js'
	// import { currentUser } from '@/service/store/current-user.js'

	const makeLink = (name, opportunityId) => asr.makePath(`app.opportunities.edit.opportunityId.${name}`, { opportunityId }, { inherit: false })

	export let asr
	export let form
	export let legId
	export let users
	export let usersById
	export let activeTab
	export let justCreated
	export let disableChanges

	$: status = form.data[form.primaryId]?.attributes?.status
	$: asrLink = opportunityStatusToLink[status]
	$: title = statusToTitle[status]
	$: stateAndIcon = opportunityToStateAndIcon({ data: form.data[form.primaryId], includedById: form.data })
	$: overviewLink = makeLink('overview', form.primaryId)
	$: editLink = makeLink('edit', form.primaryId)
	$: historyLink = makeLink('history', form.primaryId)
	$: communicationLink = makeLink('communication', form.primaryId)
	$: accountingSheetLink = makeLink('accountingSheet', form.primaryId)
	$: assignedUser = usersById[form.original[form.primaryId]?.relationships?.user?.data?.id]
	$: expirationState = soonestExpirationState({
		opportunity: form.original[form.primaryId],
		includedById: form.original,
		nowMillis: Date.now(),
	})
</script>

<FormHeaderMain slim>
	<div class="row">
		<div class="col-auto">
			<h5 class="mb-0 mt-1 ms-3">
				{#if asrLink}
					<a href={asr.makePath(asrLink.link, asrLink.params, { inherit: false })}>
						{title}
					</a>
				{:else}
					{title || 'Opportunity'}
				{/if}
				<NamedIcon name="breadcrumb" class="ms-2 me-2" />
				<!--
				Note: the weird wrapping here is because on Windows+Chrome, double clicking
				would select the ID string *and* the trailing whitespace.
				-->
				Edit <p class="m-0" style="display: inline-block;"><IdString resource={form.data[form.primaryId]} fallback="N/A" /></p>
				{#if form.data[form.primaryId]?.attributes?.archived}
					<span class="badge bg-warning ms-3">
						<span class="fa fa-archive"></span>
						Archived
						{#if form.data[form.primaryId]?.meta?.importSource === 'jtracker'}
							from jTracker
						{/if}
					</span>
				{:else if stateAndIcon?.length}
					{#each stateAndIcon as { state, icon, id, style }}
						<span
							class="badge ms-3 {id === legId ? (style || 'bg-primary') : 'bg-light text-dark'}"
							style={id === legId ? '' : 'background-color: #e4e6ee !important;'}
						>
							<NamedIcon name={icon} />
							{state}
						</span>
					{/each}
				{/if}
				{#if assignedUser}
					<span class="badge bg-light text-dark ms-3" style="background-color: #e4e6ee !important;">
						<MaybeLink href={asr.makePath('app.users.edit.userId', { userId: assignedUser.id })}>
							<NamedIcon name="user" />
							{assignedUser.attributes.name || 'N/A'}
						</MaybeLink>
					</span>
				{/if}
				{#if justCreated}
					<span class="badge bg-success">Created new Opportunity 🎉</span>
				{/if}
				{#if expirationState?.message}
					<span class="badge ms-3 bg-{expirationState.color}">
						{#if expirationState.expired}
							Expired Leg
						{:else}
							Leg Expiring Soon
						{/if}
					</span>
				{/if}
			</h5>
		</div>
		{#if activeTab !== 'logCreditCard'}
			<div class="col-auto ms-auto">
				<NavHeaderEditStatus
					bind:form
					{disableChanges}
					{users}
				/>
			</div>
		{/if}
	</div>
	<div class="row" slot="nav">
		<div class="col-auto">
			<ul class="nav nav-pills">
				<li class="nav-item">
					<a class="nav-link" href={overviewLink} class:active={activeTab === 'overview'}>Overview</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href={editLink} class:active={activeTab === 'edit'}>Edit</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href={communicationLink} class:active={activeTab === 'communication'}>Communication</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href={historyLink} class:active={activeTab === 'history'}>History</a>
				</li>
				{#if [ 'order', 'delivered' ].includes(status)}
					<li class="nav-item">
						<a class="nav-link" href={accountingSheetLink} class:active={activeTab === 'accountingSheet'}>Accounting Sheet</a>
					</li>
				{/if}
			</ul>
		</div>
		<div class="col">
		</div>
		<div class="col-auto">
			<a class="btn btn-link" href={asr.makePath('app.systemSettings.jobs.list', { opportunityId: form.primaryId }, { inherit: false })}>
				Jobs
			</a>
		</div>
	</div>
</FormHeaderMain>

<FormErrorsOther {form} />
