import template from '@/component/BlankUiView.svelte'
import { redirectIfLackingPermission } from '@/lib/redirect-if-lacking-permission.js'
import { MCS_STAFF } from '@/shared/value/user-permissions.js'

export default {
	data: {
		title: 'Companies',
	},
	template,
	defaultChild: 'list',
	resolve: async () => redirectIfLackingPermission(MCS_STAFF),
}
