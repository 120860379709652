<script>
	import { createEventDispatcher } from 'svelte'
	import { opportunityColumns } from '@/shared/value/report-columns.js'

	const columnKeyToDefinition = opportunityColumns({})
	const dispatch = createEventDispatcher()

	export let above
	export let index
</script>

<style>
	button.add-row {
		position: absolute;
		left: calc(50% - 20px);
		padding: 2px 12px;
		z-index: 1; /* pull them over the top of the list items */
	}
	button.add-row:not(:hover) {
		background-color: #fff;
	}
	button.add-row-above {
		top: -20px;
	}
	button.add-row-below {
		bottom: -24px;
	}
</style>

<div class="dropdown">
	<button
		class="btn btn-sm btn-outline-secondary add-row dropdown-toggle"
		type="button"
		id="add-new-column-{index}"
		data-bs-toggle="dropdown"
		aria-expanded="false"
		class:add-row-above={above}
		class:add-row-below={!above}
	>
		<span class="fa fa-plus"></span>
	</button>
	<ul class="dropdown-menu" aria-labelledby="add-new-column-{index}">
		{#each Object.keys(columnKeyToDefinition).sort() as columnKey}
			<li>
				<button class="dropdown-item" on:click={() => dispatch('add', columnKey)}>
					<strong>{columnKeyToDefinition[columnKey]?.label || 'UNLABELED COLUMN PLEASE FIX'}</strong>
					{#if columnKeyToDefinition[columnKey]?.description}
						{columnKeyToDefinition[columnKey].description}
					{/if}
				</button>
			</li>
		{/each}
	</ul>
</div>
