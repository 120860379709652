<script>
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate
	export let hideArrows
	export let inputMinWidth

	export let type = 'text'
	export let placeholder = ''
	export let size = ''

	let clazz = ''
	export { clazz as class }

	$: elementId = [ id, ...keypath ].join('.')

	const INT_TYPES = [ 'number', 'month' ]
	const FLOAT_TYPES = [ 'double' ]

	const cast = value => {
		if (value === '') return undefined
		else if (INT_TYPES.includes(type)) return parseInt(value, 10)
		else if (FLOAT_TYPES.includes(type)) return parseFloat(value)
		return value
	}
</script>

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	<input
		readonly={readonly || disabled}
		type={hideArrows && INT_TYPES.includes(type) ? 'text' : type}
		{placeholder}
		{value}
		on:input={ event => { (form.data[id] || onCreate(create)); set(cast(event.target.value)) } }
		on:*
		style:min-width={inputMinWidth}
		class="form-control {size ? `form-control-${size}` : ''} {clazz || 'wat'}"
		class:is-invalid={errors?.length || clazz?.includes('is-invalid')}
		id={elementId}
	>
</Field>
