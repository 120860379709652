import { writable } from 'svelte/store'

export const formHasChanges = writable()

export const updateChanges = changes => formHasChanges.set(
	Object.keys(changes || {}).filter(key => {
		return changes[key].filter(change => {
			return !(change.op === 'add' && change.path?.length && change.value === undefined)
		}).length
	}).length > 0,
)
