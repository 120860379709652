<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import PasswordResetForm from '../../../mixins/authentication/PasswordResetForm.svelte'
	import deepmerge from 'deepmerge'

	export let system
	export let config
	export let form = {
		reset: {},
	}

	$: opts = deepmerge(
		{
			reset: {
				password: { placeholder: 'New Password', label: 'Password' },
				confirmPassword: { placeholder: 'Confirm', label: 'Confirm Password' },
			},
		},
		config || {},
	)

</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout}>
		<h5 class="text-center">
			Reset new password
		</h5>
		<PasswordResetForm
			class="mt-3"
			name="reset"
			on:input
			on:submit
			{system}
			{form}
			config={config.reset}
		/>
	</LayoutBasic>
</LayoutTheme>
