import { BadRequest } from '../exceptions.js'
import { errorFormatter } from './error-formatter.js'
import { opportunityToPerLegDocument } from './opportunity-to-per-leg-documents.js'

const recursiveAddChanges = (changes, keypathPrefix, original, updated) => {
	for (const keypath in original) {
		if (Array.isArray(original[keypath])) {
			if (original[keypath].length !== updated[keypath].length) {
				changes.push({
					keypath: [ ...keypathPrefix, keypath ],
					original: `${original[keypath].length} items`,
					updated: `${updated[keypath].length} items`,
				})
			}
			let itemIndex = 0
			for (const originalItem of original[keypath]) {
				if (itemIndex < updated[keypath].length) {
					recursiveAddChanges(changes, [ ...keypathPrefix, keypath, itemIndex ], originalItem, updated[keypath][itemIndex] || {})
					itemIndex++
				}
			}
		} else if (typeof original[keypath] === 'object') {
			recursiveAddChanges(changes, [ ...keypathPrefix, keypath ], original[keypath], updated[keypath])
		} else {
			if (original[keypath] !== updated[keypath]) changes.push({
				keypath: [ ...keypathPrefix, keypath ],
				original: original[keypath],
				updated: updated[keypath],
			})
		}
	}
}

const PUSHABLE_LEG_STATUSES = {
	post: true,
	assign: true,
	remind: true,
	dispatch: true,
	receive: true,
}

const DISPATCHABLE_LEG_STATUSES = {
	// everything same as push except not for `post`
	assign: true,
	remind: true,
	dispatch: true,
	receive: true,
}

export const changesSyncableToCentralDispatch = ({
	original: { data: originalData, includedById: originalIncludedById },
	updated: { data: updatedData, includedById: updatedIncludedById },
	otherIncludedById,
}) => {
	const originalLegs = originalData.relationships?.legs?.data?.map(rel => originalIncludedById[rel.id])

	const warnings = []
	if (originalLegs.find(leg => leg.attributes.status !== updatedIncludedById[leg.id]?.attributes?.status)) warnings.push(
		errorFormatter(new BadRequest('Changing leg status is not supported here.')),
	)
	if (warnings.length) return { warnings }

	const legIdsPushable = []
	for (const leg of originalLegs)
		if (PUSHABLE_LEG_STATUSES[leg.attributes.status])
			legIdsPushable.push(leg.id)

	const overallErrors = []
	const overallChangesPerLeg = []
	for (const legId of legIdsPushable) {
		const legStatus = originalIncludedById[legId].attributes.status
		const { errors: originalErrors, legDocument: originalLegDocument } = opportunityToPerLegDocument({
			specificLegId: legId,
			opportunity: { data: originalData, includedById: originalIncludedById },
			otherIncludedById,
			legStatus,
			forDispatch: DISPATCHABLE_LEG_STATUSES[legStatus],
		})
		const { errors: updatedErrors, legDocument: updatedLegDocument } = opportunityToPerLegDocument({
			specificLegId: legId,
			opportunity: { data: updatedData, includedById: updatedIncludedById },
			otherIncludedById,
			legStatus,
			forDispatch: DISPATCHABLE_LEG_STATUSES[legStatus],
		})
		if (originalErrors?.length) overallErrors.push(...originalErrors)
		if (updatedErrors?.length) overallErrors.push(...updatedErrors)
		if (!originalErrors?.length && !updatedErrors?.length) {
			const changes = []
			recursiveAddChanges(changes, [], originalLegDocument, updatedLegDocument || {})
			if (changes.length) overallChangesPerLeg.push({ legId, changes })
		}

	}
	if (overallErrors.length) return { errors: overallErrors }

	return { changes: overallChangesPerLeg }
}
