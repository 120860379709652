<script>
	import TableHeaderItem from './TableHeaderItem.svelte'
	import { statusToTitle } from '../status-to-title.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { opportunityCounts } from '@/service/store/opportunity-counts.js'
	import { opportunityStatusToLink } from '@/lib/high-level-links.js'
	import { opportunityLegStatusToIcon } from '@/lib/opportunity-leg-status-to-icon.js'

	const GROW = []

	export let asr
	export let params
	export let users
	export let allUsersSelected

	$: opportunityStatusToTabs = {
		lead: [
			[ 'Active', 'active', 'lead', $opportunityCounts.lead.active ],
			GROW,
			[ 'Cancelled', 'cancelled', 'cancel' ],
		],
		quote: [
			[ 'Active', 'active', 'quote', $opportunityCounts.quote.active ],
			GROW,
			[ 'Held', 'held', 'hold', $opportunityCounts.quote.held ],
			[ 'Cancelled', 'cancelled', 'cancel' ],
		],
		order: [
			// all these will show opportunities that have one leg X and one leg not X
			[ 'Unposted', 'unposted', opportunityLegStatusToIcon.unpost, $opportunityCounts.order.unpost ],
			[ 'Posted to CD', 'posted', opportunityLegStatusToIcon.post, $opportunityCounts.order.post ],
			[ 'Not Signed', 'unsigned', opportunityLegStatusToIcon.assign, $opportunityCounts.order.assign ],
			[ 'Dispatched', 'dispatched', opportunityLegStatusToIcon.dispatch, $opportunityCounts.order.dispatch ],
			[ 'Picked Up', 'received', opportunityLegStatusToIcon.receive, $opportunityCounts.order.receive ],
			[ 'Delivered', 'delivered', opportunityLegStatusToIcon.deliver, $opportunityCounts.order.deliver ],
			GROW,
			[ 'Held', 'held', 'hold', $opportunityCounts.order.held ],
			[ 'Cancelled', 'cancelled', 'cancel' ],
		],
		delivered: [
			[ 'Delivered', 'delivered', 'delivered', $opportunityCounts.delivered.delivered ],
		],
	}
	$: asrLink = opportunityStatusToLink[params.opportunityStatus]
	$: tabs = opportunityStatusToTabs[params.opportunityStatus] || []
	$: title = statusToTitle[params.opportunityStatus] || ''
	$: mutedIndex = tabs.findIndex(t => !t[0])
	$: selectedAssignedUserId = (params['filter[userId]'] || $currentUser.id)
	$: selectedUser = users?.find(user => user.id === selectedAssignedUserId)
	$: filteredUsers = (users || []).filter(user => allUsersSelected || user.id !== selectedUser?.id)
</script>

<div class="row">
	<div class="col-auto mt-2 pe-0">
		<h4>{title}</h4>
	</div>
	{#if params.opportunityStatus === 'lead' || params.opportunityStatus === 'quote'}
		<div class="col-auto pe-0">
			<div class="input-group input-group-sm mt-2">
				<span class="input-group-text" id="inputGroup-sizing-sm">
					{allUsersSelected ? 'All Users' : selectedUser?.attributes?.name}
				</span>
				<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					Filter by Assigned
				</button>
				<ul class="dropdown-menu dropdown-menu-end">
					{#if !allUsersSelected}
						<li>
							<a
								class="dropdown-item"
								href={asr.makePath('app.opportunities.list.opportunityStatus', { ...asrLink.params, ['filter[userId]']: undefined }, { inherit: true })}
							>
								All Users
							</a>
						</li>
						<li><hr class="dropdown-divider"></li>
					{/if}
					{#each filteredUsers as user}
						<li>
							<a
								class="dropdown-item"
								href={asr.makePath('app.opportunities.list.opportunityStatus', { ...asrLink.params, ['filter[userId]']: user.id }, { inherit: true })}
							>
								{user.attributes.name}
							</a>
						</li>
					{/each}
				</ul>
			</div>
		</div>
	{/if}
	<div class="col pe-1">
		<ul class="nav nav-tabs" role="tablist">
			{#each tabs as [ title, linkedTab, iconName, count ], index}
				{#if !title}
					<li class="nav-item flex-grow-1"></li>
				{:else}
					<TableHeaderItem
						{asr}
						{title}
						{count}
						opportunityStatus={params.opportunityStatus}
						{linkedTab}
						{iconName}
						muted={index >= mutedIndex}
						currentTab={params.tab}
					/>
				{/if}
			{/each}
		</ul>
	</div>
</div>
