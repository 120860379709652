import template from './Overview.svelte'
import { getUserById } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { userId }) => {
		const { data, included } = await getUserById({ get }, userId, { include: 'sessions' })
		return {
			form: load({ data }, 0),
			history: (included || []).filter(resource => resource.type === 'session'),
		}
	},
}
