<script>
	export let searchType
	export let label
</script>

<style>
	.form-check-input {
		margin-right: 0.3em;
	}
</style>

<span class="input-group-text justify-content-center">
	<input class="form-check-input mt-0" type="radio" bind:group={searchType} value={label.toLowerCase()}>
	{label}
</span>
