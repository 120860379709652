<script>
	import SimpleText from './SimpleText.svelte'
	export let row
	export let includedById
	$: customerContactId = row?.relationships?.customerContact?.data?.id
	$: customer = includedById[customerContactId]?.attributes || {}
	$: customerName = customer.name || `${customer.firstName || ''} ${customer.lastName || ''}`.trim()
</script>

{#if customer.companyName}
	<strong>
		<SimpleText text={customer.companyName} />
	</strong><br>
{/if}

<SimpleText text={customerName} br />
<SimpleText text={customer.phonePrimary} br />
<SimpleText text={customer.emailPrimary} />
