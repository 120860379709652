<script>
	import ErrorRow from './ErrorRow.svelte'
	export let exe
	$: millis = new Date(exe.end).getTime() - new Date(exe.start).getTime()
	let rowStacktrace
	const toggleStacktrace = event => {
		rowStacktrace = rowStacktrace ? false : event.detail
	}
</script>

<tr>
	<td>{exe.messageId}</td>
	<td>{exe.start}</td>
	<td>{exe.end}</td>
	<td>{millis}ms</td>
	<td>
		{#each (exe.errors || []) as error}
			<ErrorRow {error} on:yolo={toggleStacktrace} />
		{/each}
	</td>
</tr>

{#if rowStacktrace}
	<tr>
		<td colspan="5">
			<pre>{rowStacktrace}</pre>
		</td>
	</tr>
{/if}
