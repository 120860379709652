<script>
	import Logo from '../mixins/common/Logo.svelte'
	let className = ''
	export let system
	export let config = {}
	export { className as class }
</script>

<div class="{config && config.container || 'container-xxl'}">
	<div class="row flex-center min-vh-100 py-6 {className}">
		<div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
			<a href="{$system.paths['default-dashboard']}" class="d-flex flex-center mb-4">
				<Logo {system} icon={config.icon} title={config.title} class="fw-bolder fs-5 d-inline-block" />
			</a>
			<div class="card">
				<div class="card-body p-4 p-sm-5">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</div>
