<style>
	.chevron1 {
		color: #f16927;
	}
	.chevron2 {
		margin-left: -0.95em;
		color: #f79521;
	}
	:global(.text-muted .central-dispatch-icon .chevron1) {
		color: #ec9974;
	}
	:global(.text-muted .central-dispatch-icon .chevron2) {
		color: #ecb27a;
	}
</style>

<span class="central-dispatch-icon">
	<span class="chevron1">
		<span class="fa fa-chevron-right"></span>
	</span>
	<span class="chevron2">
		<span class="fa fa-chevron-right"></span>
	</span>
</span>
