<script>
	import { onMount } from 'svelte'
	import { keyBy } from '@/shared/util/key-by.js'
	import { groupBy } from '@/shared/util/group-by.js'
	import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

	import Navbar from './_QbChangesNavbar.svelte'
	import CompletedRow from './completed/CompletedRow.svelte'

	export let api
	export let asr

	let loading = true
	let data
	let errors
	let includedById
	let historyByQbSyncId

	onMount(() => {
		api
			.get('/api/v1/qbSyncs' + objectToJsonApiQuery({ include: [ 'history', 'user' ] }))
			.then(response => {
				data = response.body.data
				includedById = keyBy(response.body.included, 'id')
				historyByQbSyncId = groupBy(response.body.included.filter(inc => inc.type === 'history'), 'attributes.qbSyncId')
				loading = false
			})
			.catch(response => {
				errors = response.body.errors
			})
	})

</script>

<Navbar {asr} current="completed" />

<div class="card mb-3">
	<div class="card-body p-0">
		<table class="table table-sm mb-0">
			<thead class="bg-200 text-900">
				<tr>
					<th><!-- icon --></th>
					<th>Resource</th>
					<th>Synced</th>
					<th>User</th>
					<th>Up-To</th>
					<th>History</th>
				</tr>
			</thead>
			<tbody>
				{#if loading || !data?.length}
					<tr>
						<td colspan="5">
							<p class="text-center my-3" class:text-muted={loading}>
								{#if loading}
									Loading...
								{:else}
									No previous QuickBook syncs found. 🤔
								{/if}
							</p>
						</td>
					</tr>
				{:else if data?.length}
					{#each data as sync (sync.id)}
						<CompletedRow
							{asr}
							{sync}
							{includedById}
							{historyByQbSyncId}
						/>
					{/each}
				{/if}
			</tbody>
		</table>
	</div>
</div>
