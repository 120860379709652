import tinydate from 'tinydate'

const stamps = {
	MMMM: d => d.toLocaleString('default', { month: 'long' }),
	MMM: d => d.toLocaleString('default', { month: 'short' }),
	D: d => d.getDate(),
	hh: d => d.getHours() % 12,
	A: d => d.getHours() < 12 ? 'AM' : 'PM',
}

export const dateFormatter = date => tinydate(date, stamps)
