<script>
	import Table from '@/component/Table.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'name',
		'active',
		'tag',
		'item',
		'zap',
		'code',
		'key',
	]

	const nameToColumn = {
		name: {
			title: 'Name',
			component: PlainLink,
			keypath: 'attributes.name',
			link: row => asr.makePath('app.referrers.edit.referrerId', { referrerId: row.id }),
			sortable: true,
		},
		zap: {
			title: 'Zap Name',
			component: PlainText,
			keypath: 'attributes.zapName',
		},
		key: {
			title: 'Old BATS AuthKey',
			component: PlainText,
			keypath: 'attributes.key',
		},
		code: {
			title: 'Code',
			component: PlainText,
			keypath: 'attributes.code',
		},
		active: {
			title: 'Active',
			component: PlainText,
			keypath: 'attributes.active',
		},
		tag: {
			title: 'Tag',
			component: PlainText,
			sortable: true,
			keypath: 'attributes.tag',
		},
		item: {
			title: 'QB Item',
			component: PlainText,
			sortable: true,
			keypath: 'attributes.accountingId',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	hover
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
