import { enumDescription } from '../util/enum-description.js'
import { jobStatuses } from '../value/job-statuses.js'
import { jsonApiRelationshipSingular, jsonApiRelationshipList } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	user: false,
	receivedEmail: false,
	opportunity: false,
	leg: false,
	cdRequests: false,
	sendEmailTemplate: false,
}

export const type = 'job'

export default {
	description: 'Long running jobs, like generating reports or posting opportunities to Central Dispatch.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the job.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			required: [ 'parameters' ],
			properties: {
				status: {
					description: 'Current status of the long running job. Available statuses are:\n\n' + enumDescription(jobStatuses),
					type: 'string',
					enum: Object.keys(jobStatuses),
				},
				executionTimeMs: {
					description: 'The milliseconds of runtime for this job, calculated from the `executions` property.',
					type: 'number',
				},
				executions: {
					type: 'array',
					items: {
						type: 'object',
						additionalProperties: false,
						properties: {
							messageId: {
								description: 'The SQS message identifier that started the job. Used for debugging.',
								type: 'string',
							},
							start: {
								description: 'The ISO-8601 date+time when processing for the job was started.',
								type: 'string',
								format: 'date-time',
							},
							end: {
								description: 'The ISO-8601 date+time when processing for the job was ended.',
								type: 'string',
								format: 'date-time',
							},
							errors: {
								description: 'Any errors thrown when the job was processed.',
								type: 'array',
								items: {
									$ref: '#/components/schemas/error',
								},
							},
						},
					},
				},
				parameters: {
					oneOf: [
						// Every job needs to be added here, and if you're going to allow
						// it to be POSTed to create, add to the `POST /jobs` route as well
						{ $ref: '#/components/schemas/job-central-dispatch-action' },
						{ $ref: '#/components/schemas/job-central-dispatch-import-carrier' },
						{ $ref: '#/components/schemas/job-central-dispatch-pull' },
						{ $ref: '#/components/schemas/job-central-dispatch-push' },
						{ $ref: '#/components/schemas/job-email-accepted' },
						{ $ref: '#/components/schemas/job-email-declined' },
						{ $ref: '#/components/schemas/job-email-picked-up' },
						{ $ref: '#/components/schemas/job-email-delivered' },
						{ $ref: '#/components/schemas/job-email-cancelled' },
						{ $ref: '#/components/schemas/job-email-received' },
						{ $ref: '#/components/schemas/job-example-generate-report' },
						{ $ref: '#/components/schemas/job-opportunity-process-lead' },
						{ $ref: '#/components/schemas/job-opportunity-send-auto-quote-email' },
					],
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'User who created this job.',
					...jsonApiRelationshipSingular('user'),
				},
				receivedEmail: {
					description: 'Reference to the received email associated with this job, if applicable.',
					...jsonApiRelationshipSingular('received-email'),
				},
				opportunity: {
					description: 'Reference to the opportunity associated with this job, if applicable.',
					...jsonApiRelationshipSingular('opportunity'),
				},
				leg: {
					description: 'Reference to the opportunity leg associated with this job, if applicable.',
					...jsonApiRelationshipSingular('leg'),
				},
				cdRequests: {
					description: 'Any requests to Central Dispatch made during these jobs.',
					...jsonApiRelationshipList('cd-request'),
				},
				sendEmailTemplate: {
					description: 'The email template this job is sending.',
					...jsonApiRelationshipSingular('email-template'),
				},
			},
		},
	},
}
