<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import AutoQuoteCampaignForm from '@/component/form/AutoQuoteCampaignForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { previousState } from '@/service/store/router-state.js'
	import { updateAutoQuoteCampaign } from '@/lib/controller/auto-quote-campaign.js'

	export let asr
	export let api
	export let form
	export let emailTemplateOptions

	$: justCreated = $previousState === 'app.systemSettings.autoQuoteCampaigns.create'

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateAutoQuoteCampaign(api, form)
			.then(response => { form = response })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>
		System
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<a href={asr.makePath('app.systemSettings.autoQuoteCampaigns', null, { inherit: false })}>
		 Auto-Quote Campaigns
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Edit {form.data[form.primaryId]?.attributes?.name || 'N/A'}
	</span>
	{#if justCreated}
		<span class="badge bg-success">Created new Campaign 🎉</span>
	{/if}
</FormHeaderMain>

<AutoQuoteCampaignForm bind:form {emailTemplateOptions} />

<FormErrorsOther {form} />

<FormCommitButton {form} on:click={save} />
