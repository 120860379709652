<script>
	import InputSelect from './InputSelect.svelte'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate

	export let short
	export let label
	export let emptySelectLabel
	export let options
	export let labelWidth
	export let icon
</script>

<InputSelect
	on:*
	bind:form
	{id}
	{readonly}
	{short}
	{label}
	{emptySelectLabel}
	{options}
	{labelWidth}
	{icon}
	{keypath}
	onSelect={ (value, { set, create }) => { (form.data[id] || onCreate(create)) && set(value || undefined) } }
/>
