<script>
	import { Form } from 'jsonapi-svelte-form'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import PhysicalAddress from '@/component/molecule/PhysicalAddress.svelte'
	import InputCheckbox from '@/component/atom/InputCheckbox.svelte'
	import InputCheckboxBare from '@/component/atom/InputCheckboxBare.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputType from '@/component/atom/InputType.svelte'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form

	$: contactId = form.data[form.primaryId]?.relationships?.contact?.data?.id
	$: addressId = form.data[form.primaryId]?.relationships?.address?.data?.id
	$: insuranceId = form.data[form.primaryId]?.relationships?.insurance?.data?.id
	$: cdId = form.data[form.primaryId]?.attributes?.centralDispatchId
	$: readonly = Boolean(cdId)

	const createContact = create => create({ relId: form.primaryId, relName: 'contact', type: 'contact' })
	const createAddress = create => create({ relId: form.primaryId, relName: 'address', type: 'address' })
	const createInsurance = create => create({ relId: form.primaryId, relName: 'insurance', type: 'insurance' })
	const createInsuranceAddress = create => {
		if (!insuranceId) createInsurance(create)
		create({ relId: insuranceId, relName: 'address', type: 'address' })
	}
</script>

<Form bind:form let:remove let:create>
	<div class="row gx-2">
		<div class="col-xs-12 col-lg-8 col-xl-6">
			<div class="card mb-3">
				<div class="card-header py-2">
					Company Information
				</div>
				<div class="card-body bg-light py-2">
					<form>
						<div class="row" style="max-width: 30em;">
							<div class="col pe-2">
								<InputType
									type="text"
									label="Central Dispatch ID"
									labelWidth="12em"
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'centralDispatchId' ]}
								/>
							</div>
							{#if cdId}
								<div class="col-auto ps-0 pt-1">
									<ExternalLink url="https://site.centraldispatch.com/protected/rating/client-snapshot?id={cdId}">
										<NamedIcon name="cd" />
										<span style="position: relative; left: -6px;">
											View
										</span>
									</ExternalLink>
								</div>
							{/if}
						</div>
						<div class="row g-3">
							<div class="col-xs-12 col-md-6">
								<p class="mb-2">Company</p>
								<InputType
									type="text"
									icon="company"
									label="Name"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'name' ]}
								/>
								<InputType
									type="text"
									icon="clock"
									label="Hours"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'hoursOpen' ]}
								/>
								<InputType
									type="text"
									label="Tax ID"
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'taxId' ]}
								/>
								<InputType
									type="text"
									label="ICC MC #"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'iccmcNumber' ]}
								/>
								<InputType
									type="text"
									label="Manager"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'ownerManagerName' ]}
								/>
								<InputType
									type="text"
									label="Established"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'yearEstablished' ]}
								/>
								<InputType
									type="text"
									label="CD Since"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'memberSince' ]}
								/>
								<InputType
									type="text"
									label="Trucks"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'truckCount' ]}
								/>
								<InputType
									type="text"
									label="Equipment"
									{readonly}
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'equipmentDescription' ]}
								/>
							</div>
							<div class="col-xs-12 col-md-6">
								<p class="mb-2">Contact</p>
								<InputType
									type="text"
									icon="user"
									label="Name"
									{readonly}
									bind:form
									onCreate={createContact}
									id={contactId}
									keypath={[ 'attributes', 'name' ]}
								/>
								<InputType
									type="text"
									icon="email"
									label="Email"
									bind:form
									onCreate={createContact}
									id={contactId}
									keypath={[ 'attributes', 'emailPrimary' ]}
								/>
								<InputType
									type="text"
									icon="phone"
									label="Primary"
									{readonly}
									bind:form
									onCreate={createContact}
									id={contactId}
									keypath={[ 'attributes', 'phonePrimary' ]}
								/>
								<InputType
									type="text"
									icon="phone"
									label="Alt"
									{readonly}
									bind:form
									onCreate={createContact}
									id={contactId}
									keypath={[ 'attributes', 'phoneTwo' ]}
								/>
								<InputType
									type="text"
									icon="fax"
									label="Fax"
									{readonly}
									bind:form
									onCreate={createContact}
									id={contactId}
									keypath={[ 'attributes', 'fax' ]}
								/>
								<p class="mb-2">Address</p>
								<PhysicalAddress
									{readonly}
									bind:form
									relId={form.primaryId}
									relName="address"
									onCreate={createAddress}
								/>
							</div>
						</div>
						<div class="row gx-2">
							<div class="col-6 col-md-3 col-xl-3">
								<p class="mb-0">Services</p>
								<InputCheckboxBare
									{readonly}
									inline
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'isCarrier' ]}
								>
									Carrier
								</InputCheckboxBare>
								<br>
								<InputCheckboxBare
									inline
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'isShipper' ]}
								>
									Shipping
								</InputCheckboxBare>
								<br>
								<InputCheckboxBare
									inline
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'isTerminal' ]}
								>
									Terminal
								</InputCheckboxBare>
							</div>
							<div class="col-6 col-md-3 col-xl-3">
								<p class="mb-0">Status</p>
								<InputCheckboxBare
									inline
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'inactive' ]}
								>
									Inactive
								</InputCheckboxBare>
								<br>
								<InputCheckboxBare
									inline
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'blocked' ]}
								>
									Blocked
								</InputCheckboxBare>
							</div>
							<div class="col-xs-12 col-md-6">
								<InputTextArea
									label="Notes"
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', 'notes' ]}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="col-xs-12 col-lg-4 col-xl-6">
			<div class="card mb-3">
				<div class="card-header py-2">
					Insurance
				</div>
				<div class="card-body bg-light py-2">
					<form>
						<div class="row g-3">
							<div class="col-xs-12 col-xl-6">
								<p class="mb-2">Company</p>
								<InputType
									type="text"
									icon="company"
									label="Name"
									{readonly}
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'companyName' ]}
								/>
								<p class="mb-2">Direct Agent</p>
								<InputType
									type="text"
									icon="user"
									label="Name"
									{readonly}
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'agentName' ]}
								/>
								<InputType
									type="text"
									icon="phone"
									label="Phone"
									{readonly}
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'agentPhone' ]}
								/>
							</div>
							<div class="col-xs-12 col-xl-6">
								<p class="mb-2">Address</p>
								<PhysicalAddress
									{readonly}
									bind:form
									relId={insuranceId}
									relName="address"
									onCreate={createInsuranceAddress}
								/>
							</div>
							<div class="col-xs-12">
								<p class="mb-2">Policy Details</p>
								<InputType
									type="text"
									label="Policy #"
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'policyNumber' ]}
								/>
								<InputType
									type="text"
									label="Expiration"
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'expiration' ]}
								/>
								<InputType
									type="text"
									label="Limit"
									{readonly}
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'limit' ]}
								/>
								<InputType
									type="text"
									label="Deductible"
									{readonly}
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'deductible' ]}
								/>
								<InputCheckbox
									bind:form
									onCreate={createInsurance}
									id={insuranceId}
									keypath={[ 'attributes', 'certificateHolder' ]}
								>
									Certificate Holder
								</InputCheckbox>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</Form>
