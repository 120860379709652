export default {
	type: 'object',
	required: [
		'jobPath',
	],
	properties: {
		jobPath: {
			type: 'string',
			enum: [ 'opportunity/process-lead' ],
		},
		lead: {
			$ref: '#/components/schemas/incoming-lead',
		},
	},
}
