<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	export let action
	export let confirm
	export let disabled
	export let iconName
	export let width
	export let light
	export let danger

	let showConfirmation
	let actionStyle
	$: {
		if (danger && light) actionStyle = 'btn-outline-danger'
		else if (danger) actionStyle = 'btn-danger'
		else if (light) actionStyle = 'btn-outline-secondary'
		else actionStyle = 'btn-secondary'
	}
</script>

<fieldset {disabled}>
	{#if showConfirmation}
		<slot />
		<p class="mb-1">
			<button class="btn btn-sm btn-secondary" style="width: {width};" on:click={() => showConfirmation = false}>
				Cancel
			</button>
		</p>
		<p class="mb-0">
			<button class="btn btn-sm {danger ? 'btn-danger' : 'btn-primary'}" on:click>
				Confirm {confirm || ''}
			</button>
		</p>
	{:else}
		<button class="btn btn-sm {actionStyle}" style="width: {width};" on:click={() => showConfirmation = true}>
			{#if iconName}
				<NamedIcon name={iconName} />
			{/if}
			{action}
		</button>
	{/if}
</fieldset>
