<script>
	export let current
	export let asr

	const tabs = [
		{
			id: 'outstanding',
			label: 'Outstanding',
		},
		{
			id: 'completed',
			label: 'Completed',
		},
	]
</script>

<ul class="nav nav-tabs mt-2 mb-3">
	{#each tabs as {id, label}}
		<li class="nav-item">
			<a class="nav-link" class:active={current === id} aria-current="page" href={asr.makePath(`app.accounting.qbChanges.${id}`, null, { inherit: false })}>
				{label}
			</a>
		</li>
	{/each}
</ul>
