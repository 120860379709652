export const opportunityFilterableDateTypes = {
	created: 'The date the opportunity was created.',
	quoted: 'The most recent date this opportunity was converted to a quote.',
	ordered: 'The most recent date this opportunity was converted into an order.',
	firstAvailablePickup: 'The first available pickup, from the overall opportunity.',
	firstDispatched: 'The date that the first leg became fully dispatched.',
	anyDispatched: 'The date that any leg became fully dispatched.',
	firstReceivedContract: 'The contractual pickup date of the first leg, ignoring the date type (terms).',
	firstReceivedActual: 'The actual pickup date of the first leg.',
	anyReceivedContract: 'The contractual pickup date for any leg, ignoring the date type (terms).',
	anyReceivedActual: 'The actual pickup date for any leg.',
	lastDeliveredContract: 'The contractual dropoff date of the last leg, ignoring the date type (terms).',
	lastDeliveredActual: 'The actual dropoff date of the last leg, ignoring the date type (terms).',
	anyDeliveredContract: 'The contractual dropoff date of any leg, ignoring the date type (terms).',
	anyDeliveredActual: 'The actual dropoff date of any leg, ignoring the date type (terms).',
	// firstInvoiced: 'The earliest date that any CC transaction was processed successfully for the opportunity.',
}

export const dateTypeToLabel = {
	created: 'Created',
	quoted: 'Quoted',
	ordered: 'Ordered',
	firstAvailablePickup: 'First Available Pickup',
	// firstInvoiced: 'First Invoiced',
	anyDispatched: 'Dispatched (Any Leg)',
	firstDispatched: 'Dispatched (First Leg)',
	anyReceivedActual: 'Pick-Up Date, Actual (Any Leg)',
	firstReceivedActual: 'Pick-Up Date, Actual (First Leg)',
	anyReceivedContract: 'Pick-Up Date, Contractual (Any Leg)',
	firstReceivedContract: 'Pick-Up Date, Contractual (First Leg)',
	anyDeliveredActual: 'Delivery Date, Actual (Any Leg)',
	lastDeliveredActual: 'Delivery Date, Actual (Last Leg)',
	anyDeliveredContract: 'Delivery Date, Contractual (Any Leg)',
	lastDeliveredContract: 'Delivery Date, Contractual (Last Leg)',
}

export const keyToKeypathMapper = {
	created: 'meta.created',
	quoted: 'attributes.quoted',
	ordered: 'attributes.ordered',
	firstAvailablePickup: 'attributes.firstAvailablePickup',
	// firstInvoiced: 'attributes.firstInvoiced',
	firstDispatched: 'attributes.legDates.firstDispatched',
	anyDispatched: '_included.attributes.dates.dispatch',
	firstReceivedContract: 'attributes.legDates.firstReceivedContract',
	firstReceivedActual: 'attributes.legDates.firstReceivedActual',
	anyReceivedContract: '_included.attributes.pickupDate',
	anyReceivedActual: '_included.attributes.actualPickup',
	lastDeliveredContract: 'attributes.legDates.lastDeliveredContract',
	lastDeliveredActual: 'attributes.legDates.lastDeliveredActual',
	anyDeliveredContract: '_included.attributes.dropoffDate',
	anyDeliveredActual: '_included.attributes.actualDropoff',
}
