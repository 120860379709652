<script>
	export let row
</script>

{#if row?.attributes?.ipAddress}
	{row.attributes.ipAddress}
{/if}
{#if row?.attributes?.cfCountry}
	({row.attributes.cfCountry})
{/if}
