<script>
	import SpreadsheetDownloader from '@/component/atom/SpreadsheetDownloader.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'

	import DateRangePicker from './_DateRangePicker.svelte'
	import TableColumnDescriptions from './_TableColumnDescriptions.svelte'

	export let asr
	export let columns
	export let columnOverrides
	export let orderedColumns
	export let label
	export let tableId
	export let rowCount
	export let generateSpreadsheetFile
	export let selectableDateRanges
	export let dateRange
	export let querystringParams
	export let cloneReport

	let helpOpen

	$: cloneParams = cloneReport && { clone: JSON.stringify(cloneReport) }
</script>

<div class="card">
	<div class="card-header py-2">
		<h3>
			<a href={asr.makePath('app.reports', null, { inherit: false })}>Reports</a>
			<small class="fa fa-chevron-right"></small>
			{label}
			{#if rowCount}
				<small class="text-muted">{rowCount}</small>
			{/if}
			{#if $$slots.description}
				<button class="btn btn-sm btn-outline-secondary ms-4" on:click={() => { helpOpen = !helpOpen }}>
					<span class="fa fa-question"></span>
				</button>
			{/if}
			<SpreadsheetDownloader
				disabled={!rowCount}
				generator={generateSpreadsheetFile}
			/>
			<CopyToClipboard
				tableId={rowCount && tableId}
			/>
			{#if cloneParams}
				<a
					class="btn btn-sm btn-outline-secondary ms-4"
					target="_blank"
					href={asr.makePath('app.customReports.create', cloneParams, { inherit: false })}
				>
					<span class="fa fa-clone"></span>
				</a>
			{/if}
		</h3>
		<DateRangePicker
			{asr}
			{querystringParams}
			{dateRange}
			{selectableDateRanges}
		/>
	</div>
</div>

{#if helpOpen}
	<div class="card mt-3">
		<div class="card-body">
			<slot name="description" />
		</div>
	</div>
	{#if columns}
		<div class="card mt-3">
			<div class="card-body">
				<TableColumnDescriptions {columnOverrides} {columns} {orderedColumns} />
			</div>
		</div>
	{/if}
{/if}
