export default {
	description: 'A reference to a different record.',
	type: 'object',
	additionalProperties: false,
	required: [
		'id',
		'type',
	],
	properties: {
		id: {
			type: 'string',
		},
		type: {
			type: 'string',
		},
	},
}
