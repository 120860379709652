import template from '@/component/BlankUiView.svelte'
import { listEmailTemplates } from '@/lib/controller/email-template.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Auto-Quote Campaigns',
	},
	template,
	defaultChild: 'list',
	resolve: async () => {
		const { data: emailTemplates } = await listEmailTemplates({ get }, { filter: { autoQuote: true } })
		return {
			emailTemplateOptions: emailTemplates.reduce((map, { id, attributes: { name } }) => {
				map[id] = name
				return map
			}, {}),
		}
	},
}
