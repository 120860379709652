const typeToPath = {
	company: 'app.companies.edit.companyId.overview',
	note: 'app.notes.edit.noteId',
	opportunity: 'app.opportunities.edit.opportunityId',
	referrer: 'app.referrers.edit.referrerId',
	user: 'app.users.edit.userId',
	emailTemplate: 'app.systemSettings.emailTemplates.edit.emailTemplateId',
	job: 'app.systemSettings.jobs.byId.jobId',
}

const typeToParam = {
	company: 'companyId',
	note: 'noteId',
	opportunity: 'opportunityId',
	referrer: 'referrerId',
	user: 'userId',
	emailTemplate: 'emailTemplateId',
	job: 'jobId',
}

export const resourceToLink = (asr, { id, type }, inherit) => {
	if (!typeToPath[type] || !typeToParam[type]) return ''

	const options = {}
	if (inherit !== undefined) options.inherit = inherit

	const params = {}
	params[typeToParam[type]] = id

	return asr.makePath(typeToPath[type], params, options)
}
