import { clone } from './clone.js'
import { keyBy } from './key-by.js'
import { opportunityLegCentralDispatchPostId } from './opportunity-leg-central-dispatch-post-id.js'
import { vehicleType } from '../value/vehicle-type.js'
import { dateType } from '../value/date-type.js'

// const LITERALLY_EVERY_PROPERTY_ON_THE_ASSIGN_FORM = {

// these all just come from the HTML form directly
// 	'encryptedListingId': '',
// 	'acknowledge': undefined,
// 	'dsid': 'L86777921',
// 	'dispatch_sheet_id': '',
// 	'autoId': '1',
// 	'Load_ID': '86777921',
// 	'load_posted': '1',
// 	'load_archived': '0',
// 	'postAnother': '1',
// 	'CSRFToken': 'b4216615a5272e03a5f8c9cc9eee773485d61880032b20f98394c7ece082741f',

// TODO not sure which one means what
// 	'postType': '', Enum<postOnly, dispatchOnly>

// NOTE: these simply carry over from fetching the form, we don't need to set them
// 	'dispatcher[companyName]': 'Mr. Car Shipper, LLC',
// 	'dispatcher[mcnum]': '787159',
// 	'dispatcher[contact]': 'Laurel or Tim',
// 	'dispatcher[address1]': '1620 Wilshire Dr Ste 300',
// 	'dispatcher[address2]': '',
// 	'dispatcher[city]': 'Bellevue',
// 	'dispatcher[valid]': '1',
// 	'dispatcher[state]': 'NE',
// 	'dispatcher[zip]': '68005',
// 	'dispatcher[phone1]': '(877) 563-8836',
// 	'dispatcher[phone2]': '(402) 218-2322',
// 	'dispatcher[fax]': '(402) 215-0760',

// these are sourced from the carrier from Central
// 	'carrier[companyName]': '',
// 	'carrier[id]': 'fdL1Fcq4uVC0YIxv',
// 	'carrier[mcnum]': '',
// 	'carrier[contact]': '',
// 	'carrier[address1]': '',
// 	'carrier[address2]': '',
// 	'carrier[city]': '',
// 	'carrier[valid]': '1',
// 	'carrier[state]': 'FL',
// 	'carrier[zip]': '',
// 	'carrier[phone1]': '',
// 	'carrier[phone2]': '',
// 	'carrier[cell]': '',
// 	'carrier[fax]': '',
// 	'carrier[driverFirstName]': '',
// 	'carrier[driverLastName]': '',
// 	'carrier[driverCell]': '',

// these are sourced from the leg relationships
// 	'origin_addressId': '',
// 	'origin[terminal]': '1',
// 	'origin[company]': '',
// 	'origin[contact]': '',
// 	'origin[buyernum]': '',
// 	'origin[phone1]': '',
// 	'origin[phone2]': '',
// 	'origin[phone3]': '',
// 	'origin[phone4]': '',
// 	'origin[address1]': '',
// 	'origin[address2]': '',
// 	'origin[city]': 'Citrus Heights',
// 	'origin[state]': undefined,
// 	'origin[zip]': '95621',
// 	'origin[valid]': '1',

// these are sourced from the leg relationships
// 	'destination_addressId': '',
// 	'destination[terminal]': '1',
// 	'destination[company]': '',
// 	'destination[contact]': '',
// 	'destination[buyernum]': '',
// 	'destination[phone1]': '',
// 	'destination[phone2]': '',
// 	'destination[phone3]': '',
// 	'destination[phone4]': '',
// 	'destination[address1]': '',
// 	'destination[address2]': '',
// 	'destination[city]': 'Phoenix',
// 	'destination[zip]': '85086',
// 	'destination[state]': undefined,
// 	'destination[valid]': '1',

// these are sourced from the vehicles, with special logic regarding running/trailerType
// 	'vehicles[running]': '0',
// 	'vehicles[trailerType]': undefined,
// 	'vehicles[vehicleDetail][0][id]': '',
// 	'vehicles[vehicleDetail][0][removed]': '',
// 	'vehicles[vehicleDetail][0][inputType]': 'vin0',
// 	'vehicles[vehicleDetail][0][vin]': '',
// 	'vehicles[vehicleDetail][0][vehicle_year]': '2001',
// 	'vehicles[vehicleDetail][0][make]': 'jeep',
// 	'vehicles[vehicleDetail][0][model]': 'tj',
// 	'vehicles[vehicleDetail][0][vehicleTypeOther]': '',
// 	'vehicles[vehicleDetail][0][qty]': '1',
// 	'vehicles[vehicleDetail][0][color]': '',
// 	'vehicles[vehicleDetail][0][plate]': '',
// 	'vehicles[vehicleDetail][0][lotNumber]': '',
// 	'vehicles[vehicleDetail][0][vehicleAdditionalInfo]': undefined,
// 	'vehicles[vehicleDetail][0][vehicle_type]': undefined,
// 	'vehicles[vehicleDetail][0][state]': undefined,

// 	'dates[available]': '10/31/2022',
// 	'dates[desiredDelivery]': '', // NOTE: could not locate on form, so not sure what this does

// 	'dates[pickup][date]': '',
// 	'dates[pickup][type]': undefined,

// 	'dates[delivery][date]': '',
// 	'dates[delivery][type]': undefined,

// 	'price[total]': '600', // "Price to pay Carrier"
// 	'price[payor]': 'dispatcher', // always "dispatcher", a hidden field

// 	'price[cod][amount]': '0', // "COD/COP Amount" always 0 for MCS
// 	'price[cod][method]': undefined, // since COD/COP is 0, this is always unset
// 	'price[cod][where]': undefined, // since COD/COP is 0, this is always unset

// 	'price[balance][amount]': '600', // "Balance Amount" an un-editable field but must be present
// 	'price[balance][method]': undefined, // "Balance Payment Method" always set to "Company Check" for MCS
// 	'price[balance][when]': undefined, // "Balance Payment Time" MCS only allows 10 or 5 business days
// 	'price[balance][where]': undefined, // "Balance Payment Terms Begin On" MCS only allows "Receiving a Signed Bill of Lading"

// 	'userDefined[orderId]': '9710075',
// 	'userDefined[additionalInfo]': 'Must deliver on or after 11/3',
// 	'userDefined[instructions]': undefined,
// 	'userDefined[customerNotes]': undefined, // Never used by MCS

// }

const makeFormError = (detail, meta) => ({
	name: 'InvalidCentralDispatchOpportunity',
	status: 500,
	title: 'The opportunity details are insufficient to post to Central Dispatch.',
	detail,
	meta,
})

const formatDate = isoDatePortion => {
	if (isoDatePortion) {
		const [ year, month, day ] = isoDatePortion.split('-')
		return `${month}/${day}/${year}`
	}
}

const getLocation = (which, leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById, forDispatch) => {
	const locationType = leg.attributes?.[`${which}Type`]
	if (!locationType) errors.push(makeFormError('Location type must be set for all legs.', { legId: leg.id }))

	const makeFormAddress = addressAttributes => {
		if (!addressAttributes) errors.push(makeFormError(`Leg ${which} type was "${locationType}" but the address could not be found or no address was set.`, { legId: leg.id }))
		const address = {
			valid: '1',
			city: addressAttributes?.city,
			state: addressAttributes?.state,
			zip: addressAttributes?.zip?.toString(),
		}
		if (forDispatch) {
			address.address1 = addressAttributes?.line1 || ''
			address.address2 = addressAttributes?.line2 || ''
		}
		return address
	}

	if (locationType === 'custom' || locationType === 'customer') {
		return makeFormAddress(includedById[leg.relationships?.[`${which}Address`]?.data?.id]?.attributes)
	}

	if (which === 'pickup' && locationType === 'previousLeg') {
		if (!legs[legIndex - 1]) {
			errors.push(makeFormError(`Leg ${which} was from previous leg, but cannot find previous leg.`, { legId: leg.id }))
		} else {
			return getLocation('dropoff', legs[legIndex - 1], legs, legIndex - 1, includedById, errors, opportunity, otherIncludedById, forDispatch)
		}
	}

	if (locationType === 'terminal') {
		const terminalId = leg.relationships?.[`${which}Terminal`]?.data?.id
		if (!terminalId) errors.push(makeFormError(`Leg ${which} was marked as a terminal, but no terminal was selected.`, { legId: leg.id }))
		else {
			const terminal = otherIncludedById?.[terminalId]
			if (!terminal) errors.push(makeFormError(`Leg ${which} was marked as a terminal, but the selected terminal could not be found.`, { legId: leg.id, terminalId }))
			else {
				const addressAttributes = otherIncludedById?.[terminal?.relationships?.address?.data?.id]?.attributes
				if (!addressAttributes) errors.push(makeFormError(`Terminal selected for leg ${which} did not have an associated address.`, { legId: leg.id, terminalId }))
				else return makeFormAddress(addressAttributes)
			}
		}
	}
}

const getContact = (which, leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById) => {
	const locationType = leg.attributes?.[`${which}Type`]
	if (!locationType) errors.push(makeFormError('Location type must be set for all legs.', { legId: leg.id }))

	const makeFormContact = (contactAttributes, isTerminal) => {
		if (!contactAttributes) errors.push(makeFormError(`Leg ${which} type was "${locationType}" but the contact could not be found or no contact was set.`, { legId: leg.id }))
		const contact = {
			contact: contactAttributes?.name
				|| [ contactAttributes?.firstName || '', contactAttributes?.lastName || '' ].join(' ').trim()
				|| '',
			phone1: contactAttributes?.phonePrimary || '',
			phone2: contactAttributes?.phoneTwo || '',
			phone3: contactAttributes?.phoneThree || '',
			phone4: contactAttributes?.phoneFour || '',
		}
		if (isTerminal) contact.terminal = '1'
		return contact
	}

	if (locationType === 'customer') return makeFormContact(includedById[opportunity.relationships?.customerContact?.data?.id]?.attributes)

	if (locationType === 'custom') return makeFormContact(includedById[leg.relationships?.[`${which}Contact`]?.data?.id]?.attributes)

	if (which === 'pickup' && locationType === 'previousLeg') {
		if (!legs[legIndex - 1]) {
			errors.push(makeFormError(`Leg ${which} was from previous leg, but cannot find previous leg.`, { legId: leg.id }))
		} else {
			return getContact('dropoff', legs[legIndex - 1], legs, legIndex - 1, includedById, errors, opportunity, otherIncludedById)
		}
	}

	if (locationType === 'terminal') {
		const terminalId = leg.relationships?.[`${which}Terminal`]?.data?.id
		if (!terminalId) errors.push(makeFormError(`Leg ${which} was marked as a terminal, but no terminal was selected.`, { legId: leg.id }))
		else {
			const terminal = otherIncludedById?.[terminalId]
			if (!terminal) errors.push(makeFormError(`Leg ${which} was marked as a terminal, but the selected terminal could not be found.`, { legId: leg.id, terminalId }))
			else {
				const contactAttributes = otherIncludedById?.[terminal?.relationships?.contact?.data?.id]?.attributes
				if (!contactAttributes) errors.push(makeFormError(`Terminal selected for leg ${which} did not have an associated contact.`, { legId: leg.id, terminalId }))
				else return makeFormContact(contactAttributes, true)
			}
		}
	}
}

const centralDispatchCarrierProps = {
	valid: '1', // from CD website form
	companyName: { att: 'name' },
	id: { att: 'centralDispatchId' },
	mcnum: { att: 'iccmcNumber' },
	contact: { rel: 'contact', att: 'name' },
	address1: { rel: 'address', att: 'line1' },
	address2: { rel: 'address', att: 'line2' },
	city: { rel: 'address', att: 'city' },
	state: { rel: 'address', att: 'city' },
	zip: { rel: 'address', att: 'zip' },
	phone1: { rel: 'contact', att: 'phonePrimary' },
	phone2: { rel: 'contact', att: 'phoneTwo' },
	cell: { rel: 'contact', att: 'phoneSms' },
	fax: { rel: 'contact', att: 'fax' },
}

const formSetter = (data, includedById, props, setter) => {
	for (const [ key, prop ] of Object.entries(props)) {
		if (typeof prop === 'string') {
			if (data?.attributes?.[key]) setter(key, data.attributes[key])
		} else {
			const { rel, att } = prop
			if (rel) {
				const relId = data?.relationships?.[rel]?.data?.id
				const relAtt = includedById[relId]?.attributes?.[att]
				if (relAtt) setter(key, relAtt)
			} else {
				if (data?.attributes?.[att]) setter(key, data.attributes[att])
			}
		}
	}
}

export const opportunityToPerLegDocuments = ({ opportunity: { data: opportunity, included, includedById }, otherIncludedById, specificLegId, forDispatch, optional }) => {
	if (Array.isArray(included) && !includedById) includedById = keyBy(included, 'id')

	let errors = []

	let legs = opportunity?.relationships?.legs?.data?.map(l => includedById[l.id]).filter(Boolean) || []
	if (!legs.length) errors.push(makeFormError('Opportunities must have at least one leg.'))

	let vehicles = opportunity?.relationships?.vehicles?.data?.map(v => includedById[v.id]).filter(Boolean) || []
	if (!vehicles.length) errors.push(makeFormError('Opportunities must have at least one vehicle.'))

	const overallDetails = {
		loadId: '',
		dsid: '',
		dispatch_sheet_id: '',
		Load_ID: '',
		load_posted: '',
		load_archived: '',
		carrier: {
			id: '',
			valid: '1',
			state: '',
		},
		origin_addressId: '',
		destination_addressId: '',
		dispatcher: {
			valid: '1',
			state: 'NE',
		},
		postType: forDispatch ? 'dispatchOnly' : 'postOnly',
		acknowledge: 'on',
		autoId: '1',
		origin: {},
		destination: {},
		vehicles: {
			vehicleDetail: [],
		},
		dates: {
			// available: '', // set in leg
			// desiredDelivery: '', only for postings
		},
		price: {
			// total: '', in the leg
			payor: 'dispatcher',
			cod: {
				// always the same
				amount: '0',
				// method: undefined always
				// where: undefined always
			},
			balance: {
				// amount: in the leg
				// when: in the leg, only '10 business days' or '5 business days'
				// always the same
				method: 'Company Check',
				where: 'receiving a signed Bill of Lading',
			},
		},
		userDefined: {
			additionalInfo: '',
		},
	}

	if (!forDispatch) overallDetails.dates.desiredDelivery = ''
	if (forDispatch) overallDetails.load_archived = '0'

	overallDetails.vehicles.running = vehicles.find(v => v.attributes?.doesNotRun)
		? '0'
		: '1'
	overallDetails.vehicles.trailerType = vehicles.find(v => v.attributes?.shippingMethod === 'enclosed')
		? 'Enclosed'
		: 'Open'

	let vehicleIndex = 0
	for (const vehicle of vehicles) {
		const formVehicle = {
			id: '',
			removed: '',
			qty: '',
			color: '',
			plate: '',
			lotNumber: '',
			state: '',
			vehicleAdditionalInfo: '',
		}
		const VEHICLE_CDKEY_TO_OTKEY = {
			make: 'make',
			model: 'model',
			vehicle_type: 'type',
			vin: 'vin',
			color: 'color',
			plate: 'plateNumber',
			state: 'plateState',
			lotNumber: 'lot',
			vehicleTypeOther: 'typeOther',
			vehicleAdditionalInfo: 'note',
		}
		for (const key in VEHICLE_CDKEY_TO_OTKEY) {
			formVehicle[key] = vehicle.attributes?.[VEHICLE_CDKEY_TO_OTKEY[key]] || ''
		}
		// vehicles[vehicleDetail][1][inputType]: ymm1
		formVehicle.inputType = `ymm${vehicleIndex}`
		formVehicle.vehicle_year = vehicle.attributes?.year?.toString()
		const missingKeys = []
		for (const key of [ 'inputType', 'vehicle_year', 'make', 'model', 'vehicle_type' ]) if (!formVehicle[key]) missingKeys.push(key)
		if (missingKeys.length) errors.push(
			makeFormError('One or more required properties are not filled out on a vehicle.', { missingKeys, vehicleId: vehicle.id }),
		)
		if (formVehicle.vehicle_type && !vehicleType[formVehicle.vehicle_type]) errors.push(
			makeFormError('Invalid vehicle type selected.', { vehicleId: vehicle.id, selected: formVehicle.vehicle_type }),
		)
		overallDetails.vehicles.vehicleDetail.push(formVehicle)
		vehicleIndex++
	}

	let legDocuments = []
	let legIndex = 0
	for (const leg of legs) {
		const legDocument = clone(overallDetails)
		const legId = leg.id

		let beforeCount = errors.length
		legDocument.origin = getLocation('pickup', leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById, forDispatch)
		if (!legDocument.origin && errors.length === beforeCount) errors.push(makeFormError('Could not determine a pickup location type for the leg.', { legId }))

		beforeCount = errors.length
		legDocument.destination = getLocation('dropoff', leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById, forDispatch)
		if (!legDocument.destination && errors.length === beforeCount) errors.push(makeFormError('Could not determine a dropoff location type for the leg.', { legId }))

		if (forDispatch) {
			let beforeCount = errors.length
			const pickupContact = getContact('pickup', leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById)
			if (!pickupContact && errors.length === beforeCount) errors.push(makeFormError('Could not determine a pickup location type for the leg.', { legId }))
			else {
				legDocument.origin = Object.assign({}, legDocument.origin, pickupContact)
			}

			beforeCount = errors.length
			const dropoffContact = getContact('dropoff', leg, legs, legIndex, includedById, errors, opportunity, otherIncludedById)
			if (!dropoffContact && errors.length === beforeCount) errors.push(makeFormError('Could not determine a dropoff location type for the leg.', { legId }))
			else {
				legDocument.destination = Object.assign({}, legDocument.destination, dropoffContact)
			}
		}

		const carrierPay = leg.attributes?.carrierPay
		if (!carrierPay) {
			if (!optional?.carrierPay) errors.push(makeFormError('Each leg must have a carrier pay set.', { legId }))
		} else {
			legDocument.price.total = carrierPay.toString()
			legDocument.price.balance.amount = legDocument.price.total
		}
		legDocument.price.balance.when = `${leg.attributes?.businessDays || 10} business days`

		legDocument.userDefined.orderId = opportunityLegCentralDispatchPostId({
			opportunityId: opportunity.id,
			legCount: legs.length,
			legRelIndex: legIndex,
		})
		if (leg.attributes.centralDispatchAdditionalTerms) {
			legDocument.userDefined.additionalInfo = leg.attributes.centralDispatchAdditionalTerms
		}
		if (leg.attributes.centralDispatchSpecialInstructions) {
			legDocument.userDefined.instructions = leg.attributes.centralDispatchSpecialInstructions
		} else {
			legDocument.userDefined.instructions = ''
		}

		let firstAvailablePickup = formatDate(leg.attributes.firstAvailablePickup)
		if (firstAvailablePickup) legDocument.dates.available = firstAvailablePickup
		else if (!optional?.firstAvailableDate) errors.push(makeFormError('Each leg must have a first-available date set.', { legId }))

		if (forDispatch) {
			if (leg.attributes.pickupDate) {
				if (!dateType[leg.attributes.pickupDateType]) errors.push(makeFormError('Could not determine a pickup date type for the leg.', { legId }))
				legDocument.dates.pickup = {
					date: formatDate(leg.attributes.pickupDate),
					type: dateType[leg.attributes.pickupDateType],
				}
			}
			if (leg.attributes.dropoffDate) {
				if (!dateType[leg.attributes.dropoffDateType]) errors.push(makeFormError('Could not determine a delivery date type for the leg.', { legId }))
				legDocument.dates.delivery = {
					date: formatDate(leg.attributes.dropoffDate),
					type: dateType[leg.attributes.dropoffDateType],
				}
			}
			const carrier = otherIncludedById[leg.relationships.shipper?.data?.id]
			if (!optional?.carrier && !carrier) errors.push(makeFormError('Could not find assigned carrier for the leg.', { legId }))
			formSetter(carrier, otherIncludedById, centralDispatchCarrierProps, (key, value) => {
				if (key) legDocument.carrier[key] = value || ''
			})

			legDocument.carrier.driverFirstName = leg.attributes.driverFirstName || ''
			legDocument.carrier.driverLastName = leg.attributes.driverLastName || ''
			legDocument.carrier.driverCell = leg.attributes.driverPhone || ''
		}

		legDocuments.push({ legId, legDocument })
		legIndex++
	}

	if (specificLegId) {
		errors = errors.filter(err => !err.meta?.legId || err.meta.legId === specificLegId)
		legDocuments = legDocuments.filter(ld => ld.legId === specificLegId)
	}

	if (errors.length) return { errors }
	return { legDocuments }
}

export const legDocumentPropertyLabels = {
	loadId: 'Load ID',
	dsid: 'DSID',
	dispatch_sheet_id: 'Dispatch Sheet ID',
	Load_ID: 'Load ID',
	load_posted: 'Load Posted',
	load_archived: 'Load Archived',
	origin_addressId: 'Origin Address ID',
	destination_addressId: 'Destination Address ID',
	postType: 'Post Type',
	acknowledge: 'Acknowledge',
	autoId: 'Automatic ID',
	carrier: {
		id: 'Carrier ID',
		valid: 'Carrier is Valid',
		state: 'Carrier State',
		driverFirstName: 'Driver First Name',
		driverLastName: 'Driver Last Name',
		driverCell: 'Driver Phone',
	},
	dispatcher: {
		valid: 'Dispatcher is Valid',
		state: 'Dispatcher State',
	},
	origin: {
		valid: 'Origin is Valid',
		city: 'Origin City',
		state: 'Origin State',
		zip: 'Origin ZIP',
	},
	destination: {
		valid: 'Destination is Valid',
		city: 'Destination City',
		state: 'Destination State',
		zip: 'Destination ZIP',
	},
	vehicles: {
		running: 'Is Running',
		trailerType: 'Trailer Type',
		vehicleDetail: {
			$: {
				id: 'ID',
				removed: 'Vehicle Removed',
				qty: 'Quantity',
				color: 'Color',
				plate: 'Plate',
				lotNumber: 'Lot Number',
				state: 'State',
				vehicleAdditionalInfo: 'Additional Info',
				inputType: 'Input Type',
				vehicle_year: 'Year',
				make: 'Make',
				model: 'Model',
				vehicle_type: 'Type',
			},
		},
	},
	dates: {
		desiredDelivery: 'Desired Delivery',
		available: 'First Available',
		pickup: {
			date: 'Pickup Date',
			type: 'Pickup Date Type',
		},
		delivery: {
			date: 'Delivery Date',
			type: 'Delivery Date Type',
		},
	},
	price: {
		payor: 'Payor',
		cod: {
			amount: 'Cash On Delivery',
		},
		balance: {
			method: 'Payment Method',
			when: 'Payment Terms (When)',
			where: 'Payment Terms (Where)',
			amount: 'Payment Amount',
		},
		total: 'Total Price',
	},
	userDefined: {
		additionalInfo: 'Additional Terms',
		orderId: 'Posted Order ID',
	},
}

export const opportunityToPerLegDocument = ({ opportunity: { data: opportunity, included, includedById }, otherIncludedById, specificLegId, forDispatch, optional }) => {
	let { legDocuments, errors } = opportunityToPerLegDocuments({
		opportunity: { data: opportunity, included, includedById },
		specificLegId,
		otherIncludedById,
		forDispatch,
		optional,
	})
	if (errors?.length) return { errors }
	return legDocuments.find(l => l.legId === specificLegId)
}
