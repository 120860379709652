<script>
	import { onMount } from 'svelte'
	import Table from '@/component/table/AutoQuoteCampaigns.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { listAutoQuoteCampaigns } from '@/lib/controller/auto-quote-campaign.js'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	export let asr
	export let api
	export let loading = true
	export let data = []
	export let included = []
	export let links
	export let errors

	onMount(() => {
		listAutoQuoteCampaigns(api)
			.then(response => {
				loading = false
				data = [ ...data, ...response.data ]
				included = [ ...included, ...response.included ]
				links = response.links
			})
			.catch(error => {
				errors = Array.isArray(error.errors)
					? error.errors
					: [ error ]
			})
	})
</script>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{api}
	{data}
	{links}
	{errors}
	{included}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>
				<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
				<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
				<span class="me-3">
					Auto-Quote Campaigns
				</span>
			</h4>
		</div>
		<div class="col-auto mt-1">
			<a href={asr.makePath('app.systemSettings.autoQuoteCampaigns.create', null, { inherit: false })} class="btn btn-sm btn-primary text-white">
				<NamedIcon name="add" />
				Add New
			</a>
		</div>
	</div>
</TableCardWrapper>
