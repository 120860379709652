export const addressCountries = {
	'1': 'United States',
	'2': 'Canada',
	'3': 'Mexico',
	'4': 'Afghanistan',
	'5': 'Albania',
	'6': 'Algeria',
	'7': 'Angola',
	'8': 'Antigua and Barbuda',
	'9': 'Argentina',
	'10': 'Armenia',
	'11': 'Aruba',
	'12': 'Australia',
	'13': 'Austria',
	'14': 'Azerbaijan',
	'15': 'Bahamas',
	'16': 'Bahrain',
	'17': 'Bangladesh',
	'18': 'Barbados',
	'19': 'Belarus',
	'20': 'Belgium',
	'21': 'Belgium/Luxembourg',
	'22': 'Belize',
	'23': 'Benin',
	'24': 'Bermuda',
	'25': 'Bhutan',
	'26': 'Bolivia',
	'27': 'Bosnia and Herzegovina',
	'28': 'Botswana',
	'29': 'Brazil',
	'30': 'Brunei Darussalam',
	'31': 'Bulgaria',
	'32': 'Burkina Faso',
	'33': 'Burundi',
	'34': 'Cambodia',
	'35': 'Cameroon',
	'37': 'Cape Verde',
	'38': 'Central African Rep',
	'39': 'Chad',
	'40': 'Chile',
	'41': 'China',
	'42': 'Colombia',
	'43': 'Comoros',
	'44': 'Congo',
	'45': 'Congo, Dem Rep',
	'46': 'Costa Rica',
	'47': 'Cote dIvoire',
	'48': 'Croatia',
	'49': 'Cuba',
	'50': 'Cyprus',
	'51': 'Czech Rep',
	'52': 'Denmark',
	'53': 'Djibouti',
	'54': 'Dominica',
	'55': 'Dominican Rep',
	'56': 'Ecuador',
	'57': 'Egypt',
	'58': 'El Salvador',
	'59': 'Equatorial Guinea',
	'60': 'Eritrea',
	'61': 'Estonia',
	'62': 'Ethiopia',
	'63': 'Fiji',
	'64': 'Finland',
	'65': 'France',
	'66': 'French Guiana',
	'67': 'French Polynesia',
	'68': 'Gabon',
	'69': 'Gambia',
	'70': 'Georgia',
	'71': 'Germany',
	'72': 'Ghana',
	'73': 'Greece',
	'74': 'Grenada',
	'75': 'Guadeloupe',
	'76': 'Guatemala',
	'77': 'Guinea',
	'78': 'Guinea-Bissau',
	'79': 'Guyana',
	'80': 'Haiti',
	'81': 'Honduras',
	'82': 'Hong Kong',
	'83': 'Hungary',
	'84': 'Iceland',
	'85': 'India',
	'86': 'Indonesia',
	'87': 'Iran, Islamic Rep',
	'88': 'Iraq',
	'89': 'Ireland',
	'90': 'Israel',
	'91': 'Italy',
	'92': 'Jamaica',
	'93': 'Japan',
	'94': 'Jordan',
	'95': 'Kazakhstan',
	'96': 'Kenya',
	'97': 'Kiribati',
	'98': 'Korea, Dem Peoples Rep',
	'99': 'Korea, Rep',
	'100': 'Kuwait',
	'101': 'Kyrgyzstan',
	'102': 'Lao Peoples Dem Rep',
	'103': 'Latvia',
	'104': 'Lebanon',
	'105': 'Lesotho',
	'106': 'Liberia',
	'107': 'Libyan Arab Jamahiriya',
	'108': 'Lithuania',
	'109': 'Luxembourg',
	'110': 'Macau',
	'111': 'Macedonia, FYR',
	'112': 'Madagascar',
	'113': 'Malawi',
	'114': 'Malaysia',
	'115': 'Maldives',
	'116': 'Mali',
	'117': 'Malta',
	'118': 'Marshall Islands',
	'119': 'Martinique',
	'120': 'Mauritania',
	'121': 'Mauritius',
	'123': 'Micronesia, Fed States',
	'124': 'Moldova, Rep',
	'125': 'Mongolia',
	'126': 'Morocco',
	'127': 'Mozambique',
	'128': 'Myanmar',
	'129': 'Namibia',
	'130': 'Nepal',
	'131': 'Netherlands',
	'132': 'Netherlands Antilles',
	'133': 'New Caledonia',
	'134': 'New Zealand',
	'135': 'Nicaragua',
	'136': 'Niger',
	'137': 'Nigeria',
	'138': 'Norway',
	'139': 'Oman',
	'140': 'Pakistan',
	'141': 'Panama',
	'142': 'Papua New Guinea',
	'143': 'Paraguay',
	'144': 'Peru',
	'145': 'Philippines',
	'146': 'Poland',
	'147': 'Portugal',
	'148': 'Puerto Rico',
	'149': 'Qatar',
	'150': 'Reunion',
	'151': 'Romania',
	'152': 'Russian Federation',
	'153': 'Rwanda',
	'154': 'Samoa',
	'155': 'Sao Tome &amp; Principe',
	'156': 'Saudi Arabia',
	'157': 'Senegal',
	'158': 'Seychelles',
	'159': 'Sierra Leone',
	'160': 'Singapore',
	'161': 'Slovakia',
	'162': 'Slovenia',
	'163': 'Solomon Islands',
	'164': 'Somalia',
	'165': 'South Africa',
	'166': 'Spain',
	'167': 'Sri Lanka',
	'168': 'St. Lucia',
	'169': 'St. Vincent &amp; Grenadines',
	'170': 'Sudan',
	'171': 'Suriname',
	'172': 'Swaziland',
	'173': 'Sweden',
	'174': 'Switzerland',
	'175': 'Syrian Arab Rep',
	'176': 'Taiwan',
	'177': 'Tajikistan',
	'178': 'Tanzania',
	'179': 'Thailand',
	'180': 'Togo',
	'181': 'Tonga',
	'182': 'Trinidad and Tobago',
	'183': 'Tunisia',
	'184': 'Turkey',
	'185': 'Turkmenistan',
	'186': 'Uganda',
	'187': 'Ukraine',
	'188': 'United Arab Emirates',
	'189': 'United Kingdom',
	'191': 'Uruguay',
	'192': 'Uzbekistan',
	'193': 'Vanuatu',
	'194': 'Venezuela',
	'195': 'Viet Nam',
	'196': 'Virgin Islands',
	'197': 'Yemen',
	'198': 'Yemen, Rep',
	'199': 'Serbia and Montenegro',
	'200': 'Zambia',
	'201': 'Zimbabwe',
	'202': 'American Samoa',
	'203': 'Andorra',
	'204': 'British Virgin Islands',
	'205': 'Cayman Islands',
	'206': 'Channel Islands',
	'207': 'Cook Islands',
	'208': 'East Timor',
	'209': 'Faeroe Islands',
	'210': 'Falkland Islands',
	'211': 'Palestinian Territories',
	'212': 'Gibraltar',
	'213': 'Greenland',
	'214': 'Guam',
	'215': 'Isle of Man',
	'216': 'Liechtenstein',
	'217': 'Monaco',
	'218': 'Nauru',
	'219': 'Niue',
	'220': 'Northern Mariana Islands',
	'221': 'Palau',
	'222': 'Saint Helena',
	'223': 'Saint Kitts and Nevis',
	'224': 'Saint Pierre and Miquelon',
	'225': 'San Marino',
	'226': 'Turks and Caicos Islands',
	'227': 'Western Sahara',
	'228': 'Gaza Strip',
	'229': 'West Bank',
	'230': 'Other',
}
