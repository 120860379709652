import { makeRequestCache } from '@/lib/make-request-cache.js'
import { router } from '@/service/router.js'
import { get, post, put } from '@/lib/json-fetch.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const listReferrers = async params => get('/api/v1/referrers' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))

/**
 *
 * @type {(function(): Promise<*>)|*}
 */
export const getReferrerById = makeRequestCache(
	router,
	async ({ id }) => get(`/api/v1/referrers/${id}`).then(response => ({ data: response.body.data })),
)

export const createReferrer = async ({ data }) => post('/api/v1/referrers', { body: { data } })

export const updateReferrer = async ({ data }) => put(`/api/v1/referrers/${data.id}`, { body: { data } })
