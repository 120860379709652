import { AuthSimpleConfirmMail } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'

export default {
	template: AuthSimpleConfirmMail,
	resolve: (data, { email }) => Promise.resolve({
		config,
		form: {
			confirm: { email },
		},
	}),
}
