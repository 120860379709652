export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		created: {
			type: 'string',
			format: 'date-time',
		},
		updated: {
			type: 'string',
			format: 'date-time',
		},
		archived: {
			description: 'Most recent date the item has been archived.',
			type: 'string',
			format: 'date-time',
		},
		imported: {
			type: 'string',
			format: 'date-time',
		},
		importSource: {
			type: 'string',
		},
	},
}
