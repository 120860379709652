<script>
	export let asr
	export let list
	export let lists
	export let label
</script>

{#if label}
	<h1>{label}</h1>
{/if}

<div class="row">
	<div class="col-xs-12 col-md-9 col-xl-6">
		{#each (lists || [ list ]) as l}
			<ul class="list-group mb-3">
				{#each l as { path, title, description }}
					<li class="list-group-item d-flex justify-content-between align-items-start">
						<div class="ms-2 me-auto">
							<div class="fw-bold">
								<a href={asr.makePath(path)}>{title}</a>
							</div>
							{description}
						</div>
					</li>
				{/each}
			</ul>
		{/each}
	</div>
</div>
