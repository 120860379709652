<script>
	import Navbar from './_CustomerNavbar.svelte'
	export let asr
</script>

<Navbar {asr} current="outstanding" />

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Outstanding CC Transactions
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<div class="alert alert-info">
			<p>Not yet implemented!</p>
			<p>This will be a list of CC transactions that are in a "pending" state.</p>
			<p>Specifically, any non-cancelled opportunity with a CC transaction in a non-completed state.</p>
			<p>
				Except that there is currently no way to "cancel" a CC transaction, so we probably should
				add that functionality, so... Maybe CC transactions are "retry", "cancelled", "completed".
				So if a CC transaction fails, you can mark it "retry" and you'll retry again tomorrow or
				whatever. If you decide to cancel it entirely you can do that, but it won't show up in the
				pending list. (Like if an order got cancelled last minute.)
			</p>
		</div>
	</div>
</div>
