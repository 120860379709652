<script>
	import { createEventDispatcher } from 'svelte'

	export let tab
	export let current
	export let slim
	export let disabled

	$: active = tab === current

	const dispatch = createEventDispatcher()
	let clicker = () => { dispatch('change', tab) }
</script>

<style>
	a.slim {
		font-weight: normal;
		padding: 0.2rem 1rem;
	}
	.nav-item .nav-link.disabled {
		color: #bbb;
	}
	.nav-item .nav-link.nav-link.disabled.active {
		border-bottom-color: #aaa;
	}
</style>

<li class="nav-item">
	<a
		class:disabled
		class="nav-link"
		class:slim
		class:active
		aria-current="page"
		href="#{tab}"
		on:click|preventDefault|stopPropagation={clicker}
	>
		<slot {clicker} />
	</a>
</li>
