export const errorFormatter = error => {
	error = error || {}
	
	const formatted = {
		status: error.status && error.status.toString() || '500',
		code: error.code || (error instanceof Error && error.name !== 'Error' && error.name) || 'UnexpectedException',
		title: error.title || 'Unexpected server exception',
		detail: error.message || error.detail || 'Unexpected server exception, please report to API maintainers.',
		source: error.source,
	}

	const stacktrace = (error.meta?.stacktrace || error.stack || '').toString()
	
	if (stacktrace) {
		formatted.meta = error.meta || {}
		formatted.meta.stacktrace = stacktrace
	}

	if (error.cause) {
		formatted.meta = formatted.meta || error.meta || {}
		formatted.meta.cause = error.cause
	}

	if (error.data) {
		formatted.meta = formatted.meta || error.meta || {}
		formatted.meta.data = error.data || {}
	}

	return formatted
}

export const formatCentralDispatchApiError = (error, statusCode) => {
	return {
		status: statusCode && statusCode.toString() || '500',
		code: error.code || error.Code || 'UnexpectedException',
		title: 'Central Dispatch API error',
		detail: error.message || error.Message || 'Unexpected server exception, please report to API maintainers.',
		meta: {
			properties: error.properties || error.Properties || [],
			errorId: error.errorId || error.ErrorId || '',
		},
	}
}

