<script>
	import { createEventDispatcher } from 'svelte'
	const dispatch = createEventDispatcher()
</script>

<style>
	.container {
		position: fixed;
		top: 0;
		z-index: 9000000;
	}
</style>

<div class="container mt-3">
	<div class="row">
		<div class="col-md-12 col-lg-8 col-xl-7 col-xxl-6">
			<div class="alert alert-danger">
				<h4 class="alert-heading">Unsaved Changes?</h4>
				<p>It looks like there are some unsaved changes. Do you want to continue?</p>
				<p class="mb-0 text-end">
					<button class="btn btn-secondary" on:click={() => dispatch('navigate')}>
						Lose Unsaved Changes
					</button>
					<button class="btn btn-primary" on:click={() => dispatch('stay')}>
						Stay Here
					</button>
				</p>
			</div>
		</div>
	</div>
</div>
