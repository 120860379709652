import { writable } from 'svelte/store'
import { clone } from '@/shared/util/clone.js'
import { load } from 'jsonapi-svelte-form/mapper'

export const openFormById = writable({})
export const savingFormById = writable({})

export const openForm = row => {
	openFormById.set({ [row.id]: load({ data: clone(row) }, 0) })
}

export const savingForm = row => {
	savingFormById.set({ [row.id]: true })
}

export const closeAll = () => {
	openFormById.set({})
	savingFormById.set({})
}
