<script>
	import Table from '@/component/table/EmailTemplates.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import Icon from 'svelte-awesome'
	import { faPlus, faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { listEmailTemplates } from '@/lib/controller/email-template.js'

	export let asr
	export let api

	let loading = true
	let data = []
	let links
	let errors

	listEmailTemplates(api)
		.then(response => {
			loading = false
			data = [ ...data, ...response.data ]
			links = response.links
		})
		.catch(error => {
			errors = Array.isArray(error.errors)
				? error.errors
				: [ error ]
		})
</script>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{data}
	{links}
	{errors}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>
				<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
				<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
				Email Templates
			</h4>
		</div>
		<div class="col mt-2 text-end">
			<a href={asr.makePath('app.systemSettings.emailTemplates.create', null, { inherit: false })}
			   class="btn btn-primary btn-sm text-light">
				<Icon data={faPlus} scale="1" class="ms-2 me-2"/>
				Add Template
			</a>
		</div>
	</div>
</TableCardWrapper>
