import template from './Overview.svelte'
import { getOpportunityById, listOpportunityCcTransactions, listOpportunityEmails } from '@/lib/controller/opportunity.js'
import { listReferrers } from '@/lib/controller/referrer.js'
import { listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'
import { keyBy } from '@/shared/util/key-by.js'
import { notFoundRedirect } from '@/lib/not-found-redirect.js'
import { LEAD_TO_QUOTE, QUOTE_TO_ORDER } from '@/shared/value/system-email-templates.js'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { opportunityId, ccTransactionId }) => {
		const [
			body,
			{ data: referrers },
			{ data: leadAndQuoteEmails },
			{ data: ccTransactions },
			{ data: users },
		] = await Promise.all([
			getOpportunityById({ get }, opportunityId, { include: [ 'assigned', 'companies', 'autoQuoteCampaign' ] }).catch(error => ({ error })),
			listReferrers({ get }),
			listOpportunityEmails({ get }, opportunityId, {
				filter: {
					templateIds: [ LEAD_TO_QUOTE, QUOTE_TO_ORDER ],
				},
				sort: 'meta.created',
			}),
			listOpportunityCcTransactions({ get }, opportunityId),
			listUsers({ get }),
		])
		notFoundRedirect(body, 'opportunity', opportunityId)
		return {
			users,
			usersById: keyBy(users, 'id'),
			ccTransactions,
			ccTransactionId,
			leadAndQuoteEmails,
			referrers,
			form: load(body, 0),
		}
	},
}
