<script>
	import { Field } from 'jsonapi-svelte-form'
	import ColumnSelector from './custom-report/ColumnSelector.svelte'
	import InputRadio from '@/component/atom/InputRadio.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import { opportunityFilterableDateTypes, dateTypeToLabel } from '@/shared/value/opportunity-filterable-date-types.js'
	import {
		assignmentStatus,
		autoQuoteRelationship,
		carrierStatus,
		dateRangeSelector,
		heldFilter,
		referrerRelationship,
	} from '@/shared/value/report-filters.js'
	import { opportunityFilterableDateLabel, opportunityFilterableDateExists } from '@/shared/value/opportunity-filterable-date-exists.js'
	import { opportunityGroupings } from '@/shared/value/opportunity-groupings.js'
	import { cancellationFilter } from '@/shared/value/report-filters.js'
	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form

	const dateTypeList = Object
		.keys(opportunityFilterableDateTypes)
		.map(key => ({
			key,
			title: dateTypeToLabel[key],
			description: opportunityFilterableDateTypes[key],
		}))

	const opportunityStatusToLabel = {
		lead: 'Lead',
		quote: 'Quote',
		order: 'Order',
		delivered: 'Delivered',
	}

	const statusParams = [
		{
			statusLabel: 'Auto-Quote Status',
			statusKey: 'autoQuoteStatus',
			statusOpts: autoQuoteRelationship,
			idsLabel: 'Auto-Quote',
			idsKey: 'autoQuoteCampaignIds',
		},
		{
			statusLabel: 'Referrer Status',
			statusKey: 'referrerStatus',
			statusOpts: referrerRelationship,
			idsLabel: 'Referrer',
			idsKey: 'referrerIds',
			tagsLabel: 'Referrer',
			tagsKey: 'referrerTags',
		},
		{
			statusLabel: 'Assignment Status',
			statusKey: 'assignmentStatus',
			statusOpts: assignmentStatus,
			idsLabel: 'User',
			idsKey: 'assignedIds',
		},
		{
			statusLabel: 'Carrier Status',
			statusKey: 'carrierStatus',
			statusOpts: carrierStatus,
			idsLabel: 'Carrier',
			idsKey: 'carrierIds',
		},
	]

	const toggleArray = (existingList, element) => {
		if (existingList?.includes(element)) return existingList.filter(i => i !== element)
		else return [ ...(existingList || []), element ]
	}
</script>

<div class="row mb-3">
	<div class="col-xs-12 col-sm-10 col-lg-8 col-xl-6">
		<div class="card">
			<div class="card-body bg-light">
				<p>
					Set the core parameters for your report:
				</p>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p class="mb-1"><strong>Display Columns</strong></p>
						<p>Pick which columns to display.</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<ColumnSelector bind:form />
					</div>
				</div>
				<hr>





				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p class="mb-1"><strong>Minimum Date Type</strong></p>
						<p>Date property to use for the minimum date.</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<InputRadio
							optionsList={dateTypeList}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'minDateType' ]}
						/>
					</div>
				</div>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p class="mb-1"><strong>Maximum Date Type</strong></p>
						<p>Date property to use for the maximum date.</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<InputRadio
							optionsList={dateTypeList}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'maxDateType' ]}
						/>
					</div>
				</div>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p class="mb-1">
							Select which dates <em>must</em> be set
						</p>
						<p class="text-muted">
							Optional. Selecting a date filters the results to opportunities
							that contain that date, independent of what the date is.
						</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'datesExist' ]} let:set let:value let:disabled>
							{#each Object.keys(opportunityFilterableDateLabel) as key}
								<div class="form-check mb-0">
									<input
										class="form-check-input"
										type="checkbox"
										{disabled}
										checked={value?.includes(key)}
										id="dateExists-{key}"
										on:input={() => { set(toggleArray(value, key)) } }
									>
									<label class="form-check-label mb-0" for="dateExists-{key}">
										<strong>{opportunityFilterableDateLabel[key]}</strong>
										<br>
										{opportunityFilterableDateExists[key]}
									</label>
								</div>
							{/each}
						</Field>
					</div>
				</div>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p class="mb-1">
							Select which opportunity statuses to include
						</p>
						<p class="text-muted">
							Optional. Selecting an opportunity status filters the results
							to opportunities that are currently that status.
						</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'opportunityStatuses' ]} let:set let:value let:disabled>
							{#each Object.keys(opportunityStatusToLabel) as status}
								<div class="form-check mb-0">
									<input
										class="form-check-input"
										type="checkbox"
										{disabled}
										checked={value?.includes(status)}
										id="status-{status}"
										on:input={() => { set(toggleArray(value, status)) } }
									>
									<label class="form-check-label mb-0" for="status-{status}">
										{opportunityStatusToLabel[status]}
									</label>
								</div>
							{/each}
						</Field>
					</div>
				</div>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p>
							Cancellation Status
						</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<InputRadio
							optionsList={Object.keys(cancellationFilter).map(key => ({ key, title: cancellationFilter[key] }))}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'cancellationStatus' ]}
						/>
					</div>
				</div>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<p>
							Held Status
						</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<InputRadio
							optionsList={Object.keys(heldFilter).map(key => ({ key, title: heldFilter[key] }))}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'heldStatus' ]}
						/>
					</div>
				</div>
				<hr>

				{#each statusParams as { statusLabel, statusKey, statusOpts, idsLabel, idsKey, tagsLabel, tagsKey }}
					<div class="row">
						<div class="col-xs-12 col-md-4">
							<p>{statusLabel}</p>
						</div>
						<div class="col-xs-12 col-md-8">
							<InputRadio
								optionsList={Object.keys(statusOpts).map(key => ({ key, title: statusOpts[key] }))}
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', statusKey ]}
							/>
							{#if idsKey && (form.data[form.primaryId]?.attributes?.[statusKey] === 'id' || form.data[form.primaryId]?.attributes?.[idsKey])}
								<InputType
									type="text"
									label={idsLabel + ' IDs'}
									labelWidth="120px"
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', idsKey ]}
								/>
								<div class="form-text">Use a comma between each ID.</div>
							{/if}
							{#if tagsKey && (form.data[form.primaryId]?.attributes?.[statusKey] === 'tag' || form.data[form.primaryId]?.attributes?.[tagsKey])}
								<InputType
									type="text"
									label={tagsLabel + ' Tags'}
									labelWidth="120px"
									bind:form
									id={form.primaryId}
									keypath={[ 'attributes', tagsKey ]}
								/>
								<div class="form-text">Use a comma between each tag.</div>
							{/if}
						</div>
					</div>
					<hr>
				{/each}

				<h5>Pick the grouping for this report.</h5>
				<InputRadio
					optionsList={Object.keys(opportunityGroupings).map(key => ({ key, title: opportunityGroupings[key] }))}
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'grouping' ]}
				/>
				<hr>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<h5>Pick the "quick select" date buttons to show.</h5>
						<p class="text-muted">If only one is selected, the report will automatically have that option selected when opened.</p>
					</div>
					<div class="col-xs-12 col-md-8">
						<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'dateRanges' ]} let:set let:value let:disabled>
							{#each Object.keys(dateRangeSelector) as key}
								<div class="form-check mb-0">
									<input
										class="form-check-input"
										type="checkbox"
										{disabled}
										checked={value?.includes(key)}
										id="dateRanges-{key}"
										on:input={() => { set(toggleArray(value, key)) } }
									>
									<label class="form-check-label mb-0" for="dateRanges-{key}">
										{dateRangeSelector[key]}
									</label>
								</div>
							{/each}
						</Field>
					</div>
				</div>
				<hr>

				<InputType
					type="text"
					label="Report Name"
					labelWidth="150px"
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'name' ]}
				/>

				<InputType
					type="text"
					label="Report Description"
					labelWidth="150px"
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'about' ]}
				/>
			</div>
		</div>
	</div>
</div>
