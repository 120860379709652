import { legEventNames } from '../value/leg-event-names.js'
import { dateType } from '../value/date-type.js'

export default {
	type: 'object',
	required: [
		'jobPath',
		'actionName',
	],
	properties: {
		jobPath: {
			type: 'string',
			enum: [ 'central-dispatch/action' ],
		},
		actionName: {
			type: 'string',
			enum: Object.keys(legEventNames),
		},
		actionParams: {
			description: 'These are the additional parameters defined by each action.',
			type: 'object',
			properties: {
				post: {
					type: 'object',
					properties: {
						carrierPay: {
							description: 'The amount of carrier pay to use in Central Dispatch post.',
							type: 'number',
							format: 'double',
						},
						centralDispatchAdditionalTerms: {
							type: 'string',
						},
						firstAvailablePickup: {
							type: 'string',
							format: 'date',
						},
					},
				},
				assign: {
					type: 'object',
					properties: {
						onTrackId: {
							description: 'The internal OnTrack ID of the carrier. If not set, it will be imported from CD.',
							type: 'string',
						},
						centralDispatchId: {
							description: 'The carrier ID from CD. If not set, the `onTrackId` must be set.',
							type: 'string',
						},
						businessDays: {
							type: 'number',
							enum: [ 5, 10 ],
						},
						pickupDate: {
							type: 'string',
							format: 'date',
						},
						pickupDateType: {
							type: 'string',
							enum: Object.keys(dateType),
						},
						dropoffDate: {
							type: 'string',
							format: 'date',
						},
						dropoffDateType: {
							type: 'string',
							enum: Object.keys(dateType),
						},
					},
				},
				receive: {
					type: 'object',
					properties: {
						actualPickup: {
							type: 'string',
							format: 'date',
						},
					},
				},
				deliver: {
					type: 'object',
					properties: {
						actualDropoff: {
							type: 'string',
							format: 'date',
						},
					},
				},
			},
		},
	},
}
