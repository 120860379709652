<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import OpportunityForm from '@/component/form/OpportunityForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { createOpportunity } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'
	import { leads } from '@/lib/high-level-links.js'

	export let asr
	export let api
	export let cloneOpportunityId
	export let form
	export let referrers = []
	export let users = []

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createOpportunity(api, form, response => router.go('app.opportunities.edit.opportunityId', { opportunityId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath(leads.link, leads.params, { inherit: false })}>
		Leads
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		{#if cloneOpportunityId}
			<span class="text-info">
				<span class="fa fa-copy"></span>
			</span>
			Clone Opportunity
			<a href={asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId: cloneOpportunityId }, { inherit: false })}>
				{cloneOpportunityId.replace(/P$/, '')}
			</a>
		{:else}
			Create Lead
		{/if}
	</span>
</FormHeaderMain>

<OpportunityForm bind:form {api} {referrers} {users} cloning={cloneOpportunityId} />

<FormErrorsOther {form} />

<FormCommitButton label="Create Lead" {form} on:click={create} />
