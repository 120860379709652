<script>
	import { xlsxGenerator } from '@/lib/xlsx-generator.js'

	export let generator
	export let data
	export let disabled

	let clazz = 'btn-outline-secondary'
	export { clazz as class }
</script>

<style>
	.disabled {
		color: #d3d3d3 !important;
		border-color: #d3d3d3 !important;
	}
</style>

<button class="btn btn-sm {clazz}" class:disabled on:click={() => xlsxGenerator(generator, data)}>
	<span class="fa fa-download"></span>
</button>
