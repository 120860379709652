<script>
	import { onMount } from 'svelte'
	import { differenceInCalendarDays } from 'date-fns'
	import financialNumber from 'financial-number'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import InputTextReadonly from '@/component/atom/InputTextReadonly.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import { formatPhoneNumber } from '@/shared/util/format-phone-number.js'
	import { shippingMethod } from '@/shared/value/shipping-method.js'
	import { paymentBusinessDays } from '@/shared/value/payment-business-days.js'

	import NavHeader from './NavHeader.svelte'

	const formatDate = string => {
		if (!string) return ''
		const [ year, month, day ] = string.split('-')
		return [ month, day, year ].join('/')
	}

	export let asr
	export let form
	export let users
	export let usersById
	export let referrersById

	onMount(() => {
		setTimeout(() => {
			const elem = window.document.querySelector('input[aria-describedby=force-focus-select]')
			if (elem) {
				elem.focus()
				elem.select()
			}
		})
	})

	let labelWidth = '14em'

	$: firstLegContractualPickup = legs?.[0]?.attributes?.pickupDate
	$: firstLegActualPickup = legs?.[0]?.attributes?.actualPickup
	$: daysDiff = firstLegContractualPickup && firstLegActualPickup && differenceInCalendarDays(new Date(firstLegContractualPickup), new Date(firstLegActualPickup))

	$: referrer = referrersById[ form.data[form.primaryId].relationships?.referrer?.data?.id ]
	$: customerContact = form.data[ form.data[form.primaryId]?.relationships?.customerContact?.data?.id ]?.attributes || {}
	$: legs = form.data[form.primaryId]?.relationships?.legs?.data?.map(rel => form.data[rel.id]) || []
	$: vehicles = form.data[form.primaryId]?.relationships?.vehicles?.data?.map(rel => form.data[rel.id])
</script>

<NavHeader {asr} {form} {users} {usersById} activeTab="accountingSheet" />

{#if form.data[form.primaryId].attributes.alerts?.length}
	<div class="row">
		{#each form.data[form.primaryId].attributes.alerts as { level, message }, index}
			<div class="col-auto">
				<div class="alert alert-{level} py-2">
					{message}
				</div>
			</div>
		{/each}
	</div>
{/if}

<div class="row gx-3">
	<div class="col-xs-12 col-md-6">
		<div class="card mb-3">
			<div class="card-header py-2">
				<strong>Customer Invoice</strong>
			</div>
			<div class="card-body bg-light pt-3 pb-2">
				<InputTextReadonly
					id="force-focus-select"
					label="Customer First Name"
					{labelWidth}
					value={customerContact.firstName || ''}
				/>
				<InputTextReadonly
					label="Customer Last Name"
					{labelWidth}
					value={customerContact.lastName || ''}
				/>
				<InputTextReadonly
					label="Customer Phone"
					icon="phone"
					{labelWidth}
					value={customerContact.phonePrimary || ''}
				/>
				<InputTextReadonly
					label="Email"
					icon="email"
					{labelWidth}
					value={customerContact.emailPrimary || ''}
				/>
				<InputTextReadonly
					label="Sales Rep"
					icon="user"
					{labelWidth}
					value={usersById[ form.data[form.primaryId].relationships.user?.data?.id ]?.attributes?.name || ''}
				/>
				<InputTextReadonly
					label="First Available"
					icon="date"
					{labelWidth}
					value={formatDate(form.data[form.primaryId].attributes.firstAvailablePickup || '')}
				/>
				<InputTextReadonly
					label="Assigned Pickup Date"
					icon="date"
					{labelWidth}
					value={formatDate(firstLegContractualPickup)}
					class="mb-0"
				/>
				<p class="mb-2"><small>
					Of the first leg.
					{#if firstLegActualPickup}
						Actual pickup {formatDate(firstLegActualPickup)}, which is
						<span class:text-warning={daysDiff > 5}>{daysDiff} days difference.</span>
					{/if}
				</small></p>
				<InputTextReadonly
					label="Order #"
					icon="order"
					{labelWidth}
					value={form.primaryId.replace(/P$/, '')}
				/>
				<InputTextReadonly
					label="Referral Source"
					icon="referrer"
					{labelWidth}
					value={referrer?.attributes?.accountingId || ''}
					class={referrer ? 'mb-0' : 'mb-2'}
				/>
				{#if referrer && !referrer.attributes.accountingId}
					<p class="mb-2"><small>
						There was a referral source
						(<a href={asr.makePath('app.referrers.edit.referrerId', { referrerId: referrer.id }, { inherit: false })}>{referrer.attributes.name}</a>)
						but no accounting "item" set.
					</small></p>
				{:else if referrer}
					<p class="mb-2"><small>
						Which is <a tabindex="-1" href={asr.makePath('app.referrers.edit.referrerId', { referrerId: referrer.id }, { inherit: false })}>{referrer.attributes.name}</a>.
					</small></p>
				{/if}
				{#each vehicles as v, index}
					<p class="mb-0">
						<strong>Vehicle {index + 1}</strong>
						<span class="text-muted">Ship via</span>
						<span class:text-info={v.attributes.shippingMethod === 'topLoad'}>
							{shippingMethod[v.attributes.shippingMethod] || 'Open'}
						</span>
					</p>
					<div class="ms-4">
						<InputTextReadonly
							label="Vehicle"
							icon="vehicle"
							{labelWidth}
							value={[ v.attributes.year, v.attributes.make, v.attributes.model ].filter(Boolean).join(' ')}
						/>
						<InputTextReadonly
							label="Tariff"
							icon="money"
							{labelWidth}
							value={financialNumber('0.00').plus(v.attributes.originalCarrierPay || 0).plus(v.attributes.originalBrokerFee || 0).plus(v.attributes.tariffAdjustment || 0)}
							class="mb-0"
						/>
						<p class="mb-0"><small>Original Carrier Pay + Original Broker Fee + Tariff Adjust</small></p>
					</div>
				{/each}
			</div>
		</div>
	</div>

	{#each legs as leg, index}
		{@const shipper = form.data[leg.relationships?.shipper?.data?.id]}
		{@const contact = form.data[shipper?.relationships?.contact?.data?.id]}
		{@const address = form.data[shipper?.relationships?.address?.data?.id]?.attributes || {}}
		{@const addressString = address && [ address.line1, address.line2, [ address.city || '', [ address.state, address.zip ].filter(Boolean).join(' ') ].filter(Boolean).join(', ') ].filter(Boolean).join('\n') || ''}
		<div class="col-xs-12 col-md-6">
			<div class="card mb-3">
				<div class="card-header py-2">
					<strong>Carrier Bill</strong>
					(Leg {index + 1} of {legs.length})
				</div>
				<div class="card-body bg-light pt-3 pb-2">
					<InputTextReadonly
						label="Carrier Name"
						icon="carrier"
						{labelWidth}
						value={shipper?.attributes?.name || ''}
						class="mb-1"
					/>
					<div class="row mb-2">
						<div class="col-auto">
							{#if shipper?.id}
								<ExternalLink url={asr.makePath('app.companies.edit.companyId.overview', { companyId: shipper.id }, { inherit: false })}>
									OnTrack
								</ExternalLink>
							{/if}
						</div>
						{#if shipper?.attributes?.centralDispatchId}
							<div class="col-auto">
								<ExternalLink url="https://site.centraldispatch.com/protected/rating/client-snapshot?id={shipper.attributes.centralDispatchId}">
									Central Dispatch
								</ExternalLink>
							</div>
						{/if}
					</div>
					<div class="input-group input-group-sm mb-2">
						<span class="input-group-text" id="carrier-address" style:width={labelWidth || ''}>
							Carrier Address
						</span>
						<textarea
							class="form-control form-control-sm"
							readonly
							value={addressString || ''}
						></textarea>
						<CopyToClipboard string={addressString || ''} />
					</div>
					<InputTextReadonly
						label="Carrier Phone"
						icon="phone"
						{labelWidth}
						value={formatPhoneNumber(contact?.attributes?.phonePrimary || '')}
					/>
					<InputTextReadonly
						label="Carrier Email"
						icon="email"
						{labelWidth}
						value={contact?.attributes?.emailPrimary || ''}
					/>
					<!-- NOTE: from accounting, all legs have the first legs `pickupDate` -->
					<InputTextReadonly
						label="Assigned Pickup Date"
						icon="date"
						{labelWidth}
						value={formatDate(firstLegContractualPickup || '')}
					/>
					<InputTextReadonly
						label="Order #"
						icon="order"
						{labelWidth}
						value={form.primaryId.replace(/P$/, '')}
					/>
					<InputTextReadonly
						label="Carrier Pay"
						icon="money"
						{labelWidth}
						value={financialNumber('0.00').plus(leg?.attributes?.carrierPay || '0')}
						class="mb-0"
					/>
					<p class="mb-2">
						Payment Terms:
						<strong class:text-info={leg?.attributes?.businessDays === 5}>
							{paymentBusinessDays[leg?.attributes?.businessDays] || 'N/A'}
						</strong>
					</p>
					<InputTextReadonly
						label="Customer Name"
						{labelWidth}
						value={[ customerContact.firstName, customerContact.lastName ].filter(Boolean).join(' ') || ''}
					/>
					<InputTextReadonly
						label="Sales Rep"
						icon="user"
						{labelWidth}
						value={usersById[ form.data[form.primaryId].relationships.user?.data?.id ]?.attributes?.name || ''}
					/>
				</div>
			</div>
		</div>
	{/each}
</div>
