import template from './Create.svelte'
import { type } from '@/shared/model/signable-template.model.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Create Signable Template',
	},
	template,
	resolve: async () => ({ form: load({
		data: {
			id: 'GID0',
			type,
			attributes: {
				name: '',
				markdown: '',
			},
		} }, 0) }),
}
