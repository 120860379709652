<script>
	import ReportTable from './ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'

	export let asr
	export let columns
	export let orderedColumns
	export let label
	export let file
	export let currentSort
	export let params
	export let querystringParams

	export let loading
	export let data
	export let errors

	$: rows = !loading && data && tableizeReportData({
		body: { data },
		sort: currentSort,
		params,
		columns,
		orderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns,
		columns,
		label,
		filename: `${file}-${new Date().toISOString().replaceAll(':', '')}.xlsx`,
		rows,
		params: params.filter,
	})
</script>

<ReportTable
	id={file}
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
