export const type = 'referrer'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the referrer record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'name',
			],
			properties: {
				name: {
					type: 'string',
					description: 'Human readable name for this referrer.',
				},
				tag: {
					type: 'string',
					description: 'Optional cross-referrer tag to use for report generation.',
				},
				accountingId: {
					type: 'string',
					description: 'The QuickBooks "item" name, for easier lookup.',
				},
				key: {
					type: 'string',
					description: 'The identifier used in external forms, originally a BATS property called "Auth Key".',
				},
				code: {
					type: 'string',
					description: 'The updated non-BATS way to link to referrers.',
				},
				zapName: {
					type: 'string',
					description: 'The name of the Zapier Zap, if one is set.',
				},
				active: {
					type: 'boolean',
					description: 'Legacy parameter, may still be used.',
				},
			},
		},
	},
}
