<script>
	import Navbar from './_AccountingNavbar.svelte'
	export let asr
</script>

<Navbar {asr} label="Carriers" />

<div class="alert alert-info">
	Not yet implemented!
</div>
