const stateToTitle = {
	unpost: 'Unposted',
	post: 'Posted to CD',
	assign: 'Not Signed',
	dispatch: 'Dispatched',
	receive: 'Picked Up',
	deliver: 'Delivered',
}
const stateToIcon = {
	unpost: 'order',
	post: 'cd',
	assign: 'unsigned',
	dispatch: 'dispatched',
	receive: 'received',
	deliver: 'delivered',
}

const legToState = leg => {
	const dates = leg?.attributes?.dates || {}
	if (!dates.post) return 'unpost'
	else if (dates.post && !dates.assign) return 'post'
	else if (dates.post && dates.assign && !dates.dispatch) return 'assign'
	else if (dates.post && dates.assign && dates.dispatch && !dates.receive && !dates.deliver) return 'dispatch'
	else if (dates.post && dates.assign && dates.dispatch && dates.receive && !dates.deliver) return 'receive'
	else if (dates.post && dates.assign && dates.dispatch && dates.deliver) return 'deliver'
}

export const legToStateTitleIcon = leg => {
	const state = legToState(leg)
	if (state) return {
		state,
		title: stateToTitle[state],
		icon: stateToIcon[state],
	}
}

export const opportunityToStateAndIcon = ({ data, includedById }) => {
	if (data?.attributes?.held) return [ { state: 'Hold', icon: 'hold', style: 'bg-warning' } ]
	if (data?.attributes?.cancelled) return [ { state: 'Cancelled', icon: 'cancel', style: 'bg-warning' } ]

	if (data?.attributes?.status !== 'order') return []

	const legs = data?.relationships?.legs?.data?.map(rel => includedById[rel.id]) || []

	return legs.map(legToState).map((state, index) => ({
		state: `${stateToTitle[state]}${legs.length > 1 ? ` (Leg ${index + 1})` : ''}`,
		icon: stateToIcon[state],
		id: data?.relationships?.legs?.data?.[index]?.id,
	}))
}
