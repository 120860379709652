import template from './Communication.svelte'
import { getOpportunityById } from '@/lib/controller/opportunity.js'
import { listEmailTemplates } from '@/lib/controller/email-template.js'
import { listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'
import { notFoundRedirect } from '@/lib/not-found-redirect.js'
import { keyBy } from '@/shared/util/key-by.js'

// TODO pull this out and add tests
const extractTypeAndIncludes = ({ response, type }) => {
	const includedById = keyBy(response?.included || [], 'id')
	const data = []
	const included = {}
	const lookDeeper = resource => {
		for (const key in (resource.relationships || {})) {
			if (Array.isArray(resource.relationships[key].data)) {
				for (const { id } of resource.relationships[key].data) {
					const deeperResource = includedById[id]
					if (deeperResource) {
						included[deeperResource.id] = deeperResource
						lookDeeper(deeperResource)
					}
				}
			} else if (resource.relationships[key].data?.id) {
				const deeperResource = includedById[resource.relationships[key].data.id]
				if (deeperResource) {
					included[deeperResource.id] = deeperResource
					lookDeeper(deeperResource)
				}
			}
		}
	}
	for (const resource of (response?.included || []).filter(r => r.type === type)) {
		data.push(resource)
		lookDeeper(resource)
	}
	return { data, included: Object.values(included) }
}

export default {
	data: {
		title: 'Communication',
	},
	template,
	resolve: async (_, { opportunityId, convertedTo }) => {
		const [ opportunityResponse, emailTemplates, { data: users } ] = await Promise.all([
			getOpportunityById({ get }, opportunityId, { include: [ 'companies' ] }),
			listEmailTemplates({ get }, { include: [ 'signableTemplateNames' ] }),
			listUsers({ get }),
		])
		notFoundRedirect(opportunityResponse, 'opportunity', opportunityId)
		return {
			convertedTo,
			users,
			usersById: keyBy(users, 'id'),
			emailTemplatesById: keyBy(emailTemplates.data, 'id'),
			opportunityResponse,
			opportunityForm: load(opportunityResponse, 0), // used for the nav header
			emailTemplates: emailTemplates.data,
			signableTemplatesById: keyBy(emailTemplates.included?.filter(inc => inc.type === 'signable-template') || [], 'id'),
			companies: extractTypeAndIncludes({ response: opportunityResponse, type: 'company' }),
		}
	},
}
