<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import Changes from './history/Changes.svelte'
	import ResourceLink from './history/ResourceLink.svelte'
	import User from './history/User.svelte'
	import { resourceToLink } from '@/lib/resource-to-link.js'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort
	export let parentType

	export let columnNames

	const defaultColumnNames = [
		'created',
		'user',
		'resource',
		'note',
		'changes',
	]
	const parentTypeToColumnNames = {
		note: [
			'created',
			'user',
			'changes',
		],
		opportunity: [
			'created',
			'user',
			'note',
			'changes',
		],
		'cc-transaction': [
			'createdLong',
			'user',
			'changes',
		],
	}

	$: nameToColumn = {
		created: {
			title: 'Date',
			component: DateTime,
			keypath: 'meta.created',
			columnClass: 'history-created',
		},
		createdLong: {
			title: 'Date',
			component: DateTime,
			time: true,
			multiline: true,
			keypath: 'meta.created',
			columnClass: 'history-created',
		},
		user: {
			title: 'User',
			component: User,
			keypath: 'attributes.userId',
			columnClass: 'history-user',
		},
		resource: {
			title: 'Resource',
			component: ResourceLink,
			keypath: 'attributes.resourceId',
			link: row => resourceToLink(asr, { id: row.attributes.resourceId, type: row.attributes.resourceType }, false),
		},
		note: {
			title: 'Note',
			component: PlainText,
			keypath: 'attributes.note',
		},
		changes: {
			title: 'Changes',
			component: Changes,
			keypath: 'attributes.changes',
			parentType,
		},
	}
	$: columns = (columnNames || parentTypeToColumnNames[parentType] || defaultColumnNames).map(name => nameToColumn[name])
</script>

<style>
	:global(.history-table .history-created) { width: 9.5em; }
	:global(.history-table .history-user) { white-space: nowrap; }
</style>

<div class="history-table">
	<Table
		small
		{columns}
		{data}
		{errors}
		{included}
		{links}
		{makeLink}
		{request}
		{sort}
		bind:loading
		on:loadNext
	/>
</div>
