<script>
	export let asr
	export let querystringParams
	export let sortKey
	export let label
	export let style
	export let align
	export let currentSort
	export let updateSort

	const styleToIconUp = {
		default: 'fa-sort-up',
		text: 'fa-sort-alpha-up-alt',
		number: 'fa-sort-numeric-up-alt',
	}
	const styleToIconDown = {
		default: 'fa-sort-down',
		text: 'fa-sort-alpha-down',
		number: 'fa-sort-numeric-down',
	}

	$: params = {
		...querystringParams,
		sort: (currentSort && currentSort === sortKey)
			? `-${sortKey}`
			: sortKey,
	}
	$: iconUp = styleToIconUp[style] || styleToIconUp.default
	$: iconDown = styleToIconDown[style] || styleToIconDown.default

	const clickHandler = event => {
		if (updateSort) updateSort({
			sortKey: currentSort === sortKey ? `-${sortKey}` : sortKey,
			event,
		})
	}
</script>

<th class="text-nowrap" class:text-end={align === 'end'}>
	<a href={asr.makePath(null, params, { inherit: false })} on:click={clickHandler}>
		{label}
	</a>
	{#if currentSort && currentSort === sortKey}
		<span class="fa {iconUp}"></span>
	{:else if currentSort && currentSort === `-${sortKey}`}
		<span class="fa {iconDown}"></span>
	{/if}
</th>
