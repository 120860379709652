<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { activeParams } from '@/service/store/router-state.js'
	import { opportunityLegStatusToIcon } from '@/lib/opportunity-leg-status-to-icon.js'
	import { router } from '@/service/router.js'

	export let column
	export let row
	export let legId
	export let legIndex
	export let includedById

	$: address = getPickupDropoffForLeg({
		opportunityId: row.id,
		legId,
		resourcesById: includedById,
	})?.[column.type]?.address
	$: leg = includedById[legId]

	$: terminalId = (column.type === 'pickup' && leg?.relationships?.pickupTerminal?.data?.id)
		|| (column.type !== 'pickup' && leg?.relationships?.dropoffTerminal?.data?.id)
	$: terminalName = includedById[terminalId]?.attributes?.name

	const legStatusToTab = {
		unpost: 'unposted',
		post: 'posted',
		assign: 'unsigned',
		dispatch: 'dispatched',
		receive: 'received',
		deliver: 'delivered',
	}

	$: legTab = legStatusToTab[leg.attributes.status] || 'unposted'
	$: currentTab = $activeParams.tab
</script>

<div class="row gx-0" data-leg-id={legId}>
	{#if column.type === 'pickup'}
		<div class="col-auto ps-1 pe-1 text-center" class:text-muted={currentTab !== legTab} class:text-primary={currentTab === legTab}>
			<NamedIcon name={opportunityLegStatusToIcon[leg.attributes.status] || 'order'} />
		</div>
	{/if}
	<div class="col">
		{#if terminalId}
			<a
				href={router.makePath('app.companies.edit.companyId.overview', { companyId: terminalId }, { inherit: false })}
				data-bs-toggle="tooltip"
				data-bs-placement="left"
				title={terminalName || `Unknown Terminal: ${terminalId}`}
			>
				<NamedIcon name="terminal" />
			</a>
		{/if}
		{#if address}
			{address.city || '–'}, {address.state || '–'} {address.zip || '–'}
		{:else if legIndex}
			<div class="text-end">
				⮑
			</div>
		{:else}
			&mdash;
		{/if}
	</div>
</div>
