<script>
	import { differenceInCalendarDays } from 'date-fns'
	import { writable } from 'svelte/store'
	import Chart from './_Chart.svelte'
	import { keyBy } from '@/shared/util/key-by.js'

	export let data
	export let users
	export let height

	let options = writable()
	$: {
		if (data?.length) {
			const usersById = keyBy(users || [], 'id')
			const days = differenceInCalendarDays(new Date(data[data.length - 1].date), new Date(data[0].date))
			const seriesByUserId = {}
			for (const { counts } of data)
				for (const userId in counts)
					if (!seriesByUserId[userId]) seriesByUserId[userId] = {
						name: userId === 'undefined'
							? '(Unassigned)'
							: (usersById[userId]?.attributes?.name || '(User Not Found)'),
						data: data.map(() => 0),
					}

			const categories = []
			let index = 0
			for (const { date, counts } of data) {
				categories.push(date)
				for (const userId in counts) {
					seriesByUserId[userId].data[index] = counts[userId]
				}
				index++
			}

			const series = Object
				.keys(seriesByUserId)
				.sort()
				.map(userId => seriesByUserId[userId])

			options.set({
				series,
				chart: {
					type: 'bar',
					height: height || '350',
					stacked: true,
					stackType: '100%',
				},
				stroke: {
					width: 1,
					colors: [ '#fff' ],
				},
				title: {
					text: `Historic Lead Assignments (${days} Days)`,
				},
				xaxis: {
					categories,
				},
				tooltip: {
					y: {
						formatter: val => val,
					},
				},
				fill: {
					opacity: 1,

				},
				legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40,
				},
			})
		}
	}
</script>

<Chart {options} />
