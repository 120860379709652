import { minimumDelay } from '@/lib/delay.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const updateCcTransaction = async ({ put }, ccTransaction) => minimumDelay(
	900,
	put(`/api/v1/ccTransactions/${ccTransaction.id}`, { body: { data: ccTransaction } }),
).then(response => response.body)

export const getCcTransactionById = async ({ get }, id, params) => get(`/api/v1/ccTransactions/${id}` + objectToJsonApiQuery(params)).then(response => response.body)

export const getCcTransactionHistories = async ({ get }, id) => get(`/api/v1/ccTransactions/${id}/histories`).then(response => response.body)
