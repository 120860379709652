<script>
	import { onMount } from 'svelte'
	import History from '@/component/table/History.svelte'
	import { listNoteHistory } from '@/lib/controller/note.js'
	export let asr
	export let api
	export let noteId

	let loading = true
	let response = {}
	onMount(() => {
		listNoteHistory(api, noteId, { include: 'users' })
			.then(r => {
				response = r
				loading = false
			})
	})
</script>

<style>
	:global(.notes-history .history-table th.change-row-name) { width: 6em; }
</style>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Note History
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2 notes-history">
		<History
			{loading}
			{asr}
			{...response}
			parentType="note"
			sort="-meta.created"
		/>
	</div>
</div>
