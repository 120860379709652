<script>
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	export let request
	export let errors
	$: plural = errors && errors.length > 1
</script>

<div class="container mt-5">
	<div class="row justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8 col-xxl-7">
			<div class="alert alert-secondary">
				<h4 class="alert-heading">Unrecoverable Error{plural ? 's' : ''}</h4>
				<p>Ope, there {plural ? 'were' : 'was an'} error{plural ? 's' : ''} that {plural ? 'were' : 'was'} not handled correctly!</p>
				<p class="mb-0">Please try reloading the page, but if the {plural ? 'errors persist' : 'error persists'} please reach out to the MCS team.</p>
			</div>
			{#if errors && errors.length}
				{#each errors as error}
					<ErrorAlert {error} {request} />
				{/each}
			{/if}
		</div>
	</div>
</div>
