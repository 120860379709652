const dateRanges = {
	today: {
		label: 'Today',
		params: { dateRange: 'today' },
	},
	thismonth: {
		label: 'This Month',
		params: { dateRange: 'thismonth' },
	},
	lastmonth: {
		label: 'Last Month',
		params: { dateRange: 'lastmonth' },
	},
	custom: {
		label: 'Custom Date Range',
		params: { dateRange: 'custom' },
	},
}

export const commonSelectableDateRanges = ranges => ranges.map(r => dateRanges[r])
