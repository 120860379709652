<script>
	import { Relationship } from 'jsonapi-svelte-form'
	import InputSelect from './InputSelect.svelte'

	export let id
	export let form
	export let name
	export let type
	export let readonly

	export let label
	export let emptySelectLabel
	export let options
	export let labelWidth
	export let icon
	export let short

</script>

<Relationship bind:form {id} {name} {type} on:change let:set>
	<InputSelect
		bind:form
		{readonly}
		{label}
		{emptySelectLabel}
		{options}
		{labelWidth}
		{icon}
		{id}
		{short}
		keypath={[ 'relationships', name, 'data', 'id' ]}
		onSelect={ value => { set(value) } }
	/>
</Relationship>
