<script>
	export let value
	export let id
	export let readonly

	let autoGrowElement
	$: {
		if (autoGrowElement?.dataset) autoGrowElement.dataset.replicatedValue = value
	}
</script>

<style>
	/*
	cribbed from https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
	*/
	div {
		/* plop the elements on top of each other and have them both sized based on the tallest one's height */
		display: grid;
	}
	div::after {
		/* Note the weird space! Needed to prevent jumpy behavior */
		content: attr(data-replicated-value) " ";

		/* This is how textarea text behaves */
		white-space: pre-wrap;

		/* Hidden from view, clicks, and screen readers */
		visibility: hidden;
	}
	div > textarea {
		/* You could leave this, but after a user resizes, then it ruins the auto sizing */
		resize: none;
		/* Firefox shows scrollbar on growth, you can hide like this. */
		overflow: hidden;
	}
	div > textarea,
	div::after {
		/* Identical styling required!! */
		/*border: 1px solid black;*/
		padding: 0.5em;
		font: inherit;
		/* Place on top of each other */
		grid-area: 1 / 1 / 2 / 2;
	}
	/* highlight when there are changes */
	div,
	textarea {
		margin: 1px;
	}
</style>

<div bind:this={autoGrowElement}>
	<textarea
		readonly={readonly}
		{value}
		on:input
		class="form-control form-control-sm"
		{id}
	/>
</div>
