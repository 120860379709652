<script>
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let inline
	export let onCreate

	$: elementId = [ id, ...keypath ].join('.')
</script>

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	<div class="form-check" class:form-check-inline={inline}>
		<input
			class="form-check-input {errors.length ? 'is-invalid' : ''}"
			type="checkbox"
			id={elementId}
			disabled={readonly || disabled}
			checked={value}
			on:input={ event => { (form.data[id] || onCreate(create)); set(!!event.target.checked) } }
			on:*
		>
		<label for={elementId} class="form-check-label mb-0" style="font-weight: normal !important;">
			<slot />
		</label>
		{#each errors as error}
			<div class="invalid-feedback">{error.title}</div>
		{/each}
	</div>
</Field>
