<script>
	import Table from '@/component/table/Users.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import Icon from 'svelte-awesome'
	import { faPlus } from '@fortawesome/free-solid-svg-icons'
	import { listUsers } from '@/lib/controller/user.js'

	export let asr
	export let api
	export let listState

	let loading = true
	let data = []
	let included = []
	let links
	let errors

	listUsers(api, { 'filter[attributes.active]': listState === 'active' ? 'true' : 'false' })
		.then(response => {
			loading = false
			// TODO after things settle down, re-add: .filter(user => user.attributes?.name !== 'Integration Testing')
			// or something like that, to not clutter up the view
			data = [ ...data, ...response.data ]
			included = [ ...included, ...response.included ]
			links = response.links
		})
		.catch(error => {
			errors = Array.isArray(error.errors)
				? error.errors
				: [ error ]
		})
</script>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{api}
	{data}
	{links}
	{errors}
	{included}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>Users</h4>
		</div>
		<div class="col-auto text-end">
			<ul class="nav nav-tabs" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href={asr.makePath('app.users.list.active')}
					   class:active={listState === 'active'}>Active</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href={asr.makePath('app.users.list.inactive')}
					   class:active={listState === 'inactive'}>Inactive</a>
				</li>
			</ul>
		</div>
		<div class="col mt-2 text-end">
			<a href={asr.makePath('app.users.create', null, { inherit: false })}
			   class="btn btn-primary btn-sm text-light">
				<Icon data={faPlus} scale="1" class="ms-2 me-2"/>
				Add User
			</a>
		</div>
	</div>
</TableCardWrapper>
