<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import TypeIcon from '@/component/table-cells/TypeIcon.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import { resourceToLink } from '@/lib/resource-to-link.js'
	import QbChangeColumn from './QbChangeColumn.svelte'

	const dispatch = createEventDispatcher()

	export let asr
	export let row
	export let saving
	export let readonly

	let firstChange
	let additionalChanges
	$: {
		const [ first, ...more ] = row.changes
		firstChange = first
		additionalChanges = more
	}
	$: rowspan = additionalChanges?.length ? row.changes.length : 1

	let confirmPanelOpen
</script>

<style>
	:global(tr.hide-bottom-border td) {
		border-bottom: none;
	}
	td.hide-bottom-border {
		border-bottom: none;
	}
	td.mark-complete {
		width: 8em;
	}
</style>

<tr class:hide-bottom-border={confirmPanelOpen && !additionalChanges?.length}>
	<td class="text-center icon" {rowspan} class:hide-bottom-border={confirmPanelOpen}>
		<TypeIcon {row} column={ { showName: true, shortName: true, iconMuted: true } } />
	</td>
	<td {rowspan} class="id" class:hide-bottom-border={confirmPanelOpen}>
		<PlainLink {row} column={ { keypath: 'id', link: row => resourceToLink(asr, row, false) } } />
	</td>
	<td class="mark-complete" {rowspan} class:hide-bottom-border={confirmPanelOpen}>
		{#if !readonly && !confirmPanelOpen}
			<button class="btn btn-sm btn-outline-secondary" on:click={() => { confirmPanelOpen = true }}>
				Sync
			</button>
		{/if}
	</td>
	<QbChangeColumn {row} change={firstChange} />
</tr>
{#if additionalChanges?.length}
	{#each additionalChanges as change, index}
		<tr class:hide-bottom-border={confirmPanelOpen && (index + 1) >= additionalChanges.length}>
			<QbChangeColumn {row} {change} />
		</tr>
	{/each}
{/if}

{#if confirmPanelOpen}
	<tr>
		<td colspan="8">
			<p class="mb-2">
				Have all necessary changes been synced to QuickBooks?
			</p>
			<p>
				<button class="btn btn-sm btn-outline-secondary" on:click={() => { confirmPanelOpen = false }}>
					Cancel
				</button>
				<button class="btn btn-sm btn-primary" on:click={() => dispatch('completed', row)}>
					<NamedIcon name="sync" /> Yes, Mark as Completed
				</button>
				{#if saving}
					<NamedIcon name="loading" />
				{/if}
			</p>
		</td>
	</tr>
{/if}
