import template from './SentEmail.svelte'
import { get } from '@/lib/json-fetch.js'
import { getSentEmailById } from '@/lib/controller/sent-email.js'

export default {
	data: {
		title: 'View Sent Email',
	},
	template,
	resolve: async (_, { sentEmailId }) => {
		return {
			email: await getSentEmailById({ get }, sentEmailId, { include: [ 'sendingUser', 'signableDocument' ] }),
		}
	},
}
