import template from './Dashboard.svelte'
import { getCurrentUser } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Dashboard',
	},
	template,
	resolve: async () => ({
		user: await getCurrentUser({ get }),
	}),
}
