import { jsonApiRelationshipSingular, jsonApiRelationshipList } from '../util/model-builder-helpers.js'
import { ccTransactionStatus } from '../value/cc-transaction-status.js'

export const $selfFragment = {
	files: false,
	opportunity: false,
	user: false,
}

export const type = 'cc-transaction'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the credit card transaction.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				status: {
					description: 'The status of the Credit Card transaction.',
					enum: Object.keys(ccTransactionStatus),
				},
				note: {
					type: 'string',
					description: 'An optional short, explanatory note for this CC transaction.',
				},
				ran: {
					description: 'The date that the Credit Card was run.',
					type: 'string',
					format: 'date',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				files: {
					description: 'Files associated with this transaction, e.g. saved receipts.',
					...jsonApiRelationshipList('file'),
				},
				opportunity: {
					description: 'The opportunity related to this CC transaction.',
					...jsonApiRelationshipSingular('opportunity'),
				},
				user: {
					description: 'OnTrack user who initiated or recorded the transaction.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
