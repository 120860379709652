<script>
	import { typeToAutoIncrementSuffix } from '@/shared/value/type-to-auto-increment-suffix.js'

	export let row
	export let column

	$: text = typeToAutoIncrementSuffix[row.attributes.resourceType]
		? row.attributes.resourceId?.slice(0, -1)
		: (row.attributes.resourceId || 'N/A')
	$: link = column && column.link && column.link(row)
</script>

<a href={link || '#'}>
	{row.attributes.resourceId}
</a>
