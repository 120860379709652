<script>
	const statusToIcon = {
		REQUESTED: 'fa fa-spinner fa-spin',
		IN_PROGRESS: 'fa fa-spinner fa-spin',
		BLOCKED: 'fa fa-hand-paper',
		FAILED: 'fa fa-exclamation-triangle',
		COMPLETED: 'fa fa-check-square',
		CANCELLED: 'fa fa-check-square',
	}
	const statusToColor = {
		BLOCKED: 'text-info',
		FAILED: 'text-danger',
		COMPLETED: 'text-success',
		// CANCELLED: '',
	}

	export let status
	export let hasErrors
	$: color = hasErrors ? 'text-danger' : statusToColor[status]
</script>

<span class={color || ''} style="{status === 'REQUESTED' || status === 'CANCELLED' ? 'color: #aaa;' : ''}">
	<span class={statusToIcon[status]}></span>
</span>
