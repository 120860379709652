<script>
	import Table from '@/component/Table.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import PlainTextRel from '@/component/table-cells/PlainTextRel.svelte'

	// export let asr
	export let data
	export let errors
	export let included = []
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'created',
		'initiated',
		'purpose',
		'status',
		'requestCount',
	]

	const nameToColumn = {
		purpose: {
			title: 'Purpose',
			component: PlainText,
			keypath: 'attributes.purpose',
			// link: row => asr.makePath('app.companies.edit.companyId', { companyId: row.id }),
		},
		status: {
			title: 'Status',
			component: PlainText,
			keypath: 'attributes.status',
		},
		requestCount: {
			title: 'Requests',
			component: PlainText,
			keypath: 'attributes.requestCount',
		},
		created: {
			title: 'Date',
			component: DateTime,
			keypath: 'meta.created',
			time: true,
		},
		initiated: {
			title: 'Initiated',
			relKeypath: 'attributes.name',
			keypath: 'relationships.user.data.id',
			component: PlainTextRel,
			alt: 'Internal Service',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
