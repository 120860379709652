<script>
	export let slim
</script>
<div class="card mb-3">
	<div class="card-header" class:p-2={slim}>
		<slot />
	</div>
	{#if $$slots.nav}
		<div class="card-body bg-light" class:p-2={slim}>
			<div class="row">
				<div class="col">
					<div class="tab-content">
						<div class="tab-pane preview-tab-pane active" role="tabpanel">
							<slot name="nav" />
						</div>
					</div>
				</div>
				{#if $$slots.sideNav}
					<div class="col-auto">
						<slot name="sideNav" />
					</div>
				{/if}
			</div>
		</div>
	{/if}
</div>
