<script>
	import { Field } from 'jsonapi-svelte-form'
	import NavHeader from './NavHeader.svelte'
	import OpportunityForm from '@/component/form/OpportunityForm.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import JobStatusChecker from '@/component/JobStatusChecker.svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import SaveAndConvert from './SaveAndConvert.svelte'
	import LegSyncableChanges from './LegSyncableChanges.svelte'
	import { updateOpportunity, patchOpportunity } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'
	import { changesSyncableToCentralDispatch } from '@/shared/util/changes-syncable-to-central-dispatch.js'

	export let asr
	export let api
	export let form
	export let companyData
	export let referrers = []
	export let users = []
	export let jobs = []
	export let usersById

	$: status = form.data[form.primaryId]?.attributes?.status
	$: readonlyNote = ![ 'changed', 'unsaved' ].includes(form.state) && !(status === 'lead' || status === 'quote')
	$: jobsInProcess = jobs.filter(job => [ 'REQUESTED', 'IN_PROGRESS' ].includes(job.attributes.status))
	$: legIdToIndex = form.data[form.primaryId]?.relationships?.legs?.data?.map(rel => rel.id)
		.reduce((map, id, index) => {
			map[id] = index
			return map
		}, {})
	$: forDispatch = status === 'order' && form.data[form.primaryId].attributes.legDates?.assign
	$: syncableChanges = changesSyncableToCentralDispatch({
		original: {
			data: form.original[form.primaryId],
			includedById: form.original,
		},
		updated: {
			data: form.data[form.primaryId],
			includedById: form.data,
		},
		otherIncludedById: {
			...(companyData?.byId || {}),
			...(companyData?.includedById || {}),
		},
	})

	let showChangeNote
	let changeNote = ''
	let onlyOntrackSync
	let syncToCentralJob
	let syncToCentralJobSuccess

	const errorHandler = ({ errors, state }) => {
		form.errors = errors
		form.state = state
	}
	const save = ({ detail: redirectAfterSave }) => {
		form.state = 'saving'
		delete form.errors
		return updateOpportunity(api, { form, changeNote, onlyOntrackSync })
			.then(updated => {
				if (!onlyOntrackSync && syncableChanges?.changes?.length && updated?._meta?.job) {
					syncToCentralJob = updated._meta.job
				}
				form = updated
				if (redirectAfterSave) router.go('app.opportunities.edit.opportunityId.overview', { opportunityId: form.primaryId })
			})
			.catch(errorHandler)
	}
	const handleOntrackSyncJob = ({ detail: updatedJob }) => {
		if (updatedJob?.attributes?.status === 'COMPLETED') syncToCentralJobSuccess = true
		syncToCentralJob = updatedJob
	}
	const updateStatus = statusToChangeTo => current => patchOpportunity(api, current, {
		id: current.primaryId,
		meta: current.data[current.primaryId].meta,
		attributes: {
			status: statusToChangeTo,
		},
	}, 0).then(() => {
		router.go('app.opportunities.edit.opportunityId.communication', { opportunityId: form.primaryId, convertedTo: statusToChangeTo })
	})
	const saveAndContinue = ({ detail: statusToChangeTo }) => {
		form.state = 'saving'
		delete form.errors
		if (Object.keys(form.changes)) {
			updateOpportunity(api, { form, changeNote, onlyOntrackSync, delay: 0 })
				.then(updateStatus(statusToChangeTo))
				.catch(errorHandler)
		} else {
			updateStatus(statusToChangeTo)(form)
				.catch(errorHandler)
		}
	}
	const evaluateJob = ({ detail: updatedJob }) => {
		jobs = [ ...jobs.map(job => job.id === updatedJob.id ? updatedJob : job) ]
	}

	const removeFromAlerts = index => {
		const copy = [ ...form.data[form.primaryId].attributes.alerts ]
		return copy.slice(0, index).concat(copy.slice(index + 1))
	}
</script>

<NavHeader {asr} {form} {users} {usersById} activeTab="edit" />

<div class="row gx-3">
	{#if status === 'lead'}
		{#if form.data[form.primaryId].attributes.duplicateIds?.length}
			<div class="col-auto">
				<div class="alert alert-warning py-2">
					Duplicate of:
					{#each form.data[form.primaryId].attributes.duplicateIds as opportunityId, index}
						<a
							href={asr.makePath(null, { opportunityId }, { inherit: false })}
						>{opportunityId.replace(/P$/, '')}</a>{#if (index + 1) < form.data[form.primaryId].attributes.duplicateIds.length},&nbsp;{/if}
					{/each}
				</div>
			</div>
		{/if}
	{/if}
	{#if form.data[form.primaryId].attributes.alerts?.length}
		<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'alerts' ]} let:set>
			{#each form.data[form.primaryId].attributes.alerts as { level, message }, index}
				<div class="col-auto">
					<div class="alert alert-{level} py-2">
						{message}
						<button
							type="button"
							class="btn-close"
							on:click={() => set(removeFromAlerts(index))}
						></button>
					</div>
				</div>
			{/each}
		</Field>
	{/if}
</div>

{#if jobsInProcess.length}
	{#each jobsInProcess as job}
		{@const legId = job.relationships?.leg?.data?.id}
		{@const legNumber = legId && legIdToIndex[legId] + 1}
		<div class="alert alert-warning">
			<JobStatusChecker {api} asr={router} jobId={job.id} on:stopped={evaluateJob}>
				<span slot="first" let:jobLink>
					There is a job running for {#if legId}<strong>Leg {legNumber}</strong> of{/if} this
					opportunity. You can wait a minute for it to finish, or look at the <a href={jobLink}>Job Inspector ({job.id})</a>
					for more details or to cancel it.
				</span>
				<span slot="stopped" let:jobLink>
					There is a job running for {#if legId}<strong>Leg {legNumber}</strong> of{/if} this
					opportunity. You can wait a minute for it to finish, or look at the <a href={jobLink}>Job Inspector ({job.id})</a>
					for more details or to cancel it.
				</span>
				<span slot="waiting" let:jobLink>
					There is a job running for {#if legId}<strong>Leg {legNumber}</strong> of{/if} this
					opportunity. You can wait a minute for it to finish, or look at the <a href={jobLink}>Job Inspector ({job.id})</a>
					for more details or to cancel it.
				</span>
			</JobStatusChecker>
		</div>
	{/each}
{/if}

<OpportunityForm bind:form {api} {companyData} {referrers} {users} readonly={jobsInProcess.length} />

<FormErrorsOther {form} />

{#if status !== 'lead'}
	<div class="form-check form-check-inline">
		<input
			class="form-check-input"
			type="checkbox"
			id="include-change-note"
			disabled={readonlyNote}
			bind:checked={showChangeNote}
		>
		<label for="include-change-note" class="form-check-label" style="font-weight: normal !important;">
			Include note for changes? (Visible in the opportunity overview timeline and history.)
		</label>
	</div>
	{#if showChangeNote}
		<div class="mb-3">
			<input
				type="text"
				class="form-control"
				{readonlyNote}
				bind:value={changeNote}
			>
		</div>
	{/if}
{/if}

{#if !onlyOntrackSync && syncableChanges.errors}
	{#each syncableChanges.errors as error, index}
		<ErrorAlert {error} class="mb-3" />
	{/each}
{/if}

{#if !onlyOntrackSync && syncableChanges.warnings}
	{#each syncableChanges.warnings as error, index}
		<ErrorAlert {error} class="mb-3" level="warning" />
	{/each}
{/if}

{#if !onlyOntrackSync && syncableChanges.changes?.length}
	<div class="alert alert-info">
		<p class="mb-0">When you save this order, the following changes will be synced to Central Dispatch:</p>
		{#each syncableChanges.changes as { legId, changes }}
			<div class="row mb-3" class:mb-3={legIdToIndex[legId] < (syncableChanges.changes.length - 1)}>
				<div class="col-auto" style="width: 6em;">
					<h5 class="mt-2">Leg {legIdToIndex[legId] + 1}</h5>
				</div>
				<div class="col">
					<LegSyncableChanges {changes} />
				</div>
			</div>
		{/each}
	</div>
{/if}

<SaveAndConvert
	syncToCentralDispatch={!onlyOntrackSync && syncableChanges.changes?.length}
	bind:form
	on:save={save}
	on:continue={saveAndContinue}
/>

{#if syncToCentralJob}
	<div class="alert mt-3" class:alert-warning={!syncToCentralJobSuccess} class:alert-success={syncToCentralJobSuccess}>
		<JobStatusChecker {api} asr={router} jobId={syncToCentralJob.id} on:stopped={handleOntrackSyncJob}>
			<span slot="first" let:jobLink>
				Syncing to Central Dispatch, one moment... <a href={jobLink}>({syncToCentralJob.id})</a>
			</span>
			<span slot="stopped" let:jobLink>
				There may be a problem syncing to Central Dispatch, have a look at the <a href={jobLink}>Job Inspector ({syncToCentralJob.id})</a>
				for more details or to cancel it.
			</span>
			<span slot="error" let:jobLink>
				An error occurred while syncing to Central Dispatch, have a look at the <a href={jobLink}>Job Inspector ({syncToCentralJob.id})</a>
				for more details.
			</span>
			<span slot="waiting" let:jobLink>
				Syncing to Central Dispatch, one moment... <a href={jobLink}>({syncToCentralJob.id})</a>
			</span>
			<span slot="completed">
				Changes synced to Central Dispatch 🎉
			</span>
		</JobStatusChecker>
	</div>
{/if}

{#if syncableChanges.changes?.length}
	<div class="form-check mt-3 mb-0">
		<input
			disabled={form.state === 'saving'}
			class="form-check-input"
			type="checkbox"
			id="leg-deliver-only-ontrack"
			checked={onlyOntrackSync}
			on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
		>
		<label for="leg-deliver-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
			Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
			and will <strong>not</strong> send a notification email.
		</label>
	</div>
{/if}
