<script>
	import TableHeadColumn from './TableHeadColumn.svelte'
	import { keyBy } from '@/shared/util/key-by.js'

	export let makeLink
	export let sort
	export let columns
	export let data
	export let included
	export let small
	export let hover
	export let loading = false
	export let firstColumnScopeRow = true
	export let noDataFoundText = 'No data available.'

	$: includedById = included && keyBy(included, 'id')
	$: colspan = columns && columns.reduce((sum, col) => sum + (col.titles && col.titles.length || 1), 0)
</script>

<table class:table-sm={small} class:table-hover={hover} class="table table-striped mb-0" style="border-top: 1px solid #eee;">
	{#if columns}
		<thead class="bg-200 text-900">
			<tr>
				{#each columns as { title, titles, keypath, sortable, columnClass }}
					{#if titles}
						{#each titles as title}
							<TableHeadColumn {makeLink} {title} {keypath} {columnClass} {sort} {sortable} on:clicksort />
						{/each}
					{:else}
						<TableHeadColumn {makeLink} {title} {keypath} {columnClass} {sort} {sortable} on:clicksort />
					{/if}
				{/each}
			</tr>
		</thead>
	{/if}
	<tbody class="list">
		{#if data && data.length}
			{#each data as row (row.id)}
				<tr data-id={row.id || ''}>
					{#each columns as column, index}
						{#if !index && firstColumnScopeRow}
							<th scope="row" class={column.columnClass || column.keypath || ''}>
								<svelte:component this={column.component} {row} {column} {includedById} />
							</th>
						{:else if column.titles}
							<svelte:component this={column.component} {row} {column} {includedById} />
						{:else}
							<td class={column.columnClass || column.keypath || ''}>
								<svelte:component this={column.component} {row} {column} {includedById} />
							</td>
						{/if}
					{/each}
				</tr>
			{/each}
		{:else}
			<tr>
				<td {colspan}>
					<div class="text-center mt-3 mb-3">
						{#if loading}
							Loading...
						{:else}
							{noDataFoundText}
						{/if}
					</div>
				</td>
			</tr>
		{/if}
	</tbody>
</table>
