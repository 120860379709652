<script>
	import { Field, Form } from 'jsonapi-svelte-form'
	import InputCheckbox from '@/component/atom/InputCheckbox.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { emailTemplateModels } from '@/shared/value/email-template-models.js'
	import { emailSender } from '@/shared/value/email-sender.js'
	import InputSelectId from '@/component/atom/InputSelectId.svelte'
	import LiquidTemplateEditor from '@/component/molecule/LiquidTemplateEditor.svelte'
	import opportunityModel from '@/shared/model/email-template-opportunity.model.js'
	import userModel from '@/shared/model/email-template-user.model.js'
	import { renderLiquidTemplate } from '@/lib/render-liquid-template.js'
	import { formatDate } from '@/shared/util/generate-opportunity-email-parameters.js'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'

	export let form
	export let signableTemplatesOptions

	const exampleIdParam = '{{id}}'
	const customNoteTag = '{{note}}'

	const modelToModel = {
		'email-template-opportunity': opportunityModel,
		'email-template-user': userModel,
	}
	const modelToExample = {
		'email-template-opportunity': {
			note: 'This is what a custom note would look like.\n\nIt can also **contain Markdown** and newlines.',
			id: '9001',
			totalQuote: 850.00,
			isAutoQuoteDuplicate: true,
			locationNotSupported: true,
			couldNotVerifyVehicle: true,
			firstAvailableDropoff: formatDate('2022-05-16'),
			signableDocumentUrl: 'https://mcsontrack.com/#/customer/sign/123456789',
			vehicles: [
				{
					quote: 450.00,
					year: 1989,
					make: 'Geo',
					model: 'Metro',
				},
				{
					quote: 400.00,
					year: 1988,
					make: 'Suzuki',
					model: 'Swift',
					shippingMethod: 'topLoad',
				},
				{
					quote: 415.00,
					year: 1992,
					make: 'Ford',
					model: 'Festiva',
					shippingMethod: 'enclosed',
				},
			],
			assigned: {
				email: 'tobias@mcsontrack.com',
				name: 'Tobias Davis',
				phone: '402-123-4567 ext 305',
			},
			customer: {
				company: 'Big Cars Inc.',
				firstName: 'Johnjacob',
				lastName: 'Jingleheimerschmidt',
				email: 'jim@bigcars.com',
				phone: '800-867-5309',
			},
			pickup: {
				firstAvailablePickup: formatDate('2022-05-13'),
				actualPickup: formatDate('2022-05-12'),
				company: 'Big Movers LLC',
				address: {
					line1: '123 West South St',
					line2: 'Suite 123',
					city: 'Omaha',
					state: 'NE',
					zip: '68123',
				},
			},
			deliver: {
				actualDeliver: formatDate('2022-05-16'),
				company: 'Small Holders Inc',
				address: {
					line1: '456 East North St',
					line2: 'Suite 789',
					city: 'North Platte',
					state: 'NE',
					zip: '69151',
				},
			},
		},
		'email-template-user': {},
	}

	$: modelName = form.data[form.primaryId]?.attributes?.model
	$: exampleSubjectPromise = renderLiquidTemplate({
		view: form.data[form.primaryId]?.attributes?.subject || '',
		model: modelToExample[modelName] || {},
	})
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Template Information
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<Form bind:form>
			<div class="row g-3">
				<div class="col-md-6">
					<InputType
						type="text"
						label="Name"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'name' ]}
					/>
					<InputType
						type="text"
						label="Subject"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'subject' ]}
					/>
					<p class="form-text">
						You can use template parameters like <code>{exampleIdParam}</code> in the subject.
					</p>
					{#await exampleSubjectPromise}
						<!-- empty -->
					{:then example}
						<p class="form-text">
							Example Subject: {example}
						</p>
					{:catch error}
						<pre>{error}</pre>
					{/await}
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'showForOpportunityStatus', 'lead' ]}
					>
						<NamedIcon name="lead" />
						Show email for Opportunity <em>Leads</em>.
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'showForOpportunityStatus', 'quote' ]}
					>
						<NamedIcon name="quote" />
						Show email for Opportunity <em>Quotes</em>.
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'showForOpportunityStatus', 'order' ]}
					>
						<NamedIcon name="order" />
						Show email for Opportunity <em>Orders</em>.
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'useForAutoQuote' ]}
					>
						<NamedIcon name="autoQuote" />
						Show email for Opportunity <em>Auto Quotes</em>.
					</InputCheckbox>
					{#if modelName === 'email-template-opportunity'}
						<InputSelectRel
							short
							label="Signable Template"
							labelWidth="12em"
							bind:form
							options={signableTemplatesOptions}
							id={form.primaryId}
							name="signableTemplate"
							type="signable-template"
						/>
						<p class="form-text">
							If set, and if the <code style="font-size: 90%">signableDocumentUrl</code> variable
							is set in the email template, when this email is sent a URL will be generated that
							is fully unique to that opportunity.
						</p>
					{/if}
					<InputSelectId
						label="Template is For"
						readonly
						labelWidth="175px"
						bind:form
						options={emailTemplateModels}
						id={form.primaryId}
						keypath={[ 'attributes', 'model' ]}
					/>
					<InputSelectId
						label="Email Sender"
						labelWidth="175px"
						bind:form
						options={emailSender}
						id={form.primaryId}
						keypath={[ 'attributes', 'sender' ]}
					/>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'customNote' ]}
					>
						Template supports adding a custom note.
					</InputCheckbox>
					{#if form.data[form.primaryId]?.attributes?.customNote}
						<p class="form-text">
							Place in the template with the <code>{customNoteTag}</code> tag.
							Note the <em>underscore</em> prefix!
						</p>
					{/if}
					{#each (form.data[form.primaryId]?.attributes?.bccs || []) as bcc, index}
						<Field
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'bccs' ]}
							let:set
							let:value
							let:disabled
						>
							<div class="row">
								<div class="col">
									<InputType
										type="text"
										label="BCC Email Address"
										labelWidth="175px"
										bind:form
										id={form.primaryId}
										keypath={[ 'attributes', 'bccs', index ]}
									/>
								</div>
								<div class="col-auto">
									<button
										class="btn btn-sm btn-secondary"
										on:click={() => { value.splice(index, 1); set([ ...value ]) }}
										{disabled}
									>
										<NamedIcon name="trash" />
									</button>
								</div>
							</div>
						</Field>
					{/each}
					<Field
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'bccs' ]}
						let:set
						let:value
						let:disabled
					>
						<button class="btn btn-sm btn-primary" {disabled} on:click={() => set([ ...(value || []), '' ])}>
							<NamedIcon name="email" />
							Add BCC Email Address
						</button>
						{#if form.data[form.primaryId]?.relationships?.signableTemplate}
							<p class="form-text">
								Emails sent with Signable Documents will <em>not</em> send BCC or CC emails. If you set a BCC
								email here, an additional <em>new</em> email will be sent to this address, without a functioning
								Signable Document URL.
							</p>
						{/if}
					</Field>
				</div>
			</div>
			<LiquidTemplateEditor
				label="Template Editor"
				bind:form
				id={form.primaryId}
				keypath={[ 'attributes', 'view' ]}
				model={modelToModel[modelName]}
				exampleModelData={modelToExample[modelName]}
				rows="12"
			/>
		</Form>
	</div>
</div>
