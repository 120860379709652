import { forgotPasswordFinalize } from '@/service/api/user.js'
import { minimumDelay } from '@/lib/delay.js'
import { AuthSimpleResetPassword } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'
import { router } from '@/service/router.js'

export default {
	template: AuthSimpleResetPassword,
	resolve: (data, parameters) => Promise.resolve({
		config,
		form: {
			reset: {
				token: parameters.token,
			},
		},
	}),
	activate: ({ domApi }) => {
		domApi.$on('submit', ({ detail: { name, form } }) => {
			form._state = {
				submitting: true,
			}
			domApi.$set({ form })
			minimumDelay(1000, forgotPasswordFinalize({ token: form[name].token, password: form[name].password }))
				.then(() => {
					domApi.$set({
						disabled: false,
						success: true,
					})
					router.go('app')
				})
				.catch(error => {
					form._state = {
						errors: error.body.errors,
					}
					domApi.$set({ form })
				})
		})
	},
}
