<script>
	import ErrorList from '@/component/ErrorList.svelte'
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import JobStatusChecker from '@/component/JobStatusChecker.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { router } from '@/service/router.js'

	export let asr
	export let api

	let url = ''
	let saving
	let jobId
	let errors

	const getQueryParam = key => url.split('?')[1]?.split('&')
		.find(s => s.startsWith(key))?.split('=')[1]

	$: oldId = url.includes('site.centraldispatch.com/protected/rating/client-snapshot')
		&& url.includes('id=')
		&& getQueryParam('id=')
	$: newId = url.includes('app.centraldispatch.com/ratings/overview')
		&& url.includes('customerid=')
		&& getQueryParam('customerid=')
	$: carrierId = oldId || newId

	const create = () => {
		saving = true
		api
			.post('/api/v1/centralDispatch/importCarrier', { body: { meta: { carrierId } } })
			.then(response => {
				jobId = response.body.data.id
			})
			.catch(response => {
				errors = response.body.errors
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.companies', null, { inherit: false })}>
		Companies
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Create Company
	</span>
</FormHeaderMain>

<div class="card">
	<div class="card-body">
		<p>Create a carrier by pasting in their Central Dispatch URL.</p>
		<div class="input-group input-group-sm mb-2">
			<span class="input-group-text justify-content-center" style="width: 3em;">
				<label for="carrier-url" class="form-label-sm mb-0" style="font-weight: normal !important;">
					URL
				</label>
			</span>
			<input
				id="carrier-url"
				type="text"
				bind:value={url}
				class="form-control form-control-sm"
			>
		</div>
		<p class="form-text">The URL should look like <code style="font-size: small;">https://site.centraldispatch.com/protected/rating/client-snapshot?id=83413cff-0942-4cbf-a9b7-943773ab7f9a</code></p>
		<p>
			<button class="btn btn-sm btn-primary" disabled={!url || saving || !carrierId} on:click={create}>
				Import the Carrier
			</button>
		</p>
		{#if jobId}
			<JobStatusChecker {api} asr={router} {jobId}>
				<span slot="first">
					Grabbing carrier data from Central Dispatch, just a minute...
				</span>
				<span slot="stopped" let:jobLink>
					It's taking an unusually long time to grab data from Central Dispatch...
					You could look at the <a href={jobLink}>Job Inspector</a> for more details.
				</span>
				<span slot="waiting">
					Grabbing carrier data from Central Dispatch, just a minute...
				</span>
				<span slot="completed" let:job>
					Done! Carrier imported from Central Dispatch, you can
					<a href={asr.makePath('app.companies.edit.companyId.overview', { companyId: job.attributes.parameters.ontrackCompanyId }, { inherit: false })}>
						go edit it now.
					</a>
				</span>
				<span slot="error" let:jobLink>
					An error occurred while connecting to Central Dispatch. Look at the
					<a href={jobLink}>Job Inspector</a>
					for more details.
				</span>
			</JobStatusChecker>
		{/if}
		{#if errors}
			<ErrorList {errors} />
		{/if}
	</div>
</div>
