import template from './Upcoming.svelte'
import { dateRangeQueryParams } from './_date-range-from-query.js'
import { get } from '@/lib/json-fetch.js'
import { listUsers } from '@/lib/controller/user.js'

export default {
	data: {
		title: 'Upcoming Picked-Up Report',
	},
	template,
	querystringParameters: [
		...dateRangeQueryParams,
		'showOnlyIncomplete',
		'assignedId',
	],
	resolve: async (_, querystringParams) => {
		const { data } = await listUsers({ get })
		return { querystringParams, users: data, upcoming: 'receive' }
	},
}
