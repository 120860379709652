<script>
	import { router } from '@/service/router.js'
	import { showAutoQuote } from '@/service/store/current-user.js'

	export let autoQuoteCampaign
	export let form
	$: href = showAutoQuote
		&& autoQuoteCampaign?.id
		&& router.makePath('app.systemSettings.autoQuoteCampaigns.edit.autoQuoteCampaignId', { autoQuoteCampaignId: autoQuoteCampaign.id }, { inherit: false })
	$: decisions = form.data[form.primaryId].attributes?.autoQuoteDecisions
	const vehicleString = (vehicleId) => {
		const vehicle = form.data[vehicleId]?.attributes || {}
		return [ vehicle.year, vehicle.make, vehicle.model ].filter(Boolean).join(' ')
	}
</script>

{#if decisions?.errors?.length}
	<p>Errors encountered during Auto-Quote processing:</p>
	{#each decisions.errors as { message }, index}
		<div class="alert alert-danger" class:mb-0={index >= decisions.errors.length - 1}>
			{message}
		</div>
	{/each}
{:else if decisions}
	<p>Auto-Quote processing decisions:</p>
	<table class="table table-sm mb-3">
		<tbody>
			<tr>
				<td>
					<strong>Miles</strong>
				</td>
				<td>
					{decisions.milesMin} - {decisions.milesMax}
				</td>
			</tr>
			<tr>
				<td>
					<strong>
						Broker Fee Count
					</strong>
				</td>
				<td>
					{decisions.vehicleCount} vehicle{#if decisions.vehicleCount !== 'One'}s{/if}
				</td>
			</tr>
		</tbody>
	</table>
	<table class="table table-sm mb-0">
		<thead>
			<tr>
				<th>Vehicle</th>
				<th>Size</th>
				<th class="text-end">CP</th>
				<th class="text-end">BF</th>
			</tr>
		</thead>
		<tbody>
			{#each (decisions.vehicles || []) as { vehicleId, vehicleSize, carrierPay }}
				<tr>
					<td class="pe-3">{vehicleString(vehicleId)}</td>
					<td class="pe-3">{vehicleSize}</td>
					<td class="text-end">{carrierPay}</td>
					<td class="text-end">{decisions.brokerFeePerVehicle}</td>
				</tr>
			{/each}
		</tbody>
	</table>
{/if}
