import template from './Token.svelte'
import { getSignableDocumentByToken } from '@/lib/controller/signable-document.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Sign Document',
	},
	template,
	resolve: async (_, { token }) => {
		try {
			const response = await getSignableDocumentByToken({ get }, token)
			return {
				token,
				signableDocument: response.data,
				visitId: response.relationships?.signableDocumentVisit?.data?.id,
			}
		} catch (error) {
			return { errors: error.body.errors }
		}
	},
}
