<script>
	let printing
	const before = () => {
		printing = true
		document.body.className = (document.body.className || '') + ' printing'
	}
	const after = () => {
		printing = false
		document.body.className = (document.body.className || '')
			.split(/\s+/)
			.filter(s => s !== 'printing')
			.join(' ')
	}
	const printPage = () => {
		before()
		setTimeout(() => {
			window.print()
			after()
		})
	}
</script>

<svelte:window on:beforeprint={before} on:afterprint={after} />

<svelte:body class:printing />

{#if !printing}
	<p>
		<button class="btn btn-primary" on:click={printPage}>
			<slot />
		</button>
	</p>
{/if}
