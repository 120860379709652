<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import ConfirmMailContent from '../../../mixins/authentication/ConfirmMailContent.svelte'
	import deepmerge from 'deepmerge'

	export let system

	export let config
	export let form = {
		confirm: {
			email: '',
		},
	}

	$: opts = deepmerge(
		{
			layout: {
				title: 'falcon',
				icon: 'vendor/falcon/assets/img/illustrations/falcon.png',
			},
		},
		config || {},
	)
</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout}>
		<ConfirmMailContent
			name="confirm"
			{form}
			{system}
			class="text-center"
		/>
	</LayoutBasic>
</LayoutTheme>
