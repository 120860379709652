<script>
	import InputType from '@/component/atom/InputType.svelte'

	export let form
	export let contactParentRelId
	export let contactRelName
	export let readonly

	$: id = form.data[contactParentRelId]?.relationships?.[contactRelName]?.data?.id

	const onCreate = create => create({ relId: contactParentRelId, relName: contactRelName, type: 'contact' })
</script>

<InputType
	type="text"
	label="Company"
	icon="company"
	labelWidth="120px"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'companyName' ]}
/>

<InputType
	type="text"
	label="First Name"
	labelWidth="120px"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'firstName' ]}
/>

<InputType
	type="text"
	label="Last Name"
	labelWidth="120px"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'lastName' ]}
/>
