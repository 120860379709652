<script>
	import { currentUser } from '@/service/store/current-user.js'
	export let form
	export let label
	export let disabled

	let showRaw
</script>

<div class="row">
	<div class="col">
		<slot name="before"></slot>
		<button
			class="btn btn-primary me-3"
			disabled={![ 'changed', 'unsaved' ].includes(form.state) || disabled}
			on:click
			style="min-width: 200px;"
		>
			{#if form.state === 'saving'}
				<span><span class="fas fa-spinner fa-pulse"></span></span>
			{:else}
				<span><span class="fa fa-save"></span></span>
			{/if}
			{#if label}
				{label}
			{:else}
				Sav{#if form.state === 'saving'}ing{:else}e{/if} Changes
			{/if}
		</button>
		{#if form.state === 'saved'}
			<span class="text-success">
				<span class="fa fa-check-square fa-lg"></span>
			</span>
			Changes saved!
		{/if}
		{#if form.state === 'error'}
			<span class="text-danger">
				<span class="fa fa-exclamation-triangle fa-lg"></span>
			</span>
			Error while saving!
		{/if}
	</div>
	{#if $currentUser?.attributes?.permissions?.manageApp}
		<div class="col-auto">
			<button class="btn btn-secondary" on:click={() => showRaw = !showRaw}>
				{showRaw ? 'Hide' : 'Show'}
				Raw Data
			</button>
		</div>
	{/if}
</div>

{#if showRaw}
	<pre style="border: 1px solid #ddd; padding: 1em; background-color: #fff; margin: 1em 0;">{JSON.stringify(form, undefined, 4)}</pre>
{/if}
