import createStateRouter from 'abstract-state-router'
import SvelteRenderer from 'svelte-state-renderer'
import { system } from '@/service/store/system.js'
import * as api from '@/lib/json-fetch.js'

export const router = createStateRouter(
	SvelteRenderer({
		props: {
			system,
			api,
		},
	}),
	document.querySelector('body'),
)
