<script>
	import { Form } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'
	import InputCheckbox from '@/component/atom/InputCheckbox.svelte'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form
</script>

<style>
	p {
		font-size: small;
		margin-top: 0;
	}
</style>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Referrer Source Information
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<Form bind:form>
			<div class="row g-3">
				<div class="col-xs-12 col-md-8 col-lg-6">
					<InputType
						type="text"
						label="Name"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'name' ]}
					/>
					<InputType
						type="text"
						label="QB Item"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'accountingId' ]}
					/>
					<p class="form-text">The "item" identifier used in QuickBooks.</p>
					<InputType
						type="text"
						label="Old BATS AuthKey"
						labelWidth="200px"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'key' ]}
					/>
					<InputType
						type="text"
						label="Code"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'code' ]}
					/>
					<p class="form-text">
						The identifier used in external forms.
					</p>
				</div>
				<div class="col-xs-12 col-md-8 col-lg-6">
					<InputType
						type="text"
						label="Zap Name"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'zapName' ]}
					/>
					<InputType
						type="text"
						label="Tag"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'tag' ]}
					/>
					<p class="form-text">
						Set this property to allow grouping in the referrer lead reports.
					</p>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'active' ]}
					>
						Referrer is active
					</InputCheckbox>
				</div>
			</div>
		</Form>
	</div>
</div>
