import template from './Overview.svelte'
import { listCustomReports } from '@/lib/controller/custom-report.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Reports',
	},
	template,
	resolve: async () => {
		const { data } = await listCustomReports({ get })
		return {
			customReports: data,
		}
	},
}
