<script>
	import { Form } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'
	import InputSelectId from '@/component/atom/InputSelectId.svelte'
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { createEmailTemplate } from '@/lib/controller/email-template.js'
	import { router } from '@/service/router.js'
	import { emailTemplateModels } from '@/shared/value/email-template-models.js'
	import { emailSender } from '@/shared/value/email-sender.js'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let asr
	export let api
	export let form

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createEmailTemplate(api, form, response => router.go('app.systemSettings.emailTemplates.edit.emailTemplateId', { emailTemplateId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.systemSettings.emailTemplates', null, { inherit: false })}>
		Email Templates
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Create Template
	</span>
</FormHeaderMain>

<Form bind:form let:remove let:create>
	<div class="card mb-3">
		<div class="card-header py-2">
			<div class="row">
				<div class="col">
					Create Email Template
				</div>
			</div>
		</div>
		<div class="card-body bg-light py-2">
			<div class="row g-3">
				<div class="col-md-4">
					<InputType
						type="text"
						label="Template Name"
						labelWidth="175px"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'name' ]}
					/>
					<p class="form-text">
						You can change this later.
					</p>
					<InputSelectId
						label="Email Sender"
						labelWidth="175px"
						bind:form
						options={emailSender}
						id={form.primaryId}
						keypath={[ 'attributes', 'sender' ]}
					/>
					<p class="form-text">
						And you can change this later.
					</p>
					<InputSelectId
						label="Template is For"
						labelWidth="175px"
						bind:form
						options={emailTemplateModels}
						id={form.primaryId}
						keypath={[ 'attributes', 'model' ]}
					/>
					<p class="form-text">
						But you <strong><em>cannot</em></strong> change this later.
					</p>
				</div>
			</div>
		</div>
	</div>
</Form>

<FormErrorsOther {form} />

<FormCommitButton label="Create Email Template" {form} on:click={create} />
