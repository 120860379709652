<script>
	import LegLocationRow from './LegLocationRow.svelte'

	export let column
	export let row
	export let includedById
</script>

{#each (row.relationships?.legs?.data || []) as leg, legIndex}
	<LegLocationRow
		{column}
		{row}
		{includedById}
		legId={leg.id}
		{legIndex}
	/>
{/each}
