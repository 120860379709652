<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { createEventDispatcher } from 'svelte'
	import { clone } from '@/shared/util/clone.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { router } from '@/service/router.js'

	export let form
	export let status
	export let syncToCentralDispatch

	let showRaw

	const dispatch = createEventDispatcher()

	const undoChanges = () => {
		form.data = clone(form.original)
		form.changes = {}
		form.state = 'loaded'
	}

	$: status = form.data[form.primaryId]?.attributes?.status
	$: heldOrCancelled = form.data[form.primaryId]?.attributes?.held || form.data[form.primaryId]?.attributes?.cancelled
	$: unsavedChanges = [ 'changed', 'unsaved' ].includes(form.state)
	$: legMiles = form.data[form.primaryId]?.relationships?.legs?.data?.map(rel => form.data[rel.id])
		.reduce((sum, leg) => sum + (leg?.attributes?.miles || 0), 0)
	$: badMiles = status === 'order' && (
		!form.data[form.primaryId]?.attributes?.miles
		|| form.data[form.primaryId].attributes.miles !== legMiles
	)

	let confirmHardDelete
	let hardDeleting
	const hardDeleteOpportunity = () => {
		hardDeleting = true
		fetch(`/api/v1/opportunities/${form.primaryId}`, { method: 'DELETE' })
			.then(() => {
				router.go('app', undefined, { inherit: false })
			})
			.catch(error => {
				console.error('Error while deleting:', error)
			})
	}
</script>

{#if badMiles}
	<div class="alert alert-warning">
		The leg miles and overall miles don't add up. This value is used for referral reporting, so please fix it. (Or if this
		message is annoying, talk to a developer about figuring out a better way to track miles.)
	</div>
{/if}

<div class="row">
	<div class="col">
		{#if ![ 'lead', 'quote' ].includes(status) || heldOrCancelled}
			<button
				class="btn btn-outline-primary me-3"
				disabled={!unsavedChanges}
				on:click={() => dispatch('save', false)}
				style="min-width: 120px;"
			>
				<NamedIcon name="save" />
				{#if syncToCentralDispatch}
					<NamedIcon name="cd" />
				{/if}
				Save
				{#if syncToCentralDispatch}
					and Sync
				{/if}
				Only
			</button>
		{/if}
		<button
			class="btn me-3"
			class:btn-outline-primary={!unsavedChanges}
			class:btn-primary={unsavedChanges}
			disabled={!unsavedChanges}
			on:click={() => dispatch('save', true)}
			style="min-width: 120px;"
		>
			<NamedIcon name="save" />
			{#if syncToCentralDispatch}
				<NamedIcon name="cd" />
			{/if}
			Save{#if syncToCentralDispatch}, Sync, {/if}
			and Close
		</button>
		{#if (status === 'lead' || status === 'quote') && !heldOrCancelled}
			<button
				class="btn btn-primary me-3"
				disabled={form.state === 'saving' || form.state === 'error'}
				on:click={() => dispatch('continue', status === 'lead' ? 'quote' : 'order')}
				style="min-width: 200px;"
			>
				<span class="fa fa-save"></span>
				{#if unsavedChanges}
					Save and
				{/if}
				Convert to
				{#if status === 'lead'}
					Quote
				{:else}
					Order
				{/if}
			</button>
		{/if}
		{#if form.state === 'saving'}
			<span><span class="fas fa-spinner fa-pulse"></span></span>
		{/if}
		{#if form.state === 'saved'}
			<span class="text-success">
				<span class="fa fa-check-square fa-lg"></span>
			</span>
			Changes saved!
		{/if}
		{#if form.state === 'error'}
			<span class="text-danger">
				<span class="fa fa-exclamation-triangle fa-lg"></span>
			</span>
			Error while saving!
		{/if}
		{#if [ 'changed', 'unsaved' ].includes(form.state) && status !== 'lead'}
			<button
				class="btn btn-outline-secondary me-3"
				disabled={form.state === 'saving' || form.state === 'error'}
				on:click={undoChanges}
				style="min-width: 200px;"
			>
				<span class="fa fa-undo"></span>
				Undo Changes
			</button>
		{/if}
	</div>
	{#if $currentUser?.attributes?.permissions?.manageApp}
		<div class="col-auto">
			{#if confirmHardDelete}
				<button class="btn btn-secondary mb-3" on:click={() => { confirmHardDelete = false }} disabled={hardDeleting}>
					Cancel
				</button>
				<br>
				<button class="btn btn-danger" on:click={hardDeleteOpportunity} disabled={hardDeleting}>
					Yes, delete this forever, no backsies!
				</button>
			{:else}
				<button class="btn btn-danger" on:click={() => { confirmHardDelete = true }}>
					Hard-Delete Opportunity
				</button>
			{/if}
		</div>
		<div class="col-auto">
			<button class="btn btn-secondary" on:click={() => { showRaw = !showRaw }}>
				{showRaw ? 'Hide' : 'Show'}
				Raw Data
			</button>
		</div>
	{/if}
</div>

{#if showRaw}
	<pre style="border: 1px solid #ddd; padding: 1em; background-color: #fff; margin: 1em 0;">{JSON.stringify(form, undefined, 4)}</pre>
{/if}
