<script>
	import SpreadsheetDownloader from '@/component/atom/SpreadsheetDownloader.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'
	import { keyBy } from '@/shared/util/key-by.js'

	import FilterParam from './_FilterParam.svelte'
	import TableColumnDescriptions from './_TableColumnDescriptions.svelte'
	import { fetchReport } from './_fetch-report.js'
	import { everyOpportunityColumn, defaultOrderedColumns } from '@/component/report/opportunity/every-opportunity-column.js'

	export let asr
	export let api
	export let querystringParams

	let apiQueryParams
	$: {
		try {
			apiQueryParams = querystringParams?.q && JSON.parse(querystringParams?.q)
		} catch (ignore) {
			// bad JSON
		}
	}
	const filtersThatHaveInputBoxes = {
		minDateType: true,
		minDate: true,
		maxDateType: true,
		maxDate: true,
		referrerId: true,
		assignedId: true,
		dateExists: true,
		includeHeld: true,
		includeCancelled: true,
	}
	$: helpFilterParams = Object
		.keys(apiQueryParams?.filter || {})
		.filter(key => !filtersThatHaveInputBoxes[key])


	$: columns = everyOpportunityColumn({ asr })

	let helpOpen
	let currentSort
	let data
	let errors
	let loading = true
	let included
	$: {
		if (apiQueryParams) {
			fetchReport({ api, url: '/api/v1/reports/groupedOpportunities/id', params: apiQueryParams })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					if (response?.data) data = response.data
					if (response?.included) included = response.included
					currentSort = querystringParams.sort || '-id'
					loading = false
				})
		} else {
			loading = false
		}
	}
	$: includedById = included?.length ? keyBy(included, []) : []
	$: assignedUser = includedById && includedById[apiQueryParams.filter?.assignedId]
	$: referrer = includedById && includedById[apiQueryParams.filter?.referrerId]
	$: rows = !loading && data && tableizeReportData({
		body: { data },
		sort: currentSort,
		params: apiQueryParams,
		columns,
		orderedColumns: defaultOrderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns: defaultOrderedColumns,
		columns,
		label: 'Filtered Opportunities',
		filename: `filtered-opportunities-${new Date().toISOString().replaceAll(':', '')}.xlsx`,
		rows,
		params: apiQueryParams?.filter || {},
	})

	const dateTypeToLabel = {
		created: 'Created',
		quoted: 'Quoted',
		ordered: 'Ordered',
		firstDispatched: 'First Leg Dispatched',
		anyDispatched: 'Any Leg Dispatched',
		firstAvailablePickup: 'First Available P/U',
		firstReceivedContract: 'First Leg P/U (Contract)',
		firstReceivedActual: 'First Leg P/U (Actual)',
		anyReceivedContract: 'Any Leg P/U (Contract)',
		anyReceivedActual: 'Any Leg P/U (Actual)',
		lastDeliveredContract: 'Last Leg Delivered (Contract)',
		lastDeliveredActual: 'Last Leg Delivered (Actual)',
		anyDeliveredContract: 'Any Leg Delivered (Contract)',
		anyDeliveredActual: 'Any Leg Delivered (Actual)',
		invoiced: 'First Invoice',
	}
	const dateExistsToLabel = {
		firstAvailablePickup: '1st Avail. P/U',
		lastAvailablePickup: 'Last Avail. P/U',
		firstAvailableDropoff: '1st Avail. Deliver',
		quoted: 'Quoted',
		ordered: 'Ordered',
		firstDispatched: 'Any Leg Dispatched',
		firstInvoiced: 'Invoiced',
		firstPickupContract: 'First Leg P/U (Contract)',
		firstPickupActual: 'First Leg P/U (Actual)',
		lastDropoffContract: 'Last Leg Deliver (Contract)',
		lastDropoffActual: 'Last Leg Deliver (Actual)',
		held: 'Held',
		cancelled: 'Cancelled',
	}
</script>

<h3>
	<a href={asr.makePath('app.reports', null, { inherit: false })}>Reports</a>
	<small class="fa fa-chevron-right"></small>
	Opportunities Drill-Down
	{#if rows?.length}
		<small class="text-muted">{rows.length}</small>
	{/if}
	<button class="btn btn-sm btn-outline-secondary ms-4" on:click={() => { helpOpen = !helpOpen }}>
		<span class="fa fa-question"></span>
	</button>
	<SpreadsheetDownloader
		disabled={loading || !rows?.length}
		generator={generateSpreadsheetFile}
	/>
	<CopyToClipboard
		tableId={rows?.length && 'filtered-opportunities'}
	/>
</h3>

<div class="card">
	<div class="card-body p-2 pb-0">
		<div class="row">
			{#if apiQueryParams?.filter?.minDate}
				<FilterParam
					icon="date"
					label="Min. Date (UTC)"
					key={dateTypeToLabel[apiQueryParams?.filter?.minDateType] || '???'}
					value={apiQueryParams?.filter?.minDate}
				/>
			{/if}
			{#if apiQueryParams?.filter?.maxDate}
				<FilterParam
					icon="date"
					label="Max. Date (UTC)"
					key={dateTypeToLabel[apiQueryParams?.filter?.maxDateType] || '!!PLEASE FIX!!'}
					value={apiQueryParams?.filter?.maxDate}
				/>
			{/if}
			{#if apiQueryParams?.filter?.dateExists}
				<FilterParam
					icon="date"
					label="Date Exists"
					value={dateExistsToLabel[apiQueryParams?.filter?.dateExists] || '!!PLEASE FIX!!'}
				/>
			{/if}
			{#if apiQueryParams?.filter?.assignedId}
				<FilterParam
					icon="user"
					label="Assigned"
					value={assignedUser?.attributes?.name || apiQueryParams?.filter?.assignedId}
				/>
			{/if}
			{#if apiQueryParams?.filter?.referrerId}
				<FilterParam
					icon="user"
					label="Referrer"
					value={referrer?.attributes?.name || apiQueryParams?.filter?.referrerId}
				/>
			{/if}
			{#if apiQueryParams?.filter?.includeHeld}
				<FilterParam
					label="Include Held"
					value={apiQueryParams?.filter?.includeHeld}
				/>
			{/if}
			{#if apiQueryParams?.filter?.includeCancelled}
				<FilterParam
					label="Include Cancelled"
					value={apiQueryParams?.filter?.includeCancelled}
				/>
			{/if}
		</div>
	</div>
</div>

{#if helpOpen}
	<div class="card mt-3">
		<div class="card-body">
			{#if helpFilterParams.length}
				<p>
					This is a drill-down list of opportunities, filtered by the following properties:
				</p>
				<table class="table table-sm bg-light text-dark">
					<thead>
					<tr>
						<th>Key</th>
						<th>Value</th>
					</tr>
					</thead>
					<tbody>
					{#each helpFilterParams as key}
						<tr>
							<td>{key}</td>
							<td>{apiQueryParams.filter[key]}</td>
						</tr>
					{/each}
					</tbody>
				</table>
			{/if}
			<TableColumnDescriptions {columns} orderedColumns={defaultOrderedColumns} />
		</div>
	</div>
{/if}

<ReportTable
	id="filtered-opportunities"
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	orderedColumns={defaultOrderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
