<script>
	import OpportunityLegs from './OpportunityLegs.svelte'

	export let row
	export let includedById

	$: orders = (row.relationships?.orders?.data || [])
		.map(order => includedById[order.id])
	$: legs = orders
		.map(order => order.relationships?.legs?.data)
		.flat()
	$: vehicles = orders
		.map(order => order?.relationships?.vehicles?.data || [])
		.flat()
		.map(vehicle => includedById[vehicle?.id])
		.filter(Boolean)

</script>

<td>
	{#each orders as order}
		<div class="order-row">
			{#each vehicles as vehicle}
				{vehicle.attributes.year}&nbsp;{vehicle.attributes.make}&nbsp;{vehicle.attributes.model}
				<br>
			{/each}
		</div>
	{/each}
</td>

<td>
	<OpportunityLegs {legs} {includedById} />
</td>

<td>
	Shipping details?
</td>

