import NumberString from '@/component/atom/NumberString.svelte'
import { clone } from '@/shared/util/clone.js'

const percent = ratio => Math.round(ratio * 1000) / 10

const noFilter = opts => addFilter(opts, {})
const addFilter = ({ path, params }, filter) => {
	const q = clone(params)
	Object.assign(q.filter, filter || {})
	return [ path, { q: JSON.stringify(q) }, { inherit: false } ]
}

export const commonColumns = ({ asr, filteredLink, overallLink }) => ({
	referrer: {
		label: 'Referral Source',
		style: 'text',
		href: (row, { includedById }) => includedById[row.id] && row.id && asr.makePath('app.referrers.edit.referrerId', { referrerId: row.id }, { inherit: false }),
		value: (row, { includedById }) => includedById[row.id]?.attributes?.name || row.id || '',
	},
	assigned: {
		label: 'Assigned',
		style: 'text',
		href: (row, { includedById }) => includedById[row.id] && row.id && asr.makePath('app.users.edit.userId', { userId: row.id }, { inherit: false }),
		value: (row, { includedById }) => includedById[row.id]?.attributes?.name || row.id || '',
	},
	tag: {
		label: 'Tag',
		style: 'text',
		value: (row, { includedById }) => includedById[row.id]?.attributes?.tag || '',
	},
	bats: {
		label: 'BATS',
		style: 'number',
		href: (row, { params }) => row.leads && row.batsDuplicateLead && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ hasBatsId: 'true' }),
		),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({ hasBatsId: 'true' }), {})),
		value: (row) => row.batsDuplicateLead || 0,
		component: NumberString,
		description: 'This is a count of leads that were added to BATS and to OnTrack, where the BATS lead was marked as cancelled. Once all our data is migrated off BATS, this column will go away.',
	},
	leads: {
		label: 'Leads',
		style: 'number',
		href: (row, { params }) => row.leads && asr.makePath(...noFilter(filteredLink(row, params))),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({}), {})),
		value: (row) => row.leads || 0,
		component: NumberString,
	},
	quoted: {
		label: 'Quoted',
		style: 'number',
		href: (row, { params }) => row.quoted && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'quoted', includeHeld: 'true', includeCancelled: 'true' }),
		),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({ dateExists: 'quoted', includeHeld: 'true', includeCancelled: 'true' }), {})),
		value: (row) => row.quoted || 0,
		component: NumberString,
		description: 'This is the sum of opportunities that have been quoted, including opportunities that were later cancelled or placed on hold.',
	},
	lqConversion: {
		label: 'L-Q %',
		style: 'number',
		summable: false,
		value: (row) => percent(row.leads && row.quoted && (row.quoted / row.leads) || 0),
		description: 'The percent of Leads that became Quotes.',
	},
	ordered: {
		label: 'Ordered',
		style: 'number',
		href: (row, { params }) => row.ordered && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'ordered', includeHeld: 'false', includeCancelled: 'false' }),
		),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({ dateExists: 'ordered', includeHeld: 'false', includeCancelled: 'false' }), {})),
		value: (row) => row.ordered || 0,
		component: NumberString,
		description: 'This is the sum of opportunities that have been converted to an order, EXCLUDING opportunities that were later cancelled or placed on hold.',
	},
	qoConversion: {
		label: 'Q-O %',
		style: 'number',
		summable: false,
		value: (row) => percent(row.quoted && row.ordered && (row.ordered / row.quoted) || 0),
		description: 'The percent of Quotes that became Orders.',
	},
	dispatched: {
		label: 'Dispatched',
		style: 'number',
		component: NumberString,
		value: (row) => row.dispatched || 0,
		href: (row, { params }) => row.dispatched && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'firstDispatched', includeHeld: 'true', includeCancelled: 'true' }),
		),
		description: 'Opportunities where at least one Leg has been fully dispatched.',
	},
	received: {
		label: 'P/U',
		style: 'number',
		component: NumberString,
		value: (row) => row.firstReceivedActual || 0,
		href: (row, { params }) => row.firstReceivedActual && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'firstPickup', includeHeld: 'true', includeCancelled: 'true' }),
		),
		description: 'Opportunities where the first Leg has been marked as Picked Up. Note that it is possible a leg could be marked as delivered but not picked up.',
	},
	delivered: {
		label: 'Delivered',
		style: 'number',
		component: NumberString,
		value: (row) => row.lastDeliveredActual || 0,
		href: (row, { params }) => row.lastDeliveredActual && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'lastDropoff', includeHeld: 'true', includeCancelled: 'true' }),
		),
		description: 'Opportunities where the last Leg has been marked as Delivered.',
	},
	held: {
		label: 'Held',
		style: 'number',
		href: (row, { params }) => row.held && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'held' }),
		),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({ dateExists: 'held' }), {})),
		value: (row) => row.held || 0,
		component: NumberString,
	},
	cancelled: {
		label: 'Cancelled',
		style: 'number',
		href: (row, { params }) => row.cancelled && asr.makePath(...addFilter(
			filteredLink(row, params),
			{ dateExists: 'cancelled' }),
		),
		sumHref: overallLink && asr.makePath(...addFilter(overallLink({ dateExists: 'cancelled' }), {})),
		value: (row) => row.cancelled || 0,
		component: NumberString,
	},
	miles: {
		label: 'Miles',
		style: 'number',
		value: (row) => row.miles || 0,
		component: NumberString,
		description: 'Total number of miles for all opportunities, including cancelled and held.',
	},
	vehicles: {
		label: 'Vehicles',
		style: 'number',
		value: (row) => row.vehicles || 0,
		component: NumberString,
		description: 'Total vehicles for all opportunities, including cancelled and held.',
	},
	originalCarrierPay: {
		label: 'Original CP',
		style: 'number',
		value: (row) => row.originalCarrierPay || 0,
		component: NumberString,
		description: '(Original Carrier Pay) Sum of the carrier pay amounts prior to the opportunity being converted to an Order.',
	},
	originalBrokerFee: {
		label: 'Original BF',
		style: 'number',
		value: (row) => row.originalBrokerFee || 0,
		component: NumberString,
		description: '(Original Broker Fee) Sum of the broker fees prior to the opportunity being converted to an Order.',
	},
	dispatchedOriginalCarrierPay: {
		label: 'Dispatched OCP',
		style: 'number',
		value: (row) => row.dispatchedOriginalCarrierPay || 0,
		component: NumberString,
		description: '(Dispatched Original Carrier Pay) Sum of the original (pre-Order) carrier pay amounts only for opportunities where all Legs have been fully dispatched.',
	},
	dispatchedOriginalBrokerFee: {
		label: 'Dispatched OBF',
		style: 'number',
		value: (row) => row.dispatchedOriginalBrokerFee || 0,
		component: NumberString,
		description: '(Dispatched Original Broker Fee) Sum of the original (pre-Order) broker fees only for opportunities where all Legs have been fully dispatched.',
	},
	dispatchedActualCarrierPay: {
		label: 'Dispatched CP',
		style: 'number',
		value: (row) => row.dispatchedActualCarrierPay || 0,
		component: NumberString,
		description: '(Dispatched Carrier Pay) Sum of the actual, final carrier pay amounts only for opportunities where all Legs have been fully dispatched.',
	},
	dispatchedActualBrokerFee: {
		label: 'Dispatched BF',
		style: 'number',
		value: (row) => ((row.dispatchedOriginalCarrierPay + row.dispatchedOriginalBrokerFee) - row.dispatchedActualCarrierPay) || 0,
		component: NumberString,
		description: '(Dispatched Broker Fee) Sum of the broker fees only for opportunities where all Legs have been fully dispatched. (DOCP + DOBF - DCP)',
	},
	dispatchedOriginalTariff: {
		label: 'Dispatched OT',
		style: 'number',
		value: (row) => ((row.dispatchedOriginalCarrierPay || 0) + (row.dispatchedOriginalBrokerFee || 0)) || 0,
		description: '(Dispatched Original Tariff) The original carrier pay plus the original broker fee.',
	},
	carrierPayDiff: {
		label: 'CP Diff',
		style: 'number',
		value: (row) => (row.dispatchedOriginalCarrierPay - row.dispatchedActualCarrierPay) || 0,
		component: NumberString,
		description: '(Carrier Pay Diff) The difference between the original carrier pay and actual carrier pay, for opportunities where all Legs have been fully dispatched.',
	},
})
