import { addressStates } from '../value/address-states.js'
import { addressCountries } from '../value/address-countries.js'

export const type = 'address'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the address.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				line1: {
					description: 'The first line of the address.',
					type: 'string',
				},
				line2: {
					description: 'The second line of the address, typically left blank.',
					type: 'string',
				},
				city: {
					description: 'The city name of the address.',
					type: 'string',
				},
				state: {
					description: 'The state abbreviation of the address.',
					type: 'string',
					enum: Object.keys(addressStates),
				},
				zip: {
					description: 'The postal code of the address.',
					type: 'string',
				},
				country: {
					description: 'The country id of the address, e.g. `2` for Canada.',
					type: 'string',
					enum: Object.keys(addressCountries),
				},
			},
		},
	},
}
