<script>
	import dlv from 'dlv'

	export let row
	export let column
	$: value = dlv(row, column.keypath)
	$: json = value && (typeof value === 'string' ? JSON.parse(value) : value)
</script>

{#if json}
	{#if json.platform?.vendor}
		{json.platform.vendor}
		{#if json.os?.name}
			({json.os.name}{#if json.os.version}&nbsp;{json.os.version}{/if})
		{:else if json.platform.type}
			({json.platform.type})
		{/if}
		<br>
	{/if}
	{json.browser?.name || '?'}
	{#if json.browser?.version}
		{json.browser.version}
	{/if}
{:else}
	–
{/if}
