import { forgotPassword } from '@/service/api/user.js'
import { minimumDelay } from '@/lib/delay.js'
import { AuthSimpleForgotPassword } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'
import { router } from '@/service/router.js'

export default {
	template: AuthSimpleForgotPassword,
	resolve: (data, parameters) => Promise.resolve({
		config,
		form: {
			forgot: {
				email: parameters.email,
			},
		},
	}),
	activate: ({ domApi }) => {
		domApi.$on('submit', ({ detail: { name, form } }) => {
			form._state = {
				submitting: true,
			}
			domApi.$set({ form })
			minimumDelay(1000, forgotPassword({ email: form[name].email }))
				.then(() => {
					router.go('confirmEmail', { email: form[name].email })
				})
				.catch(error => {
					form._state = {
						errors: error.body.errors,
					}
					domApi.$set({ form })
				})
		})
	},
}
