import { AuthSimpleLogout } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'
import { logout } from '@/service/api/user.js'

export default {
	template: AuthSimpleLogout,
	resolve: () => logout()
		.then(
			() => ({ config }),
			error => ({ config, form: { logout: { errors: [ error ] } } }),
		),
}
