import template from './CcTransactionId.svelte'
import { getCcTransactionById } from '@/lib/controller/cc-transaction.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'CC Transaction',
	},
	template,
	resolve: async (_, { ccTransactionId }) => ({
		form: load(await getCcTransactionById({ get }, ccTransactionId, { include: [ 'files', 'user' ] }), 0),
	}),
}
