import template from './Create.svelte'
import { load } from 'jsonapi-svelte-form/mapper'
import { type } from '@/shared/model/custom-report.model.js'
import { type as opportunityType } from '@/shared/model/opportunity.model.js'
import { opportunityColumnOrderDefault } from '@/shared/value/report-columns.js'

export default {
	data: {
		title: 'Create Custom Report',
	},
	template,
	resolve: async (_, params) => {
		let data
		let cloned
		if (params?.clone) {
			try {
				data = {
					id: 'GID0',
					attributes: JSON.parse(params.clone),
				}
				cloned = true
			} catch (error) {
				console.error('Could not parse clone parameter', params)
			}
		}
		if (!data) data = {
			id: 'GID0',
			type,
			attributes: {
				type: opportunityType,
				orderedColumns: opportunityColumnOrderDefault,
				assignmentStatus: 'any',
				cancellationStatus: 'any',
				carrierStatus: 'any',
				grouping: 'id',
				heldStatus: 'any',
				referrerStatus: 'any',
				maxDateType: 'created',
				minDateType: 'created',
				dateRanges: [ 'custom' ],
			},
		}
		return { form: load({ data }, 0), cloned }
	},
}
