<script>
	import { createEventDispatcher, onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	export let form
	export let api
	export let legId
	export let companyData

	$: companyId = form.data[legId]?.relationships?.shipper?.data?.id
	$: carrierName = companyData?.byId?.[companyId]?.attributes?.name
	$: carrierCdId = companyData?.byId?.[companyId]?.attributes?.centralDispatchId

	let actualPickup = ''
	let onlyOntrackSync
	onMount(() => {
		actualPickup = (form.data[legId]?.attributes?.dates?.receive || new Date().toISOString()).split('T')[0]
	})

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'receive',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
			data: { actualPickup },
		}))
	}
</script>

<div style="max-width: 32em;">
	<div class="input-group input-group-sm mb-2">
		<span class="input-group-text">
			Actual Pickup
		</span>
		<input
			disabled={form.state === 'saving'}
			type="date"
			class="form-control"
			value={actualPickup}
			on:input={event => { actualPickup = event.target.value }}
		>
	</div>

	<p>
		Manually marking as picked up will
		{#if onlyOntrackSync}
			update the OnTrack record only. Central Dispatch will <strong>not</strong> be updated.
		{:else}
			send a notification email to
			<a href={router.makePath('app.companies.edit.companyId.overview', { companyId }, { inherit: false })}>{carrierName}</a>
			and update Central Dispatch.
		{/if}
	</p>

	<p>
		Please do not mark this order as picked up unless you can verify the pick-up date.
	</p>

	<button class="btn btn-sm btn-primary" disabled={!actualPickup || form.state === 'saving'} on:click={postHandler}>
		<NamedIcon name="received" />
		Manually Mark as Picked Up
		{#if onlyOntrackSync}
			<em>in OnTrack Only!</em>
		{/if}
	</button>

	<div class="form-check mt-3 mb-0">
		<input
			disabled={form.state === 'saving'}
			class="form-check-input"
			type="checkbox"
			id="leg-receive-only-ontrack"
			checked={onlyOntrackSync}
			on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
		>
		<label for="leg-receive-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
			Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
			and will <strong>not</strong> send a notification email.
		</label>
	</div>
</div>
