import financialNumber from 'financial-number'

import { keyBy } from './key-by.js'
import { markdownToHtml } from './markdown-to-html.js'

export const formatDate = string => {
	if (!string) return string
	const [ year, month, day ] = string.split('-')
	return new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), 12, 0, 0))
		.toLocaleDateString('default', { year: 'numeric', month: 'long', day: 'numeric' })
}

const generateLegDetails = (which, leg, opportunitiesIncludedById, companiesIncludedById) => {
	const details = {}

	const type = leg?.attributes?.[`${which}Type`]
	let address
	if (type === 'terminal') {
		const terminal = companiesIncludedById[leg?.relationships?.[`${which}Terminal`]?.data?.id]
		details.company = terminal?.attributes?.name
		address = companiesIncludedById[terminal?.relationships?.address?.data?.id]
	} else if (type === 'custom' || type === 'customer') {
		address = opportunitiesIncludedById[leg?.relationships?.[`${which}Address`]?.data?.id]
	}

	if (address) details.address = address?.attributes

	return details
}

// TODO extend to Zapier, write tests!!!
export const generateOpportunityEmailParameters = ({ note, assigned, opportunity: { data, included }, companies }) => {
	if (note) note = markdownToHtml(note)

	const opportunitiesIncludedById = keyBy(included || [], 'id')
	const companiesIncludedById = keyBy(companies?.included || [], 'id')
	for (const company of (companies?.data || [])) companiesIncludedById[company.id] = company

	const customerContact = opportunitiesIncludedById[data.relationships?.customerContact?.data?.id]
	const customer = customerContact && {
		company: customerContact.attributes.company,
		firstName: customerContact.attributes.firstName,
		lastName: customerContact.attributes.lastName,
		fullName: [
			customerContact.attributes.firstName?.trim(),
			customerContact.attributes.lastName?.trim(),
		].filter(Boolean).join(' '),
		email: customerContact.attributes.emailPrimary,
		phone: customerContact.attributes.phonePrimary,
	}

	const vehicles = included?.filter(i => i.type === 'vehicle')
	const totalQuote = parseFloat(vehicles.reduce((sum, v) => {
		return sum
			.plus(String(v.attributes.originalCarrierPay || 0))
			.plus(String(v.attributes.originalBrokerFee || 0))
			.plus(String(v.attributes.tariffAdjustment || 0))
	}, financialNumber('0')))

	const legs = data.relationships?.legs?.data?.map(rel => opportunitiesIncludedById[rel.id]).filter(Boolean) || []

	const [ firstLeg ] = legs
	const pickup = generateLegDetails('pickup', firstLeg, opportunitiesIncludedById, companiesIncludedById)
	pickup.firstAvailablePickup = formatDate(data.attributes?.firstAvailablePickup)
	pickup.actualPickup = formatDate(firstLeg?.attributes?.actualPickup)

	const lastLeg = legs[legs.length - 1]
	const deliver = generateLegDetails('dropoff', lastLeg, opportunitiesIncludedById, companiesIncludedById)
	deliver.actualDeliver = formatDate(lastLeg?.attributes?.actualDeliver)

	return {
		note,
		id: data.id?.replace(/P$/, ''),
		totalQuote,
		couldNotVerifyVehicle: !vehicles.every(v => v.attributes.sizeCorrect),
		isAutoQuoteDuplicate: data.attributes.autoQuoteAlreadySent,
		locationNotSupported: !data.attributes.autoQuoteLocationSupported,
		firstAvailableDropoff: formatDate(data.attributes?.firstAvailableDropoff),
		vehicles: vehicles.map(v => ({
			quote: parseFloat(
				financialNumber('0')
					.plus(String(v.attributes.originalCarrierPay || 0))
					.plus(String(v.attributes.originalBrokerFee || 0))
					.plus(String(v.attributes.tariffAdjustment || 0))
					.toString(),
			),
			year: v.attributes.year,
			make: v.attributes.make,
			model: v.attributes.model,
			shippingMethod: v.attributes.shippingMethod,
		})),
		assigned: assigned && {
			email: assigned.attributes.email,
			name: assigned.attributes.name,
			phone: assigned.attributes.phone,
		},
		customer,
		pickup,
		deliver,
	}
}
