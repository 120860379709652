<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import LogoutContent from '../../../mixins/authentication/LogoutContent.svelte'
	import deepmerge from 'deepmerge'

	export let system
	export let config

	$: opts = deepmerge(
		{
			layout: {
				title: 'falcon',
				icon: 'vendor/falcon/assets/img/illustrations/falcon.png',
			},
		},
		config || {},
	)
</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout}>
		<LogoutContent {system} class="text-center" />
	</LayoutBasic>
</LayoutTheme>
