<script>
	import ChangeRow from './ChangeRow.svelte'
	export let row
	export let column
	export let includedById
</script>

{#if row.attributes?.changes?.length}
	<table class="table table-sm table-hover">
		<tbody>
			{#each row.attributes.changes as change}
				<tr>
					<ChangeRow {includedById} {change} parentType={column.parentType} showClipboardCopy={column.showClipboardCopy} />
				</tr>
			{/each}
		</tbody>
	</table>
{/if}
