<script>
	import InputType from '@/component/atom/InputType.svelte'
	export let form
	export let relId
	export let relName
	export let readonly

	$: id = form.data[relId]?.relationships?.[relName]?.data?.id

	const onCreate = create => create({ relId, relName, isArray: false, type: 'contact' })
</script>

<div class="row mb-2">
	<div class="col">
		<p class="mb-2">Person</p>
		<InputType
			type="text"
			label="Company"
			icon="company"
			labelWidth="120px"
			bind:form
			{readonly}
			{id}
			{onCreate}
			keypath={[ 'attributes', 'companyName' ]}
		/>
		<InputType
			type="text"
			label="First Name"
			labelWidth="120px"
			bind:form
			{readonly}
			{id}
			{onCreate}
			keypath={[ 'attributes', 'firstName' ]}
		/>
		<InputType
			type="text"
			label="Last Name"
			labelWidth="120px"
			bind:form
			{readonly}
			{id}
			{onCreate}
			keypath={[ 'attributes', 'lastName' ]}
		/>
	</div>
</div>
