export const opportunityAlertCodes = {
	INVALID_EMAIL_ADDRESS: email => `Invalid email address "${email}".`,
	INVALID_AUTO_QUOTE_ID: id => `Auto Quote ID not found "${id}".`,

	NO_VEHICLES: () => 'No vehicles found.',
	BAD_VEHICLE_YEAR: year => `Invalid vehicle year "${year}".`,
	BAD_VEHICLE_MAKE: make => `Invalid vehicle make "${make}".`,
	BAD_VEHICLE_MODEL: model => `Invalid vehicle model "${model}".`,
	BAD_VEHICLE_TYPE: type => `Invalid vehicle type "${type}".`,

	ORIGIN_ZIP_MISSING: () => 'Missing origin Zip Code.',
	ORIGIN_ZIP_BAD: zip => `Invalid origin Zip Code "${zip}".`,

	DESTINATION_ZIP_MISSING: () => 'Missing destination Zip Code.',
	DESTINATION_ZIP_BAD: zip => `Invalid destination Zip Code "${zip}".`,

	BAD_SHIP_DATE: date => `Invalid ship date "${date}".`,
}

export const code = {
	INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
	INVALID_AUTO_QUOTE_ID: 'INVALID_AUTO_QUOTE_ID',

	NO_VEHICLES: 'NO_VEHICLES',
	BAD_VEHICLE_YEAR: 'BAD_VEHICLE_YEAR',
	BAD_VEHICLE_MAKE: 'BAD_VEHICLE_MAKE',
	BAD_VEHICLE_MODEL: 'BAD_VEHICLE_MODEL',
	BAD_VEHICLE_TYPE: 'BAD_VEHICLE_TYPE',

	ORIGIN_ZIP_MISSING: 'ORIGIN_ZIP_MISSING',
	ORIGIN_ZIP_BAD: 'ORIGIN_ZIP_BAD',

	DESTINATION_ZIP_MISSING: 'DESTINATION_ZIP_MISSING',
	DESTINATION_ZIP_BAD: 'DESTINATION_ZIP_BAD',

	BAD_SHIP_DATE: 'BAD_SHIP_DATE',

}
