<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import LoginForm from '../../../mixins/authentication/LoginForm.svelte'
	import FormHeader from '../../../mixins/authentication/FormHeader.svelte'
	import deepmerge from 'deepmerge'

	export let system

	export let config
	export let form = {
		login: {},
	}

	$: opts = deepmerge(
		{
			layout: {
				title: 'falcon',
				icon: 'vendor/falcon/assets/img/illustrations/falcon.png',
			},
			header: {
				title: {
					text: 'Log in',
				},
				normalContent: {
					text: 'or',
				},
				linkText: $system.paths['authentication/simple/register'] ? 'Create an account' : undefined,
				link: $system.paths['authentication/simple/register'],
			},
			login: {
				email: { placeholder: 'Email address', label: 'Email address' },
				password: { placeholder: 'Password', label: 'Password' },
				checkbox: { id: 'basic-checkbox', checked: true },
			},
		},
		config || {},
	)
</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout}>
		<FormHeader
			{system}
			config={opts.header}
		/>
		<LoginForm
			name="login"
			on:input
			on:submit
			{system}
			{form}
			config={opts.login}
		/>
	</LayoutBasic>
</LayoutTheme>
