import template from './CentralDispatch.svelte'
import { getCurrentUser } from '@/lib/controller/user.js'
import { getCentralDispatchSettings } from '@/lib/controller/system.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Central Dispatch',
	},
	template,
	resolve: async () => {
		const user = await getCurrentUser({ get })
		let form
		if (user?.attributes?.permissions?.manageApp) form = load(await getCentralDispatchSettings({ get }), 0)
		return { form }
	},
}
