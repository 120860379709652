<script>
	import { onMount } from 'svelte'
	import Table from '@/component/table/Jobs.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { listJobs } from '@/lib/controller/job.js'

	export let asr
	export let api
	export let querystringParams
	export let loading = true
	export let data = []
	export let included = []
	export let links
	export let errors

	const paramToLabel = {
		jobPath: 'Name',
		status: 'Job Status',
		userId: 'User ID',
		opportunityId: 'Opportunity',
		legId: 'Leg ID',
	}

	onMount(() => {
		const query = { include: 'user' }
		const filter = {}
		for (const key in paramToLabel) if (querystringParams[key]) filter[key] = querystringParams[key]
		if (Object.keys(filter).length) query.filter = filter
		listJobs(api, query)
			.then(response => {
				loading = false
				data = [ ...data, ...response.data ]
				included = [ ...included, ...response.included ]
				links = response.links
			})
			.catch(error => {
				errors = Array.isArray(error.errors)
					? error.errors
					: [ error ]
			})
	})
</script>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{api}
	{data}
	{links}
	{errors}
	{included}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>
				<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
				<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
				<span class="me-3">
					Job Inspector
				</span>
				{#each Object.keys(paramToLabel) as param}
					{#if querystringParams[param]}
						<span class="badge bg-light border border-secondary text-dark pe-1 me-3">
							{paramToLabel[param]}: {param === 'opportunityId' ? querystringParams[param].replace(/P$/, '') : querystringParams[param]}
							<a
								class="btn-close ps-4"
								href={asr.makePath(null, { ...querystringParams, [param]: undefined }, { inherit: false })}
							></a>
						</span>
					{/if}
				{/each}
			</h4>
		</div>
	</div>
</TableCardWrapper>
