<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let form
	export let openActionPanel
	export let panel
	export let label
	export let icon
</script>

<li class="nav-item">
	<a class="nav-link" class:disabled={form.state === 'saving'} class:active={openActionPanel === panel} href="#{panel}" on:click|preventDefault={() => { openActionPanel = panel }}>
		{#if icon}
			<NamedIcon name={icon} />
		{/if}
		{label}
	</a>
</li>
