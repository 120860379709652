<script>
	import { carriers, shippers, terminals } from '@/lib/high-level-links.js'
	export let company
	$: att = company?.attributes || {}
	const GRAY = '#b9b9b9'
	const GREEN = '#1ec120'
</script>

<span>
	<span style="color: {att.isCarrier ? GREEN : GRAY};" title="Carrier Services: {att.isCarrier ? 'Yes' : 'No'}">
		<span class="fa fa-{carriers.icon}"></span>
	</span>
	<span style="color: {att.isShipper ? GREEN : GRAY};" title="Shipping Services: {att.isShipper ? 'Yes' : 'No'}">
		<span class="fa fa-{shippers.icon}"></span>
	</span>
	<span style="color: {att.isTerminal ? GREEN : GRAY};" title="Terminal Services: {att.isTerminal ? 'Yes' : 'No'}">
		<span class="fa fa-{terminals.icon}"></span>
	</span>
</span>
