import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'
import { shippingMethod } from '../value/shipping-method.js'

export const $selfFragment = {
	vehicle: true,
}

export const type = 'leg-vehicle'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the vehicle leg details.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			description: 'Per-vehicle details for the leg of the journey.',
			type: 'object',
			additionalProperties: false,
			properties: {
				tariff: {
					description: 'Amount charged to the customer to ship this vehicle for this leg.',
					type: 'number',
					format: 'double',
				},
				// TODO is this actually done in the form? I don't think we're doing this when we generate and post to CD
				shippingMethod: {
					description: 'The method of shipment for the vehicle, if different than the overall order.',
					type: 'string',
					enum: Object.keys(shippingMethod),
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				vehicle: {
					description: 'The vehicle for these details.',
					...jsonApiRelationshipSingular('vehicle'),
				},
			},
		},
	},
}
