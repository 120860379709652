<script>
	import InputTypeBare from '@/component/atom/InputTypeBare.svelte'
	export let form
	export let rowIndex
	export let lastRow
	export let badMileRange
	const onCreate = () => { form.data[form.primaryId].attributes.rateSheetRows = [] }
</script>

<tr class:border-bottom={lastRow}>
	<td class="border-left aqc-form-rate-column">
		<InputTypeBare
			id={form.primaryId}
			bind:form
			type="number"
			keypath={[ 'attributes', 'rateSheetRows', rowIndex, 'milesMin' ]}
			class={badMileRange ? 'is-invalid' : ''}
			{onCreate}
		/>
	</td>
	<td class="aqc-form-rate-column">
		<InputTypeBare
			id={form.primaryId}
			bind:form
			type="number"
			keypath={[ 'attributes', 'rateSheetRows', rowIndex, 'milesMax' ]}
			class={badMileRange ? 'is-invalid' : ''}
			{onCreate}
		/>
	</td>
	<td class="border-left">
		<InputTypeBare
			id={form.primaryId}
			bind:form
			type="number"
			keypath={[ 'attributes', 'rateSheetRows', rowIndex, 'carrierPaySmall' ]}
			class={form.data[form.primaryId].attributes?.rateSheetRows?.[rowIndex]?.carrierPaySmall ? '' : 'is-invalid'}
			{onCreate}
		/>
	</td>
	<td>
		<InputTypeBare
			id={form.primaryId}
			bind:form
			type="number"
			keypath={[ 'attributes', 'rateSheetRows', rowIndex, 'carrierPayMedium' ]}
			class={form.data[form.primaryId].attributes?.rateSheetRows?.[rowIndex]?.carrierPayMedium ? '' : 'is-invalid'}
			{onCreate}
		/>
	</td>
	<td class="border-right">
		<InputTypeBare
			id={form.primaryId}
			bind:form
			type="number"
			keypath={[ 'attributes', 'rateSheetRows', rowIndex, 'carrierPayLarge' ]}
			class={form.data[form.primaryId].attributes?.rateSheetRows?.[rowIndex]?.carrierPayLarge ? '' : 'is-invalid'}
			{onCreate}
		/>
	</td>
</tr>

<style>
	.border-left {
		border-left: 1px solid #cecece;
	}
	.border-right {
		border-right: 1px solid #cecece;
	}
	.border-bottom {
		border-bottom: 1px solid #cecece;
	}
</style>
