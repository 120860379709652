/*

These zoom and lat/long values were determined experimentally to produce some nice
looking Google Maps views:

For any address constrained to the non-Alaskan US states:
https://www.google.com/maps/dir/Omaha,+NE+68122/Maxwell,+NE+69151/@37.65,-104.11,5z/

For any address in the US that has one point in the non-Alaskan
US states, and another point in Alaska:
https://www.google.com/maps/dir/Fairbanks,+Alaska/Green+Bay,+WI+54303/@53.73,-126.80,4z/

For any address that has all points in Alaska:
https://www.google.com/maps/dir/Anchorage,+AK+99502/Trapper+Creek,+AK+99683/@64.54,-163.68,5z/\

*/

const addressString = ({ city, state, zip }) => {
	if (!city) return `${state || ''} ${zip || ''}`.trim()
	else if (state || zip) return `${city}, ${state} ${zip}`.trim()
	else return city
}

export const legPickupDropoffToGoogleMapsLink = ({ pickup: { address: pickup }, dropoff: { address: dropoff } }) => {
	if (!pickup || !dropoff) return ''
	const directions = `${addressString(pickup)}/${addressString(dropoff)}`
		// + encoded urls apparently
		.replaceAll(' ', '+')
	let latLongZoom = '@37.65,-104.11,5z'
	if (pickup.state === 'AK' && dropoff.state === 'AK') latLongZoom = '@64.54,-163.68,5z/'
	else if (pickup.state === 'AK' || dropoff.state === 'AK') latLongZoom = '@53.73,-126.80,4z'
	return `https://www.google.com/maps/dir/${directions}/${latLongZoom}/`
}
