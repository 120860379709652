<script>
	import Table from '@/component/Table.svelte'
	import Contact from './company/Contact.svelte'
	import Services from './company/Services.svelte'
	import Status from './company/Status.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import AddressSimple from '@/component/table-cells/AddressSimple.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'services',
		'name',
		'contact',
		'hours',
		'address',
		'inactive',
		'blocked',
	]

	const nameToColumn = {
		name: {
			title: 'Name',
			component: PlainLink,
			keypath: 'attributes.name',
			link: row => asr.makePath('app.companies.edit.companyId', { companyId: row.id }),
		},
		services: {
			title: 'Services',
			component: Services,
		},
		contact: {
			title: 'Contact',
			component: Contact,
			keypath: 'attributes.name',
		},
		hours: {
			title: 'Hours',
			component: PlainText,
			keypath: 'attributes.hoursOpen',
		},
		address: {
			title: 'Address',
			keypath: 'relationships.address.data.id',
			component: AddressSimple,
		},
		inactive: {
			title: 'Inactive',
			component: Status,
			which: 'inactive',
		},
		blocked: {
			title: 'Blocked',
			component: Status,
			which: 'blocked',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
