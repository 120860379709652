<script>
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'
	import { everyOpportunityColumn } from '@/component/report/opportunity/every-opportunity-column.js'

	import DateRangeNavbar from './_DateRangeNavbar.svelte'
	import { fetchReport } from './_fetch-report.js'
	import { dateRangeFromQuery } from './_date-range-from-query.js'

	export let api
	export let asr
	export let querystringParams
	export let legEventName

	const legEventNameToFilter = {
		dispatched: {
			minDateType: 'anyDispatched',
			maxDateType: 'anyDispatched',
		},
		received: {
			minDateType: 'anyReceivedActual',
			maxDateType: 'anyReceivedActual',
		},
		delivered: {
			minDateType: 'anyDeliveredActual',
			maxDateType: 'anyDeliveredActual',
		},
	}
	const legEventNameToLabel = {
		dispatched: 'Recently Dispatched',
		received: 'Pickups (Actual)',
		delivered: 'Deliveries (Actual)',
	}
	const legEventNameToFilenamePrefix = {
		dispatched: 'recently-dispatched',
		received: 'recently-picked-up',
		delivered: 'recently-delivered',
	}
	const legEventNameToKeyColumn = {
		dispatched: 'dispatched',
		received: 'pickupActual',
		delivered: 'dropoffActual',
	}

	$: orderedColumns = [
		'id',
		'customerName',
		'customerPhones',
		'customerEmails',
		'notes',
		'firstAvailable',
		'vehiclesStacked',
		'legStatusStacked',
		'carrierDetailsStackedSimple',
		...(legEventName === 'dispatched' ? [ 'dispatchedStacked' ] : []),
		legEventName === 'dispatched' ? 'pickupContractStacked' : 'pickupActualStacked',
		legEventName === 'dispatched' ? 'dropoffContractStacked' : 'dropoffActualStacked',
		'assigned',
	]
	$: selectableDateRanges = [
		{
			label: 'Today',
			params: { dateRange: 'today' },
		},
		{
			label: 'Yesterday',
			params: { dateRange: 'yesterday' },
		},
		{
			label: 'Last Business Day',
			params: { dateRange: 'back1biz' },
		},
		{
			label: 'Last 5 Business Days',
			params: { dateRange: 'back5biz' },
		},
		{
			label: 'Custom Date Range',
			params: { dateRange: 'custom' },
		},
	]
	$: columns = everyOpportunityColumn({ asr })

	let currentSort
	let data
	let errors
	let loading = true
	$: dateRange = dateRangeFromQuery({ querystringParams, backward: true, now: new Date() })
	$: params = dateRange?.apiDates?.min && dateRange?.apiDates?.max && {
		filter: {
			minDate: dateRange.apiDates.min && new Date(dateRange.apiDates.min).toISOString(),
			maxDate: dateRange.apiDates.max && new Date(dateRange.apiDates.max).toISOString(),
			...legEventNameToFilter[legEventName],
		},
		include: [ 'legs' ],
	}
	$: {
		if (params) {
			fetchReport({ api, url: '/api/v1/reports/groupedOpportunities/id', params })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					else if (response?.data) data = response.data
					currentSort = querystringParams.sort || `-${legEventNameToKeyColumn[legEventName]}`
					loading = false
				})
		} else {
			loading = false
		}
	}

	$: rows = !loading && data && tableizeReportData({
		body: { data },
		sort: currentSort,
		params,
		columns,
		orderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns,
		columns,
		label: legEventNameToLabel[legEventName],
		filename: `${legEventNameToFilenamePrefix[legEventName]}-${new Date().toISOString().replaceAll(':', '')}.xlsx`,
		rows,
		params: params.filter,
	})
</script>

<DateRangeNavbar
	{asr}
	{columns}
	{dateRange}
	{generateSpreadsheetFile}
	label={legEventNameToLabel[legEventName]}
	{orderedColumns}
	{querystringParams}
	rowCount={rows?.length}
	{selectableDateRanges}
	tableId={legEventNameToFilenamePrefix[legEventName]}
>
	<div slot="description" style="max-width: 55ch;">
		{#if legEventName === 'dispatched'}
			<p class="mt-1 mb-2">
				Opportunities where any leg has been assigned OR dispatched within the date
				range specified.
			</p>
		{:else if legEventName === 'received' || legEventName === 'delivered'}
			<p class="mt-1 mb-2">
				Opportunities where any legs have an actual
				{#if legEventName === 'received'}
					pickup
				{:else}
					delivery
				{/if}
				date set within the range specified.
			</p>
		{/if}
	</div>
</DateRangeNavbar>

<ReportTable
	id={legEventNameToFilenamePrefix[legEventName]}
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
