import template from './ReceivedEmail.svelte'
import { getReceivedEmailById } from '@/lib/controller/received-email.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Inspect Job',
	},
	template,
	resolve: async (_, { receivedEmailId }) => ({
		email: await getReceivedEmailById({ get }, receivedEmailId),
	}),
}
