import template from './List.svelte'
import { listReferrers } from '@/service/api/referrer.js'
import { activate } from '@/lib/list-view-load-next.js'

export default {
	data: {
		title: 'Referrers',
	},
	template,
	querystringParameters: [
		'sort',
	],
	resolve: async (data, { start, end, sort }) => listReferrers({
		filter: {
			start,
			end,
		},
		sort: sort || 'attributes.name',
		page: {
			limit: 75,
		},
	}),
	activate,
}
