<script>
	export let label
	export let usersById
	export let emails
</script>

{#if emails?.length}
	<p class="mb-0 mt-3">📝 <strong>{label}</strong></p>
	{#each emails as email}
	{@const sendingUserId = email.relationships?.sendingUser?.data?.id}
		<div class="ms-3">
			{#if sendingUserId}
				({usersById[sendingUserId]?.attributes?.name || 'N/A'})
			{/if}
			{@html email.attributes.parameters.note}
		</div>
	{/each}
{/if}
