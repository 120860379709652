<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { dateType as allowedDateTypes } from '@/shared/value/date-type.js'
	export let dateType
	export let date
	export let readonly
	export let label
	const dispatch = createEventDispatcher()
</script>

<div class="input-group input-group-sm mt-2 mb-1">
	<span class="input-group-text justify-content-center">
		<label for="dispatch-date" class="form-label-sm mb-0" style="font-weight: normal !important;">
			<NamedIcon name="date" />
			{label}
		</label>
	</span>
	<input
		id="dispatch-date"
		type="date"
		value={date}
		on:input={event => dispatch('date', event.target.value)}
		disabled={readonly}
		class="form-control form-control-sm"
	>
</div>

{#each Object.keys(allowedDateTypes) as d}
	<div class="form-check mb-0 ms-5">
		<input on:click={() => dispatch('dateType', d)} checked={dateType === d} disabled={readonly} class="form-check-input" type="radio" name={label} id="{label}-{d}">
		<label class="form-check-label mb-0" for="{label}-{d}">
			{allowedDateTypes[d]}
		</label>
	</div>
{/each}
