<script>
	import { createEventDispatcher } from 'svelte'
	import FormCheckbox from '../common/FormCheckbox.svelte'
	import FormInput from '../common/FormInput.svelte'
	import ErrorAlert from '../common/ErrorAlert.svelte'

	export let system
	export let config = {}
	export let form = {}
	export let name

	const dispatch = createEventDispatcher()
	const onsubmit = () => { dispatch('submit', { name, form }) }

	const requiredProps = [
		'name',
		'email',
		'password',
		'confirmPassword',
		'checkbox',
	]

	$: valid = requiredProps.every(prop => form[name][prop])
		&& form[name].password === form[name].confirmPassword
	$: state = form[name]._state || form._state || {}
	$: emailQuery = form[name].email
		? `?email=${form[name].email}`
		: ''
</script>

{#each (state.errors || []) as error}
	{#if error.code === 'ItemAlreadyExists'}
		<ErrorAlert level="info" title="User Already Exists" icon="exclamation-triangle">
			A user already exists for that email. You could try
			<a href={$system.paths['authentication/simple/forgot-password'] + emailQuery}>resetting your password?</a>
		</ErrorAlert>
	{:else}
		<ErrorAlert title={error.code} icon="exclamation-triangle">
			{error.detail}
		</ErrorAlert>
	{/if}
{/each}

<form on:submit|preventDefault={onsubmit}>
	<fieldset disabled={state.submitting}>
		<div class="mb-3">
			<FormInput type="text" on:input config={config.name} bind:value={form[name].name} form={name} keypath="name" />
		</div>
		<div class="mb-3">
			<FormInput type="email" on:input config={config.email} bind:value={form[name].email} form={name} keypath="email" />
		</div>
		<div class="row gx-2">
			<div class="col-sm-6 mb-3">
				<FormInput type="password" on:input config={config.password} bind:value={form[name].password} form={name} keypath="password" />
			</div>
			<div class="col-sm-6 mb-3">
				<FormInput type="password" on:input config={config.confirmPassword} bind:value={form[name].confirmPassword} form={name} keypath="confirmPassword" />
			</div>
		</div>
		<FormCheckbox on:input config={config.checkbox} bind:value={form[name].checkbox} form={name} keypath="checkbox">
			I accept the
			<a href={$system.paths['misc/terms']}>terms</a>
			and
			<a href={$system.paths['misc/privacy-policy']}>privacy policy</a>
		</FormCheckbox>
		<div class="mb-3">
			<button disabled={!valid} type="submit" name="submit" class="btn btn-primary d-block w-100 mt-3">
				Register
			</button>
		</div>
	</fieldset>
</form>
