<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	export let icon
	export let label
	export let value
	export let clipboard
	export let labelWidth
	export let id
	$: _id = id || Date.now() + label

	let clazz = 'mb-2'
	export { clazz as class }
</script>

<div class="input-group input-group-sm {clazz}">
	<span class="input-group-text" id={_id} style:width={labelWidth}>
		{#if icon}
			<span class="pe-1">
				<NamedIcon name={icon} />
			</span>
		{/if}
		{label}
	</span>
	<input {value} readonly type="text" class="form-control" aria-label={label} aria-describedby={_id}>
	<CopyToClipboard hide={!clipboard} string={value} />
</div>
