import { commitForm } from '@/lib/form-committer.js'
import { minimumDelay } from '@/lib/delay.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

// export const createJob = async ({ post }, form, callback) => minimumDelay(900, commitForm({ form, request: post, url: '/api/v1/jobs', callback }))
export const updateJob = async ({ put }, form) => minimumDelay(900, commitForm({ form, request: put, url: `/api/v1/jobs/${form.primaryId}` }))

export const listJobs = async ({ get }, params) => get('/api/v1/jobs' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
export const getJobById = async ({ get }, id) => get(`/api/v1/jobs/${id}`).then(response => response.body)

export const cancelJob = async ({ patch }, { jobId, updated }) => minimumDelay(600, patch(`/api/v1/jobs/${jobId}`, {
	body: {
		data: {
			id: jobId,
			type: 'job',
			attributes: { status: 'CANCELLED' },
			meta: { updated },
		},
	},
}))
