<script>
	import DateString from '@/component/atom/DateString.svelte'
	import dlv from 'dlv'
	export let row
	export let column
	$: value = dlv(row, column.keypath)
</script>

<DateString
	date={value}
	format={column.format}
	time={column.time}
	multiline={column.multiline}
	dateFormat={column.dateFormat}
	timeFormat={column.timeFormat}
/>
