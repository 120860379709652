<script>
	import { tick } from 'svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import { openForm, closeAll, openFormById, savingFormById } from './open-form-store.js'
	import { highlightedWord } from '@/service/store/highlighter.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { makeHighlightedText } from '@/lib/make-highlighted-text.js'

	export let row
	export let column

	const openFormAndFocus = () => {
		openForm(row)
		tick().then(() => {
			document.getElementById(`${row.id}.attributes.note`)?.focus()
		})
	}

	const saveChanges = () => column.save($openFormById[row.id])

	$: highlight = makeHighlightedText($highlightedWord, row.attributes.note || '')
	$: noteIsSelfAssigned = row.relationships?.assigned?.data?.id === $currentUser.id
	$: noteHasResource = !!row.relationships?.resource?.data?.id
	$: assignableUsers = column.assignableUsersById || (column.assignableUsers || [])
		.map(user => ({ value: user.id, title: user.attributes.name }))
		.filter(item => item?.title)
		.reduce((map, { value, title }) => {
			map[value] = title
			return map
		}, {})
</script>

{#if !$openFormById[row.id]}
	<div on:click={openFormAndFocus}>
		{#if highlight?.html}
			{@html highlight.html}
		{:else}
			{highlight.text}
		{/if}
	</div>
{:else}
	<fieldset disabled={$savingFormById[row.id]}>
		<div class="mt-2">
			<InputTextArea
				bind:form={$openFormById[row.id]}
				id={row.id}
				keypath={[ 'attributes', 'note' ]}
			/>
		</div>
		{#if noteIsSelfAssigned && column.assignableUsers?.length}
			<div class="mt-2">
				<InputSelectRel
					label="Assign Note"
					labelWidth="12em"
					emptySelectLabel="(Assign to Nobody)"
					bind:form={$openFormById[row.id]}
					options={assignableUsers}
					id={row.id}
					name="assigned"
					type="user"
				/>
			</div>
		{/if}
		<div class="mt-2">
			<button class="btn btn-secondary btn-sm" on:click={closeAll}>
				Cancel
			</button>
			<button
				class="btn btn-primary btn-sm"
				disabled={![ 'changed', 'unsaved' ].includes($openFormById[row.id].state)}
				on:click={saveChanges}
			>
				Save Changes
			</button>
		</div>
	</fieldset>
{/if}
