<script>
	export let columns
	export let columnOverrides
	export let orderedColumns
	$: keys = (orderedColumns || Object.keys(columns || {}))
		.sort()
		.filter(key => columns?.[key]?.description)
</script>

{#if keys.length}
	<p class="mb-2">
		Here's what the columns mean:
	</p>
	<ul class="mb-3">
		{#each keys as key}
			<li>
				<strong>{columnOverrides?.[key]?.label || columns[key].label}</strong> {columnOverrides?.[key]?.description || columns[key].description}
			</li>
		{/each}
	</ul>
{/if}
