<script>
	import CustomReportForm from '@/component/form/CustomReportForm.svelte'
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import { createCustomReport } from '@/lib/controller/custom-report.js'
	import { router } from '@/service/router.js'

	export let api
	export let form
	export let cloned

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createCustomReport(api, form, response => router.go('app.customReports.view.customReportId', { customReportId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<h2 class="mb-0">
		{#if cloned}
			Copy
		{:else}
			Create
		{/if}
		a Custom Report
	</h2>
</FormHeaderMain>

<CustomReportForm bind:form />

<FormErrorsOther {form} />

<FormCommitButton label="Create Custom Report" {form} on:click={create} />
