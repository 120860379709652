<script>
	import { terminals } from '@/lib/high-level-links.js'
	export let location
</script>

{#if location.isTerminal}
	<span style="color: #bbb;" title="Terminal"><span class="fa fa-{terminals.icon}"></span></span>
{/if}
{#if location.shortAddress}
	{@html location.shortAddress}
{:else}
	<span class="text-danger">N/A</span>
{/if}
