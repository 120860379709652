import template from './Create.svelte'
import { type } from '@/shared/model/referrer.model.js'
import { listReferrers } from '@/lib/controller/referrer.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Create Referrer',
	},
	template,
	resolve: async () => ({
		form: load({ data: { id: 'GID0', type } }, 0),
		referrers: await listReferrers({ get }).then(response => response.data),
	}),
}
