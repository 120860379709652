export const type = 'central-dispatch'

export default {
	description: `
		The login credentials and other settings for connecting to Central Dispatch. This resource
		should only be available to read/write by internal systems and users with the "manageApp" permission.
	`,
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Manually managed id for this singleton.',
			type: 'string',
			enum: [ type ],
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				username: {
					description: 'The username to log in to Central Dispatch.',
					type: 'string',
				},
				password: {
					description: 'The password to log in to Central Dispatch.',
					type: 'string',
				},
				session: {
					description: 'The current session used to make requests.',
					type: 'string',
				},
			},
		},
	},
}
