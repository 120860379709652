<script>
	import EditOrderShippingInformationSingle from './EditOrderShippingInformationSingle.svelte'

	export let api
	export let create
	export let remove
	export let form
	export let anyAre
	export let companyData

	$: status = form.data[form.primaryId]?.attributes?.status
	$: leadOrQuote = status === 'lead' || status === 'quote'
	$: legRels = form.data[form.primaryId]?.relationships?.legs?.data || []
	$: lastLeg = form.data[legRels[legRels.length - 1]?.id]
	let creatableLegResource
	$: {
		if (lastLeg) {
			creatableLegResource = {
				attributes: {
					pickupContact: 'previousLeg',
					pickupLocation: 'previousLeg',
					dropoffContact: 'customer',
					dropoffLocation: 'custom',
				},
			}
		}
	}

	$: overallMiles = form.data[form.primaryId]?.attributes?.miles
	$: vehicleRels = form.data[form.primaryId]?.relationships?.vehicles?.data || []
	$: legRels = form.data[form.primaryId]?.relationships?.legs?.data || []

	const sum = (total, num) => total + num
	$: totalAdjustedCarrierPay = legRels.map(l=> form.data[l.id]?.attributes?.carrierPay || 0).reduce(sum, 0)
	$: totalOriginalCarrierPay = vehicleRels.map(v => form.data[v.id]?.attributes?.originalCarrierPay || 0).reduce(sum, 0)
</script>

{#each legRels as { id: legId }, legIndex}
	<EditOrderShippingInformationSingle
		{companyData}
		{anyAre}
		{overallMiles}
		{vehicleRels}
		{legRels}
		{totalAdjustedCarrierPay}
		{totalOriginalCarrierPay}
		{legIndex}
		{legId}
		{api}
		{create}
		{remove}
		bind:form
	/>
{/each}
