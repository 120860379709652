import template from './List.svelte'
import { listCompanies } from '@/service/api/company.js'
import { activate } from '@/lib/list-view-load-next.js'

const castToBoolean = string => string === 'true' ? true : (string === 'false' ? false : undefined)

export default {
	data: {
		title: 'Companies',
	},
	template,
	querystringParameters: [ 'service', 'searchType', 'search', 'inactive', 'blocked' ],
	resolve: async (data, { start, end, sort, service, searchType, search, inactive, blocked }) => {
		inactive = castToBoolean(inactive)
		blocked = castToBoolean(blocked)
		const filter = {
			start,
			end,
			service,
			inactive: inactive ? [ 'true', 'false' ] : [ 'false' ],
			blocked: blocked ? [ 'true', 'false' ] : [ 'false' ],
		}
		if (searchType && search) filter[searchType] = search
		const params = {
			filter,
			sort,
			page: {
				limit: 50,
			},
		}
		const { body } = await listCompanies(params)
		params.searchType = searchType
		params.search = search
		params.inactive = inactive
		params.blocked = blocked
		return {
			...body,
			params,
			clearable: !!search,
		}
	},
	activate,
}
