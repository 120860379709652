<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import Card from '@/component/atom/Card.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { showSignableAdmin } from '@/service/store/current-user.js'

	export let asr
	export let email

	$: atts = email.data?.attributes || {}
	$: opportunityId = email.data?.relationships?.opportunity?.data?.id
	$: emailTemplateId = email.data?.relationships?.template?.data?.id
	$: signableDocumentId = email.data?.relationships?.signableDocument?.data?.id
	$: userId = email.data?.relationships?.sendingUser?.data?.id
	$: user = email.included?.find(i => i.id === userId)
	$: signableDocument = email.included?.find(i => i.id === signableDocumentId)
	$: signedDate = signableDocument?.attributes?.signed && new Date(signableDocument.attributes.signed)
	$: signableTemplateId = signableDocument?.relationships?.signableTemplate?.data?.id
</script>

<style>
	th {
		width: 12em;
	}
</style>

<FormHeaderMain>
	Email Sent:
	<DateString time date={email.data.meta.created} />
</FormHeaderMain>

<Card>
	<div class="row">
		{#if opportunityId}
			<div class="col-auto">
				<a href={asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId }, { inherit: false })}>
					<NamedIcon name="opportunity" />
					Opportunity:
					{opportunityId.replace(/P$/, '')}
				</a>
			</div>
		{/if}
		{#if emailTemplateId}
			<div class="col-auto">
				<a href={asr.makePath('app.systemSettings.emailTemplates.edit.emailTemplateId', { emailTemplateId }, { inherit: false })}>
					<NamedIcon name="email" />
					Email Template
				</a>
			</div>
		{/if}
		{#if signableTemplateId && $showSignableAdmin}
			<div class="col-auto">
				<a href={asr.makePath('app.systemSettings.signableTemplates.edit.signableTemplateId', { signableTemplateId }, { inherit: null })}>
					<NamedIcon name="signableDocument" />
					Signable Document Template
				</a>
			</div>
		{/if}
		{#if userId}
			<div class="col-auto">
				<a href={asr.makePath('app.users.edit.userId', { userId }, { inherit: false })}>
					<NamedIcon name="user" />
					{#if user}
						{user.attributes.name}
					{:else}
						Sender
					{/if}
				</a>
			</div>
		{/if}
	</div>
	<hr>
	<table class="table table-sm">
		<tbody>
		<tr>
			<th>From Address</th>
			<td>{atts.fromAddress}</td>
		</tr>
		<tr>
			<th>To Address</th>
			<td>{atts.toAddress}</td>
		</tr>
		<tr>
			<th>Subject</th>
			<td>{atts.subject}</td>
		</tr>
		{#if signableDocumentId}
			<tr>
				<th>Signable Document</th>
				<td>
					<a href={asr.makePath('app.signableDocuments.signableDocumentId', { signableDocumentId }, { inherit: null })}>
						{signableDocument?.attributes?.name}
					</a>
					{#if signedDate}
						(Signed on <strong>{signedDate.toLocaleDateString('default')}</strong>
						at
						<strong>{signedDate.toLocaleTimeString('default')}</strong>)
					{/if}
				</td>
			</tr>
		{/if}
		<tr>
			<th>Custom Note</th>
			<td>{atts.note}</td>
		</tr>
		<tr>
			<th>Email HTML</th>
			<td>
				<div class="border rounded px-3 py-1 mt-2 bg-white">
					{@html atts.html}
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</Card>
