import template from './EditSignableTemplate.svelte'
import { getSignableTemplateById } from '@/lib/controller/signable-template.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { signableTemplateId }) => ({ form: load(await getSignableTemplateById({ get }, signableTemplateId), 0) }),
}
