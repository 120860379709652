<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'

	export let form
	export let api
	export let legId
	export let legState

	const dispatch = createEventDispatcher()
	let cancelling
	let onlyOntrackSync
	const cancelOrder = () => {
		cancelling = true
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'unpost',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
		}))
	}
</script>

<p>
	{#if legState === 'post'}
		This will remove the posting from Central Dispatch for this leg.
	{:else if legState === 'assign'}
		You have chosen to cancel your request for this carrier for this leg.
		If you are cancelling your request because the carrier has
		not responded, try sending them a reminder before you cancel. Once
		you have cancelled your request, a notification email will be sent
		to the carrier and a copy will be retained for their records.
	{:else if legState === 'dispatch'}
		You have chosen to cancel the dispatch and contract between your company and
		the carrier for this leg. PLEASE NOTE: Because a contract has already been signed by the carrier,
		you may be obligated to provide a valid reason for terminating the contract. Upon cancellation,
		a notification email will be sent to the carrier and a copy of the dispatch sheet and
		contract will be retained for their records.
	{:else if legState === 'receive'}
		You have chosen to cancel the dispatch and contract between your company and the
		carrier for this leg. PLEASE NOTE: Because a contract has already been signed by the carrier,
		you may be obligated to provide a valid reason for terminating the contract. Upon cancellation,
		a notification email will be sent to the carrier and a copy of the dispatch sheet and
		contract will be retained for their records.
	{:else if legState === 'deliver'}
		By taking this action, this leg will be marked as "Dispatched" and the picked-up and
		delivered dates will be removed. A notification email will be sent to the carrier. Please
		do not take this action unless you or the carrier accidentally marked the leg as delivered.
	{/if}
</p>

<button class="btn btn-sm btn-danger" disabled={cancelling || form.state === 'saving'} on:click={cancelOrder}>
	Yes, Cancel this Leg Order
	{#if onlyOntrackSync}
		<em>in OnTrack Only!</em>
	{/if}
</button>
{#if cancelling}
	<NamedIcon name="loading" />
{/if}

<div class="form-check mt-3 mb-0">
	<input
		disabled={form.state === 'saving'}
		class="form-check-input"
		type="checkbox"
		id="leg-cancel-only-ontrack"
		checked={onlyOntrackSync}
		on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
	>
	<label for="leg-cancel-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
		Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
		and will <strong>not</strong> send a notification email.
	</label>
</div>
