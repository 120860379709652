<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { legPickupDropoffToGoogleMapsLink } from '@/lib/leg-pickup-dropoff-to-google-maps-link.js'
	import { legPickupDropoffToCentralDispatchSearch } from '@/lib/leg-pickup-dropoff-to-central-dispatch-search.js'
	import SingleLegCarrier from './SingleLegCarrier.svelte'
	import SingleLegContact from './SingleLegContact.svelte'
	import SingleLegEvent from './SingleLegEvent.svelte'
	import { dateType } from '@/shared/value/date-type.js'

	export let asr
	export let form
	export let legId
	export let legIndex
	export let legCount

	const getLegContactDetails = ({ contact, address, addressRel }) => {
		return {
			companyName: contact?.companyName,
			name: contact?.name || `${contact?.firstName || ''} ${contact?.lastName || ''}`.trim(),
			address: `${address?.city || 'N/A'}, ${address?.state || 'N/A'} ${address?.zip || ''}`,
			terminalUrl: addressRel?.id && addressRel?.type === 'company'
				? asr.makePath('app.companies.edit.companyId.overview', { companyId: addressRel.id }, { inherit: false })
				: false,
		}
	}

	$: status = form.data[form.primaryId]?.attributes?.status
	$: leg = form.data[legId]
	$: dates = leg?.attributes?.dates || {}
	$: pickupDropoff = getPickupDropoffForLeg({ opportunityId: form.primaryId, legId, resourcesById: form.data })
	$: legContact = {
		pickup: getLegContactDetails(pickupDropoff.pickup),
		dropoff: getLegContactDetails(pickupDropoff.dropoff),
	}
	$: mapsLink = legPickupDropoffToGoogleMapsLink(pickupDropoff)
	$: centralDispatchSearchLink = legPickupDropoffToCentralDispatchSearch(pickupDropoff)

	let showAllEvents
	const hiddenEventStatuses = [ 'CANCELLED', 'FAILED' ]
	$: legEvents = (leg?.attributes?.events || [])
	$: anyHidden = legEvents.find(event => hiddenEventStatuses.includes(event.completionStatus))
</script>

<style>
	tr.single-leg > td {
		padding-top: 1em;
		padding-bottom: 1em;
		border-bottom-width: 6px;
	}
	tr.single-leg.bottom-padding > td {
		border-bottom: none;
	}
	.date-label {
		display: inline-block;
		width: 4ch;
		color: var(--falcon-muted);
	}
</style>

<tr class="single-leg" class:bottom-padding={legIndex === (legCount - 1)}>
	<td class="ps-0">
		<table class="table table-sm mb-0">
			{#each legEvents as event, eventIndex}
				{#if showAllEvents || !hiddenEventStatuses.includes(event.completionStatus) || eventIndex === (legEvents.length - 1)}
					<SingleLegEvent {...event} {leg} isLastEvent={eventIndex === (legEvents.length - 1)} />
				{/if}
			{/each}
			{#if anyHidden}
				<tr>
					<td colspan="3">
						<button class="btn btn-sm btn-link" on:click={() => { showAllEvents = !showAllEvents }}>
							{#if showAllEvents}
								Hide
							{:else}
								Show
							{/if}
							Additional Events
						</button>
					</td>
				</tr>
			{/if}
		</table>
	</td>
	<td>
		<SingleLegContact
			{asr}
			{form}
			{legContact}
			which="pickup"
		/>
	</td>
	<td>
		&rarr;
		{#if mapsLink}
			<br>
			<ExternalLink url={mapsLink}>
				Map
			</ExternalLink>
		{/if}
		{#if centralDispatchSearchLink}
			<br>
			<ExternalLink url={centralDispatchSearchLink}>
				CD
			</ExternalLink>
		{/if}
	</td>
	<td>
		<SingleLegContact
			{asr}
			{form}
			{legContact}
			which="dropoff"
		/>
	</td>
	<td>
		{leg?.attributes?.carrierPay || ''}
	</td>
	<td>
		<SingleLegCarrier {asr} {form} {legId} />
	</td>
	<td class="pe-0">
		{#if leg?.attributes?.pickupDate}
			<span class="date-label">P/U</span>
			<DateString date={leg.attributes.pickupDate} />
			<small>{dateType[leg.attributes.pickupDateType]}</small>
			<br>
		{/if}
		{#if leg?.attributes?.dropoffDate}
			<span class="date-label">Del.</span>
			<DateString date={leg.attributes.dropoffDate} />
			<small>{dateType[leg.attributes.dropoffDateType]}</small>
			<br>
		{/if}
		{#if leg?.attributes?.businessDays}
			<span class:text-warning={leg.attributes.businessDays !== 10}>
				<strong>Terms</strong>
				{leg.attributes.businessDays} business days
				{#if leg.attributes.businessDays !== 10}
					<NamedIcon name="warning" />
				{/if}
			</span>
		{/if}
	</td>
</tr>
