export const type = 'history'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the history record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-immutable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'resourceId',
				'resourceType',
			],
			properties: {
				needsQbSync: {
					description: 'Must be set to true to show up in qb-changes reports or marked as synced. Property removed once synced.',
					type: 'boolean',
				},
				qbSyncId: {
					description: 'The identifier of the qb-sync resource, once a sync is done.',
					type: 'string',
				},
				systemId: {
					type: 'string',
					description: `
						If the change was made by a backend system process, this property
						must be set to the system identifier, which is a curated set.
					`,
				},
				jobId: {
					type: 'string',
					description: `
						If the change was made by a backend system process that was kicked
						off by a job, this property must be set to that job's identifier.
					`,
				},
				userId: {
					type: 'string',
					description: `
						If the change was made by a user, this property must be set
						to the users identifier.
					`,
				},
				ipAddress: {
					type: 'string',
					description: `
						If the change was made by a user, every effort should be taken
						to include the request's IP address for record keeping.
					`,
				},
				userAgent: {
					type: 'string',
					description: 'If available, the full User-Agent header of the request creating this history record.',
				},
				cleanUserAgent: {
					$ref: '#/components/schemas/user-agent',
				},
				cfCountry: {
					type: 'string',
					description: 'The country code of the originating request, provided by Cloudflare.',
				},
				cfRay: {
					type: 'string',
					description: 'The trace identifier, provided by Cloudflare for debugging purposes.',
				},
				sessionId: {
					type: 'string',
					description: `
						If the change was made by a user, if they are logged in with a
						token, the session identifier should also be stored.
					`,
				},
				resourceType: {
					type: 'string',
					description: `
						The type of the resource being changed, e.g. \`opportunity\`.
					`,
				},
				resourceId: {
					type: 'string',
					description: `
						The identifier of the resource being changed.
					`,
				},
				note: {
					type: 'string',
					description: 'An optional short, explanatory note for why the change happened. E.g. "client phone call".',
				},
				changes: {
					type: 'array',
					minItems: 1,
					items: {
						type: 'object',
						additionalProperties: false,
						properties: {
							op: {
								type: 'string',
								description: 'The JSON:Patch operation.',
								enum: [ 'add', 'replace', 'remove' ],
							},
							keypath: {
								type: 'array',
								description: `
									The keypath of the property that was changed, e.g. \`[ 'data', 'attributes', 'name' ]\` or
									for changes to included data \`[ 'included', '0001', 'attributes', 'name' ]\`.
								`,
								items: {
									oneOf: [
										{ type: 'number' },
										{ type: 'string' },
									],
								},
							},
							relationship: {
								type: 'array',
								description: `
									If the change was to included data, this will be the relationship path from the root
									resource out to the changed resource, e.g. \`[ 'legs', 1, 'dropoffAddress' ]\`.
								`,
								items: {
									oneOf: [
										{ type: 'number' },
										{ type: 'string' },
									],
								},
							},
							original: {
								description: 'The value prior to the change.',
							},
							updated: {
								description: 'The value after the change.',
							},
						},
					},
				},
			},
		},
	},
}
