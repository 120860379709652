<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import RegisterForm from '../../../mixins/authentication/RegisterForm.svelte'
	import FormHeader from '../../../mixins/authentication/FormHeader.svelte'
	import deepmerge from 'deepmerge'

	export let system

	export let config
	export let form = {
		register: {},
	}

	$: opts = deepmerge(
		{
			layout: {
				title: 'falcon',
				icon: 'vendor/falcon/assets/img/illustrations/falcon.png',
			},
			header: {
				title: {
					text: 'Register',
				},
				normalContent: {
					text: 'Have an account?',
				},
				linkText: 'Login',
				link: $system.paths['authentication/simple/login'],
			},
			register: {
				name: { label: 'Name' },
				email: { label: 'Email address' },
				password: { label: 'Password' },
				confirmPassword: { placeholder: 'Repeat', label: 'Confirm Password' },
				checkbox: { id: 'basic-register-checkbox' },
			},
		},
		config || {},
	)
</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout}>
		<FormHeader
			class="mb-2"
			config={opts.header}
		/>
		<RegisterForm
			name="register"
			on:input
			on:submit
			{system}
			{form}
			config={opts.register}
		/>
	</LayoutBasic>
</LayoutTheme>
