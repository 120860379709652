import { getOpportunityById } from '@/lib/controller/opportunity.js'
import { listUsers } from '@/lib/controller/user.js'
import template from './LogCreditCard.svelte'
import { load } from 'jsonapi-svelte-form/mapper'
import { keyBy } from '@/shared/util/key-by.js'
import { notFoundRedirect } from '@/lib/not-found-redirect.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Log CC',
	},
	template,
	resolve: async (_, { opportunityId }) => {
		const [ opportunityBody, { data: users } ] = await Promise.all([
			getOpportunityById({ get }, opportunityId, { include: [ 'assigned', 'companies' ] }).catch(error => ({ error })),
			listUsers({ get }),
		])
		notFoundRedirect(opportunityBody, 'opportunity', opportunityId)
		const now = new Date()
		return {
			users,
			usersById: keyBy(users, 'id'),
			opportunityId,
			opportunityFormForNavHeader: load(opportunityBody, 0),
			form: load({
				data: {
					id: 'GID0',
					type: 'cc-transaction',
					attributes: {
						status: 'completed',
						ran: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${(now.getDate()).toString().padStart(2, '0')}`,
					},
					relationships: {
						opportunity: {
							data: {
								type: 'opportunity',
								id: opportunityId,
							},
						},
					},
				},
			}, 1),
		}
	},
}
