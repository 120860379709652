export const opportunityLegStatusToIcon = {
	unpost: 'order',
	post: 'cd',
	assign: 'unsigned',
	remind: 'email',
	dispatch: 'dispatched',
	receive: 'received',
	deliver: 'delivered',
	undeliver: 'undeliver',
}
