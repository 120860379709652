export const getLegPostingId = ({ opportunity: { data: { id, relationships } }, legId }) => {
	const legRelIndex = relationships?.legs?.data?.findIndex(rel => rel.id === legId)
	if (legRelIndex >= 0) return opportunityLegCentralDispatchPostId({
		opportunityId: id,
		legCount: relationships.legs.data.length,
		legRelIndex,
	})
}

export const opportunityLegCentralDispatchPostId = ({ opportunityId, legCount, legRelIndex }) => {
	// if there is only one leg, it's just `123P` => `123`
	if (legCount <= 1) return opportunityId.replace(/P$/, '')
	// but for multiple legs we append e.g. for leg index 1 it's `123P` => `123-B` (65 is the ascii offset to get to A)
	else return `${opportunityId.replace(/P$/, '')}-${String.fromCharCode(65 + legRelIndex)}`
}

export const legIndexFromChar = char => (char?.charCodeAt(0) || 65) - 65
