<script>
	import { onMount } from 'svelte'
	import { load } from 'jsonapi-svelte-form/mapper'
	import { updateCcTransaction, getCcTransactionHistories } from '@/lib/controller/cc-transaction.js'
	import History from '@/component/table/History.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import CcTransactionForm from '@/component/form/CcTransactionForm.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import { router } from '@/service/router.js'

	export let asr
	export let api
	export let form

	let loading = true
	let response = {}
	const loadHistory = () => {
		loading = true
		getCcTransactionHistories(api, form.primaryId)
			.then(r => {
				response = r
				loading = false
			})
	}
	onMount(() => { loadHistory() })

	let clearUploadingFiles
	let uploadingFiles

	$: stillUploadingFiles = Object.keys(uploadingFiles || {}).find(key => !uploadingFiles[key].file)
	$: previewFiles = form.data[form.primaryId]?.relationships?.files?.data?.map(rel => form.data[rel.id]).filter(Boolean) || []
	$: opportunityId = form.data[form.primaryId]?.relationships?.opportunity?.data?.id

	const save = () => {
		form.state = 'saving'
		const waitForFilesToUpload = new Promise(resolve => {
			const waitLonger = () => {
				if (stillUploadingFiles) setTimeout(waitLonger, 50)
				else resolve(
					Object
						.keys(uploadingFiles || {})
						.map(key => {
							const { id, type } = uploadingFiles[key].file.data
							return { id, type }
						}),
				)
			}
			waitLonger()
		})
		waitForFilesToUpload
			.then(() => updateCcTransaction(api, form.data[form.primaryId]))
			.then(response => {
				form = load(response, 0)
				form.state = 'saved'
				clearUploadingFiles()
				loadHistory()
			})
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<style>
	img {
		width: 100%;
	}
	.card-header {
		word-break: break-all;
	}
</style>

<FormHeaderMain>
	<div class="row">
		<div class="col-auto">
			<h4 class="mb-0">
				Edit CC Transaction
			</h4>
		</div>
		{#if opportunityId}
			<div class="col">
				<span class="badge bg-light text-dark" style="background-color: #e4e6ee !important;">
					<a href={router.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId }, { inherit: false })}>
						<NamedIcon name="opportunity" />
						Opportunity
						{opportunityId.replace(/P$/, '')}
					</a>
				</span>
			</div>
		{/if}
	</div>
</FormHeaderMain>

<div class="row mb-3">
	<div class="col-xs-12 col-md-8 col-xl-6">
		<CcTransactionForm bind:form bind:uploadingFiles bind:clearUploadingFiles {opportunityId} />
	</div>
	<div class="col-xs-12 col-md-4 col-lg-4">
		{#each previewFiles as file}
			<div class="card mb-3">
				<div class="card-header pb-0">
					<small>
						<ExternalLink url="/api/v1/files/{file.id}/download">
							{file.attributes?.originalFilename}
						</ExternalLink>
					</small>
				</div>
				<div class="card-body">
					{#if file.attributes.mimeType?.startsWith('image/')}
						<img src="/api/v1/files/{file.id}/download" alt="File: {file.attributes?.filename}">
					{:else}
						<small class="text-muted">No Preview Available</small>
					{/if}
				</div>
			</div>
		{/each}
	</div>
</div>

<FormErrorsOther {form} />

<FormCommitButton {form} on:click={save} />

<div class="mt-3">
	<div class="card mb-3">
		<div class="card-header pb-0">
			History
		</div>
		<div class="card-body">
			<History
				{loading}
				{asr}
				{...response}
				parentType="cc-transaction"
				sort="-meta.created"
			/>
		</div>
	</div>
</div>
