<script>
	import { onMount } from 'svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import { get } from '@/lib/json-fetch.js'

	const METERS_PER_MILE = 1609.344
	const PUBLIC_API_TOKEN = 'pk.eyJ1IjoidG9iaWFzMTgxOTM0ODU0NyIsImEiOiJjbGJiOWR3ZnMxYXM2M29wa3N3MXQ2bGM4In0.AiHiwtVHzf3VEoIbt1gMtQ'

	export let height = '400px'

	let clazz = ''
	let originZip
	let destinationZip
	export { clazz as class, originZip as origin, destinationZip as destination }

	let mapboxStarted
	let LoadingIndicatorControl
	let MapLibreGlDirections
	let mapboxgl
	let containerId
	let previousOriginZip
	let previousDestinationZip

	onMount(() => {
		containerId = `${Date.now()}-${Math.random()}`
		import('mapbox-gl/dist/mapbox-gl.js').then(mb => {
			mapboxgl = mb
			mapboxgl.accessToken = PUBLIC_API_TOKEN
		})
		import('@maplibre/maplibre-gl-directions').then(gl => {
			MapLibreGlDirections = gl.default
			LoadingIndicatorControl = gl.LoadingIndicatorControl
		})

		let map
		let directions
		const interval = setInterval(() => {
			if (!mapboxStarted && !directions && mapboxgl && LoadingIndicatorControl && MapLibreGlDirections && center && zoom) {
				console.log('Starting mapbox...')
				mapboxStarted = true
				previousOriginZip = originZip
				previousDestinationZip = destinationZip
				setTimeout(() => {
					try {
						map = new mapboxgl.Map({
							container: containerId,
							style: 'mapbox://styles/mapbox/navigation-day-v1',
							center: [ center.longitude, center.latitude ],
							zoom,
						})
					} catch (error) {
						console.error('Error starting mapbox', error)
					}
					if (map) map.on('load', () => {
						directions = new MapLibreGlDirections(map)
						map.addControl(new LoadingIndicatorControl(directions))
						directions.setWaypoints([
							[ origin.longitude, origin.latitude ],
							[ destination.longitude, destination.latitude ],
						])
					})
				}, 1000)
			}
			if (directions && origin?.found && destination?.found && (originZip !== previousOriginZip || destinationZip !== previousDestinationZip)) {
				previousOriginZip = originZip
				previousDestinationZip = destinationZip
				setTimeout(() => {
					console.log('Updating waypoints...')
					directions.setWaypoints([
						[ origin.longitude, origin.latitude ],
						[ destination.longitude, destination.latitude ],
					])
					map.setCenter([ center.longitude, center.latitude ])
					map.setZoom(zoom)
				}, 50)
			}
		}, 50)
		return () => clearInterval(interval)
	})

	let zipToGeolocation
	$: {
		originZip && destinationZip && get(`/api/v1/geolocation?zip=${[ originZip, destinationZip ].filter(Boolean).join(',')}`)
			.then(res => {
				if (res?.body?.data?.length) {
					const map = {}
					for (const geo of res.body.data) map[geo.zip] = geo
					zipToGeolocation = map
				}
			})
	}

	$: origin = zipToGeolocation?.[originZip]
	$: destination = zipToGeolocation?.[destinationZip]

	let center
	let zoom
	$: {
		if (origin?.found && destination?.found) {
			const longDiff = Math.abs((origin.longitude - destination.longitude) / 2)
			const latDiff = Math.abs((origin.latitude - destination.latitude) / 2)
			center = {
				longitude: Math.max(origin.longitude, destination.longitude) - longDiff,
				latitude: Math.max(origin.latitude, destination.latitude) - latDiff,
			}
			zoom = 5
			if (longDiff > 10 || latDiff > 3) zoom = 4
			else if (longDiff > 20 || latDiff > 2) zoom = 3
		}
	}

	let distanceInMiles
	$: {
		if (origin?.found && destination?.found) {
			fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${origin.longitude}%2C${origin.latitude}%3B${destination.longitude}%2C${destination.latitude}?alternatives=false&geometries=geojson&language=en&overview=simplified&steps=false&access_token=${PUBLIC_API_TOKEN}`)
				.then(response => response.json())
				.then(body => {
					distanceInMiles = Math.round(body?.routes?.[0]?.distance / METERS_PER_MILE)
				})
		}
	}
</script>

<style>
	.map-origin-destination {
		position: relative;
	}
	.waiting-for-coordinates, .miles-distance {
		position: relative;
		left: 5px;
		max-width: 20em;
		background-color: #fff;
		border-radius: 4px;
	}
</style>

<svelte:head>
	<link href="https://api.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.css" rel="stylesheet" />
</svelte:head>

{#if mapboxStarted || (origin?.found && destination?.found)}
	<div class="map-origin-destination {clazz || ''}" style:height={height}>
		<div class="mapbox-container">
			<div style:height={height} id={containerId}></div>
		</div>
		{#if distanceInMiles}
			<div class="miles-distance" style:top={`calc(5px - ${height})`}>
				<div class="input-group mb-0">
					<span class="input-group-text" id="distance-in-miles">
						Miles
					</span>
					<input type="text" class="form-control" aria-describedby="distance-in-miles" value={distanceInMiles}>
					<CopyToClipboard string={distanceInMiles} />
				</div>
			</div>
		{/if}
		{#if !origin?.found || !destination?.found}
			<div class="waiting-for-coordinates p-3" style:top={`calc(5px - ${height})`}>
				Waiting for valid coordinates...
			</div>
		{/if}
	</div>
{/if}
