<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import AddNewNote from './AddNewNote.svelte'
	import TimelineItem from './TimelineItem.svelte'
	import {
		listOpportunityHistories,
		listOpportunityEmails,
		listOpportunityNotes,
		listOpportunitySignableDocuments,
	} from '@/lib/controller/opportunity.js'
	import { listEmailTemplates } from '@/lib/controller/email-template.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { keyBy } from '@/shared/util/key-by.js'

	export let asr
	export let api
	export let usersById
	export let opportunityId

	const sortByCreated = list => list.sort((a, b) => {
		return new Date(b.meta.created).getTime() - new Date(a.meta.created).getTime()
	})

	let showAddNote
	let resourceHighlightId

	let createdNotes = []
	let timeout
	const handleNewNote = ({ detail: { data, included } }) => {
		showAddNote = false
		resourceHighlightId = data.id
		createdNotes.push({ data, included })
		if (timeout) clearTimeout(timeout)
		timeout = setTimeout(() => { resourceHighlightId = undefined }, 2500)
	}

	const timelinePromise = Promise
		.all([
			// NOTE: list order matters, last one is email templates
			listOpportunityEmails(api, opportunityId, { include: [ 'signableDocuments' ] }),
			listOpportunityHistories(api, opportunityId, { 'filter[hasNote]': true }),
			listOpportunityNotes(api, opportunityId, {
				'filter[hasArchived]': false,
				include: [ 'files' ],
			}),
			listOpportunitySignableDocuments(api, opportunityId, { 'filter[signed]': true })
				.then(response => {
					// doing a little cheating, to set the create to the signed date
					response.data = response.data.map(d => {
						d.meta.created = d.attributes.signed
						return d
					})
					return response
				}),
			listEmailTemplates(api),
		])
		.then(responses => {
			const data = []
			const included = []
			const emailTemplates = responses.pop()
			for (const template of emailTemplates.data) included.push(template)
			for (const response of responses) {
				data.push(...response.data)
				included.push(...(response.included || []))
			}
			return { data: sortByCreated(data), includedById: keyBy(included, 'id') }
		})
		.catch(error => {
			console.error('Error while loading!', error)
			throw error
		})
</script>

<style>
	:global(table.timeline-table .col-date) {
		white-space: nowrap;
	}
	:global(table.timeline-table .col-user) {
		white-space: nowrap;
	}
	:global(table.timeline-table .col-action) {
		white-space: nowrap;
	}
	:global(.table.timeline-table > tbody > tr:hover) {
		background-color: #e9eef5;
	}
</style>

{#if showAddNote}
	<div class="card mb-3">
		<div class="card-body pt-0">
			<AddNewNote
				{api}
				{opportunityId}
				{usersById}
				currentUserId={$currentUser.id}
				on:cancel={() => { showAddNote = false }}
				on:created={handleNewNote}
			/>
		</div>
	</div>
{/if}

<div class="card">
	<div class="card-header pt-2 pb-2">
		<div class="row">
			<div class="col-auto">
				<h5 class="mb-0 mt-1">Timeline</h5>
			</div>
			<div class="col">
				<button class="btn btn-sm btn-secondary" on:click={() => showAddNote = !showAddNote}>
					<NamedIcon name="add" />
					Add Note
				</button>
			</div>
		</div>
	</div>
	<div class="card-body bg-light p-0">
		{#await timelinePromise}
			<p class="text-center">Loading...</p>
		{:then { data, includedById }}
			<table class="table table-sm timeline-table">
				<thead>
					<tr>
						<th class="col-date" scope="col">Date</th>
						<th class="col-user" scope="col">User</th>
						<th class="col-action" scope="col">Action</th>
						<th scope="col">Notes</th>
					</tr>
				</thead>
				<tbody>
					{#each createdNotes as item (item.data.id)}
						<TimelineItem includedById={keyBy(item.included, 'id')} item={item.data} {opportunityId} {asr} {usersById} {resourceHighlightId} {api} />
					{/each}
					{#each data as item}
						<TimelineItem {includedById} {item} {opportunityId} {asr} {usersById} {resourceHighlightId} {api} />
					{/each}
				</tbody>
			</table>
		{/await}
	</div>
</div>
