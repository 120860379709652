<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	export let form
	export let api
	export let legId
	export let companyData

	$: companyId = form.data[legId]?.relationships?.shipper?.data?.id
	$: carrierName = companyData?.byId?.[companyId]?.attributes?.name
	$: carrierCdId = companyData?.byId?.[companyId]?.attributes?.centralDispatchId

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'remind',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
		}))
	}
</script>

<p>
	This will send a reminder email through Central Dispatch to
	<a href={router.makePath('app.companies.edit.companyId.overview', { companyId }, { inherit: false })}>{carrierName}</a>
</p>

<button class="btn btn-sm btn-primary" disabled={form.state === 'saving'} on:click={postHandler}>
	<NamedIcon name="email" />
	Send Reminder Email
</button>
