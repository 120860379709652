<script>
	export let row
	export let includedById

	const sum = (total, value) => total + value
	$: brokerFee = row.relationships?.vehicles?.data?.map(v => includedById[v.id]).map(v => v?.attributes?.originalBrokerFee || 0).reduce(sum, 0)
	$: carrierPay = row.relationships?.vehicles?.data?.map(v => includedById[v.id]).map(v => v?.attributes?.originalCarrierPay || 0).reduce(sum, 0)
	$: tariffAdjustment = row.relationships?.vehicles?.data?.map(v => includedById[v.id]).map(v => v?.attributes?.tariffAdjustment || 0).reduce(sum, 0)
	$: tariff = brokerFee + carrierPay + tariffAdjustment
</script>

<style>
	.table {
		margin: 0;
		width: 6em;
	}
	.table tr td:first-child {
		text-align: right;
		padding: 0;
		width: 3em;
	}
	.table tr td:last-child {
		padding: 0;
		width: 3em;
	}
</style>

<table class="table table-sm">
	<tr>
		<td>{brokerFee}</td>
		<td><small>BF</small></td>
	</tr>
	<tr>
		<td>{carrierPay}</td>
		<td><small>CP</small></td>
	</tr>
	<tr>
		<td>{tariff}</td>
		<td><small>AT</small></td>
	</tr>
</table>
