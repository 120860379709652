import template from './Create.svelte'
import { listReferrers } from '@/lib/controller/referrer.js'
import { listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

const makeBlankForm = () => {
	let GID = 0
	const makeGid = () => `GID${GID++}`
	const rel = ({ id, type }) => ({ id, type })
	const vehicle = { id: makeGid(), type: 'vehicle', attributes: { shippingMethod: 'open' } }
	const customerContact = { id: makeGid(), type: 'contact' }
	const pickupContact = { id: makeGid(), type: 'contact' }
	const pickupAddress = { id: makeGid(), type: 'address' }
	const dropoffContact = { id: makeGid(), type: 'contact' }
	const dropoffAddress = { id: makeGid(), type: 'address' }
	const leg = {
		id: makeGid(),
		type: 'leg',
		attributes: {
			pickupType: 'custom',
			dropoffType: 'custom',
		},
		relationships: {
			pickupContact: { data: rel(pickupContact) },
			pickupAddress: { data: rel(pickupAddress) },
			dropoffContact: { data: rel(dropoffContact) },
			dropoffAddress: { data: rel(dropoffAddress) },
		},
	}
	const opportunity = {
		id: makeGid(),
		type: 'opportunity',
		attributes: { status: 'lead' },
		relationships: {
			vehicles: { data: [ rel(vehicle) ] },
			legs: { data: [ rel(leg) ] },
			customerContact: { data: rel(customerContact) },
		},
	}
	return load({
		data: opportunity,
		included: [
			vehicle,
			customerContact,
			pickupContact,
			pickupAddress,
			dropoffContact,
			dropoffAddress,
			leg,
		],
	}, GID + 1)
}

const makeForm = params => {
	let form
	if (params?.clone) {
		try {
			const { data, included, gid } = JSON.parse(params.clone)
			form = load({ data, included }, gid)
		} catch (error) {
			console.error('Bad data provided to clone:', params.clone)
		}
	}
	if (!form) form = makeBlankForm()
	return form
}

export default {
	data: {
		title: 'Create Opportunity',
	},
	template,
	resolve: async (_, params) => {
		const [ { data: referrers }, { data: users } ] = await Promise.all([
			listReferrers({ get }),
			listUsers({ get }),
		])
		return {
			cloneOpportunityId: params?.cloneOpportunityId,
			users,
			referrers,
			form: makeForm(params),
		}
	},
}
