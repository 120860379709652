<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { opportunityStatusToLink } from '@/lib/high-level-links.js'

	export let asr
	export let opportunityStatus
	export let linkedTab
	export let currentTab
	export let title
	export let count
	export let iconName
	export let muted

	$: asrLink = opportunityStatusToLink[opportunityStatus]
	$: active = currentTab && currentTab === linkedTab
	$: inverseSort = linkedTab === 'cancelled'
		? { sort: '-meta.created' }
		: {}
</script>

<style>
	span {
		padding: .2em .5em;
		font-weight: lighter;
		border: 1px solid var(--falcon-muted);
	}
	span.budge-right {
		margin-left: 0.4em;
	}
</style>

<li class="nav-item">
	<a
		class="nav-link position-relative"
		style="{(!active && muted) ? 'color: var(--falcon-400);' : ''}{active ? '' : 'border-bottom: 2px solid transparent;'}"
		class:active
		href={asr.makePath('app.opportunities.list.opportunityStatus', { ...inverseSort, ...asrLink.params, tab: linkedTab }, { inherit: false })}
		role="tab"
	>
		{title}
		{#if iconName}<NamedIcon name={iconName} />{/if}{#if count}<span class:budge-right={linkedTab === 'received'} class="badge rounded-pill bg-light text-dark">{count}</span>{/if}
	</a>
</li>
