<script>
	import Card from '@/component/atom/Card.svelte'
	import SingleLeg from './SingleLeg.svelte'
	export let asr
	export let form
	$: legRels = form.data[form.primaryId]?.relationships?.legs?.data || []
</script>

<Card bodyClass="p-2">
	{#if !legRels.length}
		<span class="text-warning p-5">No legs.</span>
	{/if}
	<table class="table table-sm mb-0">
		<thead style="background-color: #fff;">
			<tr>
				<td class="leg-status">Leg Status</td>
				<td>Origin</td>
				<td>&rarr;</td>
				<td>Destination</td>
				<td>CP</td>
				<td>Carrier</td>
				<td>Dates</td>
			</tr>
		</thead>
		<tbody>
			{#each legRels as { id }, legIndex}
				<SingleLeg legId={id} {asr} {form} {legIndex} legCount={legRels.length} />
			{/each}
		</tbody>
	</table>
</Card>
