<script>
	import CentralDispatchIcon from './CentralDispatchIcon.svelte'
	import { nameToFontAwesomeIcon } from '@/lib/name-to-font-awesome-icon.js'

	let cssClass
	export { cssClass as class }
	export let name

	$: title = nameToFontAwesomeIcon[name]
		? name
		: `Misnamed icon '${name}', please tell a developer where you saw this.`
</script>

<style>
	:global(.fa.fa-box-open::before) {
		left: -0.13em;
		top: 0.05em;
		position: relative;
	}
</style>

<span class={cssClass || ''}>
	{#if name === 'cd'}
		<CentralDispatchIcon />
	{:else if name === 'signableDocument'}
		<span style="font-size: 0.5em; position: relative; top: -4px; left: -2px;">
			<span class="fa-stack fa-1x">
				<span class="fas fa-stack-2x fa-file"></span>
				<span class="fas fa-stack-1x fa-signature" style="color: #fff;"></span>
			</span>
		</span>
	{:else if Array.isArray(nameToFontAwesomeIcon[name])}
		<!--
		Because the fa-stack doubles icon sizes, we need to halve them to get them
		back to the correct size.
		-->
		<span style="font-size: 0.5em; position: relative; top: -2px;">
			<span class="fa-stack fa-1x">
				{#each nameToFontAwesomeIcon[name] as faName, index}
					<span class="fas fa-stack-{index + 1}x {faName}"></span>
				{/each}
			</span>
		</span>
	{:else if name === 'descending'}
		<svg class="svg-inline--fa fa-caret-down fa-w-10" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
	{:else if name === 'ascending'}
		<svg class="svg-inline--fa fa-caret-up fa-w-10" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path></svg>
	{:else}
		<span class="fa {nameToFontAwesomeIcon[name] || 'fa-ghost'}" {title} class:text-warning={!nameToFontAwesomeIcon[name]}></span>
	{/if}
</span>
