<script>
	import LayoutTheme from '../../../layouts/LayoutTheme.svelte'
	import LayoutBasic from '../../../layouts/LayoutBasic.svelte'
	import ForgotPasswordForm from '../../../mixins/authentication/ForgotPasswordForm.svelte'
	import deepmerge from 'deepmerge'

	export let system

	export let config
	export let form = {
		forgot: {},
	}

	$: opts = deepmerge(
		{
			layout: {
				title: 'falcon',
				icon: 'vendor/falcon/assets/img/illustrations/falcon.png',
			},
			forgot: {
				email: { label: 'Email address' },
			},
		},
		config || {},
	)
</script>

<LayoutTheme {system}>
	<LayoutBasic {system} config={opts.layout} class="text-center">
		<h5 class="mb-0">
			Forgot your password?
		</h5>
		<small>
			Enter your email and we'll send you a reset link.
		</small>
		<ForgotPasswordForm
			class="mt-4"
			name="forgot"
			{system}
			{form}
			config={opts.forgot}
			on:input
			on:submit
		/>
	</LayoutBasic>
</LayoutTheme>
