<script>
	export let label
	export let items
</script>

<div class="row gx-3">
	<div class="col-auto">
		<strong>{label}</strong>
	</div>
	<div class="col">
		{#each items as item}
			<p class="mb-0">{item}</p>
		{/each}
	</div>
</div>
