<script>
	import { showAutoQuote, showSignableAdmin } from '@/service/store/current-user.js'
	export let asr
	export let user

	$: links = [
		{
			title: 'Auto-Quote Campaigns',
			description: 'Manage rates and configurations for automated quotes.',
			route: 'app.systemSettings.autoQuoteCampaigns',
			visible: $showAutoQuote,
		},
		{
			title: 'Signable Templates',
			description: 'Manage templates for signable documents, sent to customers and carriers.',
			route: 'app.systemSettings.signableTemplates',
			visible: $showSignableAdmin,
		},
		{
			title: 'Auto-Quote API Documentation',
			description: 'Information about integrating with the OnTrack Auto-Quote API.',
			route: 'app.systemSettings.autoQuoteDocumentation',
			visible: true,
		},
		{
			title: 'Central Dispatch',
			description: 'Integration and connection settings.',
			route: 'app.systemSettings.centralDispatch',
			visible: true,
		},
		{
			title: 'Database Editor',
			description: 'Edit the raw data of any resource. <em>Very dangerous.</em>',
			route: 'app.systemSettings.databaseEditor',
			visible: user?.attributes?.permissions?.manageApp,
		},
		{
			title: 'Email Templates',
			description: 'Templates for emails sent to customers.',
			route: 'app.systemSettings.emailTemplates',
			visible: true,
		},
		{
			title: 'Job Inspector',
			description: 'Check on long running jobs like report generation and CD updates.',
			route: 'app.systemSettings.jobs',
			visible: true,
		},
		{
			title: 'Lead Assignment',
			description: 'Configure ratio of leads assigned to sales users.',
			route: 'app.systemSettings.leadAssignment',
			visible: true,
		},
		{
			title: 'Incoming Leads',
			description: 'Logic and definitions about leads and duplicates.',
			route: 'app.systemSettings.incomingLeads',
			visible: true,
		},
	].filter(link => link.visible)
</script>

<h1>System</h1>

<div class="row">
	<div class="col-xs-12 col-md-9 col-xl-6">
		<ul class="list-group">
			{#each links as { title, description, route }}
				<li class="list-group-item d-flex justify-content-between align-items-start">
					<div class="ms-2 me-auto">
						<div class="fw-bold">
							<a href={asr.makePath(route)}>{title}</a>
						</div>
						{@html description}
					</div>
				</li>
			{/each}
		</ul>
	</div>
</div>
