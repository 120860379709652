<script>
	import InputCheckboxBare from './InputCheckboxBare.svelte'
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate
</script>

<Field bind:form {id} {keypath} on:change let:errors>
	<div class="input-group {errors.length ? 'has-validation' : ''}">
		<InputCheckboxBare {id} bind:form {keypath} {readonly} {onCreate} on:*>
			<slot />
		</InputCheckboxBare>
	</div>
</Field>
