import { jsonApiRelationshipSingular, jsonApiRelationshipList } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	creator: false,
	assigned: false,
	completer: false,
	archiver: false,
	updater: false,
	resource: false,
	files: false,
}

export const type = 'note'

export default {
	description: 'A simple markdown-allowed note to leave on a resource.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the note record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'note',
			],
			properties: {
				note: {
					type: 'string',
					description: 'Markdown capable, human readable text.',
				},
				reminder: {
					description: 'Date at which this note should be re-examined.',
					type: 'string',
					format: 'date', // day-level detail
				},
				completed: {
					description: 'Most recent date the note is marked as "completed", in whatever sense that has meaning.',
					type: 'string',
					format: 'date-time',
				},
				archived: {
					description: 'Most recent date the note is archived. Archived notes do not show up in primary lists, but are still accessible.',
					type: 'string',
					format: 'date-time',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				creator: {
					description: 'OnTrack user who originally created this note.',
					...jsonApiRelationshipSingular('user'),
				},
				assigned: {
					description: 'OnTrack user who is assigned this note. Once assigned, only the assigned user can re-assign.',
					...jsonApiRelationshipSingular('user'),
				},
				completer: {
					description: 'OnTrack user who marked this note as complete.',
					...jsonApiRelationshipSingular('user'),
				},
				archiver: {
					description: 'OnTrack user who marked this note as archived.',
					...jsonApiRelationshipSingular('user'),
				},
				updater: {
					description: 'OnTrack user who most recently changed this note.',
					...jsonApiRelationshipSingular('user'),
				},
				resource: {
					description: 'The resource that this note is tied to, if any.',
					...jsonApiRelationshipSingular('opportunity', 'referrer', 'company'),
				},
				files: {
					description: 'Files associated with this note.',
					...jsonApiRelationshipList('file'),
				},
			},
		},
	},
}
