<script>
	import { createEventDispatcher } from 'svelte'
	import { load } from 'jsonapi-svelte-form/mapper'
	import MimeTypeIcon from '@/component/atom/MimeTypeIcon.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import InputFilesRel from '@/component/atom/InputFilesRel.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import { firstDateIsEarlier } from '@/shared/util/date-comparator.js'
	import { patchNote } from '@/lib/controller/note.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { clone } from '@/shared/util/clone.js'

	export let asr
	export let api
	export let form // type = "note"
	export let opportunityId
	export let usersById
	export let includedById

	$: atts = clone(form.data[form.primaryId].attributes || {})
	$: rels = clone(form.data[form.primaryId].relationships || {})

	let showRaw
	let uploadingFiles
	let savingForm
	let noteEditorIsOpen
	let confirmArchive
	let confirmComplete
	let confirmReopen
	let clearUploadingFiles
	let mostRecentMetaUpdated

	const dispatch = createEventDispatcher()

	const patchNoteAndUpdateForm = updatedNoteProperties => {
		savingForm = true
		patchNote(api, {
			id: form.primaryId,
			type: form.data[form.primaryId].type,
			meta: mostRecentMetaUpdated
				? { ...form.data[form.primaryId].meta, updated: mostRecentMetaUpdated }
				: form.data[form.primaryId].meta,
			...updatedNoteProperties,
		}).then(body => {
			form = load(clone(body), 0)
			// Note: I'm not sure why this was necessary, but after saving a change, before you
			// navigate away, try to make a second change and without this hack it would still
			// have the old meta.updated value, I'm not sure why...
			mostRecentMetaUpdated = form.data[form.primaryId].meta.updated
			const now = form.data[form.primaryId].attributes
			if (!!atts.completed !== !!now.completed || !!atts.archived !== !!now.archived) dispatch('visibilityChanged')
			// atts = clone(form.data[form.primaryId].attributes || {})
			// rels = clone(form.data[form.primaryId].relationships || {})
			savingForm = false
			noteEditorIsOpen = false
			confirmArchive = false
			confirmComplete = false
			confirmReopen = false
		})
	}
	const saveChanges = () => {
		const props = {}
		const set = key => {
			const value = form.data[form.primaryId].attributes?.[key]
			if (form.original[form.primaryId].attributes?.[key] !== value) {
				props.attributes = props.attributes || {}
				props.attributes[key] = value ? value : null
			}
		}
		set('note')
		set('reminder')
		const assignedId = form.data[form.primaryId].relationships?.assigned?.data?.id
		if (assignedId !== form.original[form.primaryId].relationships?.assigned?.data?.id) {
			props.relationships = {
				assigned: assignedId
					? { data: { id: assignedId, type: 'user' } }
					: null,
			}
		}
		if (form.changes[form.primaryId]?.find(change => change.path[0] === 'relationships' && change.path[1] === 'files')) {
			const files = form.data[form.primaryId].relationships?.files?.data
			props.relationships = props.relationships || {}
			props.relationships.files = { data: files }
		}
		patchNoteAndUpdateForm(props)
	}
	const confirmAction = () => {
		if (confirmArchive) patchNoteAndUpdateForm({ attributes: { archived: true } })
		else if (confirmComplete) patchNoteAndUpdateForm({ attributes: { completed: true } })
		else if (confirmReopen) patchNoteAndUpdateForm({ attributes: { completed: null, archived: null } })
	}

	$: assignedUserId = rels.assigned?.data?.id
	$: completerUserId = rels.completer?.data?.id
	$: updaterUserId = rels.updater?.data?.id
	$: completedDate = atts.completed
	$: canEditNote = !assignedUserId || assignedUserId === $currentUser.id
	$: canBeArchived = !atts.archived
	$: canBeCompleted = !canBeArchived && !atts.completed
	$: canBeReopened = !!atts.completed || !!atts.archived
	$: disabledAction = savingForm || confirmReopen || confirmArchive || confirmComplete
	let assignableUsers
	$: {
		assignableUsers = {}
		for (const id in usersById) {
			assignableUsers[id] = usersById[id].attributes.name || 'N/A'
			if (!usersById[id].attributes.active) assignableUsers[id] += ' (Inactive)'
		}
	}
</script>

<style>
	.not-set {
		color: #b7bed2;
	}
	p {
		line-height: 0.9;
	}
</style>

{#if noteEditorIsOpen}
	<div class="row justify-content-end mt-1 mb-2">
		<div class="col-auto">
			{#if canBeArchived}
				<button class="btn btn-secondary btn-sm" disabled={disabledAction} on:click={() => { confirmArchive = true }}>
					<NamedIcon name="archive" />
					Archive
				</button>
			{/if}
			{#if canBeCompleted}
				<button class="btn btn-secondary btn-sm" disabled={disabledAction} on:click={() => { confirmComplete = true }}>
					<NamedIcon name="complete" />
					Complete
				</button>
			{/if}
			{#if canBeReopened}
				<button class="btn btn-secondary btn-sm" disabled={disabledAction} on:click={() => { confirmReopen = true }}>
					<NamedIcon name="open" />
					Re-Open
				</button>
			{/if}
			<button class="btn btn-secondary btn-sm" disabled={savingForm} on:click={() => { confirmArchive = false; confirmReopen = false; confirmComplete = false; noteEditorIsOpen = false } }>
				Cancel
			</button>
		</div>
	</div>
	{#if confirmArchive || confirmComplete || confirmReopen}
		<div class="row justify-content-end mt-1 mb-2">
			<div class="col-auto pe-0" style="margin-top: 0.2em;">
				{#if savingForm}
					<NamedIcon name="loading" />
				{:else if confirmArchive}
					Do you really want to archive this note?
				{:else if confirmReopen}
					Do you really want to re-open this note?
				{:else}
					Do you really want to mark this note as complete?
				{/if}
			</div>
			<div class="col-auto">
				<button class="btn-primary btn-sm" disabled={savingForm} on:click={confirmAction}>
					{#if confirmArchive}
						<NamedIcon name="archive" />
						Yes, Archive
					{:else if confirmReopen}
						<NamedIcon name="open" />
						Yes, Re-Open
					{:else}
						<NamedIcon name="complete" />
						Yes, Complete
					{/if}
				</button>
			</div>
		</div>
	{/if}
	<fieldset disabled={disabledAction}>
		<div class="row">
			<div class="col-xs-12 col-lg-6">
				<InputTextArea
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'note' ]}
				/>
				<div class="mt-2">
					<InputType
						label="Reminder"
						labelWidth="7em"
						type="date"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'reminder' ]}
					/>
				</div>
				{#if Object.keys(assignableUsers).length}
					<div class="mt-2">
						<InputSelectRel
							label="Assign"
							labelWidth="7em"
							emptySelectLabel="(Not Assigned)"
							bind:form
							options={assignableUsers}
							id={form.primaryId}
							name="assigned"
							type="user"
						/>
					</div>
				{/if}
			</div>
			<div class="col-xs-12 col-lg-6 mb-2">
				<label for="note-attachment" class="form-label">
					<NamedIcon name="upload" />
					Add Attachments
				</label>
				<InputFilesRel
					inputId="note-attachment"
					formId={form.primaryId}
					fileParentType="opportunity"
					fileParentId={opportunityId}
					fileChildType="note"
					fileChildId={form.primaryId}
					relName="files"
					bind:form
					bind:uploading={uploadingFiles}
					bind:clearUploadingFiles
				/>
			</div>
		</div>
		<div class="mb-1">
			<button
				class="btn btn-primary btn-sm"
				disabled={![ 'changed', 'unsaved' ].includes(form?.state)}
				on:click={saveChanges}
			>
				Save Changes
			</button>
		</div>
	</fieldset>
	{#if $currentUser?.attributes?.permissions?.manageApp}
		<div class="col-auto">
			<button class="btn btn-secondary btn-sm" on:click={() => { showRaw = !showRaw }}>
				{showRaw ? 'Hide' : 'Show'}
				Raw Data
			</button>
		</div>
	{/if}
{:else}
	<div class="row pe-3">
		<div class="col-xs-12 {rels.files?.data ? 'col-lg-4' : 'col-lg-8'}">
			<div>
				{#if atts?.note}
					{atts?.note}
				{/if}
			</div>
			{#if atts.reminder}
				<p class="mt-0 mb-1">
					<small>Reminder: {atts.reminder}</small>
				</p>
			{/if}
			{#if completedDate || assignedUserId}
				<p class="mt-0 mb-0">
					<small>
						{#if completedDate}
							Completed
							by
							<MaybeLink href={completerUserId && asr.makePath('app.users.edit.userId', { userId: completerUserId }, { inherit: null })}>
								{usersById[completerUserId]?.attributes?.name || '-'}
							</MaybeLink>
							on
							<DateString time date={completedDate} />
						{:else if assignedUserId}
							Assigned to
							<a href={asr.makePath('app.users.edit.userId', { userId: assignedUserId }, { inherit: null })}>
								{usersById[assignedUserId]?.attributes?.name || '-'}
							</a>
						{/if}
					</small>
				</p>
			{/if}
			{#if updaterUserId && (!completedDate || firstDateIsEarlier(completedDate, form.data[form.primaryId].meta.updated))}
				<p class="mt-0 mb-0">
					<small>
						Updated by
						<a href={asr.makePath('app.users.edit.userId', { userId: updaterUserId }, { inherit: null })}>
							{usersById[updaterUserId]?.attributes?.name || '-'}
						</a>
						on
						<a href={asr.makePath('app.notes.edit.noteId', { noteId: form.primaryId }, { inherit: false })}>
							<DateString time date={form.data[form.primaryId].meta.updated} />
						</a>
					</small>
				</p>
			{/if}
		</div>
		{#if rels.files?.data}
			<div class="col-xs-12 col-lg-4">
				{#each rels.files.data.map(({ id }) => includedById[id] || form.data[id]).filter(Boolean) as file}
					<p>
						<a href="/api/v1/files/{file.id}/download">
							<MimeTypeIcon mimeType={file.attributes.mimeType} />
							{file.attributes.originalFilename}
						</a>
					</p>
				{/each}
			</div>
		{/if}
		{#if canEditNote}
			<div class="col-xs-12 col-lg-4 text-end">
				<button class="btn btn-secondary btn-sm" on:click={() => { noteEditorIsOpen = true }}>
					Edit
				</button>
			</div>
		{/if}
	</div>
{/if}

{#if showRaw}
	<pre style="border: 1px solid #ddd; padding: 1em; background-color: #fff; margin: 1em 0;">{JSON.stringify(form, undefined, 4)}</pre>
{/if}
