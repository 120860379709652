<script>
	export let currentTab
	export let name
	$: id = name.toLowerCase()
</script>

<li class="nav-item">
	<a class="nav-link" class:active={currentTab === id} href="#notes-{id}" on:click|preventDefault>
		{name}
	</a>
</li>
