<script>
	import DateString from '@/component/atom/DateString.svelte'
	import { router } from '@/service/router.js'
	import { resourceToLink } from '@/lib/resource-to-link.js'
	export let value
	export let isOriginal
	export let definition
	export let includedById
	$: link = value?.id && value?.type && resourceToLink(router, value, false)
	$: atts = includedById[value?.id]?.attributes
	$: name = atts && (atts.name || atts.title || atts.firstName) || value?.type
</script>

<style>
	span {
		color: #829fa8;
		font-style: italic;
	}
</style>

{#if value === null}
	<span>Empty</span>
{:else if link}
	<a href={link}>{name}</a>
{:else}
	{#if definition?.type === 'string'}
		{#if definition.format === 'date-time'}
			<DateString time date={value} />
		{:else if definition.format === 'date'}
			<DateString date={value} />
		{:else}
			{value}
		{/if}
	{:else if value?.id && value?.type}
		{#if isOriginal}
			<em>Original {value.type}</em>
		{:else}
			<em>New {value.type}</em>
		{/if}
	{:else}
		{typeof value === 'string' ? value : JSON.stringify(value)}
	{/if}
{/if}
