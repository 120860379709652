<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import NoteForm from '@/component/form/NoteForm.svelte'
	import NoteHistories from './NoteHistories.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { patchNote } from '@/lib/controller/note.js'
	import { load } from 'jsonapi-svelte-form/mapper'

	export let asr
	export let api
	export let form
	export let users

	const save = () => {
		form.state = 'saving'
		delete form.errors
		const updated = form.data[form.primaryId]
		const original = form.original[form.primaryId]
		const body = {
			id: original.id,
			type: original.type,
			meta: original.meta,
		}
		const assignedId = updated?.relationships?.assigned?.data?.id
		if (original?.relationships?.assigned?.data?.id !== assignedId) {
			body.relationships = {
				assigned: assignedId
					? { data: { id: assignedId, type: 'user' } }
					: null,
			}
		}
		const attributes = {}
		for (const key of [ 'note', 'reminder', 'completed', 'archived' ]) {
			const value = updated?.attributes?.[key]
			if (original?.attributes?.[key] !== value) {
				attributes[key] = value
					? value
					: null
			}
		}
		if (Object.keys(attributes).length) body.attributes = attributes
		patchNote(api, body)
			.then(response => { form = load(response, 0) })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	Notes
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Edit Note
	</span>
</FormHeaderMain>

<div class="row">
	<div class="col-xs-12 col-lg-6">
		<NoteForm bind:form {users} {asr} />
		<FormErrorsOther {form} />
		<FormCommitButton {form} on:click={save} />
	</div>
	<div class="col-xs-12 col-lg-6">
		<NoteHistories {asr} {api} noteId={form.primaryId} />
	</div>
</div>
