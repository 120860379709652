<script>
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'

	import DateRangeNavbar from './_DateRangeNavbar.svelte'
	import { commonColumns } from './_common-columns.js'
	import { commonSelectableDateRanges } from './_common-selectable-date-ranges.js'
	import { dateRangeFromQuery } from './_date-range-from-query.js'
	import { fetchReport } from './_fetch-report.js'

	export let api
	export let asr
	export let querystringParams

	const label = 'Lead Referral Sources'
	const tableId = 'lead-referral-sources'
	const orderedColumns = [
		'referrer',
		'tag',
		'bats',
		'leads',
		'quoted',
		'lqConversion',
		'ordered',
		'qoConversion',
		'held',
		'cancelled',
	]

	let currentSort
	let data
	let errors
	let included
	let loading = true
	$: dateRange = dateRangeFromQuery({ querystringParams, backward: false, now: new Date() })
	$: filteredLinkParams = {
		filter: {
			minDateType: 'created',
			minDate: dateRange.apiDates.min,
			maxDateType: 'created',
			maxDate: dateRange.apiDates.max,
			includeCancelled: 'true',
			includeHeld: 'true',
		},
	}
	$: columns = commonColumns({
		asr,
		filteredLinkParams,
		overallLink: (columnParams) => ({
			path: 'app.reports.filteredOpportunities',
			params: {
				filter: {
					...filteredLinkParams.filter,
					...columnParams,
				},
			},
		}),
		filteredLink: row => {
			const rowParams = { filter: { ...filteredLinkParams.filter } }
			if (row.id) rowParams.filter.referrerId = row.id
			else rowParams.filter.hasReferrer = 'false'
			return { path: 'app.reports.filteredOpportunities', params: rowParams }
		},
	})
	$: params = dateRange?.apiDates?.min && dateRange?.apiDates?.max && {
		filter: {
			minDate: dateRange.apiDates.min,
			maxDate: dateRange.apiDates.max,
			includeCancelled: 'true',
			includeHeld: 'true',
		},
	}
	$: {
		if (params) {
			fetchReport({ api, url: '/api/v1/reports/groupedOpportunities/referrer', params })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					if (response?.data) data = response.data
					if (response?.included) included = response.included
					currentSort = querystringParams.sort || '-referrer'
					loading = false
				})
		} else {
			loading = false
		}
	}

	$: rows = !loading && data && tableizeReportData({
		body: { data, included },
		sort: currentSort,
		params,
		columns,
		orderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns,
		columns,
		label: 'Lead Referral Sources',
		filename: `${tableId}-${new Date().toISOString().split('T')[0]}.xlsx`,
		rows,
		params: params.filter,
	})
</script>

<DateRangeNavbar
	{asr}
	{columns}
	{dateRange}
	{generateSpreadsheetFile}
	{label}
	{orderedColumns}
	{querystringParams}
	rowCount={rows?.length}
	selectableDateRanges={commonSelectableDateRanges([ 'today', 'thismonth', 'lastmonth', 'custom' ])}
	{tableId}
>
	<div slot="description" style="max-width: 55ch;">
		<p class="mt-1 mb-2">
			All opportunities where the create-date is within the specified date range. (Note that
			the create-date is the same as the "lead date" from BATS.)
		</p>
	</div>
</DateRangeNavbar>

<ReportTable
	id={tableId}
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
