<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	export let asr
	export let form
	export let legId

	$: leg = form.data[legId]
	$: insuranceId = shipper?.relationships?.insurance?.data?.id
	$: shipperId = leg?.relationships?.shipper?.data?.id

	$: shipper = form.data[shipperId]
	$: shipperAtts = shipper?.attributes || {}
	$: shipperContactAtts = form.data[shipper?.relationships?.contact?.data?.id]?.attributes || {}
	$: shipperAddressAtts = form.data[shipper?.relationships?.address?.data?.id]?.attributes || {}
	$: shipperInsuranceAtts = form.data[insuranceId]?.attributes || {}
	$: insuranceAddressAtts = form.data[shipperInsuranceAtts?.relationships?.address?.data?.id]?.attributes || {}
	$: driver = {
		driverFirstName: leg?.attributes?.driverFirstName,
		driverLastName: leg?.attributes?.driverLastName,
		driverPhone: leg?.attributes?.driverPhone,
	}
	$: hasDriver = Object.values(driver).filter(Boolean).length

	$: addressEnd = ((
		shipperAddressAtts.city
			? `${shipperAddressAtts.city || ''}${(shipperAddressAtts.state || shipperAddressAtts.zip) ? ', ' : ''}`
			: ''
	) + `${shipperAddressAtts.state || ''} ${shipperAddressAtts.zip || ''}`).trim()
</script>

{#if shipper?.id}
	<a href={asr.makePath('app.companies.edit.companyId', { companyId: shipper.id }, { inherit: false })}>
		<NamedIcon name="company" />
		{shipperAtts.name || 'N/A'}
	</a>
	{#if shipperAtts.blocked}
		<span class="badge bg-danger">Blocked</span>
	{/if}
	{#if shipperAtts.inactive}
		<span class="badge bg-danger">Inactive</span>
	{/if}
	<br>
{/if}

{#if shipperAtts.hoursOpen}
	<NamedIcon name="clock" />
	{shipperAtts.hoursOpen}
	<br>
{/if}

{#if shipperContactAtts.name}
	<NamedIcon name="user" />
	{shipperContactAtts.name}
	<br>
{/if}

{#if shipperAddressAtts.line1}
	{shipperAddressAtts.line1}
	<br>
{/if}

{#if shipperAddressAtts.line2}
	{shipperAddressAtts.line2}
	<br>
{/if}

{#if addressEnd}
	{addressEnd}
	<br>
{/if}

{#if shipperContactAtts.phonePrimary}
	<NamedIcon name="phone" />
	{shipperContactAtts.phonePrimary}
	<br>
{/if}

{#if shipperContactAtts.phoneTwo}
	<NamedIcon name="phone" />
	{shipperContactAtts.phoneTwo}
	<br>
{/if}

{#if shipperContactAtts.fax}
	<NamedIcon name="fax" />
	{shipperContactAtts.fax}
	<br>
{/if}

{#if shipperContactAtts.emailPrimary}
	<NamedIcon name="email" />
	{shipperContactAtts.emailPrimary}
	<br>
{/if}

{#if shipperContactAtts.emailAlternate}
	<NamedIcon name="email" />
	{shipperContactAtts.emailAlternate}
	<br>
{/if}

{#if hasDriver}
	<div class="row mt-2">
		<div class="col-auto border rounded">
			<strong class="text-muted">Driver</strong>
			{[ driver.driverFirstName, driver.driverLastName ].filter(Boolean).join(' ').trim() || 'N/A'}
			{#if driver.driverPhone}
				<br>
				{driver.driverPhone}
			{/if}
		</div>
	</div>
{/if}
