<script>
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'
	import SpreadsheetDownloader from '@/component/atom/SpreadsheetDownloader.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import { keyBy } from '@/shared/util/key-by.js'
	import {
		assignmentStatus,
		cancellationFilter,
		carrierStatus,
		dateRangeSelector,
		heldFilter,
		referrerRelationship,
		dateType,
	} from '@/shared/value/report-filters.js'
	import { opportunityStatus } from '@/shared/value/opportunity-status.js'
	import { opportunityGroupings } from '@/shared/value/opportunity-groupings.js'
	import { opportunityFilterableDateTypes } from '@/shared/value/opportunity-filterable-date-types.js'
	import { opportunityFilterableDateLabel } from '@/shared/value/opportunity-filterable-date-exists.js'
	import { opportunityColumnOrderDefault, opportunityColumns } from '@/shared/value/report-columns.js'

	// TODO migrate these into this folder, presumably, once the old reports are no more
	import DateRangeNavbar from '../../reports/_DateRangeNavbar.svelte'
	import FilterParam from '../../reports/_FilterParam.svelte'
	import TableColumnDescriptions from '../../reports/_TableColumnDescriptions.svelte'
	import { fetchReport } from '../../reports/_fetch-report.js'
	import { dateRangeFromQuery } from '../../reports/_date-range-from-query.js'

	export let asr
	export let api
	export let query
	export let report

	$: dateRange = dateRangeFromQuery({ querystringParams: query, now: new Date() })

	$: selectableDateRanges = (report?.dateRanges || []).map(key => ({
		label: dateRangeSelector[key] || '!!!!!!',
		params: { dateRange: key },
	}))

	let apiQueryParams
	$: {
		// TODO pagination and sort stuff, eventually
		const r = report || {}
		apiQueryParams = {
			include: [ 'legs' ],
			filter: {
				minDateType: r.minDateType,
				maxDateType: r.maxDateType,
			},
		}
		// Dates
		if (dateRange?.apiDates?.min) apiQueryParams.filter.minDate = dateRange.apiDates.min
		if (dateRange?.apiDates?.max) apiQueryParams.filter.maxDate = dateRange.apiDates.max
		// Core stuff
		if (r.datesExist?.length) apiQueryParams.filter.dateExists = r.datesExist
		if (r.opportunityStatuses?.length) apiQueryParams.filter.opportunityStatus = r.opportunityStatuses.join(',')
		// OnTrack user assignment
		if (r.assignmentStatus === 'set') apiQueryParams.filter.hasAssigned = 'true'
		else if (r.assignmentStatus === 'unset') apiQueryParams.filter.hasAssigned = 'false'
		else if (r.assignmentStatus === 'id' && r.assignedIds?.length) apiQueryParams.filter.assignedIds = r.assignedIds.join(',')
		// Carriers
		// TODO not actually implemented on the API side
		if (r.carrierStatus === 'set') apiQueryParams.filter.hasCarrier = 'true'
		else if (r.carrierStatus === 'unset') apiQueryParams.filter.hasCarrier = 'false'
		else if (r.carrierStatus === 'id' && r.carrierIds?.length) apiQueryParams.filter = r.carrierIds.join(',')
		// Referrers
		if (r.referrerStatus === 'set') apiQueryParams.filter.hasReferrer = 'true'
		else if (r.referrerStatus === 'unset') apiQueryParams.filter.hasReferrer = 'false'
		else if (r.referrerStatus === 'id' && r.referrerIds?.length) apiQueryParams.filter.referrerId = r.referrerIds.join(',')
		else if (r.referrerStatus === 'tag' && r.referrerTags?.length) apiQueryParams.filter.referrerTags = r.referrerTags.join(',')
		// Cancel / Held
		if (r.cancellationStatus === 'include') apiQueryParams.filter.includeCancelled = 'true'
		else if (r.cancellationStatus === 'onlyCancelled') apiQueryParams.filter.onlyCancelled = 'true'
		if (r.heldStatus === 'include') apiQueryParams.filter.includeHeld = 'true'
		else if (r.heldStatus === 'onlyHeld') apiQueryParams.filter.onlyHeld = 'true'
	}
	const filtersThatHaveInputBoxes = {
		minDateType: true,
		minDate: true,
		maxDateType: true,
		maxDate: true,
		referrerId: true,
		assignedId: true,
		dateExists: true,
		includeHeld: true,
		includeCancelled: true,
	}
	$: helpFilterParams = Object
		.keys(apiQueryParams?.filter || {})
		.filter(key => !filtersThatHaveInputBoxes[key])

	$: columns = opportunityColumns({ asr })
	$: orderedColumns = report?.orderedColumns || opportunityColumnOrderDefault

	let helpOpen
	let currentSort
	let data
	let errors
	let loading = true
	let included
	$: {
		if (apiQueryParams?.filter?.minDate || apiQueryParams?.filter?.maxDate) {
			fetchReport({ api, url: `/api/v1/reports/groupedOpportunities/${report?.grouping || 'id'}`, params: apiQueryParams })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					if (response?.data) data = response.data
					if (response?.included) included = response.included
					currentSort = query.sort || '-id'
					loading = false
				})
		} else {
			loading = false
		}
	}
	$: includedById = included?.length ? keyBy(included, []) : []
	$: assignedUser = includedById && includedById[apiQueryParams?.filter?.assignedId]
	$: referrer = includedById && includedById[apiQueryParams?.filter?.referrerId]
	$: rows = !loading && data && tableizeReportData({
		body: { data },
		sort: currentSort,
		params: apiQueryParams,
		columns,
		orderedColumns,
	})

	const sanitizeFileName = string => string.replaceAll(' ', '-').replaceAll(/[^a-zA-Z0-9-]/g, '').toLowerCase()

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns,
		columns,
		label: report?.name || 'Filtered Opportunities',
		filename: `${ report?.name ? sanitizeFileName(report.name) : 'filtered-opportunities' }-${new Date().toISOString().replaceAll(':', '')}.xlsx`,
		rows,
		params: apiQueryParams?.filter || {},
	})
</script>

<DateRangeNavbar
	{asr}
	{columns}
	{dateRange}
	{generateSpreadsheetFile}
	label={report?.name || 'Custom Report'}
	{orderedColumns}
	querystringParams={query}
	rowCount={rows?.length}
	{selectableDateRanges}
	cloneReport={report}
	tableId="filtered-opportunities"
>
	<div slot="description" style="max-width: 55ch;">
		<p class="mt-1 mb-2">
			{report.about || 'This is a custom report, you can clone it to modify it.'}
		</p>
	</div>
</DateRangeNavbar>

<div class="card my-1">
	<div class="card-body p-2 pb-0">
		<div class="row">
			{#if apiQueryParams?.filter?.minDate}
				<FilterParam
					icon="date"
					label="Min. Date (UTC)"
					key={dateType[apiQueryParams?.filter?.minDateType] || `ERROR: ${apiQueryParams.filter.minDateType}`}
					value={apiQueryParams?.filter?.minDate}
				/>
			{/if}
			{#if apiQueryParams?.filter?.maxDate}
				<FilterParam
					icon="date"
					label="Max. Date (UTC)"
					key={dateType[apiQueryParams?.filter?.maxDateType] || `ERROR: ${apiQueryParams.filter.maxDateType}`}
					value={apiQueryParams?.filter?.maxDate}
				/>
			{/if}
			{#if apiQueryParams?.filter?.dateExists?.length}
				{#each apiQueryParams.filter.dateExists as key}
					<FilterParam
						icon="date"
						label="Date Exists"
						value={opportunityFilterableDateLabel[key] || `ERROR: ${key}`}
					/>
				{/each}
			{/if}
			{#if apiQueryParams?.filter?.assignedId}
				<FilterParam
					icon="user"
					label="Assigned"
					value={assignedUser?.attributes?.name || apiQueryParams?.filter?.assignedId}
				/>
			{/if}
			{#if apiQueryParams?.filter?.referrerId}
				<FilterParam
					icon="user"
					label="Referrer"
					value={referrer?.attributes?.name || apiQueryParams?.filter?.referrerId}
				/>
			{/if}
			{#if apiQueryParams?.filter?.includeHeld}
				<FilterParam
					label="Include Held"
					value={apiQueryParams?.filter?.includeHeld}
				/>
			{/if}
			{#if apiQueryParams?.filter?.includeCancelled}
				<FilterParam
					label="Include Cancelled"
					value={apiQueryParams?.filter?.includeCancelled}
				/>
			{/if}
		</div>
	</div>
</div>

{#if helpOpen}
	<div class="card mt-3">
		<div class="card-body">
			{#if helpFilterParams.length}
				<p>
					This is a drill-down list of opportunities, filtered by the following properties:
				</p>
				<table class="table table-sm bg-light text-dark">
					<thead>
					<tr>
						<th>Key</th>
						<th>Value</th>
					</tr>
					</thead>
					<tbody>
					{#each helpFilterParams as key}
						<tr>
							<td>{key}</td>
							<td>{apiQueryParams?.filter[key]}</td>
						</tr>
					{/each}
					</tbody>
				</table>
			{/if}
			<TableColumnDescriptions {columns} {orderedColumns} />
		</div>
	</div>
{/if}

<ReportTable
	id="filtered-opportunities"
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	querystringParams={query}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
