<script>
	import { Field } from 'jsonapi-svelte-form'
	import InputGroup from './InputGroup.svelte'
	import InputTypeBare from './InputTypeBare.svelte'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate

	export let label
	export let labelWidth = '85px'
	export let inputMinWidth
	export let type = 'text'
	export let hideArrows
	export let placeholder
	export let icon

	$: elementId = [ id, ...keypath ].join('.')
</script>

<Field bind:form {id} {keypath} on:change let:errors>
	<InputGroup {form} {id} {keypath} {elementId} {errors} {icon} {label} {labelWidth}>
		<InputTypeBare {type} {hideArrows} {inputMinWidth} {id} bind:form {readonly} {onCreate} {keypath} {placeholder} />
	</InputGroup>
</Field>
