import { saving, error, saved } from 'jsonapi-svelte-form/mapper'
import { errorFormatter } from '@/shared/util/error-formatter.js'

export const commitForm = async ({ patch, form, changeNote, onlyOntrackSync, url, request, callback }) => {
	let savingDetails
	try {
		savingDetails = saving(form)
	} catch (savingError) {
		throw { status: 'error', errors: [ errorFormatter(savingError) ] }
	}

	let response
	let responseMeta
	try {
		const body = patch
			? { data: patch }
			: savingDetails.body
		if (changeNote) {
			body.meta = body.meta || {}
			body.meta.changeNote = changeNote
		}
		if (onlyOntrackSync) {
			body.meta = body.meta || {}
			body.meta.onlyOntrackSync = true
		}
		const rawResponse = await request(url, { body })
		responseMeta = rawResponse.body.meta
		response = saved(rawResponse.body)
	} catch (errorResponse) {
		const body = errorResponse.body
		if (body?.links?.audit && Array.isArray(body?.errors)) {
			for (const e of body.errors) {
				e.meta = e.meta || {}
				e.meta.auditLink = body.links.audit
			}
		}
		throw error({ body, remap: savingDetails.remap })
	}

	if (callback) {
		try {
			callback(response)
		} catch (callbackError) {
			throw { status: 'error', errors: [ errorFormatter(callbackError) ] }
		}
	}

	response._meta = responseMeta
	return response
}
