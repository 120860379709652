import { router } from '@/service/router.js'
import { getCurrentUser } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { login } from '@/service/api/user.js'
import { minimumDelay } from '@/lib/delay.js'
import { AuthSimpleLogin } from 'falcon'
import { authConfig as config } from '@/lib/layout-values.js'

const getRedirect = parameters => {
	let name = 'app'
	let params
	try {
		const redirect = JSON.stringify(parameters.redirect)
		name = redirect.name
		params = redirect.params
	} catch (ignore) {
		// bad data or no redirect found
	}
	return { name, params }
}

export default {
	template: AuthSimpleLogin,
	resolve: (data, parameters) => getCurrentUser({ get })
		.then(
			// resolved promise means the user is logged in, so
			// redirect to the original location if one is set
			// in query params, or to `app` otherwise
			() => Promise.reject({
				redirectTo: getRedirect(parameters),
			}),
			// user is not logged in, present normal login
			() => ({
				config,
				form: {
					login: {
						email: parameters.email,
					},
				},
			}),
		),
	activate: ({ domApi, parameters }) => {
		domApi.$on('submit', ({ detail: { name, form } }) => {
			form._state = {
				submitting: true,
			}
			domApi.$set({ form })
			// TODO it would be good to remove `logout=true` from query params on any form submit
			minimumDelay(600, login({ email: form[name].email, password: form[name].password }))
				.then(() => {
					const { name, params } = getRedirect(parameters)
					router.go(name, params)
				})
				.catch(error => {
					if (parameters && parameters.logout) {
						delete parameters.logout
					}
					form._state = {
						errors: error.body.errors,
					}
					domApi.$set({ form })
				})
		})
	},
}
