<script>
	import ReferrersNavbar from '../../_ReferrersNavbar.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import ReferrerForm from '@/component/form/ReferrerForm.svelte'
	import { previousState } from '@/service/store/router-state.js'
	import { updateReferrer } from '@/lib/controller/referrer.js'

	export let asr
	export let api
	export let form

	$: justCreated = $previousState === 'app.referrers.create'
	$: label = `Edit ${form.data[form.primaryId]?.attributes?.name || 'N/A'}`

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateReferrer(api, form)
			.then(response => { form = response })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<ReferrersNavbar {asr} {label}>
	{#if justCreated}
		<div class="col-auto">
			<span class="badge bg-success">Created new Referrer Source 🎉</span>
		</div>
	{/if}
</ReferrersNavbar>

<ReferrerForm bind:form />

<FormErrorsOther {form} />

<FormCommitButton {form} on:click={save} />
