<script>
	import { onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import CompanyTable from '@/component/table/Company.svelte'
	import NotesTable from '@/component/table/Notes.svelte'
	import OpportunitiesTable from '@/component/table/Opportunities.svelte'
	import ReferrersTable from '@/component/table/Referrers.svelte'
	import UsersTable from '@/component/table/Users.svelte'
	import SearchOpportunitiesDelivered from './SearchOpportunitiesDelivered.svelte'
	import { listCompanies } from '@/lib/controller/company.js'
	import { listAllNotes } from '@/lib/controller/note.js'
	import { getOpportunityById, listOpportunities } from '@/lib/controller/opportunity.js'
	import { listReferrers } from '@/lib/controller/referrer.js'
	import { listUsers } from '@/lib/controller/user.js'
	import { highlightedWord } from '@/service/store/highlighter.js'

	export let api
	export let asr
	export let query
	export let currentTab

	const typeToName = {
		companies: 'Companies',
		notes: 'Notes',
		opportunities: 'Opportunities',
		referrers: 'Referrers',
		users: 'Users',
	}
	const typeToIconName = {
		companies: 'company',
		notes: 'note',
		opportunities: 'opportunity',
		referrers: 'referrer',
		users: 'users',
	}
	const statusToSearchTitle = {
		'exact': 'Exact Match',
		'leads': 'Leads',
		'quotes': 'Quotes',
		'orders': 'Orders',
	}


	const opportunityColumnNames = [
		'updated',
		'status',
		'overviewId',
		'customerDetails',
		'customerNotes',
		'firstAvailable',
		'vehicles',
		'pickup',
		'dropoff',
	]

	const OPPORTUNITY = /^\d+[pP]?$/
	let opportunityId
	$: {
		if (query && OPPORTUNITY.test(query)) opportunityId = parseInt(query.replace(/p/i, ''), 10)
	}

	const responses = {}
	const typeToRequestMaker = {
		companies: () => listCompanies(api, {
			filter: {
				name: query,
				sort: '-meta.updated',
			},
		}),
		notes: () => listAllNotes(api, {
			filter: {
				hasArchived: false,
				noteText: query,
			},
			sort: '-meta.updated',
		}),
		opportunities: () => {
			return Promise
				.all([
					// exact lookup
					opportunityId && getOpportunityById(api, `${opportunityId}P`).catch(() => null),
					listOpportunities(api, { filter: { status: 'lead', search: query }, sort: '-meta.updated' }),
					listOpportunities(api, { filter: { status: 'quote', search: query }, sort: '-meta.updated' }),
					listOpportunities(api, { filter: { status: 'order', search: query }, sort: '-meta.updated' }),
				])
				.then(([ exact, leads, quotes, orders ]) => {
					if (exact) exact.data = [ exact.data ] // array to make them all the same
					return { exact, leads, quotes, orders }
				})
		},
		referrers: () => listReferrers(api, {
			filter: {
				'attributes.name': query,
			},
		}),
		users: () => Promise
			.all([
				listUsers(api, { filter: { 'attributes.active': 'true' } }),
				listUsers(api, { filter: { 'attributes.active': 'false' } }),
			])
			.then(([ active, inactive ]) => {
				const response = active
				response.data = [ ...response.data, ...inactive.data ]
				response.data = response.data.filter(user =>
					user.attributes.name?.toLowerCase().includes(query.toLowerCase())
					|| user.attributes.email.toLowerCase().includes(query.toLowerCase()),
				)
				return response
			}),
	}
	onMount(() => {
		highlightedWord.set(query)
		typeToRequestMaker[currentTab]?.().then(response => {
			responses[currentTab] = response
		})
	})
</script>

<style>
	:global(.search-results-tables .highlighted) {
		/*color: #000;*/
		background-color: #e6e46a;
	}
</style>

<div class="row">
	<div class="col-auto mb-2">
		<h4 style="display: inline;">Search Results</h4>
		for
			<code>{query}</code>
	</div>
</div>

<div class="card">
	<div class="card-header">
		<ul class="nav nav-tabs" role="tablist">
			{#each Object.keys(typeToName) as type}
				<li class="nav-item">
					<a
						class="nav-link"
						style="white-space: nowrap;"
						class:active={currentTab === type}
						href={asr.makePath('app.search', { tab: type }, { inherit: true })}
						role="tab"
					>
						{typeToName[type]}
						<span class="ps-2">
							<NamedIcon name={typeToIconName[type]} />
						</span>
					</a>
				</li>
			{/each}
		</ul>
	</div>
	<div class="card-body bg-light search-results-tables">
		{#if responses[currentTab]}
			{#if currentTab === 'companies'}
				<CompanyTable
					{asr}
					{...responses[currentTab]}
					sort="-meta.updated"
				/>
			{:else if currentTab === 'notes'}
				<NotesTable
					{api}
					{asr}
					sort="-meta.updated"
					currentTab="active"
					notesResponse={responses.notes}
					noDataFoundText="No notes found."
				/>
			{:else if currentTab === 'opportunities'}
				{#each [ opportunityId && 'exact', 'leads', 'quotes', 'orders' ].filter(Boolean) as status}
					<h5>
						{statusToSearchTitle[status]}
						{#if status === 'exact'}
							🎉
						{/if}
					</h5>
					{#if responses.opportunities[status]?.data?.length}
						<OpportunitiesTable
							{asr}
							columnNames={opportunityColumnNames}
							{...responses[currentTab][status]}
						/>
					{:else}
						<p>No results found.</p>
					{/if}
				{/each}
				<h5>Delivered</h5>
				<SearchOpportunitiesDelivered
					{asr}
					{api}
					{query}
				/>
			{:else if currentTab === 'referrers'}
				<ReferrersTable
					{asr}
					{...responses[currentTab]}
				/>
			{:else if currentTab === 'users'}
				<UsersTable
					{asr}
					{...responses[currentTab]}
					columnNames={[ 'name', 'email', 'permissions', 'lastLogin', 'active' ]}
				/>
			{/if}
		{:else}
			<p class="text-center">Loading...</p>
		{/if}
	</div>
</div>
