<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import { createEventDispatcher } from 'svelte'
	import { getCompanyById } from '@/lib/controller/company.js'
	import { router } from '@/service/router.js'
	import { keyBy } from '@/shared/util/key-by.js'
	import TerminalList from '../terminal/TerminalList.svelte'
	import TerminalSearch from '@/component/molecule/TerminalSearch.svelte'
	import TerminalApiLookup from '../terminal/TerminalApiLookup.svelte'
	import ViewLegTerminal from './ViewLegTerminal.svelte'

	export let api
	export let form
	export let companyData = { byId: {}, includedById: {} }
	export let actionName
	export let legId
	export let readonly

	$: terminalId = form.data[legId]?.relationships?.[`${actionName}Terminal`]?.data?.id
	$: terminal = companyData?.byId[terminalId]
	$: terminalPromise = terminalId && !terminal && getCompanyById(api, terminalId)

	let searchType = 'name'
	let searchString = ''

	const dispatch = createEventDispatcher()
</script>

<InputType
	label="Terminal Fee"
	icon="money"
	labelWidth="16ch"
	inputMinWidth="75px"
	type="double"
	bind:form
	id={legId}
	keypath={[ 'attributes', `${actionName}TerminalFee` ]}
/>

{#if terminalPromise}
	{#await terminalPromise}
		<p>Loading...</p>
	{:then response}
		<ViewLegTerminal
			{readonly}
			terminal={response.data}
			includedById={keyBy(response.included, 'id')}
			action="Change"
			on:click={() => dispatch('removeTerminal')}
		/>
	{:catch error}
		<p>Error while loading terminal!</p>
		<pre>{JSON.stringify(error, undefined, 4)}</pre>
	{/await}
{:else if terminal}
	<ViewLegTerminal
		{readonly}
		{terminal}
		includedById={companyData?.includedById || {}}
		action="Change"
		on:click={() => dispatch('removeTerminal')}
	/>
{:else}
	<TerminalSearch
		{legId}
		bind:searchType
		bind:searchString
	/>
	{#if searchString}
		<TerminalApiLookup {api} {searchType} {searchString} let:responsePromise>
			{#await responsePromise}
				<p>Loading...</p>
			{:then response}
				<p>
					<a href={router.makePath('app.companies.create', undefined, { inherit: false })} target="_blank">
						Need to make a new Terminal?
					</a>
				</p>
				<TerminalList
					{response}
					action="Select"
					on:click={event => dispatch('selectTerminal', { terminalId: event.detail, response })}
				/>
			{:catch error}
				<p>Error while loading terminals!</p>
				<pre>{JSON.stringify(error, undefined, 4)}</pre>
			{/await}
		</TerminalApiLookup>
	{:else}
		<p>
			Start typing to search for a Terminal, or
			<a href={router.makePath('app.companies.create', undefined, { inherit: false })} target="_blank">
				make a new one.
				<NamedIcon name="link" />
			</a>
		</p>
	{/if}
{/if}
