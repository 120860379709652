<script>
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import Card from '@/component/atom/Card.svelte'
	import CentralDispatchIcon from '@/component/atom/CentralDispatchIcon.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import { router } from '@/service/router.js'

	const creditCardStatusToLabel = {
		failed: 'CC Failed',
		completed: 'CC Processed',
	}

	const QUOTE_EXPIRATION_MILLIS = 10 * 24 * 60 * 60 * 1000

	export let form
	export let ccTransactionId
	export let ccTransactions
	export let leadAndQuoteEmails

	$: legDates = form.data[form.primaryId]?.attributes?.legDates || {}
	$: quoteEmails = (leadAndQuoteEmails || [])
		.filter(email => email?.relationships?.template?.data?.id === 'quote')
	$: orderEmails = (leadAndQuoteEmails || [])
		.filter(email => email?.relationships?.template?.data?.id === 'order')

	$: dates = [
		{
			label: 'Lead Captured',
			fa: 'play',
			date: form.data[form.primaryId]?.meta?.created,
		},
		...quoteEmails
			.map((email, index) => ({
				label: index > 0
					? '⮑ Retry'
					: 'Quote Sent',
				fa: index === 0 && 'play-circle',
				date: email.meta.created,
				expired: new Date(email.meta.created).getTime() < (Date.now() - QUOTE_EXPIRATION_MILLIS),
			})),
		...orderEmails
			.map(email => ({
				label: 'Order Email Sent',
				date: email.meta.created,
			})),
		...(ccTransactions || [])
			.map(cc => ({
				label: creditCardStatusToLabel[cc.attributes?.status] || cc.attributes?.status || '???',
				date: cc.attributes?.ran,
				id: cc.id,
				href: router.makePath('app.ccTransactions.ccTransactionId', { ccTransactionId: cc.id }, { inherit: false }),
				fa: 'credit-card',
				color: cc.attributes?.status === 'completed' ? false : 'warning',
			})),
		legDates.deliver && {
			label: 'Delivered',
			fa: 'check-circle',
			date: legDates.deliver,
		},
	]
		.filter(item => item?.date)
		.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
</script>

<style>
	.highlight-row {
		background-color: var(--falcon-table-success-bg);
	}
</style>

<Card title="Status" bodyClass="p-2">
	<table class="table table-sm table-striped mb-2">
		{#each dates as { label, date, id, href, fa, icon, color, expired }}
			<tr class:highlight-row={ccTransactionId && id === ccTransactionId}>
				<td>
					{#if fa}
						<span class="{expired ? 'text-danger' : (color ? `text-${color}` : '')}">
							<span class="fa fa-{fa}"></span>
						</span>
					{:else if icon === 'cd'}
						<CentralDispatchIcon />
					{/if}
				</td>
				<th>
					{label}
					{#if expired}
						<em class="text-danger fw-lighter">Expired</em>
					{/if}
				</th>
				<td>
					<MaybeLink {href}>
						<DateString time={date?.includes('T')} {date} />
					</MaybeLink>
				</td>
			</tr>
		{/each}
	</table>
</Card>
