<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import BrowserDetails from './loginHistory/BrowserDetails.svelte'
	import ConnectionDetails from './loginHistory/ConnectionDetails.svelte'

	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'browser',
		'connection',
		'created',
	]

	const nameToColumn = {
		browser: {
			title: 'Browser',
			component: BrowserDetails,
			keypath: 'attributes.cleanUserAgent',
		},
		connection: {
			title: 'Connection',
			component: ConnectionDetails,
		},
		created: {
			title: 'Created',
			component: DateTime,
			keypath: 'meta.created',
			time: true,
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
