import template from './Overview.svelte'
import { getCustomReportById } from '@/lib/controller/custom-report.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Custom Report',
	},
	template,
	resolve: async (_, { customReportId }) => {
		const { data } = await getCustomReportById({ get }, customReportId)
		return {
			form: load({ data }, 0),
		}
	},
}
