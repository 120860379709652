<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let value
	export let icon
	export let label
	export let labelWidth = '85px'
</script>

<div class="input-group input-group-sm mb-2">
	<span class="input-group-text justify-content-center" style="width: {labelWidth};">
		<label for="readonly" class="form-label-sm mb-0" style="font-weight: normal !important;">
			{#if icon}
				<NamedIcon name={icon} />
			{/if}
			{label}
		</label>
	</span>
	<input
		id="readonly"
		readonly
		type="text"
		{value}
		class="form-control form-control-sm"
	>
</div>
