<script>
	const LEG_NUMBER = /(.+) \(Leg (\d+)\)/

	export let value

	$: rows = (value?.split(';') || [])
		.map(row => {
			const match = LEG_NUMBER.exec(row)
			if (match) return {
				value: match[1],
				num: match[2],
			}
			else return {
				value: row,
			}
		})
</script>

{#each rows as row, index}
	<p class="mb-0">
		{#if row.num}
			<span class="badge bg-light border border-secondary text-secondary" style="width: 3ch;">{row.num}</span>
		{/if}
		{row.value}
	</p>
{/each}
