<script>
	import { listCompanies } from '@/lib/controller/company.js'

	export let api
	export let searchType
	export let searchString

	$: queryParams = {
		'page[limit]': 15,
		'filter[service]': 'terminal',
		'filter[inactive]': false,
		'filter[blocked]': false,
		[`filter[${searchType}]`]: searchString,
	}
	$: responsePromise = searchString
		? listCompanies(api, queryParams)
		: new Promise(() => { /* never resolves */ })
</script>

<slot {responsePromise} />
