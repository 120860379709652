<script>
	import { Field } from 'jsonapi-svelte-form'
	import dlv from 'dlv'

	export let form
	export let id
	export let keypath
	export let readonly
	export let onCreate

	export let label = ''
	export let icon = ''

	let autoGrowElement

	$: elementId = [ id, ...keypath ].join('.')
	$: hasChanges = !!form?.changes[id]?.find(c => c.path?.every((p, index) => keypath?.[index] === p))
	$: textAreaValue = dlv(form.data[id], keypath)
	$: {
		if (autoGrowElement?.dataset) autoGrowElement.dataset.replicatedValue = textAreaValue
	}
</script>

<style>
	/*
	cribbed from https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
	*/
	div {
		/* plop the elements on top of each other and have them both sized based on the tallest one's height */
		display: grid;
	}
	div::after {
		/* Note the weird space! Needed to prevent jumpy behavior */
		content: attr(data-replicated-value) " ";

		/* This is how textarea text behaves */
		white-space: pre-wrap;

		/* Hidden from view, clicks, and screen readers */
		visibility: hidden;
	}
	div > textarea {
		/* You could leave this, but after a user resizes, then it ruins the auto sizing */
		resize: none;
		/* Firefox shows scrollbar on growth, you can hide like this. */
		overflow: hidden;
	}
	div > textarea,
	div::after {
		/* Identical styling required!! */
		/*border: 1px solid black;*/
		padding: 0.5em;
		font: inherit;
		/* Place on top of each other */
		grid-area: 1 / 1 / 2 / 2;
	}
	/* highlight when there are changes */
	div,
	textarea {
		margin: 1px;
	}
	div.highlight {
		border: 1px solid var(--falcon-info);
		border-radius: 5px;
	}
	textarea.highlight {
		margin: 0;
	}
</style>

{#if label}
	<label for={elementId} class="form-label-sm mb-0">
		{#if icon}
			<span class="fa fa-{icon}"></span>
		{/if}
		{label}
	</label>
{/if}

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	<div bind:this={autoGrowElement} class:highlight={hasChanges}>
		<textarea
			readonly={readonly || disabled}
			{value}
			on:input={ event => { (form.data[id] || onCreate(create)); set(event.target.value || undefined) } }
			on:*
			class="form-control form-control-sm"
			class:highlight={hasChanges}
			class:is-invalid={errors?.length}
			id={elementId}
		/>
	</div>
	{#each errors as error}
		<div class="invalid-feedback">{error.title}</div>
	{/each}
</Field>
