import template from './Overview.svelte'
import { getReferrerById } from '@/lib/controller/referrer.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { referrerId }) => {
		const referrer = await getReferrerById({ get }, referrerId)
		return {
			form: load(referrer, 0),
		}
	},
}
