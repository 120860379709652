<script>
	import { FieldSetter } from 'jsonapi-svelte-form'
	import { clone } from '@/shared/util/clone.js'

	import NavItem from './NavItem.svelte'
	import EditLegFlipButton from './EditLegFlipButton.svelte'
	import EditLegSplitButton from './EditLegSplitButton.svelte'
	import EditLegLocationTerminal from './EditLegLocationTerminal.svelte'
	import EditLegAddress from './EditLegAddress.svelte'
	import EditLegPerson from './EditLegPerson.svelte'
	import EditLegPhones from './EditLegPhones.svelte'
	import TerminalAddress from './TerminalAddress.svelte'
	import TerminalPhone from './TerminalPhone.svelte'

	export let api
	export let form
	export let companyData
	export let pickup
	export let legId
	export let legIndex
	export let previousLegId
	export let readonly

	$: actionName = pickup ? 'pickup' : 'dropoff'
	$: leg = form.data[legId] // may not exist yet
	$: previousLeg = previousLegId && form.data[previousLegId]
	$: canFlipOrSplit = form.data[legId]?.attributes && form.data[legId]?.relationships && !form.data[legId]?.attributes?.dates?.post

	$: legTerminalId = form.data[legId]?.relationships?.[`${actionName}Terminal`]?.data?.id
	$: previousLegDropoffTerminalId = form.data[previousLeg?.id]?.relationships?.dropoffTerminal?.data?.id

	$: opportunityStatus = form.data[form.primaryId]?.attributes?.status
	$: opportunityContactId = form.data[form.primaryId]?.relationships?.customerContact?.data?.id
	$: legContactId = form.data[legId]?.relationships?.[`${actionName}Contact`]?.data?.id
	$: legAddressId = form.data[legId]?.relationships?.[`${actionName}Address`]?.data?.id
	$: legTerminalContactId = companyData.byId[legTerminalId]?.relationships?.contact?.data?.id
	$: legTerminalAddressId = companyData.byId[legTerminalId]?.relationships?.address?.data?.id
	$: previousLegContactId = form.data[previousLegId]?.relationships?.dropoffContact?.data?.id
	$: previousLegAddressId = form.data[previousLegId]?.relationships?.dropoffAddress?.data?.id
	$: previousLegTerminalDropoffContactId = form.data[previousLegDropoffTerminalId]?.relationships?.contact?.data?.id
	$: previousLegTerminalDropoffAddressId = form.data[previousLegDropoffTerminalId]?.relationships?.address?.data?.id

	$: tab = leg?.attributes?.[`${actionName}Type`]

	$: contactRelName = (tab === 'custom' && `${actionName}Contact`)
		|| (tab === 'customer' && 'customerContact')
		|| (tab === 'previousLeg' && previousLegDropoffTerminalId && 'contact')
		|| (tab === 'previousLeg' && 'dropoffContact')
		|| (tab === 'terminal' && 'contact')
	$: contactParentRelId = (tab === 'custom' && legId)
		|| (tab === 'customer' && form.primaryId)
		|| (tab === 'previousLeg' && previousLegDropoffTerminalId)
		|| (tab === 'previousLeg' && previousLegId)
		|| (tab === 'terminal' && legTerminalId)

	$: addressRelName = ((tab === 'custom' || tab === 'customer') && `${actionName}Address`)
		|| (tab === 'previousLeg' && previousLegDropoffTerminalId && 'address')
		|| (tab === 'previousLeg' && 'dropoffAddress')
		|| (tab === 'terminal' && 'address')
	$: addressParentRelId = ((tab === 'custom' || tab === 'customer') && legId)
		|| (tab === 'previousLeg' && previousLegDropoffTerminalId)
		|| (tab === 'previousLeg' && previousLegId)
		|| (tab === 'terminal' && legTerminalId)

	$: disableEditing = opportunityStatus === 'delivered'
		|| form.data[form.primaryId]?.attributes?.cancelled
		|| readonly
	$: terminalTabSelected = tab === 'terminal' || (tab === 'previousLeg' && previousLegDropoffTerminalId)

	/*
	The only reason we have this complexity at all is that if you tab over
	to something else, e.g. from "Customer" to "New" and then tab back, you
	don't want the "Customer" tab to be blank. Other than that, the only
	complexity is that switching tabs doesn't change the "address" except
	if you switch to "Terminal" and select one.
	 */
	let previousTabStates = {}
	const changeTab = set => ({ detail: toTabName }) => {
		if (!form.data[legId].relationships) form.data[legId].relationships = {}
		if (!form.data[legId].attributes) form.data[legId].attributes = {}

		const legTabKey = `${actionName}Type`

		// Preserve the current relationships
		const fromTabName = form.data[legId].attributes[legTabKey]
		const currentTabRels = clone({
			contact: form.data[legId].relationships[`${actionName}Contact`],
			address: form.data[legId].relationships[`${actionName}Address`],
			terminal: form.data[legId].relationships[`${actionName}Terminal`],
		})

		// If there was a saved tab state for the tab name, restore it, otherwise clear the old
		const restoredTabRels = clone(previousTabStates[toTabName] || {})
		if (restoredTabRels.terminal) form.data[legId].relationships[`${actionName}Terminal`] = restoredTabRels.terminal
		else delete form.data[legId].relationships[`${actionName}Terminal`]
		if (restoredTabRels.contact) form.data[legId].relationships[`${actionName}Contact`] = restoredTabRels.contact
		else delete form.data[legId].relationships[`${actionName}Contact`]
		if (restoredTabRels.address) form.data[legId].relationships[`${actionName}Address`] = restoredTabRels.address
		else if (toTabName === 'terminal') delete form.data[legId].relationships[`${actionName}Address`]

		// Update the tab name on `attributes.(pickup|dropoff)Type`
		form.data[legId].attributes[legTabKey] = toTabName

		// Then save the previous tab state
		previousTabStates[fromTabName] = currentTabRels

		set({ ...form.data[legId].relationships })
	}

	const selectTerminal = set => ({ detail: { terminalId, response } }) => {
		for (const inc of response.included) companyData.includedById[inc.id] = inc
		for (const d of response.data) companyData.byId[d.id] = d

		form._otherIncludedById = form._otherIncludedById || {}
		for (const inc of response.included) form._otherIncludedById[inc.id] = inc
		for (const d of response.data) form._otherIncludedById[d.id] = d

		const terminal = response.data.find(d => d.id === terminalId)
		if (terminal) {
			form.data[terminal.id] = terminal
			form.original[terminal.id] = clone(terminal)
		}
		if (!form.data[legId]?.relationships) form.data[legId].relationships = {}
		form.data[legId].relationships[`${actionName}Terminal`] = {
			data: {
				id: terminalId,
				type: 'company',
			},
		}
		set({ ...form.data[legId].relationships })
	}
	const removeTerminal = set => () => {
		delete form.data[legId].relationships[`${actionName}Terminal`]
		set({ ...form.data[legId].relationships })
	}

	const createAddress = create => {
		create({ relId: addressParentRelId, relName: addressRelName, type: 'address' })
	}
</script>

<style>
	/* styles for dashed line connecting map icons */
	.opportunity-order-leg-location {
		position: relative;
	}
	.opportunity-order-leg-location:before {
		content: "";
		position: absolute;
		top: 2.5em;
		bottom: 0;
		left: 1.5em;
		width: 1em;
		border-left: 2px dashed var(--falcon-body-color);
	}
</style>

<FieldSetter bind:form id={legId} tokens={[ 'relationships' ]} let:set>
	<div class="row g-0">
		<div class="col-auto {pickup && 'opportunity-order-leg-location' || ''}" style="width: 90px;">
			<p class="mt-2 mb-2">
				<span class="fa fa-map-marked-alt"></span>
				{pickup ? 'Pickup' : 'Deliver'}
			</p>
			{#if !pickup}
				{#if canFlipOrSplit && [ 'quote', 'order' ].includes(opportunityStatus)}
					<div class="d-grid pe-3 mb-2">
						<EditLegFlipButton
							bind:form
							{legId}
							{legIndex}
						/>
					</div>
				{/if}
				{#if canFlipOrSplit && opportunityStatus === 'order'}
					<div  class="d-grid pe-3">
						<EditLegSplitButton
							bind:form
							{legId}
						/>
					</div>
				{/if}
			{/if}
		</div>
		<div class="col">
			<div class="row">
				<div class={terminalTabSelected ? 'col-xs-12' : 'col-xs-12 col-lg-8'}>
					<ul class="nav nav-tabs mb-3">
						{#if pickup && legIndex > 0}
							<NavItem tab="previousLeg" current={tab} on:change={changeTab(set)} disabled={disableEditing}>
								Previous Leg Delivery
							</NavItem>
						{/if}
						<NavItem tab="customer" current={tab} on:change={changeTab(set)} disabled={disableEditing}>
							Customer
						</NavItem>
						<NavItem tab="custom" current={tab} on:change={changeTab(set)} disabled={disableEditing}>
							New
						</NavItem>
						<NavItem tab="terminal" current={tab} on:change={changeTab(set)} disabled={disableEditing}>
							Terminal
						</NavItem>
					</ul>
				</div>
			</div>
			{#if terminalTabSelected || tab === 'previousLeg' && previousLegDropoffTerminalId}
				<div class="row">
					<div class={legTerminalId ? 'col-xs-12 col-md-6 col-lg-4' : 'col'}>
						<EditLegLocationTerminal
							bind:form
							actionName={(tab === 'previousLeg' && previousLegDropoffTerminalId) ? 'dropoff' : actionName}
							{api}
							{companyData}
							readonly={disableEditing || (tab === 'previousLeg' && previousLegDropoffTerminalId)}
							legId={(tab === 'previousLeg' && previousLegDropoffTerminalId) ? previousLegId : legId}
							on:selectTerminal={selectTerminal(set)}
							on:removeTerminal={removeTerminal(set)}
						/>
					</div>
					{#if legTerminalId}
						<div class="col-xs-12 col-md-6 col-lg-4">
							<TerminalPhone contact={companyData.includedById[legTerminalContactId]} />
						</div>
						<div class="col-xs-12 col-md-6 col-lg-4">
							<TerminalAddress address={companyData.includedById[legTerminalAddressId]} />
						</div>
					{/if}
				</div>
			{:else}
				<div class="row">
					<div class="col-xs-12 col-md-6 col-lg-4">
						<EditLegPerson
							bind:form
							readonly={tab === 'previousLeg' || tab === 'customer' || disableEditing}
							{contactParentRelId}
							{contactRelName}
						/>
					</div>
					<div class="col-xs-12 col-md-6 col-lg-4">
						<EditLegPhones
							bind:form
							readonly={tab === 'previousLeg' || tab === 'customer' || disableEditing}
							{contactParentRelId}
							{contactRelName}
						/>
					</div>
					<div class="col-xs-12 col-md-6 col-lg-4 mt-lg-n5">
						<div class="mb-lg-2">
							<strong>Address</strong>
						</div>
						<EditLegAddress
							bind:form
							readonly={tab === 'previousLeg' || disableEditing}
							parentRelId={addressParentRelId}
							relName={addressRelName}
							onCreate={createAddress}
						/>
					</div>
				</div>
			{/if}
			{#if pickup}
				<hr class="my-0">
			{/if}
		</div>
	</div>
</FieldSetter>
