import { keyBy } from '@/shared/util/key-by.js'

const createCell = (value, index, { style, format, formula }) => {
	// style = text | number
	// format = date (if style = text)
	if (value && style === 'text' && format === 'date') {
		if (value.includes('T')) return { v: value, t: 'd' }
		else {
			const d = new Date(value + 'T12:00:00.000Z')
			if (d.getTime() > 0) {
				return {
					t: 'd',
					// To show up correct in Excel as a date, it needs to be like this
					// v: '19-Feb-17',
					v: [
						d.toLocaleDateString(undefined, { day: 'numeric' }),
						d.toLocaleDateString(undefined, { month: 'short' }),
						d.toLocaleDateString(undefined, { year: '2-digit' }),
					].join('-'),
				}
			} else return value
		}
	}
	if (formula) return { v: value, f: formula(index) }
	return value
}

export const tableizeReportData = ({ body, sort, params, columns, orderedColumns }) => {
	const includedById = keyBy(body?.included || [], 'id')
	const negative = sort?.[0] === '-'
	const columnSortKey = sort?.replace(/^-/, '')
	const data = body?.data?.map((row) => {
		const r = {
			id: row.id,
			columns: {},
		}
		for (const columnKey of orderedColumns.filter(name => columns[name])) r.columns[columnKey] = {
			value: columns[columnKey].value?.(row, { params, includedById }),
			sortKey: columnKey,
			href: columns[columnKey].href?.(row, { params, includedById }),
			component: columns[columnKey].component,
		}
		return r
	})
	if (!columns[columnSortKey]) console.error('Missing column definition for sort key!')
	const sortedRows = sort && columns[columnSortKey]
		? data.sort((a, b) => {
			if (columns[columnSortKey]?.style === 'number') return negative
				? ((a.columns[columnSortKey]?.value || 0) - (b.columns[columnSortKey]?.value || 0))
				: ((b.columns[columnSortKey]?.value || 0) - (a.columns[columnSortKey]?.value || 0))
			else return negative
				? (a.columns[columnSortKey]?.value || '')?.toString()?.localeCompare(b.columns[columnSortKey]?.value || '')
				: (b.columns[columnSortKey]?.value || '')?.toString()?.localeCompare(a.columns[columnSortKey]?.value || '')
		})
		: data

	let index = 0
	for (const row of sortedRows) {
		for (const columnKey of orderedColumns.filter(name => columns[name])) {
			row.columns[columnKey].cell = createCell(row.columns[columnKey].value, index, columns[columnKey])
		}
		index++
	}

	return sortedRows
}
