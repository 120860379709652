import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	user: false,
}

export const type = 'password-reset-request'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-expirable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				password: {
					description: 'The hashed secret for this request, which will be emailed to the users known email address.',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'User that the password reset request is being sent to.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
