const ONLY_NUMBERS = /^\d+$/

const isPhoneNumber = string => {
	string = string && string.replaceAll(/\D/g, '')
	return string && (string.length === 7 || string.length === 10 || string.length === 11) && ONLY_NUMBERS.test(string)
}

export const makeHighlightedText = (highlight, text) => {
	let regex
	const onlyNumbersString = text && text.replaceAll?.(/\D/g, '').trim()
	if (highlight && onlyNumbersString && isPhoneNumber(highlight) && isPhoneNumber(onlyNumbersString)) {
		regex = new RegExp(`^(.*)(${onlyNumbersString.split('').map(s => `${s}-?`).join('')})(.*)$`, 'i')
	} else if (highlight) {
		regex = new RegExp(`^(.*)(${highlight})(.*)$`, 'i')
	}
	const parts = regex && regex.exec(text)
	if (parts?.length === 4) return { html: `${parts[1]}<span class="highlighted">${parts[2]}</span>${parts[3]}` }
	else return { text }
}
