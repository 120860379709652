<script context="module">
	import { readable } from 'svelte/store'
	const ApexCharts = readable(null, set => {
		import('apexcharts').then(i => {
			set(i.default)
		})
	})
</script>

<script>
	export let options
	let element
	let chart
	let _options
	options.subscribe(value => _options = value)
	$: {
		if (element && _options && $ApexCharts) {
			if (chart) {
				chart.updateOptions(_options, true)
			} else {
				chart = new $ApexCharts(element, _options)
				chart.render()
			}
		}
	}
</script>

<div bind:this={element}></div>
