<script>
	import DateString from '@/component/atom/DateString.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { router } from '@/service/router.js'

	export let asr
	export let email

	$: atts = email.data?.attributes || {}
	$: jobId = email.data?.relationships?.job?.data?.id
	$: opportunityId = email.data?.relationships?.opportunity?.data?.id
	$: millisecondsBetweenSendAndCreate = new Date(email.data.meta.created).getTime() - new Date(atts.timestamp).getTime()
</script>

<div class="card mb-3">
	<h4 class="card-header">
		<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
		<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
		{email.data.id}
	</h4>
	<div class="card-body">
		<dl>
			<dt>Job</dt>
			<dd>
				{#if jobId}
					<a href={router.makePath('app.systemSettings.jobs.byId.jobId', { jobId }, { inherit: false })}>
						{jobId}
					</a>
				{:else}
					<span class="text-muted">Still in process</span>
				{/if}
			</dd>
			<dt>OnTrack Order</dt>
			<dd>
				{#if opportunityId}
					<a href={router.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId })}>
						{opportunityId.replace(/P$/, '')}
					</a>
				{:else}
					<span class="text-muted">Not Set</span>
				{/if}
			</dd>
		</dl>
		<dl>
			<dt>Received</dt>
			<dd>
				<DateString date={email.data.meta.created} time />
				{#if millisecondsBetweenSendAndCreate}
					<br>
					<small class="text-muted">
						Received and processed in {Math.round(millisecondsBetweenSendAndCreate / 100) / 10} seconds
					</small>
				{/if}
			</dd>
		</dl>
		<dl>
			<dt>From</dt>
			<dd>
				<!-- TODO these are backwards, but they come this way from SES, so I don't know what's up... -->
				{atts.receiverEmail}
				{#if atts.senderMessageId}
					<br>
					<small class="text-muted">Message ID: {atts.senderMessageId}</small>
				{/if}
			</dd>
		</dl>
		<dl>
			<dt>To</dt>
			<dd>
				{atts.senderEmail}
			</dd>
		</dl>
		<dl>
			<dt>Subject</dt>
			<dd>
				{atts.subject}
			</dd>
		</dl>
		<dl>
			<dt>Body HTML</dt>
			<dd>
				{#if atts.html}
					<pre>{atts.html}</pre>
				{:else}
					<small class="text-muted">None Sent</small>
				{/if}
			</dd>
		</dl>
		<dl>
			<dt>Body Text</dt>
			<dd>
				{#if atts.text}
					<pre>{atts.text}</pre>
				{:else}
					<small class="text-muted">None Sent</small>
				{/if}
			</dd>
		</dl>
	</div>
</div>
