<script>
	import { createEventDispatcher, onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	export let form
	export let api
	export let legId
	export let companyData

	$: companyId = form.data[legId]?.relationships?.shipper?.data?.id
	$: carrierName = companyData?.byId?.[companyId]?.attributes?.name
	$: carrierCdId = companyData?.byId?.[companyId]?.attributes?.centralDispatchId

	let actualDropoff = ''
	let onlyOntrackSync
	onMount(() => {
		actualDropoff = (form.data[legId]?.attributes?.actualDropoff || new Date().toISOString()).split('T')[0]
	})

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'deliver',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
			data: { actualDropoff },
		}))
	}
</script>

<div style="max-width: 32em;">
	<div class="input-group input-group-sm mb-2">
		<span class="input-group-text">
			Actual Delivery
		</span>
		<input
			disabled={form.state === 'saving'}
			type="date"
			class="form-control"
			value={actualDropoff}
			on:input={event => { actualDropoff = event.target.value }}
		>
	</div>

	<p>
		Manually marking as delivered will
		{#if onlyOntrackSync}
			update the OnTrack record only. Central Dispatch will <strong>not</strong> be updated.
		{:else}
			send a notification email to
			<a href={router.makePath('app.companies.edit.companyId.overview', { companyId }, { inherit: false })}>{carrierName}</a>
			and update Central Dispatch.
		{/if}
	</p>

	<p>
		By indicating the order was delivered, you are concluding the transaction through Central Dispatch, so do not
		mark this order as delivered unless you can verify the delivery.
	</p>

	<button class="btn btn-sm btn-primary" disabled={!actualDropoff || form.state === 'saving'} on:click={postHandler}>
		<NamedIcon name="received" />
		Manually Mark as Delivered
		{#if onlyOntrackSync}
			<em>in OnTrack Only!</em>
		{/if}
	</button>

	<div class="form-check mt-3 mb-0">
		<input
			disabled={form.state === 'saving'}
			class="form-check-input"
			type="checkbox"
			id="leg-deliver-only-ontrack"
			checked={onlyOntrackSync}
			on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
		>
		<label for="leg-deliver-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
			Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
			and will <strong>not</strong> send a notification email.
		</label>
	</div>
</div>
