<script>
	import { createEventDispatcher, onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	export let form
	export let api
	export let legId
	export let companyData

	$: companyId = form.data[legId]?.relationships?.shipper?.data?.id
	$: carrierName = companyData?.byId?.[companyId]?.attributes?.name
	$: carrierCdId = companyData?.byId?.[companyId]?.attributes?.centralDispatchId

	let onlyOntrackSync

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'undeliver',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
		}))
	}
</script>

<div style="max-width: 32em;">
	<p>
		By taking this action, your order will be marked as "Dispatched" and the picked-up and delivered dates will be removed.
		{#if !onlyOntrackSync}
			A notification email will be sent to
			<a href={router.makePath('app.companies.edit.companyId.overview', { companyId }, { inherit: false })}>{carrierName}</a>
			and Central Dispatch will be updated.
		{/if}
	</p>

	<p>
		Please do not take this action unless you or the carrier accidentally marked the order as delivered.
	</p>

	<button class="btn btn-sm btn-primary" disabled={form.state === 'saving'} on:click={postHandler}>
		<NamedIcon name="undeliver" />
		Move Back to "Dispatched"
		{#if onlyOntrackSync}
			<em>in OnTrack Only!</em>
		{/if}
	</button>

	<div class="form-check mt-3 mb-0">
		<input
			disabled={form.state === 'saving'}
			class="form-check-input"
			type="checkbox"
			id="leg-undeliver-only-ontrack"
			checked={onlyOntrackSync}
			on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
		>
		<label for="leg-undeliver-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
			Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
			and will <strong>not</strong> send a notification email.
		</label>
	</div>
</div>
