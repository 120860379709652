import { makeRequestCache } from '@/lib/make-request-cache.js'
import { router } from '@/service/router.js'
import { get, post, put } from '@/lib/json-fetch.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const listCompanies = async params => get('/api/v1/companies' + objectToJsonApiQuery(params))

/**
 *
 * @type {(function(): Promise<*>)|*}
 */
export const getCompanyById = makeRequestCache(
	router,
	async ({ id }) => get(`/api/v1/companies/${id}`)
		.then(response => ({
			data: response.body.data,
			included: response.body.included,
		})),
)

export const createCompany = async ({ data, included }) => post('/api/v1/companies', { body: { data, included } })

export const updateCompany = async ({ data, included }) => put(`/api/v1/companies/${data.id}`, { body: { data, included } })
