<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import UserSession from './UserSession.svelte'
	import { router } from '@/service/router.js'

	export let row
	export let includedById

	$: user = includedById?.[row.attributes.userId]
	$: isJob = row.attributes.jobId
	$: iconName = isJob ? 'job' : 'system'
	$: systemName = isJob ? `Job ${row.attributes.jobId}` : row.attributes.systemId
</script>

<style>
	.text-subtle { color: #c7d1d7; }
</style>

{#if row.attributes.userId && !user}
	<span class="text-subtle">
		N/A
	</span>
{:else if user}
	<a href={router.makePath('app.users.edit.userId', { userId: user.id }, { inherit: null })}>
		{user.attributes.name}
	</a>
	<UserSession history={row} />
{/if}

{#if systemName}
	<div class:small={user}>
		<span class="text-subtle">
			<NamedIcon name={iconName} />
		</span>
		{#if isJob}
			<a href={router.makePath('app.systemSettings.jobs.byId.jobId', { jobId: row.attributes.jobId }, { inherit: null })}>{systemName}</a>
		{:else}
			&nbsp;&nbsp;{systemName}
		{/if}
	</div>
{/if}
