<script>
	let className = ''
	export { className as class }
	export let system
	export let config = {}
	export let form
	export let name
	$: opts = Object.assign({ layout: 'basic' }, config)
</script>

<div class={className}>
	<img class="d-block mx-auto mb-4" src={$system.CWD + 'vendor/falcon/assets/img/illustrations/envelope.png'} alt="Email" width="70">
	<h4 class={config.layout === 'basic' ? '' : 'mb-2'}>
		Please check your email!
	</h4>
	<p>
		An email has been sent to
		<strong>{form[name].email || 'your email'}</strong>.
		Please click on the
		{#if config.layout === 'card'}
			<br class="d-none d-sm-block d-md-none">
		{/if}
		included link to reset your password.
	</p>
	<a href={$system.paths['authentication/simple/login']} class="btn btn-primary btn-sm mt-3">
		<span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4 down-1"></span>
		Return to login
	</a>
</div>
