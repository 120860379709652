import { currentUser } from '@/service/store/current-user.js'
import { get, patch, post } from '@/lib/json-fetch.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

const setUser = response => {
	currentUser.update(() => response.body.data)
	return response.body.data
}

export const login = async ({ email, password }) => post('/api/v1/sessions', {
	body: { email, password },
})

export const logout = async () => get('/api/v1/logout')
	.then(response => {
		currentUser.update(() => false)
		return response
	})

export const createUser = async ({ name, email, password }) => post('/api/v1/user', {
	body: { name, email, password },
}).then(setUser)

export const forgotPassword = async ({ email }) => post('/api/v1/forgotPassword', {
	body: { email },
})

export const forgotPasswordFinalize = async ({ password, token }) => patch('/api/v1/forgotPassword', {
	body: { password, token },
})

export const listAllUsers = async params => get('/api/v1/users' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
