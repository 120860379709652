import { getCurrentUser } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import template from './App.svelte'

export default {
	template,
	defaultChild: 'dashboard',
	resolve: async () => {
		return {
			user: await getCurrentUser({ get }),
		}
	},
}
