import { shippingMethod } from '../value/shipping-method.js'

const supportedShippingMethods = Object
	.keys(shippingMethod)
	.map(method => `"${method}"`)
	.join(', ')

const readableSupportedShippingMethods = Object
	.keys(shippingMethod)
	.map(method => `"${method}": "${shippingMethod[method]}"`)
	.join('; ')

export default {
	type: 'object',
	properties: {
		firstName: {
			type: 'string',
			description: 'The formal name to address the primary contact, e.g. "John", "Angie".',
		},
		lastName: {
			type: 'string',
			description: 'The remaining name portion of the primary contact, e.g. "Robinson".',
		},
		email: {
			type: 'string',
			description: 'A valid email address for the primary contact for this opportunity. May be used to send Auto-Quote emails on opportunity creation.',
		},
		phone: {
			type: 'string',
			description: 'A valid phone number of the primary contact for this opportunity.',
		},
		customerNote: {
			type: 'string',
			description: 'Optional notes to provide additional details about the request.',
		},
		originCity: {
			type: 'string',
			description: 'The city name that the vehicles will be picked up.',
		},
		originZip: {
			type: 'string',
			description: 'The six-digit USPS ZIP Code™ where the vehicles will be picked up. Codes outside the United States are not accepted.',
		},
		destinationCity: {
			type: 'string',
			description: 'The city name that the vehicles will be delivered.',
		},
		destinationZip: {
			type: 'string',
			description: 'The six-digit USPS ZIP Code™ where the vehicles will be delivered. Codes outside the United States are not accepted.',
		},
		autoQuoteCampaignId: {
			type: 'string',
			description: 'If this is set to a valid Auto-Quote Campaign ID or code, a tariff will be generated if possible and an email will be sent to the provided email, if the campaign is configured to do so.',
		},
		shipDate: {
			type: 'string',
			description: 'The first available date to pick up the vehicle(s). Must be in the format YYYY-MM-DD.',
		},
		shipVia: {
			type: 'string',
			description: `The desired shipping method. Must be one of: ${supportedShippingMethods}. If not set, the opportunity will use "open". (${readableSupportedShippingMethods})`,
		},
		referrerCode: {
			type: 'string',
			description: 'If set to a valid OnTrack referrer code, this will link the opportunity to that referrer.',
		},
		vehicles: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					year: {
						type: 'string',
						description: 'Should be in the format YYYY, e.g. "1969". Other formats may require manual processing. (Required for Auto-Quote functionality.)',
					},
					make: {
						type: 'string',
						description: 'Vehicle make, e.g. "Chevrolet". Some aliases are supported, e.g. "Chevy" and "VW". (Required for Auto-Quote functionality.)',
					},
					model: {
						type: 'string',
						description: 'Vehicle model, e.g. "Corvette". (Required for Auto-Quote functionality.)',
					},
					inoperable: {
						type: 'string',
						description: 'Set to the string "true" or the boolean `true` if the vehicle is known to be inoperable.',
					},
				},
			},
		},
	},
}
