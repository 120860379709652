import template from './EditNote.svelte'
import { listUsers } from '@/lib/controller/user.js'
import { getNoteById } from '@/lib/controller/note.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Edit Note',
	},
	template,
	resolve: async (_, { noteId }) => {
		const [ note, { data: users } ] = await Promise.all([
			getNoteById({ get }, noteId, { include: 'users' }),
			listUsers({ get }, { filter: { 'attributes.active': 'true' } }),
		])
		const assignedId = note?.data?.relationships?.assigned?.data?.id
		if (assignedId && !users.find(u => u.id === assignedId)) {
			const user = note.included.find(u => u.id === assignedId)
			if (user) users.push(user)
		}
		return {
			form: load(note, 0),
			users,
		}
	},
}
