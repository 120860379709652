import { dateType } from '../value/date-type.js'
import { legEventNames } from '../value/leg-event-names.js'
import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'
import { jobStatuses } from '../value/job-statuses.js'
import { paymentBusinessDays } from '../value/payment-business-days.js'

export const $selfFragment = {
	pickupTerminal: false,
	pickupAddress: true,
	pickupContact: true,
	dropoffTerminal: false,
	dropoffAddress: true,
	dropoffContact: true,
	pickup: true,
	dropoff: true,
	shipper: false,
	driver: true,
	vehicleDetails: true,
}

export const type = 'leg'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for this leg.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			description: 'Travel segment of the vehicle transport route.',
			type: 'object',
			additionalProperties: false,
			properties: {
				miles: {
					description: 'The number of miles for this legs pickup/delivery route.',
					type: 'number',
				},
				centralDispatchAdditionalTerms: {
					description: 'The full text used in the "Additional Terms" field when posting to CD.',
					type: 'string',
				},
				centralDispatchSpecialInstructions: {
					description: 'The full text used in the "Special Instructions" field when posting to CD.',
					type: 'string',
				},
				centralDispatchListingId: {
					description: 'Identifier of the current listing on Central Dispatch, once posted.',
					type: 'string',
				},
				centralDispatchDsid: {
					description: 'Identifier of the current listing on Central Dispatch, once signed or otherwise moved on from listing.',
					type: 'string',
				},
				lastReminderSent: {
					description: 'The date of the most recent reminder sent via Central Dispatch to the carrier.',
					type: 'string',
					format: 'date-time',
				},
				carrierPay: {
					description: 'Amount due to the carrier.',
					type: 'number',
					format: 'double',
				},
				businessDays: {
					description: 'Days after receipt of BOL that a check will be sent.',
					type: 'number',
					default: 10,
					enum: Object.keys(paymentBusinessDays).map(days => parseInt(days, 10)),
				},
				pickupTerminalFee: {
					description: 'If there is an additional fee required to be paid to the terminal on pickup.',
					type: 'number',
					format: 'double',
				},
				dropoffTerminalFee: {
					description: 'If there is an additional fee required to be paid to the terminal on delivery.',
					type: 'number',
					format: 'double',
				},
				status: {
					description: 'The current status, which is the last completed event, e.g. `post` if posted but not yet assigned.',
					type: 'string',
					enum: Object.keys(legEventNames),
				},
				dates: {
					description: `
						The most recent date+time values from the "events" property. Made available as aliases for
						convenience. If an event moves "backwards" the "forward" date value will be removed as well,
						e.g. if a leg is posted and then unposted, the \`post\` property will be removed.
					`,
					type: 'object',
					additionalProperties: false,
					properties: Object
						.keys(legEventNames)
						.reduce((map, name) => {
							map[name] = {
								description: `The most recent date+time when the "${name}" action event was marked as completed.`,
								type: 'string',
								format: 'date-time',
							}
							return map
						}, {}),
				},
				events: {
					description: 'Record of all events for this leg, ordered by event start. This is a system-managed property.',
					type: 'array',
					items: {
						type: 'object',
						additionalProperties: false,
						properties: {
							name: {
								description: 'The name of the event.',
								type: 'string',
								enum: Object.keys(legEventNames),
							},
							started: {
								description: 'The date+time that this event was started.',
								type: 'string',
								format: 'date-time',
							},
							completed: {
								description: 'The date+time that this event completed.',
								type: 'string',
								format: 'date-time',
							},
							completionStatus: {
								description: 'The completion status of the job, e.g. "COMPLETED" or "CANCELLED" etc.',
								type: 'string',
								enum: Object.keys(jobStatuses),
							},
							jobId: {
								description: 'The identifier of the `job` for this event, for CD-related events.',
								type: 'string',
							},
							hasJobErrors: {
								description: 'Set to true if an error was generated by the job.',
								type: 'boolean',
							},
						},
					},
				},
				firstAvailablePickup: {
					description: 'The posting date for this leg. Should correspond to the `pickupDate` property.',
					type: 'string',
					format: 'date',
				},
				actualPickup: {
					description: 'The actual pickup date for this leg, if known. Overrides the `dates.receive` value.',
					type: 'string',
					format: 'date',
				},
				actualDropoff: {
					description: 'The actual delivery date for this leg, if known. Overrides the `dates.deliver` value.',
					type: 'string',
					format: 'date',
				},
				dropoffType: {
					description: 'The location of this legs pickup. Initial default is "custom".',
					type: 'string',
					enum: [
						'terminal',
						'custom',
						'customer',
					],
				},
				dropoffDate: {
					description: 'The date which the carrier agreed to drop off the vehicle(s) for this leg.',
					type: 'string',
					format: 'date',
				},
				dropoffDateType: {
					description: 'The range of acceptable for the dropoff date. Available values are: ' + Object.keys(dateType),
					type: 'string',
					enum: Object.keys(dateType),
					default: 'exact',
				},
				pickupType: {
					description: 'The location of this legs pickup. Initial default is "custom".',
					type: 'string',
					enum: [
						'terminal',
						'previousLeg',
						'custom',
						'customer',
					],
				},
				pickupDate: {
					description: 'The date which the carrier agreed to pick up the vehicle(s) for this leg.',
					type: 'string',
					format: 'date',
				},
				pickupDateType: {
					description: 'The range of acceptable for the pickup date. Available values are: ' + Object.keys(dateType),
					type: 'string',
					enum: Object.keys(dateType),
					default: 'exact',
				},
				driverFirstName: {
					description: 'First name of driver for this leg, if known.',
					type: 'string',
				},
				driverLastName: {
					description: 'Last name of driver for this leg, if known.',
					type: 'string',
				},
				driverPhone: {
					description: 'Phone number of driver for this leg, if known.',
					type: 'string',
				},
				// TODO is this a thing on CD? if not, it can maybe go away?
				additionalDateRestriction: {
					description: 'If dispatch places additional shipping instructions on the delivery.',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				pickupTerminal: {
					description: 'Reference to the company with terminal services where the vehicle will be picked up.',
					...jsonApiRelationshipSingular('company'),
				},
				pickupContact: {
					description: 'Reference to the pickup contact for the leg of this order.',
					...jsonApiRelationshipSingular('contact'),
				},
				pickupAddress: {
					description: 'Reference to the pickup location information for the leg of this order.',
					...jsonApiRelationshipSingular('address'),
				},
				dropoffTerminal: {
					description: 'Reference to the company with terminal services where the vehicle will be dropped off.',
					...jsonApiRelationshipSingular('company'),
				},
				dropoffContact: {
					description: 'Reference to the dropoff contact for the leg of this order.',
					...jsonApiRelationshipSingular('contact'),
				},
				dropoffAddress: {
					description: 'Reference to the dropoff location information for the leg of this order.',
					...jsonApiRelationshipSingular('address'),
				},
				shipper: {
					description: 'The shipping company for this leg. Set by an internal service connected to Central Dispatch.',
					...jsonApiRelationshipSingular('company'),
				},
			},
		},
	},
}
