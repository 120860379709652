import { differenceInCalendarDays } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { opportunityStatusLabel } from './opportunity-status-label.js'

const FIX_ID = /P$/

const formattedDateString = (string, format) => string?.includes('T')
	? formatInTimeZone(new Date(string), Intl.DateTimeFormat().resolvedOptions().timeZone, format)
	: (string || '')

const dateString = string => formattedDateString(string, 'yyyy-MM-dd')
const dateTimeString = string => formattedDateString(string, 'yyyy-MM-dd HH:mm')

const mapLegProps = list => {
	if (list.length === 1) return list[0]
	else return list
		.map((string, index) => string ? `${string} (Leg ${index + 1})` : '')
		.filter(Boolean)
		.join('; ')
}

const legsRequired = key => {
	console.error(`The column "${key}" requires the \`include=legs\` query param set.`)
	return ''
}

const getStackedLocation = prefix => row => {
	const state = row[`${prefix}State`]
	const zip = row[`${prefix}Zip`]
	let out = row[`${prefix}City`]
	if (out && (state || zip)) out += '\n'
	if (state) out += state
	if (zip) out += (' ' + zip)
	return out
}

export const componentsMap = {
	LegNumberedNewline: 'LegNumberedNewline',
	LegStatuses: 'LegStatuses',
	VehicleTableCell: 'VehicleTableCell',
}

export const opportunityColumnOrderDefault = [
	// TODO "payment info C/C" ???
	// TODO full carrier details for all legs
	'id',
	'assigned',
	'referrer',
	'autoQuoteCampaign',
	'status',
	'created',
	'firstAvailable',
	'quoted',
	'originalCarrierPay',
	'originalBrokerFee',
	'originalTariff',
	'tariffAdjustment',
	'actualCarrierPay',
	'carrierPayDiff',
	'terminalFees',
	'ordered',
	'cancelled',
	'held',
	'dispatched',
	'pickupContract',
	'pickupActual',
	'dropoffContract',
	'dropoffActual',
	'miles',
	// TODO all possible dollar values
	// TODO show the tariff, even though calculated it's still handy
	// 'dispatchedPickup', //fully dispatched, not just assigned
	// 'actualPickup',
	// 'dispatchedDropoff',
	// 'actualDeliver',
	// 'tariff',
	// 'dispatchedCarrierPay',
	// 'terms',
	// 'vehicleCount',
	// 'vehicles', vehicles.map(v => `${v.year} ${v.make} ${v.model}`).join(', ') is okay
	// 'user',
]

export const opportunityColumns = ({ asr }) => ({
	id: {
		label: 'ID',
		style: 'text',
		href: (row) => asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId: row.id }, { inherit: false }),
		value: (row) => row.id.replace(FIX_ID, ''),
		description: 'The unique identifier of the opportunity.',
	},
	status: {
		label: 'Status',
		style: 'text',
		value: (row) => opportunityStatusLabel[row.status] || row.status || 'N/A',
		description: 'The overall opportunity status, e.g. "lead", "quote", "order", or "delivered".',
	},
	created: {
		label: 'Created',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.created),
		description: 'The date the opportunity was created, also called the "Lead Date".',
	},
	quoted: {
		label: 'Quoted',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.quoted),
		description: 'The date the opportunity first became quoted.',
	},
	autoQuoteCampaign: {
		label: 'Auto-Quote Campaign',
		style: 'text',
		description: 'The Auto-Quote campaign that generated this opportunity.',
		href: (row) => row.autoQuoteCampaignId && asr.makePath('app.systemSettings.autoQuoteCampaigns.edit.autoQuoteCampaignId', { autoQuoteCampaignId: row.autoQuoteCampaignId }, { inherit: false }),
		value: (row) => row.autoQuoteCampaignCode || '',
	},
	createdTime: {
		label: 'Created (Date+Time)',
		style: 'text',
		format: 'date',
		value: (row) => dateTimeString(row.created),
		description: 'The date and time that the opportunity was created, also called the "Lead Date".',
	},
	quotedTime: {
		label: 'Quoted (Date+Time)',
		style: 'text',
		format: 'date',
		value: (row) => dateTimeString(row.quoted),
		description: 'The date and time that the opportunity first became quoted.',
	},
	ordered: {
		label: 'Ordered',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.ordered),
		description: 'The date the opportunity first became ordered.',
	},
	cancelled: {
		label: 'Cancelled',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.cancelled),
		description: 'The date that the opportunity was marked as cancelled.',
	},
	held: {
		label: 'Held',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.held),
		description: 'The date that the opportunity was marked as On-Hold.',
	},
	dispatched: {
		label: 'Dispatched',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.dispatched),
		description: 'The date that the first leg has been fully dispatched.',
	},
	dispatchedStacked: {
		label: 'Dispatched',
		style: 'text',
		description: 'The most recent date each leg was fully dispatched.',
		value: (row) => {
			if (!row.legs) return legsRequired('dispatchedStacked')
			// TODO what about UTC offset?
			return mapLegProps(row.legs.map(leg => dateString(leg.dispatched)))
		},
		component: componentsMap.LegNumberedNewline,
	},
	originalCarrierPay: {
		label: 'Original CP',
		style: 'number',
		value: (row) => row.originalCarrierPay || 0,
		description: '(Original Carrier Pay) The carrier pay estimated by sales for an opportunity, before it becomes an order.',
	},
	originalBrokerFee: {
		label: 'Original BF',
		style: 'number',
		value: (row) => row.originalBrokerFee || 0,
		description: '(Original Broker Fee) The broker fee set by sales before the opportunity becomes an order.',
	},
	tariffAdjustment: {
		label: 'BA',
		style: 'number',
		value: (row) => row.tariffAdjustment || 0,
		description: '(Billing Adjustment) Any adjustment to the customer quote after becoming an order.',
	},
	originalTariff: {
		label: 'OT',
		style: 'number',
		value: (row) => (
			(row.originalCarrierPay || 0)
			+ (row.originalBrokerFee || 0)
			+ (row.tariffAdjustment || 0)
		),
		description: '(Original Tariff) The original carrier pay plus the original broker fee plus any tariff adjustments.',
	},
	actualCarrierPay: {
		label: 'Actual CP',
		style: 'number',
		value: (row) => row.actualCarrierPay || 0,
		description: '(Actual Carrier Pay) The sum of the carrier pay of all legs.',
	},
	carrierPayDiff: {
		label: 'CP Diff',
		style: 'number',
		value: (row) => (row.originalCarrierPay || 0) - (row.actualCarrierPay || 0),
		description: '(Carrier Pay Diff) The difference between the original and actual carrier pays.',
	},
	terminalFees: {
		label: 'Terminal',
		style: 'number',
		value: (row) => row.terminalFees || 0,
		description: 'The sum of the pickup or delivery terminal fees of all legs.',
	},

	// 'pickupFirstAvailable'
	// 'pickupDateContract'
	// 'pickupDateRange'
	// 'pickupDateActual'
	// 'dropoffDateContract'
	// 'dropoffDateRange'
	// 'dropoffDateActual'
	pickupContract: {
		label: 'Pickup (Contract)',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.pickupDateContract),
		description: 'The contractual pickup date of the first leg of the opportunity.',
	},
	pickupContractStacked: {
		label: 'Pickup (Contract)',
		style: 'text',
		format: 'date',
		description: 'The contractual pickup date of all opportunity legs.',
		value: (row) => {
			if (!row.legs) return legsRequired('pickupContractStacked')
			return mapLegProps(row.legs.map(leg => leg.pickupDateContract || ''))
		},
		component: componentsMap.LegNumberedNewline,
	},
	pickupActual: {
		label: 'Pickup (Actual)',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.pickupDateActual),
		description: 'The contractual pickup date of the first leg of the opportunity.',
	},
	pickupActualStacked: {
		label: 'Pickup (Actual)',
		style: 'text',
		format: 'date',
		description: 'The contractual pickup date of all opportunity legs.',
		value: (row) => {
			if (!row.legs) return legsRequired('pickupActualStacked')
			return mapLegProps(row.legs.map(leg => dateString(leg.pickupDateActual)))
		},
		component: componentsMap.LegNumberedNewline,
	},
	dropoffContract: {
		label: 'Deliver (Contract)',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.dropoffDateContract),
		description: 'The contractual delivery date of the last leg of the opportunity.',
	},
	dropoffContractStacked: {
		label: 'Deliver (Contract)',
		style: 'text',
		format: 'date',
		description: 'The contractual delivery date of all opportunity legs.',
		value: (row) => {
			if (!row.legs) return legsRequired('dropoffContractStacked')
			return mapLegProps(row.legs.map(leg => leg.dropoffDateContract || ''))
		},
		component: componentsMap.LegNumberedNewline,
	},
	dropoffActual: {
		label: 'Deliver (Actual)',
		style: 'text',
		format: 'date',
		value: (row) => dateString(row.dropoffDateActual),
		description: 'The contractual delivery date of the last leg of the opportunity.',
	},
	dropoffActualStacked: {
		label: 'Deliver (Actual)',
		style: 'text',
		format: 'date',
		description: 'The contractual delivery date of all opportunity legs.',
		value: (row) => {
			if (!row.legs) return legsRequired('dropoffActualStacked')
			return mapLegProps(row.legs.map(leg => dateString(leg.dropoffDateActual)))
		},
		component: componentsMap.LegNumberedNewline,
	},
	leadToPickupDays: {
		label: 'Lead to Pickup',
		style: 'number',
		sumRows: false,
		description: 'The calculated number of days between lead creation and pickup, e.g. Feb 3 to Feb 5 would be 2 days.',
		value: (row) => row.pickupDateContract
			&& row.created
			&& (differenceInCalendarDays(new Date(`${row.pickupDateContract}T12:00`), new Date(row.created))),
		// 2 because 1 for zero-vs-one-index and 1 for top description row
		formula: index => `A${index + 2}-B${index + 2}`,
		// TODO this is *really brittle* aka if the column order ever changes this WILL break
	},

	legStatusStacked: {
		label: 'Status',
		style: 'text',
		description: 'Status of each leg.',
		value: (row) => {
			if (!row.legs) return legsRequired('legStatusStacked')
			return mapLegProps(row.legs.map(leg => leg.status))
		},
		component: componentsMap.LegStatuses,
	},
	carrierDetailsStackedSimple: {
		label: 'Carrier',
		style: 'text',
		description: 'Simple per-leg details about carrier information.',
		value: (row) => {
			if (!row.legs) return legsRequired('carrierDetailsStackedSimple')
			return mapLegProps(row.legs.map(leg => leg.shipperCompany))
		},
		component: componentsMap.LegNumberedNewline,
	},

	miles: {
		label: 'Miles',
		style: 'number',
		description: 'The total miles for the opportunity.',
		value: (row) => row.miles || 0,
	},
	legs: {
		label: 'Legs',
		style: 'number',
		description: 'The number of legs.',
		value: (row) => row.legCount || 0,
	},
	assigned: {
		label: 'Assigned',
		style: 'text',
		description: 'The OnTrack user assigned to this opportunity.',
		href: (row) => row.assignedId && asr.makePath('app.users.edit.userId', { userId: row.assignedId }, { inherit: false }),
		value: (row) => row.assignedName || row.assignedId || '',
	},
	referrer: {
		label: 'Referrer',
		style: 'text',
		description: 'The referral source, aka where did this opportunity come from.',
		href: (row) => row.referrerId && asr.makePath('app.referrers.edit.referrerId', { referrerId: row.referrerId }, { inherit: false }),
		value: (row) => row.referrerName || row.referrerId || '',
	},
	referrerTag: {
		label: 'Referrer Tag',
		style: 'text',
		value: (row, { includedById }) => includedById?.[row.referrerId]?.attributes?.tag || '',
	},
	firstAvailable: {
		label: '1st Avail.',
		style: 'text',
		format: 'date',
		description: 'First available ship date.',
		value: (row) => row.firstAvailable || '',
	},
	customerStacked: {
		label: 'Customer',
		style: 'text',
		stacked: true,
		description: 'Customer name, phone numbers, and email addresses.',
		value: (row) => [
			row.customerName,
			...(row.customerPhones || '').split(';').map(s => s.trim()),
			...(row.customerEmails || '').split(';').map(s => s.trim()),
		].filter(Boolean).join('\n'),
	},
	customerName: {
		label: 'Customer',
		style: 'text',
		stacked: true,
		description: 'Customer name only.',
		value: (row) => row.customerName || '',
	},
	customerPhones: {
		label: 'Phone',
		style: 'text',
		stacked: true,
		description: 'Customer phone numbers.',
		value: (row) => (row.customerPhones || '').split(';').map(s => s.trim()).join('\n'),
	},
	customerEmails: {
		label: 'Email',
		style: 'text',
		stacked: true,
		description: 'Customer email addresses.',
		value: (row) => (row.customerEmails || '').split(';').map(s => s.trim()).join('\n'),
	},
	vehiclesStacked: {
		label: 'Vehicles',
		style: 'text',
		// stacked: true,
		description: 'Vehicles for the opportunity (shows as stacked rows).',
		value: (row) => row.vehicles || '',
		component: componentsMap.VehicleTableCell,
	},
	vehiclesFlat: {
		label: 'Vehicles',
		style: 'text',
		description: 'Vehicles for the opportunity (separated by semi-colon).',
		value: (row) => row.vehicles || '',
	},
	pickupStacked: {
		label: 'Pickup',
		style: 'text',
		stacked: true,
		description: 'First leg pickup location information, vertically stacked.',
		value: getStackedLocation('pickup'),
	},
	deliveryStacked: {
		label: 'Deliver',
		style: 'text',
		stacked: true,
		description: 'Last leg delivery location information, vertically stacked.',
		value: getStackedLocation('dropoff'),
	},
	notes: {
		label: 'Notes',
		style: 'text',
		description: 'Customer notes.',
		// css: 'max-width: 12ch;',
		value: (row) => row.notes || '',
	},
	bats: {
		label: 'BATS',
		style: 'text',
		value: (row) => row.batsId || '',
		description: 'The legacy BATS identifier, if set.',
	},
	// firstAvailable: {
	// 	label: '1st Avail.',
	// },
	// dispatchedPickup: {
	// 	label: 'Contract Pickup', // first leg contractual, independent of date type
	// },
	// actualPickup: {
	// 	label: 'Actual Pickup', // first leg actual
	// },
	// dispatchedDropoff: {
	// 	label: 'Contract Deliver', // last leg contractual, independent of date type
	// },
	// actualDeliver: {
	// 	label: 'Actual Deliver', // last leg actual
	// },
	// tariff: {
	// 	label: 'Total Tariff', // sum from legs
	// },
	// dispatchedCarrierPay: {
	// 	label: 'Dispatched CP',
	// },
	// terms: {
	// 	label: 'Terms',
	// },
	// vehicleCount: {
	// 	label: 'Vehicle #',
	// },
	// vehicles: {
	// 	label: 'Vehicles', // comma separated, year+make+model
	// },
	// user: {
	// 	label: 'TBA',
	// },
})
