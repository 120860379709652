import template from './EditEmailTemplate.svelte'
import { getEmailTemplateById } from '@/lib/controller/email-template.js'
import { listSignableTemplates } from '@/lib/controller/signable-template.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { emailTemplateId }) => {
		const [ emailTemplate, signableTemplates ] = await Promise.all([
			getEmailTemplateById({ get }, emailTemplateId),
			listSignableTemplates({ get }, {}),
		])
		return {
			form: load(emailTemplate, 0),
			signableTemplatesOptions: signableTemplates.data.reduce((map, { id, attributes: { name } }) => {
				map[id] = name
				return map
			}, {}),
		}
	},
}
