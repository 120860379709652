<script>
	export let system
	let className = ''
	export let icon
	export let title
	export let width = 58
	export { className as class }
</script>

<style>
	img {
		height: 120px;
		width: 120px;
	}
</style>

<div class="text-center">
	<img src="{$system.CWD}{icon}" alt="" {width} class="me-2">
	<br>
	<span class="font-sans-serif {className}">
		{title}
	</span>
</div>
