<script>
	import ReferrersNavbar from './_ReferrersNavbar.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import ReferrerForm from '@/component/form/ReferrerForm.svelte'
	import { createReferrer } from '@/lib/controller/referrer.js'
	import { router } from '@/service/router.js'

	export let asr
	export let api
	export let form

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createReferrer(api, form, response => router.go('app.referrers.edit.referrerId', { referrerId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<ReferrersNavbar {asr} label="Create New" createPage />

<ReferrerForm bind:form />

<FormErrorsOther {form} />

<FormCommitButton label="Create Referrer Source" {form} on:click={create} />
