<script>
	import Table from '@/component/table/Company.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import TerminalSearch from '@/component/molecule/TerminalSearch.svelte'
	import { carriers, shippers, terminals } from '@/lib/high-level-links.js'
	import Icon from 'svelte-awesome'
	import { faPlus } from '@fortawesome/free-solid-svg-icons'
	import { router } from '@/service/router.js'

	export let asr
	export let clearable
	export let data
	export let links
	export let included
	export let errors

	export let params
	export let loading
	export let request

	let searchType = params.searchType || 'name'
	let searchString = params.search || ''
	const reloadForFilter = () => router.go(null, { searchType, search: searchString }, { inherit: true })
	const reloadForCheckbox = key => ({ target: { checked } }) => router.go(null, { [key]: !!checked }, { inherit: true })

	const navTabs = [
		{
			service: 'carrier',
			title: 'Carrier',
			icon: carriers.icon,
		},
		{
			service: 'shipper',
			title: 'Shipper',
			icon: shippers.icon,
		},
		{
			service: 'terminal',
			title: 'Terminal',
			icon: terminals.icon,
		},
	]

	$: columnNames = [
		'services',
		'name',
		'contact',
		'hours',
		'address',
		params.inactive && 'inactive',
		params.blocked && 'blocked',
	].filter(Boolean)
</script>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{request}
	{data}
	{links}
	{errors}
	{included}
	{columnNames}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>Services</h4>
		</div>
		<div class="col-auto">
			<ul class="nav nav-tabs" role="tablist">
				<li class="nav-item">
					<a
						class="nav-link"
						class:active={!params?.filter?.service}
						href={asr.makePath('app.companies.list', undefined, { inherit: false })}
						role="tab"
					>
						All Companies
					</a>
				</li>
				{#each navTabs as { service, title, icon }}
					<li class="nav-item">
						<a
							class="nav-link"
							class:active={params?.filter?.service === service}
							href={asr.makePath('app.companies.list', { service }, { inherit: false })}
							role="tab"
						>
							<span class="fa fa-{icon}"></span>
							{title}
						</a>
					</li>
				{/each}
			</ul>
		</div>
		<div class="col">
			&nbsp;
		</div>
		<div class="col-auto text-end mt-2">
			<a href={asr.makePath('app.companies.createFromUrl', null, { inherit: false })} class="btn btn-primary btn-sm text-light">
				<Icon data={faPlus} scale="1" class="ms-2 me-2" />
				Add From URL
			</a>
		</div>
		<div class="col-auto text-end mt-2">
			<a href={asr.makePath('app.companies.create', null, { inherit: false })} class="btn btn-primary btn-sm text-light">
				<Icon data={faPlus} scale="1" class="ms-2 me-2" />
				Add Company
			</a>
		</div>
	</div>
	<div class="row">
		<div class="col-auto mt-2">
			<TerminalSearch
				searchButton
				bind:searchType
				bind:searchString
				on:click={reloadForFilter}
			/>
		</div>
		{#if searchString && clearable}
			<div class="col-auto mt-2">
				<button class="btn btn-outline-primary btn-sm" on:click={() => { searchString = ''; reloadForFilter() }}>
					Clear
				</button>
			</div>
		{/if}
		<div class="col-auto mt-2">
			<div class="form-check form-check-inline mb-0">
				<input class="form-check-input" type="checkbox" id="include-inactive" checked={params.inactive} on:click={reloadForCheckbox('inactive')}>
				<label class="form-check-label mb-0" for="include-inactive">Include Inactive</label>
			</div>
			<div class="form-check form-check-inline mb-0">
				<input class="form-check-input" type="checkbox" id="include-blocked" checked={params.blocked} on:click={reloadForCheckbox('blocked')}>
				<label class="form-check-label mb-0" for="include-blocked">Include Blocked</label>
			</div>
		</div>
	</div>
</TableCardWrapper>
