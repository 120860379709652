import { getCurrentUser } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'

export const redirectIfLackingPermission = async (...permissions) => {
	const currentUser = await getCurrentUser({ get })
	if (!permissions.every(key => currentUser?.attributes?.permissions?.[key])) {
		return Promise.reject({
			redirectTo: { name: 'app' },
		})
	}
}
