<script>
	import CardTableFooter from './CardTableFooter.svelte'

	export let asr
	export let api
	export let makeLink
	export let sort
	export let columns
	export let columnNames
	export let data
	export let included
	export let links
	export let errors
	export let request
	export let small
	export let loading = false
	export let firstColumnScopeRow = true
	export let table
</script>

<div class="card mb-3">
	<div class="card-body p-0">
		<div class="card-header py-2">
			<slot />
		</div>
		<svelte:component
			this={table}
			{asr}
			{api}
			{makeLink}
			{sort}
			{columns}
			{columnNames}
			{data}
			{included}
			{small}
			{loading}
			{firstColumnScopeRow}
		/>
	</div>
	<CardTableFooter
		{data}
		{included}
		{links}
		{errors}
		{request}
		bind:loading
		on:loadNext
	/>
</div>
