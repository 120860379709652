<script>
	import { onMount } from 'svelte'
	import { Form } from 'jsonapi-svelte-form'
	import ChartCentralDispatchRequests from '@/component/charts/ChartCentralDispatchRequests.svelte'
	import Table from '@/component/table/CentralDispatchRequest.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { updateCentralDispatchCredentials, listCentralDispatchRequests } from '@/lib/controller/system.js'
	import { listUsers } from '@/lib/controller/user.js'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let asr
	export let api
	export let form

	let unlocked

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateCentralDispatchCredentials(api, form)
			.then(response => form = response)
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}

	let historicRequests
	let users
	onMount(() => {
		listCentralDispatchRequests(api, {}).then(response => historicRequests = response.data)
		listUsers(api).then(response => users = response.data)
	})
</script>

<div class="card">
	<div class="card-header">
		<h4>
			<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
			<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
			Central Dispatch
		</h4>
	</div>
</div>

{#if form}
	<div class="card mt-3">
		<div class="row">
			<div class="col-auto">
				<div class="card-header">
					<h5>
						<span class="fa fa-key"></span>
						Edit Credentials
					</h5>
				</div>
			</div>
			<div class="col">
				<div class="card-body">
					<Form bind:form>
						<InputType
							type="text"
							label="Username"
							readonly={!unlocked || form.state === 'saving'}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'username' ]}
						/>
						<InputType
							type={unlocked ? 'text' : 'password'}
							label="Password"
							readonly={!unlocked || form.state === 'saving'}
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'password' ]}
						/>
					</Form>
					<FormErrorsOther {form} />
					<FormCommitButton disabled={!unlocked} {form} on:click={save}>
						<span slot="before">
							<button on:click={() => unlocked = !unlocked} class="btn btn-secondary me-3 text-center" style="width: 8em;">
								{#if unlocked}
									<span class="fa fa-lock-open"></span>
									Lock
								{:else}
									<span class="fa fa-lock"></span>
									Unlock
								{/if}
							</button>
						</span>
					</FormCommitButton>
				</div>
			</div>
		</div>
	</div>
{/if}

<div class="card mt-3">
	<div class="card-body">
		{#if historicRequests}
			<ChartCentralDispatchRequests requests={historicRequests} />
			<Table data={historicRequests} included={users || []} />
		{:else}
			Loading...
		{/if}
	</div>
</div>
