import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	address: true,
}

export const type = 'insurance'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the insurance record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				companyName: {
					type: 'string',
					description: 'Name of the insurance company.',
				},
				agentName: {
					type: 'string',
					description: 'Name of agent assigned specifically, if known.',
				},
				agentPhone: {
					type: 'string',
					description: 'Direct phone number to agent, if known.',
				},
				policyNumber: {
					type: 'string',
					description: 'Insurance policy identifier.',
				},
				certificateHolder: {
					type: 'boolean',
					description: 'True if the insurance company is a certificate holder.',
				},
				limit: {
					description: 'Financial obligation limit for insurance coverage.',
					type: 'number',
					format: 'double',
				},
				deductible: {
					description: 'Cost of deductible listed in Central Dispatch.',
					type: 'number',
					format: 'double',
				},
				expiration: {
					description: 'The date that the insurance policy expires, if known.',
					type: 'string', // TODO: The expiration is sort of free-form in jTracker, so... maybe fix
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				address: {
					description: 'Address for this company.',
					...jsonApiRelationshipSingular('address'),
				},
			},
		},
	},
}
