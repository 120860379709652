<script>
	import { opportunityAddress } from '@/lib/opportunity-address.js'
	import LegLocation from '@/component/LegLocation.svelte'

	export let legs
	export let includedById

	$: addresses = (legs || [])
		.map(leg => ({
			legId: leg.id,
			start: opportunityAddress(leg.id, includedById, true),
			end: opportunityAddress(leg.id, includedById, false),
		}))
		.map((item, index, list) => {
			item.same = index > 0 && item.start.shortAddress === list[index - 1].end.shortAddress
			return item
		})
</script>

{#each addresses as { start, end, same }, index}
	<p class="mb-0">
		<LegLocation location={start} />→<LegLocation location={end} />
	</p>
{/each}
