<script>
	import { dateFormatter } from '@/shared/util/date-formatter.js'

	export let date
	export let format
	export let dateFormat // optional
	export let timeFormat // optional
	export let time // boolean: show the time portion
	export let multiline // boolean: put time on a new line
	export let smallTime // boolean: if truthy, the time will be styled small

	const defaultDateFormat = '{MMM} {D}, {YYYY}'
	const defaultTimeFormat = '{hh}:{mm} {A}'

	const YYYY_MM_DD_DATE = /^\d{4}-\d{2}-\d{2}$/
	const getUtcDate = string => {
		if (!string) return string
		const [ year, month, day ] = string.split('-')
		const utcDate = Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), 12, 0, 0)
		return new Date(utcDate)
	}

	$: formatTheDate = dateFormatter(format || dateFormat || defaultDateFormat)
	$: formatTheTime = time && dateFormatter(timeFormat || defaultTimeFormat)
	$: internalDate = (typeof date === 'string' && YYYY_MM_DD_DATE.test(date)) ? getUtcDate(date) : (date && new Date(date))
	$: dateString = date && formatTheDate(internalDate)
	$: timeString = date && time && formatTheTime(internalDate)
</script>

{dateString || '-'}

{#if time && timeString}
	{#if multiline}
		<br>
	{/if}
	<span style:font-size={smallTime && 'smaller'}>{timeString}</span>
{/if}
