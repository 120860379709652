import { emailSender } from '../value/email-sender.js'
import { emailTemplateModels } from '../value/email-template-models.js'
import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	signableTemplate: false,
}

export const type = 'email-template'

export default {
	description: `
		An email template which, combined with required and optional parameters, is used
		to generate a complete email to send to a user, client, etc.
	`,
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'The template identifier, e.g. `forgot-password` for a system-level template, or something else for a manually created template.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				useForAutoQuote: {
					description: 'Setting to true will show this template in Auto-Quote Campaigns.',
					type: 'boolean',
				},
				showForOpportunityStatus: {
					description: 'Set each status to true, to have this template shown for that opportunity communications.',
					type: 'object',
					properties: {
						lead: {
							description: 'Set to true if the template should be shown for Lead communications.',
							type: 'boolean',
						},
						quote: {
							description: 'Set to true if the template should be shown for Quote communications.',
							type: 'boolean',
						},
						order: {
							description: 'Set to true if the template should be shown for Order communications.',
							type: 'boolean',
						},

					},
				},
				status: {
					description: 'Status of the email template.',
					type: 'string',
					enum: [
						'active',
						'archived',
					],
				},
				name: {
					description: 'A human-readable name for the template.',
					type: 'string',
				},
				subject: {
					description: 'The subject line used for the email. May make use of model parameters.',
					type: 'string',
				},
				customNote: {
					description: 'If set, an optional custom note field will be shown when sending the email.',
					type: 'boolean',
				},
				sender: {
					description: 'Determines the return address be for this email.',
					type: 'string',
					enum: Object.keys(emailSender),
				},
				bccs: {
					description: 'List of BCC addresses to send these emails to.',
					type: 'array',
					items: {
						description: 'A well-formatted email address.',
						type: 'string',
					},
				},
				model: {
					description: 'The name of the template model to use.',
					type: 'string',
					enum: Object.keys(emailTemplateModels),
				},
				view: {
					description: 'The HTML formatted content template.',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				signableTemplate: {
					description: `
						If set and the email template view contains the {{signableDocumentUrl}} template
						variable, a signable document will be created from a signable template, and a
						unique token will be set on that URL to identify the recipient.
					`,
					...jsonApiRelationshipSingular('signable-template'),
				},
			},
		},
	},
}
