<script>
	import TypeIcon from '@/component/table-cells/TypeIcon.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import QbChangeColumn from '@/component/table/qbChanges/QbChangeColumn.svelte'
	import { resourceToLink } from '@/lib/resource-to-link.js'

	export let asr
	export let sync
	export let includedById
	export let historyByQbSyncId

	let showHistories

	$: user = includedById[sync.relationships?.user?.data?.id]
	$: type = sync.relationships?.company?.data?.id ? 'company' : 'opportunity'
	$: id = sync.relationships?.company?.data?.id || sync.relationships?.opportunity?.data?.id
	$: histories = showHistories && historyByQbSyncId[sync.id] || []
</script>

<style>
	tr.hide-bottom-border td {
		border-bottom: none;
	}
</style>

<tr class:hide-bottom-border={showHistories}>
	<td class="text-center icon">
		<TypeIcon row={ { type } } column={ { showName: true, shortName: true, iconMuted: true } } />
	</td>
	<td class="id">
		<PlainLink row={ { id, type } } column={ { keypath: 'id', link: row => resourceToLink(asr, row, false) } } />
	</td>
	<td>
		<DateString date={sync.meta.created} time />
	</td>
	<td>
		{#if user}
			{user.attributes.name}
		{/if}
	</td>
	<td>
		<DateString date={sync.attributes.maximumDateInclusive} time />
	</td>
	<td>
		<button class="btn btn-sm btn-outline-secondary" on:click={() => { showHistories = !showHistories }}>
			<span class="fa fa-search"></span>
			{sync.attributes.modifiedCount}
			Change
		</button>
	</td>
</tr>

{#if showHistories && histories?.length}
	<tr>
		<td colspan="2"></td>
		<td colspan="4">
			<table class="table table-sm mb-0">
				<thead class="bg-200 text-900">
					<tr>
						<th>Name</th>
						<th>From</th>
						<th><!-- arrow --></th>
						<th>To</th>
					</tr>
				</thead>
				<tbody>
					{#each histories as row (row.id)}
						{#each row.attributes.changes as change}
							<tr>
								<QbChangeColumn
									hideClipboardColumn
									{row}
									{change}
								/>
							</tr>
						{/each}
					{/each}
				</tbody>
			</table>
		</td>
	</tr>
{/if}
