import { minimumDelay } from '@/lib/delay.js'
import { commitForm } from '@/lib/form-committer.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const getCentralDispatchSettings = async ({ get }) => get('/api/v1/centralDispatch').then(response => response.body)
export const updateCentralDispatchCredentials = async ({ patch }, form) => minimumDelay(900, commitForm({ form, request: patch, url: '/api/v1/centralDispatch' }))

export const submitCDMfaMethod = async ({ post }, data) => minimumDelay(300, post('/api/v1/centralDispatch/session/method', { body: { data } }))
export const submitCDMfaCode = async ({ post }, data) => minimumDelay(300, post('/api/v1/centralDispatch/session/code', { body: { data } }))

export const listCentralDispatchRequests = async ({ get }, params) => get('/api/v1/centralDispatch/requests' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
