// const legacyJtrackerVehicleTypes = {
// 	'1': 'Sedan Small',
// 	'2': 'Sedan Large',
// 	'3': 'Pickup Small',
// 	'4': 'Pickup Extd. Cab',
// 	'5': 'Pickup Crew Cab',
// 	'6': 'SUV Small',
// 	'7': 'SUV Mid-size',
// 	'8': 'SUV Large',
// 	'9': 'Van Mini',
// 	'10': 'Van Full-size',
// 	'11': 'Other',
// 	'12': 'Boat',
// 	'13': 'Sedan Midsize',
// 	'14': 'Convertible',
// 	'15': 'Pickup Full-size',
// 	'16': 'Van Extd. Length',
// 	'18': 'Van Pop-Top',
// 	'19': 'Motorcycle',
// 	'20': 'Dually',
// 	'21': 'Coupe',
// 	'22': 'RV',
// 	'23': 'Travel Trailer',
// }

// TODO refresh this list from Central Dispatch
export const vehicleType = {
	'ATV': 'ATV',
	'Boat': 'Boat',
	'Car': 'Car',
	'Heavy Equipment': 'Heavy Equipment',
	'Large Yacht': 'Large Yacht',
	'Motorcycle': 'Motorcycle',
	'Pickup': 'Pickup',
	'RV': 'RV',
	'SUV': 'SUV',
	'Travel Trailer': 'Travel Trailer',
	'Van': 'Van',
	'Other': 'Other',
}

export const vehicleSize = {
	small: 'Small',
	medium: 'Medium',
	large: 'Large',
}
