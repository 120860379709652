<script>
	import ErrorAlert from './ErrorAlert.svelte'
	export let errors
</script>

{#if errors && errors.length}
	{#each errors as error, index}
		<ErrorAlert {error} class={index === (errors.length - 1) ? 'mb-0' : ''} />
	{/each}
{/if}
