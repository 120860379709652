<script>
	export let error
	export let level = 'danger'
	export let request

	let clazz = ''
	export { clazz as class }
</script>

<div class="alert alert-{level} {clazz}">
	<h4 class="alert-heading">
		{error.code || 'Unknown Error'}
		{#if request}
			<small class="text-muted">
				{request.method}
				{request.url}
				&rarr;
			</small>
		{/if}
		<small class="text-muted">{error.status || 'N/A'}</small>
	</h4>
	<p class="mb-1">{error.title || 'Client side error.'}</p>
	{#if error.detail || error.message}
		<p class="mb-1">{error.detail || error.message}</p>
	{/if}
	{#if error.meta?.response?.errors?.length}
		<!-- UnexpectedServiceResponse has response.errors as Array<string> -->
		<p class="mb-1"><strong>Service Response Message:</strong></p>
		{#each error.meta.response.errors as message}
			<p class="mb-1">{message}</p>
		{/each}
	{/if}
	{#if error.code === 'InvalidResource'}
		<p class="mb-1"><strong>Resource ID: {error.meta.id}</strong></p>
		{#each error.meta.details as detail}
			<pre>{JSON.stringify(detail, undefined, 4)}</pre>
		{/each}
	{:else if error.meta?.stacktrace}
		<hr>
		<pre>{error.meta.stacktrace}</pre>
	{/if}
	{#if error.meta?.auditLink}
		<p class="mb-0">
			If this error continues to occur, please send this link to a site admin:
			<a href={error.meta.auditLink}>{error.meta.auditLink}</a>
		</p>
	{/if}
</div>
