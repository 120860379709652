/*
This functionality should be reserved for user-facing identifiers only, e.g. opportunities
or other resources where the ID might be read and used by humans.

NOTE: Be sure the suffix is unique, so that generated IDs are globally unique!
 */
export const typeToAutoIncrementSuffix = {
	company: 'C',
	opportunity: 'P', // "O" is too hard to differentiate from "0" in normal fonts
	referrer: 'R',
	user: 'U',
	'sent-email': 'S',
	job: 'J',
}
