<script>
	import { onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'
	import { keyBy } from '@/shared/util/key-by.js'
	import { router } from '@/service/router.js'
	import { everyOpportunityColumn } from '@/component/report/opportunity/every-opportunity-column.js'


	import DateRangeNavbar from './_DateRangeNavbar.svelte'
	import { commonSelectableDateRanges } from './_common-selectable-date-ranges.js'
	import { dateRangeFromQuery } from './_date-range-from-query.js'
	import { fetchReport } from './_fetch-report.js'

	export let api
	export let asr
	export let querystringParams
	export let referrers

	$: referrersById = keyBy(referrers, 'id')
	$: referrerTags = Object.keys(referrers
		.reduce((map, ref) => {
			if (ref.attributes.tag) map[ref.attributes.tag] = true
			return map
		}, {}))

	let selectedReferrerIds = []
	let selectedReferrerTags = []
	let requireReferrer = 'true'
	onMount(() => {
		selectedReferrerIds = querystringParams?.referrerIds?.split(',') || []
		selectedReferrerTags = querystringParams?.referrerTags?.split(',') || []
		requireReferrer = querystringParams?.requireReferrer || 'true'
	})
	let referrerSelectorPanelOpen
	$: referrerFilterHasChanges = selectedReferrerIds.sort().join(',') === (querystringParams?.referrerIds?.split(',').sort().join(',') || '')
		&& selectedReferrerTags.sort().join(',') === (querystringParams?.referrerTags?.split(',').sort().join(',') || '')


	const updateReferrerFilter = () => {
		const params = { ...querystringParams } // shallow copy
		if (requireReferrer === 'true') delete params.requireReferrer
		else params.requireReferrer = 'false'
		if (selectedReferrerIds.length) params.referrerIds = selectedReferrerIds.join(',')
		else delete params.referrerIds
		if (selectedReferrerTags.length) params.referrerTags = selectedReferrerTags.join(',')
		else delete params.referrerTags
		router.go(null, params, { inherit: false })
		referrerSelectorPanelOpen = false
	}

	const label = 'Referral Data Export'
	const tableId = 'referral-data'
	const orderedColumns = [
		'pickupContract',
		'created',
		'leadToPickupDays',
		'miles',
		'id',
		'customerName',
		'customerEmails',
		'vehiclesFlat',
		'originalTariff',
		'referrer',
		'referrerTag',
		'bats',
	]
	const columnOverrides = {
		pickupContract: { label: 'Picked Up Date' },
		created: { label: 'Lead Date' },
		leadToPickupDays: { label: '# Days' },
		miles: { label: 'Miles' },
		id: { label: 'Num' },
		customerName: { label: 'Name' },
		customerEmails: { label: 'Email' },
		vehiclesFlat: { label: 'Memo' },
		originalTariff: { label: 'Sales Price' },
		referrer: { label: 'Source' },
		referrerTag: { label: 'Source Tag' },
		bats: { label: 'BATS' },
	}

	let currentSort
	let data
	let errors
	let included
	let loading = true
	$: dateRange = dateRangeFromQuery({ querystringParams, backward: false, now: new Date() })
	$: columns = everyOpportunityColumn({ asr })
	$: params = dateRange?.apiDates?.min && dateRange?.apiDates?.max && {
		filter: {
			minDate: dateRange.apiDates.min,
			minDateType: 'firstReceivedContract',
			maxDate: dateRange.apiDates.max,
			maxDateType: 'firstReceivedContract',
			includeCancelled: 'true',
			includeHeld: 'true',
		},
	}
	$: {
		if (params) {
			const filter = { ...params?.filter } // shallow clone
			if (requireReferrer === 'true') filter.requireReferrer = 'true'
			const refIds = querystringParams?.referrerIds?.split(',') || []
			const refTags = querystringParams?.referrerTags?.split(',') || []
			if (refIds.length || refTags.length) {
				const referrerIdsForTags = refTags.length
					&& referrers
						.filter(r => refTags.includes(r.attributes.tag))
						.map(r => r.id)
				const ids = [ ...new Set([ ...(refIds || []), ...(referrerIdsForTags || []) ]) ]
				if (ids.length) filter.referrerId = ids.join(',')
			}
			fetchReport({ api, url: '/api/v1/reports/groupedOpportunities/id', params: { filter } })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					if (response?.data) data = response.data
					if (response?.included) included = response.included
					currentSort = querystringParams.sort || '-pickupContract'
					loading = false
				})
		} else {
			loading = false
		}
	}

	$: rows = !loading && data && tableizeReportData({
		body: { data, included },
		sort: currentSort,
		params,
		columns,
		orderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		columnOverrides,
		orderedColumns,
		columns,
		label,
		filename: `${tableId}-${new Date().toISOString().split('T')[0]}.xlsx`,
		rows,
		params: params.filter,
	})
</script>

<DateRangeNavbar
	{asr}
	{columnOverrides}
	{columns}
	{dateRange}
	{generateSpreadsheetFile}
	{label}
	{orderedColumns}
	{querystringParams}
	rowCount={rows?.length}
	selectableDateRanges={commonSelectableDateRanges([ 'today', 'thismonth', 'lastmonth', 'custom' ])}
	{tableId}
>
	<div slot="description" style="max-width: 55ch;">
		<p class="mt-1 mb-2">
			Opportunities filtered by the date of the first Leg's contractual pickup date.
		</p>
	</div>
</DateRangeNavbar>

<p class="mt-3 mb-0">
	<button class="btn btn-sm btn-outline-secondary bg-light text-dark" on:click={() => referrerSelectorPanelOpen = !referrerSelectorPanelOpen}>
		<NamedIcon name="referrer" />
		Referrer Filters
	</button>
	{#if selectedReferrerIds.length || selectedReferrerTags.length}
		<small class="ms-5 me-1">Selected</small>
	{/if}
	{#each selectedReferrerIds as refId}
		<div class="badge border border-color-dark text-dark bg-light me-2">
			{referrersById[refId]?.attributes?.name || 'N/A'}
		</div>
	{/each}
	{#each selectedReferrerTags as tag}
		<div class="badge border border-color-dark text-dark bg-light me-2">
			tag:{tag}
		</div>
	{/each}
	{#if requireReferrer !== 'true'}
		<div class="badge border border-color-dark text-dark bg-light me-2">
			Including unreferred
		</div>
	{/if}
</p>

{#if referrerSelectorPanelOpen}
	<div class="card mt-3">
		<div class="card-body bg-light">
			<div class="row">
				<div class="col-auto">
					<div class="mb-1">
						<div class="form-check form-check-inline mb-0">
							<input
								class="form-check-input"
								type="checkbox"
								id="require-referrer"
								checked={requireReferrer === 'true'}
								on:click={() => { requireReferrer = requireReferrer === 'true' ? 'false' : 'true' }}
							>
							<label class="form-check-label mb-0" for="require-referrer">
								Exclude opportunities without a referrer
							</label>
						</div>
					</div>

					<strong>Referrers</strong>
					{#each referrers.sort((a, b) => (a.attributes.name || '').localeCompare(b.attributes.name || '')) as ref}
						<div class="mb-1">
							<div class="form-check form-check-inline mb-0">
								<input
									class="form-check-input"
									type="checkbox"
									id="id-{ref.id}"
									checked={selectedReferrerIds.includes(ref.id)}
									on:click={() => { selectedReferrerIds = selectedReferrerIds.includes(ref.id) ? selectedReferrerIds.filter(r => r !== ref.id) : [ ...selectedReferrerIds, ref.id ] }}
								>
								<label class="form-check-label mb-0" for="id-{ref.id}">
									{ref.attributes.name || '–'}
								</label>
							</div>
						</div>
					{/each}
				</div>
				<div class="col-auto">
					<strong>Referrer Tags</strong>
					{#each referrerTags.sort() as tag}
						<div class="mb-1">
							<div class="form-check form-check-inline mb-0">
								<input
									class="form-check-input"
									type="checkbox"
									id="tag-{tag}"
									checked={selectedReferrerTags.includes(tag)}
									on:click={() => { selectedReferrerTags = selectedReferrerTags.includes(tag) ? selectedReferrerTags.filter(t => t !== tag) : [ ...selectedReferrerTags, tag ] }}
								>
								<label class="form-check-label mb-0" for="tag-{tag}">
									{tag}
								</label>
							</div>
						</div>
					{/each}
				</div>
				<div class="col-auto">
					<button
						class="btn btn-sm btn-primary"
						disabled={referrerFilterHasChanges && requireReferrer === (querystringParams?.requireReferrer || 'true')}
						on:click={updateReferrerFilter}
					>
						Update Filter
					</button>
				</div>
			</div>
		</div>
	</div>
{/if}

<ReportTable
	id={tableId}
	{asr}
	{columnOverrides}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
