<script>
	export let form
	export let legErrors
</script>

<style>
	pre {
		border: 1px solid #ddd;
		border-radius: 0.3em;
		padding: 1em;
		background-color: #fff;
	}
</style>

<p class="mb-0">
	This opportunity is not quite ready to post to Central Dispatch:
</p>
<ul class="mb-0">
	{#each legErrors as error}
		{@const vehicle = form.data[error.meta?.vehicleId]?.attributes}
		{@const vehicleDescription = vehicle && [ vehicle.year, vehicle.make, vehicle.model ].filter(Boolean).join(' ').trim()}
		<li>
			{#if error.name === 'InvalidCentralDispatchOpportunity'}
				{error.detail}
				{#if error.meta?.missingKeys}
					Missing
					<code>{error.meta.missingKeys.join(', ')}</code>
					from the {vehicleDescription || 'vehicle'}.
				{:else if vehicleDescription}
					({vehicleDescription})
				{/if}
			{:else}
				This is an unexpected error, please copy this text and send it to support:
				<pre>{JSON.stringify(error, undefined, 2)}</pre>
			{/if}
		</li>
	{/each}
</ul>
