export const type = 'contact'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the contact.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				name: {
					description: 'The contact overall name. If set, the `firstName` and `lastName` should not be set.',
					type: 'string',
				},
				firstName: {
					description: 'The contact first name. If set, the `name` property should not be set.',
					type: 'string',
				},
				lastName: {
					description: 'The contact last name. If set, the `name` property should not be set.',
					type: 'string',
				},
				companyName: {
					description: 'The name of the company for this contact, if appropriate.',
					type: 'string',
				},
				emailPrimary: {
					description: 'Primary email for this contact.',
					type: 'string',
				},
				emailAlternate: {
					description: 'Alternate email for this contact.',
					type: 'string',
				},
				phonePrimary: {
					description: 'Primary phone for this contact.',
					type: 'string',
				},
				phoneTwo: {
					description: 'Second, alternate phone for this contact.',
					type: 'string',
				},
				phoneThree: {
					description: 'Third, alternate phone for this contact.',
					type: 'string',
				},
				phoneFour: {
					description: 'Fourth, alternate phone for this contact.',
					type: 'string',
				},
				phoneSms: {
					description: 'Phone number known to receive SMS messages.',
					type: 'string',
				},
				fax: {
					description: 'Fax number for this contact.',
					type: 'string',
				},
			},
		},
	},
}
