import { writable } from 'svelte/store'
import { router } from '@/service/router.js'

export const activeState = writable('')
export const activeParams = writable('')
export const previousState = writable('')

let previously = ''

router.on('stateChangeEnd', (state, parameters) => {
	if (previously) {
		previousState.update(() => previously)
	}
	activeState.update(() => state.name)
	activeParams.update(() => parameters)
	previously = state.name
})
