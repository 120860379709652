<script>
	import { Form } from 'jsonapi-svelte-form'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import InputCheckbox from '@/component/atom/InputCheckbox.svelte'
	import InputToggle from '@/component/atom/InputToggle.svelte'
	import InputSelect from '@/component/atom/InputSelect.svelte'
	import { userRoles } from '@/shared/value/user-roles.js'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form
	export let currentUser
	$: canManagePermissions = currentUser?.attributes?.permissions?.manageUsers
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				User Information
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<Form bind:form>
			<div class="row">
				<div class="col">
					<InputType
						type="text"
						label="Name"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'name' ]}
					/>
					<InputType
						type="text"
						label="Phone"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'phone' ]}
					/>
					<p class="form-text">Used for customer emails, if the template includes it.</p>
					<InputType
						type="text"
						label="Email"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'email' ]}
						readonly={!form.primaryId?.startsWith('GID')}
					/>
					<InputSelect
						bind:form
						id={form.primaryId}
						label="Role"
						options={userRoles}
						keypath={[ 'attributes', 'role' ]}
						onSelect={ (value, { set }) => { set(value || undefined) } }
					/>
					<InputToggle
						label="Status"
						bottomSpacing="0"
						enabledText="Active"
						disabledText="Inactive"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'active' ]}
					/>

					<p class="mt-3 mb-1">Permissions</p>

					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'mcsStaff' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="staff" />
						User can view all internal resources
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'manageOpportunities' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="order" />
						User can edit all Opportunities
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'manageReferrers' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="referrer" />
						User can edit all Referrers
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'generateCustomReports' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="report" />
						User can view and generate Custom Reports
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'manageUsers' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="users" />
						User can edit other users permissions
					</InputCheckbox>
					<InputCheckbox
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'permissions', 'manageApp' ]}
						readonly={!canManagePermissions}
					>
						<NamedIcon name="system" />
						User can manage this website
					</InputCheckbox>
				</div>
			</div>
		</Form>
	</div>
</div>
