<script>
	import Card from '@/component/atom/Card.svelte'
	import { patch } from '@/lib/json-fetch.js'
	import { signDocument } from '@/lib/controller/signable-document.js'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { minimumDelay } from '@/lib/delay.js'
	import { markdownToHtml } from '@/shared/util/markdown-to-html.js'

	export let token
	export let signableDocument
	export let visitId
	export let errors

	$: content = markdownToHtml(signableDocument?.attributes?.markdown)
	$: agreement = markdownToHtml(signableDocument?.attributes?.agreementLabel)
	$: signedDate = signableDocument?.attributes?.signed && new Date(signableDocument.attributes.signed)

	let saving = false
	let checked = false
	let name = ''
	$: namesMatch = name === signableDocument?.attributes.signerName

	let success
	const submitAgreement = () => {
		saving = true
		minimumDelay(1400, signDocument({ patch }, { token, visitId }))
			.then((response) => {
				const updated = response.body?.meta?.updated
				signableDocument.attributes.signed = updated
				signableDocument.meta.updated = updated
				success = true
			})
			.catch(error => {
				console.log('Failure to submit agreement.', error)
				errors = error.body?.errors || []
			})
	}
</script>

<style>
	@media print {
		.alert-success {
			display: none;
		}
	}
</style>

{#if errors || !visitId}
	<Card title="Error Loading Document">
		<p>Apologies, the document could not be loaded.</p>
		<p>Try clicking on the emailed link again, and if this problem continues please reach out to someone at Mr. Car Shipper for assistance.</p>
	</Card>
{:else}
	<Card title={signableDocument?.attributes?.name || 'Terms & Conditions'}>
		{@html content}
		<hr class="mt-4 mb-4">
		<div style="max-width: 30em; margin: 0 auto;">
			{#if signableDocument.attributes.signed}
				{#if success}
					<div class="alert alert-success">
						Success! You can print this page for your own reference, or close this tab when you're done.
					</div>
				{/if}
				<dl>
					<dt>Signed By</dt>
					<dd>{signableDocument?.attributes?.signerName}</dd>
					<dt>Email Address</dt>
					<dd>{signableDocument?.attributes?.signerEmailAddress}</dd>
					<dt>Date</dt>
					<dd>{signedDate.toLocaleDateString('default')}</dd>
					<dt>Timestamp</dt>
					<dd>{signedDate.toLocaleTimeString('default')}</dd>
				</dl>
			{:else}
				<p>
					To sign this document, please enter your name exactly as it appears here:
					<strong>{signableDocument?.attributes?.signerName}</strong>
				</p>
				<div class="input-group input-group-sm mb-3">
					<span class="input-group-text">
						Name
					</span>
					<input
						disabled={saving}
						type="text"
						class="form-control"
						class:is-invalid={checked && !namesMatch}
						value={name}
						on:input={event => { name = event.target.value }}
					>
					{#if checked && !namesMatch}
						<div class="invalid-feedback">
							You must enter your name <em>exactly</em> as it appears above.
						</div>
					{/if}
				</div>
				<div class="form-check mb-3">
					<input
						disabled={saving}
						type="checkbox"
						class="form-check-input"
						id="agreementLabel"
						bind:checked
					>
					<label class="form-check-label" for="agreementLabel">
						{@html agreement}
					</label>
				</div>
				<div class="row g-3">
					<div class="col-auto">
						<button type="button" class="btn btn-primary" disabled={!checked || !namesMatch || saving} on:click={submitAgreement}>
							Sign Agreement
						</button>
					</div>
					{#if saving}
						<div class="col d-flex align-items-center" style="opacity: 0.7">
							<NamedIcon name="loading" />
						</div>
					{/if}
				</div>
			{/if}
		</div>
	</Card>
{/if}
