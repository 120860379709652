import template from './FilteredOpportunities.svelte'

export default {
	data: {
		title: 'Filtered Opportunities',
	},
	template,
	querystringParameters: [
		'q',
	],
	resolve: async (_, querystringParams) => ({ querystringParams }),
}
