<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'

	export let form
	export let api
	export let legId

	let onlyOntrackSync

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'dispatch',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
		}))
	}
</script>

<p>
	For now, you can only manually mark a leg as Dispatched <strong>in OnTrack</strong>.
</p>

<button class="btn btn-sm btn-primary" disabled={form.state === 'saving' || !onlyOntrackSync} on:click={postHandler}>
	<NamedIcon name="sign" />
	Manually Mark as Signed
	{#if onlyOntrackSync}
		<em>in OnTrack Only!</em>
	{/if}
</button>

<div class="form-check mt-3 mb-0">
	<input
		disabled={form.state === 'saving'}
		class="form-check-input"
		type="checkbox"
		id="leg-dispatch-only-ontrack"
		checked={onlyOntrackSync}
		on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
	>
	<label for="leg-dispatch-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
		Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
		and will <strong>not</strong> send a notification email.
	</label>
</div>
