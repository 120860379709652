import template from './LeadAssignment.svelte'
import { listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'

export default {
	data: {
		title: 'Lead Assignment',
	},
	template,
	resolve: async () => {
		const { data: users } = await listUsers({ get })
		return { users }
	},
}
