<script>
	import { onMount } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputTextAreaBare from '@/component/atom/InputTextAreaBare.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

	export let asr
	export let api
	export let params

	$: resourceId = params?.id || ''

	let loading
	let loadedRawResource
	// let resourceIdToFind
	let errors
	let showConfirmation
	let saving
	let saved

	const loadResourceById = () => {
		loading = true
		api.get(`/api/v1/dangerouslyRaw/${resourceId}`)
			.then(response => {
				loadedRawResource = JSON.stringify(response.body.meta.raw, undefined, 4)
			})
			.catch(response => {
				errors = response.body?.errors || [ response ]
			})
	}

	const saveLoadedResource = () => {
		saving = true
		api.put(`/api/v1/dangerouslyRaw/${resourceId}`, { body: { meta: { raw: JSON.parse(loadedRawResource) } } })
			.then(() => {
				saved = true
			})
			.catch(response => {
				console.log('Oh no!', response)
				errors = response.body?.errors || [ response ]
			})
	}

	onMount(() => {
		if (resourceId) loadResourceById(resourceId)
	})
</script>

<div class="card">
	<div class="card-header">
		<h4>
			<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
			<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
			Database Editor
		</h4>
	</div>
</div>

<div class="card mt-3">
	<div class="card-body">
		{#if loadedRawResource}
			<div class="alert alert-danger">
				Editing this is probably a bad idea! You'll give yourself headaches and keep yourself awake at
				night if you touch anything in here! And yes that goes for you too, Tobias!
			</div>
			<InputTextAreaBare
				id="dangerous-raw-data"
				value={loadedRawResource}
				on:input={event => { loadedRawResource = event.target.value }}
			/>
			{#if !saved}
				<button class="btn btn-sm btn-danger mt-3" on:click={() => { showConfirmation = true }} disabled={showConfirmation}>
					Dangerously Overwrite Real Data
				</button>
				{#if showConfirmation}
					<br>
					<button class="btn btn-sm btn-danger mt-3" on:click={saveLoadedResource} disabled={saving}>
						Yes, I'm really really sure
						{#if saving}
							<NamedIcon name="loading" />
						{/if}
					</button>
				{/if}
			{:else}
				<div class="alert alert-success">
					It saved!
				</div>
			{/if}
		{:else}
			<fieldset disabled={loading}>
				<div class="input-group input-group-sm mb-2">
					<span class="input-group-text" id="carrier-search">
						<NamedIcon name="search" />&nbsp;Lookup Resource
					</span>
					<input
						type="text"
						class="form-control"
						value={resourceId}
						on:input={event => { resourceId = event.target.value }}
					>
					<button class="btn btn-primary" class:disabled={!resourceId} on:click={() => loadResourceById()}>
						Load
						{#if loading}
							&nbsp;
							<NamedIcon name="loading" />
						{/if}
					</button>
				</div>
			</fieldset>
		{/if}
	</div>
</div>

{#if errors}
	<pre>{JSON.stringify(errors, undefined, 4)}</pre>
{/if}
