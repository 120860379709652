<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	const dispatch = createEventDispatcher()

	export let string
	export let tableId
	export let hide

	$: disabled = !string && !tableId

	let icon
	let visible
	const clearStatus = () => setTimeout(() => {
		visible = false
	}, 1000)
	const copyToClipboard = () => {
		if (tableId) {
			try {
				const table = document.getElementById(tableId)
				const range = document.createRange()
				const selection = window.getSelection()
				selection.removeAllRanges()
				try {
					range.selectNodeContents(table)
					selection.addRange(range)
				} catch (e) {
					range.selectNode(table)
					selection.addRange(range)
				}
				document.execCommand('copy')
				selection.removeAllRanges()
				visible = true
				icon = 'complete'
				dispatch('copied')
				clearStatus()
			} catch (error) {
				visible = true
				icon = 'warning'
				console.error('Failed to copy to clipboard', error)
				clearStatus()
			}
		} else if (string) {
			navigator.clipboard.writeText(string)
				.then(() => {
					visible = true
					icon = 'complete'
					dispatch('copied')
					clearStatus()
				})
				.catch(error => {
					visible = true
					icon = 'warning'
					console.error('Failed to copy to clipboard', error)
					clearStatus()
				})
		}
	}
</script>

<style>
	button {
		position: relative;
	}
	.clipboard-status {
		position: absolute;
		top: 0;
		right: 0.1em;
		font-size: 0.6em;
	}
	.clipboard-status.text-success, .clipboard-status.text-danger {
		opacity: 0.7;
	}
	.clipboard-status.fade-out {
		opacity: 0;
		animation: fadeout 1s;
	}
	.disabled {
		color: #d3d3d3 !important;
		border-color: #d3d3d3 !important;
	}
	@keyframes fadeout {
		from { opacity: 0.7; }
		to { opacity: 0; }
	}
</style>

{#if string || !hide}
	<button tabindex="-1" class="btn btn-sm btn-outline-secondary" type="button" class:disabled {disabled} on:click={copyToClipboard}>
		<NamedIcon name="copy" />
		<span class="clipboard-status" class:fade-out={visible === false} class:text-success={icon === 'complete'} class:text-danger={icon === 'warning'}>
			{#if icon}
				<NamedIcon name={icon} />
			{/if}
		</span>
	</button>
{/if}
