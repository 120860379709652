<script>
	import { createEventDispatcher } from 'svelte'
	import FormInput from '../common/FormInput.svelte'

	export let config = {}
	export let form = {}
	export let name = ''

	let className = ''
	export { className as class }

	const dispatch = createEventDispatcher()
	const onsubmit = () => { dispatch('submit', { name, form }) }

	$: state = form[name]._state || form._state || {}
	$: passwordTests = config && config.passwordTests || [
		{
			label: 'More than 10 characters.',
			test: password => password.length > 10,
		},
		{
			label: 'Must contain some letters.',
			test: password => /[a-z]/.test(password.toLowerCase()),
		},
		{
			label: 'Must contain some numbers.',
			test: password => /[0-9]/.test(password.toLowerCase()),
		},
	]
	$: valid = form[name].password
		&& form[name].password === form[name].confirmPassword
		&& passwordTests.every(({ test }) => test(form[name].password))
</script>

<form on:submit|preventDefault={onsubmit} class={className}>
	<fieldset disabled={state.submitting}>
		<div class="mb-3">
			<FormInput type="password" on:input config={config.password} bind:value={form[name].password} form={name} keypath="password" />
		</div>
		<p>Confirm password</p>
		<div class="mb-3">
			<FormInput type="password" on:input config={config.confirmPassword} bind:value={form[name].confirmPassword} form={name} keypath="confirmPassword" />
		</div>
		<ul>
			{#if passwordTests?.length}
			{#each passwordTests as { label, test }}
				<li>
					{#if test}
						{#if form[name].password && test(form[name].password)}
							✅
						{:else}
							🔲
						{/if}
						{label}
					{/if}
				</li>
			{/each}
			{/if}
			<li>
				{#if form[name].password && form[name].password === form[name].confirmPassword}
					✅
				{:else}
					🔲
				{/if}
				Passwords must match.
			</li>
		</ul>
		<button disabled={!valid} type="submit" name="submit" class="btn btn-primary d-block w-100 mt-3">
			Set password
		</button>
	</fieldset>
</form>
