import template from './Search.svelte'
import { redirectIfLackingPermission } from '@/lib/redirect-if-lacking-permission.js'
import { MCS_STAFF } from '@/shared/value/user-permissions.js'

export default {
	data: {
		title: 'Search Results',
	},
	template,
	querystringParameters: [ 'q', 'tab' ],
	resolve: async (_, params) => {
		const redirect = await redirectIfLackingPermission(MCS_STAFF)
		if (redirect) return redirect
		return { query: params?.q?.trim(), currentTab: params?.tab }
	},
}
