<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { opportunityToStateAndIcon } from '@/lib/opportunity-to-state-and-icon.js'

	export let row
	export let includedById

	$: stateAndIcons = opportunityToStateAndIcon({ data: row, includedById })
</script>

{#each stateAndIcons as { state, icon }}
	<span class="text-nowrap">
		{#if icon}
			<NamedIcon name={icon} />
		{/if}
		{#if state}
			{state}
		{/if}
	</span>
{/each}
