<script>
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import ExternalLink from '@/component/ExternalLink.svelte'

	export let asr
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		<h4 class="mb-0">
			<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
			<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
			Incoming Leads
		</h4>
	</div>
</div>

<div class="card">
	<div class="card-header py-2">
		Flow of Data
	</div>
	<div class="card-body bg-light">
		<p>Leads come in from Click Funnel through this process:</p>
		<p>
			Click Funnel
			<Icon data={faAngleRight} scale="1" />
			Zapier Trigger
			<Icon data={faAngleRight} scale="1" />
			Custom Zapier App
			<Icon data={faAngleRight} scale="1" />
			Custom API
			<Icon data={faAngleRight} scale="1" />
			MCS OnTrack
			<Icon data={faAngleRight} scale="1" />
			Job Runner
		</p>

		<h3>Click Funnel</h3>
		<p>
			Click Funnel has an integration with Zapier: when a form is submitted,
			that form goes through into Zapier as a trigger.
		</p>

		<h3>Zapier Trigger</h3>
		<p>
			Within the Zapier trigger, the event does stuff like append to a Google
			spreadsheet, and at the very end it makes use of a custom-made Zapier App.
		</p>

		<h3>Custom Zapier App</h3>
		<p>
			This app is named
			<ExternalLink url="https://developer.zapier.com/app/152057/">"MCS OnTrack"</ExternalLink>
			and has an action named
			<ExternalLink url="https://developer.zapier.com/app/152057/version/1.0.0/actions/creates/create_opportunity/settings">"Create Opportunity"</ExternalLink>.
			This Zapier app pushes the data you map from Click Funnel, over to the Custom API.
		</p>

		<h3>
			Custom API
			<small>(see <ExternalLink url="https://github.com/mrcarshipper/zapier">Github</ExternalLink>)</small>
		</h3>
		<p>
			<strong>(First Duplicate Check)</strong>
			Because Zapier sometimes pushes duplicates up, we have to do a first-pass check
			for duplicates here. Basically, if every property in the lead is the same, character
			for character, it'll get treated as a duplicate and <em>ignored</em>.
		</p>
		<p>
			If the incoming lead is not a duplicate, it is pushed to MCS OnTrack, using the same API endpoint
			as used by Auto-Quote Campaigns
			(see the <a href={asr.makePath('app.systemSettings.autoQuoteDocumentation')}>API documentation</a>).
		</p>

		<h3>Job Runner</h3>
		<p>
			Once leads are pushed to MCS OnTrack, the job runner processes it, normalizing
			phone numbers to standard format, matching the lead to an existing customers,
			assigning the lead, looking for duplicates, and more.
		</p>
		<p>
			<strong>(Second Duplicate Check)</strong>
			A little massaging of data here to catch leads that are <em>basically</em> the same,
			where a customer might have forgotten and tried again. Here's the steps used to see
			if an incoming lead is a duplicate:
		</p>
		<ul>
			<li>
				Normalize the email and phone number of the lead.
			</li>
			<li>
				A duplicate opportunity needs to have <strong>any</strong> of the same email <strong>or</strong> phone numbers.
			</li>
			<li>
				A duplicate opportunity also needs to match vehicle details for <strong>every</strong> vehicle.
				(The customer might try all the same details but different car combinations. These would <em>not</em>
				be considered duplicates.)
			</li>
			<li>
				A duplicate must have a matching pickup/dropoff Zip Code.
			</li>
			<li>
				We only look back 30 days for duplicates, so a customer trying again 31 days
				later with the same exact details would not match as a duplicate.
			</li>
		</ul>
	</div>
</div>
