<script>
	export let row
	export let includedById

	$: estimatedShipDate = includedById[row?.relationships?.estimatedShipDate?.data?.id]?.attributes || {}

	$: legs = row?.relationships?.legs?.data?.map(leg => includedById[leg.id]).filter(Boolean) || []
</script>

<td>
	origin/destination
</td>

<td>
	{estimatedShipDate.startDate?.trim() || 'N/A'}
	<span class="fa fa-angle-right"></span>
	{estimatedShipDate.endDate?.trim() || 'N/A'}
	{#each legs as leg, legIndex}
		{@const date = includedById[leg?.relationships?.shipDate?.data?.id]?.attributes || {}}
		<br>
		Leg {legIndex + 1}:
		{(date.startDate?.trim() || '')}
		<span class="fa fa-angle-right"></span>
		{(date.endDate?.trim() || '')}
	{/each}
</td>
