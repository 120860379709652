export default {
	type: 'object',
	required: [
		'body',
	],
	properties: {
		body: {
			type: 'object',
		},
	},
}
