export default {
	type: 'object',
	properties: {
		mcsStaff: {
			description: 'True if this user is allowed to view or edit all resources except system settings and users.',
			type: 'boolean',
		},
		manageReferrers: {
			description: 'True if this user is allowed to create and edit referral sources.',
			type: 'boolean',
		},
		manageOpportunities: {
			description: 'Set to true if this user is able to create and edit all opportunities.',
			type: 'boolean',
		},
		generateCustomReports: {
			description: 'Set to true if this user is be able to generate custom reports.',
			type: 'boolean',
		},
		manageUsers: {
			description: 'Set to true if this user is able to modify all users except the `manageApp` setting.',
			type: 'boolean',
		},
		manageApp: {
			description: 'Set to true if this user is able to modify system-level settings.',
			type: 'boolean',
		},
	},
}
