<script>
	import AuthenticatedVisitRow from './AuthenticatedVisitRow.svelte'

	export let visits = []
	export let signedVisitId
	export let signedDate
</script>

<style>
	table {
		white-space: nowrap;
		font-size: smaller;
	}
</style>

<table class="table table-sm table-striped">
	<thead>
		<tr>
			<th scope="col">Date</th>
			<th scope="col">Time</th>
			<th scope="col">IP Address</th>
			<th scope="col">Browser</th>
			<th scope="col">Platform</th>
		</tr>
	</thead>
	<tbody>
	{#each visits as visit}
		<AuthenticatedVisitRow {visit} {signedVisitId} {signedDate} />
	{/each}
	</tbody>
</table>
