<script>
	import { vehicleType } from '@/shared/value/vehicle-type.js'
	import { shippingMethod } from '@/shared/value/shipping-method.js'
	import Card from '@/component/atom/Card.svelte'

	export let form

	$: vehicles = form.data[form.primaryId]?.relationships?.vehicles?.data || []
</script>

<style>
	p {
		margin-bottom: 0;
	}
	.col-auto {
		width: 3em;
		font-weight: bold;
		color: var(--falcon-400);
	}
	.vehicle-overview {
		line-height: 1.2;
	}
</style>

<Card title="Vehicles">
	{#if !vehicles.length}
		<span class="text-danger">No vehicles.</span>
	{/if}
	{#each vehicles as { id: vehicleId }}
		{@const atts = form.data[vehicleId]?.attributes || {}}
		<div class="mb-3 vehicle-overview">
			<p style="{atts.doesNotRun ? 'margin-bottom: -0.2em;' : ''}">
				{atts.year || ''}
				{atts.make || ''}
				{atts.model || ''}
				{#if vehicleType[atts.type]}
					<small><em>{vehicleType[atts.type]}</em></small>
				{/if}
			</p>
			{#if atts.doesNotRun}
				<p>
					<small class="text-warning">
						<span class="fa fa-car-crash"></span>
						&nbsp;
						Does not run.
					</small>
				</p>
			{/if}

			{#if atts.vin}
				<div class="row gx-2">
					<div class="col-auto">VIN</div>
					<div class="col">{atts.vin}</div>
				</div>
			{/if}
			{#if atts.lot}
				<div class="row gx-2">
					<div class="col-auto">Lot</div>
					<div class="col">{atts.lot}</div>
				</div>
			{/if}
			{#if atts.shippingMethod}
				<div class="row gx-2">
					<div class="col-auto">Ship</div>
					<div class="col" class:text-info={atts.shippingMethod !== 'open'}>{shippingMethod[atts.shippingMethod] || atts.shippingMethod}</div>
				</div>
			{/if}
		</div>
	{/each}
</Card>
