<script>
	import { containsCreditCardDigits } from '@/shared/util/contains-credit-card-digits.js'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import InputFilesRel from '@/component/atom/InputFilesRel.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import Card from '@/component/atom/Card.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputRadio from '@/component/atom/InputRadio.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import { ccTransactionStatus } from '@/shared/value/cc-transaction-status.js'

	export let form
	export let opportunityId
	export let uploadingFiles
	export let clearUploadingFiles

	$: transactionBeingCreated = /GID\d+/.test(form.primaryId || '')
	$: userId = form.data[form.primaryId]?.relationships?.user?.data?.id
	$: user = form.data[userId]
	$: creatingUserMap = { [userId]: user?.attributes?.name || '' }
	$: containsCcDigits = containsCreditCardDigits(form.data[form.primaryId]?.attributes?.note)
</script>

<Card title="Log Credit Card">
	<fieldset disabled={form.state === 'saving'}>
		{#if !transactionBeingCreated}
			<InputType
				type="text"
				label="OnTrack ID"
				readonly
				labelWidth="120px"
				bind:form
				id={form.primaryId}
				keypath={[ 'id' ]}
			/>
			<InputSelectRel
				label="User who Ran"
				labelWidth="120px"
				readonly
				bind:form
				options={creatingUserMap}
				id={form.primaryId}
				name="user"
				type="user"
			/>
		{/if}
		<InputType
			type="date"
			icon="date"
			label="Date Ran"
			labelWidth="120px"
			bind:form
			id={form.primaryId}
			keypath={[ 'attributes', 'ran' ]}
		/>
		<div class="mb-2">
			<p class="mb-0">Transaction status:</p>
			<InputRadio
				optionsList={Object.keys(ccTransactionStatus).map(key => ({ key, title: ccTransactionStatus[key] }))}
				bind:form
				id={form.primaryId}
				keypath={[ 'attributes', 'status' ]}
			/>
		</div>
		<InputTextArea
			label="Note"
			bind:form
			id={form.primaryId}
			keypath={[ 'attributes', 'note' ]}
		/>
		<p class="form-text"><strong>DO NOT</strong> save Credit Card numbers here!</p>
		<label for="note-attachment" class="form-label">
			<NamedIcon name="upload" />
			Add Attachments
		</label>
		<InputFilesRel
			inputId="note-attachment"
			formId={form.primaryId}
			fileParentType="opportunity"
			fileParentId={opportunityId}
			fileChildType="cc-transaction"
			fileChildId={form.primaryId}
			relName="files"
			bind:form
			bind:uploading={uploadingFiles}
			bind:clearUploadingFiles
		/>
	</fieldset>
	{#if containsCcDigits}
		<div class="alert alert-danger mt-3">
			<span class="fa fa-exclamation-circle"></span>
			You entered what appears to be a credit card number in the "Notes"
			field. If you believe this message to be in error, please let an
			admin know, to look into it.
		</div>
	{/if}
</Card>
