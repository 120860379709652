import template from './Overview.svelte'
import { getAutoQuoteCampaignById } from '@/lib/controller/auto-quote-campaign.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { autoQuoteCampaignId }) => ({
		autoQuoteCampaignId,
		form: load(await getAutoQuoteCampaignById({ get }, autoQuoteCampaignId), 0),
	}),
}
