<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import JobStatusChecker from '@/component/JobStatusChecker.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { opportunityToPerLegDocument } from '@/shared/util/opportunity-to-per-leg-documents.js'
	import { router } from '@/service/router.js'

	import DispatchLegPanelSelector from './DispatchLegPanelSelector.svelte'
	import LegDocumentErrors from './LegDocumentErrors.svelte'

	import PanelAssign from './PanelAssign.svelte'
	import PanelCancel from './PanelCancel.svelte'
	import PanelDeliver from './PanelDeliver.svelte'
	import PanelDispatch from './PanelDispatch.svelte'
	import PanelPost from './PanelPost.svelte'
	import PanelReceive from './PanelReceive.svelte'
	import PanelRemind from './PanelRemind.svelte'
	import PanelStatus from './PanelStatus.svelte'
	import PanelUndeliver from './PanelUndeliver.svelte'

	export let api
	export let form
	export let disabled
	export let companyData
	export let legState
	export let legIndex
	export let legId
	export let legRels
	export let totalAdjustedCarrierPay
	export let totalOriginalCarrierPay

	let jobId
	let responseErrors
	let originalFormState
	let stoppedJobLink
	let openActionPanel

	$: unsavedChanges = [ 'changed', 'unsaved' ].includes(form.state)
	$: status = form.data[form.primaryId]?.attributes?.status
	$: leadOrQuote = status === 'lead' || status === 'quote'
	$: previousLegId = legRels[legIndex - 1]?.id
	$: legLocations = getPickupDropoffForLeg({ opportunityId: form.primaryId, previousLegId, legId, resourcesById: form.data })
	$: legIsBeingAssigned = false

	let legErrors
	let legDocument
	$: {
		const out = opportunityToPerLegDocument({
			opportunity: {
				data: form.data[form.primaryId],
				included: Object.values(form.data),
			},
			otherIncludedById: Object.assign({}, form._otherIncludedById || {}, form.data || {}, companyData?.byId || {}, companyData?.includedById || {}),
			specificLegId: legId,
			optional: {
				firstAvailableDate: legState === 'unpost',
				carrierPay: legState === 'unpost',
			},
		})
		legErrors = out.errors || []
		legDocument = out.legDocument
	}

	const unsavedChangesThatMatter = (f, l, key) => {
		const isChanged = f?.changes?.[l]?.find(c => c.path[0] === 'attributes' && c.path[1] === key)
		const onlyThat = isChanged && Object.keys(f.changes).length === 1 && f.changes[l].length === 1
		return Object.keys(f.changes).length === 0 || onlyThat
	}
	$: onlyActualPickupChanged = unsavedChangesThatMatter(form, legId, 'actualPickup')
	$: onlyActualDropoffChanged = unsavedChangesThatMatter(form, legId, 'actualDropoff')

	const jobStopped = ({ detail: job }) => {
		if (job?.attributes?.status === 'COMPLETED') {
			// Note: the statefulness of these DispatchActionButtons means it's easiest and
			// cleanest to simply reload the page than reload the state... This could be waaaaaay better.
			window.location.reload()
		} else {
			form.state = originalFormState
			stoppedJobLink = router.makePath('app.systemSettings.jobs.byId.jobId', { jobId: job.id }, { inherit: false })
		}
	}

	let actionResponseErrors
	const handleActionPromise = ({ detail: actionPromise }) => {
		actionPromise
			.then(response => {
				if (response.body?.errors) actionResponseErrors = response.body.errors
				if (response.body?.meta?.job?.id) jobId = response.body.meta.job.id
				else if (response.body?.data?.id) {
					// Note: the statefulness of these DispatchLeg components means it's easiest and
					// cleanest to simply reload the page than reload the state... This could be way better.
					window.location.reload()
				}
			})
			.catch(e => {
				console.error('Error while creating post!', e)
				if (e?.body?.errors) actionResponseErrors = e.body.errors
				else actionResponseErrors = [ e ]
			})
	}

	let missingPickupInfo
	let missingDropoffInfo
	let legDocumentErrors
	const requiredLocationProps = [
		'city',
		'state',
		'zip',
		'address1',
		'contact',
		'phone1',
	]
	const locationPropToLabel = {
		city: 'City',
		state: 'State',
		zip: 'Zip',
		address1: 'Address Line 1',
		contact: 'Contact Name',
		phone1: 'Primary Phone',
	}
	$: {
		const { errors, legDocument } = opportunityToPerLegDocument({
			opportunity: {
				data: form.data[form.primaryId],
				includedById: form.data,
			},
			otherIncludedById: Object.assign(
				{},
				form._otherIncludedById || {},
				form.data || {},
				companyData?.byId || {},
				companyData?.includedById || {},
			),
			specificLegId: legId,
			optional: {
				carrier: true,
				firstAvailableDate: true,
				carrierPay: true,
			},
			forDispatch: true,
		})
		legDocumentErrors = errors
		missingPickupInfo = requiredLocationProps.filter(prop => !legDocument?.origin?.[prop])
		missingDropoffInfo = requiredLocationProps.filter(prop => !legDocument?.destination?.[prop])
	}
</script>

{#if stoppedJobLink}
	<div class="alert alert-warning">
		<p class="mb-0">
			<NamedIcon name="warning" />
			An unexpected error happened while trying to sync to Central Dispatch. Please look at the
			<a href={stoppedJobLink}>job details</a> and contact an admin or developer.
		</p>
	</div>
{/if}

<ul class="nav nav-tabs">
	<li class="nav-item">
		<a
			class="nav-link"
			class:disabled={form.state === 'saving' || disabled}
			class:active={!openActionPanel}
			href="#status"
			on:click|preventDefault={() => { openActionPanel = false }}
		>
			<NamedIcon name="cd" />
			Status
		</a>
	</li>
	{#if !disabled}
		{#if legState === 'unpost' && !legErrors?.length}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="post" label="Post to Central Dispatch" />
		{/if}
		{#if legState === 'post'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="assign" label="Assign to Carrier" icon="sign" />
		{/if}
		{#if legState === 'assign'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="remind" label="Send Reminder" icon="email" />
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="dispatch" label="Manually Mark as Dispatched" icon="dispatched" />
		{/if}
		{#if legState === 'dispatch'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="receive" label="Mark as Picked Up" icon="received" />
		{/if}
		{#if legState === 'receive'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="deliver" label="Mark as Delivered" icon="delivered" />
		{/if}
		{#if legState === 'deliver'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="undeliver" label="Mark as Not Delivered" icon="undeliver" />
		{/if}
		{#if legState && legState !== 'unpost'}
			<DispatchLegPanelSelector bind:form bind:openActionPanel panel="cancel" label="Cancel" />
		{/if}
	{/if}
</ul>

<div class="border rounded-bottom p-2" class:alert-info={!openActionPanel && legErrors?.length}>
	{#if !openActionPanel}
		<PanelStatus
			bind:form
			{legId}
			{legErrors}
			{companyData}
		/>
	{/if}
	{#if openActionPanel === 'post'}
		<PanelPost
			bind:form
			{api}
			{legRels}
			{legId}
			{previousLegId}
			{legIndex}
			{legState}
			{totalOriginalCarrierPay}
			{totalAdjustedCarrierPay}
			{legDocument}
			readonly={legErrors?.length}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'assign' && !missingPickupInfo?.length && !missingDropoffInfo?.length && !legDocumentErrors?.length}
		<PanelAssign
			bind:form
			{api}
			{legState}
			{legId}
			{legIndex}
			on:action={handleActionPromise}
		/>
	{:else if openActionPanel === 'assign'}
		{#if legDocumentErrors?.length}
			<div style:font-size="smaller" class="alert-warning">
				<LegDocumentErrors {form} legErrors={legDocumentErrors} />
			</div>
		{/if}
		{#if missingPickupInfo?.length}
			<p class="mb-0">Missing pickup information: {missingPickupInfo.map(p => locationPropToLabel[p]).join(', ')}</p>
		{/if}
		{#if missingDropoffInfo?.length}
			<p class="mb-0">Missing delivery information: {missingDropoffInfo.map(p => locationPropToLabel[p]).join(', ')}</p>
		{/if}
	{/if}
	{#if openActionPanel === 'remind'}
		<PanelRemind
			bind:form
			{api}
			{legId}
			{companyData}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'dispatch'}
		<PanelDispatch
			bind:form
			{api}
			{legId}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'receive'}
		<PanelReceive
			{form}
			{api}
			{legId}
			{companyData}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'deliver'}
		<PanelDeliver
			{form}
			{api}
			{legId}
			{companyData}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'undeliver'}
		<PanelUndeliver
			{form}
			{api}
			{legId}
			{companyData}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if openActionPanel === 'cancel'}
		<PanelCancel
			bind:form
			{api}
			{legState}
			{legId}
			on:action={handleActionPromise}
		/>
	{/if}
	{#if legErrors?.length}
		<div style:font-size={openActionPanel ? '' : 'smaller'} class="alert-info">
			<LegDocumentErrors {form} {legErrors} />
		</div>
	{/if}
	{#if actionResponseErrors?.length}
		{#each actionResponseErrors as error}
			<ErrorAlert {error} />
		{/each}
	{/if}
</div>

{#if jobId}
	<JobStatusChecker {api} asr={router} {jobId} on:stopped={jobStopped}>
		<span slot="first">
			Syncing to Central Dispatch, just a minute...
		</span>
		<span slot="stopped" let:jobLink>
			It's taking an unusually long time to sync to Central Dispatch...
			You could look at the <a href={jobLink}>Job Inspector</a> for more details.
		</span>
		<span slot="waiting">
			Syncing to Central Dispatch, just a minute...
		</span>
		<span slot="completed">
			Done! Synced to Central Dispatch.
		</span>
		<span slot="error" let:jobLink>
			An error occurred while syncing to Central Dispatch. Look at the
			<a href={jobLink}>Job Inspector</a>
			for more details.
		</span>
	</JobStatusChecker>
{/if}

{#if responseErrors?.length}
	{#each responseErrors as error}
		<ErrorAlert {error} />
	{/each}
{/if}
