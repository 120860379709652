<script>
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import ChangeCell from './ChangeCell.svelte'
	import { getSchema } from '@/shared/models.js'
	import { getSchemaKeypathDefinition } from '@/shared/util/get-schema-keypath-definition.js'
	import kebabCase from 'just-kebab-case'

	export let change
	export let parentType
	export let showClipboardCopy
	export let includedById

	$: parentSchema = getSchema(parentType)?.schema

	let schema
	let definition
	let name
	$: {
		if (change.keypath[0] === 'data') {
			schema = parentSchema
			if (change.keypath[1] === 'attributes' || change.keypath[1] === 'relationships') name = change.keypath.slice(2)
		} else if (change.keypath[0] === 'included') {
			const anyType = change.original?.type || change.updated?.type
			name = change.relationship || (anyType && [ anyType ])
			if (change.keypath.length > 3 && change.keypath[2] === 'attributes') name.push(...change.keypath.slice(3))
		} else {
			name = change.keypath
		}
		definition = schema && change.keypath?.[0] === 'data' && change.keypath[1] !== 'relationships' && getSchemaKeypathDefinition(schema, change.keypath.slice(1))
		// upper case first letter in words
		if (name?.[0] === 'legs' && Number.isInteger(name[1])) {
			name[1] = `Leg ${name[1] + 1}`
			name.shift()
		}
		name = name?.length && name.map(n => kebabCase(n.toString()).split('-').map(s => `${s[0]?.toUpperCase()}${s.slice(1)}`).join(' '))
	}
</script>

<style>
	.col-auto {
		width: 1em;
	}
	.col {
		width: calc(calc(100% - 1em) / 2);
	}
	.change-row-name {
		width: 50%;
	}
</style>

<th class="change-row-name ps-0">
	{#each (name || []) as word, index}
		<div class="text-nowrap" class:text-muted={index < (name.length - 1)}>
			{#if index > 0}
				<span style="width: {(index - 1) * 1.5}em; display: inline-block;"></span>
				⮑
			{/if}
			{word}
		</div>
	{/each}
</th>

<td>
	<div class="row">
		<div class="col">
			<ChangeCell isOriginal {includedById} {definition} value={change.original} />
		</div>
		<div class="col-auto">
			→
		</div>
		<div class="col">
			<ChangeCell {includedById} {definition} value={change.updated} />
		</div>
		{#if showClipboardCopy}
			<div class="col-auto pe-3">
				<CopyToClipboard string={change.updated} />
			</div>
		{/if}
	</div>
</td>

<!--
relationship: {
	type: 'array',
	description: `
		If the change was to included data, this will be the relationship path from the root
		resource out to the changed resource, e.g. \`[ 'legs', 1, 'dropoffAddress' ]\`.
	`,
	items: {
		oneOf: [
			{ type: 'number' },
			{ type: 'string' },
		],
	},
},
-->
