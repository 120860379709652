import { saving } from 'jsonapi-svelte-form/mapper'
import { minimumDelay } from '@/lib/delay.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const listAllNotes = async ({ get }, params) => get('/api/v1/notes' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
export const getNoteById = async ({ get }, id, params) => get(`/api/v1/notes/${id}${objectToJsonApiQuery(params)}`).then(response => response.body)
export const listNoteHistory = async ({ get }, id, params) => get(`/api/v1/notes/${id}/histories${objectToJsonApiQuery(params)}`).then(response => ({ ...response.body, params }))

export const createNoteForOpportunity = async ({ post }, opportunityId, form) => {
	const savingDetails = saving(form)
	return minimumDelay(600, post(`/api/v1/opportunities/${opportunityId}/notes`, { body: savingDetails.body }).then(response => response.body))
}

export const patchNote = ({ patch }, data) => minimumDelay(600, patch(`/api/v1/notes/${data.id}`, { body: { data } }).then(response => response.body))

const changeStatus = async (attributes, { patch }, { id, meta }) => patchNote({ patch }, { id, meta, attributes })

export const archiveNote = (...params) => changeStatus({ archived: true }, ...params).then(body => body.data)
export const completeNote = (...params) => changeStatus({ completed: true }, ...params).then(body => body.data)
export const reopenNote = (...params) => changeStatus({ completed: null, archived: null }, ...params).then(body => body.data)
