<script>
	import ConfirmActionSimple from '@/component/ConfirmActionSimple.svelte'
	import { savingFormById } from './open-form-store.js'

	export let row
	export let column

	// open, complete, archive
	const action = name => () => column[name](row)
</script>

<div class="row pe-1">
	{#if column.currentTab === 'archived' || column.currentTab === 'completed'}
		<ConfirmActionSimple
			action="Re-Open"
			confirm="Re-Open"
			iconName="archive"
			width="11em"
			disabled={$savingFormById[row.id]}
			on:click={action('open')}
		/>
	{:else}
		{#if column.currentTab !== 'completed'}
			<div class="col pe-0">
				<ConfirmActionSimple
					action="Complete"
					confirm="Complete"
					iconName="complete"
					width="11em"
					disabled={$savingFormById[row.id]}
					on:click={action('complete')}
				/>
			</div>
		{/if}
		<div class="col-auto ps-1">
			<ConfirmActionSimple
				light
				action="Archive"
				confirm="Archiving"
				iconName="archive"
				width="11em"
				disabled={$savingFormById[row.id]}
				on:click={action('archive')}
			/>
		</div>
	{/if}
</div>
