<script>
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'

	import EditContactPhoneEmail from './EditContactPhoneEmail.svelte'
	import EditContactName from './EditContactName.svelte'
	import AutoQuoteDetails from '@/component/form/opportunity/AutoQuoteDetails.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	export let form
	export let cloning
	export let referrers = []

	$: status = form.data[form.primaryId]?.attributes?.status
	$: referrals = referrers.reduce((map, { id, attributes: { name } }) => {
		map[id] = name
		return map
	}, {})
	$: autoQuoteCampaignId = form.data[form.primaryId]?.relationships?.autoQuoteCampaign?.data?.id
	$: autoQuoteCampaign = form.data[autoQuoteCampaignId]
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		Customer Information
	</div>
	<div class="card-body bg-light pt-2">
		<div class="row">
			<div class="col-xs-12 col-md-6 col-lg-4">
				<EditContactName
					bind:form
					relId={form.primaryId}
					relName="customerContact"
				/>
				<p class="mb-2">Source</p>
				<InputSelectRel
					label="Referral"
					bind:form
					options={referrals}
					id={form.primaryId}
					name="referrer"
					type="referrer"
				/>
				{#if cloning && !form.data[form.primaryId]?.relationships?.referrer?.data?.id}
					<div class="alert alert-warning mb-0 py-1 px-3">
						<small>Be sure to set a referral source, if one is known.</small>
					</div>
				{/if}
				{#if autoQuoteCampaign}
					<div class="row">
						<div class="col-auto color-success">
							<NamedIcon name="autoQuote" />
							Auto Quote
						</div>
						<div class="col">
							<div class="dropdown">
								<button
									class="btn btn-sm btn-justified btn-outline-secondary dropdown-toggle"
									style="width: 100%;"
									type="button"
									id="show-auto-quote-decisions"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									{autoQuoteCampaign.attributes.name}
								</button>
								<div class="dropdown-menu p-2 text-dark text-nowrap">
									<AutoQuoteDetails {form} {autoQuoteCampaign} />
								</div>
							</div>
						</div>
					</div>
				{/if}
			</div>
			<div class="col-xs-12 col-md-6 col-lg-4">
				<EditContactPhoneEmail
					bind:form
					relId={form.primaryId}
					relName="customerContact"
				/>
			</div>
			<div class="col-xs-12 col-md-8 col-lg-4">
				<InputTextArea
					label="Customer Notes"
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'customerNotes' ]}
				/>
				<p class="form-text">This information comes from the user submitted form, but is only internally viewable.</p>
			</div>
		</div>
	</div>
</div>
