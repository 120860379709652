import template from './InspectJob.svelte'
import { getJobById } from '@/lib/controller/job.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'
import { listUsers } from '@/lib/controller/user.js'

export default {
	data: {
		title: 'Inspect Job',
	},
	template,
	resolve: async (_, { jobId }) => {
		const [ jobResponse, { data: users } ] = await Promise.all([
			getJobById({ get }, jobId),
			listUsers({ get }),
		])
		return {
			users,
			form: load(jobResponse, 0),
		}
	},
}
