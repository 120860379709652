<script>
	import { previousState } from '@/service/store/router-state.js'

	import NavHeader from './NavHeader.svelte'

	import Financial from './overview/Financial.svelte'
	import Legs from './overview/Legs.svelte'
	import Overall from './overview/Overall.svelte'
	import Status from './overview/Status.svelte'
	import Timeline from './overview/Timeline.svelte'
	import Vehicles from './overview/Vehicles.svelte'

	export let api
	export let asr
	export let form
	export let users
	export let usersById
	export let ccTransactions
	export let ccTransactionId
	export let referrers
	export let leadAndQuoteEmails

	$: justCreated = $previousState === 'app.opportunities.create'
</script>

<NavHeader {asr} {form} {users} {usersById} {justCreated} activeTab="overview" />

<div class="row">
	<div class="col-md mb-3">
		<Overall {usersById} {referrers} {asr} {form} {leadAndQuoteEmails} {users} />
	</div>
	<div class="col-md mb-3">
		<div class="mb-3">
			<Status {ccTransactions} {ccTransactionId} {leadAndQuoteEmails} {asr} {form} />
		</div>
		<Vehicles {form} />
	</div>
	<div class="col-md mb-3">
		<Financial {form} />
	</div>
</div>

<div class="mb-3">
	<Legs {asr} {form} />
</div>

<Timeline {asr} {api} {usersById} opportunityId={form.primaryId} />
