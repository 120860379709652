import template from '@/component/BlankUiView.svelte'
import { redirectIfLackingPermission } from '@/lib/redirect-if-lacking-permission.js'
import { GENERATE_REPORTS } from '@/shared/value/user-permissions.js'

export default {
	data: {
		title: 'Custom Reports',
	},
	template,
	defaultChild: 'list',
	resolve: async () => redirectIfLackingPermission(GENERATE_REPORTS),
}
