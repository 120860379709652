<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import CustomReportForm from '@/component/form/CustomReportForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { updateCustomReport } from '@/lib/controller/custom-report.js'

	export let asr
	export let api
	export let form

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateCustomReport(api, form)
			.then(response => { form = response })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.customReports', null, { inherit: false })}>
		My Custom Reports
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Edit {form.data[form.primaryId]?.attributes?.name || 'N/A'}
	</span>
</FormHeaderMain>

<CustomReportForm bind:form />

<FormErrorsOther {form} />

<FormCommitButton {form} on:click={save} />
