<script>
	import { MakeDiff } from 'jsonapi-svelte-form'

	export let form
	export let legId

	const atts = [
		'Type',
	]
	const rels = [
		'Terminal',
		'Contact',
		'Address',
	]

	const swap = (relOrAtt, key) => {
		const pickup = `pickup${key}`
		const dropoff = `dropoff${key}`
		const copy = form.data[legId][relOrAtt][pickup]
		if (form.data[legId][relOrAtt][dropoff]) form.data[legId][relOrAtt][pickup] = form.data[legId][relOrAtt][dropoff]
		else delete form.data[legId][relOrAtt][pickup]
		if (copy) form.data[legId][relOrAtt][dropoff] = copy
		else delete form.data[legId][relOrAtt][dropoff]
	}

	const flipLeg = makeDiff => () => {
		for (const key of atts) swap('attributes', key)
		for (const key of rels) swap('relationships', key)
		makeDiff(form, legId)
	}
</script>

<MakeDiff let:makeDiff>
	<button class="btn btn-sm btn-outline-primary px-0" on:click={flipLeg(makeDiff)}>
		<span class="fa fa-sync"></span> Flip
	</button>
</MakeDiff>
