<script>
	import SimpleNavBar from '@/component/SimpleNavBar.svelte'
	import { currentUser } from '@/service/store/current-user.js'
	export let label
	export let asr
	$: items = [
		{
			path: $currentUser?.attributes?.role === 'accounting' && 'app.accounting',
			label: 'Accounting',
		},
		{ label },
	]
</script>

<SimpleNavBar
	{asr}
	{items}
>
	<slot></slot>
</SimpleNavBar>
