import template from './Recently.svelte'
import { dateRangeQueryParams } from './_date-range-from-query.js'

export default {
	data: {
		title: 'Recently Picked Up Report',
	},
	template,
	querystringParameters: dateRangeQueryParams,
	resolve: async (_, querystringParams) => ({ querystringParams, legEventName: 'received' }),
}
