import { keyBy } from '@/shared/util/key-by.js'

export const mapCompaniesTogether = (onTrackResponse, centralDispatchResponse) => {
	const cdIdToCompany = {}
	for (const [ centralDispatchId, text ] of (centralDispatchResponse?.body?.data?.json || [])) {
		const [ name, location ] = text.split(/\s*=>\s*/)
		cdIdToCompany[centralDispatchId] = { centralDispatchId, name, location }
	}
	const companiesNotInCd = []
	const includedById = keyBy(onTrackResponse.body.included || [], 'id')
	for (const company of (onTrackResponse.body.data || [])) {
		if (cdIdToCompany[company.attributes.centralDispatchId]) {
			cdIdToCompany[company.attributes.centralDispatchId].onTrackId = company.id
			cdIdToCompany[company.attributes.centralDispatchId].email = includedById[company.relationships?.contact?.data?.id]?.attributes?.emailPrimary
			cdIdToCompany[company.attributes.centralDispatchId].inactive = company.attributes.inactive
			cdIdToCompany[company.attributes.centralDispatchId].blocked = company.attributes.blocked
		} else {
			const address = includedById[company.relationships?.address?.data?.id]?.attributes
			companiesNotInCd.push({
				centralDispatchId: company.attributes.centralDispatchId,
				name: company.attributes.name,
				email: includedById[company.relationships?.contact?.data?.id]?.attributes?.emailPrimary,
				location: address
					? `${address.city}, ${address.state}`
					: 'N/A',
				onTrackId: company.id,
				inactive: company.attributes.inactive,
				blocked: company.attributes.blocked,
			})
		}
	}
	return [
		...Object.values(cdIdToCompany),
		...companiesNotInCd,
	].sort((a, b) => ('' + a.name).localeCompare(b.name))
}
