import template from '@/component/BlankUiView.svelte'
import { redirect } from '@/lib/redirect-if-missing-params.js'

export default {
	data: {
		title: 'Sent Emails',
	},
	template,
	resolve: redirect([ 'sentEmailId' ], 'app'),
}
