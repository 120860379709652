<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { opportunityLegStatusToIcon } from '@/lib/opportunity-leg-status-to-icon.js'

	const LEG_NUMBER = /(.+) \(Leg (\d+)\)/
	const stateToTitle = {
		unpost: 'Unposted',
		post: 'Posted',
		assign: 'Not Signed',
		dispatch: 'Dispatched',
		receive: 'Picked Up',
		deliver: 'Delivered',
	}

	export let value

	$: rows = (value?.split(/s*;\s*/) || [])
		.map(row => {
			const match = LEG_NUMBER.exec(row)
			if (match) return {
				value: match[1],
				num: match[2],
			}
			else return {
				value: row,
			}
		})
</script>

{#each rows as row, index}
	<p class="mb-0 text-nowrap">
		{#if row.num}
			<span class="badge bg-light border border-secondary text-secondary" style="width: 3ch;">{row.num}</span>
		{/if}
		{#if opportunityLegStatusToIcon[row.value]}
			<NamedIcon name={opportunityLegStatusToIcon[row.value]} />
			{#if stateToTitle[row.value]}
				{stateToTitle[row.value]}
			{/if}
		{:else}
			{row.value}
		{/if}
	</p>
{/each}
