import { userRoles } from '../value/user-roles.js'

export const type = 'user'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				name: {
					type: 'string',
					description: 'The display name of the user, e.g. "John Smith".',
				},
				active: {
					type: 'boolean',
					description: 'If this is not set to true, the user will not be able to log in or view or edit any resource.',
				},
				phone: {
					description: 'Official phone number for this user, e.g. their office number.',
					type: 'string',
				},
				email: {
					type: 'string',
				},
				password: {
					type: 'string',
				},
				role: {
					description: 'The role of the user. Primarily used as a display toggle, but may affect functionality as well.',
					type: 'string',
					enum: Object.keys(userRoles),
				},
				leadAssignmentFactor: {
					description: 'The number which determines how many leads will be auto-assigned to this user.',
					type: 'number',
					format: 'double',
				},
				permissions: {
					$ref: '#/components/schemas/user-permissions',
				},
				lastLogin: {
					description: 'System-managed date which is updated at login for the user.',
					type: 'string',
					format: 'date-time',
				},
			},
		},
	},
}
