export const ASSIGN_LISTING = 'assign-listing'
export const FETCH_CARRIER = 'fetch-carrier'
export const LOGIN = 'login'
export const MARK_RECEIVED = 'mark-received'
export const MATCH_VEHICLE = 'match-vehicle'
export const POST_FORM = 'post-form'
export const UNPOST_FORM = 'unpost-form'
export const UPDATE_LISTING = 'update-listing'

export const cdRequestPurpose = {
	[ASSIGN_LISTING]: 'Assign a Posted Order',
	[FETCH_CARRIER]: 'Fetch Carrier Details',
	[LOGIN]: 'Login to CD',
	[MARK_RECEIVED]: 'Mark as Picked-Up',
	[MATCH_VEHICLE]: 'Get Vehicle Details',
	[POST_FORM]: 'Post a Form', // TODO this should really go away for a more specific name
	[UNPOST_FORM]: 'Unpost a Form',
	[UPDATE_LISTING]: 'Update a Posted or Dispatched Order',
}
