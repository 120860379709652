<script>
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import * as templates from '@/shared/value/system-email-templates.js'

	export let row
	export let column
	export let includedById

	$: isSystem = Object.values(templates).includes(row.id)
</script>

<PlainLink {row} {column} {includedById}/>

{#if isSystem}
	<span style="color: #bbb;">
		<span class="fa fa-cogs"></span>
	</span>
{/if}
