<script>
	import ParentLink from '@/component/MenuLeftParentLink.svelte'
	import * as links from '@/lib/high-level-links.js'
	import { system } from '@/service/store/system.js'
	import { currentUser } from '@/service/store/current-user.js'

	export let asr

	$: development = [ 'develop', 'local' ].includes($system?.stage)
	$: permissions = $currentUser?.attributes?.permissions || {}
	$: role = $currentUser?.attributes?.role
	$: menuItems = [
		permissions.mcsStaff && {
			label: links._parents.opportunities,
			parents: [
				{ ...links.leads },
				{ ...links.quotes },
				{ ...links.orders },
				{ ...links.delivered },
			],
		},
		permissions.mcsStaff && {
			label: links._parents.services,
			parents: [
				links.carriers,
				links.shippers,
				links.terminals,
			],
		},
		permissions.mcsStaff && {
			label: links._parents.internal,
			parents: [
				permissions.manageReferrers && (role === 'accounting' ? links.referrers : links.referrersList),
				links.reports,
				permissions.manageUsers && links.users,
				permissions.manageApp && links.systemSettings,
			].filter(Boolean),
		},
		/*
		{
			label: 'Things2',
			parents: [
				{
					label: 'Dashboard2',
					icon: 'chart-pie',
					children: [
						{
							label: 'Stuff1',
							link: '#',
						},
						{
							label: 'Stuff2',
							active: true,
							link: '#',
						},
					],
				},
			],
		},
		*/
	]
		.filter(Boolean)
		.map(menu => {
			if (menu.parents) {
				menu.parents = menu.parents.filter(Boolean)
			}
			return menu
		})
		.filter(menu => menu && menu.parents && menu.parents.length)
</script>

<nav class="navbar navbar-light navbar-vertical navbar-expand-md">
	<div class="d-flex align-items-center">
		{#if menuItems.length}
			<div class="toggle-icon-wrapper">
				<button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="" data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation">
					<span class="navbar-toggle-icon">
						<span class="toggle-line"></span>
					</span>
				</button>
			</div>
		{/if}

		<a class="navbar-brand" href="#/app">
			<div class="d-flex align-items-center pt-3">
				<span class="font-sans-serif" class:text-danger={development}>
					{#if development}<span style="font-size: 1em;">⚠️</span>{/if}
					{$system.name}
				</span>
			</div>
		</a>
	</div>

	<div class="navbar-collapse collapse" id="navbarVerticalCollapse">
		<div class="navbar-vertical-content scrollbar" style="height: calc(100vh - 8.3125rem) !important;">
			<ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
				{#each menuItems as { label, parents }, index}
					<li class="nav-item">
						<div class="row navbar-vertical-label-wrapper mb-2 {index ? 'mt-3' : ''}">
							<div class="col-auto navbar-vertical-label">
								{label}
							</div>
							<div class="col ps-0">
								<hr class="mb-0 navbar-vertical-divider">
							</div>
						</div>
						{#each parents as { label, icon, count, link, params, expanded, children, paramsToBeActive }}
							<ParentLink {asr} {children} {link} {params} {paramsToBeActive} on:click={() => { expanded = !expanded }} {expanded}>
								<div class="d-flex align-items-center">
									<span class="nav-link-icon">
										<span class="fas fa-{icon}"></span>
									</span>
									<span class="nav-link-text ps-1">{label}</span>
								</div>
							</ParentLink>
							{#if children && children.length}
								<ul class="nav collapse {expanded ? 'show' : 'false'}">
									{#each children as { link, params, label, active }}
										<li class="nav-item">
											<a class="nav-link" href={asr.makePath(link, params, { inherit: false })} class:active>
												<div class="d-flex align-items-center">
													<span class="nav-link-text ps-1">
														{label}
													</span>
												</div>
											</a>
										</li>
									{/each}
								</ul>
							{/if}
						{/each}
					</li>
				{/each}
			</ul>
		</div>
	</div>
</nav>
