<script>
	import Card from '@/component/atom/Card.svelte'
	import Notes from '@/component/dashboard-panel/Notes.svelte'

	export let user
	export let api
	export let asr
</script>

<h1>Dashboard</h1>

{#if !Object.keys(user.attributes?.permissions || {}).length}
	<p>Welcome to the MCS OnTrack website!</p>
	<Card title="User Details">
		<p>
			It looks like your user doesn't have any useful permissions
			yet, so contact an administrator to get access!
		</p>
	</Card>
{:else}
	<Notes {api} {asr} />
{/if}
