<script>
	import InputType from '@/component/atom/InputType.svelte'

	export let form
	export let relId
	export let relName
	export let readonly

	$: contactId = form.data[relId]?.relationships?.[relName]?.data?.id

	const createContact = create => create({ relId, relName, isArray: false, type: 'contact' })
</script>

<div class="row mb-2">
	<div class="col">
		<p class="mb-2">Contact</p>
		<InputType
			type="text"
			icon="email"
			label="Email"
			bind:form
			{readonly}
			id={contactId}
			onCreate={createContact}
			keypath={[ 'attributes', 'emailPrimary' ]}
		/>
		<InputType
			type="text"
			icon="phone"
			label="Primary"
			bind:form
			{readonly}
			id={contactId}
			onCreate={createContact}
			keypath={[ 'attributes', 'phonePrimary' ]}
		/>
		<InputType
			type="text"
			icon="phone"
			label="Alt1"
			bind:form
			{readonly}
			id={contactId}
			onCreate={createContact}
			keypath={[ 'attributes', 'phoneTwo' ]}
		/>
		<InputType
			type="text"
			icon="phone"
			label="Alt2"
			bind:form
			{readonly}
			id={contactId}
			onCreate={createContact}
			keypath={[ 'attributes', 'phoneThree' ]}
		/>
		<InputType
			type="text"
			icon="phone"
			label="Alt3"
			bind:form
			{readonly}
			id={contactId}
			onCreate={createContact}
			keypath={[ 'attributes', 'phoneFour' ]}
		/>
	</div>
</div>
