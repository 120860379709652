<script>
	import { onMount } from 'svelte'
	import NotesTable from '@/component/table/Notes.svelte'
	import CardTableFooter from '@/component/CardTableFooter.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { listAllNotes } from '@/lib/controller/note.js'
	import { listUsers } from '@/lib/controller/user.js'
	import { currentUser } from '@/service/store/current-user.js'

	import NavTab from './notes/NavTab.svelte'

	export let api
	export let asr

	let filterDateType = 'exact'
	let filterDateStart = ''
	let filterDateEnd = ''
	let currentTab = 'active'
	let notesResponse = {}
	let assignableUsers
	let loading = true

	const notesFetchParams = {
		sort: 'attributes.reminder',
		'filter[hasArchived]': false,
		'filter[hasCompleted]': false,
		'relationships.assigned.data.id': $currentUser.id,
	}
	const fetchNotes = changeTab => {
		loading = true
		Promise
			.all([
				listAllNotes(api, notesFetchParams),
				!assignableUsers && listUsers(api, { filter: { 'attributes.active': 'true' } }),
			])
			.then(responses => {
				notesResponse = responses[0]
				if (responses[1]) assignableUsers = responses[1].data
				if (changeTab) currentTab = changeTab
				loading = false
			})
	}

	onMount(() => { fetchNotes() })

	const tabUpdater = tab => () => {
		notesFetchParams['filter[hasArchived]'] = tab === 'archived'
		notesFetchParams['filter[hasCompleted]'] = tab === 'completed'
		fetchNotes(tab)
	}

	const dateUpdater = () => {
		if (filterDateStart) notesFetchParams['filter[start]'] = filterDateStart
		else delete notesFetchParams['filter[start]']
		if (filterDateEnd) notesFetchParams['filter[end]'] = filterDateEnd
		else if (filterDateType === 'exact') notesFetchParams['filter[end]'] = filterDateStart
		else delete notesFetchParams['filter[end]']
		fetchNotes()
	}

	const sortUpdater = ({ event, keypath, ascending }) => {
		event.preventDefault()
		event.stopPropagation()
		notesFetchParams.sort = `${ascending ? '-' : ''}${keypath}`
		fetchNotes()
	}
</script>

<style>
	.input-group .form-control { width: 11em; }
	:global(.dashboard-panel-notes td.note-archive) { padding-right: 0.35rem !important; }
</style>

<div class="dashboard-panel-notes">
	<div class="card mb-3">
		<div class="card-header py-2">
			<div class="row">
				<div class="col-auto">
					<div class="row">
						<div class="col-auto pt-2">
							<h5>
								<NamedIcon name="note" />
								Notes
							</h5>
						</div>
						<div class="col-auto">
							<ul class="nav nav-tabs">
								<NavTab {currentTab} name="Active" on:click={tabUpdater('active')} />
								<NavTab {currentTab} name="Completed" on:click={tabUpdater('completed')} />
								<NavTab {currentTab} name="Archived" on:click={tabUpdater('archived')} />
							</ul>
						</div>
					</div>
				</div>
				<div class="col-auto">
					<div class="row">
						<div class="col-auto">
							<div class="row mt-2">
								<div class="col-auto">
									<p class="mb-0">Reminder Filter</p>
								</div>
								<div class="col-auto">
									<div class="form-check form-check-inline mb-0">
										<input class="form-check-input" type="radio" id="note-filter-exact" bind:group={filterDateType} value="exact" on:change={dateUpdater}>
										<label class="form-check-label" for="note-filter-exact">Exact</label>
									</div>
									<div class="form-check form-check-inline mb-0">
										<input class="form-check-input" type="radio" id="note-filter-range" bind:group={filterDateType} value="range" on:change={dateUpdater}>
										<label class="form-check-label" for="note-filter-range">Range</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-auto">
							<div class="row">
								{#if filterDateType === 'exact'}
									<div class="col-auto pt-2">
										<div class="input-group input-group-sm">
											<span class="input-group-text">
												<NamedIcon name="date" />
											</span>
											<input type="date" class="form-control" bind:value={filterDateStart} on:change={dateUpdater}>
										</div>
									</div>
								{:else}
									<div class="col-auto pt-2">
										<div class="input-group input-group-sm">
											<span class="input-group-text">
												<NamedIcon name="date" />
												&nbsp;
												Start
											</span>
											<input type="date" class="form-control" bind:value={filterDateStart} on:change={dateUpdater}>
										</div>
									</div>
									<div class="col-auto pt-2">
										<div class="input-group input-group-sm">
											<span class="input-group-text">
												<NamedIcon name="date" />
												&nbsp;
												End
											</span>
											<input type="date" class="form-control" bind:value={filterDateEnd} on:change={dateUpdater}>
										</div>
									</div>
								{/if}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body p-0">
			<NotesTable
				sortable
				{api}
				{asr}
				{loading}
				{assignableUsers}
				sort={notesFetchParams.sort}
				bind:currentTab
				bind:notesResponse
				on:clicksort={event => sortUpdater(event.detail)}
			/>
		</div>
		<CardTableFooter
			data={notesResponse.data}
			included={notesResponse.included}
			links={notesResponse.links}
			errors={notesResponse.errors}
			request={notesResponse.request}
		/>
	</div>
</div>
