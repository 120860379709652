import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	company: false,
	opportunity: false,
	user: false,
}

export const type = 'qb-sync'

export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the QuickBook sync.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				requestId: {
					description: 'The internal audit log request identifier.',
					type: 'string',
				},
				maximumDateInclusive: {
					description: 'The date used to mark history items as synced.',
					type: 'string',
					format: 'date-time',
				},
				modifiedCount: {
					description: 'The returned value from the MongoDB update operation. (A system-managed property.)',
					type: 'number',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				company: {
					description: 'The company related to this sync.',
					...jsonApiRelationshipSingular('company'),
				},
				opportunity: {
					description: 'The opportunity related to this sync.',
					...jsonApiRelationshipSingular('opportunity'),
				},
				user: {
					description: 'OnTrack user who did the sync.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
