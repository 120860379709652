<script>
	import OpportunityReport from '@/component/report/OpportunityReport.svelte'
	import { onMount } from 'svelte'
	import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'
	import { get } from '@/lib/json-fetch.js'
	import { everyOpportunityColumn } from '@/component/report/opportunity/every-opportunity-column.js'

	export let asr
	export let companyId

	$: columns = everyOpportunityColumn({ asr })

	const orderedColumns = [
		'id',
		'status',
		'customerName',
		'vehiclesStacked',
		'pickupContract',
		'dropoffContract',
	]

	let loading = true
	let data
	let errors
	onMount(() => {
		get('/api/v1/reports/groupedOpportunities/id' + objectToJsonApiQuery({
			filter: {
				minDate: '1990-01-01T00:00:00.000Z',
				minDateType: 'created',
				maxDate: '2050-01-01T00:00:00.000Z',
				maxDateType: 'created',
				carrierId: companyId,
			},
			include: [ 'legs' ],
			sort: 'meta.created',
			page: {
				limit: 25,
			},
		}))
			.then(response => {
				data = response.body.data
				loading = false
			})
			.catch(e => { errors = e })
	})
</script>

<OpportunityReport
	{asr}
	{columns}
	{data}
	{errors}
	{loading}
	{orderedColumns}
	params={ { companyId } }
	querystringParams={ { companyId } }
	label="Carrier Orders"
	file="carrier-orders"
	currentSort="created"
/>
