<script>
	import DateString from '@/component/atom/DateString.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import InputRadio from '@/component/atom/InputRadio.svelte'
	import { dateType as allowedDateTypes } from '@/shared/value/date-type.js'
	import { paymentBusinessDays } from '@/shared/value/payment-business-days.js'
	import { router } from '@/service/router.js'

	const MAX_ADDITIONAL_TERMS_CHARS = 60

	export let form
	export let companyData
	export let legId
	export let legErrors

	$: dsid = form.data[legId].attributes.centralDispatchDsid
	$: listingId = form.data[legId].attributes.centralDispatchListingId
	$: cdUrl = dsid
		? `https://site.centraldispatch.com/protected/dispatch/broker?do=edit&dsid=${dsid}`
		: (
			listingId
				? `https://site.centraldispatch.com/protected/listing/post-listing?id=${listingId}`
				: ''
		)

	$: legDates = form.data[legId].attributes.dates || {}
	$: additionalTermsLength = form.data[legId].attributes.centralDispatchAdditionalTerms?.length || 0
	$: companyId = form.data[legId].relationships?.shipper?.data?.id
	$: companyCentralDispatchId = companyData?.byId[companyId]?.attributes?.centralDispatchId
</script>

<p class="mb-2">
	{#if cdUrl}
		Posted to CD
		{#if form.data[legId].attributes.dates?.post}
			on
			<DateString date={form.data[legId].attributes.dates?.post} />
		{/if}
		as
		<ExternalLink url={cdUrl}>
			{#if dsid}
				DSID {dsid}
			{:else}
				Listing {listingId}
			{/if}
		</ExternalLink>
	{:else if !legErrors?.length}
		Ready to be posted to Central Dispatch.
	{/if}
</p>

{#if legDates.assign}
	<p class="mb-2">
		{#if companyId}
			Assigned to
			<a href={router.makePath('app.companies.edit.companyId.overview', { companyId }, { inherit: false })}>
				{companyData.byId[companyId]?.attributes?.name || `INCORRECTLY ASSIGNED TO ${companyId}`}
			</a>
		{:else}
			<span class="text-danger">
				Leg marked as assigned but not linked to a carrier! If there is a job running, please wait for it.
				If the job just finished, try reloading the page. If this warning shows up a lot, please let a
				developer know.
			</span>
		{/if}
		{#if companyCentralDispatchId}
			(View on <ExternalLink url="https://site.centraldispatch.com/protected/rating/client-snapshot?id={companyCentralDispatchId}">CD</ExternalLink>)
		{/if}
	</p>
{/if}

<div class="row">
	{#if legDates.post}
		<div class="col-auto">
			<InputType
				label="Carrier Pay"
				labelWidth="120px"
				type="double"
				icon="money"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'carrierPay' ]}
			/>
			{#if legDates.assign}
				<div class="ps-3">
					<p class="mb-0">Days after receipt of BOL that a check will be sent:</p>
					<InputRadio
						optionsList={Object.keys(paymentBusinessDays).map(days => ({ key: parseInt(days, 10), title: paymentBusinessDays[days] }))}
						bind:form
						id={legId}
						keypath={[ 'attributes', 'businessDays' ]}
					/>
				</div>
			{/if}
		</div>
	{/if}

	{#if legDates.post && !legDates.assign}
		<div class="col-auto">
			<InputType
				type="date"
				labelWidth="18ch"
				label="First Available"
				icon="date"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'firstAvailablePickup' ]}
			/>
		</div>
	{/if}

	{#if legDates.assign}
		<div class="col-auto">
			<InputType
				type="date"
				label="Pickup Date"
				labelWidth="120px"
				icon="date"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'pickupDate' ]}
			/>
			<div class="ps-3">
				<InputRadio
					options={allowedDateTypes}
					bind:form
					id={legId}
					keypath={[ 'attributes', 'pickupDateType' ]}
				/>
			</div>
		</div>

		<div class="col-auto">
			<InputType
				type="date"
				label="Delivery Date"
				labelWidth="120px"
				icon="date"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'dropoffDate' ]}
			/>
			<div class="ps-3">
				<InputRadio
					options={allowedDateTypes}
					bind:form
					id={legId}
					keypath={[ 'attributes', 'dropoffDateType' ]}
				/>
			</div>
		</div>
	{/if}
</div>

{#if form.data[legId].attributes.status === 'post'}
	<div class="row">
		<div class="col">
			<InputTextArea
				label="Additional Terms"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'centralDispatchAdditionalTerms' ]}
			/>
			<small class:text-danger={additionalTermsLength > MAX_ADDITIONAL_TERMS_CHARS}>
				You're at {additionalTermsLength} of {MAX_ADDITIONAL_TERMS_CHARS} characters.
			</small>
		</div>
	</div>
{/if}

{#if legDates.assign}
	<div class="row">
		<div class="col-xs-12 col-md-6 col-lg-4">
			<InputType
				label="Driver First Name"
				labelWidth="140px"
				type="text"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'driverFirstName' ]}
			/>
		</div>
		<div class="col-xs-12 col-md-6 col-lg-4">
			<InputType
				label="Driver Last Name"
				labelWidth="140px"
				type="text"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'driverLastName' ]}
			/>
		</div>
		<div class="col-xs-12 col-md-6 col-lg-4">
			<InputType
				label="Driver Phone"
				labelWidth="100px"
				type="text"
				bind:form
				id={legId}
				keypath={[ 'attributes', 'driverPhone' ]}
			/>
		</div>
	</div>
	<InputTextArea
		label="Special Instructions"
		bind:form
		id={legId}
		keypath={[ 'attributes', 'centralDispatchSpecialInstructions' ]}
	/>
{/if}

{#if legDates.receive || legDates.deliver}
	<div class="row mt-3">
		{#if legDates.receive}
			<div class="col-auto">
				<InputType
					type="date"
					label="Actual Pickup"
					labelWidth="140px"
					icon="date"
					bind:form
					id={legId}
					keypath={[ 'attributes', 'actualPickup' ]}
				/>
			</div>
		{/if}
		{#if legDates.deliver}
			<div class="col-auto">
				<InputType
					type="date"
					label="Actual Delivery"
					labelWidth="140px"
					icon="date"
					bind:form
					id={legId}
					keypath={[ 'attributes', 'actualDropoff' ]}
				/>
			</div>
		{/if}
	</div>
{/if}
