export const opportunityFilterableDateLabel = {
	firstAvailablePickup: 'First Available Pickup',
	lastAvailablePickup: 'Last Available Pickup',
	firstAvailableDropoff: 'First Available Delivery',
	quoted: 'Quoted',
	ordered: 'Ordered',
	held: 'Held',
	cancelled: 'Cancelled',
	dispatched: 'Dispatched (Any Leg)',
	receivedContract: 'Picked-Up, Contractual (Any Leg)',
	receivedActual: 'Picked-Up, Actual (Any Leg)',
	deliveredContract: 'Delivered, Contractual (Any Leg)',
	deliveredActual: 'Delivered, Actual (Any Leg)',
	// TODO these were never quite completed, not sure if needed at this point
	// firstPickupContract: 'First Leg P/U (Contract)',
	// firstPickupActual: 'First Leg P/U (Actual)',
	// lastDropoffContract: 'Last Leg Deliver (Contract)',
	// lastDropoffActual: 'Last Leg Deliver (Actual)',
}

export const opportunityFilterableDateExists = {
	firstAvailablePickup: 'The opportunity level "first available pickup" date must be set.',
	lastAvailablePickup: 'The opportunity level "last available pickup" date must be set.',
	firstAvailableDropoff: 'The opportunity level "first available dropoff" date must be set.',
	quoted: 'The opportunity level "quoted" date must be set.',
	ordered: 'The opportunity level "ordered" date must be set.',
	held: 'The opportunity level "held" date must be set.',
	cancelled: 'The opportunity level "cancelled" date must be set.',
	dispatched: 'The first leg must be fully dispatched.',
	receivedContract: 'The first leg must have a contractual pick up date set.',
	receivedActual: 'The first leg must be marked as actually picked up.',
	deliveredContract: 'The last leg must have a contractual delivery date set.',
	deliveredActual: 'The last leg must be marked as actually delivered.',
}

export const keyToAttributesMapper = {
	firstAvailablePickup: 'firstAvailablePickup',
	lastAvailablePickup: 'lastAvailablePickup',
	firstAvailableDropoff: 'firstAvailableDropoff',
	quoted: 'quoted',
	ordered: 'ordered',
	held: 'held',
	cancelled: 'cancelled',
	dispatched: 'legDates.firstDispatched',
	receivedContract: 'legDates.firstReceivedContract',
	receivedActual: 'legDates.firstReceivedActual',
	deliveredContract: 'legDates.firstDeliveredContract',
	deliveredActual: 'legDates.lastDeliveredContract',
}
