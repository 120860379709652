<script>
	import { Form } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import { markdownToHtml } from '@/shared/util/markdown-to-html.js'

	export let form

	$: markdownString = form.data[form.primaryId]?.attributes?.markdown || ''
	$: html = markdownString && markdownToHtml(markdownString)

	$: agreementLabelString = form.data[form.primaryId]?.attributes?.agreementLabel || ''
	$: agreementLabelHtml = agreementLabelString && markdownToHtml(agreementLabelString)

	$: emailPrefixString = form.data[form.primaryId]?.attributes?.emailConfirmationPrefix || ''
	$: emailPrefixHtml = emailPrefixString && markdownToHtml(emailPrefixString)
</script>

<Form bind:form>
	<div class="card mb-3">
		<div class="card-header py-2">
			<div class="row">
				<div class="col">
					Email Confirmation
				</div>
			</div>
		</div>
		<div class="card-body bg-light py-2">
			<div class="row mb-3">
				<div class="col-xs-12 col-lg-6">
					<InputType
						type="text"
						label="Email Subject"
						labelWidth="12em"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'emailConfirmationSubject' ]}
					/>
					<p class="form-text">
						This is the subject of the email that the customer gets after
						signing this document.
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-xs-12 col-lg-6">
					<InputTextArea
						label="Email Prefix"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'emailConfirmationPrefix' ]}
					/>
					<p class="form-text">
						This will get inserted into the email that the customer gets after
						signing this document.
					</p>
				</div>
				<div class="col-xs-12 col-lg-6">
					<p>Preview</p>
					<div class="card">
						<div class="card-body">
							{@html emailPrefixHtml}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card mb-3">
		<div class="card-header py-2">
			<div class="row">
				<div class="col">
					Signable Template Information
				</div>
			</div>
		</div>
		<div class="card-body bg-light py-2">
			<div class="row">
				<div class="col-md-4">
					<InputType
						type="text"
						label="Name"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'name' ]}
					/>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-xs-12 col-lg-6">
					<InputTextArea
						label="Signable Content"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'markdown' ]}
					/>
				</div>
				<div class="col-xs-12 col-lg-6">
					<p>Preview</p>
					<div class="card">
						<div class="card-body">
							{@html html}
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-xs-12 col-lg-6">
					<InputTextArea
						label="Agreement Label"
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'agreementLabel' ]}
					/>
				</div>
				<div class="col-xs-12 col-lg-6">
					<p>Preview</p>
					<div class="card">
						<div class="card-body">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="signableTemplateAgreementLabel">
								<label class="form-check-label" for="signableTemplateAgreementLabel">
									{@html agreementLabelHtml}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</Form>
