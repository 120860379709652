export const generateNoteData = ({ note, opportunityId, assignedId, reminder, fileRels }) => {
	const data = {
		id: 'GID0',
		type: 'note',
		attributes: {
			note,
		},
		relationships: {
			resource: {
				data: {
					id: opportunityId,
					type: 'opportunity',
				},
			},
		},
	}
	if (assignedId) {
		data.relationships.assigned = {
			data: {
				id: assignedId,
				type: 'user',
			},
		}
	}
	if (fileRels?.length) {
		data.relationships.files = {
			data: fileRels,
		}
	}
	if (reminder) data.attributes.reminder = reminder
	return data
}
