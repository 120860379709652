<script>
	import ReferrersNavbar from './_ReferrersNavbar.svelte'
	import Table from '@/component/table/Referrers.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'

	export let asr
	export let data
	export let links
	export let included
	export let loading
	export let request
	export let errors
	export let params
</script>

<ReferrersNavbar
	{asr}
	label="List"
/>

<TableCardWrapper
	table={Table}
	small
	{asr}
	{data}
	makeLink={({ keypath, ascending }) => asr.makePath('app.referrers.list', { sort: `${ascending ? '-' : ''}${keypath}` })}
	sort={params.sort}
	included={[ ...included, ...data ]}
	{links}
	{request}
	{errors}
	bind:loading
	on:loadNext
>
	<div class="row">
		<div class="col-auto mt-2">
			<h4>Referrer Sources</h4>
		</div>
	</div>
</TableCardWrapper>
