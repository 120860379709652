<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { nameToFontAwesomeIcon } from '@/lib/name-to-font-awesome-icon.js'

	const typeToName = {
		opportunity: 'Opportunity',
		company: 'Company',
	}
	// No longer than 4 chars and one period!
	const typeToShortName = {
		opportunity: 'Opp.',
		company: 'Comp.',
	}

	export let row
	export let column
</script>

<style>
	small {
		position: relative;
		top: -0.6em;
	}
</style>

{#if nameToFontAwesomeIcon[row?.type]}
	<span class:text-muted={column.iconMuted}>
		<NamedIcon name={row.type} />
	</span>
{/if}

{#if column.showName && row?.type}
	<br>
	<small class="text-muted">
		{(column.shortName ? typeToShortName[row.type] : typeToName[row.type]) || row.type}
	</small>
{/if}
