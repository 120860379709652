<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let row
	$: hasSignableTemplate = row?.relationships?.signableTemplate
</script>

<style>
	span {
		color: #A5ACB2;
	}
</style>

{#if hasSignableTemplate}
	<span>
		<NamedIcon name="signableDocument" />
	</span>
{/if}
