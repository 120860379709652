<script>
	import InputTextDisplayOnly from '@/component/atom/InputTextDisplayOnly.svelte'
	import { addressStates } from '@/shared/value/address-states.js'
	export let address
</script>

<InputTextDisplayOnly labelWidth="85px" label="Line 1" value={address?.attributes?.line1 || ''} />
<InputTextDisplayOnly labelWidth="85px" label="Line 2" value={address?.attributes?.line2 || ''} />
<InputTextDisplayOnly labelWidth="85px" label="City" value={address?.attributes?.city || ''} />
<InputTextDisplayOnly labelWidth="85px" label="State" value={addressStates[address?.attributes?.state] || ''} />
<InputTextDisplayOnly labelWidth="85px" label="Zip" value={address?.attributes?.zip || ''} />
