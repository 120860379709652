export const jobPaths = {
	'example/generate-report': 'Simple demo of executing long running jobs.',
	'central-dispatch/action': 'Process an action for Central Dispatch.',
	'central-dispatch/handle-event': 'Handles a request to the Central Dispatch event listener',
	'central-dispatch/import-carrier': 'Import a carrier from Central Dispatch.',
	'central-dispatch/pull': 'Pull updates from Central Dispatch.',
	'central-dispatch/push': 'Push updates to Central Dispatch.',
	'email/accepted': 'Carrier accepts an assigned order.',
	'email/cancelled': 'Carrier marks an order as cancelled.',
	'email/declined': 'Carrier declines an assigned order.',
	'email/delivered': 'Carrier marks an order as delivered.',
	'email/picked-up': 'Carrier marks an order as picked up.',
	'email/received': 'Handle unknown received emails.',
	'opportunity/process-lead': 'Incoming leads are processed before being assigned.',
	'opportunity/send-auto-quote-email': 'Send an auto-quote campaign email, either success or error.',
}
