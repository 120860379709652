<script>
	export let href
	export let external = false
	export let newTab = false
	// TODO could drive `external` from the `href` property entirely
</script>

{#if href}
	<a {href} target={(newTab || external) ? '_blank' : undefined}>
		<slot />{#if newTab || external}<span style="font-size: 60%; position: relative; top: -6px;"><span class="fa fa-external-link-alt"></span></span>{/if}
	</a>
{:else}
	<slot />
{/if}
