<script>
	import { Field } from 'jsonapi-svelte-form'
	import InputToggleBare from './InputToggleBare.svelte'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate

	export let label
	export let labelWidth = '85px'
	export let enabledText = ''
	export let disabledText = ''
	export let bottomSpacing = '2'

	$: elementId = [ id, ...keypath ].join('.')
</script>

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	<InputToggleBare
		{id}
		{keypath}
		{readonly}
		value={!!value}
		{label}
		{labelWidth}
		{enabledText}
		{disabledText}
		{bottomSpacing}
		on:input={ event => { (form.data[id] || onCreate(create)); set(event.target.value === 'true') } }
	/>
	{#each errors as error}
		<div class="invalid-feedback">{error.title}</div>
	{/each}
</Field>
