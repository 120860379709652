/**
 * @typedef {Object} HistoryUserAgentDetail
 * @property {string} name - Human-readable name of field.
 * @property {string} version - Version of field.
 * @property {string} [versionName] - Additional version detail.
 */

/**
 * @typedef {Object} HistoryUserAgent
 * @property {HistoryUserAgentDetail} [browser] - Browser details.
 * @property {HistoryUserAgentDetail} [os] - Operating System details.
 * @property {HistoryUserAgentDetail} [engine] - Browser engine details.
 * @property {string} [platform.type] - The platform, e.g. "mobile" or "desktop".
 */

export default {
	description: 'The parsed and cleaned-up user-agent information, using [bowser](https://github.com/lancedikson/bowser).',
	type: 'object',
	additionalProperties: false,
	properties: {
		browser: {
			type: 'object',
			properties: {
				name: {
					description: 'The browser name, e.g. "Internet Explorer".',
					type: 'string',
				},
				version: {
					description: 'The browser version, e.g. "11.0".',
					type: 'string',
				},
			},
		},
		os: {
			type: 'object',
			properties: {
				name: {
					description: 'The operating system name, e.g. "Windows".',
					type: 'string',
				},
				version: {
					description: 'The operating system version, e.g. "NT 6.3".',
					type: 'string',
				},
				versionName: {
					description: 'The operating system named version, e.g. "8.1".',
					type: 'string',
				},
			},
		},
		platform: {
			type: 'object',
			properties: {
				type: {
					description: 'The platform type, e.g. "desktop" or "mobile".',
					type: 'string',
				},
			},
		},
		engine: {
			type: 'object',
			properties: {
				name: {
					description: 'The browser engine name, e.g. "Trident".',
					type: 'string',
				},
				version: {
					description: 'The browser engine version, e.g. "7.0".',
					type: 'string',
				},
			},
		},
	},
}
