import { router } from '@/service/router.js'
import { makeRequestCache } from '@/lib/make-request-cache.js'
import { commitForm } from '@/lib/form-committer.js'
import { minimumDelay } from '@/lib/delay.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'
import { currentUser } from '@/service/store/current-user.js'

const setUser = response => {
	currentUser.update(() => response.body.data)
	return response.body.data
}

export const createUser = async ({ post }, { email, name }) => minimumDelay(900, post('/api/v1/users', {
	body: { meta: { email, name } },
}))
export const updateUser = async ({ put }, form) => minimumDelay(900, commitForm({ form, request: put, url: `/api/v1/users/${form.primaryId}` }))

/**
 *
 * @param {Function} get - API GET.
 * @param {Object} [params] - Additional filter params.
 * @return {Promise<Array<Object>>} - The users list.
 */
export const listUsers = async ({ get }, params) => get('/api/v1/users' + objectToJsonApiQuery(params)).then(response => ({ ...response.body, params }))
export const getUserById = async ({ get }, id, params) => get(`/api/v1/users/${id}` + objectToJsonApiQuery(params)).then(response => response.body)

/**
 * Get the current user, if the requesting user is logged in, and cache it so it can
 * be fetched many times during router initialization.
 * @type {function({ get: function }): Promise<Object>}
 */
export const getCurrentUser = makeRequestCache(
	router,
	async ({ get }) => get('/api/v1/user').then(setUser),
)

export const updateUserLeadAssignment = async ({ patch }, userId, leadAssignmentFactor) => patch(`/api/v1/users/${userId}`, {
	body: {
		data: {
			type: 'user',
			id: userId,
			attributes: { leadAssignmentFactor: leadAssignmentFactor || null },
		},
	},
})

export const getHistoricLeadAssignments = async ({ get }, params) => get('/api/v1/reports/historicLeadAssignments' + objectToJsonApiQuery(params))
