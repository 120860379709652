export default {
	type: 'object',
	required: [
		'jobPath',
	],
	properties: {
		jobPath: {
			type: 'string',
			enum: [ 'central-dispatch/pull' ],
		},
	},
}
