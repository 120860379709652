<script>
	import NumberString from '@/component/atom/NumberString.svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import financialNumber from 'financial-number'
	import ReportTableRowCell from './ReportTableRowCell.svelte'
	import ReportTableHeaderCell from './ReportTableHeaderCell.svelte'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'

	export let asr
	export let columnOverrides
	export let columns
	export let currentSort
	export let errors
	export let id
	export let loading
	export let orderedColumns
	export let querystringParams
	export let rows
	export let updateSort

	let highlightedColumn
	let highlightedCellIndex

	$: actualOrderedColumns = orderedColumns.filter(name => columns[name])

	$: summedFooterRow = !loading && rows?.length && actualOrderedColumns.map(name => {
		if (!columns[name]?.value || columns[name].summable === false || columns[name].style !== 'number' || columns[name].sumRows === false) return { sum: '', name }
		let sum = financialNumber('0')
		for (const row of rows) sum = sum.plus(String(row.columns[name].value || 0))
		sum = sum.toString()
		sum = sum.includes('.') ? parseFloat(sum) : parseInt(sum, 10)
		return {
			sum,
			name,
			link: columns[name].sumHref,
		}
	})
	$: showSummedFooter = summedFooterRow?.length && summedFooterRow?.find(row => row.sum !== '')
</script>

<style>
	.report-table-wrapper {
		min-width: 100%;
		display: inline-block;
	}
	table > tbody > tr:hover {
		background-color: #e9eef5;
	}
</style>

{#if actualOrderedColumns.length !== orderedColumns.length}
	<div class="alert alert-danger mt-3">
		<strong>Developer Error</strong> These ordered column names do not have
		assigned column definitions: {orderedColumns.filter(name => !columns[name]).join(', ')}
	</div>
{/if}

<div class="report-table-wrapper">
	<div class="card">
		<div class="card-body p-1">
			<table class="table table-sm mb-0" {id}>
				<thead class="bg-200 text-900">
				<tr>
					{#each actualOrderedColumns as columnName, index}
						<ReportTableHeaderCell
							{asr}
							{querystringParams}
							{updateSort}
							align={index === 0 ? 'start' : (columns[columnName].align || (columns[columnName].style === 'number' ? 'end' : 'start'))}
							{currentSort}
							sortKey={columnName}
							label={columnOverrides?.[columnName]?.label || columns[columnName]?.label}
							style={columnOverrides?.[columnName]?.style || columns[columnName]?.style}
						/>
					{/each}
				</tr>
				</thead>
				<tbody>
				{#if loading}
					<tr>
						<td colspan={actualOrderedColumns.length}>
							<p class="my-2 text-muted text-muted text-center">
								Loading...
							</p>
						</td>
					</tr>
				{:else if errors?.length}
					<tr class="text-start">
						<td colspan={actualOrderedColumns.length}>
							{#each errors as error}
								<ErrorAlert {error} />
							{/each}
						</td>
					</tr>
				{:else if !rows}
					<tr>
						<td colspan={actualOrderedColumns.length}>
							<p class="my-2 text-muted text-center">
								Select a date range to generate the report.
							</p>
						</td>
					</tr>
				{:else if !rows.length}
					<tr>
						<td colspan={actualOrderedColumns.length}>
							<p class="my-2 text-muted text-center">
								No data found for specified date range.
							</p>
						</td>
					</tr>
				{:else}
					{#each rows as row (row.id)}
						<tr>
							{#each actualOrderedColumns as columnName, index}
								<ReportTableRowCell
									on:mouseenter={() => { highlightedColumn = columnName; highlightedCellIndex = row.id }}
									on:mouseleave={() => { highlightedColumn = false; highlightedCellIndex = false }}
									highlightColumn={highlightedColumn === columnName}
									highlightCell={highlightedCellIndex === row.id}
									align={index === 0 ? 'start' : (columns[columnName].align || (columns[columnName].style === 'number' ? 'end' : 'start'))}
									stacked={columns[columnName].stacked}
									format={columns[columnName].format}
									column={row.columns[columnName]}
								/>
							{/each}
						</tr>
					{/each}
				{/if}
				</tbody>
				{#if showSummedFooter}
					<tfoot class="bg-200 text-900">
					<tr>
						{#each summedFooterRow as { sum, name, link }, index}
							<td class:text-end={index === 0 || columns[name].align === 'end' || columns[name].style === 'number'}>
								{#if index === 0}
									Total
								{:else}
									<MaybeLink href={link}>
										<NumberString value={sum} />
									</MaybeLink>
								{/if}
							</td>
						{/each}
					</tr>
					</tfoot>
				{/if}
			</table>
		</div>
	</div>
</div>
