import { isMonday, startOfDay, endOfDay, startOfMonth, endOfMonth, getDay, setHours, subDays, subMonths, subBusinessDays, addBusinessDays } from 'date-fns'

const dayNumberToDayName = {
	0: 'Sunday',
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
	6: 'Saturday',
}

const dateToHumanReadable = date => date && date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
const queryStringToPseudoDate = string => new Date(`${string}T12:00:00.000Z`)
const dateToApiFormatted = date => date.toISOString()
const getDayName = date => date?.getTime() && dayNumberToDayName[getDay(date)] || 'N/A'
const dateToQueryParam = date => setHours(date, 12).toISOString().split('T')[0]

const getHumanReadableDateRange = (querystringParams, minCreateDate, maxUpdatedDate) => {
	if (!minCreateDate) return 'Select a date range to generate the report.'

	let humanReadable = [ 'today', 'up1biz', 'up5biz' ].includes(querystringParams?.dateRange)
		? 'Today'
		: dateToHumanReadable(minCreateDate)
	humanReadable += ` (a ${getDayName(minCreateDate)}) at early morning (just past midnight), through`
	if (querystringParams?.dateRange === 'yesterday') {
		humanReadable += ' yesterday evening just before midnight'
	} else if (querystringParams?.dateRange === 'tomorrow') {
		humanReadable += ' tomorrow evening just before midnight'
	} else if (maxUpdatedDate) {
		humanReadable += ` ${dateToHumanReadable(maxUpdatedDate)} just before midnight.`
	} else {
		humanReadable += ' now.'
	}
	return humanReadable
}

export const dateRangeQueryParams = [
	'dateRange',
	'dateMin',
	'dateMax',
]

export const dateRangeFromQuery = ({ querystringParams, now }) => {
	let minCreateDate
	let maxUpdatedDate

	if (querystringParams?.dateRange === 'today') {
		minCreateDate = startOfDay(now)
		maxUpdatedDate = now
	} else if (querystringParams?.dateRange === 'yesterday') {
		if (isMonday(now)) minCreateDate = startOfDay(subBusinessDays(now, 1))
		else minCreateDate = startOfDay(subDays(now, 1))
		maxUpdatedDate = endOfDay(subDays(now, 1))
	} else if (querystringParams?.dateRange === 'back1biz') {
		minCreateDate = startOfDay(subBusinessDays(now, 1))
		maxUpdatedDate = now
	} else if (querystringParams?.dateRange === 'back5biz') {
		minCreateDate = startOfDay(subBusinessDays(now, 5))
		maxUpdatedDate = now
	} else if (querystringParams?.dateRange === 'tomorrow') {
		minCreateDate = endOfDay(now)
		maxUpdatedDate = endOfDay(addBusinessDays(now, 1))
	} else if (querystringParams?.dateRange === 'up1biz') {
		minCreateDate = now
		maxUpdatedDate = endOfDay(addBusinessDays(now, 1))
	} else if (querystringParams?.dateRange === 'up5biz') {
		minCreateDate = now
		maxUpdatedDate = endOfDay(addBusinessDays(now, 5))
	} else if (querystringParams?.dateRange === 'thismonth') {
		minCreateDate = startOfMonth(now)
		maxUpdatedDate = now
	} else if (querystringParams?.dateRange === 'lastmonth') {
		minCreateDate = startOfMonth(subMonths(now, 1))
		maxUpdatedDate = endOfMonth(subMonths(now, 1))
	} else if (querystringParams?.dateRange === 'custom') {
		const start = querystringParams.dateMin && queryStringToPseudoDate(querystringParams.dateMin)
		if (start?.getTime()) minCreateDate = startOfDay(start)
		const end = querystringParams.dateMax && queryStringToPseudoDate(querystringParams.dateMax)
		if (end?.getTime()) maxUpdatedDate = endOfDay(end)
	}

	return {
		type: querystringParams?.dateRange,
		min: minCreateDate,
		max: maxUpdatedDate,
		humanReadable: getHumanReadableDateRange(querystringParams, minCreateDate, maxUpdatedDate),
		queryParam: {
			dateMin: minCreateDate && dateToQueryParam(minCreateDate),
			dateMax: maxUpdatedDate && dateToQueryParam(maxUpdatedDate),
		},
		apiDates: {
			min: minCreateDate && dateToApiFormatted(minCreateDate),
			max: maxUpdatedDate && dateToApiFormatted(maxUpdatedDate),
		},
	}
}
