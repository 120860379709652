export const type = 'signable-template'

export default {
	description: 'Templates of documents signable by customers or carriers, such as Terms & Conditions.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the signable template.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'name',
			],
			properties: {
				name: {
					description: 'A human-readable name for the template, displayed in the card header.',
					type: 'string',
				},
				markdown: {
					description: 'The markdown content of the signable template.',
					type: 'string',
				},
				emailConfirmationPrefix: {
					description: 'The markdown text to use before the signable document emailed to the signer.',
					type: 'string',
				},
				emailConfirmationSubject: {
					description: 'The subject of the email.',
					type: 'string',
				},
				agreementLabel: {
					description: 'The markdown label next to the agreement checkbox.',
				},
			},
		},
	},
}
