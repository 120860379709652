import { makeRequestCache } from '@/lib/make-request-cache.js'
import { router } from '@/service/router.js'
import { get, post, put } from '@/lib/json-fetch.js'
import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

export const listOpportunities = async params => get('/api/v1/opportunities' + objectToJsonApiQuery(params)).then(response => response.body)

/**
 *
 * @type {(function(): Promise<*>)|*}
 */
export const getOpportunityById = makeRequestCache(
	router,
	async ({ id }) => get(`/api/v1/opportunities/${id}`)
		.then(response => ({
			data: response.body.data,
			included: response.body.included,
		})),
)

export const createOpportunity = async ({ data, included }) => post('/api/v1/opportunities', { body: { data, included } })

export const updateOpportunity = async ({ data, included }) => put(`/api/v1/opportunities/${data.id}`, { body: { data, included } })
