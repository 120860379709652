import { BadRequest, ForbiddenRequest } from '@/lib/exceptions.js'
import { opportunity as opportunityTag, autoQuote as autoQuoteTag } from '@/lib/tags.js'
import { requestToHistory } from '@/lib/request-to-history.js'
import { createOpportunityFromLead } from '@/lib/controller/opportunity/create-opportunity-from-lead.js'

// This is not actually secure, but it doesn't really need to be strongly secure, since
// it's just sort of making sure some external process doesn't accidentally
// start creating leads. I hope...
const HARD_CODED_API_TOKENS = [
	// The Zapier one
	'ce3457d170cd42d8ab37251ffb2f87e0',
	// Other ones that can be handed out to e.g. PODS
	'd384d3e3c04d4b0da89b4e88658ea73c',
	'4e493fb6b8c149c18066b848e047b961',
	'c8bbfd04dc0c4ba49938883c1950980a',
	'403c53c29520410d98f47b38f8a64043',
	'1d6975c7905d48f68cccff594e869651',
	'60fdff1d8d6d4de3aba17fb8a7695f28',
]

export const summary = `
	Create an OnTrack Opportunity.
`

export const description = `
	Creates an opportunity if enough valid properties are provided. Processing
	the opportunity happens out-of-band of the request, so things like calculating
	miles, duplicate detection, and calculation of the tariff happen outside
	this request.

	Details on the opportunity are interpreted as gracefully as possible on this
	request. For example, if an address is not recognizable the request will still
	be a success and a person will attempt to interpret the address manually.

	However, if a valid Auto-Quote Campaign is specified on the request, the
	opportunity details are less graceful: if there are not enough valid details
	for the Auto-Quote calculation to run, such as an invalid origin or destination
	address, an error will be returned.

	If the opportunity is valid and the API Token used on the request has
	appropriate access roles, an access token (and pre-formatted URL) will
	be provided on the response. This gives access to a simplified version of the created
	opportunity, and can be used to check on the status and the
	auto-quoted tariff, if configured and available.
`

export const tags = [
	autoQuoteTag,
	opportunityTag,
]

export const requestBody = {
	description: `
		The opportunity to create. If the provided opportunity does not contain
		sufficient valid details, the request will be rejected.
	`,
	content: {
		'application/json': {
			schema: {
				$ref: '#/components/schemas/incoming-lead',
			},
		},
	},
}

export const responses = {
	202: {
		description: 'The opportunity was accepted, but processing is not complete.',
		content: {
			'application/json': {
				schema: {
					type: 'object',
					properties: {
						data: {
							type: 'object',
							properties: {
								opportunityId: {
									type: 'string',
									description: 'The identifier of the created opportunity.',
								},
								accessToken: {
									type: 'string',
									description: 'If policy configurations make it available, this is a token used to access this single opportunity.',
								},
							},
						},
						links: {
							type: 'object',
							properties: {
								opportunity: {
									type: 'string',
									description: 'The URL used to access the opportunity, if policy configurations provide an access token.',
								},
							},
						},
						meta: {
							type: 'object',
							properties: {
								warnings: {
									description: 'Things that may prevent some functionality, such as Auto-Quote emails, but will still create leads.',
									type: 'array',
									items: {
										$ref: '#/components/schemas/error',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	400: {
		description: 'The opportunity had invalid data and was not accepted.',
		content: {
			'application/json': {
				schema: {
					type: 'object',
					properties: {
						errors: {
							description: 'Errors about the invalid opportunity properties.',
							type: 'array',
							items: {
								$ref: '#/components/schemas/error',
							},
						},
					},
				},
			},
		},
	},
	403: {
		description: 'The request did not contain a valid API token.',
	},
}

export const handler = async (services, req) => {
	if (!HARD_CODED_API_TOKENS.includes(req.headers['X-API-TOKEN']?.trim().toLowerCase())) throw new ForbiddenRequest('The provided API token was invalid.')
	if (!Object.keys(req.body || {}).length) throw new BadRequest('No opportunity data provided.')
	const { opportunityId, accessToken, warnings, createErrors, jobId, jobErrors } = await createOpportunityFromLead(services, req.body || {}, requestToHistory(req))
	console.log('Results from creating opportunity', {
		opportunityId,
		warnings,
		createErrors,
		jobId,
		jobErrors,
	})
	const body = {
		data: { opportunityId, accessToken },
		links: {},
		meta: {},
	}

	if (warnings?.length) body.meta.warnings = warnings

	if (createErrors?.length || jobErrors?.length) body.errors = []
	if (createErrors?.length) body.errors.push(...createErrors)
	if (jobErrors?.length) body.errors.push(...jobErrors)

	if (jobId) {
		body.data.relationships = {
			job: {
				data: { type: 'job', id: jobId },
			},
		}
		body.links.job = `${services.config.get('BASE_URL')}/api/v1/jobs/${jobId}`
	}

	if (accessToken) body.links.opportunity = `${services.config.get('BASE_URL')}/api/v1/opportunity?opportunityId=${opportunityId}&accessToken=${accessToken}`

	if (body.errors?.length) console.warn('Errors found on request.', body.errors)
	return {
		json: true,
		status: body.errors?.length ? 400 : 202,
		body,
	}
}
