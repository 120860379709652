<script>
	export let mimeType

	const ms = {
		excel: 'fa-file-excel',
		powerpoint: 'fa-file-powerpoint',
		word: 'fa-file-word',
	}

	const mimeTypeToIcon = {
		'application/pdf': 'fa-file-pdf',
		'application/zip': 'fa-file-archive',

		// MS Office files
		'application/vnd.ms-excel': ms.excel,
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ms.excel,
		'application/vnd.ms-powerpoint': ms.powerpoint,
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ms.powerpoint,
		'application/msword': ms.word,
		'application/vnd-msword': ms.word,
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ms.word,
	}

	const mimeBaseToIcon = {
		audio: 'fa-file-audio',
		image: 'fa-file-image',
		video: 'fa-file-video',
	}

	$: base = mimeType?.split('/')?.[0]
	$: icon = mimeTypeToIcon[mimeType] || mimeBaseToIcon[base] || 'fa-file'
</script>

<span class="fa {icon}"></span>
