<script>
	import dlv from 'dlv'

	export let row
	export let includedById

	$: customerId = dlv(row, 'relationships.customer.data.id')
	$: shipper = includedById && includedById[customerId] || { attributes: {} }
	$: name = shipper.attributes.name || `${shipper.attributes.firstName || ''} ${shipper.attributes.lastName || ''}`.trim()
	$: phones = shipper.relationships && shipper.relationships.phones && shipper.relationships.phones.data && shipper.relationships.phones.data
		.map(({ id }) => includedById[id] && includedById[id].attributes)
</script>

{#if shipper.attributes.companyName}
	<strong class="text-muted">{shipper.attributes.companyName}</strong>
	<br>
{/if}

{name}

{#if phones}
	{#each phones as { type, value }}
		<br>
		<span class="fas fa-phone"></span>
		{value}
	{/each}
{/if}

{#if shipper.attributes.emails}
	{#each shipper.attributes.emails as email}
		<br>
		<span class="fas fa-envelope"></span>
		{email}
	{/each}
{/if}
