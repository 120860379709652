<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import CompanyForm from '@/component/form/CompanyForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { createCompany } from '@/lib/controller/company.js'
	import { router } from '@/service/router.js'

	export let asr
	export let api
	export let form

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createCompany(api, form, response => router.go('app.companies.edit.companyId', { companyId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.companies', null, { inherit: false })}>
		Companies
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Create Company
	</span>
</FormHeaderMain>

<CompanyForm bind:form />

<FormErrorsOther {form} />

<FormCommitButton label="Create Company" {form} on:click={create} />
