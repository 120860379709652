import template from '@/component/BlankUiView.svelte'
import { redirect } from '@/lib/redirect-if-missing-params.js'

export default {
	data: {
		title: 'Inspect Job',
	},
	template,
	resolve: redirect([ 'jobId' ], 'app.systemSettings.jobs.list'),
}
