<script>
	import { createEventDispatcher } from 'svelte'

	export let error
	const dispatch = createEventDispatcher()
	const toggle = () => {
		dispatch('yolo', error.meta.stacktrace)
	}
</script>

<h5>
<small class="text-muted">{error.status || 'N/A'}</small>
</h5>
{error.code || 'Unknown Error'}

<p>{error.title || 'Client side error.'}</p>
{#if error.detail || error.message}
	<p class="mb-0">{error.detail || error.message}</p>
{/if}
{#if error.code === 'InvalidResource'}
	<p class="mb-0"><strong>Resource ID: {error.meta.id}</strong></p>
	{#each error.meta.details as detail}
		<pre>{JSON.stringify(detail, undefined, 4)}</pre>
	{/each}
{:else if error?.meta?.stacktrace}
	<button class="btn btn-sm btn-secondary" on:click={toggle}>
		Toggle Stacktrace
	</button>
{/if}
