<script>
	import { onMount } from 'svelte'
	import { subDays } from 'date-fns'
	import Icon from 'svelte-awesome'
	import ErrorList from '@/component/ErrorList.svelte'
	import HistoricLeadAssignments from '@/component/charts/HistoricLeadAssignments.svelte'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { userRoles } from '@/shared/value/user-roles.js'
	import { get, patch } from '@/lib/json-fetch.js'
	import { keyBy } from '@/shared/util/key-by.js'
	import { updateUserLeadAssignment, getHistoricLeadAssignments } from '@/lib/controller/user.js'
	import { minimumDelay } from '@/lib/delay.js'
	import { AUTO_ASSIGNMENT_MULTIPLIER } from '@/shared/value/auto-assignment-multiplier.js'

	export let asr
	export let users

	$: filteredUsers = users.filter(u => u.attributes.leadAssignmentFactor || (u.attributes.role === 'sales' && u.attributes.active))
	$: usersById = keyBy(filteredUsers, 'id')

	let saved
	let userIdToUpdatedFactor = {}
	$: unsavedChanges = Object.keys(userIdToUpdatedFactor).length
	const updateFactor = userId => ({ target: { value } }) => {
		saved = false
		value = value && parseFloat(value)
		if (!value) value = undefined
		if (usersById[userId].attributes.leadAssignmentFactor !== value) userIdToUpdatedFactor[userId] = value
		else {
			delete userIdToUpdatedFactor[userId]
			userIdToUpdatedFactor = { ...userIdToUpdatedFactor }
		}
	}

	let errorsByUserId = {}
	let saving
	const save = () => {
		saved = false
		saving = true
		minimumDelay(
			500,
			Promise
				.all(
					Object
						.keys(userIdToUpdatedFactor)
						.map(
							userId => updateUserLeadAssignment({ patch }, userId, userIdToUpdatedFactor[userId])
								.then(response => ({ userId, ...response.body }))
								.catch(response => ({ userId, ...response.body })),
						),
				),
		).then(responses => {
			errorsByUserId = {}
			const updatedById = {}
			for (const { userId, data, errors } of responses) {
				if (errors?.length) errorsByUserId[userId] = errors
				if (data) updatedById[userId] = data
			}
			users = users.map(u => updatedById[u.id] ? updatedById[u.id] : u)
			userIdToUpdatedFactor = {}
			saving = false
			saved = true
		})
	}

	let historicLeadAssignmentsReport
	onMount(() => {
		const minDate = subDays(new Date(), 45).toISOString().split('T')[0]
		getHistoricLeadAssignments({ get }, { filter: { minDate } }).then(out => {
			historicLeadAssignmentsReport = out?.body?.meta?.report
		})
	})
</script>

<style>
	input {
		width: 4em;
	}
</style>

<div class="card">
	<div class="card-header">
		<h4>
			<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>System</a>
			<Icon data={faAngleRight} scale="1" class="ms-2 me-2"/>
			Lead Assignment
		</h4>
	</div>
</div>

<div class="row gx-3">
	<div class="col-xs-12 col-lg-9 col-xl-6">
		<div class="card mt-3">
			<div class="card-header pb-0">
				<div class="row">
					<div class="col-auto">
							<span class="fa fa-user-circle"></span>
							Assign Lead Factors
					</div>
					<div class="col">
						<p class="text-muted">Includes all active users with sales role.</p>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<table class="table table-sm">
					<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Assignment Factor</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{#each filteredUsers as user}
						<tr>
							<td>{user.attributes.name || 'N/A'}</td>
							<td>{user.attributes.email}</td>
							<td>
								<input
									readonly={saving}
									type="text"
									value={user.attributes.leadAssignmentFactor || ''}
									on:input={updateFactor(user.id)}
									class="form-control form-control-sm"
								>
							</td>
							<td>
								{#if !user.attributes.active}
									<span class="text-warning">Inactive</span>
								{/if}
								{#if user.attributes.role !== 'sales'}
									<span class="text-warning">Non-Sales User</span>
									({userRoles[user.attributes.role] || user.attributes.role || 'No Role'})
								{/if}
							</td>
						</tr>
					{/each}
					</tbody>
				</table>
				<div class="px-3" style="max-width: 60ch;">
					<!-- The exact logic of this is in `get-auto-assigned-sales-person.js` -->
					<p>
						When changes are saved, the users assignment factor is changed immediately. For each new lead coming
						in, the most recent leads are grabbed and checked to calculate assignment.
					</p>
					<p>
						The number of leads is equal to the total factors, multiplied by {AUTO_ASSIGNMENT_MULTIPLIER}. For example,
						if there are 2 sales users, one with a factor of 3 and one with a factor of 2, the total leads fetched
						will be (3+2)*{AUTO_ASSIGNMENT_MULTIPLIER} or {(3 + 2) * AUTO_ASSIGNMENT_MULTIPLIER}. This would mean
						the assignment factor changes will be fully in effect after {(3 + 2) * AUTO_ASSIGNMENT_MULTIPLIER} more
						leads come in.
					</p>
					<p>
						To make the changes take longer, use larger assignment factor numbers, e.g. instead of 3 and 2 use 6 and 4,
						or 24 and 16. To make them take less time, use smaller numbers, e.g. instead of 3 and 2 use 0.3 and 0.2,
						and so on.
					</p>
				</div>
				<div class="p-3 pt-0">
					<button class="btn btn-primary me-3" disabled={!unsavedChanges || saving} on:click={save}>
						<span class="fa fa-save"></span>
						Save Changes
					</button>
					{#if saving}
						<span class="fas fa-spinner fa-pulse"></span>
					{/if}
					{#if saved}
						{#if errorsByUserId && Object.keys(errorsByUserId).length}
							<span class="text-danger"><span class="fa fa-exclamation-circle fa-lg"></span></span>
							Errors while saving!
						{:else}
							<span class="text-success"><span class="fa fa-check-square fa-lg"></span></span>
							Changes saved!
						{/if}
					{/if}
				</div>
			</div>
		</div>

		{#if errorsByUserId && Object.keys(errorsByUserId).length}
			<div class="card mt-3">
				<div class="card-header pb-0">
					<strong>Errors</strong>
				</div>
				<div class="card-body">
					<p>Please try reloading the page and saving again. If this happens again, please let an admin or developer know!</p>
					{#each Object.keys(errorsByUserId) as userId}
						<ErrorList errors={errorsByUserId[userId]} />
					{/each}
				</div>
			</div>
		{/if}
	</div>
	<div class="col-xs-12 col-xl-6">
		<div class="card mt-3">
			<div class="card-body">
				{#if historicLeadAssignmentsReport?.data?.length}
					<HistoricLeadAssignments
						data={historicLeadAssignmentsReport.data}
						users={historicLeadAssignmentsReport.users}
						height="500"
					/>
				{:else if historicLeadAssignmentsReport}
					<p class="text-center">No historic lead assignment data found.</p>
				{:else}
					<p class="text-center">
						Loading...
					</p>
				{/if}
			</div>
		</div>
	</div>
</div>
