<script>
	import dlv from 'dlv'
	import { idString } from '@/lib/id-string.js'
	import { highlightedWord } from '@/service/store/highlighter.js'
	import { makeHighlightedText } from '@/lib/make-highlighted-text.js'

	export let row
	export let column
	export let includedById

	$: resource = includedById[dlv(row, column.keypath)]
	$: text = column.relKeypath === 'id'
		? idString(resource)
		: dlv(resource, column.relKeypath)
	$: highlight = makeHighlightedText($highlightedWord, text)
</script>

{#if $$slots.before}
	<slot name="before" {resource} />
{/if}

{#if highlight?.html}
	{@html highlight.html}
{:else if highlight?.text}
	{highlight.text}
{:else if text}
	{text}
{:else}
	<span class="{column.altClass || ''}">{column.alt || ''}</span>
{/if}

{#if $$slots.after}
	<slot name="after" {resource} />
{/if}
