import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	agreementEmail: false,
	signableTemplate: false,
	signableDocumentVisitSigned: false,
	opportunity: false,
}

export const type = 'signable-document'

export default {
	description: 'Per-opportunity document signable by customers or carriers, such as Terms & Conditions.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the signable document.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'signerEmailAddress',
				'signerName',
				'name',
				'markdown',
				'agreementLabel',
			],
			properties: {
				hashedSecret: {
					description: `
						The hash of the token set on the email sent to the customer, which is used to
						give evidence that e.g. no person at Mr Car Shipper could easily sign the document
						for the customer, only the person who had access to the email address can access it.
					`,
					type: 'string',
				},
				signerEmailAddress: {
					description: 'Optional email address displayed on Signable Document page for customer.',
					type: 'string',
				},
				signerName: {
					description: 'Optional human-name displayed on Signable Document page for customer.',
					type: 'string',
				},
				signed: {
					description: 'The datetime that the customer "signed" this document.',
					type: 'string',
					format: 'date-time',
				},
				name: {
					description: `
						The immutable document name, which is a copy of the signable
						template name at time of creation of this document.
					`,
					type: 'string',
				},
				emailConfirmationPrefix: {
					description: 'The immutable markdown text to use before the signable document emailed to the signer.',
					type: 'string',
				},
				emailConfirmationSubject: {
					description: 'The subject of the email.',
					type: 'string',
				},
				markdown: {
					description: `
						The immutable markdown content, which is a copy of the signable
						template markdown at time of creation of this document.
					`,
					type: 'string',
				},
				agreementLabel: {
					description: `
						The immutable markdown label, which is a copy of the signable template's
						label markdown at time of creation of this document.
					`,
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				agreementEmail: {
					description: 'Reference to the email sent immediately after the customer submits their agreement.',
					...jsonApiRelationshipSingular('sent-email'),
				},
				signableTemplate: {
					description: 'The template that this signable document was created from.',
					...jsonApiRelationshipSingular('signable-template'),
				},
				signableDocumentVisitSigned: {
					description: 'The specific visit during which the document was signed.',
					...jsonApiRelationshipSingular('signable-document-visit'),
				},
				opportunity: {
					description: 'The opportunity related to this signable document.',
					...jsonApiRelationshipSingular('opportunity'),
				},
			},
		},
	},
}
