<script>
	export let title
	export let bodyClass
	let clazz = ''
	export { clazz as class }
</script>

<style>
	.missing-header {
		border-top-left-radius: 0.375rem;
		border-top-right-radius: 0.375rem;
	}
</style>

<div class="card {clazz}">
	<slot name="header" />
	{#if title}
		<div class="card-header">
			<h6 class="mb-0">{title}</h6>
		</div>
	{/if}
	<div class="card-body bg-light {bodyClass || ''}" class:missing-header={!title && !$$slots.header}>
		<slot />
	</div>
</div>
