<script>
	import JobStatusIcon from '@/component/JobStatusIcon.svelte'
	export let row
	$: status = row?.attributes?.status
	$: hasErrors = row?.attributes?.executions?.[row?.attributes?.executions?.length - 1]?.errors?.length > 0
</script>

<style>
	span {
		/* to center under the "Status" column */
		margin-left: 1em;
	}
</style>

<span>
	<JobStatusIcon {status} {hasErrors} />
</span>
