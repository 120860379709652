export const legEventNames = {
	unpost: 'The posting was removed from Central Dispatch.',
	cancel: 'The posting had been dispatched but has been cancelled.',
	decline: 'The posting had been dispatched but was declined by the carrier.',
	post: 'The leg is posted to Central Dispatch.',
	assign: 'The leg is assigned to a carrier.',
	remind: 'A reminder email was sent to the carrier assigned to this leg.',
	dispatch: 'The carrier signed the contract for this leg.',
	receive: 'The vehicles were marked as picked up for this leg, either by this legs carrier or by MCS staff.',
	deliver: 'The vehicles were marked as delivered for this leg, either by this legs carrier or by MCS staff.',
	undeliver: 'The vehicles were previously marked as delivered, but are now marked as undelivered by MCS staff.',
}
