<script>
	import { onMount } from 'svelte'
	import OpportunitiesTable from '@/component/table/Opportunities.svelte'
	import { listOpportunities } from '@/lib/controller/opportunity.js'

	export let api
	export let asr
	export let query

	let loading = true
	let response = {}

	onMount(() => {
		listOpportunities(api, { filter: { status: 'delivered', search: query }, sort: '-meta.updated' })
			.then(r => {
				response = r
			})
			.then(() => {
				loading = false
			})
	})
</script>

{#if loading || response.data?.length}
	<OpportunitiesTable
		{asr}
		{loading}
		{...response}
	/>
{:else}
	<p>No results found.</p>
{/if}
