const formatQuery = (input, deeper) => {
	const wrap = key => encodeURIComponent(deeper ? `[${key}]` : key)
	const encode = (key, value) => wrap(key) + '=' + encodeURIComponent(value)

	return Object
		.keys(input || {})
		.map(key => {
			if (Array.isArray(input[key])) {
				return encode(key, input[key].join(','))
			} else if (typeof input[key] === 'object') {
				return formatQuery(input[key], true)
					.map(param => wrap(key) + param)
			} else if (input[key] !== undefined) {
				return encode(key, input[key])
			}
		})
		.flat()
		.filter(Boolean)
}

export const objectToJsonApiQuery = obj => {
	const string = formatQuery(obj).join('&')
	return string
		? '?' + string
		: string
}
