<script>
	import Card from '@/component/atom/Card.svelte'
	import financialNumber from 'financial-number'

	export let form

	$: status = form.data[form.primaryId]?.attributes?.status

	const sum = (total, dollar) => total.plus(dollar || 0)

	$: vehicleAtts = (form.data[form.primaryId]?.relationships?.vehicles?.data || [])
		.map(({ id }) => form.data[id]?.attributes || {})
	$: totalVehiclesOriginalCarrierPay = vehicleAtts
		.map(atts => atts.originalCarrierPay)
		.reduce(sum, financialNumber(0))
	$: totalVehiclesOriginalBrokerFee = vehicleAtts
		.map(atts => atts.originalBrokerFee)
		.reduce(sum, financialNumber(0))
	$: totalVehiclesTariffAdjustment = vehicleAtts
		.map(atts => atts.tariffAdjustment)
		.reduce(sum, financialNumber(0))
	$: totalOriginalTariff = totalVehiclesOriginalCarrierPay.plus(totalVehiclesOriginalBrokerFee).plus(totalVehiclesTariffAdjustment)

	$: legAtts = (form.data[form.primaryId]?.relationships?.legs?.data || [])
		.map(({ id }) => form.data[id]?.attributes || {})
	$: allCarrierPaysSet = legAtts.every(l => l.carrierPay)
	$: totalFinalCarrierPay = legAtts
		.map(atts => atts.carrierPay)
		.reduce(sum, financialNumber(0))
	$: carrierPayDiff = totalVehiclesOriginalCarrierPay.minus(totalFinalCarrierPay)

	$: totalTerminalFees = legAtts
		.map(atts => atts.pickupTerminalFee)
		.reduce(sum, financialNumber(0))
		.plus(
			legAtts.map(atts => atts.dropoffTerminalFee).reduce(sum, financialNumber(0)),
		)
	$: totalFinalBrokerFee = totalVehiclesOriginalCarrierPay.plus(totalVehiclesOriginalBrokerFee).minus(totalFinalCarrierPay).minus(totalTerminalFees)
	$: brokerFeeDiff = totalVehiclesOriginalBrokerFee.minus(totalFinalBrokerFee)
</script>

<Card bodyClass="p-2">
	<div slot="header" class="card-header p-0">
		<div class="row">
			<div class="col">
				<h6 class="mb-0 px-3 py-2">Financial</h6>
			</div>
			<!--
			<div class="col-auto">
				<p class="mb-0 pt-2 pe-2" style="font-size: 0.83rem">
					<a class="btn btn-sm btn-link" href={router.makePath('app.opportunities.edit.opportunityId.logCreditCard', { opportunityId: form.primaryId }, { inherit: false })}>
						Log CC <span class="fa fa-credit-card"></span>
					</a>
				</p>
			</div>
			-->
		</div>
	</div>
	<table class="table table-sm table-striped mb-1">
		<tr>
			<th>Carrier Pay</th>
			<td class="text-end">{totalVehiclesOriginalCarrierPay}</td>
		</tr>
		<tr>
			<th>Broker Fee</th>
			<td class="text-end">{totalVehiclesOriginalBrokerFee}</td>
		</tr>
		{#if totalVehiclesTariffAdjustment}
			<tr>
				<th>Billing Adjustments</th>
				<td class="text-end">{totalVehiclesTariffAdjustment > 0 ? '+' : '-'}{totalVehiclesTariffAdjustment}</td>
			</tr>
		{/if}
		<tr class="border-top">
			<th>
				Total Tariff
				<span style="font-weight: normal;" class="text-muted">Customer Quote</span>
			</th>
			<td colspan="2" class="text-end">
				{totalOriginalTariff}
			</td>
		</tr>
	</table>
</Card>

{#if allCarrierPaysSet}
	<Card class="mt-3" bodyClass="p-2">
		<div slot="header" class="card-header p-0">
			<h6 class="mb-0 px-3 py-2">Dispatched</h6>
		</div>
		<table class="table table-sm table-striped mb-1">
			{#if totalTerminalFees}
				<tr>
					<th>Terminal Fees</th>
					<td colspan="2" class="text-end">{totalTerminalFees}</td>
				</tr>
			{/if}
			<tr>
				<th>Carrier Pay Diff</th>
				<td
					class="text-end"
					class:text-success={totalFinalCarrierPay < totalVehiclesOriginalCarrierPay}
					class:text-warning={totalFinalCarrierPay > totalVehiclesOriginalCarrierPay}
				>
					({(carrierPayDiff) > 0 ? '-' : '+'}{Math.abs(carrierPayDiff)})
				</td>
				<td class="text-end">{totalFinalCarrierPay}</td>
			</tr>
			<tr>
				<th>Broker Fee Diff</th>
				<td
					class="text-end"
					class:text-success={totalFinalBrokerFee > totalVehiclesOriginalBrokerFee}
					class:text-warning={totalFinalBrokerFee < totalVehiclesOriginalBrokerFee}
				>
					({brokerFeeDiff > 0 ? '-' : '+'}{Math.abs(brokerFeeDiff)})
				</td>
				<td class="text-end">{totalFinalBrokerFee}</td>
			</tr>
	<!--
			{#if allCarrierPaysSet}
					<tr>
						<th class="ps-5">Dispatched Carrier Pay</th>
						<td class="text-end pe-5">{totalFinalCarrierPay}</td>
					</tr>
			{/if}
			{#if totalTerminalFees}
				<tr>
					<th>Terminal Fees</th>
					<td class="text-end">{totalTerminalFees}</td>
				</tr>
			{/if}
			<tr>
				<th>Tariff</th>
				<td class="text-end">
					{totalOriginalTariff}
				</td>
			</tr>
			{#if totalVehiclesTariffAdjustment}
				<tr>
					<th>Adjustments</th>
					<td class="text-end" class:text-success={totalVehiclesTariffAdjustment > 0} class:text-warning={totalVehiclesTariffAdjustment < 0}>
						{totalVehiclesTariffAdjustment}
					</td>
				</tr>
			{/if}
			<tr class="border-top">
				<th>Broker Fee</th>
				<td class="text-end" class:text-success={totalFinalBrokerFee > 50} class:text-danger={totalFinalBrokerFee < 0}>
					{totalFinalBrokerFee}
				</td>
			</tr>
			-->
		</table>
	</Card>
{/if}
