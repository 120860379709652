<script>
	import SimpleNavBar from '@/component/SimpleNavBar.svelte'
	import AuthenticatedVisits from './AuthenticatedVisits.svelte'
	import { markdownToHtml } from '@/shared/util/markdown-to-html.js'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import PrintPage from '@/component/PrintPage.svelte'

	export let asr
	export let signableDocument
	export let signableDocumentVisits
	export let agreementEmail

	$: label = signableDocument.attributes.name
	$: items = [
		{ label: 'Signable Documents' },
		{ label },
	]

	$: opportunityId = signableDocument.relationships?.opportunity?.data?.id

	$: markdownString = signableDocument.attributes.markdown || ''
	$: html = markdownString && markdownToHtml(markdownString)

	$: agreementLabelString = signableDocument.attributes.agreementLabel || ''
	$: agreementLabelHtml = agreementLabelString && markdownToHtml(agreementLabelString)

	$: sentDate = new Date(signableDocument.meta.created)
	$: signedDate = signableDocument.attributes.signed && new Date(signableDocument.attributes.signed)
</script>

<style>
	@media print {
		#signable-document-navbar {
			display: none;
		}
	}
</style>

<div class="row">
	<div class="col-auto">
		<div id="signable-document-navbar">
			<SimpleNavBar {asr} {items} />
		</div>
	</div>
	<div class="col">
		<PrintPage>Print Document</PrintPage>
	</div>
</div>

<div class="row">
	<div class="col-xs-12 col-md-4">
		<div class="card mb-3">
			<div class="card-header py-2">
				Document Information
			</div>
			<div class="card-body bg-light pt-2">
				<dl>
					<dt>Document Title</dt>
					<dd>{signableDocument.attributes.name}</dd>
					{#if opportunityId}
						<dt>For Opportunity</dt>
						<dd>
							<a href={asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId }, { inherit: false })}>
								{opportunityId}
							</a>
						</dd>
					{/if}
					<dt>Signatory</dt>
					<dd>{signableDocument.attributes.signerName}</dd>
					<dt>Email Address</dt>
					<dd>{signableDocument.attributes.signerEmailAddress}</dd>
					<dt>Sent Date</dt>
					<dd>
						<NamedIcon name="email" />
						"{agreementEmail?.attributes?.subject || 'Agreement Email'}"
						<br>
						{sentDate?.toLocaleDateString('default')}
						at
						{sentDate?.toLocaleTimeString('default')}
					</dd>
					<dt>Signed At</dt>
					<dd>
						{#if signableDocument.attributes.signed}
							{signedDate.toLocaleDateString('default')}
							at
							{signedDate.toLocaleTimeString('default')}
						{:else}
							<em class="danger">
								Not signed.
							</em>
						{/if}
					</dd>
				</dl>
			</div>
		</div>
	</div>
	<div class="col-xs-12 col-md-8">
		<div class="card mb-3">
			<div class="card-header py-2">
				Authenticated Visits
				<br>
				<small>Page loads using the unique URL emailed to the customer.</small>
			</div>
			<div class="card-body bg-light pt-2">
				<AuthenticatedVisits
					visits={signableDocumentVisits}
					signedVisitId={signableDocument.relationships?.signableDocumentVisitSigned?.data?.id}
					signedDate={signableDocument.attributes.signed}
				/>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-xs-12 col-md-10 col-lg-9">
		<div class="card mb-3">
			<div class="card-header py-2">
				Signable Document
				<br>
				<small>The exact document content presented to the customer.</small>
			</div>
			<div class="card-body bg-light pt-3 pb-0">
				{@html html}
			</div>
		</div>
		<div class="card mb-3">
			<div class="card-header py-2">
				Agreement Label
				<br>
				<small>
					The user is required to enter their name and check a box with this
					exact text prior to being allowed to click an agreement button.
				</small>
			</div>
			<div class="card-body bg-light pt-3 pb-0">
				{@html agreementLabelHtml}
			</div>
		</div>
	</div>
</div>
