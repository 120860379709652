<script>
	import { createEventDispatcher, onMount, onDestroy } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { getJobById } from '@/lib/controller/job.js'
	import { router } from '@/service/router.js'

	export let api
	export let jobId

	const STATUSES_TO_RETRY = [ 'REQUESTED', 'IN_PROGRESS' ]
	const MAX_RETRY_DELAY = 5 * 60 * 1000
	const dispatch = createEventDispatcher()

	let fetchedAtLeastOnce
	let job
	let recheckTimeout
	let stopped
	let checkAgainTime
	const fetchJob = waitMillis => {
		if (waitMillis > MAX_RETRY_DELAY) {
			stopped = true
		} else {
			getJobById(api, jobId).then(({ data }) => {
				job = data
				fetchedAtLeastOnce = true
				if (STATUSES_TO_RETRY.includes(job.attributes.status)) {
					waitMillis = (waitMillis || 0) + 2000
					checkAgainTime = Date.now() + waitMillis
					recheckTimeout = setTimeout(() => fetchJob(waitMillis), waitMillis)
				} else {
					dispatch('stopped', data)
				}
			})
		}
	}

	$: status = job?.attributes?.status
	$: jobLink = jobId && router.makePath('app.systemSettings.jobs.byId.jobId', { jobId }, { inherit: false })

	// A little randomness to the spinner to make it more clear that something is happening.
	let checkingSpinner
	let checkAgainInterval = setInterval(() => {
		checkingSpinner = true
		setTimeout(() => {
			checkingSpinner = false
		}, 1000 * Math.random() + 1000)
	}, 3000 * Math.random() + 2000)

	onMount(fetchJob)
	onDestroy(() => {
		if (recheckTimeout) clearTimeout(recheckTimeout)
		if (checkAgainInterval) clearInterval(checkAgainInterval)
	})
</script>

{#if !fetchedAtLeastOnce}
	<slot name="first" {jobLink}>
		<span>&nbsp;</span>
	</slot>
{:else if !job || STATUSES_TO_RETRY.includes(status)}
	{#if stopped}
		<slot name="stopped" {jobLink}>
			<!--
			<span>
				It's taking an unusually long time for this Job to complete...
				You could try looking at the <a href={jobLink}>Job Inspector</a> for more details.
			</span>
			-->
		</slot>
	{:else}
		<span>
			<slot name="waiting" {jobLink}>
				<!--
				Waiting for Job to complete, one moment.
				-->
			</slot>
			{#if checkingSpinner}
				<NamedIcon name="loading" />
			{/if}
		</span>
	{/if}
{:else if status === 'COMPLETED'}
	<slot name="completed" {job} {jobLink}>
		<!--
		<span>Job has completed! 🎉</span>
		-->
	</slot>
{:else}
	<slot name="error" {jobLink}>
		<!--
		<span>
			An error occurred. Look at the
			<a href={jobLink}>Job Inspector</a>
			for more details.
		</span>
		-->
	</slot>
{/if}
