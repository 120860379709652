<script>
	import Table from '@/component/table/Opportunities.svelte'
	import TableCardWrapper from '@/component/TableCardWrapper.svelte'
	import TableHeader from './TableHeader.svelte'

	export let asr
	export let data
	export let links
	export let included
	export let loading
	export let request
	export let errors
	export let users

	export let params
	export let allUsersSelected

	const leadColumnNames = [
		'editId',
		'routeLinks',
		'received',
		'customerDetails',
		'firstAvailable',
		'vehicles',
		'pickup',
		'dropoff',
	]
	const quoteColumnNames = [
		'overviewId',
		'received',
		'carrierPay',
		'brokerFee',
		'customerDetails',
		'firstAvailable',
		'vehicles',
		'pickup',
		'dropoff',
	]
	const deliveredColumnNames = [
		'overviewId',
		'received',
		'customerDetails',
		'firstAvailable',
		'vehicles',
		'pickup',
		'dropoff',
		'financeSummary',
	]
	const defaultOrderColumnNames = [
		'overviewId',
		'customerDetails',
		'customerDates',
		'vehicles',
		'pickup',
		'dropoff',
		'financeSummary',
	]

	$: orderPostedColumnNames = params.opportunityStatus === 'order' && params.tab === 'posted'
		? [
			'overviewIdWithFlag',
			'customerDetails',
			'customerDates',
			'vehicles',
			'pickup',
			'dropoff',
			'financeSummary',
		]
		: defaultOrderColumnNames
	$: opportunityStatusToTabToColumnNames = {
		lead: {
			active: leadColumnNames,
			cancelled: leadColumnNames,
		},
		quote: {
			active: quoteColumnNames,
			held: quoteColumnNames,
			cancelled: quoteColumnNames,
		},
		order: {
			unposted: defaultOrderColumnNames,
			posted: orderPostedColumnNames,
			unsigned: defaultOrderColumnNames,
			dispatched: defaultOrderColumnNames,
			received: defaultOrderColumnNames,
			delivered: defaultOrderColumnNames,
			held: defaultOrderColumnNames,
			cancelled: defaultOrderColumnNames,
		},
		delivered: {
			delivered: deliveredColumnNames,
		},
	}

	let columnNames = []
	$: {
		let names = opportunityStatusToTabToColumnNames[params.opportunityStatus]?.[params.tab] || []
		if (allUsersSelected) names = [ ...names, 'assigned' ]
		columnNames = names
	}

	const makeLink = ({ keypath, ascending }) => asr.makePath(
		'app.opportunities.list.opportunityStatus',
		{ sort: `${ascending ? '-' : ''}${keypath}` },
		{ inherit: true },
	)
</script>

<TableCardWrapper
	table={Table}
	small
	{makeLink}
	{asr}
	{request}
	{data}
	{links}
	{errors}
	included={[ ...included, ...users ]}
	{columnNames}
	sort={params.sort}
	bind:loading
	on:loadNext
>
	<TableHeader {asr} {params} {users} {allUsersSelected} />
</TableCardWrapper>
