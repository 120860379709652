export const delay = async millis => new Promise(resolve => {
	setTimeout(() => {
		resolve()
	}, millis)
})

/**
 * Wait at least an amount of time, for UI purposes.
 * @param {number} millis - The minimum dwell time.
 * @param {Promise} promise - The thing to wait for.
 * @return {Promise} - The resolved or rejected thing.
 */
export const minimumDelay = (millis, promise) => {
	const wait = delay(millis)
	return promise
		.then(
			success => wait.then(() => success),
			failure => wait.then(() => Promise.reject(failure)),
		)
}
