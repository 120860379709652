<script>
	import { activeState, activeParams } from '@/service/store/router-state.js'

	export let asr
	export let children
	export let link = ''
	export let params
	export let paramsToBeActive
	export let expanded

	$: isActiveState = $activeState.startsWith(link)
	$: isActiveQueryParams = params && $activeParams && paramsToBeActive && paramsToBeActive.every(key => $activeParams && $activeParams[key] === params[key])
	$: active = isActiveState && (!paramsToBeActive || isActiveQueryParams)
</script>

{#if children && children.length}
	<a
		on:click|preventDefault
		class="nav-link dropdown-indicator"
		href={asr.makePath(link, params, { inherit: false })}
		role="button"
		data-bs-toggle="collapse"
		aria-expanded={expanded ? 'true' : 'false'}
		aria-controls="email"
	>
		<slot></slot>
	</a>
{:else}
	<a
		class="nav-link"
		class:active
		href={asr.makePath(link, params, { inherit: false })}
		role="button"
	>
		<slot></slot>
	</a>
{/if}
