<script>
	import OpportunityDateRange from '@/component/molecule/OpportunityDateRange.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let opportunity
</script>

<tr>
	<td style="vertical-align: top;">
		<NamedIcon name="date" />
	</td>
	<th style="white-space: nowrap; vertical-align: top;">
		Preferred Dates
	</th>
	<td>
		<OpportunityDateRange {opportunity} />
	</td>
</tr>
