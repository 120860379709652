<script>
	export let row
	export let includedById

	$: contact = includedById[row?.relationships?.contact?.data?.id]?.attributes

	const orderedFields = [
		'name',
		'phonePrimary',
		'phoneTwo',
		'emailPrimary',
	]
</script>

{#if contact}
	{#each orderedFields as key}
		{#if contact[key]}
			<p class="mb-0">
				{contact[key]}
			</p>
		{/if}
	{/each}
{/if}
