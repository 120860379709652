<script>
	import InputRadioBare from './InputRadioBare.svelte'
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let options
	export let optionsList
	export let onCreate
</script>

<Field bind:form {id} {keypath} on:change let:errors>
	<InputRadioBare
		{id}
		{keypath}
		{readonly}
		{options}
		{optionsList}
		{onCreate}
		bind:form
		on:*
	/>
</Field>
