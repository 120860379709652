<script>
	import dlv from 'dlv'
	import { idString } from '@/lib/id-string.js'
	import { highlightedWord } from '@/service/store/highlighter.js'
	import { makeHighlightedText } from '@/lib/make-highlighted-text.js'

	export let row
	export let column

	$: text = column.keypath === 'id'
		? idString(row)
		: dlv(row, column.keypath)
	$: highlight = makeHighlightedText($highlightedWord, text)
</script>

{#if $$slots.before}
	<slot name="before" resource={row} />
{/if}

{#if highlight?.html}
	{@html highlight.html}
{:else if highlight?.text}
	{highlight.text}
{:else}
	{text || column.alt || ''}
{/if}

{#if $$slots.after}
	<slot name="after" resource={row} />
{/if}
