<script>
	import { createEventDispatcher } from 'svelte'
	import FormInput from '../common/FormInput.svelte'

	export let system
	export let name
	export let config = {}
	export let form = {}

	let className = ''
	export { className as class }

	const dispatch = createEventDispatcher()
	const onsubmit = () => { dispatch('submit', { name, form }) }

	const pseudoValidEmail = /[^@]+@[^.]+/
	$: valid = form[name].email && pseudoValidEmail.test(form[name].email)
	$: state = form[name]._state || form._state || {}
</script>

<form class={className} on:submit|preventDefault={onsubmit}>
	<fieldset disabled={state.submitting}>
		<FormInput type="email" on:input config={config.email} bind:value={form[name].email} form={name} keypath="email" />

		{#if state.errors}
			{#each state.errors as error}
				<div class="alert alert-danger mt-3" role="alert">
					{error.detail}
				</div>
			{/each}
		{/if}

		<div class="mb-3">
			<button disabled={!valid} class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
				{#if state.submitting}
					Sending...
				{:else}
					Send reset link
				{/if}
			</button>
		</div>
	</fieldset>
</form>

{#if $system.paths['misc/help']}
	<a href={$system.paths['misc/help']} class="fs--1 text-600">
		I can't recover my account using this page
		<span class="d-inline-block ms-1">
			&rarr;
		</span>
	</a>
{/if}
