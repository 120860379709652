<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import SimpleNavBar from '@/component/SimpleNavBar.svelte'
	export let label
	export let asr
	export let createPage
	$: items = [
		{
			path: 'app.referrers',
			label: 'Referrers',
		},
		{ label },
	]
</script>

<SimpleNavBar
	{asr}
	{items}
>
	<slot />
	{#if !createPage}
		<div class="col pe-1 text-end">
			<a href={asr.makePath('app.referrers.create', null, { inherit: false })} class="btn btn-primary btn-sm text-light">
				<NamedIcon name="add" />
				Add Referrer Source
			</a>
		</div>
	{/if}
</SimpleNavBar>
