<script>
	import ExternalLink from '@/component/ExternalLink.svelte'
	import CompanyIcons from '@/component/molecule/CompanyIcons.svelte'
	import { createEventDispatcher } from 'svelte'
	import InputTextDisplayOnly from '@/component/atom/InputTextDisplayOnly.svelte'
	import { router } from '@/service/router.js'

	export let terminal
	export let includedById = {}
	export let action
	export let readonly

	$: contact = includedById[terminal.relationships?.contact?.data?.id]
	$: insurance = includedById[terminal.relationships?.insurance?.data?.id]

	$: isExpiredOrNoDate = !insurance?.attributes?.expiration || new Date(insurance?.attributes?.expiration).getTime() <= Date.now()

	$: contactName = contact?.attributes?.name
		? contact.attributes.name
		: ((contact?.attributes?.firstName || '') + ' ' + (contact?.attributes?.lastName || '')).trim()

	const dispatch = createEventDispatcher()
</script>

<div class="row mb-2">
	<div class="col">
		<h5 class="mb-1" style="display: inline">
			<small><CompanyIcons company={terminal} /></small>
			<ExternalLink url={router.makePath('app.companies.edit.companyId.overview', { companyId: terminal.id }, { inherit: false })}>
				{terminal.attributes.name}
			</ExternalLink>
		</h5>
	</div>
	{#if !readonly}
		<div class="col-auto">
			<button class="btn btn-sm btn-primary" on:click={() => dispatch('click', terminal.id)}>
				{action}
			</button>
		</div>
	{/if}
</div>

{#if insurance?.attributes}
	<div class="row mb-2">
		<div class="col-auto">
			<small>
				<span class="fa fa-car-crash"></span>
				{insurance.attributes.companyName || '(Unnamed Insurer)'}
			</small>
		</div>
		<div class="col-auto">
			<small>
				<span class="fa fa-calendar-times"></span>
				<span class="{isExpiredOrNoDate ? 'text-danger' : ''}">
					{insurance.attributes.expiration || 'No Expiration'}
				</span>
			</small>
		</div>
	</div>
{/if}

<InputTextDisplayOnly labelWidth="10ch" label="Contact" value={contact?.attributes?.name || contact?.attributes?.firstName || ''} />
<InputTextDisplayOnly icon="email" labelWidth="10ch" label="Email" value={contact?.attributes?.emailPrimary || ''} />
