import template from './ReferralDataExport.svelte'
import { listReferrers } from '@/lib/controller/referrer.js'
import { get } from '@/lib/json-fetch.js'
import { dateRangeQueryParams } from './_date-range-from-query.js'

export default {
	data: {
		title: 'Referral Data Export',
	},
	template,
	querystringParameters: [
		...dateRangeQueryParams,
		'requireReferrer',
		'referrerIds',
		'referrerTags',
	],
	resolve: async (_, querystringParams) => ({
		querystringParams,
		referrers: await listReferrers({ get }).then(res => res.data),
	}),
}
