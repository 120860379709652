export const _parents = {
	opportunities: 'Opportunities',
	services: 'Services',
	accounting: 'Accounting',
	internal: 'Internal',
}

export const leads = {
	parent: 'opportunities',
	label: 'Leads',
	icon: 'file',
	link: 'app.opportunities.list.opportunityStatus',
	params: { opportunityStatus: 'lead' },
	paramsToBeActive: [ 'opportunityStatus' ],
}
export const quotes = {
	parent: 'opportunities',
	label: 'Quotes',
	icon: 'file-invoice-dollar',
	link: 'app.opportunities.list.opportunityStatus',
	params: { opportunityStatus: 'quote' },
	paramsToBeActive: [ 'opportunityStatus' ],
}
export const orders = {
	parent: 'opportunities',
	label: 'Orders',
	icon: 'file-contract',
	link: 'app.opportunities.list.opportunityStatus',
	params: { opportunityStatus: 'order' },
	paramsToBeActive: [ 'opportunityStatus' ],
}
export const delivered = {
	parent: 'opportunities',
	label: 'Delivered',
	icon: 'check-square',
	link: 'app.opportunities.list.opportunityStatus',
	params: { opportunityStatus: 'delivered', sort: '-meta.created' },
	paramsToBeActive: [ 'opportunityStatus' ],
}

export const opportunityStatusToLink = {
	lead: leads,
	quote: quotes,
	order: orders,
	delivered,
}

export const carriers = {
	parent: 'services',
	label: 'Carriers',
	icon: 'truck',
	link: 'app.companies',
	params: { service: 'carrier' },
	paramsToBeActive: [ 'service' ],
}
export const shippers = {
	parent: 'services',
	label: 'Shippers',
	icon: 'truck-pickup',
	link: 'app.companies',
	params: { service: 'shipper' },
	paramsToBeActive: [ 'service' ],
}
export const terminals = {
	parent: 'services',
	label: 'Terminals',
	icon: 'parking',
	link: 'app.companies',
	params: { service: 'terminal' },
	paramsToBeActive: [ 'service' ],
}

export const reports = {
	parent: 'internal',
	label: 'Reports',
	icon: 'chart-line',
	link: 'app.reports',
}
export const users = {
	parent: 'internal',
	label: 'Users',
	icon: 'users',
	link: 'app.users',
}
export const systemSettings = {
	parent: 'internal',
	label: 'System',
	icon: 'cogs',
	link: 'app.systemSettings',
}

export const creditCards = {
	parent: 'accounting',
	label: 'Customer',
	icon: 'credit-card',
	link: 'app.accounting.customers',
}
export const moneyChecks = {
	parent: 'accounting',
	label: 'Carrier',
	icon: 'money-check',
	link: 'app.accounting.carriers',
}
export const qbChanges = {
	parent: 'accounting',
	label: 'QB Changes',
	icon: 'exchange-alt',
	link: 'app.accounting.qbChanges',
}
export const referrers = {
	label: 'Referrers',
	icon: 'hands-helping',
	link: 'app.referrers',
}
export const referrersList = {
	label: 'Referrers',
	icon: 'hands-helping',
	link: 'app.referrers.list',
}
