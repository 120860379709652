<script>
	import { legDocumentPropertyLabels } from '@/shared/util/opportunity-to-per-leg-documents.js'
	import dlv from 'dlv'

	export let changes

	const label = keypath => {
		if (keypath[0] === 'vehicles' && keypath[1] === 'vehicleDetail') {
			if (keypath.length === 2) return 'Vehicle Count'
			else return `Vehicle ${parseInt(keypath[2]) + 1}, ${legDocumentPropertyLabels.vehicles.vehicleDetail.$[keypath[3]]}`
		} else {
			return dlv(legDocumentPropertyLabels, keypath) || keypath.join('.')
		}
	}
</script>

<style>
	span {
		display: inline-block;
		color: var(--falcon-muted);
		width: 6em;
	}
</style>

<table class="table table-sm mb-0">
	<thead>
	<tr>
		<td>Property</td>
		<td>Change</td>
	</tr>
	</thead>
	<tbody>
		{#each changes as { keypath, original, updated }}
			<tr>
				<td>{label(keypath)}</td>
				<td>
					<span>Original:</span> {original}
					<br>
					<span>Updated:</span> {updated}
				</td>
			</tr>
		{/each}
	</tbody>
</table>
