<script>
	import { MakeDiff, Relationship } from 'jsonapi-svelte-form'

	export let form
	export let legId

	const atts = [
		'Type',
	]
	const rels = [
		'Terminal',
		'Contact',
		'Address',
	]

	const makeRel = resource => ({
		data: {
			type: resource.type,
			id: resource.id,
		},
	})

	const splitLeg = ({ makeDiff, add }) => () => {
		const makeId = () => `GID${form.gidIndex++}`
		// first we make the new leg and add it to the form
		const pickupContact = {
			id: makeId(),
			type: 'contact',
			attributes: {},
		}
		form.data[pickupContact.id] = pickupContact
		const pickupAddress = {
			id: makeId(),
			type: 'address',
			attributes: {},
		}
		form.data[pickupAddress.id] = pickupAddress
		const leg = {
			id: makeId(),
			type: 'leg',
			attributes: {
				pickupType: 'custom',
			},
			relationships: {
				pickupContact: makeRel(pickupContact),
				pickupAddress: makeRel(pickupAddress),
			},
		}
		for (const key of atts) if (form.data[legId].attributes[`dropoff${key}`]) leg.attributes[`dropoff${key}`] = form.data[legId].attributes[`dropoff${key}`]
		for (const key of rels) if (form.data[legId].relationships[`dropoff${key}`]) leg.relationships[`dropoff${key}`] = form.data[legId].relationships[`dropoff${key}`]
		form.data[leg.id] = leg
		add(leg.id)
		// then we remove the dropoff details from the original leg
		for (const key of atts) delete form.data[legId].attributes[`dropoff${key}`]
		for (const key of rels) delete form.data[legId].relationships[`dropoff${key}`]
		form.data[legId].attributes.dropoffType = 'custom'
		const dropoffContact = {
			id: makeId(),
			type: 'contact',
			attributes: {},
		}
		form.data[dropoffContact.id] = dropoffContact
		const dropoffAddress = {
			id: makeId(),
			type: 'address',
			attributes: {},
		}
		form.data[dropoffAddress.id] = dropoffAddress
		form.data[legId].relationships.dropoffContact = makeRel(dropoffContact)
		form.data[legId].relationships.dropoffAddress = makeRel(dropoffAddress)
		// and tidy up at the end
		makeDiff(form, form.primaryId)
		makeDiff(form, legId)
	}
</script>

<MakeDiff let:makeDiff>
	<Relationship isArray bind:form id={form.primaryId} name="legs" type="leg" let:add>
		<button class="btn btn-sm btn-outline-primary px-0" on:click={splitLeg({ makeDiff, add })}>
			<span class="fa fa-clone"></span> Split
		</button>
	</Relationship>
</MakeDiff>
