<script>
	import MenuLeft from '@/component/MenuLeft.svelte'
	import MenuTop from '@/component/MenuTop.svelte'
	import { LayoutTheme } from 'falcon'
	import { system } from '@/service/store/system.js'

	export let asr = {}
</script>

<style>
	:global(body) {
		--falcon-muted: #b9bcc8;
	}
	:global(table.table-striped > tbody > tr:nth-of-type(2n)) {
		color: #0b1727;
	}
	@media print {
		:global(.printing main > .container-fluid > nav.navbar),
		:global(.printing main > .container-fluid > .content > nav.navbar) {
			display: none;
		}
	}
</style>

<LayoutTheme {system}>
	<div class="container-fluid">
		<MenuLeft {asr} />
		<div class="content pb-5">
			<MenuTop {asr} />
			<uiView />
		</div>
	</div>
</LayoutTheme>
