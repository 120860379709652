<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import AutoQuoteCampaignForm from '@/component/form/AutoQuoteCampaignForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { createAutoQuoteCampaign } from '@/lib/controller/auto-quote-campaign.js'
	import { router } from '@/service/router.js'

	export let asr
	export let api
	export let form
	export let emailTemplateOptions

	const create = () => {
		form.state = 'saving'
		delete form.errors
		createAutoQuoteCampaign(api, form, response => router.go('app.systemSettings.autoQuoteCampaigns.edit.autoQuoteCampaignId', { autoQuoteCampaignId: response.primaryId }))
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>
		 System
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<a href={asr.makePath('app.systemSettings.autoQuoteCampaigns', null, { inherit: false })}>
		 Auto-Quote Campaigns
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Create Auto-Quote Campaign
	</span>
</FormHeaderMain>

<AutoQuoteCampaignForm bind:form {emailTemplateOptions} />

<FormErrorsOther {form} />

<FormCommitButton label="Create Auto-Quote Campaign" {form} on:click={create} />
