<script>
	import { Field, Form } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'

	import EditVehicle from './EditVehicle.svelte'
	import EditOrderShippingInformation from './EditOrderShippingInformation.svelte'
	import EditLeadPickupDeliver from './EditLeadPickupDeliver.svelte'

	export let api
	export let create
	export let remove
	export let form
	export let companyData
	export let cloning

	const MILES_PER_DAY = 400

	const createVehicle = _create => () => _create({ relId: form.primaryId, relName: 'vehicles', type: 'vehicle', isArray: true })

	$: status = form.data[form.primaryId]?.attributes?.status
	$: vehicleRels = form.data[form.primaryId]?.relationships?.vehicles?.data || []
	$: overallDays = Math.ceil(form.data[form.primaryId]?.attributes?.miles / MILES_PER_DAY * 10) / 10
	$: firstAvailablePickup = form.data[form.primaryId].attributes.firstAvailablePickup
	$: leadPickupDate = form.data[form.primaryId].attributes.leadPickupDate

	$: legs = form.data[form.primaryId]?.relationships?.legs?.data?.map(rel => form.data[rel.id]) || []
	$: anyAre = {
		posted: legs.some(leg => leg?.attributes?.dates?.posted),
		assigned: legs.some(leg => leg?.attributes?.dates?.assigned),
		dispatched: legs.some(leg => leg?.attributes?.dates?.dispatched),
		pickedUp: legs.some(leg => leg?.attributes?.dates?.received),
		delivered: legs.some(leg => leg?.attributes?.dates?.delivered),
	}
</script>

<div class="row g-2">
	<div class="col-md-6">
		<div class="card mb-3">
			<div class="card-header {anyAre.posted ? 'py-2' : ''}">
				<div class="row align-items-center">
					<div class="col">
						Vehicle Information
					</div>
					<div class="col-auto">
						{#if anyAre.posted}
							<div class="alert alert-info py-1 mb-0">
								<small>
									Un-post
									{#if legs.length > 1}
										all legs
									{:else}
										the order
									{/if}
									to add or remove vehicles.
								</small>
							</div>
						{:else}
							<Form bind:form let:create={_create}>
								<button class="btn btn-sm btn-outline-primary" on:click={createVehicle(_create)}>
									<span class="fa fa-plus"></span>
									Add Vehicle
								</button>
							</Form>
						{/if}
					</div>
				</div>
			</div>
			<div class="card-body bg-light">
				<Form bind:form let:create={_create}>
					{#each vehicleRels as { id: vehicleId }, index}
						<EditVehicle
							bind:form
							{vehicleId}
							legCount={legs.length}
							{anyAre}
							canBeRemoved={vehicleRels?.length > 1 && !anyAre.posted}
							onCreate={createVehicle(_create)}
							on:removeVehicle={() => remove({ id: vehicleId, type: 'vehicle' })}
						/>
						{#if index < (vehicleRels.length - 1)}
							<hr class="mt-2 mb-3" style="color: #bbb;">
						{/if}
					{/each}
				</Form>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card mb-3">
			<div class="card-header py-2 pb-0">
				Dates
				<div class="d-inline-block ps-3" style:width={status === 'quote' ? '240px' : '200px'}>
					<InputType
						label={status === 'quote' ? 'Overall Miles' : 'Miles'}
						labelWidth={status === 'quote' ? '120px' : '80px'}
						type="number"
						hideArrows
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'miles' ]}
					/>
				</div>
				{#if overallDays}
					<span class="text-muted ps-2">
						/ {MILES_PER_DAY} = {overallDays} day{overallDays === 1 ? '' : 's'}
					</span>
				{/if}
			</div>
			<div class="card-body bg-light">
				<div class="row">
					<div class="col">
						<InputType
							type="date"
							label="First Available Pickup"
							labelWidth="180px"
							icon="date"
							bind:form
							id={form.primaryId}
							keypath={[ 'attributes', 'firstAvailablePickup' ]}
						/>
						{#if !cloning && status === 'lead' && leadPickupDate !== firstAvailablePickup}
							<p class="mb-0">
								<span class="text-muted">Original Date:</span>
								<span class="text-info">{leadPickupDate}</span>
							</p>
						{:else if status !== 'lead'}
							<InputType
								type="date"
								label="Last Preferred Pickup"
								labelWidth="180px"
								icon="date"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'lastAvailablePickup' ]}
							/>
							<div class="row">
								<div class="col">
									<div class="form-check form-check-inline" style="margin: 0.2rem 0 .7rem 0.1rem">
										<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'firstAvailableDropoff' ]} let:set let:disabled>
											<input
												{disabled}
												class="form-check-input"
												type="checkbox"
												id="special-dropoff-instructions"
												checked={form.data[form.primaryId]?.attributes?.firstAvailableDropoff}
												on:input={ () => { set(form.data[form.primaryId]?.attributes?.firstAvailableDropoff ? undefined : new Date().toISOString().split('T')[0]) } }
											>
											<label for="special-dropoff-instructions" class="form-check-label mb-0" style="font-weight: normal !important;">
												Delivery Restriction
											</label>
										</Field>
									</div>
								</div>
								{#if form.data[form.primaryId]?.attributes?.firstAvailableDropoff}
									<div class="col-auto">
										<InputType
											type="date"
											label="First Available Delivery"
											labelWidth="200px"
											icon="date"
											placeholder="yyyy-mm-dd"
											bind:form
											id={form.primaryId}
											keypath={[ 'attributes', 'firstAvailableDropoff' ]}
										/>
									</div>
								{/if}
							</div>
						{/if}
					</div>
				</div>
			</div>
		</div>
		{#if status === 'lead'}
			<EditLeadPickupDeliver
				bind:form
			/>
		{/if}
	</div>
</div>

{#if status !== 'lead'}
	<EditOrderShippingInformation
		{api}
		{create}
		{remove}
		{anyAre}
		{companyData}
		bind:form
	/>
{/if}
