<script>
	import ReportTable from '@/component/report/ReportTable.svelte'
	import { tableizeReportData } from '@/component/report/tableize-report-data.js'
	import { spreadsheetGenerator } from '@/component/report/table-spreadsheet-generator.js'
	import { everyOpportunityColumn } from '@/component/report/opportunity/every-opportunity-column.js'


	import DateRangeNavbar from './_DateRangeNavbar.svelte'
	import { fetchReport } from './_fetch-report.js'
	import { dateRangeFromQuery } from './_date-range-from-query.js'

	export let api
	export let asr
	export let users
	export let querystringParams
	export let upcoming

	const upcomingToFilter = {
		receive: {
			minDateType: 'anyReceivedContract',
			maxDateType: 'anyReceivedContract',
		},
		deliver: {
			minDateType: 'anyDeliveredContract',
			maxDateType: 'anyDeliveredContract',
		},
	}
	const upcomingToLabel = {
		receive: 'Pickups (By Contract Date)',
		deliver: 'Deliveries (By Contract Date)',
	}
	const upcomingToFilenamePrefix = {
		receive: 'upcoming-pickups',
		deliver: 'upcoming-deliveries',
	}
	const upcomingToSortColumn = {
		receive: 'pickupContract',
		deliver: 'dropoffContract',
	}
	const upcomingToExclude = {
		receive: { has: 'pickupDateContract', not: 'pickupDateActual' },
		deliver: { has: 'dropoffDateContract', not: 'dropoffDateActual' },
	}
	const filterToExclude = (data, which) => {
		const { has, not } = upcomingToExclude[which]
		return data.filter(row => {
			return row.legs?.find(leg => leg[has] && !leg[not])
		})
	}

	$: orderedColumns = [
		'id',
		'customerStacked',
		'notes',
		'firstAvailable',
		'vehiclesStacked',
		'legStatusStacked',
		'carrierDetailsStackedSimple',
		'pickupContractStacked',
		querystringParams.showOnlyIncomplete !== 'true' && upcoming === 'receive' && 'pickupActualStacked',
		'dropoffContractStacked',
		querystringParams.showOnlyIncomplete !== 'true' && upcoming === 'deliver' && 'dropoffActualStacked',
		'assigned',
	].filter(Boolean)
	$: selectableDateRanges = [
		{
			label: 'Today',
			params: { dateRange: 'today' },
		},
		{
			label: '1 Business Day',
			params: { dateRange: 'up1biz' },
		},
		{
			label: '5 Business Days',
			params: { dateRange: 'up5biz' },
		},
		{
			label: 'Custom Date Range',
			params: { dateRange: 'custom' },
		},
	]
	$: columns = everyOpportunityColumn({ asr })

	let currentSort
	let data
	let errors
	let loading = true
	$: dateRange = dateRangeFromQuery({ querystringParams, backward: false, now: new Date() })
	$: params = dateRange?.apiDates?.min && dateRange?.apiDates?.max && {
		filter: {
			minDate: dateRange.apiDates.min && new Date(dateRange.apiDates.min).toISOString(),
			maxDate: dateRange.apiDates.max && new Date(dateRange.apiDates.max).toISOString(),
			assignedId: querystringParams.assignedId,
			...upcomingToFilter[upcoming],
		},
		include: [ 'legs' ],
	}
	$: {
		if (params) {
			fetchReport({ api, url: '/api/v1/reports/groupedOpportunities/id', params })
				.then(response => {
					if (response.errors?.length) errors = response.errors
					else if (response?.data) data = querystringParams.showOnlyIncomplete === 'true' ? filterToExclude(response.data, upcoming) : response.data
					currentSort = querystringParams.sort || `-${upcomingToSortColumn[upcoming]}`
					loading = false
				})
		} else {
			loading = false
		}
	}

	$: rows = !loading && data && tableizeReportData({
		body: { data },
		sort: currentSort,
		params,
		columns,
		orderedColumns,
	})

	const generateSpreadsheetFile = ({ XLSX }) => spreadsheetGenerator({
		XLSX,
		orderedColumns,
		columns,
		label: upcomingToLabel[upcoming],
		filename: `${upcomingToFilenamePrefix[upcoming]}-${new Date().toISOString().replaceAll(':', '')}.xlsx`,
		rows,
		params: params.filter,
	})
</script>

<DateRangeNavbar
	{asr}
	{columns}
	{dateRange}
	{generateSpreadsheetFile}
	label={upcomingToLabel[upcoming]}
	{orderedColumns}
	{querystringParams}
	rowCount={rows?.length}
	{selectableDateRanges}
	tableId="upcoming"
>
	<div slot="description" style="max-width: 55ch;">
		<p class="mt-1 mb-2">
			Opportunities where any leg has a contractual
			{#if upcoming === 'receive'}
				pick up
			{:else if upcoming === 'deliver'}
				delivery
			{/if}
			date within the range specified.
		</p>
		<p class="mb-2">
			Dates are filtered by the contractual date, without regard to the date "type" (e.g. "Estimated"
			versus "Exact").
		</p>
		<p class="mb-2">Examples:</p>
		<ul class="mb-2">
			<li>
				If your start search date is November 10, a Leg with an "Estimated"
				{#if upcoming === 'receive'}
					pickup
				{:else}
					delivery
				{/if}
				date of November 11 would <em>not</em> be included.
			</li>
			<li>
				For the same search, a leg with an "Exact"
				{#if upcoming === 'receive'}
					pickup
				{:else}
					delivery
				{/if}
				date of November 10 <em>would</em> be included.
			</li>
		</ul>
	</div>
</DateRangeNavbar>

<div class="row align-items-center mt-2">
	<div class="col-auto">
		<strong>Filter by Assigned</strong>
	</div>
	<div class="col-auto">
		<div class="btn-group btn-group-sm" role="group" aria-label="Filter to Assigned User">
			<a
				href={asr.makePath(null, { ...querystringParams, assignedId: undefined }, { inherit: false })}
				type="button"
				class="btn btn-outline-primary"
				class:active={!querystringParams.assignedId}
				on:click={() => { asr.go(null, { ...querystringParams, assignedId: undefined }, { inherit: false }) }}
			>
				All Users
			</a>
			{#each users.filter(u => u.attributes.role === 'sales') as user}
				<a
					href={asr.makePath(null, { ...querystringParams, assignedId: user.id }, { inherit: false })}
					type="button"
					class="btn btn-outline-primary"
					class:active={querystringParams.assignedId === user.id}
					on:click={() => { asr.makePath(null, { ...querystringParams, assignedId: user.id }, { inherit: false }) }}
				>
					{user.attributes.name}
				</a>
			{/each}
		</div>
	</div>
	<div class="col-auto" style="min-width: 16em;">
		<div class="form-check mb-0">
			<input
				class="form-check-input mb-0"
				type="checkbox"
				id="show-only"
				checked={querystringParams.showOnlyIncomplete === 'true'}
				on:input={event => {
					asr.go(
						null,
						{
							...querystringParams,
							showOnlyIncomplete: event.target.checked ? 'true' : undefined,
						},
						{ inherit: false },
					)
				}}
			>
			<label class="form-check-label mb-0" for="show-only">
			Show only legs that have a contractual date but no actual date.
			</label>
		</div>
	</div>
</div>

<ReportTable
	id="upcoming"
	{asr}
	{columns}
	{currentSort}
	{errors}
	{loading}
	{orderedColumns}
	{querystringParams}
	{rows}
	updateSort={({ sortKey }) => { currentSort = sortKey }}
/>
