<script>
	import { createEventDispatcher } from 'svelte'
	export let config = {}
	export let type = 'text'
	export let form = ''
	export let keypath = ''
	export let value = ''

	const dispatch = createEventDispatcher()
	const oninput = event => {
		value = event.target.value
		dispatch('input', { form, keypath, value })
	}
</script>

{#if config.label}
	<label for={config.id} class="form-label">
		{config.label}
	</label>
{/if}

<input
	id={config.id}
	class="form-control"
	{type}
	placeholder={config.placeholder || config.label || ''}
	value={value || ''}
	on:input={oninput}
>
