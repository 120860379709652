<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import CreatedWithAutoQuote from '@/component/table/opportunities/CreatedWithAutoQuote.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import PlainTextRel from '@/component/table-cells/PlainTextRel.svelte'
	import ShipperDetails from '@/component/table-cells/ShipperDetails.svelte'
	import CustomerDetails from './opportunities/CustomerDetails.svelte'
	import CustomerDates from './opportunities/CustomerDates.svelte'
	import DollarsCarrierPay from './opportunities/DollarsCarrierPay.svelte'
	import DollarsBrokerFee from './opportunities/DollarsBrokerFee.svelte'
	import DollarsSummary from './opportunities/DollarsSummary.svelte'
	import RouteSearchLinks from './opportunities/RouteSearchLinks.svelte'
	import OpportunityDetails from './opportunities/OpportunityDetails.svelte'
	import OverviewIdWithFlag from './opportunities/OverviewIdWithFlag.svelte'
	import Shipping from './opportunities/Shipping.svelte'
	import Status from './opportunities/Status.svelte'
	import LegLocations from './opportunities/LegLocations.svelte'
	import Vehicles from './opportunities/Vehicles.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'updated',
		'overviewId',
		'customerDetails',
		'customerNotes',
		'firstAvailable',
		'vehicles',
		'pickup',
		'dropoff',
		'assigned',
	]

	const rowLink = row => asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId: row.id })
	const nameToColumn = {
		overviewId: {
			title: 'ID',
			keypath: 'id',
			sortable: true,
			component: PlainLink,
			nowrap: true,
			link: rowLink,
			columnClass: 'id',
		},
		overviewIdWithFlag: {
			title: 'ID',
			keypath: 'id',
			component: OverviewIdWithFlag,
			nowrap: true,
			link: rowLink,
			columnClass: 'id',
		},
		editId: {
			title: 'ID',
			keypath: 'id',
			component: PlainLink,
			nowrap: true,
			link: rowLink,
			columnClass: 'id',
		},
		received: {
			title: 'Created',
			keypath: 'meta.created',
			sortable: true,
			component: CreatedWithAutoQuote,
			time: true,
			multiline: true,
			columnClass: 'received',
		},
		customerDetails: {
			title: 'Customer',
			component: CustomerDetails,
			columnClass: 'customer-details',
		},
		firstAvailable: {
			title: '1st Available',
			keypath: 'attributes.firstAvailablePickup',
			sortable: true,
			component: DateTime,
			columnClass: 'first-available',
		},
		vehicles: {
			title: 'Vehicles',
			component: Vehicles,
			columnClass: 'vehicles',
		},
		pickup: {
			title: 'Origin',
			component: LegLocations,
			columnClass: 'pickup',
			type: 'pickup',
		},
		dropoff: {
			title: 'Destination',
			component: LegLocations,
			columnClass: 'dropoff',
			type: 'dropoff',
		},
		updated: {
			title: 'Updated',
			keypath: 'meta.updated',
			sortable: true,
			component: DateTime,
			time: true,
			multiline: true,
			columnClass: 'updated',
		},
		customerNotes: {
			title: 'Notes',
			keypath: 'attributes.customerNotes',
			component: PlainText,
			columnClass: 'customer-notes',
		},
		assigned: {
			title: 'Assigned',
			relKeypath: 'attributes.name',
			keypath: 'relationships.user.data.id',
			component: PlainTextRel,
			alt: 'N/A',
			altClass: 'text-muted',
			columnClass: 'assigned',
		},
		routeLinks: {
			title: 'Search',
			component: RouteSearchLinks,
			columnClass: 'routes',
		},
		carrierPay: {
			title: 'CP',
			component: DollarsCarrierPay,
			columnClass: 'dollars',
		},
		brokerFee: {
			title: 'BF',
			component: DollarsBrokerFee,
			columnClass: 'dollars',
		},
		financeSummary: {
			title: 'Finance',
			component: DollarsSummary,
			columnClass: 'dollars',
		},
		customerDates: {
			title: 'Dates',
			keypath: 'attributes.firstAvailablePickup',
			component: CustomerDates,
			sortable: true,
			columnClass: 'customer-dates',
		},
		status: {
			title: 'Status',
			component: Status,
			columnClass: 'status',
		},
		// TODO make sure all the columns below here are used: if they aren't, they add to the bundle size for no reason
		shipper: {
			title: 'Shippers',
			component: ShipperDetails,
			columnClass: 'shipper',
		},
		quote: {
			title: 'Quote',
			keypath: 'attributes.tariff',
			component: PlainText,
			columnClass: 'quote',
		},
		details: {
			title: 'Details',
			component: OpportunityDetails,
			columnClass: 'details',
		},
		shipping: {
			title: 'Shipping',
			component: Shipping,
			columnClass: 'shipping',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name]).filter(Boolean)
</script>

<style>
	:global(.opportunities-table .id) { width: 8em; }
	:global(.opportunities-table .updated) { width: 9.5em; }
	:global(.opportunities-table .received) { width: 11em; }
	:global(.opportunities-table .customer-details) { width: 22em; }
	:global(.opportunities-table .customer-notes) { width: 16em; }
	:global(.opportunities-table .first-available) { width: 8em; }
</style>

<div class="opportunities-table">
	<Table
		small
		{columns}
		{data}
		{errors}
		{included}
		{links}
		{makeLink}
		{request}
		{sort}
		bind:loading
		on:loadNext
	/>
</div>
