<script>
	import { createEventDispatcher } from 'svelte'
	import { humanReadableBytes } from '@/shared/util/string.js'
	import ExternalLink from '../ExternalLink.svelte'

	export let form
	export let relName
	export let parentType
	export let parentId
	export let childType
	export let childId
	export let inputId = 'upload-files'
	export let uploading = {}

	const dispatch = createEventDispatcher()
	let dragover
	let internalUploading = {}
	let uploaderControllers = {}
	export const clearUploadingFiles = () => {
		internalUploading = {}
	}

	$: allExistingFilesRemoved = !!form.changes[form.primaryId]?.find(({ op, path }) => {
		return op === 'remove'
			&& path?.[0] === 'relationships'
			&& path[1] === 'files'
			&& path.length === 2
	})
	$: currentFileIds = new Set(form.data[form.primaryId]?.relationships?.[relName]?.data?.map(rel => rel.id) || [])
	$: originalStillPresentFileIds = (form.original[form.primaryId]?.relationships?.[relName]?.data || [])
		.filter(rel => !allExistingFilesRemoved && currentFileIds.has(rel.id))
		.map(rel => rel.id)

	$: uploadQueryParams = { parentType, parentId, childType, childId }
	$: uploadQueryString = Object
		.keys(uploadQueryParams)
		.map(key => uploadQueryParams[key] && `${key}=${uploadQueryParams[key]}`)
		.filter(Boolean)
		.join('&')
	$: uploadUrl = `/api/v1/files?${uploadQueryString}`

	const appendFiles = moreFiles => {
		for (let i = 0; i < moreFiles.length; i++) {
			const id = Math.random().toString()
			uploading[id] = { start: new Date() }
			const fd = new FormData()
			fd.append('file', moreFiles.item(i))

			uploaderControllers[id] = new AbortController()

			internalUploading[id] = {
				name: moreFiles.item(i).name,
				bytes: moreFiles.item(i).size,
				uploader: fetch(uploadUrl, {
					method: 'POST',
					body: fd,
					signal: uploaderControllers[id].signal,
				})
					.then(response => {
						if (response.status !== 201) {
							delete uploading[id]
							throw response
						}
						return response.json()
					})
					.then(json => {
						uploading[id].end = new Date()
						uploading[id].file = json
						delete uploaderControllers[id]
						dispatch('add', json)
					}),
			}
		}
	}
	const handleDrop = event => {
		appendFiles(event.dataTransfer.files)
		dragover = false
	}
	const uploadFiles = ({ target: { files } }) => {
		appendFiles(files)
	}
	const removeFile = internalTempId => {
		if (uploaderControllers[internalTempId]) uploaderControllers[internalTempId].abort()
		const fileId = uploading[internalTempId]?.file?.data?.id
		delete uploading[internalTempId]
		uploading = { ...uploading }
		delete internalUploading[internalTempId]
		internalUploading = { ...internalUploading }
		if (uploaderControllers[internalTempId]) uploaderControllers[internalTempId].abort()
		dispatch('remove', { fileId })
	}
</script>

<style>
	.alert {
		font-size: 0.82rem;
	}
	.dashed-border {
		border: 1px dashed var(--falcon-alert-secondary-color);
	}
</style>

{#each originalStillPresentFileIds as fileId}
	<div class="alert alert-secondary py-1 mb-2">
		<div class="row align-items-center">
			<div class="col">
				<ExternalLink url="/api/v1/files/{fileId}/download">
					{form.data[fileId]?.attributes?.originalFilename}
				</ExternalLink>
				({humanReadableBytes(form.data[fileId]?.attributes?.bytes)})
			</div>
			<div class="col-auto text-end">
				<button class="btn btn-sm btn-link py-0 text-danger" on:click={() => dispatch('remove', { fileId })}>
					<span class="fa fa-trash-alt"></span>
				</button>
			</div>
		</div>
	</div>
{/each}

{#each Object.keys(internalUploading) as id}
	{@const file = internalUploading[id]}
	<div class="alert alert-secondary py-1 mb-2">
		<div class="row align-items-center">
			<div class="col">
				{#if form.data[id]?.attributes?.folder}
					<ExternalLink url="/api/v1/files/{id}/download">
						{file.filename}
					</ExternalLink>
				{:else}
					{file.name}
				{/if}
				({humanReadableBytes(file.bytes)})
				{#await file.uploader}
					<span class="text-warning">
						<span class="fas fa-spinner fa-pulse"></span>
					</span>
				{:then value}
					<span class="text-success">
						<span class="fa fa-check"></span>
					</span>
				{:catch error}
					<span class="text-danger">
						<span class="fa fa-exclamation-circle"></span>
					</span>
				{/await}
			</div>
			<div class="col-auto text-end">
				<button class="btn btn-sm btn-link py-0" on:click={() => removeFile(id)}>
					<span class="text-danger">
						{#await file.uploader}
							<span class="fa fa-stop-circle"></span>
						{:then value}
							<span class="fa fa-trash-alt"></span>
						{:catch error}
							<!-- nothing to do here I think? -->
						{/await}
					</span>
				</button>
			</div>
		</div>
	</div>
{/each}

<div class="alert alert-primary py-1 mb-0" class:dashed-border={dragover}>
	<div class="row">
		<div
			class="col gx-0"
			on:dragover|preventDefault={() => { dragover = true }}
			on:dragleave|preventDefault={() => { dragover = false }}
			on:drop|preventDefault={handleDrop}
		>
			<label for={inputId} class="mb-0 px-3" style="width: 100%; cursor: pointer;">
				Click or Drop to Add
			</label>
			<input
				style="display: none;"
				type="file"
				multiple
				class="form-control form-control-sm"
				id={inputId}
				on:input={uploadFiles}
			>
		</div>
	</div>
</div>
