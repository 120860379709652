<script>
	import { dateFormatter } from '@/shared/util/date-formatter.js'
	import dlv from 'dlv'

	const TZ = 'T12:00:00.000Z'
	// const formatMMDD = dateFormatter('{MM}/{DD}')
	const formatMM = dateFormatter('{MM}')
	const formatDD = dateFormatter('{DD}')

	export let opportunity

	$: firstPickup = dlv(opportunity, 'attributes.firstAvailablePickup')
	$: lastPickup = dlv(opportunity, 'attributes.lastAvailablePickup')
	$: firstDropoff = dlv(opportunity, 'attributes.firstAvailableDropoff')

	$: firstPickupDate = firstPickup && new Date(firstPickup + TZ)
	$: lastPickupDate = lastPickup && new Date(lastPickup + TZ)
	$: firstDropoffDate = firstDropoff && new Date(firstDropoff + TZ)

	$: firstPickupMM = firstPickup && formatMM(firstPickupDate)
	$: firstPickupDD = firstPickup && formatDD(firstPickupDate)
	$: lastPickupMM = lastPickup && formatMM(lastPickupDate)
	$: lastPickupDD = lastPickup && formatDD(lastPickupDate)
	$: firstDropoffMM = firstDropoff && formatMM(firstDropoffDate)
	$: firstDropoffDD = firstDropoff && formatDD(firstDropoffDate)

	$: pickupSameMonth = firstPickupMM && firstPickupMM === lastPickupMM

	$: firstPickupString = firstPickup
		? `${firstPickupMM}/${firstPickupDD}`
		: 'N/A'
	$: lastPickupString = pickupSameMonth
		? lastPickupDD
		: (
			lastPickup
				? `${lastPickupMM}/${lastPickupDD}`
				: 'ASAP'
		)
</script>

<span class:text-danger={!firstPickup}>{firstPickupString}</span>-<span>{lastPickupString}</span>

{#if firstDropoff}
	<br>
	Del. Rest. {firstDropoffMM}/{firstDropoffDD}
{/if}
