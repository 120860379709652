import template from '@/component/BlankUiView.svelte'
import { get } from '@/lib/json-fetch.js'
import { listUsers } from '@/lib/controller/user.js'
import { keyBy } from '@/shared/util/key-by.js'

export default {
	data: {
		title: 'Reports',
	},
	template,
	defaultChild: 'overview',
	resolve: async () => {
		const response = await listUsers({ get })
		return {
			usersById: keyBy(response.data, 'id'),
		}
	},
}
