<script>
	const SUFFIX = /\((.+)\)$/
	export let value
	$: vehicles = (value || '')
		.split(';')
		.map(s => s.trim())
		.filter(Boolean)
		.map(name => {
			const out = { name }
			const match = SUFFIX.exec(name)
			if (match) {
				out.name = out.name.replace(`(${match[1]})`, '').trim()
				const parts = match[1].split(',').map(s => s.trim())
				if (parts.includes('DNR')) out.broke = true
				if (parts.includes('Top Load')) out.via = 'TL'
				else if (parts.includes('Enclosed')) out.via = 'Enc.'
			}
			return out
		})
</script>

{#each vehicles as v}
	<p class="mb-0">
		{v.name}
		{#if v.via}
			<strong class="text-info">
				{v.via}
			</strong>
		{/if}
		{#if v.broke}
			<span class="text-warning" title="Does Not Run">
				<span class="fa fa-car-crash"></span>
			</span>
		{/if}
	</p>
{/each}
