<script>
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import SelfIcon from '@/component/SelfIcon.svelte'
	import { currentUser } from '@/service/store/current-user.js'

	export let row
	export let column
	export let includedById
</script>

<PlainLink {row} {column} {includedById}>
	<span slot="after">
		{#if row.id === $currentUser?.id}
			<span style="top: -0.4em; display: inline-block; position: relative;">
				<SelfIcon />
			</span>
		{/if}
	</span>
</PlainLink>
