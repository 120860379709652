import { shippingMethod } from '../value/shipping-method.js'

export default {
	description: 'Data available for emails sent from an opportunity, like lead or quote emails.',
	type: 'object',
	properties: {
		note: {
			description: 'Custom Markdown-formatted note when sending email. Only available if set in template.',
			type: 'string',
		},
		id: {
			description: 'The opportunity identifier.',
			type: 'string',
		},
		isAutoQuoteDuplicate: {
			description: 'This opportunity is likely a duplicate of a previous Auto-Quote lead.',
			type: 'boolean',
		},
		couldNotVerifyVehicle: {
			description: 'True if any vehicle could not be verified as accurate from Central Dispatch.',
			type: 'boolean',
		},
		locationNotSupported: {
			description: 'True if the pickup or dropoff location is outside the contiguous USA and Alaska, e.g. if the pickup is in Canada.',
			type: 'boolean',
		},
		totalQuote: {
			description: 'The sum of all vehicle "quote" values.',
			type: 'number',
			format: 'double',
		},
		shipVia: {
			description: 'The overall shipping method.',
			type: 'string',
		},
		firstAvailableDropoff: {
			description: 'Delivery restriction date.',
			type: 'string',
		},
		signableDocumentUrl: {
			description: 'The URL which will direct the email recipient to a signable document.',
			type: 'string',
		},
		vehicles: {
			description: 'The vehicles for this opportunity.',
			type: 'array',
			minItems: 1,
			items: {
				type: 'object',
				properties: {
					quote: {
						description: 'The sum of the carrier pay, broker fee, and billing adjustments.',
						type: 'number',
						format: 'double',
					},
					year: {
						description: 'The year of the vehicle.',
						type: 'integer',
					},
					make: {
						description: 'The make of the vehicle.',
						type: 'string',
					},
					model: {
						description: 'The model of the vehicle.',
						type: 'string',
					},
					shippingMethod: {
						description: 'Method of shipping: ' + Object.keys(shippingMethod).join(', '),
						type: 'string',
						enum: Object.keys(shippingMethod),
					},
				},
			},
		},
		assigned: {
			description: 'The OnTrack user assigned to this opportunity.',
			type: 'object',
			properties: {
				email: {
					description: 'The address used in the Reply-To of the email.',
					type: 'string',
				},
				name: {
					description: 'The name of the OnTrack user.',
					type: 'string',
				},
				phone: {
					description: 'The phone number of the OnTrack user.',
					type: 'string',
				},
			},
		},
		customer: {
			description: 'The customer for this opportunity.',
			type: 'object',
			properties: {
				company: {
					description: 'Name of the company for this opportunity.',
					type: 'string',
				},
				firstName: {
					description: 'The first name of the customer.',
					type: 'string',
				},
				lastName: {
					description: 'The first name of the customer.',
					type: 'string',
				},
				email: {
					description: 'The address this email will be sent to.',
					type: 'string',
				},
				phone: {
					description: 'The primary phone number of customer.',
					type: 'string',
				},
			},
		},
		pickup: {
			description: 'Information about the pickup location.',
			type: 'object',
			properties: {
				firstAvailablePickup: {
					description: 'The date that the vehicles are first available for picking up.',
					type: 'string',
				},
				actualPickup: {
					description: 'Will be set if the related date is set on the opportunity.',
					type: 'string',
				},
				company: {
					description: 'Name of company if the pickup location is a terminal or similar.',
					type: 'string',
				},
				address: {
					description: 'The pickup location address.',
					type: 'object',
					properties: {
						line1: {
							description: 'The first line of the address. Not usually set for leads or quotes.',
							type: 'string',
						},
						line2: {
							description: 'The second line of the address. Not usually set for leads or quotes.',
							type: 'string',
						},
						city: {
							description: 'The city name of the address.',
							type: 'string',
						},
						state: {
							description: 'The state abbreviation of the address.',
							type: 'string',
						},
						zip: {
							description: 'The postal code of the address.',
							type: 'string',
						},
					},
				},
			},
		},
		deliver: {
			description: 'Information about the delivery location.',
			type: 'object',
			properties: {
				actualDeliver: {
					description: 'Will be set if the related date is set on the opportunity.',
					type: 'string',
				},
				company: {
					description: 'Name of company if the pickup location is a terminal or similar.',
					type: 'string',
				},
				address: {
					description: 'The delivery location address.',
					type: 'object',
					properties: {
						line1: {
							description: 'The first line of the address. Not usually set for leads or quotes.',
							type: 'string',
						},
						line2: {
							description: 'The second line of the address. Not usually set for leads or quotes.',
							type: 'string',
						},
						city: {
							description: 'The city name of the address.',
							type: 'string',
						},
						state: {
							description: 'The state abbreviation of the address.',
							type: 'string',
						},
						zip: {
							description: 'The postal code of the address.',
							type: 'string',
						},
					},
				},
			},
		},
	},
}
