<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { router } from '@/service/router.js'
	import { idString } from '@/lib/id-string.js'

	export let row

	$: text = idString(row.relationships?.resource?.data)
	$: id = row.relationships?.resource?.data?.id
	$: type = row.relationships?.resource?.data?.type
</script>

{#if id}
	{#if type === 'opportunity'}
		<a href={router.makePath('app.opportunities.edit.opportunityId', { opportunityId: id }, { inherit: false })} title="Opportunity {text}">
			{text}
			<NamedIcon name="opportunity" />
		</a>
	{:else}
		{text}
	{/if}
{/if}
