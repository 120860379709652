<script>
	import InputType from '@/component/atom/InputType.svelte'
	import InputSelectId from '@/component/atom/InputSelectId.svelte'
	import { addressStates } from '@/shared/value/address-states.js'
	export let form
	export let parentRelId
	export let relName
	export let readonly
	export let onCreate
	$: id = form.data[parentRelId]?.relationships?.[relName]?.data?.id
</script>

<InputType
	type="text"
	label="Line 1"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'line1' ]}
/>

<InputType
	type="text"
	label="Line 2"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'line2' ]}
/>

<InputType
	type="text"
	label="City"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'city' ]}
/>

<InputSelectId
	label="State"
	bind:form
	{readonly}
	{id}
	{onCreate}
	options={addressStates}
	keypath={[ 'attributes', 'state' ]}
/>

<InputType
	type="text"
	label="Zip"
	bind:form
	{readonly}
	{id}
	{onCreate}
	keypath={[ 'attributes', 'zip' ]}
/>
