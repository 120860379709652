import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'

let ac // only one report fetchable, ever, at all

export const fetchReport = ({ api, url, params }) => {
	if (ac) ac.abort()
	ac = new AbortController()
	return api.get(
		url + objectToJsonApiQuery(params || {}),
		{
			method: 'GET',
			signal: ac.signal,
		},
	)
		.then(r => {
			if (r.body?.errors) return { errors: r.body.errors }
			else return r.body
		})
		.catch(error => {
			if (error.body?.errors?.length) return { errors: error.body.errors }
			else return { errors: [ error ] }
		})
}
