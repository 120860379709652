import { BadRequest, NotFound } from '@/lib/exceptions.js'
import { opportunity as opportunityTag, autoQuote as autoQuoteTag } from '@/lib/tags.js'
import { type } from '@/shared/model/opportunity.model.js'

const autoQuoteStatuses = {
	incoming: 'The opportunity has not been fully processed.',
	lead: 'The opportunity requires human intervention to be processed.',
	quote: 'The opportunity has a calculated total tariff available.',
}

export const summary = `
	Fetch an Opportunity.
`

export const description = `
	Fetches an opportunity record containing no PII, primarily meant for retrieval of the
	calculated tariff, for presentation to an end user. It is accessed using an access
	token provided after submitting an opportunity with an Auto-Quote Campaign identifier.

	Processing the opportunity can take some time, so be sure to check the status to validate
	that processing has completed.

	Opportunities are not available once they have been approved by the customer.
`

export const tags = [
	autoQuoteTag,
	opportunityTag,
]

export const responses = {
	200: {
		description: `
			A very minimal opportunity record.
		`,
		content: {
			'application/json': {
				schema: {
					type: 'object',
					properties: {
						data: {
							type: 'object',
							properties: {
								id: {
									type: 'string',
									description: 'The opportunity identifier.',
								},
								type: {
									type: 'string',
									description: 'The opportunity type. (Will always be "opportunity".)',
								},
								attributes: {
									type: 'object',
									properties: {
										status: {
											type: 'string',
											description: 'The following statuses are used:\n'
												+ Object
													.keys(autoQuoteStatuses)
													.map(key => `- \`${key}\`: ${autoQuoteStatuses[key]}`)
													.join('\n'),
										},
									},
								},
								meta: {
									type: 'object',
									properties: {
										totalTariff: {
											type: 'number',
											description: 'If the Auto-Quote calculation was successful, this value will be the calculated total tariff.',
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
	401: {
		description: `
			The request could not be authenticated.
		`,
	},
}

export const handler = async (services, req) => {
	if (!req.query?.opportunityId || !req.query?.accessToken) throw new BadRequest('Opportunity ID and access token must be provided.')
	const { documents } = await services.mongodb.find({
		filter: {
			type,
			_id: req.query.opportunityId,
			'attributes.accessToken': req.query.accessToken,
		},
	})
	if (documents.length !== 1) throw new BadRequest('Could not locate opportunity for ID and token.')

	const status = documents[0].attributes.status
	if (!autoQuoteStatuses[status]) throw new NotFound('Opportunity not found, possibly cancelled or settled.')

	let totalTariff
	if (status === 'quote') for (const vehicle of documents[0]._included.filter(inc => inc.type === 'vehicle')) {
		totalTariff = totalTariff || 0
		totalTariff += (vehicle.attributes.originalCarrierPay || 0)
		totalTariff += (vehicle.attributes.originalBrokerFee || 0)
	}

	return {
		json: true,
		status: 200,
		body: {
			data: {
				id: documents[0]._id,
				type,
				attributes: { status },
				meta: totalTariff ? { totalTariff } : undefined,
			},
		},
	}
}
