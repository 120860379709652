<script>
	import SimpleMenuList from '@/component/SimpleMenuList.svelte'
	export let asr

	const list = [
		{
			path: 'app.accounting.carriers',
			title: 'Carriers',
			description: 'Manage payments to carriers.',
		},
		{
			path: 'app.accounting.customers',
			title: 'Customers',
			description: 'Manage credit-card payments from customers.',
		},
		{
			path: 'app.referrers',
			title: 'Referrers',
			description: 'Manage payments and reporting to referrers.',
		},
	]
</script>

<SimpleMenuList
	{asr}
	{list}
	label="Accounting"
/>
