import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	opportunity: false,
	leg: false,
	job: false,
}

export const type = 'received-email'

export default {
	description: 'Emails received by MCS OnTrack, e.g. when a carrier accepts an assigned order and Central Dispatch sends an update email.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the received email.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-immutable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				awsMessageId: {
					description: 'The message identifier assigned by AWS, maybe the SNS message? Unsure.',
					type: 'string',
				},
				senderMessageId: {
					description: 'The message identifier assigned by the SMTP/IMAP sender.',
					type: 'string',
				},
				senderEmail: {
					description: 'The email address of the one who sent the message.',
					type: 'string',
				},
				receiverEmail: {
					description: 'The email address of the receiver, aka SES.',
					type: 'string',
				},
				subject: {
					description: 'The email subject, parsed by SES.',
					type: 'string',
				},
				timestamp: {
					description: 'The sent date, or maybe SES handling date? Hard to say. It is in ISO-8601 format though.',
					type: 'string',
					format: 'date-time',
				},
				html: {
					description: 'The HTML email body, if set.',
					type: 'string',
				},
				text: {
					description: 'The text email body, if set.',
					type: 'string',
				},
				centralDispatchDsid: {
					description: 'The ID within Central Dispatch used for later lookups, if it can be found.',
					type: 'string',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				opportunity: {
					description: 'Reference to the opportunity associated with this job, if the ID can be parsed from the email.',
					...jsonApiRelationshipSingular('opportunity'),
				},
				leg: {
					description: 'Reference to the opportunity leg associated with this job, if the opportunity ID can be parsed from the email.',
					...jsonApiRelationshipSingular('leg'),
				},
				job: {
					description: 'Reference to the job processing this email. This is set downstream by the job itself.',
					...jsonApiRelationshipSingular('job'),
				},
			},
		},
	},
}
