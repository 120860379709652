<script>
	import Navbar from './_AccountingNavbar.svelte'
	export let asr
</script>

<Navbar {asr} label="QuickBook Changes">
	<p class="mb-0 text-muted">
		Any opportunities with notable changes since the last time
		they were synced to QuickBooks.
	</p>
</Navbar>

<uiView />
