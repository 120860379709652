import template from './Create.svelte'
import { type } from '@/shared/model/company.model.js'
import { load } from 'jsonapi-svelte-form/mapper'

export default {
	data: {
		title: 'Create Company',
	},
	template,
	resolve: async () => ({ form: load({ data: { id: 'GID0', type } }, 0) }),
}
