import template from './LeadToOrderConversion.svelte'
import { dateRangeQueryParams } from './_date-range-from-query.js'

export default {
	data: {
		title: 'Lead to Order Conversion | Report',
	},
	template,
	querystringParameters: dateRangeQueryParams,
	resolve: async (_, querystringParams) => ({ querystringParams }),
}
