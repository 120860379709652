<script>
	import InputType from '@/component/atom/InputType.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { legPickupDropoffToGoogleMapsLink } from '@/lib/leg-pickup-dropoff-to-google-maps-link.js'
	import { legPickupDropoffToCentralDispatchSearch } from '@/lib/leg-pickup-dropoff-to-central-dispatch-search.js'
	import { legToStateTitleIcon } from '@/lib/opportunity-to-state-and-icon.js'

	import DispatchLeg from './dispatch/DispatchLeg.svelte'
	import EditLeg from './leg/EditLeg.svelte'

	const MAX_ADDITIONAL_TERMS_CHARS = 60

	export let api
	export let form
	export let companyData
	export let anyAre
	export let legIndex
	export let legId
	export let overallMiles
	export let legRels
	export let totalAdjustedCarrierPay
	export let totalOriginalCarrierPay

	$: status = form.data[form.primaryId]?.attributes?.status
	$: heldOrCancelled = form.data[form.primaryId]?.attributes?.held || form.data[form.primaryId]?.attributes?.cancelled
	$: leadOrQuote = status === 'lead' || status === 'quote'
	$: previousLegId = legRels[legIndex - 1]?.id
	$: lastLeg = form.data[legRels[legRels.length - 1]?.id]
	let creatableLegResource
	$: {
		if (lastLeg) {
			creatableLegResource = {
				attributes: {
					pickupContact: 'previousLeg',
					pickupLocation: 'previousLeg',
					dropoffContact: 'customer',
					dropoffLocation: 'custom',
				},
			}
		}
	}
	$: legLocations = getPickupDropoffForLeg({ opportunityId: form.primaryId, previousLegId, legId, resourcesById: form.data })
	$: mapsLink = legPickupDropoffToGoogleMapsLink(legLocations)
	$: cdLink = legPickupDropoffToCentralDispatchSearch(legLocations)
	$: stateTitleIcon = legToStateTitleIcon(form.data[legId])
	$: legIsDelivered = stateTitleIcon.state === 'delivered'
	$: carrierPayDifference = totalOriginalCarrierPay - totalAdjustedCarrierPay
	$: additionalTerms = form.data[legId]?.attributes?.centralDispatchAdditionalTerms
	$: additionalTermsTooLong = additionalTerms?.length > MAX_ADDITIONAL_TERMS_CHARS
</script>

<div class="card mb-3">
	<div class="card-header pt-2 pb-0" class:bg-light={leadOrQuote}>
		<div class="row align-items-center">
			{#if !leadOrQuote}
				<div class="col-auto">
					<h5 class="m-0 mb-1">
						Leg {legIndex + 1}
						<small style="color: #9fa9ac;">of {legRels.length}</small>
					</h5>
				</div>
				{#if stateTitleIcon}
					<div class="col-auto">
						<h5 class="m-0 mb-1">
							<span
								class="badge ms-3 bg-light text-dark"
								style="background-color: #e4e6ee !important;"
							>
								<NamedIcon name={stateTitleIcon.icon} />
								{stateTitleIcon.title}
							</span>
						</h5>
					</div>
				{/if}
			{/if}
			{#if mapsLink}
				<div class="col-auto mb-1">
					<ExternalLink url={mapsLink}>
						Route
					</ExternalLink>
				</div>
			{/if}
			{#if cdLink}
				<div class="col-auto mb-1">
					<ExternalLink url={cdLink}>
						CD Search
					</ExternalLink>
				</div>
			{/if}
			{#if status === 'order'}
				<div class="col-auto">
					<div style="display: inline-block; width: 170px;">
						<InputType
							label="Leg Miles"
							labelWidth="90px"
							type="number"
							hideArrows
							bind:form
							id={legId}
							readonly={legIsDelivered}
							keypath={[ 'attributes', 'miles' ]}
						/>
					</div>
					<span class="text-muted">
						of
						{overallMiles || '0'}
						overall
					</span>
				</div>
			{/if}
		</div>
	</div>
	<div class="card-body bg-light pt-0">
		{#if leadOrQuote}
			<hr class="mt-0 mb-2">
		{/if}
		<EditLeg
			pickup={true}
			bind:form
			{api}
			{legId}
			{legIndex}
			{companyData}
			readonly={legIsDelivered}
			previousLegId={legIndex > 0 && legRels[legIndex - 1]?.id}
		/>
		<EditLeg
			pickup={false}
			bind:form
			{api}
			{legId}
			{legIndex}
			{companyData}
			readonly={legIsDelivered}
			legCount={legRels.length}
		/>
		{#if !leadOrQuote}
			<hr class="mt-2">
			<div class="row g-0">
				<div class="col-auto pt-2" style="width: 90px;">
					<strong>Dispatch</strong>
				</div>
				<div class="col">
					<DispatchLeg
						bind:form
						disabled={heldOrCancelled}
						{api}
						{companyData}
						{anyAre}
						{legIndex}
						{legId}
						{legRels}
						legState={stateTitleIcon.state}
						{totalAdjustedCarrierPay}
						{totalOriginalCarrierPay}
					/>
				</div>
			</div>
		{/if}
	</div>
</div>
