import template from './History.svelte'
import { getOpportunityById, listOpportunityHistories, listOpportunityNotes } from '@/lib/controller/opportunity.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'
import { listUsers } from '@/lib/controller/user.js'
import { keyBy } from '@/shared/util/key-by.js'
import { notFoundRedirect } from '@/lib/not-found-redirect.js'

export default {
	data: {
		title: 'History',
	},
	template,
	resolve: async (_, { opportunityId }) => {
		const [ opportunityBody, { data: histories, included }, { data: users }, archivedNotes ] = await Promise.all([
			getOpportunityById({ get }, opportunityId).catch(error => ({ error })),
			listOpportunityHistories({ get }, opportunityId, { include: 'users' }),
			listUsers({ get }),
			listOpportunityNotes({ get }, opportunityId, { 'filter[hasArchived]': true, include: [ 'files', 'users' ] }),
		])
		notFoundRedirect(opportunityBody, 'opportunity', opportunityId)
		return {
			form: load(opportunityBody, 0),
			archivedNotes,
			histories,
			historiesIncluded: included,
			users,
			usersById: keyBy(users, 'id'),
		}
	},
}
