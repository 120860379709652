<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import PlainTextRel from '@/component/table-cells/PlainTextRel.svelte'
	import OpportunityLink from './jobs/OpportunityLink.svelte'
	import JobName from './jobs/JobName.svelte'
	import JobStatus from './jobs/JobStatus.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'id',
		'opportunity',
		'jobPath',
		'owner',
		'status',
		'created',
		'updated',
	]

	const nameToColumn = {
		id: {
			title: 'ID',
			component: PlainLink,
			keypath: 'id',
			link: row => asr.makePath('app.systemSettings.jobs.byId.jobId', { jobId: row.id }),
		},
		opportunity: {
			title: 'Opportunity',
			component: OpportunityLink,
			keypath: 'relationships.opportunity.data.id',
			link: opportunityId => asr.makePath('app.opportunities.edit.opportunityId.overview', { opportunityId }),
		},
		jobPath: {
			title: 'Name',
			keypath: 'attributes.parameters.jobPath',
			component: JobName,
		},
		owner: {
			title: 'Owner',
			relKeypath: 'attributes.name',
			keypath: 'relationships.user.data.id',
			component: PlainTextRel,
			alt: 'N/A',
			altClass: 'text-muted',
		},
		status: {
			title: 'Status',
			keypath: 'attributes.status',
			component: JobStatus,
		},
		created: {
			title: 'Created',
			component: DateTime,
			keypath: 'meta.created',
			time: true,
		},
		updated: {
			title: 'Last Updated',
			component: DateTime,
			keypath: 'meta.updated',
			time: true,
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
