<script>
	import SimpleMenuList from '@/component/SimpleMenuList.svelte'
	export let asr
	const lists = [
		[
			{
				path: 'app.referrers.create',
				title: 'Create Referrer',
				description: 'Create a new referrer identity.',
			},
			{
				path: 'app.referrers.list',
				title: 'Manage Referrers',
				description: 'Manage referrer identities, parents, and status.',
			},
		],
	]
</script>

<SimpleMenuList
	{asr}
	{lists}
	label="Referrers"
/>
