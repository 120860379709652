import template from '@/component/BlankUiView.svelte'
import { redirectIfLackingPermission } from '@/lib/redirect-if-lacking-permission.js'
import { MANAGE_APP } from '@/shared/value/user-permissions.js'

export default {
	data: {
		title: 'System',
	},
	template,
	defaultChild: 'overview',
	resolve: async () => redirectIfLackingPermission(MANAGE_APP),
}
