<script>
	import dlv from 'dlv'
	import { idString } from '@/lib/id-string.js'
	import { highlightedWord } from '@/service/store/highlighter.js'
	import { makeHighlightedText } from '@/lib/make-highlighted-text.js'

	export let row
	export let column

	$: text = column.keypath === 'id'
		? idString(row)
		: dlv(row, column.keypath)
	$: link = column && column.link && column.link(row)
	$: highlight = makeHighlightedText($highlightedWord, text)
</script>

<style>
	a.nowrap { white-space: nowrap; }
</style>

{#if $$slots.before}
	<slot name="before" resource={row} />
{/if}

<a href={link || '#'} class:nowrap={column.nowrap}>
	{#if highlight?.html}
		{@html highlight.html}
	{:else}
		{highlight.text}
	{/if}
</a>

{#if $$slots.after}
	<slot name="after" resource={row} />
{/if}
