<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { createEventDispatcher } from 'svelte'
	const dispatch = createEventDispatcher()

	export let title
	export let sort
	export let sortable
	export let columnClass
	export let keypath
	export let makeLink

	$: ascending = sort === keypath
	$: descending = sort === `-${keypath}`
</script>

<style>
	span {
		display: inline-block;
		width: 1.5em;
	}
</style>

<th scope="col" style="white-space: nowrap;" class={columnClass || keypath || ''}>
	{#if sortable && makeLink}
		<a href={makeLink({ keypath, ascending })} on:click={event => { dispatch('clicksort', { event, keypath, ascending }) }}>
			{title}
			<span>
				{#if ascending}
					<NamedIcon name="ascending" />
				{:else if descending}
					<NamedIcon name="descending" />
				{/if}
			</span>
		</a>
	{:else}
		{title || ''}
	{/if}
</th>
