<script>
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import { createEventDispatcher } from 'svelte'

	export let data
	export let included
	export let links
	export let errors
	export let request
	export let loading = false

	const dispatch = createEventDispatcher()
	const load = () => {
		loading = true
		dispatch('loadNext', { links, data, included })
	}
</script>

{#if links && links.next}
	<div class="card-footer text-center">
		{#if errors}
			{#each errors as error}
				<ErrorAlert {error} {request} />
			{/each}
		{/if}
		<button style="width: 150px;" class="btn btn-primary" disabled={loading} on:click={load}>
			{#if loading}
				Loading...
			{:else}
				Load more
			{/if}
		</button>
	</div>
{/if}
