<script>
	import { createEventDispatcher } from 'svelte'
	export let config = {}
	export let form = ''
	export let keypath = ''
	export let value = ''

	let className = ''
	export { className as class }

	const dispatch = createEventDispatcher()
	const oninput = event => {
		dispatch('input', { form, keypath, value: event.target.checked })
	}
</script>

<div class="form-check {className}">
	<input
		id={config.id}
		class="form-check-input"
		type="checkbox"
		placeholder={config.placeholder || ''}
		bind:checked={value}
		on:input={oninput}
	>
	<label for={config.id} class="form-label">
		<slot />
	</label>
</div>
