<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import SignableTemplate from '@/component/form/SignableTemplate.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { previousState } from '@/service/store/router-state.js'
	import { updateSignableTemplate } from '@/lib/controller/signable-template.js'

	export let asr
	export let api
	export let form

	$: justCreated = $previousState === 'app.systemSettings.signableTemplates.create'

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateSignableTemplate(api, form)
			.then(response => { form = response })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.systemSettings', null, { inherit: false })}>
		System
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<a href={asr.makePath('app.systemSettings.signableTemplates', null, { inherit: false })}>
		Signable Templates
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Edit "{form.data[form.primaryId]?.attributes?.name || 'N/A'}"
	</span>
	{#if justCreated}
		<span class="badge bg-success">Created new Signable Template 🎉</span>
	{/if}
</FormHeaderMain>

<SignableTemplate bind:form/>

<FormErrorsOther {form} />

<FormCommitButton {form} on:click={save} />
