import { opportunityStatus } from '../value/opportunity-status.js'
import { jsonApiRelationshipSingular, jsonApiRelationshipList } from '../util/model-builder-helpers.js'
import { opportunityAlertCodes } from '../value/opportunity-alert-codes.js'

export const $selfFragment = {
	user: false,
	referrer: false,
	ccTransactions: false,
	customerContact: true,
	customerAddress: true,
	vehicles: true,
	legs: true,
	postings: false,
	autoQuoteCampaign: false,
}

export const type = 'opportunity'

export default {
	type: 'object',
	additionalProperties: false,
	required: [
		'attributes',
	],
	properties: {
		id: {
			description: 'Globally unique identifier for this opportunity.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-imported',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'status',
			],
			properties: {
				status: {
					description: 'Status of the opportunity.',
					enum: Object.keys(opportunityStatus),
				},
				batsId: {
					description: 'Property used to link an OnTrack opportunity to a cancelled BATS opportunity.',
					type: 'string',
				},
				customerNotes: {
					description: `
						Free-form notes from the person originating this [opportunity]. This will not appear on the customers
						invoice, dispatch sheets, or anywhere else. MCS staff are allowed to edit this field.
					`,
					type: 'string',
				},
				legDates: {
					description: `
						The most recent date-only values from the equivalent "leg.dates" property for all
						legs. If legs move "backwards" the "forward" date values will be removed, e.g. if
						the maximum "forward" leg was marked as delivered than \`legDates.deliver\` would
						be set, but if that leg was then manually marked as not-delivered, than \`legDates.deliver\`
						would be removed. System-set properties, made available as aliases for convenience.
					`,
					type: 'object',
					additionalProperties: false,
					properties: {
						firstDispatched: {
							description: 'The date that the first leg became fully dispatched.',
							type: 'string',
							format: 'date-time',
						},
						lastDispatched: {
							description: 'The date that the last leg became fully dispatched.',
							type: 'string',
							format: 'date-time',
						},
						firstReceivedContract: {
							description: 'The contractual pickup date of the first leg.',
							type: 'string',
							format: 'date',
						},
						firstReceivedActual: {
							description: 'The actual pickup date of the first leg.',
							type: 'string',
							format: 'date',
						},
						lastReceivedContract: {
							description: 'The contractual pickup date for the last leg.',
							type: 'string',
							format: 'date',
						},
						lastReceivedActual: {
							description: 'The actual pickup date for the last leg.',
							type: 'string',
							format: 'date',
						},
						firstDeliveredContract: {
							description: 'The contractual dropoff date of the first leg.',
							type: 'string',
							format: 'date',
						},
						firstDeliveredActual: {
							description: 'The actual dropoff date of the first leg.',
							type: 'string',
							format: 'date',
						},
						lastDeliveredContract: {
							description: 'The contractual dropoff date of the last leg.',
							type: 'string',
							format: 'date',
						},
						lastDeliveredActual: {
							description: 'The actual dropoff date of the last leg.',
							type: 'string',
							format: 'date',
						},
						// TODO these are sort of "legacy" properties that are kind of unclear. Most places
						//  that use them should actually be using one of the first/list, and if those are
						//  insufficient there should be a new property with a more specific name.
						post: {
							description: 'The most recent date+time that all legs were successfully posted to Central Dispatch.',
							type: 'string',
							format: 'date-time',
						},
						assign: {
							description: 'The most recent date+time that all legs were successfully assigned to a carrier.',
							type: 'string',
							format: 'date-time',
						},
						dispatch: {
							description: 'The most recent date+time that all legs were successfully dispatched (contractually signed) via Central Dispatch.',
							type: 'string',
							format: 'date-time',
						},
						receive: {
							description: `
								The most recent date+time as near as can be known that all carriers have picked up all
								vehicles for all legs.
							`,
							type: 'string',
							format: 'date-time',
						},
						deliver: {
							description: `
								The most recent date+time as near as can be known that all carriers have delivered
								all vehicles for all legs.
							`,
							type: 'string',
							format: 'date-time',
						},
					},
				},
				miles: {
					description: 'The number of miles for the overall opportunity route.',
					type: 'number',
				},
				leadPickupDate: {
					description: 'The original date from the lead, as it comes in the API.',
					type: 'string',
					// not formatted!
				},
				firstAvailablePickup: {
					description: 'The date that the vehicle(s) are first available for picking up.',
					type: 'string',
					format: 'date',
				},
				lastAvailablePickup: {
					description: 'The latest date that the vehicle can be picked up. Not typically set.',
					type: 'string',
					format: 'date',
				},
				firstAvailableDropoff: {
					description: 'The first date that the vehicle can be dropped off. Not typically set.',
					type: 'string',
					format: 'date',
				},
				quoted: {
					description: 'The date+time that the opportunity was *first* converted to a quote.',
					type: 'string',
					format: 'date-time',
				},
				ordered: {
					description: 'The date+time that the opportunity was *first* converted to an order.',
					type: 'string',
					format: 'date-time',
				},
				firstDispatched: {
					description: 'DEPRECATED: do not use. This schema property can go away as soon as a migration is run to delete.',
					type: 'string',
					format: 'date-time',
				},
				firstInvoiced: {
					description: 'The earliest date that any CC transaction was processed successfully for the opportunity.',
					type: 'string',
					format: 'date',
				},
				firstPickup: {
					description: 'The contractual pickup date of the first leg of the opportunity, ignoring the date type (terms).',
					type: 'string',
					format: 'date',
				},
				lastDropoff: {
					description: 'The contractual dropoff date of the last leg of the opportunity, ignoring the date type (terms).',
					type: 'string',
					format: 'date',
				},
				held: {
					description: 'The date+time that the opportunity was marked as in-hold.',
					type: 'string',
					format: 'date-time',
				},
				cancelled: {
					description: 'The date+time that the opportunity was cancelled.',
					type: 'string',
					format: 'date-time',
				},
				duplicateIds: {
					description: 'Identifiers of other opportunities that duplicate this one.',
					type: 'array',
					items: {
						type: 'string',
					},
				},
				accessToken: {
					description: 'If set, user can make a formatted request to `/api/v1/opportunity` to get limited details about it.',
					type: 'string',
				},
				autoQuoteLocationSupported: {
					description: 'Set to true if the Auto-Quote process determines the location is inside US territory.',
					type: 'boolean',
				},
				autoQuoteAlreadySent: {
					description: 'Set to true if an Auto-Quote was sent from a duplicate lead.',
					type: 'boolean',
				},
				autoQuoteDecisions: {
					description: 'The decisions made in calculating the auto-quote.',
					type: 'object',
					additionalProperties: false,
					properties: {
						errors: {
							type: 'array',
							items: {
								type: 'object',
								additionalProperties: false,
								properties: {
									message: { type: 'string', description: 'Human-readable' },
									code: { type: 'string', description: 'Machine-readable' },
								},
							},
						},
						milesMin: { type: 'number' },
						milesMax: { type: 'number' },
						vehicleCount: {
							type: 'string',
							enum: [ 'One', 'Two', 'Three', 'FourOrMore' ],
						},
						brokerFeePerVehicle: { type: 'number' },
						vehicles: {
							type: 'array',
							items: {
								type: 'object',
								additionalProperties: false,
								properties: {
									vehicleId: { type: 'string' },
									vehicleSize: {
										type: 'string',
										enum: [ 'small', 'medium', 'large' ],
									},
									carrierPay: { type: 'number' },
								},
							},
						},
					},

				},
				alerts: {
					description: 'List of alert messages concerning this opportunity. These are added by system processes, but can be removed by a human.',
					type: 'array',
					items: {
						type: 'object',
						additionalProperties: false,
						properties: {
							level: {
								type: 'string',
								description: 'The level of the alert. Used to display differently on leads.',
								enum: [
									'success',
									'danger',
									'warning',
									'info',
								],
							},
							code: {
								type: 'string',
								description: 'The computer-readable alert code.',
								enum: Object.keys(opportunityAlertCodes),
							},
							message: {
								type: 'string',
								description: 'The alert message.',
							},
						},
					},
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'OnTrack user who is assigned this opportunity.',
					...jsonApiRelationshipSingular('user'),
				},
				referrer: {
					description: 'The entity who referred this opportunity to MCS.',
					...jsonApiRelationshipSingular('referrer'),
				},
				autoQuoteCampaign: {
					description: 'Auto-Quote campaign used for this opportunity.',
					...jsonApiRelationshipSingular('auto-quote-campaign'),
				},
				ccTransactions: {
					description: 'Reference to the Central Dispatch posting job for this order.',
					...jsonApiRelationshipList('cc-transaction'),
				},
				customerContact: {
					description: 'Contact information for the person securing the opportunity.',
					...jsonApiRelationshipSingular('contact'),
				},
				customerAddress: {
					description: 'Address information for the person securing the opportunity.',
					...jsonApiRelationshipSingular('address'),
				},
				vehicles: {
					description: `
						Reference to the vehicles for this order. Within an order with multiple vehicles, all
						vehicles must have identical legs. If they have different legs, they must be different orders.
					`,
					...jsonApiRelationshipList('vehicle'),
				},
				legs: {
					description: 'Reference to the legs for this order.',
					...jsonApiRelationshipList('leg'),
				},
				postings: {
					description: 'Reference to the Central Dispatch posting job for this order.',
					...jsonApiRelationshipList('job'),
				},
			},
		},
	},
}
