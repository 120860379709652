<script>
	import PlainLink from '@/component/table-cells/PlainLink.svelte'
	import { soonestExpirationState } from '@/lib/central-dispatch-leg-order-expiration.js'

	export let row
	export let column
	export let includedById

	$: expirationState = soonestExpirationState({ opportunity: row, includedById, nowMillis: Date.now() })
</script>

<PlainLink {row} {column} {includedById}>
	<span slot="after">
		{#if expirationState?.message}
			<p class="text-{expirationState.color} mb-0">
				<small>
					{expirationState.message}
				</small>
			</p>
		{/if}
	</span>
</PlainLink>
