<script>
	let className = ''
	export let config = {}
	export { className as class }
</script>

<div class="row flex-between-center {className}">
	<div class="col-auto">
		<h5>
			{config.title.text}
		</h5>
	</div>
	{#if config.linkText}
		<div class="col-auto fs--1 text-600">
			<span class="mb-0">
				{config.normalContent.text}
			</span>
			<span>
				<a href={config.link}>
					{config.linkText}
				</a>
			</span>
		</div>
	{/if}
</div>
