<script>
	import dlv from 'dlv'

	export let row
	export let column
	export let includedById

	$: resource = includedById[dlv(row, column.keypath)]
	$: atts = resource?.attributes || {}
</script>

{#if atts.line1}
	<p class="mb-0">{atts.line1}</p>
{/if}
{#if atts.line2}
	<p class="mb-0">{atts.line2}</p>
{/if}
<p class="mb-0">
	{atts.city ? `${atts.city}, ` : ''}{atts.state || ''} {atts.zip || ''}
</p>
