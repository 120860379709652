<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { currentUser } from '@/service/store/current-user.js'
	import { put } from '@/lib/json-fetch.js'

	export let asr
	export let customReports

	$: isTobias = $currentUser?.attributes?.name?.toLowerCase().includes('tobias')
	$: canGenerateCustomReports = $currentUser?.attributes?.permissions?.generateCustomReports

	$: lists = [
		[
			{
				path: 'app.reports.upcomingReceived',
				title: 'Pickups (By Contract Date)',
				description: 'Opportunities with Legs that are scheduled to be picked up.',
			},
			{
				path: 'app.reports.recentlyReceived',
				title: 'Pickups (Actual)',
				description: 'Opportunities with Legs that have been recently marked as picked up.',
			},
			{
				path: 'app.reports.upcomingDelivered',
				title: 'Deliveries (By Contract Date)',
				description: 'Opportunities with Legs that are scheduled to be delivered.',
			},
			{
				path: 'app.reports.recentlyDelivered',
				title: 'Deliveries (Actual)',
				description: 'Opportunities with Legs that have been recently marked as delivered.',
			},
			{
				path: 'app.reports.recentlyDispatched',
				title: 'Recently Dispatched',
				description: 'Opportunities that have been recently dispatched to Central Dispatch.',
			},
		],
		[
			{
				path: 'app.reports.leadReferralSources',
				title: 'Lead Referral Sources',
				description: 'Conversions by referral source for all leads.',
			},
			{
				path: 'app.reports.referralDataExport',
				title: 'Referral Data Export',
				description: 'Raw per-opportunity data export for referral sources, filtered by contractual pick up date.',
			},
			{
				path: 'app.reports.leadToOrderConversion',
				title: 'Lead to Order Conversion',
				description: 'Conversions by TBA to orders, filtered by lead/create date, for all leads.',
			},
			{
				path: 'app.reports.salesByAssigned',
				title: 'Sales by TBA',
				description: 'Orders by TBA, filtered by date the lead was converted to an order.',
			},
		],
	]

	const idToDeletePrompt = {}
	const idToDeleting = {}
	const deleteReport = id => {
		idToDeleting[id] = true
		const report = customReports.find(r => r.id === id)
		report.meta.archived = new Date().toISOString()
		put(`/api/v1/customReports/${id}`, { body: { data: report } })
			.then(() =>  {
				customReports = [
					...customReports.filter(r => r.id !== id),
				]
			})
			.catch(response =>  {
				console.log('error deleting custom report', response)
			})
	}
</script>

<!--
<p>
	Want a refresher on OnTrack data? You should read
	<a href={asr.makePath('app.welcome', null, { inherit: false })}>this welcome message</a>
	to get familiar.
</p>
-->

<h1 class="mb-0">Reports</h1>
<hr class="mb-3">

<div class="row">
	<div class="col-xs-12 col-md-6">
		<h2 class="mb-3">System Reports</h2>
		{#each lists as l}
			<ul class="list-group mb-3">
				{#each l as { path, title, description, owners }}
					<li class="list-group-item">
						<div class="fw-bold">
							<a href={asr.makePath(path)}>{title}</a>
						</div>
						{description}
					</li>
				{/each}
			</ul>
		{/each}
	</div>
	{#if canGenerateCustomReports}
		<div class="col-xs-12 col-md-6">
			<div class="row align-items-center">
				<div class="col">
					<h2 class="mb-3">My Reports</h2>
				</div>
				<div class="col-auto">
					<a href={asr.makePath('app.customReports.create', null, { inherit: false })} class="btn btn-primary btn-sm text-light">
						<NamedIcon name="add" />
						Add Custom Report
					</a>
				</div>
			</div>
			{#if customReports?.length}
				<ul class="list-group mb-3">
					{#each customReports as { id, attributes: { name, about } }}
						<li class="list-group-item">
							<div class="row">
								<div class="col">
									<div class="fw-bold">
										<a href={asr.makePath('app.customReports.view.customReportId', { customReportId: id })}>{name}</a>
									</div>
									{#if about}
										{about}
									{/if}
								</div>
								<div class="col-auto">
									{#if !idToDeletePrompt[id]}
										<button class="btn btn-sm btn-outline-danger" on:click={() => { idToDeletePrompt[id] = true }}>
											<NamedIcon name="trash" />
										</button>
									{:else}
										<p>Are you sure you want to delete it?</p>
										<div>
											{#if idToDeleting[id]}
												<NamedIcon name="loading" />
											{/if}
											<button class="btn btn-sm btn-danger" on:click={deleteReport(id)} disabled={idToDeleting[id]}>
												<NamedIcon name="trash" />
												Yes, delete it
											</button>
											<button class="btn btn-sm btn-secondary" on:click={() => { idToDeletePrompt[id] = false }} disabled={idToDeleting[id]}>
												<NamedIcon name="cancel" />
												Do not delete
											</button>
										</div>
									{/if}
								</div>
							</div>
						</li>
					{/each}
				</ul>
			{/if}
		</div>
	{/if}
</div>
