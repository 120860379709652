<script>
	export let type
	export let id
</script>

<h2>Ope, not found!</h2>
<div class="row">
	<div class="col-xs-12 col-md-6 col-lg-4">
		<div class="card">
			<div class="card-body">
				<p class="card-text">The {type} with the identifier <code>{id}</code> could not be found.</p>
				<p class="card-text">Is it possible that you don't have permission to access it? If you do have permission, maybe it was deleted?</p>
			</div>
		</div>
	</div>
</div>
