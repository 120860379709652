<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputCheckbox from '@/component/atom/InputCheckbox.svelte'
	import InputSelectId from '@/component/atom/InputSelectId.svelte'
	import InputType from '@/component/atom/InputType.svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import ConfirmActionSimple from '@/component/ConfirmActionSimple.svelte'
	import { vehicleType } from '@/shared/value/vehicle-type.js'
	import { addressStates } from '@/shared/value/address-states.js'
	import { shippingMethod } from '@/shared/value/shipping-method.js'
	import { createEventDispatcher } from 'svelte'
	const dispatch = createEventDispatcher()

	export let vehicleId
	export let form
	export let onCreate
	export let canBeRemoved
	export let anyAre
	export let legCount

	$: status = form.data[form.primaryId]?.attributes?.status
	$: props = form.data[vehicleId]?.attributes || {}
	$: imageQuery = [ props.year, props.make, props.model ].filter(Boolean).join(' ')
	$: calculatedTariff = (form.data[vehicleId]?.attributes?.originalCarrierPay || 0) + (form.data[vehicleId]?.attributes?.originalBrokerFee || 0)

	$: vehicleIsNew = /^GID\d+$/.test(vehicleId)
</script>

<style>
	span.finance-input {
		display: inline-block;
		min-width: 13em;
		max-width: 45%;
	}
</style>

{#if status === 'lead' || status === 'quote' || vehicleIsNew}
	<div class="row align-items-center">
		<div class="col">
			<span class="finance-input">
				<InputType
					label="Carrier Pay"
					labelWidth="120px"
					type="double"
					icon="money"
					bind:form
					id={vehicleId}
					keypath={[ 'attributes', 'originalCarrierPay' ]}
				/>
			</span>
			<span class="finance-input">
				<InputType
					label="Broker Fee"
					labelWidth="120px"
					type="double"
					icon="money"
					bind:form
					id={vehicleId}
					keypath={[ 'attributes', 'originalBrokerFee' ]}
				/>
			</span>
		</div>
		<div class="col-auto mb-2" style="width: 10em;">
			<strong>Tariff</strong>
			{calculatedTariff}
		</div>
	</div>
{:else}
	<div class="row">
		<div class="col-xs-12 col-md-8 col-lg-6">
			<InputType
				label="Original CP"
				labelWidth="165px"
				type="double"
				readonly
				bind:form
				id={vehicleId}
				keypath={[ 'attributes', 'originalCarrierPay' ]}
			/>
			<InputType
				label="Original BF"
				labelWidth="165px"
				type="double"
				readonly
				bind:form
				id={vehicleId}
				keypath={[ 'attributes', 'originalBrokerFee' ]}
			/>
		</div>
		<div class="col-xs-12 col-md-8 col-lg-6">
			<InputType
				label="Billing Adjustment"
				icon="money"
				labelWidth="165px"
				type="double"
				bind:form
				id={vehicleId}
				keypath={[ 'attributes', 'tariffAdjustment' ]}
			/>
			<div class="input-group input-group-sm mb-2">
				<span class="input-group-text justify-content-center" style="width: 165px;">
					<label for="adjusted-tariff-{vehicleId}" class="form-label-sm mb-0" style="font-weight: normal !important;">
						<NamedIcon name="money" />
						Adjusted Tariff
					</label>
				</span>
				<input
					readonly
					type="text"
					value={(form.data[vehicleId]?.attributes?.originalCarrierPay || 0) + (form.data[vehicleId]?.attributes?.originalBrokerFee || 0) + (form.data[vehicleId]?.attributes?.tariffAdjustment || 0)}
					class="form-control"
					id="adjusted-tariff-{vehicleId}"
				>
			</div>
		</div>
	</div>
{/if}

<div class="row row g-md-2 g-lg-4">
	<div class="col-xs-12 col-md-6">
		<InputType
			type="number"
			label="Year"
			labelWidth="8ch"
			inputMinWidth="75px"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'year' ]}
		/>
		<InputType
			type="text"
			label="Make"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'make' ]}
		/>
		<InputType
			type="text"
			label="Model"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'model' ]}
		/>
		<InputType
			type="text"
			label="Color"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'color' ]}
		/>
		<InputSelectId
			label="Type"
			labelWidth="8ch"
			bind:form
			{onCreate}
			options={vehicleType}
			id={vehicleId}
			keypath={[ 'attributes', 'type' ]}
		/>
	</div>
	<div class="col-xs-12 col-md-6">
		<InputType
			type="text"
			label="Plate#"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'plateNumber' ]}
		/>
		<InputSelectId
			label="State"
			labelWidth="6ch"
			bind:form
			{onCreate}
			options={addressStates}
			id={vehicleId}
			keypath={[ 'attributes', 'plateState' ]}
		/>
		<InputType
			type="text"
			label="VIN"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'vin' ]}
		/>
		<InputType
			type="text"
			label="Lot#"
			labelWidth="8ch"
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'lot' ]}
		/>
		<InputSelectId
			label="Ship Via"
			labelWidth="8ch"
			bind:form
			{onCreate}
			options={shippingMethod}
			id={vehicleId}
			keypath={[ 'attributes', 'shippingMethod' ]}
		/>
		{#if anyAre.posted || anyAre.assigned}
			<div class="text-end">
				{#if anyAre.assigned}
					<small class="text-warning">
						{#if legCount > 1}
							One or more legs are
						{:else}
							The order is
						{/if}
						already assigned.
					</small>
				{/if}
				{#if anyAre.posted}
					<span class="text-info" data-bs-toggle="tooltip" data-bs-placement="left" title="Changes will update the post${legCount > 1 ? 's' : ''} on Central Dispatch automatically on save.">
						<span class="fa fa-info-circle"></span>
					</span>
				{/if}
			</div>
		{/if}
		{#if canBeRemoved}
			<div class="text-end mb-2">
				<ConfirmActionSimple
					light
					danger
					iconName="trash"
					action="Remove Vehicle"
					confirm="Removing"
					on:click={() => dispatch('removeVehicle')}
				/>
			</div>
		{/if}
	</div>
</div>

<div class="row">
	<div class="col">
		<InputCheckbox
			bind:form
			{onCreate}
			id={vehicleId}
			keypath={[ 'attributes', 'doesNotRun' ]}
		>
			Vehicle does not run
		</InputCheckbox>
	</div>
	<div class="col-auto">
		<ExternalLink url="https://images.google.com/search?tbm=isch&q={imageQuery}">
			<img src="/asset/falcon/logo/g.png" alt="" width="20">
		</ExternalLink>
	</div>
</div>
