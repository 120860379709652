<script>
	import { createEventDispatcher } from 'svelte'
	import InputFiles from './InputFiles.svelte'
	import { Relationship } from 'jsonapi-svelte-form'
	import { clone } from '@/shared/util/clone.js'

	export let inputId
	export let form
	export let formId
	export let relName
	export let fileParentType
	export let fileParentId
	export let fileChildType
	export let fileChildId
	export let uploading
	export let clearUploadingFiles

	const dispatch = createEventDispatcher()
</script>

<Relationship isArray bind:form id={formId} name={relName} type="file" on:change let:add let:remove>
	<InputFiles
		bind:form
		bind:uploading
		bind:clearUploadingFiles
		{inputId}
		{relName}
		parentType={fileParentType}
		parentId={fileParentId}
		childType={fileChildType}
		childId={fileChildId}
		on:add={ ({ detail: { data } }) => { let copy = clone(data); add(data.id); dispatch('add', copy) } }
		on:remove={ ({ detail: { fileId } }) => { remove(fileId); dispatch('remove', fileId) } }
	/>
</Relationship>
