<script>
	import { Relationship } from 'jsonapi-svelte-form'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { updateOpportunity, patchOpportunity } from '@/lib/controller/opportunity.js'
	import { put, patch } from '@/lib/json-fetch.js'
	import { clone } from '@/shared/util/clone.js'
	import { router } from '@/service/router.js'

	export let form
	export let users = []
	export let disableChanges

	$: userOptions = users.reduce((map, { id, attributes: { name, email, active } }) => {
		if (active || id === form.data[form.primaryId].relationships?.user?.data?.id) {
			map[id] = name || email || 'Unknown User?'
			if (!active) map[id] += ' (Inactive)'
		}
		return map
	}, {})
	$: atts = form.data[form.primaryId]?.attributes || {}
	$: status = atts.status || ''
	$: disabled = ![ 'loaded', 'unchanged', 'saved' ].includes(form.state)
	$: assignedUserChanged = form.changes?.[form.primaryId]?.find(({ path }) => path[0] === 'relationships' && path[1] === 'user')
	$: originalAssignedUserId = form.original[form.primaryId]?.relationships?.user?.data?.id
	$: creating = !form.data[form.primaryId]?.meta?.created

	$: canBeMovedBackToQuote = status === 'order' && !Object.keys(form.data[form.primaryId]?.attributes?.legDates || {}).length
	$: postedLegCount = form.data[form.primaryId]?.relationships?.legs?.data?.filter(rel => rel.type === 'leg')
		.map(rel => form.data[rel.id])
		.filter(leg => leg.attributes?.status && leg.attributes.status !== 'unpost')
		.length

	let clickedButton
	const statusToName = {
		lead: 'Lead',
		quote: 'Quote',
		order: 'Order',
	}
	$: name = statusToName[status] || 'Opportunity'
	$: buttonToConfirmText = {
		cancel: `cancel this ${name}?`,
		hold: `put this ${name} on Hold?`,
		open: `re-open this ${name}?`,
		quote: `${canBeMovedBackToQuote ? 'revert' : 'convert'} this ${name} to a Quote?`,
		order: `convert this ${name} to an Order?`,
	}
	$: buttonToActionText = {
		cancel: 'Cancel',
		decline: 'Declined',
		hold: 'Hold',
		open: 'Re-Open',
		quote: (canBeMovedBackToQuote ? 'Revert' : 'Convert') + ' to Quote',
		order: 'Convert to Order',
	}

	const errorHandler = ({ errors, state }) => {
		form.errors = errors
		form.state = state
	}
	const successHandler = response => {
		for (const key in response) {
			form[key] = response[key]
		}
		return response
	}
	const confirmClick = () => {
		const attributes = {}
		if (clickedButton === 'cancel') attributes.cancelled = true
		if (clickedButton === 'hold') attributes.held = true
		if (clickedButton === 'open') {
			attributes.cancelled = null
			attributes.held = null
		}
		if (clickedButton === 'quote' || clickedButton === 'order') attributes.status = clickedButton
		form.state = 'saving'
		patchOpportunity({ patch }, form, {
			id: form.primaryId,
			type: form.data[form.primaryId].type,
			meta: form.data[form.primaryId].meta,
			attributes,
		})
			.then(successHandler)
			.then(() => {
				clickedButton = false
			})
			.catch(errorHandler)
	}
	const reassignUser = () => {
		form.state = 'saving'
		patchOpportunity({ patch }, form, {
			id: form.primaryId,
			type: form.data[form.primaryId].type,
			meta: form.data[form.primaryId].meta,
			relationships: {
				user: {
					data: {
						type: 'user',
						id: form.data[form.primaryId].relationships?.user?.data?.id,
					},
				},
			},
		})
			.then(successHandler)
			.catch(errorHandler)
	}

	// Be very careful not to mutate the form state here!
	const legAttributesToKeep = [
		'firstAvailablePickup',
		'pickupType',
		'dropoffType',
	]
	const copyOpportunity = () => {
		let gid = 0
		const makeId = () => `GID${gid++}`
		const formData = clone(form.data)

		const data = {
			...formData[form.primaryId],
			id: makeId(),
		}
		data.attributes.status = 'lead'
		delete data.attributes.quoted
		delete data.meta

		delete data.relationships.ccTransactions
		delete data.relationships.postings

		for (const legId of Object.keys(formData).filter(id => formData[id].type === 'leg')) {
			delete formData[legId].relationships.shipper
			const atts = {}
			for (const att of legAttributesToKeep) atts[att] = formData[legId].attributes[att]
			formData[legId].attributes = atts
		}

		const includedIds = Object.keys(formData).filter(id => id !== form.primaryId)
		const includedIdToGid = {}
		for (const id of includedIds) includedIdToGid[id] = makeId()

		const remapRelationships = relationships => {
			for (const relName in (relationships || {})) {
				if (Array.isArray(relationships[relName].data)) {
					for (const rel of relationships[relName].data) {
						rel.id = includedIdToGid[rel.id]
					}
					relationships[relName].data = relationships[relName].data.filter(rel => rel.id && rel.type)
					if (!relationships[relName].data.length) delete relationships[relName]
				} else {
					relationships[relName].data.id = includedIdToGid[relationships[relName].data.id]
					if (!relationships[relName].data.id || !relationships[relName].data.type) delete relationships[relName]
				}
			}
		}

		remapRelationships(data.relationships)

		const included = []
		for (const includedId of includedIds) if (includedIdToGid[includedId]) {
			const resource = formData[includedId]
			resource.id = includedIdToGid[resource.id]
			remapRelationships(resource.relationships)
			included.push(resource)
		}

		const referrerId = form.data[form.primaryId]?.relationships?.referrer?.data?.id
		if (referrerId) {
			data.relationships = data.relationships || {}
			data.relationships.referrer = { data: { id: referrerId, type: 'referrer' } }
		}

		router.go('app.opportunities.create', { cloneOpportunityId: form.primaryId, clone: JSON.stringify({ data, included, gid }) }, { inherit: false })
	}
</script>

<div class="row mt-1 gx-2">
	{#if disabled}
		<div class="col-auto">
			<small>
				{#if form.state === 'saving'}
					<em>Saving changes...</em>
				{:else}
					<em>Unsaved changes, please save before changing status.</em>
				{/if}
			</small>
		</div>
	{/if}
	<div class="col-auto" style="max-width: 22em;">
		<InputSelectRel
			short
			readonly={disableChanges}
			label="Assigned To"
			labelWidth="110px"
			bind:form
			options={userOptions}
			id={form.primaryId}
			name="user"
			type="user"
		/>
	</div>
	{#if !creating && !postedLegCount}
		<div class="col-auto" class:col={!disabled} class:col-auto={disabled}>
			<fieldset disabled={disabled || clickedButton || disableChanges}>
				{#if atts.cancelled || atts.held}
					<button class="btn btn-outline-primary btn-sm" on:click={() => clickedButton = 'open'}>
						<NamedIcon name="open" />
						Re-Open
					</button>
				{:else}
					{#if canBeMovedBackToQuote}
						<button class="btn btn-outline-secondary btn-sm" on:click={() => { clickedButton = 'quote' }}>
							<NamedIcon name="quote" />
							Revert to Quote
						</button>
					{/if}
					<button class="btn btn-outline-warning btn-sm" class:me-1={status !== 'lead'} on:click={() => clickedButton = 'cancel'}>
						<NamedIcon name="cancel" />
						Cancel
					</button>
					{#if status !== 'lead'}
						<button class="btn btn-outline-warning btn-sm" on:click={() => clickedButton = 'hold'}>
							<NamedIcon name="hold" />
							Place on Hold
						</button>
					{/if}
					{#if status !== 'delivered'}
						<a href="#create-copy-of-opportunity" class="btn btn-outline-primary btn-sm" on:click|preventDefault={copyOpportunity}>
							<span class="fa fa-copy"></span>
							Clone
						</a>
					{/if}
				{/if}
			</fieldset>
		</div>
	{/if}
	{#if postedLegCount}
		<div class="col-auto">
			<div class="btn-group dropstart">
				<button class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdown-legs-are-posted" data-bs-toggle="dropdown" aria-expanded="false">
					<span class="fa fa-question-circle"></span>
				</button>
				<div class="dropdown-menu p-3" style="max-width: 25em;" aria-labelledby="dropdown-legs-are-posted">
					<p class="text-dark mb-0">
						There are {postedLegCount} legs posted to Central Dispatch. Unpost them before
						cancelling this Opportunity.
					</p>
				</div>
			</div>
			<small class="text-muted">

			</small>
		</div>
	{/if}
</div>

{#if assignedUserChanged}
	<div class="row">
		<div class="col text-end">
			<p class="mt-2 mb-2">
				Are you sure you want to reassign?
			</p>
			<p class="mb-0">
				<Relationship bind:form id={form.primaryId} name="user" type="user" let:set>
					<button class="btn btn-secondary btn-sm" on:click={() => set(originalAssignedUserId)}>
						Cancel this Action
					</button>
				</Relationship>
				<button
					class="btn btn-sm btn-primary"
					on:click={reassignUser}
				>
					<span class="fa fa-user-friends fa-flip-horizontal"></span>
					Reassign
				</button>
			</p>
		</div>
	</div>
{/if}

{#if clickedButton}
	<div class="row">
		<div class="col text-end">
			<p class="mt-2 mb-2">
				Are you sure you want to {buttonToConfirmText[clickedButton]}
			</p>
			<p class="mb-0">
				<button class="btn btn-secondary btn-sm" on:click={() => clickedButton = false}>
					Cancel this Action
				</button>
				<button class="btn btn-{clickedButton === 'open' ? 'primary' : 'warning'} btn-sm" on:click={confirmClick} {disabled}>
					<NamedIcon name={clickedButton} />
					{buttonToActionText[clickedButton]}
				</button>
			</p>
		</div>
	</div>
{/if}
