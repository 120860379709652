import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	signableDocument: false,
	opportunity: false,
}

export const type = 'signable-document-visit'

export default {
	description: 'Record of a page-load from the customer, using the emailed token to authenticate.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the signable document.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta-immutable',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				ipAddress: {
					type: 'string',
					description: 'Every effort is taken to include the request\'s IP address for record keeping.',
				},
				userAgent: {
					type: 'string',
					description: 'If available, the full User-Agent header of the request loading this signable document.',
				},
				cleanUserAgent: {
					$ref: '#/components/schemas/user-agent',
				},
				cfCountry: {
					type: 'string',
					description: 'The country code of the originating request, provided by Cloudflare.',
				},
				cfRay: {
					type: 'string',
					description: 'The trace identifier, provided by Cloudflare for debugging purposes.',
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				signableDocument: {
					description: 'The signable document being visited.',
					...jsonApiRelationshipSingular('signable-document'),
				},
				opportunity: {
					description: 'The opportunity related to this signable document.',
					...jsonApiRelationshipSingular('opportunity'),
				},
			},
		},
	},
}
