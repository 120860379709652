<script>
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'

	export let legContact
	export let which

	$: contact = legContact?.[which]
</script>

<MaybeLink href={contact.terminalUrl}>
	{#if contact.terminalUrl}
		<NamedIcon name="terminal" />
	{/if}
	{#if contact.companyName}
		<NamedIcon name="company" />
		<strong>{contact.companyName}</strong>
		<br>
	{/if}
</MaybeLink>

{#if contact.name}
	{contact.name}
	<br>
{/if}

{#if contact.address}
	{contact.address}
	<CopyToClipboard string={contact.address} />
{/if}
