// IF it's been posted to CD
// AND if it's (the fourth day after first available OR after)
// THEN red flag in the list view and overview like "will be unposted from CD tomorrow"

const MILLIS_PER_HOUR = 60 * 60 * 1000 // 3_600_000

const soonestLegHoursToExpiration = ({ opportunity, nowMillis, includedById  }) => {
	let soonestHoursToExpiration
	const legIds = opportunity.relationships?.legs?.data?.map(rel => rel.id) || []
	for (const id of legIds)
		if (
			// any posted leg can potentially be expired
			includedById[id]?.attributes?.dates?.post
			// but once it's dispatched CD won't expire it
			&& !includedById[id]?.attributes?.dates?.dispatch
		) {
			const firstAvailableMillis = new Date(includedById[id].attributes.firstAvailablePickup + 'T12:00:00.000Z').getTime()
			// if (now - 4 days ago) is past the first available
			// CD will automatically expire the NEXT day
			// if it's a weekend we don't really know what happens yet...
			const expirationMillis = firstAvailableMillis + (MILLIS_PER_HOUR * 24 * 5)
			const hoursUntilExpiration = (expirationMillis - nowMillis) / MILLIS_PER_HOUR
			if (hoursUntilExpiration < 24) {
				soonestHoursToExpiration = hoursUntilExpiration
				break
			}
		}
	return soonestHoursToExpiration
}

export const soonestExpirationState = ({ opportunity, nowMillis, includedById }) => {
	let message
	let color
	let expired
	const hours = soonestLegHoursToExpiration({ opportunity, includedById, nowMillis })
	if (hours < 1) {
		message = 'Expired'
		color = 'danger'
		expired = true
	} else if (hours <= 24) {
		message = 'Expiring'
		color = 'warning'
	}
	return { message, color, expired }
}
