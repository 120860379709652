<script>
	import ExternalLink from '@/component/ExternalLink.svelte'
	import { getPickupDropoffForLeg } from '@/shared/util/get-pickup-dropoff-for-leg.js'
	import { legPickupDropoffToGoogleMapsLink } from '@/lib/leg-pickup-dropoff-to-google-maps-link.js'
	import { legPickupDropoffToCentralDispatchSearch } from '@/lib/leg-pickup-dropoff-to-central-dispatch-search.js'

	export let row
	export let includedById

	$: legId = row?.relationships?.legs?.data?.[0]?.id
	$: legLocations = getPickupDropoffForLeg({ opportunityId: row.id, legId, resourcesById: includedById })
	$: mapsLink = legPickupDropoffToGoogleMapsLink(legLocations)
	$: cdLink = legPickupDropoffToCentralDispatchSearch(legLocations)
</script>

{#if mapsLink}
	<ExternalLink url={mapsLink} nowrap>
		Google
	</ExternalLink>
	{#if cdLink}
		<br>
	{/if}
{/if}

{#if cdLink}
	<ExternalLink url={cdLink} nowrap>
		Central
	</ExternalLink>
{/if}
