<script>
	import InputType from '@/component/atom/InputType.svelte'

	export let form
	export let contactParentRelId
	export let contactRelName
	export let readonly

	$: contactId = form.data[contactParentRelId]?.relationships?.[contactRelName]?.data?.id

	const createContact = create => create({ relId: contactParentRelId, relName: contactRelName, type: 'contact' })
</script>

<InputType
	type="text"
	icon="phone"
	label="Primary"
	bind:form
	{readonly}
	id={contactId}
	onCreate={createContact}
	keypath={[ 'attributes', 'phonePrimary' ]}
/>

<InputType
	type="text"
	icon="phone"
	label="Alt1"
	bind:form
	{readonly}
	id={contactId}
	onCreate={createContact}
	keypath={[ 'attributes', 'phoneTwo' ]}
/>

<InputType
	type="text"
	icon="phone"
	label="Alt2"
	bind:form
	{readonly}
	id={contactId}
	onCreate={createContact}
	keypath={[ 'attributes', 'phoneThree' ]}
/>

<InputType
	type="text"
	icon="phone"
	label="Alt3"
	bind:form
	{readonly}
	id={contactId}
	onCreate={createContact}
	keypath={[ 'attributes', 'phoneFour' ]}
/>
