import { commitForm } from '@/lib/form-committer.js'
import { minimumDelay } from '@/lib/delay.js'

export const createCustomReport = async ({ post }, form, callback) => minimumDelay(900, commitForm({ form, request: post, url: '/api/v1/customReports', callback }))

export const updateCustomReport = async ({ put }, form) => minimumDelay(900, commitForm({ form, request: put, url: `/api/v1/customReports/${form.primaryId}` }))

export const listCustomReports = async ({ get }) => get('/api/v1/customReports').then(response => response.body)

export const getCustomReportById = async ({ get }, id) => get(`/api/v1/customReports/${id}`).then(response => response.body)
