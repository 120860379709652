<script>
	import { onMount } from 'svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import Table from '@/component/table/QbChanges.svelte'
	import { currentUser } from '@/service/store/current-user.js'
	import Navbar from './_QbChangesNavbar.svelte'

	let loading = true

	export let api
	export let asr
	export let data
	export let errors
	export let included

	onMount(() => {
		api.get('/api/v1/reports/qbChanges').then(response => {
			data = response.body.meta
			included = []
			loading = false
		}).catch(response => {
			errors = response.body.errors
		})
	})

	let saving
	const handleMarkingCompleted = ({ detail: { id, type, changes } }) => {
		saving = true
		const maximumDateInclusive = changes
			.sort((a, b) => new Date(a._created).getTime() - new Date(b._created).getTime())[0]
			._created
		api.post(`/api/v1/qbSyncs/${type}/${id}`, { body: { meta: { maximumDateInclusive } } }).then(response => {
			console.log('res', response)
			data = data.filter(d => !(d.id === id && d.type === type))
			saving = false
		}).catch(response => {
			saving = false
			if (response.body.links?.audit) response.body.errors.forEach(e => {
				e.meta = e.meta || {}
				e.meta.auditLink = response.body.links.audit
			})
			errors = [ ...response.body.errors ]
		})
	}
</script>

<Navbar {asr} current="outstanding" />

<div class="card mb-3">
	<div class="card-body p-0">
		{#if errors?.length}
			{#each errors as error}
				<ErrorAlert {error} />
			{/each}
		{/if}
		<fieldset disabled={saving}>
			<Table
				{asr}
				{data}
				{loading}
				{saving}
				readonly={$currentUser?.attributes?.role !== 'accounting'}
				on:completed={handleMarkingCompleted}
			/>
		</fieldset>
	</div>
</div>
