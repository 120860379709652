<script>
	export let level
	export let icon
	export let title
</script>

<div class="alert alert-{level || 'warning'}">
	{#if icon}
		<span class="fa fa-{icon}"></span>
	{/if}
	{#if title}
		<strong>{title}:</strong>
	{/if}
	<slot />
</div>
