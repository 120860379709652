<script>
	import { Form } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'
	import InputTextArea from '@/component/atom/InputTextArea.svelte'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import { resourceToLink } from '@/lib/resource-to-link.js'

	import { updateChanges } from '@/service/store/form-changes.js'
	$: { updateChanges(form?.changes) }

	export let form
	export let users
	export let asr

	$: userOptions = (users || []).reduce((map, r) => {
		map[r.id] = r.attributes.name
		if (!r.attributes.active) map[r.id] += ' (Inactive)'
		return map
	}, {})
	$: resourceRel = form.data[form.primaryId]?.relationships?.resource?.data
</script>

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Note Details
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<Form bind:form>
			<div class="mb-2">
				<InputTextArea
					label="Note"
					bind:form
					id={form.primaryId}
					keypath={[ 'attributes', 'note' ]}
				/>
			</div>
			<InputType
				type="date"
				label="Reminder"
				labelWidth="100px"
				bind:form
				id={form.primaryId}
				keypath={[ 'attributes', 'reminder' ]}
			/>
			<InputType
				type="date"
				label="Completed"
				labelWidth="100px"
				bind:form
				id={form.primaryId}
				keypath={[ 'attributes', 'completed' ]}
			/>
			<InputType
				type="date"
				label="Archived"
				labelWidth="100px"
				bind:form
				id={form.primaryId}
				keypath={[ 'attributes', 'archived' ]}
			/>
			<InputSelectRel
				label="Assigned"
				labelWidth="100px"
				bind:form
				options={userOptions}
				id={form.primaryId}
				name="assigned"
				type="user"
			/>
		</Form>
		{#if resourceRel}
			<dl class="row">
				<dt class="col-auto">{resourceRel.type?.[0].toUpperCase()}{resourceRel.type?.slice(1)}</dt>
				<dd class="col">
					<a href={resourceToLink(asr, resourceRel, false)}>{resourceRel?.id}</a>
				</dd>
			</dl>
		{/if}
	</div>
</div>
