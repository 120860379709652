<script>
	import { createEventDispatcher } from 'svelte'
	import FormCheckbox from '../common/FormCheckbox.svelte'
	import FormInput from '../common/FormInput.svelte'

	export let system
	export let name
	export let config = {}
	export let form = {}

	const dispatch = createEventDispatcher()
	const onsubmit = () => { dispatch('submit', { name, form }) }

	$: valid = form[name].email && form[name].password
	$: state = form[name]._state || form._state || {}
</script>

<form on:submit|preventDefault={onsubmit}>
	<fieldset disabled={state.submitting}>
		<div class="mb-3">
			<FormInput type="email" on:input config={config.email} bind:value={form[name].email} form={name} keypath="email" />
		</div>
		<div class="mb-3">
			<FormInput type="password" on:input config={config.password} bind:value={form[name].password} form={name} keypath="password" />
		</div>
		{#if !config.layout || config.layout === 'basic'}
			<div class="row flex-between-center">
				<div class="col-auto">
					<FormCheckbox class="mb-0 mt-2" on:input config={config.checkbox} bind:value={form[name].checkbox} form={name} keypath="checkbox">
						Remember me
					</FormCheckbox>
				</div>
				<div class="col-auto">
					<a href={$system.paths['authentication/simple/forgot-password']} class="fs--1">
						Forgot Password?
					</a>
				</div>
			</div>
		{:else}
			<FormCheckbox class="mb-0" on:input config={config.checkbox} bind:value={form[name].checkbox} form={name} keypath="checkbox">
				Remember me
			</FormCheckbox>
		{/if}

		{#if state.errors}
			{#each state.errors as error}
				<div class="alert alert-danger" role="alert">
					{error.detail}
				</div>
			{/each}
		{/if}

		<div class="mb-3">
			<button disabled={!valid} type="submit" name="submit" class="btn btn-primary d-block w-100 mt-3">
				Log in
			</button>
		</div>
	</fieldset>
</form>
