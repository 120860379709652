<script>
	import NamedIcon from './NamedIcon.svelte'
	export let form
	export let id
	export let keypath
	export let elementId
	export let errors
	export let icon
	export let label
	export let labelWidth = '85px'
	export let short

	$: hasChanges = !!form?.changes[id]?.find(c => c.path?.every((p, index) => keypath?.[index] === p))
</script>

<style>
	.input-group {
		padding: 1px;
	}
	.input-group.highlight {
		padding: 0;
		border: 1px solid var(--falcon-info);
		border-radius: 5px;
	}
</style>

<div class="input-group input-group-sm" class:has-validation={errors?.length} class:mb-2={!short} class:highlight={hasChanges}>
	<span class="input-group-text justify-content-center" style="width: {labelWidth};">
		<label for={elementId} class="form-label-sm mb-0" style="font-weight: normal !important;">
			{#if icon}
				<NamedIcon name={icon} />
			{/if}
			{label}
		</label>
	</span>
	<slot />
</div>
{#each (errors || []) as error}
	<div class="invalid-feedback">{error.title}</div>
{/each}
