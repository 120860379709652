<script>
	import ExternalLink from '@/component/ExternalLink.svelte'
	import CompanyIcons from '@/component/molecule/CompanyIcons.svelte'
	import { createEventDispatcher } from 'svelte'
	import { addressStates } from '@/shared/value/address-states.js'
	import { router } from '@/service/router.js'

	export let terminal
	export let includedById
	export let action
	export let readonly

	$: contact = includedById[terminal.relationships?.contact?.data?.id]
	$: address = includedById[terminal.relationships?.address?.data?.id]
	$: insurance = includedById[terminal.relationships?.insurance?.data?.id]
	$: insuranceAddress = includedById[includedById[insurance?.id]?.relationships?.address?.data?.id]

	$: isExpiredOrNoDate = !insurance?.attributes?.expiration
		|| new Date(insurance?.attributes?.expiration).getTime() <= Date.now()

	$: contactName = contact?.attributes?.name
		? contact.attributes.name
		: ((contact?.attributes?.firstName || '') + ' ' + (contact?.attributes?.lastName || '')).trim()

const dispatch = createEventDispatcher()
</script>

<div class="list-group-item list-group-item-action" class:disabled={readonly}>
	<div class="row">
		<div class="col col-md-6 col-sm-12">
			<h5 class="mb-1">
				<ExternalLink url={router.makePath('app.companies.edit.companyId.overview', { companyId: terminal.id }, { inherit: false })}>
					{terminal.attributes.name}
				</ExternalLink>
			</h5>
			<p class="mb-1">
				{address?.attributes?.city || 'N/A'},
				{addressStates[address?.attributes?.state] || 'N/A'}
				{address?.attributes?.zip || ''}
			</p>
			<p class="mb-1">
				<CompanyIcons company={terminal} />
			</p>
		</div>
		<div class="col col-md-6 col-sm-12">
			{#if contactName}
				<p class="mb-1">
					{contactName}
				</p>
			{/if}
			{#if contact?.attributes?.emailPrimary}
				<p class="mb-1">
					<span class="fa fa-at"></span>
					{contact.attributes.emailPrimary}
				</p>
			{/if}
			{#if contact?.attributes?.phonePrimary}
				<p class="mb-1">
					<span class="fa fa-phone"></span>
					{contact.attributes.phonePrimary}
				</p>
			{/if}
		</div>
		<div class="col col-md-6 col-sm-12">
			{#if insurance?.attributes}
				<p class="mb-1">
					<small>
						<span class="fa fa-car-crash"></span>
						{insurance.attributes.companyName || '(Unnamed Insurer)'}
					</small>
				</p>
				<p class="mb-1">
					<small>
						<span style="padding: 0 4px;">
							<span class="fa fa-calendar-times"></span>
						</span>
						<span class="{isExpiredOrNoDate ? 'text-danger' : ''}">
							{insurance.attributes.expiration || 'No Expiration'}
						</span>
					</small>
				</p>
			{/if}
		</div>
		{#if !readonly}
			<div class="col-auto">
				<button class="btn btn-primary" on:click={() => dispatch('click', terminal.id)}>
					{action}
				</button>
			</div>
		{/if}
	</div>
</div>
