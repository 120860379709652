<script>
	import { highlightedWord } from '@/service/store/highlighter.js'
	import { makeHighlightedText } from '@/lib/make-highlighted-text.js'

	export let text
	export let br
	$: highlight = makeHighlightedText($highlightedWord, text)
</script>

{#if highlight?.html}
	{@html highlight.html}
{:else if highlight?.text}
	{highlight.text}
{/if}

{#if br}
	<br>
{/if}
