<script>
	export let system
</script>

<svelte:head>
	<script src="{$system.CWD}vendor/falcon/assets/js/config.js"></script>

	<link rel="preconnect" href="https://fonts.gstatic.com">
	<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7cPoppins:300,400,500,600,700,800,900&display=swap" rel="stylesheet">
	<link href="{$system.CWD}vendor/falcon/vendors/overlayscrollbars/OverlayScrollbars.min.css" rel="stylesheet">

	{#if $system.isRTL}
		<link href="{$system.CWD}vendor/falcon/assets/css/theme-rtl.min.css" rel="stylesheet" id="style-rtl">
		<link href="{$system.CWD}vendor/falcon/assets/css/user-rtl.min.css" rel="stylesheet" id="user-style-rtl">
		<!--
		TODO right-to-left languages are not yet supported correctly, you need to do: document.querySelector('html').setAttribute('dir', 'rtl');
		-->
	{:else}
		<link href="{$system.CWD}vendor/falcon/assets/css/theme.min.css" rel="stylesheet" id="style-default">
		<link href="{$system.CWD}vendor/falcon/assets/css/user.min.css" rel="stylesheet" id="user-style-default">
	{/if}
</svelte:head>

<div aria-hidden="true" style="display: none;">
	<script src="{$system.CWD}vendor/falcon/vendors/lodash/lodash.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/list.js/list.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/overlayscrollbars/OverlayScrollbars.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/popper/popper.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/anchorjs/anchor.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/is/is.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/vendors/bootstrap/bootstrap.min.js"></script>
	<script src="{$system.CWD}vendor/falcon/assets/js/theme.js" async defer></script>
</div>

<main class="main" id="top">
	<slot></slot>
</main>
