<script>
	export let row

	let jobPath
	let actionName
	$: {
		const params = row.attributes.parameters || {}
		jobPath = params.jobPath
		actionName = params.actionName
	}
</script>

{jobPath}{#if jobPath === 'central-dispatch/action'}/{actionName}{/if}
