import { activate } from '@/lib/list-view-load-next.js'
import template from './List.svelte'

export default {
	data: {
		title: 'Job Inspector',
	},
	querystringParameters: [
		'jobPath',
		'status',
		'userId',
		'opportunityId',
		'legId',
	],
	template,
	activate,
	resolve: async (_, querystringParams) => ({ querystringParams }),
}
