<script>
	import SearchRadio from './SearchRadio.svelte'
	export let legId
	export let searchButton
	export let searchType
	export let searchString
</script>

<form on:submit|preventDefault>
	<div class="input-group input-group-sm">
		<span class="input-group-text justify-content-center" style="width: 120px;">
			<label for="leg-terminal-{legId}" class="form-label-sm mb-0" style="font-weight: normal !important;">
				{#if !searchButton}
					<span class="fa fa-search"></span>
				{/if}
				Search by
			</label>
		</span>
		<SearchRadio bind:searchType label="Name" />
		<SearchRadio bind:searchType label="City" />
		<SearchRadio bind:searchType label="State" />
		<input
			type="text"
			bind:value={searchString}
			class="form-control"
			id="leg-terminal-{legId}"
		>
		{#if searchButton}
			<button class="btn btn-primary" type="submit" on:click>
				<span class="fa fa-search"></span>
			</button>
		{/if}
	</div>
</form>
