<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let icon
	export let label
	export let key
	export let value
</script>

<style>
	.input-group .input-group-text label.form-label-sm {
		font-weight: normal !important;
	}
	label {
		width: 130px;
	}
	input.filter-param-key {
		max-width: 120px;
	}
	input.filter-value-key {
		/*max-width: 220px;*/
	}
</style>

<div class="col-xs-12 col-md-8 col-lg-6 col-xl-4">
	<div class="input-group input-group-sm mb-2">
		<span class="input-group-text">
			<label for={key} class="form-label-sm mb-0">
				{#if icon}
					<NamedIcon name={icon} />
				{/if}
				{label}
			</label>
		</span>
		{#if key}
			<input
				readonly
				type="text"
				value={key || ''}
				class="form-control filter-param-key"
			>
		{/if}
		<input
			readonly
			type="text"
			value={value || ''}
			class="form-control filter-value-key"
			id={key}
		>
	</div>
</div>
