<script>
	import { createEventDispatcher } from 'svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import InputFiles from '@/component/atom/InputFiles.svelte'
	import { createNoteForOpportunity } from '@/lib/controller/note.js'
	import { load } from 'jsonapi-svelte-form/mapper'
	import { currentUser } from '@/service/store/current-user.js'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import { generateNoteData } from './generate-note-data.js'

	export let api
	export let opportunityId
	// export let currentUserId
	export let usersById

	const dispatch = createEventDispatcher()

	let addingNote
	let uploadingFiles
	let note = ''
	let reminder = ''
	let assignedId = ''
	let errors
	let uploaderForm = load({ data: {} }, 1)

	$: stillUploadingFiles = Object.keys(uploadingFiles || {}).find(key => !uploadingFiles[key].file)
	$: needsReminderDate = reminder && !assignedId

	const addNote = () => {
		addingNote = true
		const waitForFilesToUpload = new Promise(resolve => {
			const waitLonger = () => {
				if (stillUploadingFiles) setTimeout(waitLonger, 50)
				else resolve(
					Object
						.keys(uploadingFiles || {})
						.map(key => {
							const { id, type } = uploadingFiles[key].file.data
							return { id, type }
						}),
				)
			}
			waitLonger()
		})
		waitForFilesToUpload
			.then(fileRels => createNoteForOpportunity(
				api,
				opportunityId,
				load({ data: generateNoteData({
					note,
					opportunityId,
					assignedId,
					reminder,
					fileRels,
				}) }, 1),
			))
			.then(response => {
				addingNote = false
				note = ''
				reminder = ''
				dispatch('created', response)
			})
			.catch(e => {
				addingNote = false
				errors = e
			})
	}
	const setReminder = event => {
		reminder = event.target.value
		assignedId = assignedId || $currentUser.id
	}
</script>

<fieldset disabled={addingNote}>
	<div class="row">
		<div class="col-xs-12 col-lg-8 mt-3">
			<div class="row">
				<div class="col">
					<label for="note-text" class="form-label">
						<NamedIcon name="comment" />
						New Note
					</label>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-6">
					<textarea bind:value={note} class="form-control" id="note-text" rows="2"></textarea>
				</div>
				<div class="col-xs-12 col-md-6 mt-3 mt-md-0">
					<div class="input-group input-group-sm">
						<span class="input-group-text justify-content-center" style="width: 7em;">
							<label for="note-reminder" class="form-label-sm mb-0" style="font-weight: normal !important;">
								Reminder
							</label>
						</span>
						<input
							type="date"
							value={reminder}
							class="form-control form-control-sm"
							id="note-reminder"
							on:input={setReminder}
						>
					</div>
					<div class="input-group input-group-sm mt-3">
						<span class="input-group-text justify-content-center" style="width: 7em;">
							<label for="note-assigned-user" class="form-label-sm mb-0" style="font-weight: normal !important;">
								Assigned
							</label>
						</span>
						<select
							class="form-select form-select-sm pe-5 ps-2"
							aria-label="Assigned User"
							id="note-assigned-user"
							bind:value={assignedId}
						>
							<option value="" selected="">Select a user...</option>
							{#each Object.keys(usersById) as userId}
								<option value={userId}>
									{usersById[userId].attributes.name || 'N/A'}
								</option>
							{/each}
						</select>
					</div>
					{#if needsReminderDate}
						<p class="mt-1 mb-0 text-warning">
							<small>If a note is assigned, it needs a reminder date.</small>
						</p>
					{/if}
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-lg-4 mt-3">
			<label for="note-attachment" class="form-label">
				<NamedIcon name="upload" />
				Add Attachments
			</label>
			<InputFiles bind:form={uploaderForm} inputId="note-attachment" parentType="opportunity" parentId={opportunityId} bind:uploading={uploadingFiles} />
		</div>
	</div>
	<div class="row mt-3">
		<div class="col-auto">
			<button class="btn btn-secondary btn-sm" on:click={() => dispatch('cancel')}>
				Cancel
			</button>
			<button class="btn btn-primary btn-sm" on:click={addNote} disabled={needsReminderDate}>
				<NamedIcon name="add" />
				Add Note
			</button>
			<span class="ps-3">
				{#if stillUploadingFiles}
					Uploading files, one moment...
				{/if}
			</span>
		</div>
	</div>
</fieldset>

{#if errors}
	<div class="mt-3">
		<FormErrorsOther form={errors} />
	</div>
{/if}
