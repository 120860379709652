import { activate } from '@/lib/list-view-load-next.js'
import template from './List.svelte'

export default {
	data: {
		title: 'Auto-Quote Campaigns',
	},
	template,
	activate,
}
