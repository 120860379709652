<script>
	import { router } from '@/service/router.js'

	export let asr
	export let dateRange
	export let selectableDateRanges
	export let querystringParams

	let customMinDate
	let customMaxDate

	const updateDate = (min, max) => router.go(
		null,
		{
			...(querystringParams || {}),
			dateRange: 'custom',
			dateMin: min || dateRange?.queryParam?.dateMin || '',
			dateMax: max || dateRange?.queryParam?.dateMax || '',
		},
		{ inherit: false },
	)
</script>

<style>
	.disabled {
		color: #d3d3d3 !important;
		border-color: #d3d3d3 !important;
	}
</style>

<div>
	<div class="row gx-2">
		<div class="col-auto">
			<div class="btn-group btn-group-sm" role="group" aria-label="Select a date range for this report">
				{#each selectableDateRanges as { label, params }}
					<a
						class="btn btn-outline-primary"
						class:active={dateRange?.type === params.dateRange}
						href={asr.makePath(null, { ...(querystringParams || {}), ...(params.dateRange === 'custom' ? (dateRange?.queryParam || {}) : {}), ...params }, { inherit: false })}
					>
						{label}
					</a>
				{/each}
			</div>
		</div>
		{#if dateRange?.min || dateRange?.max}
			<div class="col-auto">
				<div class="dropdown">
					<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<span class="fa fa-clock"></span>
					</button>
					<div class="dropdown-menu p-3 text-dark text-nowrap">
						<p>Precise date information.</p>
						<p class="mb-0"><strong>Earliest Date:</strong></p>
						<p class="mb-0">
							Local: {dateRange?.min?.toLocaleString()}
						</p>
						<p>
							UTC: {dateRange?.apiDates?.min}
						</p>
						<p class="mb-0"><strong>Latest Date:</strong></p>
						<p class="mb-0">
							Local: {dateRange?.max?.toLocaleString()}
						</p>
						<p class="mb-0">
							UTC: {dateRange?.apiDates?.max}
						</p>
					</div>
				</div>
			</div>
		{/if}
	</div>

	{#if dateRange?.type === 'custom'}
		<form on:submit|preventDefault={updateDate(customMinDate, customMaxDate)}>
			<div class="row mt-2 gx-2">
				<div class="col-auto" style="width: 18em;">
					<div class="input-group input-group-sm">
						<span class="input-group-text" id="custom-date-start">
							<span class="fa fa-calendar"></span>
							&nbsp;
							Start
						</span>
						<input
							value={dateRange?.queryParam?.dateMin || ''}
							on:input={event => { customMinDate = event.target.value }}
							type="date"
							class="form-control"
							aria-describedby="custom-date-start"
						>
					</div>
					<p class="form-text mb-0">
						Just past midnight.
					</p>
				</div>
				<div class="col-auto" style="width: 18em;">
					<div class="input-group input-group-sm">
						<span class="input-group-text" id="custom-date-end">
							<span class="fa fa-calendar"></span>
							&nbsp;
							End
						</span>
						<input
							value={dateRange?.queryParam?.dateMax || ''}
							on:input={event => { customMaxDate = event.target.value }}
							type="date"
							class="form-control"
							aria-describedby="custom-date-end"
						>
					</div>
					<p class="form-text mb-0">
						Up to midnight.
					</p>
				</div>
				<div class="col-auto">
					<button class="btn btn-sm btn-outline-secondary" type="submit" class:disabled={!customMinDate && !customMaxDate} disabled={!customMinDate && !customMaxDate}>
						<span class="fa fa-search"></span>
						Search
					</button>
				</div>
			</div>
		</form>
	{:else}
		<div class="row mt-2">
			<div class="col-auto form-text">
				{dateRange?.humanReadable || ''}
			</div>
		</div>
	{/if}
</div>
