export default {
	description: 'The error object defined by the JSON:API here: https://jsonapi.org/format/#errors',
	type: 'object',
	additionalProperties: false,
	properties: {
		status: {
			type: 'string',
		},
		code: {
			type: 'string',
		},
		title: {
			type: 'string',
		},
		detail: {
			type: 'string',
		},
		source: {
			type: 'object',
			additionalProperties: false,
			properties: {
				pointer: {
					type: 'string',
				},
				parameter: {
					type: 'string',
				},
			},
		},
		meta: {
			type: 'object',
		},
	},
}
