<script>
	import { createEventDispatcher, onMount } from 'svelte'
	import InputTextDisplayOnly from '@/component/atom/InputTextDisplayOnly.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'

	const MAX_ADDITIONAL_TERMS_CHARS = 60

	export let form
	export let readonly
	export let api
	export let legRels
	export let legId
	export let previousLegId
	export let legState
	export let totalOriginalCarrierPay

	let centralDispatchListingId = ''
	let carrierPay = ''
	let centralDispatchAdditionalTerms = ''
	let firstAvailablePickup = ''
	let onlyOntrackSync
	onMount(() => {
		centralDispatchListingId = form.data[legId]?.attributes?.centralDispatchListingId || ''
		carrierPay = form.data[legId]?.attributes?.carrierPay || ''
		centralDispatchAdditionalTerms = form.data[legId]?.attributes?.centralDispatchAdditionalTerms || ''
		firstAvailablePickup = form.data[legId]?.attributes?.firstAvailablePickup || ''
		if (!firstAvailablePickup && previousLegId) firstAvailablePickup = form.data[previousLegId]?.attributes?.firstAvailablePickup || ''
		if (!firstAvailablePickup) firstAvailablePickup = form.data[form.primaryId]?.attributes?.firstAvailablePickup || ''
	})

	$: carrierPayDifference = totalOriginalCarrierPay - parseInt(carrierPay || '0', 10)
	$: additionalTermsTooLong = centralDispatchAdditionalTerms?.length > MAX_ADDITIONAL_TERMS_CHARS

	const dispatch = createEventDispatcher()
	const postHandler = () => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'post',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
			data: {
				centralDispatchListingId,
				carrierPay: parseFloat(carrierPay),
				centralDispatchAdditionalTerms,
				firstAvailablePickup,
			},
		}))
	}
</script>

<div style="max-width: 32em;">
	<div class="input-group input-group-sm mb-2">
		<span class="input-group-text" id="carrier-search">
			Actual Carrier Pay
		</span>
		<input
			disabled={form.state === 'saving'}
			type="text"
			class="form-control"
			value={carrierPay}
			on:input={event => { carrierPay = event.target.value }}
		>
	</div>

	<table class="table table-sm table-borderless mb-0" style="font-size: smaller;">
		<tbody>
		<tr>
			<td class="p-0">
				<small>{#if legRels.length > 1}Total{/if} Original Carrier Pay</small>
			</td>
			<td class="p-0"><small>{totalOriginalCarrierPay}</small></td>
		</tr>
		<tr>
			<td class="p-0"><small>Difference</small></td>
			<td class="p-0">
				<small class:text-success={carrierPayDifference > 50} class:text-warning={carrierPayDifference < -50}>{carrierPayDifference}</small>
			</td>
		</tr>
		</tbody>
	</table>

	<InputTextDisplayOnly labelWidth="10em" label="Payment Method" value="Company Check" />
	<InputTextDisplayOnly labelWidth="10em" label="Payment Terms" value="10 Business Days" />
	<InputTextDisplayOnly labelWidth="10em" label="Payment Begins" value="receiving a signed Bill of Lading" />

	<label for="dispatch-post-leg" class="form-label-sm mb-0">
		Additional Terms
	</label>
	<textarea
		id="dispatch-post-leg"
		rows="2"
		readonly={form.state === 'saving' || readonly}
		value={centralDispatchAdditionalTerms}
		on:input={event => { centralDispatchAdditionalTerms = event.target.value }}
		class="form-control form-control-sm"
	/>

	<p class="form-text">
		Text shown on the Central Dispatch posting.
		{#if legState !== 'deliver'}
			<br>
			<span class:text-danger={additionalTermsTooLong}>
				You're at {centralDispatchAdditionalTerms?.length || 0} of {MAX_ADDITIONAL_TERMS_CHARS} characters.
			</span>
		{/if}
	</p>

	<div class="input-group input-group-sm mt-2 mb-2">
		<span class="input-group-text justify-content-center">
			<label for="dispatch-date" class="form-label-sm mb-0" style="font-weight: normal !important;">
				<NamedIcon name="date" /> First Available
			</label>
		</span>
		<input
			id="dispatch-date"
			type="date"
			value={firstAvailablePickup}
			on:input={({ target: { value } }) => { firstAvailablePickup = value }}
			readonly={form.state === 'saving'}
			class="form-control form-control-sm"
		>
	</div>

	{#if onlyOntrackSync}
		<div class="input-group input-group-sm mb-2">
			<span class="input-group-text" id="central-dispatch-id">
				<NamedIcon name="cd" />
				Existing CD ID (Optional)
			</span>
			<input
				disabled={form.state === 'saving'}
				type="text"
				class="form-control"
				value={centralDispatchListingId}
				on:input={event => { centralDispatchListingId = event.target.value }}
			>
		</div>
	{/if}

	<button class="btn btn-sm btn-primary" disabled={readonly || !carrierPay || !firstAvailablePickup || form.state === 'saving'} on:click={postHandler}>
		{#if onlyOntrackSync}
			Set Posting Properties
		{:else}
			<NamedIcon name="cd" />
			Post to Central Dispatch
		{/if}
	</button>

	<div class="form-check mt-3 mb-0">
		<input
			disabled={form.state === 'saving'}
			class="form-check-input"
			type="checkbox"
			id="leg-post-only-ontrack"
			checked={onlyOntrackSync}
			on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
		>
		<label for="leg-post-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
			Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
			and will <strong>not</strong> send a notification email.
		</label>
	</div>
</div>
