import template from './CustomReportId.svelte'
import { getCustomReportById } from '@/lib/controller/custom-report.js'
import { get } from '@/lib/json-fetch.js'

import { dateRangeQueryParams } from '../../reports/_date-range-from-query.js'

export default {
	data: {
		title: 'Custom Report',
	},
	template,
	querystringParameters: [
		'report',
		...dateRangeQueryParams,
	],
	resolve: async (_, querystringParams) => ({
		report: await getCustomReportById({ get }, querystringParams.customReportId).then(r => r.data?.attributes),
		query: querystringParams,
	}),
}
