<script>
	import TableHeadColumn from '@/component/TableHeadColumn.svelte'
	import QbChangeRow from './qbChanges/QbChangeRow.svelte'
	export let asr
	export let data
	export let saving
	export let loading
	export let readonly
</script>

<style>
	:global(.qb-changes-table td.id) {
		width: 5em;
	}
</style>

<table class="table table-sm qb-changes-table mb-0">
	<thead class="bg-200 text-900">
		<th scope="col">
			<!-- icon -->
		</th>
		<TableHeadColumn
			title="ID"
			columnClass="id"
		/>
		<th scope="col">
			<!-- mark complete -->
		</th>
		<TableHeadColumn
			title="Change"
			columnClass="change-name"
		/>
		<TableHeadColumn
			title="From"
			columnClass="change-from"
		/>
		<th scope="col">
			<!-- arrow -->
		</th>
		<TableHeadColumn
			title="To"
			columnClass="change-to"
		/>
		<th scope="col">
			<!-- clipboard -->
		</th>
	</thead>
	<tbody>
	{#if loading}
		<tr>
			<td colspan="7" class="text-center text-muted p-3">
				Loading...
			</td>
		</tr>
	{:else if data?.length}
		{#each data as row (row.id)}
			<QbChangeRow {asr} {row} {saving} {readonly} on:completed />
		{/each}
	{:else}
		<tr>
			<td colspan="7" class="text-center p-3">
				No changes to sync! 🎉
			</td>
		</tr>
	{/if}
	</tbody>
</table>
