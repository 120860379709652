<script>
	import { onMount, onDestroy } from 'svelte'
	import { getJobById } from '@/lib/controller/job.js'
	import { get } from '@/lib/json-fetch.js'
	import ago from 's-ago'

	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	import { router } from '@/service/router.js'

	export let leg
	export let isLastEvent

	export let name
	export let started
	export let completed
	export let completionStatus
	export let jobId
	// export let centralDispatchRequestId
	// export let sqs
	// export let errors
	// export let items
	// export let companyId

	const nameToTitle = {
		unpost: 'Unposted',
		post: 'Posted to CD',
		assign: 'Not Signed',
		cancel: 'Cancelled',
		decline: 'Declined',
		dispatch: 'Dispatched',
		receive: 'Picked Up',
		deliver: 'Delivered',
	}
	const nameToIcon = {
		unpost: 'order',
		post: 'cd',
		cancel: 'unpost',
		decline: 'cancel',
		assign: 'unsigned',
		dispatch: 'sign',
		receive: 'received',
		deliver: 'delivered',
	}
	const jobStatusToLabel = {
		BLOCKED: 'blocked',
		FAILED: 'failed',
		COMPLETED: 'completed',
		CANCELLED: 'cancelled',
	}

	let now = Date.now()
	let nowInterval
	let currentJob
	const update = () => {
		now = Date.now()
		const timeToStop = (now - new Date(started).getTime()) > (1000 * 60 * 5)
		if (timeToStop) clearInterval(nowInterval)
		if (jobId && isLastEvent && (!currentJob || !timeToStop)) {
			getJobById({ get }, jobId).then(response => {
				currentJob = response.data
			}).catch(error => {
				console.error('Could not load job.', error)
			})
		}
	}
	onMount(() => {
		if (started) {
			update()
			nowInterval = setInterval(update, 3000)
		}
	})
	onDestroy(() => {
		if (nowInterval) clearInterval(nowInterval)
	})
	$: waitMillis = started && (now - new Date(started).getTime())
	$: waitTime = started && ago(new Date(started))
	$: failureError = currentJob?.attributes?.status === 'FAILED'
		&& currentJob?.attributes?.executions?.length
		&& currentJob.attributes.executions.filter(e => e.errors?.length).reverse()[0].errors.reverse()[0]

	let centralDispatchLink
	$: {
		if (name === 'post' && leg?.attributes?.centralDispatchListingId) centralDispatchLink = `https://site.centraldispatch.com/protected/listing/post-listing?id=${leg.attributes.centralDispatchListingId}`
		else if (name === 'assign' && leg?.attributes?.centralDispatchDsid) centralDispatchLink = `https://site.centraldispatch.com/protected/dispatch/view?dsid=${leg.attributes.centralDispatchDsid}`
	}
	$: jobLink = jobId && router.makePath('app.systemSettings.jobs.byId.jobId', { jobId }, { inherit: false })
</script>

<style>
	.date-or-status {
		max-width: 8em;
	}
	.leg-event-icon {
		max-width: 2em;
	}
	.leg-event-label {
		/* based on nameToTitle values */
		max-width: 6em;
	}
</style>

<tr>
	<td class="ps-0 leg-event-icon text-center">
		{#if nameToIcon[name]}
			<NamedIcon name={nameToIcon[name]} />
		{/if}
	</td>
	<td class="leg-event-label">
		<strong>
			<MaybeLink external newTab href={centralDispatchLink}>
				{nameToTitle[name] || name}
			</MaybeLink>
		</strong>
	</td>
	<td class="text-center date-or-status">
		{#if completed && completionStatus === 'COMPLETED'}
			<span class="text-nowrap">
				<DateString time smallTime date={completed} />
			</span>
		{:else if waitTime}
			{#if failureError}
				<div class="alert alert-danger p-1">
					<small>
						<a href={jobLink}>Job {jobId.replace(/J$/, '')}</a> failed with the error: <em>{failureError.title || ''} {failureError.detail || ''}</em>
					</small>
				</div>
			{:else}
				<small class="text-muted">
					<em>
						Started {#if jobId}<a href={jobLink}>Job {jobId.replace(/J$/, '')}</a>{/if} {waitTime}{#if jobStatusToLabel[completionStatus]}
							&thinsp;and was marked as {jobStatusToLabel[completionStatus]}{/if}.
					</em>
				</small>
			{/if}
		{:else if !completed || (completed && !centralDispatchLink)}
			<small class="text-muted">
				<NamedIcon name="warning" class="text-warning" />
				There was an issue with this event, please check the
				<a href={jobLink}>job details</a> and report this to
				an admin or developer.
			</small>
		{/if}
	</td>
</tr>
