<script>
	import { Field } from 'jsonapi-svelte-form'

	export let id
	export let form
	export let keypath
	export let readonly
	export let onCreate
	export let options = {}
	export let optionsList

	$: elementId = [ id, ...keypath ].join('.')

	$: actualOptionsList = optionsList || Object.keys(options || {}).map(key => ({ key, title: options[key] }))
</script>

<Field bind:form {id} {keypath} on:change let:create let:set let:value let:errors let:disabled>
	{#each actualOptionsList as { key, title, description }}
		<div class="form-check mb-0">
			<input
				class="form-check-input"
				type="radio"
				name={elementId}
				id={`${elementId}-${key}`}
				on:input={ () => { (form.data[id] || onCreate(create)); set(key) } }
				on:*
				checked={value === key}
				disabled={readonly}
			>
			<label class="form-check-label mb-0" for={`${elementId}-${key}`}>
				{#if description}
					<strong>{title}</strong>
					<br>
					{description}
				{:else}
					{title}
				{/if}
			</label>
		</div>
	{/each}
</Field>
