<script>
	export let keypath
	export let model
	export let data
	const TAG_START = '{{'
	const TAG_END = '}}'
</script>

{#if model.type === 'array'}
	<p class="mb-2">
		This is a <code>list</code> of items, and each list item has these properties:
	</p>
	<svelte:self model={model.items} keypath={[]} />
{:else if model.type === 'object'}
	<ul class="list-group">
		{#each Object.keys(model.properties).sort() as key}
		{@const text = `${TAG_START}${[ ...keypath, key ].join('.')}${TAG_END}`}
			<li class="list-group-item">
				<div class="pb-2">
					<button class="btn btn-sm btn-outline-secondary" on:click={() => navigator.clipboard.writeText(text)}>
						<span class="fa fa-clipboard"></span>
					</button>
					<code>{text}</code>
					{model.properties[key].description}
				</div>
				<svelte:self bind:data model={model.properties[key]} keypath={[ ...keypath, key ]} />
			</li>
		{/each}
	</ul>
{/if}
