<script>
	import { Form, Field } from 'jsonapi-svelte-form'
	import InputType from '@/component/atom/InputType.svelte'
	import AQCFormRateRow from '@/component/form/AQCFormRateRow.svelte'
	import { updateChanges } from '@/service/store/form-changes.js'
	import InputSelectRel from '@/component/atom/InputSelectRel.svelte'
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import { rateSheetRowColumnKeys } from '@/shared/model/auto-quote-campaign.model.js'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'

	let showDuplicateInfo = false

	$: { updateChanges(form?.changes) }
	$: rateSheetRows = form.data[form.primaryId].attributes?.rateSheetRows || []
	let overlappingRows
	$: {
		const list = new Array(rateSheetRows.length).fill(false)
		for (let i = 0; i < rateSheetRows.length; i++) {
			for (let j = i + 1; j < rateSheetRows.length; j++) {
				if (rateSheetRows[i].milesMin <= rateSheetRows[j].milesMax && rateSheetRows[i].milesMax >= rateSheetRows[j].milesMin) {
					list[i] = true
					list[j] = true
				}
			}
		}
		overlappingRows = [ ...list ]
	}
	$: rowsWithOverlappingMiles = overlappingRows?.find(Boolean)
	$: rowsMissingCarrierPay = rateSheetRows?.find(row => !row.carrierPaySmall || !row.carrierPayMedium || !row.carrierPayLarge)

	$: clipboardString = rateSheetRowColumnKeys.join('\t')
		+ '\n'
		+ rateSheetRows.map(r => rateSheetRowColumnKeys.map(key => r[key] || '0').join('\t')).join('\n')

	export let form
	export let emailTemplateOptions

	const IS_NUMBER = /^\d+(\.\d*)?$/
	let showImportPanel = false
	let invalidPaste = false
	let pastedValue = ''
	const handlePaste = set => event => {
		invalidPaste = false
		const rows = (event.target.value || '').split('\n').map(row => row.split('\t'))
		if (event.target.value && !rows.every(columns => columns.length === 5 && columns.every(cell => IS_NUMBER.test(cell)))) {
			invalidPaste = true
			pastedValue = ''
			showImportPanel = false
		} else {
			set(
				rows
					.map(cells => cells.map(cell => parseFloat(cell)))
					.map(([
						milesMin,
						milesMax,
						carrierPaySmall,
						carrierPayMedium,
						carrierPayLarge,
					]) => ({ milesMin, milesMax, carrierPaySmall, carrierPayMedium, carrierPayLarge }),
					))
			pastedValue = ''
			showImportPanel = false
		}
	}
</script>

<Form bind:form let:remove let:create>
	<div class="row gx-2">
		<div class="col-xs-12 col-lg-8 col-xl-6">
			<div class="card mb-3">
				<div class="card-header py-2">
					Campaign Information
				</div>
				<div class="card-body bg-light py-2">
					<div class="row">
						<div class="col">
							<InputType
								type="text"
								label="Name"
								labelWidth="15em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'name' ]}
							/>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<InputType
								type="text"
								label="Code"
								labelWidth="15em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'code' ]}
							/>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col">
							<InputType
								type="number"
								label="Email Delay (Seconds)"
								labelWidth="15em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'delayEmailSendSeconds' ]}
							/>
							<p class="form-text mb-0">
								Number of seconds after receiving the lead to send the automated email.
							</p>
						</div>
					</div>
					<h5 class="mt-2">Email Templates</h5>
					<div class="row">
						<div class="col">
							<InputSelectRel
								short
								label="Successfully Processed"
								labelWidth="15em"
								bind:form
								options={emailTemplateOptions}
								id={form.primaryId}
								name="quoteEmailTemplate"
								type="email-template"
							/>
							<p class="form-text">
								If set, a successfully processed lead will be sent an auto-quote email
								using this template. If not set, successful auto quote emails will
								<em>not</em> be sent.
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<InputSelectRel
								short
								label="Successful Duplicate"
								labelWidth="15em"
								bind:form
								options={emailTemplateOptions}
								id={form.primaryId}
								name="duplicateEmailTemplate"
								type="email-template"
							/>
							<div class="row">
								<div class="col">
									<p class="form-text">
										If set, a successfully processed lead
										<em>that is detected to be a duplicate</em>
										will be sent an auto-quote email using this template. If not
										set, duplicate auto quote emails will be sent using the
										default <em>Successfully Processed</em> template.
									</p>
								</div>
								<div class="col-auto mt-1">
									<button class="btn btn-sm btn-primary" on:click={() => { showDuplicateInfo = !showDuplicateInfo }}>
										<span class="fa fa-info-circle"></span>
									</button>
								</div>
							</div>
							{#if showDuplicateInfo}
								<div class="alert alert-secondary">
									<p>
										Whenever an opportunity comes in, <em>OnTrack</em> looks to see if there
										are any existing duplicates that are so similar that a reasonable person
										would call them duplicates of each other.
									</p>
									<p>
										This decision is made by the following robot 🤖 algorithm:
									</p>
									<p>
										<em>First,</em> given the customer name, phone, and email on the opportunity,
										see if we have any existing customers that match by the same name and <strong>either</strong>
										phone number or email address.
									</p>
									<p>
										<em>Next,</em> of opportunities for that customer, see if any have the same
										vehicles, matching by year, make, and model. (The two opportunities need the
										same vehicles, but they can be in a different order.)
									</p>
									<p>
										<em>Next,</em> we limit the potential duplicates to ones with the same origin
										and destination <em>zip code</em>. (If they put in the same zip code but a
										different city, for example, that would be identical.)
									</p>
									<p class="mb-0">
										<em>Finally,</em> we limit the timeframe to be 30 days back. (So if a customer
										entered the same data 31 days ago, it wouldn't be considered a duplicate.)
									</p>
								</div>
							{/if}
						</div>
					</div>
					<div class="row">
						<div class="col">
							<InputSelectRel
								short
								label="Unsupported Location"
								labelWidth="15em"
								bind:form
								options={emailTemplateOptions}
								id={form.primaryId}
								name="unsupportedLocationEmailTemplate"
								type="email-template"
							/>
							<p class="form-text">
								If set, leads with an unsupported location (e.g. non-US and Alaska)
								will be sent this template. If not set, it'll fall back to the
								<em>Error Email Template</em>.
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<InputSelectRel
								short
								label="Error Email"
								labelWidth="15em"
								bind:form
								options={emailTemplateOptions}
								id={form.primaryId}
								name="errorEmailTemplate"
								type="email-template"
							/>
							<p class="form-text">
								If set, leads with errors during processing will be sent an email
								with this template. If not set, failed auto quotes will <em>not</em>
								be sent an email.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12 col-lg-8 col-xl-6">
			<div class="card mb-3">
				<div class="card-header py-2">
					Broker Fee
					<strong><em>($/vehicle)</em></strong>
				</div>
				<div class="card-body bg-light py-2">
					<div class="row">
						<div class="col-xs-12 col-lg-8 col-xl-6">
							<InputType
								type="number"
								label="1 vehicle"
								labelWidth="8em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'brokerFeeOne' ]}
							/>
							<InputType
								type="number"
								label="2 vehicles"
								labelWidth="8em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'brokerFeeTwo' ]}
							/>
							<InputType
								type="number"
								label="3 vehicles"
								labelWidth="8em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'brokerFeeThree' ]}
							/>
							<InputType
								type="number"
								label="4+ vehicles"
								labelWidth="8em"
								bind:form
								id={form.primaryId}
								keypath={[ 'attributes', 'brokerFeeFourOrMore' ]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<div class="card mb-3">
				<div class="card-header py-2">
					Rate Sheet
				</div>
				<div class="card-body bg-light py-2">
					<table class="table table-sm">
						<thead class="text-center">
							<tr>
								<td colspan="2">
									<p class="m-0">Mileage Range</p>
									<div>
										<div>Min (Inclusive)</div>
										<div>Max (Inclusive)</div>
									</div>
								</td>
								<td colspan="3">
									<p class="m-0">Carrier Pay (Vehicle Size)</p>
									<div>
										<div>Small</div>
										<div>Medium</div>
										<div>Large</div>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							{#each rateSheetRows as row, rowIndex}
								<AQCFormRateRow
									bind:form
									{rowIndex}
									lastRow={rowIndex === rateSheetRows.length - 1}
									badMileRange={overlappingRows[rowIndex]}
								/>
							{/each}
						</tbody>
					</table>
					<Field
						bind:form
						id={form.primaryId}
						keypath={[ 'attributes', 'rateSheetRows' ]}
						let:set
					>
						<div class="row">
							<div class="col">
								<button
									class="btn btn-sm btn-primary me-5"
									on:click={() => set([ ...(form.data[form.primaryId].attributes?.rateSheetRows || []), {} ])}
								>
									Add Row
								</button>
							</div>
							<div class="col-auto" style="width: 22em;">
								<span class="me-5">
									<CopyToClipboard string={clipboardString} />
								</span>
								<button
									class="btn btn-sm btn-outline-secondary me-3"
									on:click={() => { showImportPanel = !showImportPanel; invalidPaste = false }}
									style="width: 12em;"
								>
									{#if showImportPanel}
										Cancel
									{:else}
										Import/Overwrite
									{/if}
								</button>
								{#if showImportPanel}
									<div>
										<p class="mt-3 mb-0">
											Paste cells from Excel here:
										</p>
										<textarea
											class="form-control"
											class:is-invalid={invalidPaste}
											on:input={handlePaste(set)}
											bind:value={pastedValue}></textarea>
									</div>
								{/if}
								{#if invalidPaste}
									<p class="mb-0 text-danger">
										You need to paste in something that is exactly five (5) columns wide, and
										has only numbers in every cell.
									</p>
								{/if}
							</div>
						</div>
					</Field>
				</div>
			</div>
		</div>
	</div>
	{#if rowsWithOverlappingMiles || rowsMissingCarrierPay}
		<div class="alert alert-danger">
			{#if rowsWithOverlappingMiles}
				<p class="mb-0">
					<NamedIcon name="warning" />
					Some rows have overlapping miles. The ranges should not overlap, e.g. "0-50, 51-100, etc".
				</p>
			{/if}
			{#if rowsMissingCarrierPay}
				<p class="mb-0">
					<NamedIcon name="warning" />
					Some rows are missing carrier pay values.
				</p>
			{/if}
		</div>
	{/if}
</Form>

<style>
	table thead td {
		padding: 0;
		border: 1px solid #cecece;
	}
	table thead td > div { display: flex; }
	table thead td > div > div { flex: 1; }
</style>
