import template from './AccountingSheet.svelte'
import { getOpportunityById } from '@/lib/controller/opportunity.js'
import { listReferrers } from '@/lib/controller/referrer.js'
import { listUsers } from '@/lib/controller/user.js'
import { get } from '@/lib/json-fetch.js'
import { load } from 'jsonapi-svelte-form/mapper'
import { keyBy } from '@/shared/util/key-by.js'
import { notFoundRedirect } from '@/lib/not-found-redirect.js'

export default {
	data: {
		title: 'Overview',
	},
	template,
	resolve: async (_, { opportunityId }) => {
		const [ body, { data: referrers }, { data: users } ] = await Promise.all([
			getOpportunityById({ get }, opportunityId, { include: [ 'assigned', 'companies' ] }).catch(error => ({ error })),
			listReferrers({ get }),
			listUsers({ get }),
		])
		notFoundRedirect(body, 'opportunity', opportunityId)
		return {
			users,
			usersById: keyBy(users, 'id'),
			referrers,
			referrersById: keyBy(referrers, 'id'),
			form: load(body, 0),
		}
	},
}
