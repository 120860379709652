<script>
	import FormHeaderMain from '@/component/FormHeaderMain.svelte'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import CompanyForm from '@/component/form/CompanyForm.svelte'
	import Icon from 'svelte-awesome'
	import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
	import { previousState } from '@/service/store/router-state.js'
	import { updateCompany } from '@/lib/controller/company.js'
	import CompanyOpportunities from './_CompanyOpportunities.svelte'

	export let asr
	export let companyId
	export let api
	export let form

	$: justCreated = $previousState === 'app.companies.create'

	$: cdId = form.data[form.primaryId]?.attributes?.centralDispatchId

	const save = () => {
		form.state = 'saving'
		delete form.errors
		updateCompany(api, form)
			.then(response => { form = response })
			.catch(({ errors, state }) => {
				form.errors = errors
				form.state = state
			})
	}
</script>

<FormHeaderMain>
	<a href={asr.makePath('app.companies', null, { inherit: false })}>
		Companies
	</a>
	<Icon data={faAngleRight} scale="2" class="ms-2 me-2" />
	<span class="me-3">
		Edit {form.data[form.primaryId]?.attributes?.name || 'N/A'}
	</span>
	{#if form.data[form.primaryId]?.attributes?.inactive}
		<span class="badge bg-secondary">Inactive</span>
	{/if}
	{#if form.data[form.primaryId]?.attributes?.blocked}
		<span class="badge bg-danger">Blocked</span>
	{/if}
	{#if cdId}
		<span class="text-muted">(Locked fields are controlled by Central Dispatch.)</span>
	{/if}
	{#if justCreated}
		<span class="badge bg-success">Created new Company 🎉</span>
	{/if}
</FormHeaderMain>

<CompanyForm bind:form />
<FormErrorsOther {form} />
<FormCommitButton {form} on:click={save} />

<hr>

<CompanyOpportunities {companyId} {asr} />
