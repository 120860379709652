<script>
	import Table from '@/component/Table.svelte'
	import DateTime from '@/component/table-cells/DateTime.svelte'
	import PlainText from '@/component/table-cells/PlainText.svelte'
	import NameLink from './emailTemplates/NameLink.svelte'
	import ListShowFor from './emailTemplates/ListShowFor.svelte'
	import ListSignable from '@/component/table/emailTemplates/ListSignable.svelte'

	export let asr
	export let data
	export let errors
	export let included
	export let links
	export let loading
	export let makeLink
	export let request
	export let sort

	export let columnNames = [
		'name',
		'subject',
		'dispatch',
		'signable',
		'updated',
	]

	const nameToColumn = {
		name: {
			title: 'Name',
			component: NameLink,
			keypath: 'attributes.name',
			link: row => asr.makePath('app.systemSettings.emailTemplates.edit.emailTemplateId', { emailTemplateId: row.id }),
		},
		subject: {
			title: 'Subject',
			component: PlainText,
			keypath: 'attributes.subject',
		},
		dispatch: {
			title: 'Show For',
			component: ListShowFor,
		},
		signable: {
			title: 'Signable',
			component: ListSignable,
		},
		updated: {
			title: 'Last Updated',
			component: DateTime,
			keypath: 'meta.updated',
		},
	}

	$: columns = columnNames.map(name => nameToColumn[name])
</script>

<Table
	small
	{columns}
	{data}
	{errors}
	{included}
	{links}
	{makeLink}
	{request}
	{sort}
	bind:loading
	on:loadNext
/>
