<script>
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	export let form
</script>

{#if form?.errors?.other}
	<div class="row">
		<div class="col-sm-12 col-md-8">
			{#each form.errors.other as error}
				<ErrorAlert {error} />
			{/each}
		</div>
	</div>
{/if}
