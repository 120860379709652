/*
BUILT FILE, SEE root:/generate-ajv-models.js FOR DETAILS.
*/

const __schemaMap = {}
const __schemaStar = {}

export const address = 'address'
import __default_address, * as __star_address from './model/address.model.js'
__schemaMap['address'] = __default_address
__schemaStar['address'] = __star_address
export const autoQuoteCampaign = 'auto-quote-campaign'
import __default_autoQuoteCampaign, * as __star_autoQuoteCampaign from './model/auto-quote-campaign.model.js'
__schemaMap['auto-quote-campaign'] = __default_autoQuoteCampaign
__schemaStar['auto-quote-campaign'] = __star_autoQuoteCampaign
export const ccTransaction = 'cc-transaction'
import __default_ccTransaction, * as __star_ccTransaction from './model/cc-transaction.model.js'
__schemaMap['cc-transaction'] = __default_ccTransaction
__schemaStar['cc-transaction'] = __star_ccTransaction
export const cdRequest = 'cd-request'
import __default_cdRequest, * as __star_cdRequest from './model/cd-request.model.js'
__schemaMap['cd-request'] = __default_cdRequest
__schemaStar['cd-request'] = __star_cdRequest
export const centralDispatch = 'central-dispatch'
import __default_centralDispatch, * as __star_centralDispatch from './model/central-dispatch.model.js'
__schemaMap['central-dispatch'] = __default_centralDispatch
__schemaStar['central-dispatch'] = __star_centralDispatch
export const company = 'company'
import __default_company, * as __star_company from './model/company.model.js'
__schemaMap['company'] = __default_company
__schemaStar['company'] = __star_company
export const contact = 'contact'
import __default_contact, * as __star_contact from './model/contact.model.js'
__schemaMap['contact'] = __default_contact
__schemaStar['contact'] = __star_contact
export const customReport = 'custom-report'
import __default_customReport, * as __star_customReport from './model/custom-report.model.js'
__schemaMap['custom-report'] = __default_customReport
__schemaStar['custom-report'] = __star_customReport
export const emailTemplateOpportunity = 'email-template-opportunity'
import __default_emailTemplateOpportunity, * as __star_emailTemplateOpportunity from './model/email-template-opportunity.model.js'
__schemaMap['email-template-opportunity'] = __default_emailTemplateOpportunity
__schemaStar['email-template-opportunity'] = __star_emailTemplateOpportunity
export const emailTemplateUser = 'email-template-user'
import __default_emailTemplateUser, * as __star_emailTemplateUser from './model/email-template-user.model.js'
__schemaMap['email-template-user'] = __default_emailTemplateUser
__schemaStar['email-template-user'] = __star_emailTemplateUser
export const emailTemplate = 'email-template'
import __default_emailTemplate, * as __star_emailTemplate from './model/email-template.model.js'
__schemaMap['email-template'] = __default_emailTemplate
__schemaStar['email-template'] = __star_emailTemplate
export const error = 'error'
import __default_error, * as __star_error from './model/error.model.js'
__schemaMap['error'] = __default_error
__schemaStar['error'] = __star_error
export const file = 'file'
import __default_file, * as __star_file from './model/file.model.js'
__schemaMap['file'] = __default_file
__schemaStar['file'] = __star_file
export const history = 'history'
import __default_history, * as __star_history from './model/history.model.js'
__schemaMap['history'] = __default_history
__schemaStar['history'] = __star_history
export const incomingLead = 'incoming-lead'
import __default_incomingLead, * as __star_incomingLead from './model/incoming-lead.model.js'
__schemaMap['incoming-lead'] = __default_incomingLead
__schemaStar['incoming-lead'] = __star_incomingLead
export const insurance = 'insurance'
import __default_insurance, * as __star_insurance from './model/insurance.model.js'
__schemaMap['insurance'] = __default_insurance
__schemaStar['insurance'] = __star_insurance
export const jobCentralDispatchAction = 'job-central-dispatch-action'
import __default_jobCentralDispatchAction, * as __star_jobCentralDispatchAction from './model/job-central-dispatch-action.model.js'
__schemaMap['job-central-dispatch-action'] = __default_jobCentralDispatchAction
__schemaStar['job-central-dispatch-action'] = __star_jobCentralDispatchAction
export const jobCentralDispatchHandleEvent = 'job-central-dispatch-handle-event'
import __default_jobCentralDispatchHandleEvent, * as __star_jobCentralDispatchHandleEvent from './model/job-central-dispatch-handle-event.model.js'
__schemaMap['job-central-dispatch-handle-event'] = __default_jobCentralDispatchHandleEvent
__schemaStar['job-central-dispatch-handle-event'] = __star_jobCentralDispatchHandleEvent
export const jobCentralDispatchImportCarrier = 'job-central-dispatch-import-carrier'
import __default_jobCentralDispatchImportCarrier, * as __star_jobCentralDispatchImportCarrier from './model/job-central-dispatch-import-carrier.model.js'
__schemaMap['job-central-dispatch-import-carrier'] = __default_jobCentralDispatchImportCarrier
__schemaStar['job-central-dispatch-import-carrier'] = __star_jobCentralDispatchImportCarrier
export const jobCentralDispatchPull = 'job-central-dispatch-pull'
import __default_jobCentralDispatchPull, * as __star_jobCentralDispatchPull from './model/job-central-dispatch-pull.model.js'
__schemaMap['job-central-dispatch-pull'] = __default_jobCentralDispatchPull
__schemaStar['job-central-dispatch-pull'] = __star_jobCentralDispatchPull
export const jobCentralDispatchPush = 'job-central-dispatch-push'
import __default_jobCentralDispatchPush, * as __star_jobCentralDispatchPush from './model/job-central-dispatch-push.model.js'
__schemaMap['job-central-dispatch-push'] = __default_jobCentralDispatchPush
__schemaStar['job-central-dispatch-push'] = __star_jobCentralDispatchPush
export const jobEmailAccepted = 'job-email-accepted'
import __default_jobEmailAccepted, * as __star_jobEmailAccepted from './model/job-email-accepted.model.js'
__schemaMap['job-email-accepted'] = __default_jobEmailAccepted
__schemaStar['job-email-accepted'] = __star_jobEmailAccepted
export const jobEmailCancelled = 'job-email-cancelled'
import __default_jobEmailCancelled, * as __star_jobEmailCancelled from './model/job-email-cancelled.model.js'
__schemaMap['job-email-cancelled'] = __default_jobEmailCancelled
__schemaStar['job-email-cancelled'] = __star_jobEmailCancelled
export const jobEmailDeclined = 'job-email-declined'
import __default_jobEmailDeclined, * as __star_jobEmailDeclined from './model/job-email-declined.model.js'
__schemaMap['job-email-declined'] = __default_jobEmailDeclined
__schemaStar['job-email-declined'] = __star_jobEmailDeclined
export const jobEmailDelivered = 'job-email-delivered'
import __default_jobEmailDelivered, * as __star_jobEmailDelivered from './model/job-email-delivered.model.js'
__schemaMap['job-email-delivered'] = __default_jobEmailDelivered
__schemaStar['job-email-delivered'] = __star_jobEmailDelivered
export const jobEmailPickedUp = 'job-email-picked-up'
import __default_jobEmailPickedUp, * as __star_jobEmailPickedUp from './model/job-email-picked-up.model.js'
__schemaMap['job-email-picked-up'] = __default_jobEmailPickedUp
__schemaStar['job-email-picked-up'] = __star_jobEmailPickedUp
export const jobEmailReceived = 'job-email-received'
import __default_jobEmailReceived, * as __star_jobEmailReceived from './model/job-email-received.model.js'
__schemaMap['job-email-received'] = __default_jobEmailReceived
__schemaStar['job-email-received'] = __star_jobEmailReceived
export const jobExampleGenerateReport = 'job-example-generate-report'
import __default_jobExampleGenerateReport, * as __star_jobExampleGenerateReport from './model/job-example-generate-report.model.js'
__schemaMap['job-example-generate-report'] = __default_jobExampleGenerateReport
__schemaStar['job-example-generate-report'] = __star_jobExampleGenerateReport
export const jobOpportunityProcessLead = 'job-opportunity-process-lead'
import __default_jobOpportunityProcessLead, * as __star_jobOpportunityProcessLead from './model/job-opportunity-process-lead.model.js'
__schemaMap['job-opportunity-process-lead'] = __default_jobOpportunityProcessLead
__schemaStar['job-opportunity-process-lead'] = __star_jobOpportunityProcessLead
export const jobOpportunitySendAutoQuoteEmail = 'job-opportunity-send-auto-quote-email'
import __default_jobOpportunitySendAutoQuoteEmail, * as __star_jobOpportunitySendAutoQuoteEmail from './model/job-opportunity-send-auto-quote-email.model.js'
__schemaMap['job-opportunity-send-auto-quote-email'] = __default_jobOpportunitySendAutoQuoteEmail
__schemaStar['job-opportunity-send-auto-quote-email'] = __star_jobOpportunitySendAutoQuoteEmail
export const job = 'job'
import __default_job, * as __star_job from './model/job.model.js'
__schemaMap['job'] = __default_job
__schemaStar['job'] = __star_job
export const legVehicle = 'leg-vehicle'
import __default_legVehicle, * as __star_legVehicle from './model/leg-vehicle.model.js'
__schemaMap['leg-vehicle'] = __default_legVehicle
__schemaStar['leg-vehicle'] = __star_legVehicle
export const leg = 'leg'
import __default_leg, * as __star_leg from './model/leg.model.js'
__schemaMap['leg'] = __default_leg
__schemaStar['leg'] = __star_leg
export const metaExpirable = 'meta-expirable'
import __default_metaExpirable, * as __star_metaExpirable from './model/meta-expirable.model.js'
__schemaMap['meta-expirable'] = __default_metaExpirable
__schemaStar['meta-expirable'] = __star_metaExpirable
export const metaImmutable = 'meta-immutable'
import __default_metaImmutable, * as __star_metaImmutable from './model/meta-immutable.model.js'
__schemaMap['meta-immutable'] = __default_metaImmutable
__schemaStar['meta-immutable'] = __star_metaImmutable
export const metaImported = 'meta-imported'
import __default_metaImported, * as __star_metaImported from './model/meta-imported.model.js'
__schemaMap['meta-imported'] = __default_metaImported
__schemaStar['meta-imported'] = __star_metaImported
export const meta = 'meta'
import __default_meta, * as __star_meta from './model/meta.model.js'
__schemaMap['meta'] = __default_meta
__schemaStar['meta'] = __star_meta
export const note = 'note'
import __default_note, * as __star_note from './model/note.model.js'
__schemaMap['note'] = __default_note
__schemaStar['note'] = __star_note
export const opportunity = 'opportunity'
import __default_opportunity, * as __star_opportunity from './model/opportunity.model.js'
__schemaMap['opportunity'] = __default_opportunity
__schemaStar['opportunity'] = __star_opportunity
export const passwordResetRequest = 'password-reset-request'
import __default_passwordResetRequest, * as __star_passwordResetRequest from './model/password-reset-request.model.js'
__schemaMap['password-reset-request'] = __default_passwordResetRequest
__schemaStar['password-reset-request'] = __star_passwordResetRequest
export const qbSync = 'qb-sync'
import __default_qbSync, * as __star_qbSync from './model/qb-sync.model.js'
__schemaMap['qb-sync'] = __default_qbSync
__schemaStar['qb-sync'] = __star_qbSync
export const receivedEmail = 'received-email'
import __default_receivedEmail, * as __star_receivedEmail from './model/received-email.model.js'
__schemaMap['received-email'] = __default_receivedEmail
__schemaStar['received-email'] = __star_receivedEmail
export const referrer = 'referrer'
import __default_referrer, * as __star_referrer from './model/referrer.model.js'
__schemaMap['referrer'] = __default_referrer
__schemaStar['referrer'] = __star_referrer
export const relationship = 'relationship'
import __default_relationship, * as __star_relationship from './model/relationship.model.js'
__schemaMap['relationship'] = __default_relationship
__schemaStar['relationship'] = __star_relationship
export const sentEmail = 'sent-email'
import __default_sentEmail, * as __star_sentEmail from './model/sent-email.model.js'
__schemaMap['sent-email'] = __default_sentEmail
__schemaStar['sent-email'] = __star_sentEmail
export const session = 'session'
import __default_session, * as __star_session from './model/session.model.js'
__schemaMap['session'] = __default_session
__schemaStar['session'] = __star_session
export const signableDocumentVisit = 'signable-document-visit'
import __default_signableDocumentVisit, * as __star_signableDocumentVisit from './model/signable-document-visit.model.js'
__schemaMap['signable-document-visit'] = __default_signableDocumentVisit
__schemaStar['signable-document-visit'] = __star_signableDocumentVisit
export const signableDocument = 'signable-document'
import __default_signableDocument, * as __star_signableDocument from './model/signable-document.model.js'
__schemaMap['signable-document'] = __default_signableDocument
__schemaStar['signable-document'] = __star_signableDocument
export const signableTemplate = 'signable-template'
import __default_signableTemplate, * as __star_signableTemplate from './model/signable-template.model.js'
__schemaMap['signable-template'] = __default_signableTemplate
__schemaStar['signable-template'] = __star_signableTemplate
export const sqsResponse = 'sqs-response'
import __default_sqsResponse, * as __star_sqsResponse from './model/sqs-response.model.js'
__schemaMap['sqs-response'] = __default_sqsResponse
__schemaStar['sqs-response'] = __star_sqsResponse
export const userAgent = 'user-agent'
import __default_userAgent, * as __star_userAgent from './model/user-agent.model.js'
__schemaMap['user-agent'] = __default_userAgent
__schemaStar['user-agent'] = __star_userAgent
export const userEmail = 'user-email'
import __default_userEmail, * as __star_userEmail from './model/user-email.model.js'
__schemaMap['user-email'] = __default_userEmail
__schemaStar['user-email'] = __star_userEmail
export const userPermissions = 'user-permissions'
import __default_userPermissions, * as __star_userPermissions from './model/user-permissions.model.js'
__schemaMap['user-permissions'] = __default_userPermissions
__schemaStar['user-permissions'] = __star_userPermissions
export const user = 'user'
import __default_user, * as __star_user from './model/user.model.js'
__schemaMap['user'] = __default_user
__schemaStar['user'] = __star_user
export const vehicle = 'vehicle'
import __default_vehicle, * as __star_vehicle from './model/vehicle.model.js'
__schemaMap['vehicle'] = __default_vehicle
__schemaStar['vehicle'] = __star_vehicle

export const modelNameToIncludedNames = {
	"auto-quote-campaign": [
		"email-template",
		"signable-template"
	],
	"cc-transaction": [
		"file",
		"opportunity",
		"user",
		"cc-transaction",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"cd-request": [
		"user",
		"cd-request",
		"opportunity",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email"
	],
	"company": [
		"contact",
		"address",
		"insurance"
	],
	"custom-report": [
		"user"
	],
	"email-template": [
		"signable-template"
	],
	"file": [
		"cc-transaction",
		"opportunity",
		"user",
		"file",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"insurance": [
		"address"
	],
	"job": [
		"user",
		"received-email",
		"opportunity",
		"leg",
		"cd-request",
		"email-template",
		"job",
		"referrer",
		"auto-quote-campaign",
		"signable-template",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"company",
		"insurance"
	],
	"leg-vehicle": [
		"vehicle"
	],
	"leg": [
		"company",
		"contact",
		"address",
		"insurance"
	],
	"note": [
		"user",
		"opportunity",
		"file",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"cc-transaction",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"opportunity": [
		"user",
		"referrer",
		"auto-quote-campaign",
		"cc-transaction",
		"contact",
		"address",
		"vehicle",
		"leg",
		"job",
		"email-template",
		"signable-template",
		"file",
		"opportunity",
		"company",
		"insurance",
		"received-email",
		"cd-request"
	],
	"password-reset-request": [
		"user"
	],
	"qb-sync": [
		"company",
		"opportunity",
		"user",
		"contact",
		"address",
		"insurance",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"cc-transaction",
		"file",
		"vehicle",
		"leg",
		"job",
		"received-email",
		"cd-request"
	],
	"received-email": [
		"opportunity",
		"leg",
		"job",
		"user",
		"referrer",
		"auto-quote-campaign",
		"email-template",
		"signable-template",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"company",
		"insurance",
		"received-email",
		"cd-request"
	],
	"sent-email": [
		"user",
		"email-template",
		"signable-template",
		"signable-document",
		"opportunity",
		"sent-email",
		"signable-document-visit",
		"referrer",
		"auto-quote-campaign",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"session": [
		"user"
	],
	"signable-document-visit": [
		"signable-document",
		"opportunity",
		"sent-email",
		"user",
		"email-template",
		"signable-template",
		"signable-document-visit",
		"referrer",
		"auto-quote-campaign",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"signable-document": [
		"sent-email",
		"signable-template",
		"signable-document-visit",
		"opportunity",
		"user",
		"email-template",
		"signable-document",
		"referrer",
		"auto-quote-campaign",
		"cc-transaction",
		"file",
		"contact",
		"address",
		"vehicle",
		"leg",
		"company",
		"insurance",
		"job",
		"received-email",
		"cd-request"
	],
	"user-email": [
		"user"
	]
}

export const getSchema = name => {
	return { schema: __schemaMap[name], selfFragments: __schemaStar[name] && __schemaStar[name].$selfFragment }
}
