<script>
	// import MenuTopNotificationDropdown from '@/component/MenuTopNotificationDropdown.svelte'
	import { system } from '@/service/store/system.js'
	import { currentUser } from '@/service/store/current-user.js'
	import { router } from '@/service/router.js'

	export let asr = {}
	// export let notifications = [{ attributes: { read: false } }, { attributes: { read: true } }]
	// export let loadingNotifications = {}

	const OPPORTUNITY_ID = /^\d{6}P?$/
	let bigSearchString = ''
	let controlOrCommandPressed
	const controlOrCommand = event => event.keyCode === 91
			|| event.keyCode === 17
			|| event.metaKey
			|| event.ctrlKey
	const handleKeydown = event => {
		if (controlOrCommand(event)) controlOrCommandPressed = true
	}
	const handleKeyup = () => {
		// any key up at all, really
		controlOrCommandPressed = false
	}
	const searchEverything = () => {
		if (controlOrCommandPressed && OPPORTUNITY_ID.test(bigSearchString)) {
			router.go('app.opportunities.edit.opportunityId.overview', {
				opportunityId: bigSearchString.endsWith('P')
					? bigSearchString
					: `${bigSearchString}P`,
			})
		} else {
			router.go('app.search', { q: bigSearchString, tab: 'opportunities' })
		}
		bigSearchString = ''
		document?.activeElement?.blur?.()
	}

	$: development = [ 'develop', 'local' ].includes($system?.stage)
	$: permissions = $currentUser?.attributes?.permissions || {}
	$: isTobias = $currentUser?.attributes?.name?.toLowerCase().includes('tobias')
</script>

<svelte:window on:keydown={handleKeydown} on:keyup={handleKeyup}/>

<nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">

	<button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
		<span class="navbar-toggle-icon">
			<span class="toggle-line"></span>
		</span>
	</button>

	<a class="navbar-brand me-1 me-sm-3" href="/">
		<div class="d-flex align-items-center">
			<span class="font-sans-serif" class:text-danger={development}>
				{#if development}<span style="font-size: 1em;">⚠️</span>{/if}
				{$system.name}
			</span>
		</div>
	</a>

	<ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
		{#if permissions.mcsStaff}
			<li class="nav-item me-5">
				<form on:submit|preventDefault={searchEverything}>
					<div class="input-group input-group-sm">
						<input type="text" class="form-control" placeholder="Search" bind:value={bigSearchString}>
						<button
							class="btn btn-outline-secondary"
							type="submit"
						>
							<span class="fa fa-search"></span>
						</button>
					</div>
				</form>
			</li>
		{/if}
		{#if permissions.manageOpportunities}
			<li class="nav-item me-5">
				<a class="btn btn-primary btn-sm text-light" href={asr.makePath('app.opportunities.create', null, { inherit: false })}>
					<span class="fa fa-plus"></span>
					Create Lead
				</a>
			</li>
		{/if}
		<!--
		<li class="nav-item dropdown">
			<MenuTopNotificationDropdown {loadingNotifications} {notifications} {asr} />
		</li>
		-->
		<li class="nav-item dropdown">
			<a class="nav-link pe-0" id="navbarDropdownUser" href="#user-dropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<div class="avatar avatar-xl">
					<img class="rounded-circle" src="{$system.CWD}asset/falcon/avatar.png" alt="">
				</div>
			</a>
			<div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
				<div class="bg-white dark__bg-1000 rounded-2 py-2">
<!--					<a class="dropdown-item" href="pages/user/settings.html">Settings</a>-->
					<a class="dropdown-item" href={asr.makePath('logout')}>Logout</a>
				</div>
			</div>
		</li>
	</ul>
</nav>
