<script>
	import { onMount, createEventDispatcher } from 'svelte'
	import ExternalLink from '@/component/ExternalLink.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import { objectToJsonApiQuery } from '@/lib/object-to-json-api-query.js'
	import { postOpportunityAction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	const MAX_ADDITIONAL_TERMS_CHARS = 60

	import { mapCompaniesTogether } from './map-companies-together.js'
	import EditDispatchDate from './EditDispatchDate.svelte'

	export let api
	export let form
	export let legId

	let onlyOntrackSync
	let carrier // { centralDispatchId, onTrackId, name, location, email }

	let carrierQuery
	const updateQuery = ({ target: { value } }) => {
		// Examples:
		// old: https://site.centraldispatch.com/protected/rating/client-snapshot?id=bc7ad899-639a-4f7b-accc-d3743ee5405c
		// new: https://app.centraldispatch.com/ratings/overview?customerid=89f1ba3a-788a-493a-8088-55b51064a523
		if (
			value.includes('centraldispatch.com/protected/rating/client-snapshot?id=')
			|| value.includes('centraldispatch.com/ratings/overview?customerid=')
		) {
			const centralDispatchId = value.split('?')[1].split('&').find(param => param.startsWith('id=') || param.startsWith('customerid='))?.split('=')[1]
			carrierQuery = api
				.get('/api/v1/companies' + objectToJsonApiQuery({
					filter: { centralDispatchId },
				}))
				.then(response => {
					if (response.body.data.length) {
						return mapCompaniesTogether(response)
					} else {
						return api
							.get(`/api/v1/centralDispatch/proxy?stripElements=.btn&url=${encodeURIComponent(`https://site.centraldispatch.com/protected/rating/client-snapshot?id=${centralDispatchId}`)}`)
							.then(({ body: { data: { text } } }) => ({ html: text, centralDispatchId }))
					}
				})
		} else {
			carrierQuery = value.length > 1 && Promise.all([
				api.get('/api/v1/companies' + objectToJsonApiQuery({
					filter: { name: value, service: 'carrier' },
				})),
				api.get(`/api/v1/centralDispatch/proxy?url=${encodeURIComponent(`https://site.centraldispatch.com/protected/dispatch/search-carrier-json?searchString=${value}`)}`),
			])
				.then(([ onTrack, centralDispatch ]) => mapCompaniesTogether(onTrack, centralDispatch))
		}
	}

	let legAtts = {}
	onMount(() => {
		legAtts = form.data[legId]?.attributes || {}
	})

	$: pickupDate = legAtts.pickupDate || legAtts.firstAvailablePickup || ''
	$: pickupDateType = legAtts.pickupDateType || 'estimate'
	$: dropoffDate = legAtts.dropoffDate || legAtts.firstAvailablePickup || ''
	$: dropoffDateType = legAtts.dropoffDateType || 'estimate'
	$: businessDays = legAtts.businessDays || 10
	$: carrierPay = legAtts.carrierPay || ''
	$: driverFirstName = legAtts.driverFirstName || ''
	$: driverLastName = legAtts.driverLastName || ''
	$: driverPhone = legAtts.driverPhone || ''
	$: centralDispatchAdditionalTerms = legAtts.centralDispatchAdditionalTerms || ''
	$: centralDispatchSpecialInstructions = legAtts.centralDispatchSpecialInstructions || ''

	$: missingProps = !pickupDateType || !pickupDate || !dropoffDateType || !dropoffDate || !businessDays
	$: disableAssign = form.state === 'saving' || missingProps

	const dispatch = createEventDispatcher()
	const assignLeg = ({ onTrackId, centralDispatchId, email }) => {
		form.state = 'saving'
		dispatch('action', postOpportunityAction(api, {
			action: 'assign',
			opportunityId: form.primaryId,
			legId,
			updated: form.data[form.primaryId]?.meta?.updated,
			onlyOntrackSync,
			data: {
				onTrackId,
				centralDispatchId,
				carrierPay,
				carrierEmail: email,
				businessDays,
				pickupDate,
				pickupDateType,
				dropoffDate,
				dropoffDateType,
				driverFirstName,
				driverLastName,
				driverPhone,
				centralDispatchAdditionalTerms,
				centralDispatchSpecialInstructions,
			},
		}))
	}
</script>

<div style="max-width: 38em;">
	<EditDispatchDate
		label="Load"
		readonly={form.state === 'saving'}
		dateType={pickupDateType}
		date={pickupDate}
		on:date={event => { pickupDate = event.detail }}
		on:dateType={event => { pickupDateType = event.detail }}
	/>
	<EditDispatchDate
		label="Deliver"
		readonly={form.state === 'saving'}
		dateType={dropoffDateType}
		date={dropoffDate}
		on:date={event => { dropoffDate = event.detail }}
		on:dateType={event => { dropoffDateType = event.detail }}
	/>

	<div class="input-group input-group-sm mb-2">
		<span class="input-group-text justify-content-center">
			Payment Terms
		</span>
		<button
			class="btn btn-outline-secondary"
			disabled={form.state === 'saving'}
			class:active={businessDays === 10}
			on:click={() => { businessDays = 10; carrierPay = form.data[legId]?.attributes?.carrierPay }}
		>
			10 business days
		</button>
		<button
			class="btn btn-outline-secondary"
			disabled={form.state === 'saving'}
			class:active={businessDays === 5}
			on:click={() => { businessDays = 5; carrierPay = Math.round(form.data[legId]?.attributes?.carrierPay * 100 * 0.95) / 100 }}
		>
			5 business days
		</button>
	</div>

	<div class="row mb-2">
		<div class="col-xs-12 col-md-6">
			<div class="input-group input-group-sm mt-2 mb-1">
				<span class="input-group-text justify-content-center">
					<label for="final-carrier-pay" class="form-label-sm mb-0" style="font-weight: normal !important;">
						Final Carrier Pay
					</label>
				</span>
				<input
					id="final-carrier-pay"
					type="text"
					value={carrierPay}
					on:input={event => { carrierPay = event.target.value }}
					disabled={form.state === 'saving'}
					class="form-control form-control-sm"
				>
			</div>
			<p class="form-text mb-0">
				Posted Carrier Pay: ${form.data[legId]?.attributes?.carrierPay || 'N/A'}
			</p>
		</div>
	</div>

	<div class="row mb-2">
		<div class="col-xs-12 col-md-6">
			<label for="dispatch-additional-terms" class="form-label-sm mb-0">
				Additional Terms
			</label>
			<textarea
				readonly={form.state === 'saving'}
				value={centralDispatchAdditionalTerms}
				on:input={event => { centralDispatchAdditionalTerms = event.target.value }}
				class="form-control form-control-sm"
				class:is-invalid={centralDispatchAdditionalTerms.length > MAX_ADDITIONAL_TERMS_CHARS}
				id="dispatch-additional-terms"
			/>
			<small class:text-danger={centralDispatchAdditionalTerms.length > MAX_ADDITIONAL_TERMS_CHARS}>
				You're at {centralDispatchAdditionalTerms.length} of {MAX_ADDITIONAL_TERMS_CHARS} characters.
			</small>
		</div>
		<div class="col-xs-12 col-md-6">
			<label for="dispatch-special-instructions" class="form-label-sm mb-0">
				Special Instructions
			</label>
			<textarea
				readonly={form.state === 'saving'}
				value={centralDispatchSpecialInstructions}
				on:input={event => { centralDispatchSpecialInstructions = event.target.value }}
				class="form-control form-control-sm"
				id="dispatch-special-instructions"
			/>
		</div>
	</div>

	<p class="mb-0">Driver Details <span class="text-muted">(if known)</span></p>
	<div class="row mb-3">
		<div class="col-xs-12 col-md-6">
			<label for="assign-driver-first-name" class="form-label-sm mb-0" style="font-weight: normal !important;">
				First Name
			</label>
			<input
				id="assign-driver-first-name"
				type="text"
				value={driverFirstName}
				on:input={event => { driverFirstName = event.target.value }}
				disabled={form.state === 'saving'}
				class="form-control form-control-sm"
			>
		</div>
		<div class="col-xs-12 col-md-6">
			<label for="assign-driver-last-name" class="form-label-sm mb-0" style="font-weight: normal !important;">
				Last Name
			</label>
			<input
				id="assign-driver-last-name"
				type="text"
				value={driverLastName}
				on:input={event => { driverLastName = event.target.value }}
				disabled={form.state === 'saving'}
				class="form-control form-control-sm"
			>
		</div>
		<div class="col-xs-12 col-md-6">
			<label for="assign-driver-phone" class="form-label-sm mb-0" style="font-weight: normal !important;">
				Phone Number
			</label>
			<input
				id="assign-driver-phone"
				type="text"
				value={driverPhone}
				on:input={event => { driverPhone = event.target.value }}
				disabled={form.state === 'saving'}
				class="form-control form-control-sm"
			>
		</div>
	</div>
	{#if carrier}
		<div class="card mb-3">
			<div class="card-body p-2">
				<div class="row align-items-center">
					<div class="col">
						<p class="card-text">
							<strong>
								<NamedIcon name="carrier" />
								&nbsp;
								{carrier.name}
							</strong>
							{carrier.location}
							{#if carrier.centralDispatchId || carrier.onTrackId}
								<span style="width: 1em; display: inline-block;"></span>
								<span class="text-nowrap">
									{#if carrier.centralDispatchId}
										<ExternalLink url="https://site.centraldispatch.com/protected/rating/client-snapshot?id={carrier.centralDispatchId}">
											<NamedIcon name="cd" />
										</ExternalLink>
									{/if}
									{#if carrier.onTrackId}
										{#if carrier.centralDispatchId}
											<span style="width: 1em; display: inline-block;"></span>
										{/if}
										<ExternalLink url={router.makePath('app.companies.edit.companyId.overview', { companyId: carrier.onTrackId }, { inherit: false })}>
											<span style="padding-right: 4px">
												<NamedIcon name="carrier" />
											</span>
										</ExternalLink>
									{/if}
								</span>
							{/if}
						</p>
					</div>
					<div class="col-auto">
						<button disabled={form.state === 'saving'} class="btn btn-sm btn-secondary" on:click={() => { carrier = null; carrierQuery = null }}>
							Change
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="input-group input-group-sm mt-2 mb-0">
			<span class="input-group-text justify-content-center">
				<label for="carrier-email" class="form-label-sm mb-0" style="font-weight: normal !important;">
					Carrier Email
				</label>
			</span>
			<input
				id="carrier-email"
				type="text"
				value={carrier.email || ''}
				on:input={event => { carrier.email = event.target.value }}
				disabled={form.state === 'saving'}
				class="form-control form-control-sm"
			>
		</div>
		<div class="alert alert-info py-0 py-2 my-2">
			Please verify the email address with the carrier. Updating it here will update
			the carrier email in OnTrack.
		</div>
		<button class="btn btn-sm btn-primary" disabled={!carrier || disableAssign} on:click={() => assignLeg(carrier)}>
			Yes, assign to {carrier?.name || '...'}
			{#if onlyOntrackSync}
				<em>in OnTrack Only!</em>
			{/if}
		</button>
		{#if carrier?.onTrackId}
			<div class="form-check mt-3 mb-0">
				<input
					disabled={form.state === 'saving' || !carrier?.onTrackId}
					class="form-check-input"
					type="checkbox"
					id="leg-assign-only-ontrack"
					checked={onlyOntrackSync}
					on:input={() => { onlyOntrackSync = !onlyOntrackSync }}
				>
				<label for="leg-assign-only-ontrack" class="form-check-label" style="font-size: small; font-weight: normal !important;">
					Only sync to OnTrack. This will <strong>not</strong> update Central Dispatch
					and will <strong>not</strong> send a notification email.
				</label>
			</div>
		{:else}
			<p class="mb-0 text-muted">
				<small>
					Cannot manually sync carriers that are not already in OnTrack.
				</small>
			</p>
		{/if}
	{:else}
		<div class="input-group input-group-sm mb-2">
			<span class="input-group-text" id="carrier-search">
				<NamedIcon name="carrier" />&nbsp;Find Carrier
			</span>
			<input disabled={form.state === 'saving'} type="text" class="form-control" placeholder="Carrier name or URL" aria-label="Carrier name or URL" aria-describedby="carrier-search" on:input={updateQuery}>
		</div>
	{/if}

	{#if carrierQuery && !carrier}
		{#await carrierQuery}
			<p class="form-text">Loading...</p>
		{:then carriersOrSearch}
			{#if carriersOrSearch.html}
				<p>Carrier not found in OnTrack yet, but was found on Central Dispatch:</p>
				<div class="card">
					<div class="card-body p-2">
						{@html carriersOrSearch.html}
					</div>
				</div>
				<p>Selecting this carrier will import it to OnTrack.</p>
				<p>
					<button class="btn btn-sm btn-primary" disabled={!carriersOrSearch.centralDispatchId || disableAssign} on:click={() => assignLeg(carriersOrSearch)}>
						<NamedIcon name="download" />
						Yes, import this carrier and assign
					</button>
				</p>
			{:else}
				<table class="table table-sm table-hover">
					<thead>
					<tr>
						<th scope="col">
							Name
							<em class="text-muted">(click to select)</em>
						</th>
						<th scope="col">Location</th>
						<th scope="col">CD</th>
						<th scope="col">OnTrack</th>
					</tr>
					</thead>
					<tbody>
					{#each carriersOrSearch as c}
						{#if c.html}
							{@html c.html}
						{:else}
							<tr>
								<td>
									{#if c.blocked || c.inactive}
										{c.name}
										&emsp;
										<span class="badge" class:bg-info={c.inactive} class:bg-danger={c.blocked}>
											{c.blocked ? 'Blocked' : 'Inactive'}
										</span>
									{:else}
										<a href="#select-carrier" on:click|preventDefault={() => { carrier = c }}>
											{c.name}
										</a>
									{/if}
								</td>
								<td>
									{c.location}
								</td>
								<td class="text-nowrap pe-3">
									{#if c.centralDispatchId}
										<ExternalLink url="https://site.centraldispatch.com/protected/rating/client-snapshot?id={c.centralDispatchId}">
											<NamedIcon name="cd" />
										</ExternalLink>
									{/if}
								</td>
								<td class="text-nowrap">
									{#if c.onTrackId}
										<ExternalLink url={router.makePath('app.companies.edit.companyId.overview', { companyId: c.onTrackId }, { inherit: false })}>
											<span style="padding-right: 4px">
												<NamedIcon name="carrier" />
											</span>
										</ExternalLink>
									{/if}
								</td>
							</tr>
						{/if}
					{/each}
					</tbody>
				</table>
			{/if}
		{:catch error}
			<div class="alert alert-error">
				<pre>{JSON.stringify(error, undefined, 4)}</pre>
			</div>
		{/await}
	{:else if !carrier}
		<p class="form-text">
			Type to search, or paste in the Central Dispatch company URL.
		</p>
	{/if}

	{#if missingProps}
		<p class="form-text">
			All fields must be set before dispatching.
		</p>
	{/if}
</div>
