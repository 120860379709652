/**
 * These properties are for convenience of using them in code. They refer
 * to the `user-permissions.model.js` permissions values.
 */

export const MCS_STAFF = 'mcsStaff'
export const MANAGE_REFERRERS = 'manageReferrers'
export const MANAGE_OPPORTUNITIES = 'manageOpportunities'
export const GENERATE_REPORTS = 'generateCustomReports'
export const MANAGE_USERS = 'manageUsers'
export const MANAGE_APP = 'manageApp'
