<script>
	import { Field } from 'jsonapi-svelte-form'
	import AddNewColumn from './AddNewColumn.svelte'
	import { opportunityColumns } from '@/shared/value/report-columns.js'

	const columnKeyToDefinition = opportunityColumns({})

	export let form

	const changeValuePosition = (arr, init, target) => {
		[ arr[init], arr[target] ] = [ arr[target], arr[init] ]
		return arr
	}
	const moveOrderPosition = (list, index, direction) => {
		changeValuePosition(list, index, index + direction)
		return [ ...list ]
	}
	const removeElement = (list, index) => {
		list.splice(index, 1)
		return [ ...list ]
	}
	const addElement = (list, index, key) => {
		list.splice(index, 0, key)
		return [ ...list ]
	}
</script>

<style>
	.button-top {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;
	}
	.button-bottom {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.list-group-item { position: relative; }
</style>

<Field bind:form id={form.primaryId} keypath={[ 'attributes', 'orderedColumns' ]} let:set let:value let:disabled>
	<div class="list-group">
		{#each (form.data[form.primaryId]?.attributes?.orderedColumns || []) as columnKey, index}
			<div class="list-group-item">
				{#if !index}
					<AddNewColumn
						above
						{index}
						on:add={({ detail }) => set(addElement(value, index, detail))}
					/>
				{/if}
				<div class="row align-items-center">
					<div class="col">
						<strong>{columnKeyToDefinition[columnKey]?.label || 'UNLABELED COLUMN PLEASE FIX'}</strong>
						{#if columnKeyToDefinition[columnKey]?.description}
							<br>
							{columnKeyToDefinition[columnKey].description}
						{/if}
					</div>
					<div class="col-auto gx-0">
						<button
							class="btn btn-sm btn-outline-danger"
							on:click={() => set(removeElement(value, index))}
						>
							<span class="fa fa-ban"></span>
						</button>
					</div>
					<div class="col-auto">
						<button
							class="btn btn-sm btn-outline-secondary button-top"
							on:click={() => set(moveOrderPosition(value, index, -1))}
						>
							<span class="fa fa-arrow-up"></span>
						</button>
						<br>
						<button
							class="btn btn-sm btn-outline-secondary button-bottom"
							on:click={() => set(moveOrderPosition(value, index, 1))}
						>
							<span class="fa fa-arrow-down"></span>
						</button>
					</div>
				</div>
				<AddNewColumn
					{index}
					on:add={({ detail }) => set(addElement(value, index + 1, detail))}
				/>
			</div>
		{/each}
	</div>
</Field>
