<script>
	import MaybeLink from '@/component/atom/MaybeLink.svelte'
	export let column
	export let align
	export let stacked
	export let format
	export let highlightColumn
	export let highlightCell
	$: href = column.href
	$: component = column.component
	$: value = column.value
	$: whiteSpace = stacked
		? 'pre-wrap'
		: (
			format === 'date'
				? 'nowrap'
				: ''
		)
</script>

<style>
	td {
		max-width: 30ch;
	}
	.report-column-distinguisher {
		background-color: #f6f8fa;
	}
</style>

<td class:report-column-distinguisher={highlightColumn && !highlightCell} class:text-end={align === 'end'} style:white-space={whiteSpace} on:mouseenter on:mouseleave>
	<MaybeLink {href}>
		{#if component}
			<svelte:component this={component} {value} />
		{:else}
			{value}
		{/if}
	</MaybeLink>
</td>
