export const opportunityAddress = (legId, includedById, start) => {
	let leg = includedById[legId]
	let locationKey = start ? 'pickup' : 'dropoff'
	let terminalId = leg?.relationships?.[`${locationKey}Terminal`]?.data?.id
	let addressId = leg?.relationships?.[`${locationKey}Address`]?.data?.id
		|| (terminalId && includedById[terminalId]?.relationships?.address?.data?.id)
	let atts = includedById[addressId]?.attributes
	return {
		isTerminal: !!terminalId,
		shortAddress: atts && `${atts.city},&nbsp;${atts.state}` || '',
		query: atts && `https://google.com/maps/search/?q=${atts.city}, ${atts.state} ${atts.zip}` || '',
	}
}
