<script>
	import { clone } from '@/shared/util/clone.js'
	import { containsCreditCardDigits } from '@/shared/util/contains-credit-card-digits.js'
	import FormErrorsOther from '@/component/FormErrorsOther.svelte'
	import FormCommitButton from '@/component/FormCommitButton.svelte'
	import CcTransactionForm from '@/component/form/CcTransactionForm.svelte'
	import ErrorAlert from '@/component/ErrorAlert.svelte'
	import NavHeader from './NavHeader.svelte'
	import { postOpportunityCcTransaction } from '@/lib/controller/opportunity.js'
	import { router } from '@/service/router.js'

	export let form
	export let opportunityId
	export let opportunityFormForNavHeader
	export let api
	export let asr
	export let users
	export let usersById

	$: containsCcDigits = containsCreditCardDigits(form.data[form.primaryId]?.attributes?.note)

	let uploadingFiles
	$: stillUploadingFiles = Object.keys(uploadingFiles || {}).find(key => !uploadingFiles[key].file)

	let errors // TODO make sure to show these
	const save = () => {
		form.state = 'saving'
		const waitForFilesToUpload = new Promise(resolve => {
			const waitLonger = () => {
				if (stillUploadingFiles) setTimeout(waitLonger, 50)
				else resolve(
					Object
						.keys(uploadingFiles || {})
						.map(key => {
							const { id, type } = uploadingFiles[key].file.data
							return { id, type }
						}),
				)
			}
			waitLonger()
		})
		waitForFilesToUpload
			.then(fileRels => {
				const ccTransaction = clone(form.data[form.primaryId])
				if (!fileRels.length) {
					if (ccTransaction.relationships?.files) delete ccTransaction.relationships.files
					if (!Object.keys(ccTransaction.relationships || {}).length) delete ccTransaction.relationships
				} else {
					// overwrite with the finished rels
					ccTransaction.relationships = ccTransaction.relationships || {}
					ccTransaction.relationships.files = ccTransaction.relationships.files || {}
					ccTransaction.relationships.files.data = fileRels
				}
				return postOpportunityCcTransaction(api, { opportunityId, ccTransaction })
			})
			.then(response => {
				router.go('app.opportunities.edit.opportunityId.overview', { opportunityId, ccTransactionId: response.body.data.id })
			})
			.catch(e => {
				console.error('Error while creating CC transaction!', e)
				errors = e
			})
	}
</script>

<NavHeader {asr} form={opportunityFormForNavHeader} {users} {usersById} activeTab="logCreditCard" />

<CcTransactionForm bind:form bind:uploadingFiles {opportunityId} />

{#if errors}
	{#each errors as error}
		<ErrorAlert {error} />
	{/each}
{/if}

<div class="row mt-3">
	<div class="col">
		<FormErrorsOther {form} />
		<FormCommitButton disabled={containsCcDigits} {form} on:click={save} label="Log Credit Card" />
		{#if stillUploadingFiles && form.state === 'saving'}
			<p>
				Uploading files, one moment... <span class="fa fa-spinner fa-spin"></span>
			</p>
		{/if}
	</div>
</div>
