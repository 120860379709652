<script>
	import CopyToClipboard from '@/component/molecule/CopyToClipboard.svelte'
	import ChangeCell from '@/component/table/history/ChangeCell.svelte'
	import { getSchema } from '@/shared/models.js'
	import { getSchemaKeypathDefinition } from '@/shared/util/get-schema-keypath-definition.js'
	import kebabCase from 'just-kebab-case'

	export let row
	export let change
	export let hideClipboardColumn
	const includedById = {}

	$: parentSchema = getSchema(row.type)?.schema

	let schema
	let definition
	let name
	$: {
		if (change.keypath[0] === 'data') {
			schema = parentSchema
			if (change.keypath[1] === 'attributes' || change.keypath[1] === 'relationships') name = change.keypath.slice(2)
		} else if (change.keypath[0] === 'included') {
			const anyType = change.original?.type || change.updated?.type
			name = change.relationship || (anyType && [ anyType ])
			if (change.keypath.length > 3 && change.keypath[2] === 'attributes') name.push(...change.keypath.slice(3))
		} else {
			name = change.keypath
		}
		definition = schema && change.keypath?.[0] === 'data' && change.keypath[1] !== 'relationships' && getSchemaKeypathDefinition(schema, change.keypath.slice(1))
		// upper case first letter in words
		if (name?.[0] === 'legs' && Number.isInteger(name[1])) {
			name[1] = `Leg ${name[1] + 1}`
			name.shift()
		}
		name = name?.length && name.map(n => kebabCase(n.toString()).split('-').map(s => `${s[0]?.toUpperCase()}${s.slice(1)}`).join(' '))
	}

	let highlight
	const highlightCopied = () => {
		highlight = true
		setTimeout(() => {
			highlight = false
		}, 1000)
	}
</script>

<style>
	.change-cell-copyable {
		color: var(--falcon-text);
	}
	.change-cell-copyable.change-cell-copied {
		color: var(--falcon-success);
		animation: fadeout 1s;
	}
	@keyframes fadeout {
		from { color: var(--falcon-success); }
		to { color: var(--falcon-text); }
	}
</style>

<td class="p-1">
	{#each (name || []) as word, index}
		<div class="text-nowrap" class:text-muted={index < (name.length - 1)}>
			{#if index > 0}
				<span style="width: {(index - 1) * 1.5}em; display: inline-block;"></span>
				⮑
			{/if}
			{word}
		</div>
	{/each}
</td>

<td>
	<ChangeCell isOriginal {includedById} {definition} value={change.original} />
</td>

<td>
	→
</td>

<td class="change-cell-copyable" class:change-cell-copied={highlight}>
	<ChangeCell {includedById} {definition} value={change.updated} />
</td>

{#if !hideClipboardColumn}
	<td>
		<CopyToClipboard string={change.updated} on:copied={highlightCopied} />
	</td>
{/if}
