<script>
	import DateString from '@/component/atom/DateString.svelte'
	import { tick } from 'svelte'
	import InputTypeBare from '@/component/atom/InputTypeBare.svelte'
	import { openForm, openFormById, savingFormById } from './open-form-store.js'

	export let row

	const openFormAndFocus = () => {
		openForm(row)
		tick().then(() => {
			document.getElementById(`${row.id}.attributes.reminder`)?.focus()
		})
	}
</script>

{#if !$openFormById[row.id]}
	<div on:click={openFormAndFocus}>
		{#if row.attributes.reminder}
			<DateString date={`${row.attributes.reminder}T12:00:00.000Z`} />
		{:else}
			–
		{/if}
	</div>
{:else}
	<InputTypeBare
		type="date"
		bind:form={$openFormById[row.id]}
		id={row.id}
		disabled={$savingFormById[row.id]}
		keypath={[ 'attributes', 'reminder' ]}
	/>
{/if}
