<script>
	import ErrorList from '@/component/ErrorList.svelte'
	import DateString from '@/component/atom/DateString.svelte'
	import Card from '@/component/atom/Card.svelte'
	import NavHeader from './NavHeader.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import SendOpportunityEmail from '@/component/form/SendOpportunityEmail.svelte'
	import { listOpportunityEmails, sendTemplateEmail } from '@/lib/controller/opportunity.js'
	import { SIGNATURE_CONFIRMED } from '@/shared/value/system-email-templates.js'
	import { emailTemplateIdToName } from '@/shared/value/email-template-id-to-name.js'

	export let asr
	export let api
	export let user
	export let users
	export let usersById
	export let emailTemplatesById
	export let signableTemplatesById
	export let opportunityForm
	export let opportunityResponse
	export let emailTemplates
	export let companies

	let selectedTemplateId

	$: templates = emailTemplates.filter(t => t.attributes?.showForOpportunityStatus?.[opportunityResponse.data.attributes.status])

	let sending
	let justSentEmail
	let justSentEmailId
	let justSentErrors
	const sendEmail = ({ detail: { note, ccRecipients, additionalSignatories } }) => {
		sending = true
		sendTemplateEmail(api, { opportunityId: opportunityForm.primaryId, emailTemplateId: selectedTemplateId, note, ccRecipients, additionalSignatories })
			.then(response => {
				justSentEmail = response.body.data
				justSentEmailId = justSentEmail.id
				selectedTemplateId = false
				sending = false
				setTimeout(() => {
					justSentEmailId = undefined
				}, 5000)
			})
			.catch(error => {
				justSentErrors = error?.body?.errors || [ error ]
			})
	}
</script>

<NavHeader {asr} {users} {usersById} form={opportunityForm} activeTab="communication" />

<style>
	.not-set {
		color: #b7bed2;
	}
	:global(.table.communication > :not(caption) > tr > td) {
		transition: background-color 1s linear;
	}
	:global(.table.communication > :not(caption) > tr.table-success > td) {
		transition: none;
	}
</style>

<p>
	Send Email:
	{#each templates as template}
		<button
			class="btn btn-sm btn-outline-primary"
			class:active={selectedTemplateId === template.id}
			on:click|preventDefault={() => selectedTemplateId = (selectedTemplateId === template.id ? false : template.id)}
		>
			{template.attributes.name}
		</button>
	{/each}
</p>

{#if selectedTemplateId}
	<Card title={`Send email using "${emailTemplatesById[selectedTemplateId]?.attributes?.name}" template:`}>
		<SendOpportunityEmail
			{asr}
			currentUser={user}
			{companies}
			{usersById}
			{sending}
			opportunity={opportunityResponse}
			signableTemplate={signableTemplatesById[emailTemplatesById[selectedTemplateId]?.relationships?.signableTemplate?.data?.id]}
			emailTemplate={emailTemplatesById[selectedTemplateId]}
			on:send={sendEmail}
		/>
		{#if justSentErrors?.length}
			<div class="mt-3">
				<ErrorList errors={justSentErrors} />
			</div>
		{/if}
	</Card>
{/if}

<div class="mt-3">
	<Card title="Email Communication">
		<table class="table communication">
			{#await listOpportunityEmails(api, opportunityForm.primaryId, {})}
				<tr>
					<td class="text-center">
						Loading...
					</td>
				</tr>
			{:then response}
				{@const emailData = [ justSentEmail, ...response.data ].filter(Boolean)}
				{#if !emailData.length}
					<tr>
						<td class="text-center">
							No email communication found.
						</td>
					</tr>
				{:else}
					<thead>
						<tr>
							<th scope="col">Sent</th>
							<th scope="col">Template</th>
							<th scope="col">Sent By</th>
							<th scope="col">Sent To</th>
							<th scope="col">Custom Message</th>
						</tr>
					</thead>
					<tbody>
						{#each emailData as email, index}
						{@const sendingUserId = email.relationships?.sendingUser?.data?.id}
						{@const emailTemplateId = email.relationships?.template?.data?.id}
							<tr class:table-success={email.id === justSentEmailId}>
								<td style="white-space: nowrap;">
									<a href={asr.makePath('app.sentEmail.sentEmailId', { sentEmailId: email.id }, { inherit: false })}>
										<DateString time multiline date={email.meta.created} />
									</a>
								</td>
								<td>
									{#if emailTemplateId === SIGNATURE_CONFIRMED}
										{emailTemplateIdToName[SIGNATURE_CONFIRMED]}
									{:else if emailTemplateId}
										<a href={asr.makePath('app.systemSettings.emailTemplates.edit.emailTemplateId', { emailTemplateId }, { inherit: null })}>
											{emailTemplatesById[emailTemplateId]?.attributes?.name || emailTemplateId}
										</a>
									{:else}
										<span class="not-set">Manual</span>
									{/if}
								</td>
								<td style="white-space: nowrap;">
									{#if sendingUserId}
										<NamedIcon name="users" />
										<a href={asr.makePath('app.users.edit.userId', { userId: sendingUserId }, { inherit: null })}>
											{usersById[sendingUserId]?.attributes?.name || 'Unnamed User'}
										</a>
									{:else if email.attributes.systemId}
										<NamedIcon name="system" />
										<code>{email.attributes.systemId}</code>
									{/if}
								</td>
								<td>
									{email.attributes.toAddress}
									{#if email.attributes.ccRecipients}
										<div class="row gx-3">
											<div class="col-auto">
												<strong>CC</strong>
											</div>
											<div class="col">
												{#each email.attributes.ccRecipients.split(',').map(e => e.trim()) as recipient}
													<p class="mb-0">{recipient}</p>
												{/each}
											</div>
										</div>
									{/if}
								</td>
								<td>
									{#if email.attributes.parameters?.note}
										{@html email.attributes.parameters.note}
									{:else}
										<span class="not-set">None</span>
									{/if}
								</td>
							</tr>
						{/each}
					</tbody>
				{/if}
			{:catch error}
				<tr>
					<td>
						<pre>{JSON.stringify(error, undefined, 4)}</pre>
					</td>
				</tr>
			{/await}
		</table>
	</Card>
</div>
