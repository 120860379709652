export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		created: {
			type: 'string',
			format: 'date-time',
		},
		updated: {
			type: 'string',
			format: 'date-time',
		},
		expiration: {
			type: 'string',
			format: 'date-time',
		},
	},
}
