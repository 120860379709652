export default {
	type: 'object',
	additionalProperties: false,
	properties: {
		messageId: { type: 'string' },
		md5OfMessageBody: { type: 'string' },
		requestId: { type: 'string' },
		// these are error specific properties
		type: { type: 'string' },
		code: { type: 'string' },
		message: { type: 'string' },
		detail: { type: 'string' },
	},
}
