import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const $selfFragment = {
	sendingUser: false,
	template: false,
	opportunity: false,
	signableTemplate: false,
	signableDocument: false,
}

export const type = 'sent-email'

export default {
	description: `
		A sent email, either based on a template or manually sent.
	`,
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'The globally unique identifier of the email.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			properties: {
				toName: {
					description: 'The name part used for the receiving address, e.g. in `"John Smith" <sender@site.com>` it would be `John Smith`.',
					type: 'string',
				},
				toAddress: {
					description: 'The address part used for the receiving address, e.g. in `"John Smith" <sender@site.com>` it would be `sender@site.com`.',
					type: 'string',
				},
				fromName: {
					description: 'The name part used for the sending address, e.g. in `"John Smith" <sender@site.com>` it would be `John Smith`.',
					type: 'string',
				},
				fromAddress: {
					description: 'The address part used for the sending address, e.g. in `"John Smith" <sender@site.com>` it would be `sender@site.com`.',
					type: 'string',
				},
				ccRecipients: {
					description: 'The comma-separated list of valid email addresses to send carbon copies to.',
					type: 'string',
				},
				subject: {
					description: 'The fully rendered subject of the email.',
					type: 'string',
				},
				html: {
					description: 'The fully rendered HTML version of the email.',
					type: 'string',
				},
				markdown: {
					description: 'The fully rendered Markdown version of the email.',
					type: 'string',
				},
				requestId: {
					description: 'The request identifier that initiated sending this email, where possible.',
					type: 'string',
				},
				systemId: {
					description: 'If sent by an internal system service, this must be that service identifier.',
					type: 'string',
				},
				note: {
					description: 'For all email templates, the "note" field is the catch-all for general, all-purpose, long-form text.',
					type: 'string',
				},
				parameters: {
					description: 'The key is the parameter used in the template, and the value is the property used.',
					type: 'object',
				},
				errors: {
					description: 'System or third-party errors that happen during sending, or e.g. on a bounce.',
					type: 'array',
					items: {
						description: 'Free-form error objects, hopefully settling on JSON:API formatted.',
						type: 'object',
					},
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				sendingUser: {
					description: `
						Reference to the user who sent the email. If an internal system service sent the email, the
						attribute \`systemId\` must be set.
					`,
					...jsonApiRelationshipSingular('user'),
				},
				template: {
					description: 'Reference to the template used for this email. Not set if email was fully manual.',
					...jsonApiRelationshipSingular('email-template'),
				},
				signableTemplate: {
					description: 'If signable document was created, this is the template that it was created from.',
					...jsonApiRelationshipSingular('signable-template'),
				},
				signableDocument: {
					description: 'Will be set iff a signable document was created.',
					...jsonApiRelationshipSingular('signable-document'),
				},
				opportunity: {
					description: 'Reference to the opportunity associated with this email, if available.',
					...jsonApiRelationshipSingular('opportunity'),
				},
			},
		},
	},
}
