export const specificRelationshipType = (...names) => ({
	allOf: [
		{ $ref: '#/components/schemas/relationship' },
		{
			type: 'object',
			properties: {
				type: {
					type: 'string',
					enum: names.flat().filter(Boolean),
				},
			},
		},
	],
})

export const jsonApiRelationshipSingular = (...names) => ({
	type: 'object',
	additionalProperties: false,
	properties: {
		data: {
			...specificRelationshipType(names),
		},
	},
})

export const jsonApiRelationshipList = (...names) => ({
	type: 'object',
	additionalProperties: false,
	properties: {
		data: {
			type: 'array',
			items: specificRelationshipType(names),
		},
	},
})
