<script>
	const GREEN = '#0bc10b'
	const GRAY = '#bbb'
	export let row
	$: perm = row?.attributes?.permissions || {}
</script>

<span style="color: {perm.mcsStaff ? GREEN : GRAY}" title="MCS Staff">
	<span class="fa fa-user-shield"></span>
</span>

<span style="color: {perm.manageOpportunities ? GREEN : GRAY}" title="Manage Opportunities">
	<span class="fa fa-file-contract"></span>
</span>

<span style="color: {perm.manageReferrers ? GREEN : GRAY}" title="Manage Referrers">
	<span class="fa fa-hands-helping"></span>
</span>

<span style="color: {perm.generateCustomReports ? GREEN : GRAY}" title="Generate Custom Reports">
	<span class="fa fa-chart-line"></span>
</span>

<span style="color: {perm.manageUsers ? GREEN : GRAY}" title="Manage Users">
	<span class="fa fa-users"></span>
</span>

<span style="color: {perm.manageApp ? GREEN : GRAY}" title="Manage App">
	<span class="fa fa-cogs"></span>
</span>
