const formatCell = obj => obj.cell || obj.value

export const spreadsheetGenerator = ({ label, filename, XLSX, columnOverrides, orderedColumns, columns, rows, params }) => {
	const actualOrderedColumns = orderedColumns.filter(name => columns[name])
	const workbook = XLSX.utils.book_new()

	XLSX.utils.book_append_sheet(
		workbook,
		XLSX.utils.aoa_to_sheet([
			actualOrderedColumns.map(columnKey => columnOverrides?.[columnKey]?.label || columns[columnKey].label),
			...(rows || []).map(row => actualOrderedColumns.map(columnName => formatCell(row.columns[columnName]))),
		]),
		label,
	)
	XLSX.utils.book_append_sheet(
		workbook,
		XLSX.utils.aoa_to_sheet([
			[ 'Parameter', 'Value' ],
			...Object.keys(params || {})
				.map(key => ([ key, params[key] ])),
		]),
		'Parameters',
	)
	return {
		workbook,
		filename,
	}
}
