import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'

export const rateSheetBrokerFees = {
	brokerFeeOne: { type: 'number' },
	brokerFeeTwo: { type: 'number' },
	brokerFeeThree: { type: 'number' },
	brokerFeeFourOrMore: { type: 'number' },
}
export const rateSheetBrokerFeeKeys = Object
	.keys(rateSheetBrokerFees)

export const rateSheetRowColumns = {
	milesMin: { type: 'number' },
	milesMax: { type: 'number' },
	carrierPaySmall: { type: 'number' },
	carrierPayMedium: { type: 'number' },
	carrierPayLarge: { type: 'number' },
}
export const rateSheetRowColumnKeys = Object
	.keys(rateSheetRowColumns)
export const rateSheetRowCarrierPayKeys = Object
	.keys(rateSheetRowColumns)
	.filter(key => key.startsWith('carrierPay'))

export const $selfFragment = {
	quoteEmailTemplate: false,
	duplicateEmailTemplate: false,
	errorEmailTemplate: false,
	unsupportedLocationEmailTemplate: false,
}

export const type = 'auto-quote-campaign'

export default {
	description: 'Algorithm used to automatically generate an opportunity quote and send an email to the customer for it.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the record.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [ 'name' ],
			properties: {
				name: {
					type: 'string',
					description: 'Name of the Auto-Quote Campaign',
				},
				code: {
					type: 'string',
					description: 'Code used for things like ClickFunnel, A/B testing, and so on.',
				},
				delayEmailSendSeconds: {
					type: 'number',
					description: 'The number of seconds after receiving a lead to send the automatic email.',
				},
				...rateSheetBrokerFees,
				rateSheetRows: {
					type: 'array',
					items: {
						type: 'object',
						properties: rateSheetRowColumns,
					},
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				quoteEmailTemplate: {
					description: 'Email template to use for quote email.',
					...jsonApiRelationshipSingular('email-template'),
				},
				duplicateEmailTemplate: {
					description: 'Email template to use for duplicate quote email.',
					...jsonApiRelationshipSingular('email-template'),
				},
				errorEmailTemplate: {
					description: 'Optional email template to use for failed quotes.',
					...jsonApiRelationshipSingular('email-template'),
				},
				unsupportedLocationEmailTemplate: {
					description: 'Optional email template to use for leads with zipcodes in Alaska or Canada.',
					...jsonApiRelationshipSingular('email-template'),
				},
			},
		},
	},
}
