/*
A simple GET request cache that only caches on router page loads and changes. That way you can
call the same API endpoint in multiple resolves but it will only be called the one time.

For each endpoint request, you will need to create a new cache:

```js
import { makeRequestCache } from 'lib/make-request-cache.js'
import { router } from '@/service/router.js'

export const exampleRequest = makeRequestCache(
	router,
	async () => get('/api/v1/example')
)
```

Then, using that is as simple as:

```js
import { exampleRequest } from 'path/to/thing.js'

exampleRequest(params).then(handler)
```

 */

/**
 * @param {EventEmitter} router - The instantiated abstract state router.
 * @param {Function} makeRequest - The function internally used to make the actual request.
 * @returns {function(): Promise<*>} - The caller.
 */
export const makeRequestCache = (router, makeRequest) => {
	let responsePromise

	const clear = () => {
		responsePromise = null
	}

	router.on('stateChangeCancelled', clear)
	router.on('stateChangeEnd', clear)
	router.on('stateChangeError', clear)
	router.on('stateError', clear)
	router.on('routeNotFound', clear)

	return async (...args) => {
		if (!responsePromise) {
			responsePromise = makeRequest(...args)
		}
		return responsePromise
	}
}
