<script>
	export let row
	export let column
	$: value = row?.attributes?.[column.which]
	$: color = column.which === 'blocked' ? 'danger' : 'secondary'
</script>

{#if value}
	<span class="badge bg-{color}">{column.title}</span>
{/if}
