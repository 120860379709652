<script>
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	export let history

	$: agent = history.attributes.cleanUserAgent

	$: agentString = agent && [
		(agent.browser?.name || 'N/A' + agent.browser?.version || ''),
		(agent.os?.name || 'N/A' + agent.os?.versionName || ''),
		(agent.platform?.type || ''),
	].filter(Boolean).join(', ')

	$: definitions = [
		// TODO if you can link directly to the user's session, then make this a link
		[ 'User Session ID', history.attributes.sessionId ],
		// [ 'Browser', agentString ],
		[ 'IP Address', history.attributes.ipAddress ],
		[ 'Country', history.attributes.cfCountry ],
	].filter(([ , string ]) => string)

	$: browser = agent?.browser?.name?.toLowerCase() || ''

	$: agentIcon = browser.includes('firefox') && 'fab fa-firefox-browser'
		|| browser.includes('safari') && 'fab fa-safari'
		|| browser.includes('chrome') && 'fab fa-chrome'
		|| browser.includes('edge') && 'fab fa-edge'
		|| browser.includes('explorer') && 'fab fa-internet-explorer'
		|| 'fa-info-circle'
</script>

<style>
	button {
		color: #A5ACB2;
	}
</style>

{#if definitions.length}
	<div class="btn-group dropdown">
		<button type="button" class="btn btn-link btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
			<NamedIcon name="descending" />
		</button>
		<div class="dropdown-menu text-dark px-3">
			<dl>
				<dt>Browser</dt>
				<dd>
					<span class={agentIcon}></span>
					{agentString}
				</dd>
				{#each definitions as [ name, value ]}
					<dt>{name}</dt>
					<dd>{value}</dd>
				{/each}
			</dl>
		</div>
	</div>
{/if}
