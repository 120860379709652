<script>
	import Navbar from './_CustomerNavbar.svelte'
	export let asr
</script>

<Navbar {asr} current="today" />

<div class="card mb-3">
	<div class="card-header py-2">
		<div class="row">
			<div class="col">
				Today’s CC Transactions
			</div>
		</div>
	</div>
	<div class="card-body bg-light py-2">
		<div class="alert alert-info">
			<p>Not yet implemented!</p>
			<p>This will be a list of any opportunity that has been fully dispatched, where the first-leg pickup is today.</p>
		</div>
	</div>
</div>
