<script>
	import TerminalSingle from './TerminalSingle.svelte'
	import { keyBy } from '@/shared/util/key-by.js'

	export let response
	export let action

	$: terminals = response?.data || []
	$: includedById = keyBy(response?.included || [], 'id')
</script>

<div class="list-group">
	{#each terminals as terminal}
		<TerminalSingle
			{terminal}
			{includedById}
			{action}
			on:click
		/>
	{/each}
</div>
