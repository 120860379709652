<script>
	let className = ''
	export { className as class }
	export let system
	export let config = {}
	$: opts = Object.assign({ layout: 'basic' }, config)
</script>

<div class={className}>
	<img class="d-block mx-auto mb-4" src={$system.CWD + 'vendor/falcon/assets/img/illustrations/rocket.png'} alt="Shield" width="70">
	<h4>
		See you again!
	</h4>
	<p>
		Thanks for using {$system.name}. You are now successfully signed out.
	</p>
	<a href={$system.paths['authentication/simple/login']} class="btn btn-primary btn-sm mt-3">
		<span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4 down-1"></span>
		Return to login
	</a>
</div>
