<script>
	export let id
	export let keypath
	export let readonly
	export let value

	export let label
	export let labelWidth = '85px'
	export let enabledText = ''
	export let disabledText = ''
	export let bottomSpacing = '2'

	$: elementId = [ id, ...keypath ].join('.')
</script>

<style>
	label {
		font-weight: normal !important;
		position: relative;
		left: -2em;
		padding-left: 2em;
		cursor: pointer;
	}
</style>

<div class="input-group input-group-sm mb-{bottomSpacing}">
	<span class="input-group-text justify-content-center" style="width: {labelWidth};">
		{label}
	</span>
	<span class="input-group-text justify-content-center">
		<div class="form-check form-check-inline mb-0">
			<input
				class="form-check-input"
				type="radio"
				name={elementId}
				bind:group={value}
				id="{elementId}-enabled"
				value={true}
				disabled={readonly}
				on:input
			>
			<label class="form-check-label mb-0" for="{elementId}-enabled">
				{enabledText}
			</label>
		</div>
		<div class="form-check form-check-inline mb-0">
			<input
				class="form-check-input"
				type="radio"
				name={elementId}
				bind:group={value}
				id="{elementId}-disabled"
				value={false}
				disabled={readonly}
				on:input
			>
			<label class="form-check-label mb-0" for="{elementId}-disabled">
				{disabledText}
			</label>
		</div>
	</span>
</div>
